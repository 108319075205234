import React from 'react';
import ReactQuill from 'react-quill';
import print from './print.document.js';

import eDocsService from '../../services/eDocsService';

export default class viewDocument extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
      super( props );

      this.state = {
        title: "",
        content: "",
        template_id: props.match.params.id,
        edocs_type_token: "",
      }

  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props  ) {

      this.setState ({
          template_id: nextProps.match.params.id
      });

      setTimeout(() => {

        this.view();

      }, 300);

    }

  }

  componentDidMount( ) { this._isMounted = true;

      this.view();

  }

  setStyle() {
    return { __html: print[0].key };
  }


  view(){

    this.eDocsService = new eDocsService();

      this.eDocsService.viewDocument( this.state.template_id ).then(res =>{
            //console.log(res);

            this.setState({
                title: res.name,
                content: res.content,
                edocs_type_token: res.edocs_type.token,
            });
      });

  }

  modules = {
      toolbar: [

      ],
    };

formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
      'list', 'bullet', 'indent',
      'image', 'align',
      'font', 'color', 'size'
    ];

  render() {

      return <div className="print view-document">
                <style dangerouslySetInnerHTML={ this.setStyle() } />
                            <button onClick={() => window.print()}>Imprimir</button>
                            <ReactQuill
                                theme="snow"
                                readOnly={true}
                                value={this.state.content}
                                modules={this.modules}
                                formats={this.formats}
                            />
                            <button onClick={() => window.print()}>Imprimir</button>
             </div>
  }

}
