import React from 'react';


import FormEvent from './FormEvent';

import EventService from '../../services/EventService';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class Tasks extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);

    this.modifiedEvents = this.props.modifiedEvents.bind(this);

    this.state = {
      tasks: props.tasks,
    }

    //console.log(props.tasks);
  }

  componentWillReceiveProps(nextProps) {

      this.setState({
        tasks: nextProps.tasks
      });

  }

  editEvent = ( event ) => {
      //console.log();
      var evento = new CustomEvent("openFormEvent", { "detail": event.target.dataset.token });

      // Dispatch/Trigger/Fire the event
      document.dispatchEvent(evento);
      /*this.setState({
        token: event.target.dataset.token,
        showModal: true,
      });*/
  }

  niceCheckChange = (item, start, end, time_start, time_end, index) => {

    this.EventService = new EventService();

    let users = [];

    for(let i=0; i < item.events_users.length; i++){
      users.push( item.events_users[i].user.token );
    }

    item.checked = true;

    let tasks = this.state.tasks;
    tasks[index] = item;

    this.setState({
      tasks
    });

    let data = {
      token: item.token,
      event_situation_id: (item.events_situation.id === 2) ? 1: 2,
      date_start: start,
      date_end: end,
      time_start: time_start,
      time_end: time_end,
      name: item.title,
      description: item.description,
      priority: item.priority,
      events_users: users
    }

    this.EventService.edit( data ).then(res =>{
          if( res.success === true ){
                this.modifiedEvents();
          }
    });

  }

  infoCompromisso = (token) => {

    this.EventService = new EventService();

    this.EventService.view( token ).then(event =>{

      if( event.parent_event !== null && typeof event.parent_event !== "undefined" ){

            //console.log(event.parent_event);

            if(document.getElementById("lawsuit-"+token) !== null){
                document.getElementById("lawsuit-"+token).innerHTML = event.parent_event.lawsuit;
            }
            if(document.getElementById("title-"+token)){
              document.getElementById("title-"+token).innerHTML = event.parent_event.name;
            }
            if(document.getElementById("category-"+token)){
              document.getElementById("category-"+token).innerHTML = event.parent_event.event_category.name;
            }
            if(document.getElementById("date-"+token)){
              document.getElementById("date-"+token).innerHTML = event.parent_event.start.substr(0,10).split("-").reverse().join("/")+" "+event.parent_event.start.substr(11,5);
            }
            if(document.getElementById("sponsor-"+token)){
              document.getElementById("sponsor-"+token).innerHTML = event.parent_event.events_users[0].user.name
            }
            if(document.getElementById("obs-"+token)){
              document.getElementById("obs-"+token).innerHTML = event.parent_event.description
            }
            if(document.getElementById("agenda-"+token)){
              document.getElementById("agenda-"+token).classList.remove("hide")
            }

      }

    });

    return <div id={"agenda-"+token} className="agenda-evento-info hide">
                      <strong className="col-md-12">Correlacionada ao agendamento:</strong>
                      <div className="col-md-4">
                          <strong>Processo: </strong>
                          <span id={"lawsuit-"+token}></span>
                      </div>
                      <div className="col-md-4">
                          <strong>Título: </strong>
                          <span id={"title-"+token}></span>
                      </div>
                      <div className="col-md-4">
                          <strong>Categoria: </strong>
                          <span id={"category-"+token}></span>
                      </div>
                      <div className="col-md-4">
                          <strong>Data: </strong>
                          <span id={"date-"+token}></span>
                      </div>
                      <div className="col-md-4">
                          <strong>Responsável: </strong>
                          <span id={"sponsor-"+token}></span>
                      </div>
                      <div className="col-md-4">
                          <strong>Observação: </strong>
                          <span id={"obs-"+token}></span>
                      </div>
           </div>;
  }

  taskComponent = ( {record, index}, type ) => {

    let status = "pendente"; let start = record.start.substr(0,10); let end = record.end.substr(0,10);
    let time_start = record.start.substr(11,5); let time_end = record.end.substr(11,5);
    let status_label = "Pendente"; let status_color = "yellow"; let responsavel = "";
    let event_situation = (typeof record.events_situation !== "undefined" && record.events_situation !== null) ? record.events_situation.id : 0;
    let lawsuit = "";

    if( (event_situation === 3 || event_situation === 2 ) && typeof record.checked == "undefined"){
      record.checked = true;
    }

    if( event_situation === 3  ){
      status = "andamento";
      status_label = "Cancelada";
      status_color = "blue";
    }else if( event_situation !== 2 && ( moment() > moment(record.end).add(3, 'hours') )  ) { //add 3h pra compensar fuso horário
      status = "atrasada";
      status_label = "Atrasada";
      status_color = "red";
    }else if( event_situation === 2  ){
      status = "concluida";
      status_label = "Concluída";
      status_color = "green";
    }

    if (record.lawsuit !== null ) {
      if(record.lawsuit.name !== null){
        lawsuit = record.lawsuit.name ;
      }else{
        lawsuit = <div>
                        <strong>Processo: </strong> 
                        <span>{record.lawsuit.number} / </span>
                        <span>{record.lawsuit.tribunal.slug}</span>
                  </div>
      }

    }

    for(let i=0; i < record.events_users.length; i++){
      if( i !== 0 ){
        responsavel += ", ";
      }
      responsavel += record.events_users[i].user.name;
    }

    let className = "panel panel-featured-left panel-featured-default tarefa ";
    className += status;

    let logged_user_token = JSON.parse(localStorage.getItem('loggedUser')).token;
    let has_permission = false;

    if(record.events_users.length > 0){



      for(let i = 0; i < record.events_users.length; i++ ){

        if( (record.events_users[i].user.token === logged_user_token) || JSON.parse(localStorage.getItem('loggedUser')).admin ){
          has_permission = true;
        }
      }
    }

    return  <section className={className} key={record.token}>
                    <div className="panel-body">
                        <div className="tarefa-descricao">
                            <h4><strong>{record.title}</strong></h4>
                        </div>
                        <label className="toggle">
                              <input className="toggle__input" name="check" type="checkbox" onChange={() => {this.niceCheckChange(record, start.split("-").reverse().join("/"), end.split("-").reverse().join("/"), time_start, time_end, index)}} checked={(record.checked) ? "checked" : ""} />
                              <label htmlFor="check" className="toggle__label" onClick={ () => {this.niceCheckChange(record, start.split("-").reverse().join("/"), end.split("-").reverse().join("/"), time_start, time_end, index)} }>
                                <span className="toggle__text">Marcar como Concluída</span>
                              </label>
                        </label>
                        <div className="tarefa-rodape">
                            <div className="info-task-important">
                                    <div className="col-md-12">
                                        <strong>Responsável: </strong>
                                        <span>{responsavel}</span>
                                    </div>
                                    <div className={status_color+" col-md-3"}>
                                        <strong>Status: </strong>
                                        <span>{status_label}</span>
                                    </div>
                                    <div className="col-md-4">
                                        <strong>Data Início:</strong> <i className="fa fa-calendar"></i> {start.split("-").reverse().join("/")} <i className="fa fa-clock-o"></i> {time_start}
                                    </div>
                                    <div className="col-md-4">
                                        <strong>Data Limite:</strong> <i className="fa fa-calendar"></i> {end.split("-").reverse().join("/")} <i className="fa fa-clock-o"></i> {time_end}
                                    </div>
                                    <div className="col-md-12">
                                        {lawsuit} 
                                    </div>
                            </div>
                            {this.infoCompromisso(record.token)}
                            <span className={(has_permission) ? "pointer btn btn-info" : "hide"} data-token={record.token}  onClick={( event )=>{this.editEvent( event )} }>Editar Tarefa</span>
                        </div>
                    </div>
              </section>
  }

  render() {
    //var url_base = localStorage.getItem('url_base');
    //<FormEvent modifiedEvents={this.modifiedEvents} token={this.state.token} showModal={this.state.showModal} start={this.state.start} end={this.state.end} events={[]} />
    return (
            <div className="panel-tasks">
                  {(this.state.tasks.length > 0) ? this.state.tasks.map( (record, index) =>  this.taskComponent( {record, index}, '' ) ) : "Nenhuma tarefa encontrada"}
            </div>
    );
  }
}
