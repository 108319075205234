import AuthService from './AuthService';
import {api} from '../config';


export default class PublicationsService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.list = this.list.bind(this)
    }

    counter(){
        return this.fetch(`${this.domain}Publications/?counter=1`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res.data.Publications);
            }else{
              return Promise.resolve(res);
            }
        })
    }

    find(date_start, date_end, page=1){
        return this.fetch(`${this.domain}Publications/index?date_start=${date_start}&date_end=${date_end}&page=${page}`, {
          method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res);
            }else{
              return Promise.resolve([]);
            }
        });      
    }

    today( page = 1 ){
        return this.fetch(`${this.domain}Publications/index?today=1&page=${page}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res);
            }else{
              return Promise.resolve([]);
            }
        })
    }

    list(){
        return this.fetch(`${this.domain}PublicationsUsers/index`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res.data.PublicationsUsers);
            }else{
              return Promise.resolve(res);
            }
        })
    }

    listPublications( page = 1 ){
        return this.fetch(`${this.domain}Publications/index?page=${page}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res);
            }else{
              return Promise.resolve(res);
            }
        })
    }

    listPublicationsByLawsuit( token ){
        return this.fetch(`${this.domain}Publications?lawsuit_token=${token}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res.data.Publications);
            }else{
              return Promise.resolve([]);
            }
        })
    }

    lawsuitsNotRegistered (counter="", page=""){
        if( page !== "" ){
          page = "&page="+page;
        }else{
          page = "";
        }
        return this.fetch(`${this.domain}Publications/index?without_lawsuit=1${counter}${page}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res);
            }else{
              return Promise.resolve(res);
            }
        });    
    }

    get( token ){
        return this.fetch(`${this.domain}PublicationsUsers/view/${token}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if(res.success){
              return Promise.resolve(res.data.PublicationsUsers);
            }else{
              return Promise.resolve(res);
            }
        })
    }

    add ( data ){
      return this.fetch(`${this.domain}PublicationsUsers/add`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    edit ( token, data ){

      return this.fetch(`${this.domain}PublicationsUsers/edit/${token}`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })

    }

    delete ( token ){
      return this.fetch(`${this.domain}PublicationsUsers/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    abrangenciasList(){
        return this.fetch(`${this.domain}Abrangencias/index`, {
            method: 'GET',
        }).then(res => {
           //console.log(res);
            if(res.success){
              var response = [];
              Object.keys(res.data.Abrangencias).map((value, index, array )=>{
                //console.log(value);
                response[index] = {"value": value, "label": res.data.Abrangencias[value], };
                return response;
              });
              return Promise.resolve(response);
            }else{
              return Promise.resolve(res);
            }
        })
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
            return Promise.resolve({
              message: "Você não tem permissão para acessar o sistema neste momento."
            });
          }else{   

            return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                          if(typeof response !== "undefined"){

                            return Promise.resolve( response.text().then(function(result){
                                //console.log(result);
                                let old_result = result;
                                result = result.split('{"success"');
                                if( result.length > 1 ){
                                    result = result[1].split('<pre');
                                    //console.log(result[0]);
                                    let json = '{"success"'+result[0];
                                    result = JSON.parse(json);
                                    //console.log(result);
                                    return result;
                                }else{
                                    return old_result;
                                }

                            }) );

                        }else{

                            let result = {
                                success: false,
                                message: "Erro desconhecido"
                            }

                            return Promise.resolve( result );

                        }

                  });

          }
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
         //console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        }/*else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }*/
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
