import React from 'react';

export default class Loading extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super( props );
      this.state = {
        visible: props.visible,
        message1: props.message1,
        message2: props.message2,
        message3: props.message3
      }

      this.cancel = this.cancel.bind(this);

  }

  componentWillReceiveProps(nextProps) {

    this.setState({
      visible: nextProps.visible,
      message1: nextProps.message1,
      message2: nextProps.message2,
      message3: nextProps.message3,
    });

  }

  cancel = () => {

    if( document.getElementById("addButton") ){
        document.getElementById("addButton").disabled = false;
    }
    document.body.style.cursor = "default";

    var event = new CustomEvent("cancelLoading", { "detail": "Example of an event" });

    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(event);

    this.setState(
      {
        visible: false
      }
    )
  }


  render() {

    if ( this.state.visible === true ){
      var url_base = localStorage.getItem('url_base');
      return (
              <div id="loader-background">
                <div id="loader-container">
                  <img alt="loading" src={url_base+"/images/basicloader.gif"}></img>
                  <p >{this.state.message1}</p>
                  <p >{this.state.message2}</p>
                  { (typeof this.state.message3 !== "undefined") ? this.state.message3.split('\n').map(i => {return <p key={i+Math.random()}>{i}</p>}) : "" }
                  <span className={(this.props.cancel) ? "btn right cancel-loading" : "btn right cancel-loading hide"} onClick={this.cancel}>Cancelar</span>
                </div>
              </div>
      );
    }else{
      return null;
    }

  }
}
