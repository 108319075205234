import React from 'react';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import MyPopover from '../containers/mypopover';
import Select from 'react-select';
import { Switch } from 'antd';

import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';
import LawsuitService from '../../services/LawsuitService';
import FinanceService from '../../services/FinanceService';

import {isTablet} from '../config/mobileDetect';

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  },
}

export default class ReportFinance extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(props) {
    super(props);

    //const select = { value: '0', label: 'Todos' };
    //const fSelect = { value: '0', label: 'Todas' };

    const statusList = [
      {label: "Ativo", value: 1},
      {label: "Arquivado", value: 2},
    ];

    const typeList = [
      {label: "Processos com Saldo Positivo e Negativo", value: 1 },
      {label: "Processos com Saldo Positivo", value: 2 },
      {label: "Processos com Saldo Negativo", value: 3 },
      {label: "Provisão de Recebimento do Escritório", value: 4 },
      {label: "Provisão de Pagamento do Escritório", value: 5 },
    ];

    this.state = {
      categoryList: [],
      clientList: [],
      lawsuitList: [],
      centroCustoList: [],
      centroCusto: null,
      statusList: statusList,
      status: null,
      typeList: typeList,
      type: typeList[0],
      client: null,
      lawsuit: null,
      categoria: null,
      switchDetails: false,
      excluirRepassesFuturos: false,
      excluirRecebimentoFuturos: false,
      liquido: false
    }

    if(isTablet()){
      alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
      window.location.href = '/';
    }

  }

  componentDidMount( ) { this._isMounted = true;

    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.lawsuitService = new LawsuitService();
    this.FinanceService = new FinanceService();

      this.LawsuitPersonagesService.list( ).then(res =>{
        this.setState({
          clientList: res,
        });

      });

      this.lawsuitService.officeList().then(response =>{

        this.setState({
          lawsuitList: response
        });

      });

      this.FinanceService.categoryList().then(response =>{

        this.setState({
          categoryList: response
        });
      });

      this.FinanceService.centroCustoList().then(response =>{

        this.setState({
          centroCustoList: response,
        });

      });

  }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;

    //console.log(selected);

    if( actionMeta.name === "lawsuit" && selected !== null){
      document.getElementById("clientContainer").className += ' hide';
      document.getElementById("categoriaContainer").className += ' hide';
      document.getElementById("tipoContainer").className += ' hide';
      document.getElementById("statusContainer").className += ' hide';
      document.getElementById("centroCustoContainer").className += ' hide';
      pops['client'] = null;
    }else if( actionMeta.name === "client" ){
      pops['lawsuit'] = null;
      document.getElementById("clientContainer").classList.remove("hide");
      document.getElementById("categoriaContainer").classList.remove("hide");
      document.getElementById("tipoContainer").classList.remove("hide");
      document.getElementById("statusContainer").classList.remove("hide");
      document.getElementById("centroCustoContainer").classList.remove("hide");
    }else{
      document.getElementById("clientContainer").classList.remove("hide");
      document.getElementById("categoriaContainer").classList.remove("hide");
      document.getElementById("tipoContainer").classList.remove("hide");
      document.getElementById("statusContainer").classList.remove("hide");
      document.getElementById("centroCustoContainer").classList.remove("hide");
    }

    if ( typeof selected !== undefined && selected !== "" ) {

      this.setState( pops );

    }
  }

  onSwitchDetailsChange = (checked) => {
    if ( checked ){
      this.setState({
        switchDetails: true,
      });
    }else{
      this.setState({
        switchDetails: false,
      });
    }
  }

  onSwitchLiquidoChange = (checked) => {
    if ( checked ){
      this.setState({
        liquido: true,
        excluirRecebimentoFuturos: false,
      });
    }else{
      this.setState({
        liquido: false,
      });
    }
  }

  onSwitchExcluirRecebimentoFuturosChange = (checked) => {
    if ( checked ){
      this.setState({
        excluirRecebimentoFuturos: true,
        liquido: false,
      });
    }else{
      this.setState({
        excluirRecebimentoFuturos: false,
      });
    }
  }

  onSwitchExcluirRepassesFuturosChange = (checked) => {
    if ( checked ){
      this.setState({
        excluirRepassesFuturos: true,
      });
    }else{
      this.setState({
        excluirRepassesFuturos: false,
      });
    }
  }

  doReport = () => {

    let client = (this.state.client !== null) ? this.state.client.value : 0;
    let lawsuit = (this.state.lawsuit !== null) ? this.state.lawsuit.value : 0;
    let categoria = (this.state.categoria !== null) ? this.state.categoria.value : 0;
    let centroCusto = (this.state.centroCusto !== null) ? this.state.centroCusto.value : 0;
    let status = (this.state.status !== null) ? this.state.status.value : 0;

    this.props.history.push( '/relatorio/financeiro/listar/'+client+'/'+
    lawsuit+'/'+categoria+'/'+this.state.type.value+'/'+status+'/'+
    this.state.switchDetails+'/'+centroCusto+'/'+this.state.excluirRepassesFuturos+'/'+
    this.state.excluirRecebimentoFuturos+'/'+this.state.liquido);

  }

  render() {

      let url_base = localStorage.getItem('url_base');

      let repasses_futuros_switch_container = null; let recebimentos_futuros_switch_container = null;
      let liquido_container = null;

      if( this.state.type.value === 4 ){

        liquido_container =  <div className="futureOptions">
          <label htmlFor="minamino">
              Mostrar valores líquidos dos acordos
              
              <MyPopover icon="white" text="Aparecerá na provisão, nos casos de acordos, os valores que correspondem a parte que ficará para o escritório, para que possa ter uma visão de entrada futura." />
          </label>
          <Switch defaultChecked={this.state.liquido} checked={this.state.liquido} onChange={this.onSwitchLiquidoChange} />
        </div>;

        recebimentos_futuros_switch_container =  <div className="futureOptions">
          <label htmlFor="minamino">
              Não Mostrar recebimentos futuros de acordos
              
              <MyPopover icon="white" text="Neste caso, todos os valores de recebimento de acordos não serão considerados no relatório." />
          </label>
          <Switch defaultChecked={this.state.excluirRecebimentoFuturos} checked={this.state.excluirRecebimentoFuturos} onChange={this.onSwitchExcluirRecebimentoFuturosChange} />
        </div>;
      }

      if( this.state.type.value === 5 ){

        repasses_futuros_switch_container =  <div className="futureOptions">
          <label htmlFor="shuuichi">
              Não Mostrar repasses futuros de acordos
              
              <MyPopover icon="white" text="Os lançamentos de repasse de acordos não serão computados no relatório, pois trata-se de valores condicionados ao recebimento." />
          </label>
          <Switch defaultChecked={this.state.excluirRepassesFuturos} checked={this.state.excluirRepassesFuturos} onChange={this.onSwitchExcluirRepassesFuturosChange} />
        </div>;
      }

      return <div className="report report-finance report-event sheet">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>Relatório Financeiro</h4>
                            </div>
                      </div>
                      <div className="box-body">

                          <div className="col-md-12">
                              <h5>Filtros</h5>

                              <div className="form-group col-md-4">
                                  <label htmlFor="lawsuit">Nº do Processo</label>
                                  <Select
                                      className="lawsuit"
                                      options={this.state.lawsuitList}
                                      placeholder="Todos"
                                      name="lawsuit"
                                      isClearable={true}
                                      styles={customStyles}
                                      value={this.state.lawsuit}
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div id="clientContainer" className="form-group col-md-4 ">
                                  <label htmlFor="user" className="col-md-12">Cliente</label>
                                  <Select
                                      className="client col-md-12"
                                      options={this.state.clientList}
                                      placeholder="Todos"
                                      isClearable={true}
                                      styles={customStyles}
                                      value={this.state.client}
                                      name="client"
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div id="categoriaContainer" className="form-group col-md-4">
                                  <label htmlFor="categoria">Categoria</label>
                                  <Select
                                      className=""
                                      options={this.state.categoryList}
                                      placeholder="Todas"
                                      name="categoria"
                                      isClearable={true}
                                      styles={customStyles}
                                      value={this.state.categoria}
                                      onChange={this.selectChange}
                                    />
                              </div>

                              <div id="tipoContainer" className="form-group col-md-5">
                                  <label htmlFor="type" className="">Tipo</label>
                                  <Select
                                      className="tipo"
                                      options={this.state.typeList}
                                      name="type"
                                      value={this.state.type}
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div id="statusContainer" className="form-group col-md-3">
                                  <label htmlFor="status" >Ativos / Arquivados</label>
                                  <Select
                                      className=""
                                      options={this.state.statusList}
                                      name="status"
                                      placeholder="Todos"
                                      isClearable={true}
                                      styles={customStyles}
                                      value={this.state.status}
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div id="centroCustoContainer" className="form-group col-md-4">
                                  <label htmlFor="centroCusto">Centro de Custo</label>
                                  <Select
                                      className=""
                                      options={this.state.centroCustoList}
                                      placeholder="Todos"
                                      name="centroCusto"
                                      isClearable={true}
                                      styles={customStyles}
                                      defaultValue={this.state.centroCustoList[0]}
                                      value={this.state.centroCusto}
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div className={"switch-details col-md-9"}>
                                  <label htmlFor="details">
                                      Exibir Detalhes
                                      <MyPopover icon="white" text="Para um relatório detalhado de todas as despesas e créditos do processo. Se não ativado, serão apresentados somente os Saldos de cada processo." />
                                  </label>
                                  <Switch defaultChecked={this.state.switchDetails} checked={this.state.switchDetails} onChange={this.onSwitchDetailsChange} />
                                  {liquido_container}
                                  {repasses_futuros_switch_container}
                                  {recebimentos_futuros_switch_container}
                              </div>

                              <span className="btn btn-primary form-submit view-report right" onClick={this.doReport}>Gerar Relatório</span>

                          </div>

                      </div>
                  </div>
                </div>
             </div>
  }

}
