import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';

export default class MyPopover extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    let url_base = localStorage.getItem('url_base');
    let img = "/images/interrogation_cropped.png";

    if( this.props.icon !== "white" ){
      img = "/images/i_branco.png";
    }

    return (
            <Popover
                isOpen={this.state.isPopoverOpen}
                position={['top', 'right', 'left', 'bottom']}
                padding={10}
                onClickOutside={() => this.setState({ isPopoverOpen: false })}
                content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        targetRect={targetRect}
                        popoverRect={popoverRect}
                        arrowColor={'#94bcd8'}
                        arrowSize={10}
                    >
                        <div className="popover-box"
                            onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                        >
                              { (typeof this.props.text !== "undefined") ? this.props.text.split('\n').map(i => {return <p key={i}>{i}</p>}) : "" }
                        </div>
                    </ArrowContainer>
                )}
            >
                <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} id={this.props.id} className="help_icon " alt="ajuda" src={url_base+img}></img>
            </Popover>
    );
  }
}
