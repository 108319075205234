export default function pdfComponent ( element_id, title ) {

        let content = document.getElementById(element_id).innerHTML;
        let head = document.getElementsByTagName('head')[0].innerHTML;
        var win = window.open('', '', 'height=700,width=817');
            win.document.write('<html><head>');
            win.document.write('<title>'+title+'</title>');   // <title> CABEÇALHO DO PDF.
            win.document.write(head);                         // INCLUI UM ESTILO NA TAB HEAD
            win.document.write('</head>');
            win.document.write('<body>');
            win.document.write(content);                     // O CONTEUDO DA TABELA DENTRO DA TAG BODY
            win.document.write('</body></html>');
            win.document.close(); 	                         // FECHA A JANELA
            setTimeout( function(){
              win.print();
            },200);
  }
