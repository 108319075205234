import React, { Component } from 'react';

export default class extends Component {
  render() {
    var url_base = localStorage.getItem('url_base');
    return (
            <div id="header">
              <a href="/">
                <img alt="nosso logo - avvocato" src={url_base+"/images/logo_avvocato_white.png"}></img>
              </a>
            </div>
    );
  }
}
