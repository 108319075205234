import React from 'react';

export default class Sobre extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
      return <div>
                    <h1>Sobre</h1>
             </div>
  }

}
