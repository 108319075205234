const print = [
    {
        key:`

                  * {
                    text-shadow:none !important;
                    filter:none !important;
                    -ms-filter:none !important;
                  }
                  body {
                    margin:0;
                    padding:0;
                    line-height: 1.4em;
                    color: #000;
                    font-size: 14pt;
                  }
                  @page {
                      margin-top: 0.2cm;
                      margin-left: 0.2cm;
                      margin-right: 0.2cm;
                      margin-bottom: 1.0cm;
                  }
                  nav, footer, video, audio, object, embed {
                    display:none;
                  }
                  img {
                    max-width: 100%;
                  }
                  .wrapper {
                    background-color: #fff !important;
                    width: 90%;
                    margin: 0;
                    float: none !important;
                    margin: 1.5cm;
                  }
                  h1 {
                    font-size: 18pt;
                    font-weight: bold;
                  }
                  h2 {
                    font-size: 16pt;
                    font-weight: bold;
                  }
                  h3 {
                    font-size: 14pt;
                  }
                  h4 {
                    font-size: 11pt !important;
                  }
                  p {
                    widows: 10;
                    orphans: 5;
                    font-size: 8pt;
                  }
                  strong {
                    font-size: 7.5pt;
                  }
                  h1, h1 strong {
                    font-size: 18pt;
                    font-weight: bold;
                    margin-bottom: 10px !important;
                  }
                  button {
                      -webkit-appearance: button;
                      -webkit-writing-mode: horizontal-tb !important;
                      text-rendering: auto;
                      color: buttontext;
                      letter-spacing: normal;
                      word-spacing: normal;
                      text-transform: none;
                      text-indent: 0px;
                      text-shadow: none;
                      display: inline-block;
                      text-align: center;
                      align-items: flex-start;
                      cursor: default;
                      background-color: buttonface;
                      box-sizing: border-box;
                      margin: 0em;
                      font: 400 11px system-ui;
                      padding: 1px 7px 2px;
                      border-width: 1px;
                      border-style: solid;
                      border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
                      border-image: initial;
                      padding:7px;
                  }
                  section {
                    margin-top: 20px;
                    /*display: table;*/
                  }
                  label {
                    float: left;
                    clear: both;
                  }
                  .contato {
                    font-size: 12pt;
                    clear: both;
                    display: table;
                  }
                  .contato label {
                    font-weight: 500;
                    clear: both;
                  }
                  .topo {
                    width: 100%;
                    height: 130px;
                    min-height: 0px !important;
                    display: table;
                  }
                  .center {
                    min-height: 0px !important;
                  }
                  .right {
                    clear: both;
                    margin-right: 27px;
                  }
                  .rodape {
                    min-height: 0px !important;
                    width: 100%;
                    /*height: 50px;*/
                  }
                  .topo .image-top {
                    display: flex;
                    float: left;
                    width: 30%;
                    vertical-align: bottom;
                  }
                  .topo .image-top img {
                    margin-right: 10px;
                    border-right: 1px solid #ddd;
                    align-self: center;
                    margin-bottom: 10px;
                  }
                  .ql-editor {
                    overflow: hidden;
                  }
                  .md-chat-widget-wrapper, jdiv {
                    display: none !important;
                  }
                  .topo .headline {
                    display: flex;
                    width: 100%;
                    clear: both;
                    border-bottom: 2px solid #000;
                  }
                  .topo .office{
                    width: 70%;
                    display: table;
                  }
                  .sectionLawsuit {
                    page-break-after: always;
                    width: 100%;
                  }
                  .sub {
                    display: flex;
                    justify-content: space-between;
                    width: 99%;
                    padding: 5px;
                  }
                  .report-title{
                    align-self: flex-end;
                    margin-bottom: 5px;
                  }
                  .subtitle {
                    text-align: center;
                    background-color: #fff;
                    margin: 0;
                    padding: 0;
                  }
                  table {
                    width: 100%;
                    font-size: 9pt;
                  }
                  table p {
                    margin-bottom: 2px;
                  }
                  table .description, .table_lawsuit_number, .type_personage {
                      width: initial;
                  }
                  thead > tr > th {
                    padding: 10px;
                    border: 0;
                    background: #fff;
                  }
                  table .date {
                      vertical-align: middle;
                  }
                  td .row strong {
                    font-size: 9pt;
                  }
                  .center {
                    margin: 0 auto;
                    padding-bottom: 20px;
                  }
                  .hidden-xs {
                    display: none;
                  }
                  .row {
                    clear: both;
                  }
                  .report-lawsuit .row {
                      margin-bottom: 0px;
                  }
                  .row:before, .row:after {
                      display: none;
                  }
                  #showOnReportContainer {
                    display: none;
                  }
                  .panel-heading > .pointer:after {
                      content: "";
                  }
                  .ql-editor {
                    padding: 0px;
                  }
                  .ql-toolbar.ql-snow, .ql-container.ql-snow {
                    border: none;
                  }
                  .ql-toolbar, .ql-hidden{
                    display: none;
                  }
                  .panel-body {
                      padding: 5px;
                      orphans: 5;
                      page-break-inside: avoid;
                  }

                  .actions, .table_actions {
                      display: none;
                  }

                  @media (max-width: 767px){
                    #root {
                      overflow-x: scroll;
                    }
                  }

                  @media print {
                    body {
                      font-size: 11pt;
                    }
                    strong {
                      font-size: 7.5pt;
                    }
                    p{
                      font-size: 8pt;
                    }
                    h1, h1 strong {
                      font-size: 18pt;
                      font-weight: bold;
                      margin-bottom: 10px !important;
                    }
                    h4 {
                      font-size: 11pt !important;
                    }
                    thead { display: table-header-group !important; }
                      .rodape {
                          display: block;
                          position: fixed;
                          bottom: 0;
                      }
                      button {
                        display: none !important;
                      }
                      .print {
                        margin: 0;
                      }
                      .resultado-saldo {
                        font-size: 10px;
                      }
                      .col-sm-6 {
                        width: 50%;
                        float: left;
                      }
                      .page-header, .page-header-space {
                        height: 130px;
                        display: table;
                        width: 100%;
                      }
                      .col-md-2 {
                          width: 17.5%;
                          float: left;
                      }
                      .col-md-3 {
                          width: 25%;
                          float: left;
                      }
                      .col-md-4 {
                          width: 32.5%;
                          float: left;
                      }
                      .wrapper {
                        width: 95%;
                        margin: 0.5cm;
                      }
                      .sub {
                        display: block;
                        position: relative;
                      }
                      .report-title{
                        float: left;
                        position: absolute;
                        top: 13px;
                      }
                      a[href]:after {
                        content: none !important;
                      }
                      .ql-editor {
                        padding: 0;
                      }
                      #user-author{
                        display: none;
                      }
                  }
            `}];
export default print;
