import AuthService from './AuthService';
import {api} from '../config';
import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class EventService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.categoryList = this.categoryList.bind(this)
    }

    add ( event ){
      return this.fetch(`${this.domain}Events/add`, {
          method: 'POST',
          body: JSON.stringify(event)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    edit ( event ){
      return this.fetch(`${this.domain}Events/edit`, {
          method: 'PUT',
          body: JSON.stringify(event)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    view( token ){
      return this.fetch(`${this.domain}Events/view/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          if( res.success === true ){
              return Promise.resolve(res.data.Events);
          }else{
            return Promise.resolve([]);
          }
      })
    }

    delete ( token ){
      return this.fetch(`${this.domain}Events/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    list( start_date, end_date, user = "0" ){
        let user_token = "0";
        if( user === "0" || user === "-99" || user === "all" ){
          user = "";
        }else{
          user_token = user;
          user = "&user_token="+user;
        }

        //console.log(user);
        return this.fetch(`${this.domain}Events/index/?start=${start_date}&end=${end_date}${user}&event_type_id=1`, {
            method: 'GET',
        }).then(res => {
            let response = [];
            //console.log(res);
            if( res.success === true ){
              var i = 0; var token = "";
              res.data.Events.map((value, index, array )=>{
                if( value.token !== token ){

                      if( user_token !== "0" && user_token !== "-99" ){
                        value.events_users.map( ( event_user )=>{


                              if( event_user.user.token === user_token ) {
                                  var date_end = moment(value.end);
                                  var date_start = moment(value.start);

                                  date_end = new Date(date_end.year(), date_end.month(), date_end.date(), date_end.hour()+3, date_end.minute(), 0);
                                  date_start = new Date(date_start.year(), date_start.month(), date_start.date(), date_start.hour()+3, date_start.minute(), 0);

                                  let category = ( value.event_category !== null && typeof value.event_category !== undefined ) ? value.event_category.name : "";

                                  let event_user = "";
                                  if(value.events_users.length > 0){

                                  if( value.events_users.length >= 2 ) {
                                    event_user = value.events_users[0].user.name+" (e outros)";
                                  }else{
                                    event_user = value.events_users[0].user.name;
                                  }

                                }

                                //let title = ( category !== "" ) ? event_user+" - "+category+" - "+value.title : event_user+" - "+value.title;
                                let title = ( category !== "" ) ? event_user+" - "+value.title+" \n \n"+category+" - "+value.description : event_user+" - "+value.title+" \n \n"+value.description;

                                  let hexColor = "";
                                  //console.log(value.events_situation);
                                  if(value.events_situation.id == 1){
                                    hexColor = "#0052CC";
                                  }
                                  if(value.events_situation.id == 2){
                                    hexColor = "rgb(46 208 27)";
                                  }
                                  if(value.events_situation.id == 3){
                                    hexColor = "#000";
                                  }

                                let hexColorPriority = "";
                               //console.log(value.priority);

                                  if(value.priority == 1){
                                    hexColorPriority = "yellow";
                                  }
                                  if(value.priority == 2){
                                    hexColorPriority = "#e69809";
                                  }
                                  if(value.priority == 3){
                                    hexColorPriority = "#FF5630";
                                  }

                                  response[i] = { "key": array.length-i, "title": title, "start": date_start, "end": date_end,
                                  "allDay": value.allDay, "token": value.token, "date_start": value.start, "date_end": value.end,
                                  "events_users": value.events_users, "hexColor": hexColor, hexColorPriority };

                                  i++;
                                  token = value.token;

                              }

                              return response;

                        });
                      }else{

                                var date_end = moment(value.end);
                                var date_start = moment(value.start);

                                date_end = new Date(date_end.year(), date_end.month(), date_end.date(), date_end.hour()+3, date_end.minute(), 0);
                                date_start = new Date(date_start.year(), date_start.month(), date_start.date(), date_start.hour()+3, date_start.minute(), 0);

                                let category = ( value.event_category !== null && typeof value.event_category !== undefined ) ? value.event_category.name : "";

                                let event_user = "";
                                if(value.events_users.length > 0){

                                  if( value.events_users.length >= 2 ) {
                                    event_user = value.events_users[0].user.name+" (e outros)";
                                  }else{
                                    event_user = value.events_users[0].user.name;
                                  }

                                }

                                //let title = ( category !== "" ) ? event_user+" - "+category+" - "+value.title : event_user+" - "+value.title;
                                let title = ( category !== "" ) ? event_user+" - "+value.title+" \n \n"+category+" - "+value.description : event_user+" - "+value.title+" \n \n"+value.description;

                                let hexColor = "";
                                  //console.log(value.events_situation);
                                  if(value.events_situation.id == 1){
                                    hexColor = "#0052CC";
                                  }
                                  if(value.events_situation.id == 2){
                                    hexColor = "rgb(46 208 27)";
                                  }
                                  if(value.events_situation.id == 3){
                                    hexColor = "#000";
                                  }

                                let hexColorPriority = "";
                                 //console.log(value.priority);

                                  if(value.priority == 1){
                                    hexColorPriority = "yellow";
                                  }
                                  if(value.priority == 2){
                                    hexColorPriority = "#e69809";
                                  }
                                  if(value.priority == 3){
                                    hexColorPriority = "#FF5630";
                                  }

                                response[i] = { "key": array.length-i, "title": title, "start": date_start, "end": date_end,
                                "allDay": value.allDay, "token": value.token, "date_start": value.start, "date_end": value.end,
                                "events_users": value.events_users, hexColor, hexColorPriority };

                                i++;
                                token = value.token;
                                return response;

                      }
                  }
                  return value;
              });
                //console.log(response);
                return Promise.resolve(response);
            }

            return Promise.resolve(res);
        })
    }

    listTasks( start_date, end_date, user="0" ){ //&sort=completed&direction=ASC
        let user_token = "0";
        if( user === "0" || user === "-99" ){
          user = "";
        }else{
          user_token = user;
          user = "&user_token="+user;
        }
        return this.fetch(`${this.domain}Events/index/?start=${start_date}&end=${end_date}${user}&event_type_id=2&sort=start&direction=asc`, {
            method: 'GET',
        }).then(res => {
            let response = []; var i = 0;
            //console.log(res);
            if( res.success === true ){

              var token = [];
              res.data.Events.map((value, index, array )=>{
                      if( !token.includes(value.token) ){
                            if( user_token !== "0" && user_token !== "-99" ){
                                  value.events_users.map( ( event_user )=>{
                                        if( event_user.user.token === user_token ) {
                                                response[i] = value;
                                                token.push(value.token);
                                                i++;
                                                return response;
                                        }
                                  });
                            }else{
                                  response[i] = value;
                                  token.push(value.token);
                                  i++;
                                  return response;
                            }
                          return value;
                      }
                });

                return Promise.resolve(response);
              }

            return Promise.resolve(res);
        })
    }

    listNotifications( start_date, end_date, token ){ //&sort=completed&direction=ASC

      if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
        return Promise.resolve({
           message: "Você não tem permissão para acessar o sistema neste momento."
        });
      }else{

        let eventNotifications = JSON.parse(localStorage.getItem('eventNotifications'));
        if( 
          ( eventNotifications == null || 
            eventNotifications == 0 || 
            eventNotifications == "undefined" ||
            typeof eventNotifications.date == "undefined" 
          ) || 
            moment().diff(eventNotifications.date, 'minutes',true ) > 120 
        ){

            let users = [];
            console.log('emptyEventNotifications', eventNotifications);

            return this.fetch(`${this.domain}Events/index/?start=${start_date}&end=${end_date}&event_type_id=3&user_token=${token}`, {
            //return this.fetch(`${this.domain}Events/index/?event_type_id=3&user_token=${token}`, {
                method: 'GET',
            }).then(res => {
                let response = []; var i = 0; var event_token = "";
                console.log(res);
                if( res.success === true ){

                    res.data.Events.map((value, index, array )=>{
                      //console.log(token);
                      //console.log(value.events_users[0].user.token);
                      //console.log(value.events_situation);

                      if( value.token !== event_token && value.events_users.length > 0 && value.events_users[0].user.token === token && value.events_situation !== null && value.events_situation.id !== 3 ){

                                //let date_end = new Date(value.end);
                                //let date_start = new Date(value.start);

                                response[i] = {
                                  "raw": value,
                                  "key": index,
                                  mensagem: value.description,
                                  de: value.user.name,
                                  data: value.start.substr(0,10).split("-").reverse().join("/"),
                                  "token": value.token,
                                  events_situation: value.events_situation
                                };

                                event_token = value.token;
                                i++;
                                return response;
                        }
                        return value;
                    });

                    users.push({"user_token": token, "notifications" : response});

                    console.log('userNotifications', users);

                    localStorage.setItem('eventNotifications', JSON.stringify( { users: users, date: moment() } ) );

                    return Promise.resolve(response);
                }else if( res.message === "Você não tem permissão para acessar o sistema neste momento."){
                    //alert(res.message);
                    window.location.href = localStorage.getItem('url_base')+'/access-denied';
                }

                return Promise.resolve(res);
            });

        }else{
          let users = eventNotifications.users;
          let user = users.find( element => element.user_token == token);
          console.log('getEventNotifications', eventNotifications);
          return Promise.resolve(user.notifications);
        }
        
      }
    }

    deleteNotification ( data ){
      return this.fetch(`${this.domain}Events/edit`, {
          method: 'PUT',
          body: JSON.stringify(data)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
      /*return this.fetch(`${this.domain}Events/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })*/
    }

    changeKeepNotification ( data ){

      return this.fetch(`${this.domain}Events/edit`, {
          method: 'PUT',
          body: JSON.stringify(data)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    changeDeleteNotification ( data ){

      return this.fetch(`${this.domain}Events/edit`, {
          method: 'PUT',
          body: JSON.stringify(data)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }


    listByUser( user_token, start_date, end_date ){
        return this.fetch(`${this.domain}Events/index/?user_token=${user_token}&start=${start_date}&end=${end_date}`, {
            method: 'GET',
        }).then(res => {
            let response = [];
            //console.log(res);
            if( res.success === true ){

              var token = ""; var i = 0;
              res.data.Events.map((value, index, array )=>{
                if( value.token !== token ){
                  response[i] = value;
                  token = value.token;
                  i++;
                  return response;
                }
                return value;
              });

                return Promise.resolve(response);
            }

            return Promise.resolve(res);
        })
    }

    listByLawsuit( lawsuit_token, start_date, end_date ){
        return this.fetch(`${this.domain}Events/index/?lawsuit_token=${lawsuit_token}&start=${start_date}&end=${end_date}`, {
            method: 'GET',
        }).then(res => {
            let response = [];
            if( res.success === true ){
              var i = 0; var token = "";
              res.data.Events.map((value, index, array )=>{

                  if( value.token !== token ){

                    var date_end = moment(value.end);
                    var date_start = moment(value.start);

                    date_end = new Date(date_end.year(), date_end.month(), date_end.date(), date_end.hour()+3, date_end.minute(), 0);
                    date_start = new Date(date_start.year(), date_start.month(), date_start.date(), date_start.hour()+3, date_start.minute(), 0);


                      response[i] = { "key": array.length-i, "title": value.title, "start": date_start, "end": date_end, date_start: value.start, date_end: value.end,
                      "allDay": value.allDay, "token": value.token, "description": value.description, "event_category": value.event_category,
                      "priority": value.priority, "events_situation": value.events_situation, "events_users": value.events_users };
                      i++;
                      token = value.token;
                      return response;

                  }
                  return value;

              });
                return Promise.resolve(response);
            }

            return Promise.resolve(res);
        })
        /*return this.fetch(`${this.domain}Events/index/?start=2019-05-25&end=2019-07-07`, {
            method: 'GET',
        }).then(res => {
            let response = [];
            //console.log(res);
            if( res.success === true ){
              var i = 0;
              res.data.Events.map((value, index, array )=>{
                var date_end = new Date(value.end)
                var date_start = new Date(value.start)
                response[index] = { "key": array.length-i, "title": value.title, "start": date_start, "end": date_end,
                "allDay": value.allDay, "token": value.token };
                i++;
                return response;
              });
                return Promise.resolve(response);
            }

            return Promise.resolve(res);
        })*/
    }

    check_event_situation(event_situation_id, event){
      if(event_situation_id !== "0"){
        return ( event.events_situation.id === parseInt( event_situation_id ) ) ? true : false;
      }else{
        return true;
      }
    }

    report( start_date, end_date, evento, status, priority, user, category, sponsorLawsuit, client, lawsuit ){
        //console.log(start_date);
        let user_token = "0"; let event_situation_id = "0";

        if( start_date === "0" ){
          start_date = "?start=2019-01-01";
        }else{
          start_date = "?start="+start_date.split("-").reverse().join("-");
        }
        if( status === "0" ){
          status = "";
        }else{
          event_situation_id = status;
          status = "&event_situation_id="+status;
        }
        if( end_date === "0" ){
          end_date = "";
        }else{
          end_date = "&end="+end_date.split("-").reverse().join("-");
        }
        if( evento === "0" ){
          evento = "";
        }else{
          evento = "&event_type_id="+evento;
        }
        if( priority === "0" ){
          priority = "";
        }else{
          priority = "&priority="+priority;
        }
        if( user === "0" || user === "-99" || user === "all" ){
          user = "";
        }else{
          user_token = user;
          user = "&user_token="+user;
        }
        if( category === "0" ){
          category = "";
        }else{
          category = "&event_category_token="+category;
        }
        if( sponsorLawsuit === "0" ){
          sponsorLawsuit = "";
        }else{
          sponsorLawsuit = "&responsible_token="+sponsorLawsuit;
        }
        if( client === "0" ){
          client = "";
        }else{
          client = "&personage_token="+client;
        }
        if( lawsuit === "0" ){
          lawsuit = "";
        }else{
          lawsuit = "&lawsuit_token="+lawsuit;
        }


        if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
          return Promise.resolve({
            message: "Você não tem permissão para acessar o sistema neste momento."
          });
        }else{
  
          return this.fetch(`${this.domain}Events/index/${status}${start_date}${end_date}${evento}${priority}${user}${category}${sponsorLawsuit}${client}${lawsuit}`, {
              method: 'GET',
          }).then(res => {
                  let response = [];
                  //console.log(res);
                  if( res.success === true ){

                    var token = ""; var i=0;
                    res.data.Events.map((value, index, array )=>{
                      if( value.token !== token ){
                        //checa se precisa filtrar por usuário
                        if( user_token !== "0" ){
                          value.events_users.map( ( event_user )=>{

                            /*console.log(event_user.user);
                          //console.log(event_user.user.token);
                          //console.log("user: "+user);*/

                            if( event_user.user.token === user_token && this.check_event_situation(event_situation_id, value) ){
                              response[i] = value;
                              i++;
                              token = value.token;
                            }
                            return event_user;

                          });
                        }else{
                          if( this.check_event_situation(event_situation_id, value) ){
                                response[i] = value;
                                i++;
                                token = value.token;
                          }
                        }
                      }
                      return response;
                    });

                      return Promise.resolve( response.reverse() );
                  }else{
                      return Promise.resolve([]);
                  }
          });

        }
    }

    categoryList ( ) {
      return this.fetch(`${this.domain}EventCategories`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.EventCategories.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.token, "label": value.name };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    situationsList () {
      return this.fetch(`${this.domain}EventsSituations/index`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          /*if( res.success === true ){
            var i = 0;
            res.data.EventCategories.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.token, "label": value.name };
              i++;
              return response;
            });
          }*/

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addCategory ( category ){
      return this.fetch(`${this.domain}EventCategories/add`, {
          method: 'POST',
          body: JSON.stringify(category)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteCategory ( token ){
      return this.fetch(`${this.domain}EventCategories/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

           if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
            return Promise.resolve({
              message: "Você não tem permissão para acessar o sistema neste momento."
            });
          }else{
    

            return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                            if(typeof response !== "undefined"){

                              return Promise.resolve( response.text().then(function(result){
                                  //console.log(result);
                                  let old_result = result;
                                  result = result.split('{"success"');
                                  if( result.length > 1 ){
                                      result = result[1].split('<pre');
                                      //console.log(result[0]);
                                      let json = '{"success"'+result[0];
                                      result = JSON.parse(json);
                                      //console.log(result);
                                      return result;
                                  }else{
                                      return old_result;
                                  }

                              }) );

                          }else{

                              let result = {
                                  success: false,
                                  message: "Erro desconhecido"
                              }

                              return Promise.resolve( result );

                          }

                  })

          }
    }

    _checkStatus(response) {
          // raises an error in case response status is not a success
          if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
              return response
          } else if( response.status === 401 || response.status === "401" ) {
           //console.log("Tentando trapacear? Você será desligado");


            localStorage.removeItem('id_token');
            localStorage.removeItem('loggedUser');
            window.location.href = '/login';

          }else {

             //console.log(response);
              return response;
              /*var error = new Error(response.statusText)
              error.response = response
              throw error*/
          }
      }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
