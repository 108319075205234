const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
  };

export default function notifications(state = INITIAL_STATE, action) {
    //console.log(action);
    switch (action.type) {
        case 'REQUEST_NOTIFICATIONS':
            return { ...state, loading: true };
        case 'SUCCESS_NOTIFICATIONS':
            return { data: action.payload.data, loading: false, error: false };
        case 'FAILURE_NOTIFICATIONS':
            return { data: [], loading: false, error: true };
        default:
            return state;
    }
}