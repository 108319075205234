import React, { Component } from 'react';

export default class extends Component {

  constructor( props ){
      super();
      let check = false;
      if( typeof props.check !== "undefined"){
        check = props.check;
      }

      this.state = {
          permission: props.permission,
          switch: check
      }
  }

  componentWillReceiveProps(nextProps) {

      if ( nextProps.permission !== this.props.permission ) {

        this.setState({
          permission: nextProps.permission,
        });

      }

      if ( nextProps.check !== this.props.check ) {

        let check = this.props.check;
        if( typeof nextProps.check !== "undefined"){
          check = nextProps.check;
        }

        this.setState({
          switch: check,
        });

      }
  }

  onChange = (event) => {
    const target = event.target;
    if ( target.checked ){
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: true,
      });
    }else{
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: false,
      });
    }
  }

  render() {

    return (
            <input type="checkbox" title={(this.state.switch)? "Desbloqueado": "Bloqueado"} id={this.state.permission} checked={this.state.switch} onChange={ this.onChange }  />
    );
  }
}
