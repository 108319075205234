import React from 'react';
import LawsuitPersonagesService from '../../../services/LawsuitPersonagesService';

export default class ClientInfos extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);
    this.LawsuitPersonagesService = new LawsuitPersonagesService();

    this.state = {
      client: { personage: [] }
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props ) {

      this.LawsuitPersonagesService = new LawsuitPersonagesService();

      if ( typeof nextProps.client !== "undefined" ) {

            this.LawsuitPersonagesService.view( nextProps.client.personage.token ).then( personage =>{

              this.setState({
                client: personage,
              });

            });

      }

    }
  }

  componentDidMount( ) { this._isMounted = true;

    this.LawsuitPersonagesService = new LawsuitPersonagesService();

    if ( typeof this.props.client !== "undefined" ) {
      this.LawsuitPersonagesService.view( this.props.client.personage.token ).then( personage =>{

        this.setState({
          client: personage,
        });

      });
    }
  }

  contactContainer = ({ contact, index }) => {

      if ( contact.value.indexOf("@") > -1 ) {
        return (
          <span key={index} className="horizontal-space">
              <small><i className="fa fa-envelope"></i> {contact.value} </small><span className="">/</span>
          </span>
        );
      }else {
        return (
          <span key={index}>
              <small><i className="fa fa-phone"></i> {contact.value} </small><span className="">/</span>
          </span>
        );
      }

  };

  addressContainer = ({ address, index }) => {

      if ( index === 0 ) {
        return (
          <span key={index}>
              <span className="horizontal-space"><small><i className="fa fa-map-marker"></i> {address.street}, {address.number} - {address.neighbor} - {(address.city !== null) ? address.city.name : ""} /{ (address.state !== null ) ? address.state.uf : ""}</small></span>
          </span>
        );
      }else {
        return "";
      }
      //console.log("xis");
  };

  render() {

          let firstClient;

          firstClient = this.state.client;

          return (

            <div>
              {
                ( typeof firstClient.personage_contacts !== "undefined" ) ? firstClient.personage_contacts.map( (contact, index) =>
                  this.contactContainer({ contact, index }) ) : ""
              }
              {
                ( typeof firstClient.personage_addresses !== "undefined" ) ? firstClient.personage_addresses.map( (address, index) =>
                  this.addressContainer({ address, index }) ) : ""
              }
            </div>
          )


  }
}
