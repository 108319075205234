import React from 'react';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';
import { Modal, Button } from 'antd';
import Select from 'react-select';
import Loading from '../containers/loading';
import Confirm from '../containers/confirm';
import IntlCurrencyInput from "react-intl-currency-input";
import FlashMessage from '../containers/FlashMessage';
import FinanceService from '../../services/FinanceService';
import LawsuitService from '../../services/LawsuitService';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

/*const formaPagamentoList = [
  {value: '1', label: 'Dinheiro'},
  {value: '2', label: 'Cartão'},
  {value: '3', label: 'Cheque'},
  {value: '4', label: 'Boleto'},
  {value: '6', label: 'Em Carteira'},
  {value: '7', label: 'Depósito / Transferência'},
  {value: '5', label: 'Outros'},
]*/

export default class BaixarEntrada extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(props) {
    super(props)

    this.modifiedIncoming = this.props.modifiedIncoming.bind(this);
    this.moeda = this.moeda.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.baixar = this.baixar.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.FinanceService = new FinanceService();

    this.state = {
      loading: false,
      visible: false,
      valor: 0,
      acordo: 0,
      reembolso: 0,
      data_pagamento: null,
      errors: {},
      loadingVisible: false,
      loadingMessage: [],
      forma_pagamento: null,
      formaPagamentoList: [],
      showConfirm: false,
      showModal: false,
      message: "",
    }
  }

  componentWillReceiveProps(nextProps) {

    //console.log(nextProps);

    if ( nextProps.token !== this.props.token || nextProps.showModal !== this.state.showModal ) {
      this.setState({
        token: nextProps.token,
        visible: nextProps.showModal,
      });

      setTimeout(() => {        
        this.showModal();
      }, 200);
    }

  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        this.handleCancel(e, true);
      }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.lawsuitService = new LawsuitService();
    this.FinanceService = new FinanceService();

      this.lawsuitService.officeList().then(response =>{

        this.setState({
          lawsuitList: response
        });
      });

      this.FinanceService.listFinancialPaymentOptions().then(response =>{
          this.setState({
            formaPagamentoList: response
          });
      });

  }

  componentDidUpdate(){

  }

  clearFields(){
    this.setState(
      {
        loading: false,
        visible: false,
        data_pagamento: null,
        errors: {},
        loadingVisible: false,
        loadingMessage: [],
        forma_pagamento: null,
        valor: 0,
        acordo: 0,
        reembolso: 0,
      }
    );
  }

  baixar(){

        this.FinanceService = new FinanceService();

        let forma_pagamento = null; let data_pagamento = null;


        if ( typeof this.state.forma_pagamento !== "undefined" && this.state.forma_pagamento !== null ) {
          forma_pagamento = this.state.forma_pagamento.value;
        }

        if ( typeof this.state.data_pagamento !== "undefined" ) {
          data_pagamento = this.state.data_pagamento;
        }

        let data = {
          "data_pagamento": data_pagamento.substr(0, 10).split('/').reverse().join('-')+" 00:00",
          "financial_payment_option_token": forma_pagamento,
          /*"token": this.state.token,
          "lawsuit": this.state.lawsuit,
          "descricao": this.state.descricao,
          "data_vencimento": this.state.data_vencimento,
          "centroCusto": this.state.centroCusto,
          "cliente": this.state.cliente,
          "categoria": this.state.categoria,
          "parcelas": this.state.parcelas,
          "valor": this.state.valor,
          "reembolso": this.state.reembolso,
          "baixa": 1,*/
          "pago": 1,
          /*"acordo": this.state.acordo*/
        }

        this.FinanceService.editIncoming( this.state.token, data ).then(res =>{

              if( res.success === true ){
                    localStorage.setItem('alert_message', "Baixa efetuada com sucesso");
                    localStorage.setItem('alert_type', 'alert-success');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );

                    this.modifiedIncoming();
              }else {
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-danger');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
              }

        });


  }

  moeda( event, value, maskedValue ) {
    event.preventDefault();

    this.setState(
        {
            [event.target.name]: value
        }
    )
 }

   onDatePickerChange = (date, dateString, name) => {

     if (dateString === "" ){
       dateString = null;
     }

      this.setState(
          {
              [name]: dateString,
          }
      )

    }

    showModal = () => {

      //console.log(this.state.visible);

        // Formulário de edição
        if( this.state.token !== "" && typeof this.state.token !== "undefined" ){

          this.FinanceService = new FinanceService();
          this.FinanceService.viewIncoming( this.state.token ).then(res => {

            if(res !== null){
              //console.log( res );

              this.setState({
                forma_pagamento: this.state.formaPagamentoList.filter( (pag)=>{ return pag.value === res.financial_payment_option.token })[0],
                valor: res.valor,
                "token": res.token,
                //"lawsuit": res.lawsuit,
                //"descricao": res.descricao,
                //"data_vencimento": res.data_vencimento.substr(0, 10).split('-').reverse().join('/'),
                //"centroCusto": res.centroCusto,
                //"categoria": res.categoria,
                //"parcelas": res.parcelas,
                //"reembolso": res.reembolso,
                //"acordo": ( typeof res.acordo !== "undefined" ) ? res.acordo : 0,
                labelSaveButton: "Dar Baixa",
                titleModal: "Baixa no Lançamento",
                visible: true
              });
            }

          });

        }

    };

    handleOk = () => {
        if (this.validateForm()) {

          this.setState({
            loadingVisible: true,
            loading: true,
            loadingMessage: ["A baixa está sendo lançada.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          });

          this.baixar();

            setTimeout( () => {
              this.clearFields();
            }, 3000);

        }

    };

    handleCancel = ( event, r = false ) => {
        //window.confirm("As alterações serão perdidas, deseja realmente sair?") 
        if( r ){
            this.clearFields();

        }else{
          this.setState({
            showConfirm: true,
            message: "As alterações serão perdidas, deseja realmente sair?"
          });
        }
    };

    handleChange = (e) => {

            this.setState(
                {
                    [e.target.name]: e.target.value
                }
            )

     }

     selectChange = (selected, actionMeta) => {
       let pops = [];
       pops[ actionMeta.name ] = selected;

       if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

         if ( typeof selected !== undefined && selected !== "" ) {
           this.setState( pops );
         }

       }
     }


    validateForm = () => {
        let errors = {};
        let formIsValid = true; 
        //console.log(this.state.forma_pagamento);

        if ( this.state.data_pagamento === 0 || this.state.data_pagamento === "" || typeof this.state.data_pagamento === "undefined" || this.state.data_pagamento === null ) {
          formIsValid = false;
          errors["data_pagamento"] = "*Preencha a data";
        }

        if ( typeof this.state.forma_pagamento === "undefined" || this.state.forma_pagamento === null ) {
          formIsValid = false;
          errors["forma_pagamento"] = "*Escolha uma forma de pagamento";
        }

        this.setState({
           errors: errors
         });

        return formIsValid;

    }

  render() {

      return <div>
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <Modal
                  visible={this.state.visible}
                  title={this.state.titleModal}
                  width="875px"
                  onOk={this.handleOk}
                  onCancel={(event)=>{this.handleCancel(event)}}
                  footer={[
                    <Button key="submit" id="handleOk" type="primary" loading={this.state.loading} onClick={this.handleOk}>
                      {this.state.labelSaveButton}
                    </Button>,
                    <Button key="back" id="close" className="btn-danger" onClick={(event)=>{this.handleCancel(event)}}>
                      Fechar
                    </Button>,
                  ]}
                >

                  <div id="lancamento-container" className="fieldset">
                        <div className="form-group col-md-3 text-left">
                            <label htmlFor="data_pagamento" className="control-label">Data do Pagamento<span className="text-red">*</span></label>
                            <DatePicker locale={locale} className="data_pagamento" placeholder="Data do Pagamento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.data_pagamento !== null ) ? moment(this.state.data_pagamento, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "data_pagamento")} />
                            <div className="errorMsg">{this.state.errors.data_pagamento}</div>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="cliente">Forma de Pagamento<span className="text-red">*</span></label>
                            <Select
                                styles={customStyles}
                                className="forma-pagamento"
                                options={this.state.formaPagamentoList}
                                placeholder="Selecione"
                                value={this.state.forma_pagamento}
                                name="forma_pagamento"
                                onChange={this.selectChange}
                              />
                              <div className="errorMsg">{this.state.errors.forma_pagamento}</div>
                        </div>
                        <div className="form-group col-md-3 text-left">
                              <label htmlFor="valorTotal" className="">Valor Pago<span className="text-red">*</span></label>
                              <IntlCurrencyInput disabled={true} className="form-control" currency="BRL" config={currencyConfig}
                               name="valor" id="valor" value={this.state.valor.toFixed(2)} onChange={this.moeda} />
                              <div className="errorMsg">{this.state.errors.valor}</div>
                        </div>
                    </div>
                </Modal>
             </div>
  }

}
