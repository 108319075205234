import React from 'react';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";

export default class ConfigAccount extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {

      return <div className="list-lawsuit">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Configurações</h1>
                        <p>Dados da Conta</p>
                      </section>
                      <div className="box-body">
                      </div>
                  </div>
                </div>
             </div>
  }

}
