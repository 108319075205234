import React from 'react';

export default class INSSSection extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {

    /*

    <div className="col-md-3"><strong>Órgão atual: </strong></div>
    <div className="col-md-3">{this.props.lawsuit.orgao_atual}</div>

    <div className="">
        <div className="col-md-4"><strong>Agência da Previdência Social de origem: </strong></div>
        <div className="col-md-3">{this.props.lawsuit.agencia_origem}</div>
    </div>
    */


      return (
        <div className="form-group col-md-4 text-left beneficio">
                    <label className="">Número do Benefício:</label>
                    <span >{this.props.lawsuit.input1}</span>
        </div>
      )

  }
}
