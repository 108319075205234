import React from 'react';
//import Pagination from "react-js-pagination";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { DatePicker } from 'antd'; 
import locale from 'antd/es/date-picker/locale/pt_BR';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from "./containers/footer";
import PrintComponent from './reports/printComponent';
import Loading from './containers/loading';
import MyPopover from './containers/mypopover';

import PublicationsService from '../services/PublicationsService';
import AuthService from '../services/AuthService';
import LawsuitService from '../services/LawsuitService';

import autocomplete from '../assets/static/js/autocomplete.js';
import '../assets/static/css/autocomplete.css';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class Publications extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;
    this.lawsuitService = new LawsuitService();

    this.getPublications( this.state.activePage );  

    autocomplete(document.getElementById("lawsuitInput"), this.state.lawsuitTips, this, 'lawsuit');
    
    /*this.lawsuitService.officeList().then(response =>{

      this.setState({
        lawsuitList: response
      });

    });*/

  }

  constructor( props ) {
      super(props);

      this.authService = new AuthService();

      this.back = this.back.bind(this);
      this.modifiedViewPrint = this.modifiedViewPrint.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.search = this.search.bind(this);
      this.clear = this.clear.bind(this);
      this.backToTop = this.backToTop.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.tip = this.tip.bind(this);

      this.state = {
        date_start: null,
        date_end: null,
        search: 0,
        qtd: 0,
        print: 0,
        token: "",
        start: "",
        end: "",
        hoje: [],
        loadingVisible: false,
        unvisualized: 0,
        nao_cadastrados: [],
        loadingMessage: [],
        outras: [],
        publications: [],
        lawsuitTips: [],
        activePage: 1,
        //lawsuitList: [],
        pubSearched: [],
        lawsuit: "",
        search_pagination: 1,
        search_last_page: false,
        search_count: 0,
        total_today_page_count: 100
      }

      if( !this.authService.can('publicacoes_visualizar_grafico', true, 'publicacoes') ){
        this.props.history.replace('/');
      }

      window.addEventListener('scroll', ()=>{
        if( this._isMounted ) {

            let scrolled = window.pageYOffset;
            let coords = document.documentElement.clientHeight;
            let goTopBtn = document.querySelector('.back-top');
        
            if (scrolled > coords) {
              goTopBtn.classList.add('show');
            }
            if (scrolled < coords) {
              goTopBtn.classList.remove('show');
            }
        }
      });

  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.match.params !== this.props.match.params) {
      this.initParams( nextProps.match.params );
    }else{
      this.initParams( this.props.match.params );
    }

  }

  getPublications ( page=1 ) {

    let lawsuitTips = this.state.lawsuitTips;

    this.PublicationsService = new PublicationsService();

    this.PublicationsService.lawsuitsNotRegistered( "", page ).then( response =>{

      //console.log(response);

        if( this._isMounted && response.success != false) {
          let nao_cadastrados = this.state.nao_cadastrados;
          for(let i=0; i<response.data.Publications.length; i++){

            if( !lawsuitTips.includes( response.data.Publications[i].numProcesso ) ){
              lawsuitTips.push( response.data.Publications[i].numProcesso );
            }

 /*           if( moment( response.data.Publications[i].dataPublicacao.substr(0,10) ).isSameOrAfter(moment(), 'day') ){

            }else{*/
              nao_cadastrados.push( response.data.Publications[i] );
            //}
    
          }
          this.setState({
            nao_cadastrados
          });
        }

    });

    if( page <= this.state.total_today_page_count){    
      this.PublicationsService.today( page ).then( response =>{

        //console.log(response);
        let hoje = this.state.hoje; 

        if( typeof response.data !== "undefined" && typeof response.data.Publications !== "undefined" ){
          for(let i=0; i<response.data.Publications.length; i++){
              hoje.push( response.data.Publications[i] );
              if( !lawsuitTips.includes( response.data.Publications[i].numProcesso ) ){
                lawsuitTips.push( response.data.Publications[i].numProcesso );
              }
          }
        }

          if( this._isMounted ) {

            this.setState({
              hoje,
              total_today_page_count: response.pagination.pageCount
            });

          }

      });

    }

    this.PublicationsService.listPublications( page ).then( response =>{

      let outras = this.state.outras; let pub = this.state.publications;
      if( this._isMounted && response.success != false) {
        for(let i=0; i<response.data.Publications.length; i++){
            if( !lawsuitTips.includes( response.data.Publications[i].numProcesso ) ){
              lawsuitTips.push( response.data.Publications[i].numProcesso );
            }

            if( moment( response.data.Publications[i].dataPublicacao.substr(0,10) ).isSameOrAfter(moment(), 'day') ){
              //console.log("oi");
              //hoje.push(response.data.Publications[i]);
            }else{
              outras.push(response.data.Publications[i]);
            }

        }
        this.setState({
          publications: pub,
          outras,
          total_count: response.pagination.pageCount,
          per_page: 20,
          lawsuitTips
        });

        if( response.data.Publications.length > 0 && page < 10 ){
          let pagination = page+1;

          this.getPublications( pagination );
        }
      }

    });

  }

  clear = ( event ) => {

    this.setState({
      pubSearched: [],
      search_pagination: 1
    });
  }

  search (page=1) {
    this.PublicationsService = new PublicationsService();
    let pagination = 1;

    let pubSearched = this.state.pubSearched;
    let pubSearchedCount = 0;

    let date_start = this.state.date_start;
    let date_end = this.state.date_end;

    if(page === 1){
      pubSearched = [];
      this.setState( {
        loadingVisible: true,
        loadingMessage: ["Pesquisando.", "Por favor, aguarde...", ]
      })
    }

    //}else{
      if( this.state.date_start === null || this.state.date_end === null ){
        date_start = moment().subtract(90, 'days').format('YYYY-MM-DD');
        date_end = moment().add(5, 'days').format('YYYY-MM-DD');
      }else{

        date_start = this.state.date_start.split('/').reverse().join('-');
        date_end = this.state.date_end.split('/').reverse().join('-');

      }
        this.PublicationsService.find( date_start, date_end, page ).then( response =>{

          //console.log(response);
    
            if( this._isMounted ) {
    
              for(let i=0; i<response.data.Publications.length; i++){

                if( this.state.lawsuit.length >= 3 ){ 
                  if( response.data.Publications[i].numProcesso == this.state.lawsuit ){
                    pubSearched.push(response.data.Publications[i]);
                  }
                }else{    
                    pubSearched.push(response.data.Publications[i]);
                }
        
              }
    
              if( response.data.Publications.length > 0 && page < response.pagination.pageCount ){
                pagination = page+1;

                this.setState({
                  pubSearched
                });
      
                this.search( pagination );
              }else{
                this.setState({
                  search: 1,
                  pubSearched,
                  loadingVisible: false,
                  search_pagination: pagination,
                  search_count: pubSearched.length,
                  search_last_page: (page >= response.pagination.pageCount) ? true : false
                });
              }

            }
    
        });

    //}

  }

  handlePageChange(pageNumber) {
   //console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    this.getPublications ( pageNumber );
  }

  componentWillMount() {

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  back(){
    this.props.history.goBack();
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  onDatePickerChange = (date, dateString, name) => {

    if (dateString === "" ){
      dateString = null;
    }

    if(name === "date_since"){
      localStorage.setItem("date_since", dateString);
    }

     this.setState(
         {
             [name]: dateString,
         }
     )

   }

   disabledStartDate = startValue => {
     const endValue = moment(this.state.date_end, "DD/MM/YYYY");
     if (!startValue || !endValue) {
       return false;
     }
     return  startValue.valueOf() > endValue.valueOf()  ;
   };

   disabledEndDate = endValue => {
     const startValue = moment(this.state.date_start, "DD/MM/YYYY");
     if (!endValue || !startValue) {
       return false;
     }
     return endValue.valueOf() < startValue.valueOf() ;
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" ) {
         this.setState( pops );
       }

     }
   }

  pubItem = ( {record, index}, type ) => {

    let numProcesso = null;

    if( typeof record.lawsuit_token !==  "undefined" ){
        let link = "/processo/detalhe_processo/"+record.lawsuit_token;
        numProcesso = <Link className="" to={link}>
          {record.numProcesso}
        </Link>
    }else{
        let link = "/tribunal/add/"+record.numProcesso;
        numProcesso = <div>
          {record.numProcesso}
          <Link className="btn btn-tiny" to={link}>
            cadastrar
          </Link>
        </div>
    }


    return (
      <tr key={record.token} className={type}>
              <td className="count">
                {index+1}
              </td>
              <td className="user">
                {record.dataPublicacao.substr(0,10).split('-').reverse().join('/')}
              </td>
              <td className="table_lawsuit_number">
                {numProcesso}
              </td>
              <td className="publication " >
                <span>{record.conteudoPublicacao}</span>
              </td>
              <td className="pub-info">
                  <span>
                    <b>Nome Pesquisado: </b><p>{record.nomePesquisado}</p>
                    <b>OAB: </b><p>{record.oab}</p>
                    <b>Diário: </b><p>{record.nomeDiario}</p>
                  </span>
              </td>
      </tr>
    )
  }

  backToTop() {
    if (window.pageYOffset > 0) {
      window.scrollBy(0, -80);
      setTimeout(this.backToTop, 0);
    }
  }

  handleChange(e){
      this.setState(
          {
              [e.target.name]: e.target.value
          }
      )
  }

  tip(e){
    //console.log(this.state.lawsuitTips);
  }

  render() {

      let table_hoje = null; let table_nao_cadastrados = null; let table_outras = null; let table_pesquisa = null;

      let table_head = <thead>
                            <tr>
                                  <th className="count">
                                      <div>#</div>
                                  </th>
                                  <th className="table_date date ">
                                      <div>Data da Publicação</div>
                                  </th>
                                  <th className="table_lawsuit_number">
                                      <div>
                                            <p>Processo</p>
                                      </div>
                                  </th>
                                  <th className="publication ">
                                      <div>
                                          <p>Conteúdo da Publicação</p>
                                      </div>
                                  </th>
                                  <th className="">
                                      <div>Informações</div>
                                  </th>
                            </tr>
                        </thead>

      if( this.state.search === 1){
        table_pesquisa =  <div className="panel panel-default container-publicacoes">
                            <div className="panel-heading text-center">
                                <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                                    <strong className="green">Publicações Pesquisadas ({this.state.search_count})</strong>
                                </div>
                            </div>
                            <div className="panel-body in" id="filter_target">
                                  <div id="box-msg-alert" className="alert alert-warning" role="warning">
                                    <label className="red">Atenção: </label>
                                    <span> As datas de publicações podem ser capturadas pela data de Disponibilização e não pela data da efetiva Publicação.</span>
                                  </div>
                                  <div className="table-responsive">
                                      <table className="table">
                                            {table_head}
                                            <tbody>
                                                { this.state.pubSearched.map( (record, index) => this.pubItem( {record, index}, 'report-event-item' ) ) }
                                            </tbody>
                                      </table>
                                  </div>
                                  <span className={(this.state.search_last_page) ? "hidden" : "btn btn-info"} style={{marginLeft: 45+"%"}} onClick={( event ) => { this.search(this.state.search_pagination) } }>Ver Mais</span>
                            </div>
                          </div> 
      }else{
        table_hoje =  <div className="panel panel-default container-publicacoes">
                            <div className="panel-heading text-center">
                                <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                                    <strong className="green">Publicações Recentes ({this.state.hoje.length})</strong>
                                    <MyPopover icon="white" text="Poderão conter publicações com datas futuras, com até 3 dias de antecedência, devido as buscas serem feitas assim que disponibilizadas pelos Tribunais. Após estas datas, estas serão remetidas para o Histórico de Publicações." />
                                </div>
                            </div>
                            <div className="panel-body collapse " id="filter_target">
                                  <div id="box-msg-alert" className="alert alert-warning" role="warning">
                                    <label className="red">Atenção: </label>
                                    <span> As datas de publicações podem ser capturadas pela data de Disponibilização e não pela data da efetiva Publicação.</span>
                                  </div>
                                  <div className="table-responsive">
                                      <table className="table">
                                            {table_head}
                                            <tbody>
                                                { this.state.hoje.map( (record, index) => this.pubItem( {record, index}, 'report-event-item' ) ) }
                                            </tbody>
                                      </table>
                                  </div>
                            </div>
                      </div>        

        table_nao_cadastrados =  <div className="panel panel-default container-publicacoes">
                                        <div className="panel-heading text-center">
                                            <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_atrasadas_target">
                                                <strong className="red">Publicações de Processos não cadastrados ({this.state.nao_cadastrados.length})</strong>
                                                <MyPopover icon="white" text="Possibilita visualizar as últimas Publicações de processos não cadastrados no sistema (Sem busca automática de informações), Se desejar filtrar todas, por data ou nº acesse 'Pesquisa Publicações' " />
                                            </div>
                                        </div>
                                        <div className="panel-body collapse " id="filter_atrasadas_target">
                                            <div className="table-responsive">
                                                <table className="table">
                                                          {table_head}
                                                          <tbody>
                                                              { this.state.nao_cadastrados.map( (record, index) => this.pubItem( {record, index}, 'report-event-item' ) ) }
                                                          </tbody>
                                                </table>
                                            </div>
                                        </div>
                                  </div>    

        table_outras =  <div className="panel panel-default container-publicacoes">
                              <div className="panel-heading text-center">
                                  <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_outras_target">
                                      <strong className="orange">Histórico de Publicações ({this.state.outras.length})</strong>                                          
                                      <MyPopover icon="white" text="Possibilita visualizar as últimas publicações de processos cadastrados por ordem de data. Se desejar filtrar publicações por data ou número do processo, acesse 'Pesquisa Publicações'. " />
                                  </div>
                              </div>
                              <div className="panel-body collapse " id="filter_outras_target">
                                  <div className="table-responsive">
                                    <table className="table">
                                            {table_head}
                                            <tbody>
                                                { this.state.outras.map( (record, index) => this.pubItem( {record, index}, 'report-event-item' ) ) }
                                            </tbody>
                                    </table>
                                  </div>
                              </div>
                        </div>  
      }
                      
      let content = <div>
                            <h2>Hoje</h2>
                            {table_hoje}
                            <h2>Publicações de processos não cadastrados</h2>
                            {table_nao_cadastrados}
                      </div>;

      if( this.state.print === 1 ){
          return <div>
                    <PrintComponent modifiedViewPrint={this.modifiedViewPrint} content={content} title={"Relatório da Agenda"} subtitle={(this.state.params.event === "1") ? "Compromissos" : "Tarefas"} />
               </div>
      }else{

        /*                                        <Pagination
                                          activePage={this.state.activePage}
                                          itemsCountPerPage={this.state.per_page}
                                          totalItemsCount={this.state.total_count}
                                          pageRangeDisplayed={5}
                                          onChange={this.handlePageChange}
                                        /> */
        /*
            <Select
              className="lawsuit forma-pagamento-container"
              options={this.state.lawsuitList}
              placeholder="Todos os processos"
              isClearable={true}
              name="lawsuit"
              value={this.state.lawsuit}
              onChange={this.selectChange}
            />
        */

        return <div className="sheet reportEventList publications">
                  <HeaderBar />
                  <NavigationMenu />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                  <div className="content-wrapper">
                    <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Listagem de Publicações</h1>
                          <div className="header-controls-container">                            
                                  <span className="btn btn-info" onClick={this.back} >Voltar</span>
                                  <div className="row filter">
                                      <div className="form-group date text-left">
                                          <label className="control-label" style={{marginTop: 8+"px"}}>Pesquisa Publicações: </label>
                                      </div>
                                      <div className="form-group date text-left">
                                        <div className="autocomplete" style={{width: 260+"px"}}>
                                          <InputMask name="lawsuit" id="lawsuitInput" value={this.state.lawsuit} className="lawsuit forma-pagamento-container form-control input-lg" onChange={this.handleChange} onKeyUp={this.tip}  />
                                        </div>
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_start" className="control-label">De</label>
                                          <DatePicker locale={locale} className="" disabledDate={this.disabledStartDate} placeholder="De" showToday={false} allowClear={true} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_end" className="control-label">Até</label>
                                          <DatePicker locale={locale} className="" disabledDate={this.disabledEndDate} placeholder="Até" showToday={false} allowClear={true} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                                      </div>
                                      <div className="publications-container-search">
                                          <span className="btn btn-primary" onClick={ ( event ) => { this.clear(event); this.search() } }>Pesquisar</span>
                                      </div>
                                  </div>                        
                          </div>
                        </section>
                        <div className="box-body">
                            <div className="alert alert-info" role="alert">
                                <center>Publicações Gerais</center>
                            </div>
                            <div className="alert alert-warning alert-pub">Alerta: Todas as publicações tem o caráter informativo, organizacional e facilitador para os usuários, não desobrigam os advogados do acompanhamento das publicações pelos Diários Oficiais e/ou tribunais.</div>

                            {table_hoje}
                            {table_outras}
                            {table_nao_cadastrados}

                            {table_pesquisa}

                        </div>
                    </div>
                  </div>
                  <span className="fa fa-chevron-up back-top" onClick={()=>{this.backToTop();}}></span>
               </div>
      }

  }

}
