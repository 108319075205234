import React, { Component } from 'react';
import { Switch } from 'antd';
import UsersService from '../../../services/UsersService';
import FlashMessage from '../../containers/FlashMessage';

export default class extends Component {

  constructor( props ){
      super();

      this.setActive = this.setActive.bind(this);

      this.state = {
        switch: (props.active === 1) ? true : false,
        admin: props.admin
      }
  }

  componentWillReceiveProps(nextProps) {

      if ( nextProps.active !== this.props.active ) {

        this.setState({
          switch: ( nextProps.active === 1) ? true : false,
          admin: nextProps.admin
        });

      }
  }

  setActive( allow ){
    this.UsersService = new UsersService();

    this.UsersService.active( this.props.user_token ).then(res =>{

          if( res.success === true ){

            /*localStorage.setItem('alert_message', res.message);
            localStorage.setItem('alert_type', 'alert-success');
            let time = new Date().getTime();
            localStorage.setItem('alert_time', time );
            setTimeout(() => {
              this.setState({
                updated: time,
              });
            }, 100);*/

          }else{
            /*localStorage.setItem('alert_message', res.message);
            localStorage.setItem('alert_type', 'alert-danger');
            let time = new Date().getTime();
            localStorage.setItem('alert_time', time );
            setTimeout(() => {
              this.setState({
                updated: time,
              });
            }, 100);*/
          }

    });
  }

  onSwitchChange = (checked) => {
    if ( checked ){
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: true,
      });

      this.setActive(true);

    }else{
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: false,
      });

      this.setActive(false);

    }
  }

  render() {

    return (
      <div>
        <FlashMessage time={ new Date().getTime() } />
        <Switch disabled={this.state.admin} title={(this.state.switch)? "Desbloqueado": "Bloqueado"} checked={this.state.switch} onChange={ this.onSwitchChange }  />
      </div>
    );
  }
}
