import React from 'react';
import Select from 'react-select';

import LawsuitPersonagesService from '../../../services/LawsuitPersonagesService'

export default class parteSelect extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
    super( props );
    this.selectChange = this.selectChange.bind(this);
    this.modifiedParte = this.props.modifiedParte.bind(this);
    this.state = {
        parte: {value: 0, label: ""},
        parteList: [],
        key: this.props.parte_key,
    }
  }

  componentWillReceiveProps(nextProps) {

    if(this.props.parte_key !== nextProps.parte_key){
      this.setState ( {
          key: nextProps.parte_key,
      });
    }

  }

  populateSelect(){
    document.body.style.cursor = "wait";
    let parteList = []; let parte = this.state.parte;

    this.LawsuitPersonagesService = new LawsuitPersonagesService();

      this.LawsuitPersonagesService.list( ).then(res =>{
            parteList = res;
            //console.log(this.state.parte)

            this.setState({
              parteList: parteList,
              parte: parte,
            });
            document.body.style.cursor = "default";
      });

  }

 componentDidMount( ) { this._isMounted = true;
   this.populateSelect();
 }

  selectChange = (selected, actionMeta) => {

        let pops = [];
        pops[ actionMeta.name ] = selected;

        if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

          if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
            this.setState( pops );
          }

        }

        document.getElementById("parte-"+this.state.key).value = selected.value;

        this.modifiedParte();

  }

  render() {

      return <div id="partes-container" className="col-md-11">
                    <div className="text-left">
                          <label className="col-md-12">Selecione a {this.state.key}ª Parte </label>
                          <Select
                              className="partes col-md-12"
                              placeholder="Selecione"
                              options={this.state.parteList}
                              value={this.state.parte1}
                              name="parte"
                              onChange={this.selectChange}
                            />
                    </div>
                    <input type="hidden" id={"parte-"+this.state.key} className="parte_id" data-key={this.state.key} />
             </div>
  }

}
