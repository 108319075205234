import React from 'react';
import DonutChart from "./donutChart";
import UsersService from '../../services/UsersService';
import EventService from '../../services/EventService';

import moment from "moment";
import 'moment/locale/pt-br';

export default class CalendarCharts extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {

      super( props );

      this.getEvents = this.getEvents.bind(this);

      this.state = {
          count_atrasados: 0,
          count_hoje: 0,
          count_futuros: 0,
          count_task_atrasados: 0,
          count_task_hoje: 0,
          count_task_futuros: 0,
          usersList: [],
          selectedUser: 0
      }

  }

  componentWillReceiveProps(nextProps) {

    this.setState({

    });

  }

  componentDidMount(){

      this.usersService = new UsersService();

      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

      this.usersService.list( ).then(res =>{

          let options = res; //let sponsor = [];

          if(options.length > 0){
              options.unshift ( {value: -99, label: "Todos"} );
          }

          this.setState({
            usersList: options,
          });

      });

      let month = new moment().format("MMMM");
      let day = new moment().format("DD");
      let year = new moment().format("YYYY");
      let week = new moment().format("dddd");

      this.setState({
        month: month,
        day: day,
        year: year,
        week: week,
        selectedUser: loggedUser.token
      });

      this.getEvents( loggedUser.token );

      //setInterval(() => {
          //this.getEvents( this.state.selectedUser );
      //}, 300000);

  }

  getEvents ( token ) {

    let start = moment().startOf('day').subtract(31, 'days').format('DD-MM-YYYY');;
    let end = moment().endOf('day').add(2, 'days').format('DD-MM-YYYY');

    if ( token === "all" && token === "-99" ){

            this.EventService = new EventService();

            this.EventService.report( start, end, "0", "0", "0", "0", "0", "0", "0", "0", "0" ).then( events =>{

                this.updateChart( events );

            });

    }else{

            this.EventService = new EventService();

            this.EventService.report( start, end, "0", "0", "0", token, "0", "0", "0", "0", "0" ).then( events =>{

                this.updateChart( events );

            });

    }

    this.setState({
      user_token: token,
      start: start,
      end: end,
    });

  }

  updateChart( events ) {

    //console.log("agora vai calendario widget");
    //console.log( events );

    let count_hoje = 0; let count_task_hoje = 0; let count_atrasados = 0; let count_task_atrasados = 0; let count_futuros = 0;
    let count_task_futuros = 0;

    for( let i=0; i < events.length; i++){

          if( events[i].start.substr(0,10) === moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //hoje
            if( events[i].event_type !== null && events[i].event_type.id === 1 ){
              count_hoje += 1;
            }else if ( events[i].event_type !== null && events[i].event_type.id === 2 ){
              count_task_hoje += 1;
            }

          }else if( events[i].start.substr(0,10) < moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //Compromissos Atrasados
            if( events[i].event_type !== null && events[i].event_type.id === 1 ){
              count_atrasados += 1;
            }else if ( events[i].event_type !== null && events[i].event_type.id === 2 ){
              count_task_atrasados += 1;
            }

          }else if( events[i].start.substr(0,10) > moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //futuros
            if( events[i].event_type !== null && events[i].event_type.id === 1 ){
              count_futuros += 1;
            }else if ( events[i].event_type !== null && events[i].event_type.id === 2 ){
              count_task_futuros += 1;
            }

          }

    }

    this.setState({
      count_hoje: count_hoje,
      count_task_hoje: count_task_hoje,
      count_atrasados: count_atrasados,
      count_task_atrasados: count_task_atrasados,
      count_futuros: count_futuros,
      count_task_futuros: count_task_futuros
    });

 }

  changeUser = ( event ) => {

    //console.log( event.target.value );

    var eventChart = new CustomEvent("changeUserChart", { "detail": {"user_token": event.target.value} });

    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(eventChart);

    if( event.target.value === "-99"){
      this.getEvents("all");
    }else{
      this.getEvents(event.target.value);
    }

    this.setState({
      selectedUser: event.target.value
    });

  }

  render() {

        var arrUser = [];

          for (var k = 0; k < this.state.usersList.length; k++) {
              arrUser.push(<option key={k} value={this.state.usersList[k].value}> {this.state.usersList[k].label} </option>);
          }

        return (
                <div id="chart-container">
                        <div className="chart-inner">
                            <div className="calendar-widget">
                                    <div className="month">{this.state.month}</div>
                                    <div className="day">{this.state.day}</div>
                                    <div className="year">{this.state.year}</div>
                                    <div className="week">{this.state.week}</div>
                                    {/*<i className="fa fa-angle-left previous"></i>
                                    <i className="fa fa-angle-right next"></i>*/}
                                    <div className="users">
                                          <span>Agenda do Usuário</span>
                                          <select className="user" value={this.state.selectedUser} onChange={this.changeUser} >
                                          {arrUser}
                                          </select>
                                    </div>
                            </div>
                            <div className="rounded-container">
                                    <DonutChart
                                        title="Compromissos"
                                        class_title="compromissos"
                                        show_future={true}
                                        label_title="Compromissos"
                                        label_value="Quantidade"
                                        color="#D7F0FB"
                                        height="120"
                                        top="5px"
                                        pasteTitle="Atrasados"
                                        count_atrasados={this.state.count_atrasados}
                                        count_hoje={this.state.count_hoje}
                                        count_futuros={this.state.count_futuros}
                                        url={'relatorio/agenda/chart/1/0/0/'+this.state.user_token+'/0/0/0/0/'+this.state.start+'/'+this.state.end}
                                        history={this.props.history}
                                    />
                                    <DonutChart
                                        title="Tarefas"
                                        class_title="tarefas"
                                        show_future={true}
                                        label_title="Tarefas"
                                        label_value="Quantidade"
                                        color="#E6F5FC"
                                        height="120"
                                        top="5px"
                                        pasteTitle="Atrasadas"
                                        count_atrasados={this.state.count_task_atrasados}
                                        count_hoje={this.state.count_task_hoje}
                                        count_futuros={this.state.count_task_futuros}
                                        url={'relatorio/agenda/chart/2/0/0/'+this.state.user_token+'/0/0/0/0/'+this.state.start+'/'+this.state.end}
                                        history={this.props.history}
                                    />
                            </div>
                        </div>
                </div>
        );
  }
}
