import AuthService from './AuthService';
import {api} from '../config';
import moment from "moment";

export default class LawsuitService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.list = this.list.bind(this)
    }

    add ( lawsuit ){
      return this.fetch(`${this.domain}Lawsuits/add`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    edit ( lawsuit, token ){
      return this.fetch(`${this.domain}Lawsuits/edit/${token}`, {
          method: 'PUT',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    addMovement( lawsuit ){
      return this.fetch(`${this.domain}Movements/addMovement`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      });
    }

    addToHighlight ( token ){
      return this.fetch(`${this.domain}lawsuits/favoritar/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    addToArchive ( token ){
      return this.fetch(`${this.domain}lawsuits/arquivar/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    jefToTrf3 ( lawsuit_token ) {
      return this.fetch(`${this.domain}Lawsuits/alterarJefParaTrf3/${lawsuit_token}`, {
          method: 'GET',
      }).then(res => {
          return Promise.resolve(res);
      })
    }

    listInformation ( lawsuit_token ) {
      return this.fetch(`${this.domain}LawsuitInformations/index/${lawsuit_token}`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log("list information");
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitInformations.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.token, "information": value.information, "created": value.created, "user_name": value.user_name, "owner": value.owner };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addInformation ( lawsuit ){
      return this.fetch(`${this.domain}LawsuitInformations/add`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    editInformation ( lawsuit, token ){
      return this.fetch(`${this.domain}LawsuitInformations/edit/${token}`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteInformation ( token ){
      return this.fetch(`${this.domain}LawsuitInformations/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          return Promise.resolve(res);
      })
    }

    get ( id ){

        return this.fetch(`${this.domain}lawsuits/${id}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            let message = "";

            if( res.success === true ){

              let lawsuit = this.processINSS( res.data.Lawsuits );

              return Promise.resolve( lawsuit );
            }else {
              if( typeof res == "string" ){
                message = res;
                prompt("Ocorreu um erro, aperte Ctrl+C para copiar e informe ao suporte técnico: \n",res);
              }else{
                message = res.message;
                prompt("Ocorreu um erro, aperte Ctrl+C para copiar e informe ao suporte técnico: \n",res.message);
              }
             //console.log(res);
              let lawsuit = {
                tribunal: {},
                tribunal_id: 0,
                valorCausa: "",
                status: "ERRO",
                lawsuits_personages: [{token:1, personage_type_name:"", personage: "", modified: "1970-01-01 10:00"},{token: 2, personage_type_name:"", personage:"",modified: "1970-01-01 09:00"}],
                movements: [{id: 1, name: "", date: ""}],
                token: id,
                formIsHalfFilledOut: 1,
                message: message
              }

              return Promise.resolve(lawsuit);
            }

        })

    }

    processINSS( lawsuit ){
        /*let procedure = {"procedures": [{
            "token": "57378c66-7328-45a4-9b33-a795e603afd4",
            "name": "935351340",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:12+00:00",
            "modified": "2022-07-20T22:53:12+00:00",
            "deleted": null,
            "procedure_movements": [],
            "procedure_inputs": [{
              "token": "19c81936-19c8-4078-bcab-64e6f841e014",
              "name": "Servi\u00e7o",
              "value": "Aposentadoria por Tempo de Contribui\u00e7\u00e3o.",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "2222e056-55b0-4302-bff0-829928dd9418",
              "name": "Situa\u00e7\u00e3o do Requerimento",
              "value": "CUMPRIDO (Compareceu)",
              "procedure_input_type_id": 32,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 32,
                "token": "61e671c5-7777-468d-98c9-852c3f2cfa8c",
                "name": "Situa\u00e7\u00e3o do Requerimento",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "cc06f8d9-1537-4949-9c32-4bec8413cf4e",
              "name": "Data e Hora Agendada",
              "value": "21\/03\/2017(Ter\u00e7a-feira) \u00e0s 17:00",
              "procedure_input_type_id": 33,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 33,
                "token": "6eed8902-4254-4c83-be90-00803ca97ce9",
                "name": "Data e Hora Agendada",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "cb82a26d-52f5-487d-ac54-155182c5a407",
              "name": "Unidade",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - VILA MARIANA",
              "procedure_input_type_id": 34,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 34,
                "token": "7f690348-b232-4e64-982d-1a86d52a9fad",
                "name": "Unidade",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "f22f4a9f-726f-4eb4-9749-9f6e96570e73",
              "name": "Endere\u00e7o",
              "value": "RUA SANTA CRUZ,707, VILA MARIANA - SAO PAULO - SP - CEP: 04.121-000",
              "procedure_input_type_id": 35,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 35,
                "token": "8381ffe4-5281-4c30-bc74-ed0d224a8794",
                "name": "Endere\u00e7o",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "5e7b69ec-5f84-4215-961d-89d9e2b57a56",
              "name": "Protocolo",
              "value": "935351340",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "a255abd3-d715-4741-8284-c31d1b549534",
              "name": "Data de Entrada do Requerimento",
              "value": "25\/11\/2016",
              "procedure_input_type_id": 36,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 36,
                "token": "e356e0c4-a944-4e8e-ac07-b142fc376271",
                "name": "Data de Entrada do Requerimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "480b216d-7385-4407-b6e7-ef6855de481f",
              "name": "Canal do Requerimento",
              "value": "Internet",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "25848635-7272-4448-9613-85811c638b0a",
              "name": "Observa\u00e7\u00e3o",
              "value": "A an\u00e1lise do requerimento ser\u00e1 confirmada ap\u00f3s o comparecimento do requerente ou seu representante na data e hora agendada.",
              "procedure_input_type_id": 37,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 37,
                "token": "8e239e82-e17d-4508-a3f8-40687b859508",
                "name": "Observa\u00e7\u00e3o",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "fdb532b5-3072-47d4-856a-efadfb72d6ec",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "b3f1fd6d-9571-4475-96b1-c1b4be047d3b",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "12d62f9a-3285-4c2b-ad01-62fdf99af615",
              "name": "Data de Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 38,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 38,
                "token": "50ff61b2-8803-4f8e-8782-6689876417b7",
                "name": "Data de Nascimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "12679e31-462f-49bd-af2b-3623d4c27e46",
              "name": "NIT Requerente",
              "value": "10289225512",
              "procedure_input_type_id": 39,
              "created": "2022-07-20T22:53:13+00:00",
              "modified": "2022-07-20T22:53:13+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 39,
                "token": "91426131-89e3-45d1-8418-a795b71aefa8",
                "name": "NIT Requerente",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "8b41a6a4-a1a2-4c9d-bd65-96af01ead046",
            "name": "666715565",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:12+00:00",
            "modified": "2022-07-20T22:53:12+00:00",
            "deleted": null,
            "procedure_movements": [],
            "procedure_inputs": [{
              "token": "e9635e40-6074-433b-b6fc-d51503d85823",
              "name": "Servi\u00e7o",
              "value": "C\u00f3pia de Processo",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "4d13ce1e-a0e3-4bb2-8ac3-f62d6bfb0b39",
              "name": "Situa\u00e7\u00e3o do Requerimento",
              "value": "CUMPRIDO (Compareceu)",
              "procedure_input_type_id": 32,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 32,
                "token": "61e671c5-7777-468d-98c9-852c3f2cfa8c",
                "name": "Situa\u00e7\u00e3o do Requerimento",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "b39667b6-a4df-4396-83ff-390dc760575d",
              "name": "Data e Hora Agendada",
              "value": "07\/07\/2017(Sexta-feira) \u00e0s 09:45",
              "procedure_input_type_id": 33,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 33,
                "token": "6eed8902-4254-4c83-be90-00803ca97ce9",
                "name": "Data e Hora Agendada",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "b7499a5a-f541-4e66-8fa3-a1a35789e56f",
              "name": "Unidade",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - VILA MARIANA",
              "procedure_input_type_id": 34,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 34,
                "token": "7f690348-b232-4e64-982d-1a86d52a9fad",
                "name": "Unidade",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "7987266b-c197-401f-b0b6-693c49344b67",
              "name": "Endere\u00e7o",
              "value": "RUA SANTA CRUZ,707, VILA MARIANA - SAO PAULO - SP - CEP: 04.121-000",
              "procedure_input_type_id": 35,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 35,
                "token": "8381ffe4-5281-4c30-bc74-ed0d224a8794",
                "name": "Endere\u00e7o",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "3080f488-9ad9-40b1-b955-b377e0034102",
              "name": "Protocolo",
              "value": "666715565",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "defc9552-3444-40b7-b599-1549a8077c6d",
              "name": "Data de Entrada do Requerimento",
              "value": "26\/06\/2017",
              "procedure_input_type_id": 36,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 36,
                "token": "e356e0c4-a944-4e8e-ac07-b142fc376271",
                "name": "Data de Entrada do Requerimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "8cf2f837-466b-4732-bbb4-86280cef6314",
              "name": "Canal do Requerimento",
              "value": "Internet",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "2f4b2594-04cb-439c-80f8-1435429e04cf",
              "name": "Observa\u00e7\u00e3o",
              "value": "A an\u00e1lise do requerimento ser\u00e1 confirmada ap\u00f3s o comparecimento do requerente ou seu representante na data e hora agendada.",
              "procedure_input_type_id": 37,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 37,
                "token": "8e239e82-e17d-4508-a3f8-40687b859508",
                "name": "Observa\u00e7\u00e3o",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "6139fe43-d9af-4eae-b1fd-9a8688ef39d2",
              "name": "Nome Completo",
              "value": "paulo dione dos santos",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "1156898a-7ea3-4c14-b4df-66838aa79312",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "1bd63e46-4c5a-41fe-a5ad-bf4230934cbd",
              "name": "Data de Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 38,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 38,
                "token": "50ff61b2-8803-4f8e-8782-6689876417b7",
                "name": "Data de Nascimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "6a7742b5-5cc2-41fb-b997-821eddf19598",
              "name": "NIT Requerente",
              "value": "10289225512",
              "procedure_input_type_id": 39,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 39,
                "token": "91426131-89e3-45d1-8418-a795b71aefa8",
                "name": "NIT Requerente",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "3aebe64f-3df7-47cc-be43-5b86656b292c",
              "name": "NB",
              "value": "181.519.178-0",
              "procedure_input_type_id": 14,
              "created": "2022-07-20T22:53:12+00:00",
              "modified": "2022-07-20T22:53:12+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 14,
                "token": "efbd83b9-3705-488b-afdd-a2d1cf26d595",
                "name": "NB",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "d225f516-930b-4840-acce-a1fa598b5946",
            "name": "1197387089",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:10+00:00",
            "modified": "2022-07-20T22:53:10+00:00",
            "deleted": null,
            "procedure_movements": [],
            "procedure_inputs": [{
              "token": "991ecf39-3f99-4baa-95cb-cc7bcb88f4f4",
              "name": "Servi\u00e7o",
              "value": "Recurso",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:10+00:00",
              "modified": "2022-07-20T22:53:10+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "3c7bd985-3b4a-46e8-86ef-faeeba4737f7",
              "name": "Situa\u00e7\u00e3o do Requerimento",
              "value": "CUMPRIDO (N\u00e3o compareceu)",
              "procedure_input_type_id": 32,
              "created": "2022-07-20T22:53:10+00:00",
              "modified": "2022-07-20T22:53:10+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 32,
                "token": "61e671c5-7777-468d-98c9-852c3f2cfa8c",
                "name": "Situa\u00e7\u00e3o do Requerimento",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "00fd689a-83df-40e9-b904-8327ae96988d",
              "name": "Data e Hora Agendada",
              "value": "23\/01\/2018(Ter\u00e7a-feira) \u00e0s 16:00",
              "procedure_input_type_id": 33,
              "created": "2022-07-20T22:53:10+00:00",
              "modified": "2022-07-20T22:53:10+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 33,
                "token": "6eed8902-4254-4c83-be90-00803ca97ce9",
                "name": "Data e Hora Agendada",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "095295aa-0c81-4e75-bdb4-14e95c0fd595",
              "name": "Unidade",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - VILA MARIANA",
              "procedure_input_type_id": 34,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 34,
                "token": "7f690348-b232-4e64-982d-1a86d52a9fad",
                "name": "Unidade",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "417f52ba-963c-4f06-b342-018ee217c898",
              "name": "Endere\u00e7o",
              "value": "RUA SANTA CRUZ,707, VILA MARIANA - SAO PAULO - SP - CEP: 04.121-000",
              "procedure_input_type_id": 35,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 35,
                "token": "8381ffe4-5281-4c30-bc74-ed0d224a8794",
                "name": "Endere\u00e7o",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "0a8f4410-b667-4bad-94dc-451cf9711bd8",
              "name": "Protocolo",
              "value": "1197387089",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "ad025b18-b0b0-4c63-8599-1f0415f6099c",
              "name": "Data de Entrada do Requerimento",
              "value": "27\/07\/2017",
              "procedure_input_type_id": 36,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 36,
                "token": "e356e0c4-a944-4e8e-ac07-b142fc376271",
                "name": "Data de Entrada do Requerimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "422da313-cb89-47c6-9f8a-4b8695585c18",
              "name": "Canal do Requerimento",
              "value": "Internet",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "fd7be811-bee9-4d8c-b993-349e7b6f15ee",
              "name": "Observa\u00e7\u00e3o",
              "value": "A an\u00e1lise do requerimento ser\u00e1 confirmada ap\u00f3s o comparecimento do requerente ou seu representante na data e hora agendada.",
              "procedure_input_type_id": 37,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 37,
                "token": "8e239e82-e17d-4508-a3f8-40687b859508",
                "name": "Observa\u00e7\u00e3o",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "c241621c-b18c-4626-9b2c-637d903f14ba",
              "name": "Nome Completo",
              "value": "Paulo Dione dos Santos",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "c6ea6e9f-da5b-42eb-8eb4-92e4b02f4c8e",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "5acea9fb-7000-4c3d-9d8c-1b28d5d97f7c",
              "name": "Data de Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 38,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 38,
                "token": "50ff61b2-8803-4f8e-8782-6689876417b7",
                "name": "Data de Nascimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "a8f0e00a-54b2-4877-8814-8f63fb428d25",
              "name": "NIT Requerente",
              "value": "10289225512",
              "procedure_input_type_id": 39,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 39,
                "token": "91426131-89e3-45d1-8418-a795b71aefa8",
                "name": "NIT Requerente",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "2c227828-5222-46f9-82aa-1c6c649d9db7",
              "name": "NB",
              "value": "181.519.178-0",
              "procedure_input_type_id": 14,
              "created": "2022-07-20T22:53:11+00:00",
              "modified": "2022-07-20T22:53:11+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 14,
                "token": "efbd83b9-3705-488b-afdd-a2d1cf26d595",
                "name": "NB",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "8d3cd5ac-80d6-4a71-aef6-e814cff05607",
            "name": "739022306",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:09+00:00",
            "modified": "2022-07-20T22:53:09+00:00",
            "deleted": null,
            "procedure_movements": [],
            "procedure_inputs": [{
              "token": "9cc239ff-bfda-4159-a995-6e635512c8d3",
              "name": "Servi\u00e7o",
              "value": "Cumprimento de Exig\u00eancia",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "3329fc5b-4bee-412d-bd17-50ae81d417b6",
              "name": "Situa\u00e7\u00e3o do Requerimento",
              "value": "CUMPRIDO (Compareceu)",
              "procedure_input_type_id": 32,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 32,
                "token": "61e671c5-7777-468d-98c9-852c3f2cfa8c",
                "name": "Situa\u00e7\u00e3o do Requerimento",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "8b861ada-495f-43f2-8ed4-990bbb6389bc",
              "name": "Data e Hora Agendada",
              "value": "12\/08\/2019(Segunda-feira) \u00e0s 12:45",
              "procedure_input_type_id": 33,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 33,
                "token": "6eed8902-4254-4c83-be90-00803ca97ce9",
                "name": "Data e Hora Agendada",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "73c14da6-b74e-49ea-9d17-4bb3dd18a44d",
              "name": "Unidade",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - ARICANDUVA",
              "procedure_input_type_id": 34,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 34,
                "token": "7f690348-b232-4e64-982d-1a86d52a9fad",
                "name": "Unidade",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "f8d46c93-9b97-41eb-997c-bbb1c05c531e",
              "name": "Endere\u00e7o",
              "value": "AVENIDA RIO DAS PEDRAS, 2.476, JARDIM ARICANDUVA - SAO PAULO - SP - CEP: 03.452-200",
              "procedure_input_type_id": 35,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 35,
                "token": "8381ffe4-5281-4c30-bc74-ed0d224a8794",
                "name": "Endere\u00e7o",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "198d7414-1549-44a1-82f2-12846dd5e35d",
              "name": "Protocolo",
              "value": "739022306",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "bfb3978e-6730-44b9-830b-c2448005976b",
              "name": "Data de Entrada do Requerimento",
              "value": "02\/08\/2019",
              "procedure_input_type_id": 36,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 36,
                "token": "e356e0c4-a944-4e8e-ac07-b142fc376271",
                "name": "Data de Entrada do Requerimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "55c8b13f-0e51-4fee-b125-00a717ab541f",
              "name": "Canal do Requerimento",
              "value": "Central 135",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "e50b2f4b-100f-4a64-ac6b-21163108f072",
              "name": "Observa\u00e7\u00e3o",
              "value": "A an\u00e1lise do requerimento ser\u00e1 confirmada ap\u00f3s o comparecimento do requerente ou seu representante na data e hora agendada.",
              "procedure_input_type_id": 37,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 37,
                "token": "8e239e82-e17d-4508-a3f8-40687b859508",
                "name": "Observa\u00e7\u00e3o",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "ca570b3b-ae50-4157-9336-e38068a86847",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "99050eeb-b33b-4974-ab50-2a7c085701a9",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "db621ba4-210f-4ca7-a407-e0180a19eab5",
              "name": "Data de Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 38,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 38,
                "token": "50ff61b2-8803-4f8e-8782-6689876417b7",
                "name": "Data de Nascimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "3de2bf07-6c62-4a03-8760-42414ed8183d",
              "name": "Celular",
              "value": "(11) 984745687",
              "procedure_input_type_id": 19,
              "created": "2022-07-20T22:53:10+00:00",
              "modified": "2022-07-20T22:53:10+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 19,
                "token": "702a226e-bfc7-41fe-8e73-832d693b4de4",
                "name": "Celular",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "2ba29d0f-cb0c-45c1-be79-0be41c1382e2",
              "name": "NIT Requerente",
              "value": "10289225512",
              "procedure_input_type_id": 39,
              "created": "2022-07-20T22:53:10+00:00",
              "modified": "2022-07-20T22:53:10+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 39,
                "token": "91426131-89e3-45d1-8418-a795b71aefa8",
                "name": "NIT Requerente",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "76934e3e-d8a7-4183-947e-089eaf497e2b",
            "name": "265516521",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:08+00:00",
            "modified": "2022-07-20T22:53:08+00:00",
            "deleted": null,
            "procedure_movements": [],
            "procedure_inputs": [{
              "token": "a09a2880-7b98-4bc9-bcb1-72172585f82d",
              "name": "Servi\u00e7o",
              "value": "Cumprimento de Exig\u00eancia",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "41a59fbc-63b3-4b6c-b9a6-93db519a6949",
              "name": "Situa\u00e7\u00e3o do Requerimento",
              "value": "CUMPRIDO (Compareceu)",
              "procedure_input_type_id": 32,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 32,
                "token": "61e671c5-7777-468d-98c9-852c3f2cfa8c",
                "name": "Situa\u00e7\u00e3o do Requerimento",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "0628a29b-8d21-41a9-87e0-c9bf33841dbf",
              "name": "Data e Hora Agendada",
              "value": "26\/08\/2019(Segunda-feira) \u00e0s 15:00",
              "procedure_input_type_id": 33,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 33,
                "token": "6eed8902-4254-4c83-be90-00803ca97ce9",
                "name": "Data e Hora Agendada",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "d5506110-b8b5-413c-9b1c-9f1133d939a1",
              "name": "Unidade",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - \u00c1GUA RASA",
              "procedure_input_type_id": 34,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 34,
                "token": "7f690348-b232-4e64-982d-1a86d52a9fad",
                "name": "Unidade",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "5957d525-9240-4790-b30c-0724dbb30b23",
              "name": "Endere\u00e7o",
              "value": "R JOAO SOARES, 59, QUARTA PARADA - SAO PAULO - SP - CEP: 03.175-070",
              "procedure_input_type_id": 35,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 35,
                "token": "8381ffe4-5281-4c30-bc74-ed0d224a8794",
                "name": "Endere\u00e7o",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "e7805f54-ce5b-4e82-93eb-c337b49bc90c",
              "name": "Protocolo",
              "value": "265516521",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "4083cfbc-a8f7-41ea-bb80-c502f3a11e22",
              "name": "Data de Entrada do Requerimento",
              "value": "19\/08\/2019",
              "procedure_input_type_id": 36,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 36,
                "token": "e356e0c4-a944-4e8e-ac07-b142fc376271",
                "name": "Data de Entrada do Requerimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "178d05a8-390d-4122-b515-77037edda0e3",
              "name": "Canal do Requerimento",
              "value": "Intranet",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "7432525f-a537-4d8c-afc0-4d80578a18f7",
              "name": "Observa\u00e7\u00e3o",
              "value": "A an\u00e1lise do requerimento ser\u00e1 confirmada ap\u00f3s o comparecimento do requerente ou seu representante na data e hora agendada.",
              "procedure_input_type_id": 37,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 37,
                "token": "8e239e82-e17d-4508-a3f8-40687b859508",
                "name": "Observa\u00e7\u00e3o",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "f82a8ad5-e4ff-4332-93d2-c1238899366a",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "878f3e81-374c-4565-8493-315704b268f2",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "2bcad67f-27b3-4a4f-8921-aeae31571132",
              "name": "Data de Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 38,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 38,
                "token": "50ff61b2-8803-4f8e-8782-6689876417b7",
                "name": "Data de Nascimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "008ac086-14de-41d9-8992-dbe413381d6b",
              "name": "Celular",
              "value": "(11) 984745687",
              "procedure_input_type_id": 19,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 19,
                "token": "702a226e-bfc7-41fe-8e73-832d693b4de4",
                "name": "Celular",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "5a4ec665-b9a8-4a22-a2a5-27b6f12563d8",
              "name": "NIT Requerente",
              "value": "10289225512",
              "procedure_input_type_id": 39,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 39,
                "token": "91426131-89e3-45d1-8418-a795b71aefa8",
                "name": "NIT Requerente",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "8438e8e0-14d3-43d8-8001-e07025536528",
              "name": "NB",
              "value": "181.519.178-0",
              "procedure_input_type_id": 14,
              "created": "2022-07-20T22:53:09+00:00",
              "modified": "2022-07-20T22:53:09+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 14,
                "token": "efbd83b9-3705-488b-afdd-a2d1cf26d595",
                "name": "NB",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "1eae5ab7-056d-400c-b9c6-ca5a8680cbf3",
            "name": "1590717290",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:06+00:00",
            "modified": "2022-07-20T22:53:06+00:00",
            "deleted": null,
            "procedure_movements": [],
            "procedure_inputs": [{
              "token": "335dcc09-aba0-4d8c-86fa-fc93ef699acd",
              "name": "Servi\u00e7o",
              "value": "Cumprimento de Exig\u00eancia",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "a7ae9dbd-14ec-4675-96a5-b1fdca1bd18d",
              "name": "Situa\u00e7\u00e3o do Requerimento",
              "value": "REMARCADO ",
              "procedure_input_type_id": 32,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 32,
                "token": "61e671c5-7777-468d-98c9-852c3f2cfa8c",
                "name": "Situa\u00e7\u00e3o do Requerimento",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "e4c3035f-d2fc-4b9d-962f-5a12128cd8d0",
              "name": "Data e Hora Agendada",
              "value": "05\/08\/2019(Segunda-feira) \u00e0s 13:00",
              "procedure_input_type_id": 33,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 33,
                "token": "6eed8902-4254-4c83-be90-00803ca97ce9",
                "name": "Data e Hora Agendada",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "e6584c8c-dac8-47c4-baf2-cdbb2f46aa95",
              "name": "Unidade",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - ARICANDUVA",
              "procedure_input_type_id": 34,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 34,
                "token": "7f690348-b232-4e64-982d-1a86d52a9fad",
                "name": "Unidade",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "0dad16f6-a9c4-4fde-84f5-db45ffbce3ad",
              "name": "Endere\u00e7o",
              "value": "AVENIDA RIO DAS PEDRAS, 2.476, JARDIM ARICANDUVA - SAO PAULO - SP - CEP: 03.452-200",
              "procedure_input_type_id": 35,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 35,
                "token": "8381ffe4-5281-4c30-bc74-ed0d224a8794",
                "name": "Endere\u00e7o",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "7f622c77-09b9-46ee-a3fd-6a34f99e3453",
              "name": "Protocolo",
              "value": "1590717290",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "df981a25-727e-4568-8ebe-276d599b891a",
              "name": "Data de Entrada do Requerimento",
              "value": "26\/07\/2019",
              "procedure_input_type_id": 36,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 36,
                "token": "e356e0c4-a944-4e8e-ac07-b142fc376271",
                "name": "Data de Entrada do Requerimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "41992bcc-cb1e-49e4-ae99-34afa55a70aa",
              "name": "Canal do Requerimento",
              "value": "Central 135",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "8220b8f3-9b78-4cbf-a36e-ef43d8c79821",
              "name": "Observa\u00e7\u00e3o",
              "value": "A an\u00e1lise do requerimento ser\u00e1 confirmada ap\u00f3s o comparecimento do requerente ou seu representante na data e hora agendada.",
              "procedure_input_type_id": 37,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 37,
                "token": "8e239e82-e17d-4508-a3f8-40687b859508",
                "name": "Observa\u00e7\u00e3o",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "f92e3a7d-7324-4c82-8453-4dde6b083ff5",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "0277a228-dcd9-4370-8adb-064c25936a0c",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "600bbd30-573b-4b6f-bbe6-be3e748e8be4",
              "name": "Data de Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 38,
              "created": "2022-07-20T22:53:07+00:00",
              "modified": "2022-07-20T22:53:07+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 38,
                "token": "50ff61b2-8803-4f8e-8782-6689876417b7",
                "name": "Data de Nascimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "ceaaa1f1-0233-445c-938f-4b220e927275",
              "name": "Celular",
              "value": "(11) 984745687",
              "procedure_input_type_id": 19,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 19,
                "token": "702a226e-bfc7-41fe-8e73-832d693b4de4",
                "name": "Celular",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "92a424a9-d222-40ee-8c4a-9f2aedd4eb8f",
              "name": "NIT Requerente",
              "value": "10289225512",
              "procedure_input_type_id": 39,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 39,
                "token": "91426131-89e3-45d1-8418-a795b71aefa8",
                "name": "NIT Requerente",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "b5163b9a-1d4e-4ca0-b945-bf24526c82d0",
              "name": "Possui todos os documentos solicitados?",
              "value": "Sim",
              "procedure_input_type_id": 40,
              "created": "2022-07-20T22:53:08+00:00",
              "modified": "2022-07-20T22:53:08+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 40,
                "token": "72cd8192-3b84-414d-86a4-370e18426dc5",
                "name": "Possui todos os documentos solicitados?",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "7814d9c0-0550-40b9-888d-a915faefa5c7",
            "name": "168197392",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:05+00:00",
            "modified": "2022-07-20T22:53:05+00:00",
            "deleted": null,
            "procedure_movements": [],
            "procedure_inputs": [{
              "token": "7bd52e31-7248-49c0-a4f6-2d34cfed4b18",
              "name": "Servi\u00e7o",
              "value": "Cumprimento de Exig\u00eancia",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:05+00:00",
              "modified": "2022-07-20T22:53:05+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "dbaa7b79-1446-4b6f-8d2e-8fb8e79592cf",
              "name": "Situa\u00e7\u00e3o do Requerimento",
              "value": "REMARCADO ",
              "procedure_input_type_id": 32,
              "created": "2022-07-20T22:53:05+00:00",
              "modified": "2022-07-20T22:53:05+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 32,
                "token": "61e671c5-7777-468d-98c9-852c3f2cfa8c",
                "name": "Situa\u00e7\u00e3o do Requerimento",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "2f1a489d-dfd5-4dd1-8fe8-e54db6a63916",
              "name": "Data e Hora Agendada",
              "value": "20\/08\/2019(Ter\u00e7a-feira) \u00e0s 13:30",
              "procedure_input_type_id": 33,
              "created": "2022-07-20T22:53:05+00:00",
              "modified": "2022-07-20T22:53:05+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 33,
                "token": "6eed8902-4254-4c83-be90-00803ca97ce9",
                "name": "Data e Hora Agendada",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "d447d7f7-0225-41c5-9290-35692904dff4",
              "name": "Unidade",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - ARICANDUVA",
              "procedure_input_type_id": 34,
              "created": "2022-07-20T22:53:05+00:00",
              "modified": "2022-07-20T22:53:05+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 34,
                "token": "7f690348-b232-4e64-982d-1a86d52a9fad",
                "name": "Unidade",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "17eb810a-282b-4266-867d-9c8edc2a55fd",
              "name": "Endere\u00e7o",
              "value": "AVENIDA RIO DAS PEDRAS, 2.476, JARDIM ARICANDUVA - SAO PAULO - SP - CEP: 03.452-200",
              "procedure_input_type_id": 35,
              "created": "2022-07-20T22:53:05+00:00",
              "modified": "2022-07-20T22:53:05+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 35,
                "token": "8381ffe4-5281-4c30-bc74-ed0d224a8794",
                "name": "Endere\u00e7o",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "f24ab304-ad65-48cd-bb75-a8fcec1b9615",
              "name": "Protocolo",
              "value": "168197392",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:05+00:00",
              "modified": "2022-07-20T22:53:05+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "fc7ce0ad-cede-44b7-af38-6912b7b230ee",
              "name": "Data de Entrada do Requerimento",
              "value": "12\/08\/2019",
              "procedure_input_type_id": 36,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 36,
                "token": "e356e0c4-a944-4e8e-ac07-b142fc376271",
                "name": "Data de Entrada do Requerimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "8fb3dd35-aebf-4d7a-92d4-a26b6d068bee",
              "name": "Canal do Requerimento",
              "value": "Intranet",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "baba2fc0-6882-46e7-aabf-6d05417a6680",
              "name": "Observa\u00e7\u00e3o",
              "value": "A an\u00e1lise do requerimento ser\u00e1 confirmada ap\u00f3s o comparecimento do requerente ou seu representante na data e hora agendada.",
              "procedure_input_type_id": 37,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 37,
                "token": "8e239e82-e17d-4508-a3f8-40687b859508",
                "name": "Observa\u00e7\u00e3o",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "35540fcc-c26d-4edd-a32d-b5283d41df6b",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "61038cc0-cc49-4e61-99b5-6a04722df6bf",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "d5861edc-7c93-4723-b816-6a0d8c0f28d5",
              "name": "Data de Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 38,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 38,
                "token": "50ff61b2-8803-4f8e-8782-6689876417b7",
                "name": "Data de Nascimento",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "5e8a3dca-e384-4a50-bdda-120db1faa0a7",
              "name": "Celular",
              "value": "(11) 984745687",
              "procedure_input_type_id": 19,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 19,
                "token": "702a226e-bfc7-41fe-8e73-832d693b4de4",
                "name": "Celular",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "08f0f113-6510-4ec4-a0c1-d4a9e0b09c44",
              "name": "NIT Requerente",
              "value": "10289225512",
              "procedure_input_type_id": 39,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 39,
                "token": "91426131-89e3-45d1-8418-a795b71aefa8",
                "name": "NIT Requerente",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "8f8358c4-9b89-4825-bdec-4d8f911dac80",
              "name": "Possui todos os documentos solicitados?",
              "value": "Sim",
              "procedure_input_type_id": 40,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 40,
                "token": "72cd8192-3b84-414d-86a4-370e18426dc5",
                "name": "Possui todos os documentos solicitados?",
                "created": "2022-07-20T22:32:35+00:00",
                "modified": "2022-07-20T22:32:35+00:00",
                "deleted": null
              }
            }, {
              "token": "11284365-8c8e-4b4d-a5ee-142bf16771bd",
              "name": "NB",
              "value": "181.519.178-0",
              "procedure_input_type_id": 14,
              "created": "2022-07-20T22:53:06+00:00",
              "modified": "2022-07-20T22:53:06+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 14,
                "token": "efbd83b9-3705-488b-afdd-a2d1cf26d595",
                "name": "NB",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "fcfe5b83-ca6c-4ea1-919e-fbe2b91e1e75",
            "name": "358506316",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:03+00:00",
            "modified": "2022-07-20T22:53:03+00:00",
            "deleted": null,
            "procedure_movements": [{
              "token": "9b56ab6a-5aee-4540-bc28-7365c05263b5",
              "date": "2020-01-14T00:00:00+00:00",
              "name": "NB: 189.531.145-1<br\/><br\/><p style=\"transition: all 0.5s ease-in-out 0s, all 0.3s ease-in-out 0s;\">Prezado(a) Senhor(a), Nome: PAULO DIONE DOS SANTOS, CPF: 851.883.368-04<\/p><p style=\"transition: all 0.5s ease-in-out 0s, all 0.3s ease-in-out 0s;\"><br style=\"transition: all 0.5s ease-in-out 0s, all 0.3s ease-in-out 0s;\"\/><\/p><p style=\"transition: all 0.5s ease-in-out 0s, all 0.3s ease-in-out 0s;\">Pelas regras vigentes da Previd\u00eancia Social, o requerimento solicitado foi indeferido sob o n\u00famero de benef\u00edcio (NB) descrito acima. Aguarde correspond\u00eancia com as informa\u00e7\u00f5es ou acesse o portal de servi\u00e7os Meu INSS (meu.inss.gov.br).<\/p><p style=\"transition: all 0.5s ease-in-out 0s, all 0.3s ease-in-out 0s;\"><br style=\"transition: all 0.5s ease-in-out 0s, all 0.3s ease-in-out 0s;\"\/><\/p><p style=\"transition: all 0.5s ease-in-out 0s, all 0.3s ease-in-out 0s;\">Em caso de d\u00favidas sobre o resultado da solicita\u00e7\u00e3o do benef\u00edcio, \u00e9 poss\u00edvel agendar o servi\u00e7o de \"Socializa\u00e7\u00e3o de Informa\u00e7\u00f5es (individuais) - Servi\u00e7o Social\", por meio do telefone 135 ou do Meu INSS.<\/p>",
              "visualized": false,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null
            }],
            "procedure_inputs": [{
              "token": "aff4aab6-7899-4c46-a586-55f2a4a03239",
              "name": "cpf",
              "value": "85188336804",
              "procedure_input_type_id": 4,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 4,
                "token": "bd40ac20-0f03-4807-8d0c-51642d2d3545",
                "name": "cpf",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "1aea4567-7792-4a82-90c7-030f615ba99f",
              "name": "Servi\u00e7o",
              "value": "Aposentadoria por Tempo de Contribui\u00e7\u00e3o",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "6ef891ed-b555-4404-a618-83f4c7aefcad",
              "name": "Status",
              "value": "CONCLUIDA",
              "procedure_input_type_id": 6,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 6,
                "token": "b7db3414-b7bd-4128-9d7a-533c22a4ad8b",
                "name": "Status",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "b6936403-169a-4e44-857c-5a9d0e8f00d1",
              "name": "Unidade Respons\u00e1vel",
              "value": "Central de An\u00e1lise do INSS",
              "procedure_input_type_id": 7,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 7,
                "token": "ac10d3fb-4763-4150-a272-2716b3a2ff3f",
                "name": "Unidade Respons\u00e1vel",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "9f23ce42-61bf-41ad-9a46-864683f7ac8c",
              "name": "Unidade de Protocolo",
              "value": "APS S\u00c3O PAULO - VILA MARIANA",
              "procedure_input_type_id": 8,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 8,
                "token": "fadf57e9-c2aa-4cc4-8bc0-f2337d47e258",
                "name": "Unidade de Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "7127e016-e325-4338-9a7b-4f42b021481b",
              "name": "Protocolo",
              "value": "358506316",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "0e1c3bb3-f859-4563-a390-926b2cc68baa",
              "name": "Canal do Requerimento",
              "value": "INTERNET",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "699eaece-e0a5-49dd-b954-f1450f8ab44f",
              "name": "Data da Solicita\u00e7\u00e3o",
              "value": "30\/10\/2019",
              "procedure_input_type_id": 11,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 11,
                "token": "9e3cd601-03b1-44b1-a435-9f9e2a73550c",
                "name": "Data da Solicita\u00e7\u00e3o",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "54e1ce59-2d69-432b-8615-2a360da11c05",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "33ba2444-7c39-47ff-a63e-4f8d9c7bf5d5",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "fc17a7c2-97ff-49d2-908e-92bda2d941d0",
              "name": "Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 17,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 17,
                "token": "446848a1-1bae-4edf-bf4e-13854857fe96",
                "name": "Nascimento",
                "created": "2022-07-20T22:32:31+00:00",
                "modified": "2022-07-20T22:32:31+00:00",
                "deleted": null
              }
            }, {
              "token": "ecc6ce4d-ea18-407d-b205-c1bfe853e595",
              "name": "E-mail",
              "value": "jairo@raprevidencia.com.br",
              "procedure_input_type_id": 18,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 18,
                "token": "6056b3c3-3beb-4ad8-9a48-1295cbd03ca9",
                "name": "E-mail",
                "created": "2022-07-20T22:32:31+00:00",
                "modified": "2022-07-20T22:32:31+00:00",
                "deleted": null
              }
            }, {
              "token": "22f02fe5-980f-4241-a4d9-8827ea9e2693",
              "name": "Telefone Fixo",
              "value": "(11) 3159-1372",
              "procedure_input_type_id": 27,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 27,
                "token": "478a342c-42ec-4346-8e30-fff095b4ef24",
                "name": "Telefone Fixo",
                "created": "2022-07-20T22:32:33+00:00",
                "modified": "2022-07-20T22:32:33+00:00",
                "deleted": null
              }
            }, {
              "token": "f678ba38-0aad-4617-97e9-984e9939e18c",
              "name": "Celular",
              "value": " (11) 9918-18055",
              "procedure_input_type_id": 19,
              "created": "2022-07-20T22:53:03+00:00",
              "modified": "2022-07-20T22:53:03+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 19,
                "token": "702a226e-bfc7-41fe-8e73-832d693b4de4",
                "name": "Celular",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "23e778ae-c6c3-41b7-be0c-137c7f45affa",
              "name": "NIT",
              "value": "10289225512",
              "procedure_input_type_id": 21,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 21,
                "token": "06b07420-ee71-4af1-9008-65e80b6a0c7e",
                "name": "NIT",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "dd843265-cbcb-4563-a973-d974a3776a71",
              "name": "Possui tempo especial?",
              "value": "NAO",
              "procedure_input_type_id": 28,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 28,
                "token": "b49b2c0f-7b13-490a-b884-08cd0f0a6a47",
                "name": "Possui tempo especial?",
                "created": "2022-07-20T22:32:33+00:00",
                "modified": "2022-07-20T22:32:33+00:00",
                "deleted": null
              }
            }, {
              "token": "f56394b2-03f1-4ba4-8464-785603f43423",
              "name": "Possui tempo rural?",
              "value": "NAO",
              "procedure_input_type_id": 22,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 22,
                "token": "e29a625e-7343-4aab-a907-3f5e44e53c0f",
                "name": "Possui tempo rural?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "d5d72843-2fc5-4fab-8a81-3cfc133e45e9",
              "name": "Trabalhou no exterior?",
              "value": "NAO",
              "procedure_input_type_id": 23,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 23,
                "token": "98f37b84-a8fc-492c-90ff-45cb94c05f25",
                "name": "Trabalhou no exterior?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "e4a03981-20eb-41d9-914d-e659fa04443c",
              "name": "Professor?",
              "value": "NAO",
              "procedure_input_type_id": 29,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 29,
                "token": "987842af-4556-4a35-9ac0-de5bc3361068",
                "name": "Professor?",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "a2211f37-a9b4-43d7-a4c7-863f3c61b846",
              "name": "Militar, Servidor?",
              "value": "NAO",
              "procedure_input_type_id": 24,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 24,
                "token": "d807e34c-f1a8-4ffb-b4ea-4a2f22de4aad",
                "name": "Militar, Servidor?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "202d492b-c44f-4556-a8d0-050df48c6e49",
              "name": "Aposentadoria proporcional?",
              "value": "NAO",
              "procedure_input_type_id": 30,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 30,
                "token": "b8c6cf33-1e50-4dc1-94eb-b86d0b3c3577",
                "name": "Aposentadoria proporcional?",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }, {
              "token": "52e676b4-0ecc-42b1-970d-b1acc080d30e",
              "name": "Caso n\u00e3o possua os requisitos ao benef\u00edcio na data de hoje, autoriza o INSS a alterar a data do pedido para atender \u00e0s condi\u00e7\u00f5es para o benef\u00edcio?",
              "value": "SIM",
              "procedure_input_type_id": 25,
              "created": "2022-07-20T22:53:04+00:00",
              "modified": "2022-07-20T22:53:04+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 25,
                "token": "87560d41-e2f4-4c7a-98e8-97ad07a48f84",
                "name": "Caso n\u00e3o possua os requisitos ao benef\u00edcio na data de hoje, autoriza o INSS a alterar a data do pedido para atender \u00e0s condi\u00e7\u00f5es para o benef\u00edcio?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "814844ac-634e-4386-aadb-fa32b127e669",
              "name": "Aceita acompanhar o requerimento via Meu INSS, e-mail ou Central 135?",
              "value": "Sim",
              "procedure_input_type_id": 31,
              "created": "2022-07-20T22:53:05+00:00",
              "modified": "2022-07-20T22:53:05+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 31,
                "token": "2351782f-434e-4f51-8f3a-80deccbceac4",
                "name": "Aceita acompanhar o requerimento via Meu INSS, e-mail ou Central 135?",
                "created": "2022-07-20T22:32:34+00:00",
                "modified": "2022-07-20T22:32:34+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "51dc662d-2daf-4fe9-bab2-bdde40f382dd",
            "name": "1545986242",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:01+00:00",
            "modified": "2022-07-20T22:53:01+00:00",
            "deleted": null,
            "procedure_movements": [{
              "token": "a2fba22e-b83c-4565-8ab9-771fd56a6036",
              "date": "2020-12-23T00:00:00+00:00",
              "name": "<p><strong>INSS - Instituto Nacional do Seguro Social<\/strong><\/p><p><strong>APS 21028060 - CARAPICUIBA, em 23 de dezembro de 2020<\/strong><\/p><p><strong><\/strong>41\/196.424.374-0<\/p><p><strong><\/strong>PAULO DIONE DOS SANTOS<\/p><p><strong><\/strong>Concess\u00e3o do Requerimento<\/p><p>1. Trata-se de Benef\u00edcio de Aposentadoria por Idade Urbana Concedido em raz\u00e3o do(a) Requerente ter completado a Idade de 65 anos (homem) \/ 60 anos (mulher); e ter comprovado a Car\u00eancia em n\u00famero de contribui\u00e7\u00f5es exigida na Data de Entrada do Requerimento - DER.<\/p><p>2. Foram considerados todos os v\u00ednculos regulares constantes no(s) documento(s) apresentado(s) (Carteira Profissional - CP ou Carteira de Trabalho - CTPS), e no Cadastro Nacional de Informa\u00e7\u00f5es Sociais - CNIS, nos termos do \u00a71\u00ba, art. 19, e \u00a72\u00ba, art. 62, ambos do Decreto n\u00ba 3.048\/99.<\/p><p>3. N\u00e3o houve a formula\u00e7\u00e3o de quaisquer exig\u00eancias no decorrer da an\u00e1lise do presente requerimento, em raz\u00e3o da documenta\u00e7\u00e3o apresentada e\/ou informa\u00e7\u00f5es constantes nos sistemas corporativos serem suficientes para a verifica\u00e7\u00e3o do direito pleiteado.<\/p><p>4. Benef\u00edcio concedido, e a tarefa correspondente encerrada nesta data.<\/p><p><strong>JOSU\u00c9 DE MELO MIRANDA<\/strong><\/p><p>T\u00c9CNICO DO SEGURO SOCIAL<\/p><p>Matr. 1525381<\/p>",
              "visualized": false,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null
            }, {
              "token": "8f469d78-8535-4c04-884a-401d2410c67f",
              "date": "2020-12-23T00:00:00+00:00",
              "name": "NB: 196.424.374-0<br\/><br\/><p class=\"ql-align-justify\">Prezado(a) Senhor(a), Nome: PAULO DIONE DOS SANTOS, CPF: 851.883.368-04<\/p><p class=\"ql-align-justify\"><br\/><\/p><p class=\"ql-align-justify\">Pelas regras vigentes da Previd\u00eancia Social, o requerimento solicitado foi CONCEDIDO sob o n\u00famero de benef\u00edcio (NB) descrito acima. Aguarde correspond\u00eancia com as informa\u00e7\u00f5es ou acesse o portal de servi\u00e7os Meu INSS (meu.inss.gov.br).<\/p><p>--<\/p><p>Josu\u00e9 de Melo Miranda<\/p><p>Siape: 1525381<\/p><p>T\u00e9cnico do Seguro Social<\/p>",
              "visualized": false,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null
            }],
            "procedure_inputs": [{
              "token": "75c53e6c-8bcc-4e42-9360-160a5e4d8aca",
              "name": "cpf",
              "value": "85188336804",
              "procedure_input_type_id": 4,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 4,
                "token": "bd40ac20-0f03-4807-8d0c-51642d2d3545",
                "name": "cpf",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "6b131f47-398c-4c3a-9876-c07ced390f73",
              "name": "Servi\u00e7o",
              "value": "Aposentadoria por Idade Urbana",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "46542960-6c18-43d3-8e16-1254e48a67db",
              "name": "Status",
              "value": "CONCLUIDA",
              "procedure_input_type_id": 6,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 6,
                "token": "b7db3414-b7bd-4128-9d7a-533c22a4ad8b",
                "name": "Status",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "1f0320f7-6350-44d5-a908-382f381976e4",
              "name": "Unidade Respons\u00e1vel",
              "value": "Central de An\u00e1lise do INSS",
              "procedure_input_type_id": 7,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 7,
                "token": "ac10d3fb-4763-4150-a272-2716b3a2ff3f",
                "name": "Unidade Respons\u00e1vel",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "67d6f7b8-b25e-462c-9026-05a470293dd9",
              "name": "Unidade de Protocolo",
              "value": "APS S\u00c3O PAULO - VILA MARIANA",
              "procedure_input_type_id": 8,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 8,
                "token": "fadf57e9-c2aa-4cc4-8bc0-f2337d47e258",
                "name": "Unidade de Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "59cf1737-a3dc-46c3-959a-7a1e4d0724bc",
              "name": "Protocolo",
              "value": "1545986242",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "654dde9b-00fb-4115-b905-8767532f7972",
              "name": "Canal do Requerimento",
              "value": "INTERNET",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "0393aac8-83fe-4633-924e-5b003b049101",
              "name": "Data da Solicita\u00e7\u00e3o",
              "value": "30\/10\/2020",
              "procedure_input_type_id": 11,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 11,
                "token": "9e3cd601-03b1-44b1-a435-9f9e2a73550c",
                "name": "Data da Solicita\u00e7\u00e3o",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "75fe136f-fe63-4fc4-bd78-605c0607d0f2",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "5702ca07-1bf7-47f4-a802-259ad9fd0eb2",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "3f85c693-eb1a-4b1e-b05e-ac4253704f61",
              "name": "Nascimento",
              "value": "29\/10\/1955",
              "procedure_input_type_id": 17,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 17,
                "token": "446848a1-1bae-4edf-bf4e-13854857fe96",
                "name": "Nascimento",
                "created": "2022-07-20T22:32:31+00:00",
                "modified": "2022-07-20T22:32:31+00:00",
                "deleted": null
              }
            }, {
              "token": "0224bb36-72b8-4e80-9ce3-f65ba99fdf6b",
              "name": "E-mail",
              "value": "jairo@raprevidencia.com.br",
              "procedure_input_type_id": 18,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 18,
                "token": "6056b3c3-3beb-4ad8-9a48-1295cbd03ca9",
                "name": "E-mail",
                "created": "2022-07-20T22:32:31+00:00",
                "modified": "2022-07-20T22:32:31+00:00",
                "deleted": null
              }
            }, {
              "token": "7f4d54e5-ab0c-427d-a3ab-17ccc2cd6822",
              "name": "Celular",
              "value": " (11) 9918-18055",
              "procedure_input_type_id": 19,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 19,
                "token": "702a226e-bfc7-41fe-8e73-832d693b4de4",
                "name": "Celular",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "5f77b3eb-6f60-4581-885a-22e8c5752ce0",
              "name": "Voc\u00ea aceita acompanhar o andamento do processo pelo Meu INSS, Central 135 ou e-mail?",
              "value": "SIM",
              "procedure_input_type_id": 20,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 20,
                "token": "c2db71b5-da12-496b-abe8-0c9e7f5877b9",
                "name": "Voc\u00ea aceita acompanhar o andamento do processo pelo Meu INSS, Central 135 ou e-mail?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "18cc1df1-b8c8-4ebf-8b32-45ee08da436d",
              "name": "NIT",
              "value": "10289225512",
              "procedure_input_type_id": 21,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 21,
                "token": "06b07420-ee71-4af1-9008-65e80b6a0c7e",
                "name": "NIT",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "b83df6ef-9850-4b2f-9b47-2eb40684ad93",
              "name": "Possui tempo rural?",
              "value": "NAO",
              "procedure_input_type_id": 22,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 22,
                "token": "e29a625e-7343-4aab-a907-3f5e44e53c0f",
                "name": "Possui tempo rural?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "0bf98717-82f0-41b7-9193-23ab7bbdde90",
              "name": "Trabalhou no exterior?",
              "value": "NAO",
              "procedure_input_type_id": 23,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 23,
                "token": "98f37b84-a8fc-492c-90ff-45cb94c05f25",
                "name": "Trabalhou no exterior?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "22d99b35-6e1c-45ad-886b-14b175fd1a87",
              "name": "Militar, Servidor?",
              "value": "NAO",
              "procedure_input_type_id": 24,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 24,
                "token": "d807e34c-f1a8-4ffb-b4ea-4a2f22de4aad",
                "name": "Militar, Servidor?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "79b74e50-9fe1-4f8d-a1ac-df64f559b856",
              "name": "Caso n\u00e3o possua os requisitos ao benef\u00edcio na data de hoje, autoriza o INSS a alterar a data do pedido para atender \u00e0s condi\u00e7\u00f5es para o benef\u00edcio?",
              "value": "SIM",
              "procedure_input_type_id": 25,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 25,
                "token": "87560d41-e2f4-4c7a-98e8-97ad07a48f84",
                "name": "Caso n\u00e3o possua os requisitos ao benef\u00edcio na data de hoje, autoriza o INSS a alterar a data do pedido para atender \u00e0s condi\u00e7\u00f5es para o benef\u00edcio?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }, {
              "token": "9979476c-907a-4e39-907e-90b0acdb2aef",
              "name": "Possui aposentadoria ou pens\u00e3o RPPS?",
              "value": "NAO",
              "procedure_input_type_id": 26,
              "created": "2022-07-20T22:53:02+00:00",
              "modified": "2022-07-20T22:53:02+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 26,
                "token": "c7d1ffeb-2a65-4caa-997e-25134ed8592d",
                "name": "Possui aposentadoria ou pens\u00e3o RPPS?",
                "created": "2022-07-20T22:32:32+00:00",
                "modified": "2022-07-20T22:32:32+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "52312458-dcef-48d7-8be7-495c08ed608b",
            "name": "1688203295",
            "procedure_type_id": 2,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:53:00+00:00",
            "modified": "2022-07-20T22:53:00+00:00",
            "deleted": null,
            "procedure_movements": [{
              "token": "8b0862d2-9501-4910-aab1-a144aa7c7a0d",
              "date": "2021-01-15T00:00:00+00:00",
              "name": "Tarefa conclu\u00bfda, considerando que o processo de recurso que deu origem \u00bf tarefa encontra-se em \u00bfrg\u00bfo do CRPS pendente de decis\u00bfo.",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }, {
              "token": "f2a5c9e2-adb0-411b-9d02-2dea9024c200",
              "date": "2021-05-24T00:00:00+00:00",
              "name": "Atualiza\u00e7\u00e3o e-SISREC: Protocolo (GET): 1688203295. Protocolo (e-Sisrec): 44233416174201860.<br\/>\u00daltimo evento: Sess\u00e3o de Julgamento Ordin\u00e1ria - N\u00ba 0255\/2021 - 14\/06\/21 15:30. \u00d3rg\u00e3o: 1\u00aaCA 5\u00aa JR.<br\/>Situa\u00e7\u00e3o do processo: Protocolo DPU.",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }, {
              "token": "027da085-afb9-4465-af65-af8faca391c8",
              "date": "2021-06-16T00:00:00+00:00",
              "name": "Atualiza\u00e7\u00e3o e-SISREC: Protocolo (GET): 1688203295. Protocolo (e-Sisrec): 44233416174201860.<br\/>\u00daltimo evento: Encaminhamento autom\u00e1tico - 1\u00aaCA 5\u00aa JR para 2150112.",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }, {
              "token": "2dc3d3df-b849-4ec5-83b8-a74c51b122f0",
              "date": "2021-06-16T00:00:00+00:00",
              "name": "Atualiza\u00e7\u00e3o e-SISREC: Protocolo (GET): 1688203295. Protocolo (e-Sisrec): 44233416174201860.<br\/>\u00daltimo evento: Conhecer do Recurso e negar-lhe provimento, por unanimidade - Ac\u00f3rd\u00e3o: 1\u00aaCA 5\u00aa JR\/5095\/2021. \u00d3rg\u00e3o: 1\u00aaCA 5\u00aa JR.<br\/>Situa\u00e7\u00e3o do processo: Recurso Ordin\u00e1rio N\u00e3o Provido.",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }],
            "procedure_inputs": [{
              "token": "a7aef741-4ee9-407e-a273-e22417f7fd4c",
              "name": "cpf",
              "value": "85188336804",
              "procedure_input_type_id": 4,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 4,
                "token": "bd40ac20-0f03-4807-8d0c-51642d2d3545",
                "name": "cpf",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "c2045a7f-3562-45b7-b607-2f363c926a4e",
              "name": "Servi\u00e7o",
              "value": "Recurso Ordin\u00e1rio (Inicial)",
              "procedure_input_type_id": 5,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 5,
                "token": "675e7cef-272e-40ea-8423-2a1571a35446",
                "name": "Servi\u00e7o",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "460ac4aa-9fd6-486b-b7b0-61b471e3ac05",
              "name": "Status",
              "value": "EM AN\u00c1LISE",
              "procedure_input_type_id": 6,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 6,
                "token": "b7db3414-b7bd-4128-9d7a-533c22a4ad8b",
                "name": "Status",
                "created": "2022-07-20T22:32:29+00:00",
                "modified": "2022-07-20T22:32:29+00:00",
                "deleted": null
              }
            }, {
              "token": "dc4709a5-2e92-428f-98a6-93cfdb4ee800",
              "name": "Unidade Respons\u00e1vel",
              "value": "Central de An\u00e1lise do INSS",
              "procedure_input_type_id": 7,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 7,
                "token": "ac10d3fb-4763-4150-a272-2716b3a2ff3f",
                "name": "Unidade Respons\u00e1vel",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "27e54363-34b0-4817-a2b9-fcf1b49a1bc4",
              "name": "Unidade de Protocolo",
              "value": "APS S\u00c3O PAULO - CIDADE ADEMAR",
              "procedure_input_type_id": 8,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 8,
                "token": "fadf57e9-c2aa-4cc4-8bc0-f2337d47e258",
                "name": "Unidade de Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "7450b319-77a7-47e6-af42-1c5163e8b64f",
              "name": "Protocolo",
              "value": "1688203295",
              "procedure_input_type_id": 9,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 9,
                "token": "1b787b84-be14-4784-95cc-5824b2fce513",
                "name": "Protocolo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "e7ee2afd-6d72-4c1b-8f4b-b41d678a12f2",
              "name": "Canal do Requerimento",
              "value": "APLICACAO_PARCEIRA",
              "procedure_input_type_id": 10,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 10,
                "token": "49c30f8e-dac6-4bba-90f0-17032a95bf68",
                "name": "Canal do Requerimento",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "adb8e438-031b-4403-9e4b-b46b5da25e7f",
              "name": "Data da Solicita\u00e7\u00e3o",
              "value": "25\/12\/2020",
              "procedure_input_type_id": 11,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 11,
                "token": "9e3cd601-03b1-44b1-a435-9f9e2a73550c",
                "name": "Data da Solicita\u00e7\u00e3o",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "90dc440c-3ffd-4f18-ba43-f6fa6e91dca0",
              "name": "Nome Completo",
              "value": "PAULO DIONE DOS SANTOS",
              "procedure_input_type_id": 12,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 12,
                "token": "42963839-7f36-42ba-a91d-348387d5b824",
                "name": "Nome Completo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "456b65dd-c2e1-4fc9-a1a3-1f7c1e10457c",
              "name": "CPF",
              "value": "85188336804",
              "procedure_input_type_id": 13,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 13,
                "token": "f2772846-6321-4307-8637-3d81068d8eb0",
                "name": "CPF",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "b64d6072-6fe0-4d3e-82d4-dc4fa049f166",
              "name": "NB",
              "value": "1815191780",
              "procedure_input_type_id": 14,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 14,
                "token": "efbd83b9-3705-488b-afdd-a2d1cf26d595",
                "name": "NB",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "0c456312-553b-41be-9d23-415effe679e5",
              "name": "Status do processo",
              "value": "D) Aguardando Cumprimento de Ac\u00f3rd\u00e3o",
              "procedure_input_type_id": 15,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 15,
                "token": "bac44218-f0a5-4334-924c-89209350aabd",
                "name": "Status do processo",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }, {
              "token": "64eba71a-9e67-410d-90d7-6276b83784a0",
              "name": "Protocolo de Recurso Ordin\u00e1rio",
              "value": "44233416174201860",
              "procedure_input_type_id": 16,
              "created": "2022-07-20T22:53:01+00:00",
              "modified": "2022-07-20T22:53:01+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 16,
                "token": "17138774-b657-4cca-9f0e-d22c7ad090a6",
                "name": "Protocolo de Recurso Ordin\u00e1rio",
                "created": "2022-07-20T22:32:30+00:00",
                "modified": "2022-07-20T22:32:30+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 2,
              "name": "Pedidos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }, {
            "token": "9cd70be1-b29d-4984-8785-a2a5760a5329",
            "name": "44233.416174\/2018-60",
            "procedure_type_id": 1,
            "lawsuit_id": 70099,
            "office_id": 35,
            "created": "2022-07-20T22:52:59+00:00",
            "modified": "2022-07-20T22:52:59+00:00",
            "deleted": null,
            "procedure_movements": [{
              "token": "8107f813-785a-4e5f-b825-a5bd7e300f85",
              "date": "2021-06-16T00:00:00+00:00",
              "name": "Conhecer do Recurso e negar-lhe provimento, por unanimidade - Ac\u00f3rd\u00e3o: 1\u00aaCA 5\u00aa JR\/5095\/2021",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "2c603d48-a61f-4802-a9f0-5569c9a67f94",
              "date": "2021-05-24T00:00:00+00:00",
              "name": "Sess\u00e3o de Julgamento Ordin\u00e1ria - N\u00ba 0255\/2021 - 14\/06\/21 15:30",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "c42bbea6-ae42-4034-9b0d-0f2ac14b5ae8",
              "date": "2021-02-14T00:00:00+00:00",
              "name": "Altera\u00e7\u00e3o da APS Respons\u00e1vel - (De: 21004060 - AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO - CIDADE ADEMAR Para: 21001800 - AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL CEAB RECONHECIMENTO DE DIREITO DA SRI)",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "3b33f756-63d2-421a-b2d6-1804b18c20e5",
              "date": "2020-12-25T00:00:00+00:00",
              "name": "Tarefa de requerimento de recurso ordin\u00e1rio criada no GET - Tarefa 1688203295",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "efaaddc9-178e-433b-9675-1f2fcf9e4b89",
              "date": "2020-08-08T00:00:00+00:00",
              "name": "Dilig\u00eancia n\u00e3o cumprida",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "cdc300d4-477c-4de4-97af-8fbfbeac2a48",
              "date": "2020-05-24T00:00:00+00:00",
              "name": "Desativa\u00e7\u00e3o do \u00f3rg\u00e3o - 21004050 - AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL S\u00c3O PAULO-VILA MARIANA",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "e3711cfd-5b78-41c4-b9d7-85723e3ed86a",
              "date": "2019-08-26T00:00:00+00:00",
              "name": "Juntada de documento",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "306d6df9-13b7-4283-a84f-0b6d4a921d71",
              "date": "2019-07-01T00:00:00+00:00",
              "name": "Juntada de documentos",
              "visualized": false,
              "created": "2022-07-20T22:52:59+00:00",
              "modified": "2022-07-20T22:52:59+00:00",
              "deleted": null
            }, {
              "token": "bc8fb564-d8a7-48de-9936-4c74cf72c8d6",
              "date": "2018-08-22T00:00:00+00:00",
              "name": "Solicita\u00e7\u00e3o de Dilig\u00eancia Preliminar",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }, {
              "token": "7334d22f-6aa0-437c-9e1c-5338eacb8a60",
              "date": "2018-08-21T00:00:00+00:00",
              "name": "Distribu\u00eddo ao Conselheiro Relator - NEURACY CUNHA CAMARA KANSSAO",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }, {
              "token": "909f4f6d-6ea1-4cfb-a47c-6e26533b58df",
              "date": "2018-08-08T00:00:00+00:00",
              "name": "Redistribui\u00e7\u00e3o para outro \u00f3rg\u00e3o julgador",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }, {
              "token": "9ed1998f-a4bc-4656-9236-b913fafe6680",
              "date": "2018-06-12T00:00:00+00:00",
              "name": "Processo gerador juntado",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }, {
              "token": "409078b1-65b9-4562-8f49-de4cdd6df63a",
              "date": "2018-01-23T00:00:00+00:00",
              "name": "Protocolo Recebido no INSS",
              "visualized": false,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null
            }],
            "procedure_inputs": [{
              "token": "73194c04-fdba-4024-b74c-8509cc58bf9e",
              "name": "orgao_atual",
              "value": "Central Especializada de Suporte CES da SRI",
              "procedure_input_type_id": 1,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 1,
                "token": "d0d05909-9214-4f11-993b-e70a1c0801a9",
                "name": "orgao_atual",
                "created": "2022-07-20T21:57:06+00:00",
                "modified": "2022-07-20T21:57:06+00:00",
                "deleted": null
              }
            }, {
              "token": "acd1d9a3-d4ab-4eb1-aac5-29e083f6b529",
              "name": "agencia_da_previdencia_social_de_origem",
              "value": "AG\u00caNCIA DA PREVID\u00caNCIA SOCIAL CEAB RECONHECIMENTO DE DIREITO DA SRI",
              "procedure_input_type_id": 2,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 2,
                "token": "072c98ce-5a94-45c4-9621-a56510ab8ec7",
                "name": "agencia_da_previdencia_social_de_origem",
                "created": "2022-07-20T21:57:40+00:00",
                "modified": "2022-07-20T21:57:40+00:00",
                "deleted": null
              }
            }, {
              "token": "41dd6a07-3014-4664-8b18-0d361c49e200",
              "name": "recorrente",
              "value": "PAULO DIONE DOS SANTOS | JAIRO MAGNO LOPES",
              "procedure_input_type_id": 3,
              "created": "2022-07-20T22:53:00+00:00",
              "modified": "2022-07-20T22:53:00+00:00",
              "deleted": null,
              "procedure_input_type": {
                "id": 3,
                "token": "3dff431f-8233-4ef1-9d8c-0be12238b486",
                "name": "recorrente",
                "created": "2022-07-20T21:57:40+00:00",
                "modified": "2022-07-20T21:57:40+00:00",
                "deleted": null
              }
            }],
            "procedure_type": {
              "id": 1,
              "name": "Recursos Inss",
              "created": null,
              "modified": null,
              "deleted": null
            }
          }]
        }*/

        //console.log("Procedure:", procedure);
    
        //lawsuit["procedures"] = procedure.procedures;

        if( typeof lawsuit.procedures !== undefined ){
          //tratamento do processo do INSS para separar em abas de pedidos e recursos
          lawsuit["pedidos"] = [];
          lawsuit["recursos"] = [];

          for(let i=0; i < lawsuit.procedures.length; i++){
              if ( lawsuit.procedures[i].procedure_type_id == 2 ){
                lawsuit["pedidos"].push(lawsuit.procedures[i]);
              }else if ( lawsuit.procedures[i].procedure_type_id == 1 ){
                lawsuit["recursos"].push(lawsuit.procedures[i]);
              }
          }
        }

        return lawsuit;
    
    }

    list( type, highlight = null, archived = null, page = 1 ) {
      if ( typeof type === 'undefined' || type === 0 || type === null ){
            let comps = "";
            if(highlight !== null){
              comps = "&favorito=1";
            }else{
              comps = "&favorito=0";
            }
            if(archived !== null){
              comps += "&arquivo=1";
            }else{
              comps += "&arquivo=0";
            }
            return this.fetch(`${this.domain}Lawsuits/index/?list_manual=1${comps}&page=${page}`, {
                method: 'GET',
            }).then(res => {
                //console.log(res);

                if( res.success === true ){
                  res.data.Lawsuits.map((value, index, array )=>{
                    res.data.Lawsuits[index].key = value.token;
                    return res.data.Lawsuits[index];
                  });
                  return Promise.resolve(res);
                }else {
                  return Promise.resolve(res.message);
                }

            });
      }else{
          let comps = "";
          if(highlight !== null){
            comps = "&favorito=1";
          }else{
            comps = "&favorito=0";
          }
          if(archived !== null){
            comps += "&arquivo=1";
          }else{
            comps += "&arquivo=0";
          }
          return this.fetch(`${this.domain}Lawsuits/?tribunal_id=${type}${comps}&page=${page}`, {
              method: 'GET',
          }).then(res => {

            //console.log(res);

              if( res.success === true ){
                res.data.Lawsuits.map((value, index, array )=>{
                  res.data.Lawsuits[index].key = value.token;
                  return res.data.Lawsuits[index];
                });
                return Promise.resolve(res);
              }else {
                return Promise.resolve(res.message);
              }

          });
      }

    }

    find( who, what, tribunals ) {

            return this.fetch(`${this.domain}lawsuits/?tipo_busca=${what}&q=${who}${tribunals}`, {
                method: 'GET',
            }).then(res => {
                //console.log(res);

                if( res.success === true ){
                  res.data.Lawsuits.map((value, index, array )=>{
                    res.data.Lawsuits[index].key = value.token;
                    return res;
                  });
                  return Promise.resolve(res);
                }else {
                  return Promise.resolve(res.message);
                }

            }).catch(err =>{
              return Promise.resolve("false");
            });
    }

    officeList() {
      let comboLawsuitList = JSON.parse(localStorage.getItem('comboLawsuitList'));
      if( 
        ( comboLawsuitList == null || 
          comboLawsuitList == 0 || 
          comboLawsuitList == "undefined" ||
          typeof comboLawsuitList.date == "undefined" 
        ) || 
          moment().diff(comboLawsuitList.date, 'minutes',true ) > 10 
      ){
          return this.fetch(`${this.domain}Lawsuits/index/?list=true`, {
              method: 'GET',
          }).then(res => {
              var i = 0;

              if( res.success === true ){
                var response = [];
                res.data.Lawsuits.map((value, index, array )=>{
                  response[index] = { "key": array.length-i, "value": value.token, "label": ( value.number !== null )? value.number: value.name };
                  i++;
                  return response;
                });
                localStorage.setItem('comboLawsuitList', JSON.stringify({ lawsuits: response, date: moment() } ) );
                return Promise.resolve(response);
              }else {
              //console.log(res.message);
                return Promise.resolve([]);
              }

          });
        }else{
          return Promise.resolve(comboLawsuitList.lawsuits);
        }
    }

    relatedList( token ){
        //console.log(`${this.domain}LawsuitsRelateds/index/${token}`);
        return this.fetch(`${this.domain}LawsuitsRelateds/index/${token}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);

            if( res.success === true ){
              var response = [];
              //let i = 0;
              res.LawsuitsRelateds.map((value, index, array )=>{ //console.log(value);
                if ( value.lawsuits1 !== null ){
                  response.push({ "key": index, "token": value.lawsuits1.token, "number": value.lawsuits1.number,
                                      "tribunal_name": value.lawsuits1.tribunal_name, "tribunal_id": value.lawsuits1.tribunal_id,
                                      "lawsuit_name": value.lawsuits1.name
                               });
                  //i++;
                }
                  if( value.lawsuits2 !== null ){
                    response.push({ "key": index, "token": value.lawsuits2.token, "number": value.lawsuits2.number,
                                        "tribunal_name": value.lawsuits2.tribunal_name, "tribunal_id": value.lawsuits2.tribunal_id,
                                        "lawsuit_name": value.lawsuits2.name
                                 });

                  }
                  //i++;
                //}
                return response;
              });
              return Promise.resolve(response);
            }else {
              return Promise.resolve([]);
            }

        });
    }

    toRelate( data ) {
      return this.fetch(`${this.domain}LawsuitsRelateds/add`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteRelationship( token, token2 ) {

      return this.fetch(`${this.domain}LawsuitsRelateds/delete/${token}/${token2}`, {
          method: 'DELETE',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })

    }

    delete( token ) {

      return this.fetch(`${this.domain}lawsuits/${token}`, {
          method: 'DELETE',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })

    }

    counterByTribunal( tribunal_id ){
      return this.fetch(`${this.domain}Lawsuits/counterByTribunal/${tribunal_id}`, {
          method: 'GET',
      }).then(res => {
          if( res.success === true ){
            return Promise.resolve(res.data.count);
          }else{
            return Promise.resolve(0);
          }
      });
      //return Promise.resolve(7);
    }

    atualizacaoAutomatica( token ){
      return this.fetch(`${this.domain}lawsuits/atualizaoAutomatica/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    report( lawsuit, client, destaque, sponsor, arquivo, tipo_parte, pasta, area, tipo, comarca, fase, filial, statusLawsuitTribunal, perda, date_movimento_start, date_movimento_end, movement_start, tribunals, created_start, created_end, dataDistribuicao_start, dataDistribuicao_end, page=1 ){

      if( lawsuit === null ){
        lawsuit = "";
      }else{
        lawsuit = "&lawsuit_token="+lawsuit.value;
      }

      if( client === null ){
        client = "";
      }else{
       // if(client.value == client.label){
          client = "&cliente_name="+client.label;
        //}else{
          //client = "&cliente_token="+client.value;
        //}
      }

      if( destaque === null ){
        destaque = "";
      }else{
        destaque = "&favorito="+destaque.value;
      }

      if( sponsor === null ){
        sponsor = "";
      }else{
        sponsor = "&user_token="+sponsor.value;
      }

      if( arquivo === null ){ //está lá no front como state.status
        arquivo = "";
      }else{
        arquivo = "&arquivo="+arquivo.value;
      }

      if( tipo_parte === null ){
        tipo_parte = "";
      }else{
        tipo_parte = "&personage_type_id="+tipo_parte.value;
      }

      if( pasta === "" ){
        pasta = "";
      }else{
        pasta = "&pasta="+pasta;
      }

      if( area === null ){
        area = "";
      }else{
        area = "&area="+area.label;
      }

      if( tipo === null ){
        tipo = "";
      }else{
        tipo = "&tipo="+tipo.label;
      }

      if( comarca === null ){
        comarca = "";
      }else{
        comarca = "&comarca="+comarca.label;
      }

      if( fase === null ){
        fase = "";
      }else{
        fase = "&faseProcessual="+fase.label;
      }

      if( filial === null ){
        filial = "";
      }else{
        filial = "&filial="+filial.label;
      }

      if( statusLawsuitTribunal === null){
        statusLawsuitTribunal = "";
      }else{
        statusLawsuitTribunal = "&status="+statusLawsuitTribunal.label;
      }

      if( perda === null ){
        perda = "";
      }else{
        perda = "&perda="+perda.label;
      }

      if( date_movimento_start === null ){
        date_movimento_start = "";
      }else{
        date_movimento_start = "&movement_last_start="+date_movimento_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( date_movimento_end === null ){
        date_movimento_end = "";
      }else{
        date_movimento_end = "&movement_last_end="+date_movimento_end.substr(0, 10).split('/').reverse().join('-');
      }

      if( movement_start === null ){
        movement_start = "";
      }else{
        movement_start= "&movement_start="+movement_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( created_start === null ){
        created_start = "";
      }else{
        created_start = "&created_start="+created_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( created_end === null ){
        created_end = "";
      }else{
        created_end = "&created_end="+created_end.substr(0, 10).split('/').reverse().join('-');
      }

      if( dataDistribuicao_start === null ){
        dataDistribuicao_start = "";
      }else{
        dataDistribuicao_start = "&dataDistribuicao_start="+dataDistribuicao_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( dataDistribuicao_end === null ){
        dataDistribuicao_end = "";
      }else{
        dataDistribuicao_end = "&dataDistribuicao_end="+dataDistribuicao_end.substr(0, 10).split('/').reverse().join('-');
      }

      let additionalData = pasta+area+tipo+comarca+fase+filial+statusLawsuitTribunal+perda;

      let dates = date_movimento_start+date_movimento_end+movement_start+created_start+created_end+dataDistribuicao_start+dataDistribuicao_end;

      //console.log(`${this.domain}lawsuits/${tribunals}${lawsuit}${client}${destaque}${sponsor}${arquivo}${tipo_parte}${additionalData}${dates}`);

      return this.fetch( `${this.domain}lawsuits/${tribunals}${lawsuit}${client}${destaque}${sponsor}${arquivo}${tipo_parte}${additionalData}${dates}&page=${page}`, {
          method: 'GET',
      }).then(res => {
        if( res.success === true  ){
          return Promise.resolve(res);
        }else{
          return Promise.resolve(res);
        }
      });
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
            return Promise.resolve({
              message: "Você não tem permissão para acessar o sistema neste momento."
            });
          }else{
    

              return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                      if(typeof response !== "undefined"){

                            return Promise.resolve( response.text().then(function(result){
                                //console.log(result);
                                let old_result = result;
                                //result = result.split('{"success"');
                                if( result.length > 1 ){
                                    //result = result[1].split('<pre');
                                    //console.log(result[0]);
                                    //let json = '{"success"'+result[0];
                                    let json = result;
                                    result = JSON.parse(json);
                                    //console.log(result);
                                    return result;
                                }else{
                                    return old_result;
                                }

                            }) );

                      }else{

                            let result = {
                                success: false,
                                message: "Erro desconhecido"
                            }

                            return Promise.resolve( result );

                        }

                  });

          }
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
         //console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
          window.location.href = '/config/mudar-plano?plano_vencido';
  
        }else {
           //console.log(response);
            return response;
            //alert(response.text);
            /*var error = new Error(response.statusText)
            error.response = response
            throw error*/
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => {
                let res = {success: false, message: "Servidor não retornou os dados"};
                try {
                  res = response.json()
                }catch(e) {
                   //console.log(e);
                    return res;
                }
                return res;
            })
    }*/
}
