import React from 'react';
import 'antd/dist/antd.css';
import { Divider } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import Alert from '../../containers/alert';
import Confirm from '../../containers/confirm';

import LawsuitPersonagesService from '../../../services/LawsuitPersonagesService';
import AuthService from '../../../services/AuthService';

import AddPersonagesLawsuit from './addPersonagesLawsuit';
import EditPersonagesLawsuit from './editPersonagesLawsuit';

import moment from "moment";
import 'moment/locale/pt-br';

export default class PersonagesLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);
      this.myRef = React.createRef();

      this.lawsuitPersonagesService = new LawsuitPersonagesService();
      this.authService = new AuthService();

      let cliente = false;
      let oponente = false;

      props.lawsuit.lawsuits_personages.map((value, index, array )=>{
        if ( value.personage_side_id === 1 ){
          cliente = true;
        }
        if ( value.personage_side_id === 2 ){
          oponente = true;
        }
        return value;
      });

      this.state = {
          cliente,
          oponente,
          formIsHalfFilledOut: (cliente && oponente) ? false : true,
          opener: false,
          personageToEdit: "",
          showTable: false,
          isPopoverOpen: false,
          isActionPopoverOpen: false,
          showConfirm: false,
          personageLawsuitToDelete: false
      }

      this.modifiedPersonages = this.props.modifiedPersonages.bind(this);

      this.incluirParte = this.incluirParte.bind(this);
      this.markClient = this.markClient.bind(this);
      this.markOponent = this.markOponent.bind(this);
      this.openEditPersonage = this.openEditPersonage.bind(this);
      this.deletePersonageLawsuit = this.deletePersonageLawsuit.bind(this);
      this.parteItem = this.parteItem.bind(this);
      this.roll = this.roll.bind(this);
 }

 componentWillReceiveProps( props ){

         let cliente = false;
         let oponente = false;

         props.lawsuit.lawsuits_personages.map((value, index, array )=>{
           if ( value.personage_side_id === 1 ){
             cliente = true;
           }
           if ( value.personage_side_id === 2 ){
             oponente = true;
           }
           return value;
         });

         //console.log( "props: "+props.formIsHalfFilledOut)
         if ( props.formIsHalfFilledOut === false || (cliente && oponente) ){
           this.setState({
             formIsHalfFilledOut: false
           })
         }

         var url_string = window.location.href;
          var url = new URL(url_string);
          var parte = url.searchParams.get("parte");
         //console.log(parte);

         if ( parte !== null ) {
            document.getElementById("personages-lawsuit-target").className += ' in';
            setTimeout(() => {
              window.scrollTo(0, 621)
            }, 2200);
          }

         this.setState({
             cliente: cliente,
             oponente: oponente
         });
 }

 componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,          
          });
        }
      });
  
      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
          });
  
          if( this.state.personageLawsuitToDelete !== false ){
              this.deletePersonageLawsuit(this.state.personageLawsuitToDelete, true);
          }
        }
      });

      document.addEventListener("cancelAlert", (e) => {
          this.setState({
          showAlert: false
          });
      });

      var getParentAnchor =  (element) => {
        while (element !== null) {
          if (typeof element.tagName !== "undefined" && element.tagName.toUpperCase() === "A" && element.href.indexOf("#") === -1  ) {
            //console.log(element.href);
            return element;
          }
          element = element.parentNode;
        }
        return null;
      };
      
      document.querySelector("body").addEventListener('click', (e) => {
        //console.log(e.target.id);
        let test = this.state.formIsHalfFilledOut;
        let shouldTest = false;
        let message = "Não foram selecionados Cliente e Oponente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções! \n Selecione o Cliente e o Oponente!";
        if( document.getElementById("lawsuit_manual") && document.getElementById("lawsuit_manual").value === "manual" ) {
          if( document.getElementById("cliente") ){
            test = (document.getElementById("cliente").value === "true") ? false : true;
          }
          shouldTest = true;
          message = "Não foi incluído um Cliente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções! \n Selecione ou inclua um Cliente para este processo.";
        }else if( document.getElementById("lawsuit_status") && document.getElementById("lawsuit_status").value === "CADASTRADO" ){
          shouldTest = true;
        }

        if( this._isMounted ){
          var anchor = getParentAnchor(e.target);
          if( anchor !== null && test && shouldTest ) {
            this.setState({
              showAlert: true,
              message: message
            });
            e.preventDefault();
          }else if( e.target.id == "btn-back" ){
            this.props.history.goBack();
          }
        }


      }, false);

      var url_string = window.location.href;
      var url = new URL(url_string);
      var parte = url.searchParams.get("parte");
      var manual = url.searchParams.get("manual");
     //console.log(parte);

      if(parte !== null){
        document.getElementById("personages-lawsuit-target").className += ' in';
            setTimeout(() => {
              window.scrollTo(0, 621)
            }, 2200);
      }

      if(manual !==null){
          document.getElementById("table-partes").className += ' hide';
          document.getElementById("bnt-incluir-parte").className += ' hide';
          document.getElementById("help_icon_incluir_parte").className += ' hide';
          document.getElementById("addPersonagesLawsuit").classList.remove("hide");
      }

 }

  markClient ( event ) {
    document.body.style.cursor = "wait";
    this.lawsuitPersonagesService = new LawsuitPersonagesService();

    var siblings = [].slice.call(event.target.parentNode.children) // convert to array
                 .filter(function(v) { return v !== event.target }); // remove element itself

    for (let n = siblings.length - 1; n >= 0; n--) {
         siblings[n].classList.remove("btn-success");
     }

     let personage_id = event.target.dataset.personage_id;
     let personage_type_id = event.target.dataset.personage_type_id;
     let personage_side_id = event.target.dataset.personage_side_id;

     let numero_processo_span = document.getElementById("lawsuit_token");
     let numero_processo = numero_processo_span.value;

     let data_lawsuit = {
       'personage_token': personage_id,
       'lawsuit_token': numero_processo,
       'personage_side_id': 1,
       'personage_type_id': personage_type_id,
     }
     if ( personage_side_id === "1" ){
       data_lawsuit.personage_side_id = 0;
       event.target.dataset.personage_side_id = 0;
       event.target.classList.remove("btn-success");
     }else{
       event.target.className += ' btn-success';
     }

     this.lawsuitPersonagesService.addToLawsuit( data_lawsuit ).then(res =>{

       if ( res.success === true ) {
       }else {
         //alert(res.message);
       }
       this.modifiedPersonages();
       document.body.style.cursor = "default";

     }).catch(err =>{
       //console.log(err);
        document.body.style.cursor = "default";
    });

     let cxsOponente = document.getElementsByClassName("cxoponente");
     let oponente = false;

     for (let n = cxsOponente.length - 1; n >= 0; n--) {

          if ( cxsOponente[n].classList.contains('btn-success') ) {
            oponente = true;
          }
      }

      this.setState({
         cliente: (personage_side_id === 1) ? true : false,
         oponente: oponente,
         formIsHalfFilledOut: ( personage_side_id === 1 && oponente ) ? false : true,
     });
  }

  markOponent ( event ) {
    document.body.style.cursor = "wait";
    this.lawsuitPersonagesService = new LawsuitPersonagesService();

    var siblings = [].slice.call(event.target.parentNode.children) // convert to array
                 .filter(function(v) { return v !== event.target }); // remove element itself

    for (let n = siblings.length - 1; n >= 0; n--) {
         siblings[n].classList.remove("btn-success");
     }

     let numero_processo_span = document.getElementById("lawsuit_token");
     let numero_processo = numero_processo_span.value;

     let personage_id = event.target.dataset.personage_id;
     let personage_type_id = event.target.dataset.personage_type_id;
     let personage_side_id = event.target.dataset.personage_side_id;

     let data_lawsuit = {
       'personage_token': personage_id,
       'lawsuit_token': numero_processo,
       'personage_side_id': 2,
       'personage_type_id': personage_type_id,
     }

     if ( personage_side_id === "2" ){
       data_lawsuit.personage_side_id = 0;
       event.target.dataset.personage_side_id = 0;
       event.target.classList.remove("btn-success");
     }else{
       event.target.className += ' btn-success';
     }

     this.lawsuitPersonagesService.addToLawsuit( data_lawsuit ).then(res =>{

       if ( res.success === true ) {
       }else {
         //alert(res.message);
       }

       this.modifiedPersonages();
       document.body.style.cursor = "default";

     }).catch(err =>{
       //console.log(err);
        document.body.style.cursor = "default";
    });

     let cxsCliente = document.getElementsByClassName("clientexo");
     let cliente = false;

     for (let n = cxsCliente.length - 1; n >= 0; n--) {

          if ( cxsCliente[n].classList.contains('btn-success') ) {
            cliente = true;
          }
      }

     this.setState({
       oponente: (personage_side_id === 2) ? true : false,
       cliente: cliente,
       formIsHalfFilledOut: ( cliente && personage_side_id === 2 ) ? false : true,
     });
  }

  roll = (event) => {

    if ( !event.target.classList.contains('collapsed') ) {
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop-65)
      }, 800);
    }

  }

  incluirParte() {

      document.getElementById("table-partes").className += ' hide';
      document.getElementById("bnt-incluir-parte").className += ' hide';
      document.getElementById("help_icon_incluir_parte").className += ' hide';
      document.getElementById("addPersonagesLawsuit").classList.remove("hide");

  }

  openEditPersonage( e ) {
    let personage = e.target.dataset.personage;
    let personage_type_id = e.target.dataset.personage_type_id;
    let personage_side_id = e.target.dataset.personage_side_id;
    this.setState({
      personageToEdit: personage,
      side: personage_side_id,
      type: personage_type_id
    });

    document.getElementById("table-partes").className += ' hide';
    document.getElementById("bnt-incluir-parte").className += ' hide';
    document.getElementById("help_icon_incluir_parte").className += ' hide';
    document.getElementById("editPersonagesLawsuit").classList.remove("hide");
  }

  deletePersonageLawsuit( e, r = false ) {
    document.body.style.cursor = "wait";

    //let result = window.confirm( "Deseja excluir definitivamente esta Parte do processo?");

    if ( r ) {

      this.LawsuitPersonages = new LawsuitPersonagesService();

      this.LawsuitPersonages.unlink( document.getElementById("lawsuit_token").value, e.target.dataset.personage_token ).then(res =>{

          if( res.success === true ){
              localStorage.setItem('alert_message', "Parte excluída com sucesso");
              localStorage.setItem('alert_type', 'alert-success');
              let time = new Date().getTime();
              localStorage.setItem('alert_time', time );
              setTimeout(() => {
                this.modifiedPersonages();
              }, 100);
          }else{
              //alert(res.message);
          }
          document.body.style.cursor = "default";

      });

    }else{
      let evt = {
        target: {
          dataset: {
            personage_token: e.target.dataset.personage_token
          }
        }
      }
      this.setState({
        showConfirm: true,
        personageLawsuitToDelete: evt,
        message: "Deseja excluir definitivamente esta Parte do processo?"
      });
    }

  }

  parteItem = ( {record, index}, type ) => {

      let classes = ( this.state.showTable === true ) ? "show-table" : "";
      classes += " "+type;
      /*
                    <Divider type="vertical" />*/

      return (
        <tr key={record.token} className={classes}>
              <td className="type_personage">
                    <label>
                        {record.personage_type_name}
                    </label>
              </td>
              <td className="personage_name " title={record.personage.name} >
                      {record.personage.name}
              </td>
              <td className="cliente_ou_oponente " >
                    <div className="btn-group" data-toggle="buttons">
                        <label className={ (record.personage_side_id === 1)? "btn btn-default btn-xs clientexo btn-success" : "btn btn-default btn-xs clientexo"} data-personage_side_id={record.personage_side_id} data-personage_type_id={record.personage_type_id} data-personage_id={record.personage.token} onClick={(event) => this.markClient(event)}>
                            Cliente
                        </label>
                        <label className={ (record.personage_side_id === 2)? "btn btn-default btn-xs cxoponente btn-success" : "btn btn-default btn-xs cxoponente"} data-personage_side_id={record.personage_side_id} data-personage_type_id={record.personage_type_id} data-personage_id={record.personage.token} onClick={(event) => this.markOponent(event)} >
                            Oponente
                        </label>
                    </div>
              </td>
              <td className="actions">


                    <span className="pointer pull-right bt-edit movement-insert" id="bt-edit-dados-processo">
                        <i className="fa fa-pencil-square-o" data-personage_side_id={record.personage_side_id} data-personage_type_id={record.personage_type_id}  data-personage={record.personage.token} onClick={this.openEditPersonage}></i>
                        <span className="edit-label">Editar</span>
                    </span>
                    <i className={ ( this.authService.can('partes_processo_excluir', true, 'processos') ) ? "fa fa-trash" : "" } data-personage_token={record.personage.token} onClick={ (event)=>{this.deletePersonageLawsuit(event)} }></i>
              </td>
        </tr>


      );
  };

  showPartes = ( e ) => {
    let itens = document.getElementsByClassName(e.target.dataset.type);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 3 ) {
           itens[n].className += ' show-table';
         }
     }

     this.setState(
       {
         showTable: true
       }
     );

     document.getElementById("show-button-"+e.target.dataset.type).className += ' hide';
     document.getElementById("hide-button-"+e.target.dataset.type).className += ' show';

  }

  hidePartes = ( e ) => {
    let itens = document.getElementsByClassName(e.target.dataset.type);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 3 ) {
           itens[n].classList.remove('show-table');
         }
     }

     this.setState(
       {
         showTable: false
       }
     );

     document.getElementById("hide-button-"+e.target.dataset.type).classList.remove('show');
     document.getElementById("show-button-"+e.target.dataset.type).classList.remove('hide');

  }

  show_hide_button_container = ( record, index, type ) => {

      if ( index > 2 && index < 4 ) {
        return (
          <div key={index} className="show-hide-button-container">
              <span id={"show-button-"+type} className="btn-small btn-info show-button" data-type={type} onClick={this.showPartes}>Ver mais</span>
              <span id={"hide-button-"+type} className="btn-small btn-info hide-button" data-type={type} onClick={this.hidePartes}>Ocultar registros</span>
          </div>
        );
      }else {
        return "";
      }

  };

  render() {

      if( !this.authService.can('partes_processo', true, 'processos') ){
        return null;
      }

      let url_base = localStorage.getItem('url_base');

      var personas  = []; var clients = []; var oponents = []; var others = []; var indice_c = 0;var indice_p = 0; var indice_o = 0;
      this.props.lawsuit.lawsuits_personages.sort(function(a, b){

        //console.log(a);

        if ( moment(a.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() < moment(b.modified.substr(0,10), "YYYY-MM-DD HH:mm").valueOf() ){
          return -1;
        }else{
          return 1;
        }

      });

      this.props.lawsuit.lawsuits_personages.map((value, index, array )=>{

        if( value.personage_side_id === 1 ){
          clients[indice_c] = value;
          clients[indice_c].key = value.token;
          indice_c++;
        }else if ( value.personage_side_id === 2 ){
          oponents[indice_p] = value;
          oponents[indice_p].key = value.token;
          indice_p++;
        }else {
          others[indice_o] = value;
          others[indice_o].key = value.token;
          indice_o++;
        }

        return others;

      });

      personas = clients.concat(oponents);
      personas = personas.concat(others);

      //console.log(this.state.formIsHalfFilledOut)

      return <div className="panel panel-default container-parte">
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <input type="hidden" id="formIsHalfFilledOut" value={this.state.formIsHalfFilledOut} />
                <input type="hidden" id="cliente" value={this.state.cliente} />
                <input type="hidden" id="oponente" value={this.state.oponente} />

                <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-parent="#box-body" data-toggle="collapse" ref={this.myRef} onClick={(event) => this.roll(event)}  data-target="#personages-lawsuit-target">
                        <strong>Partes do Processo { (this.props.lawsuit.tribunal_id === null ) ? "Manual" : null}</strong>
                    </div>
                </div>
                <div className="panel-body collapse" id="personages-lawsuit-target">

                    <span id="bnt-incluir-parte" onClick={this.incluirParte} className="btn btn-primary">Incluir</span>
                    <Popover
                        isOpen={this.state.isPopoverOpen}
                        position={['top', 'right', 'left', 'bottom']}
                        padding={10}
                        onClickOutside={() => this.setState({ isPopoverOpen: false })}
                        content={({ position, targetRect, popoverRect }) => (
                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                position={position}
                                targetRect={targetRect}
                                popoverRect={popoverRect}
                                arrowColor={'#94bcd8'}
                                arrowSize={10}
                            >
                                <div className="popover-box"
                                    onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                >
                                      { (this.props.lawsuit.tribunal_id === null ) ? "Inclua os dados do Cliente de forma completa, assim será possivel gerar documentos no Doc-Facil.  Inclua também pelo menos um Oponente." : "Possibilita incluir mais que uma parte ao processo. O ideal é que se tenha uma parte Cliente e uma parte Oponente (Parte Adversa)." }
                                </div>
                            </ArrowContainer>
                        )}
                    >
                        <img id="help_icon_incluir_parte" onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                    </Popover>
                    <AddPersonagesLawsuit modifiedPersonages={this.modifiedPersonages} />
                    <EditPersonagesLawsuit modifiedPersonages={this.modifiedPersonages} personage={this.state.personageToEdit} side={this.state.side} type={this.state.type} />
                    <div id="table-partes">
                      <div className="table-responsive">
                          <table className="table">
                                  <thead>
                                      <tr>
                                            <th className="type_personage">
                                                <div>Tipo</div>
                                            </th>
                                            <th className="personage_name ">
                                                <div>Parte</div>
                                            </th>
                                            <th className="table_lawsuit_oponente ">
                                                <div>
                                                      Cliente ou Oponente
                                                      <Popover
                                                          isOpen={this.state.isClientOponentPopoverOpen}
                                                          position={['top', 'right', 'left', 'bottom']}
                                                          padding={10}
                                                          onClickOutside={() => this.setState({ isClientOponentPopoverOpen: false })}
                                                          content={({ position, targetRect, popoverRect }) => (
                                                              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                                  position={position}
                                                                  targetRect={targetRect}
                                                                  popoverRect={popoverRect}
                                                                  arrowColor={'#94bcd8'}
                                                                  arrowSize={10}
                                                              >
                                                                  <div className="popover-box"
                                                                      onClick={() => this.setState({ isClientOponentPopoverOpen: !this.state.isClientOponentPopoverOpen })}
                                                                  >
                                                                        Selecione quem são os clientes e oponentes. O primeiro cliente selecionado, terá seus dados (telefones, e-mail e endereço) fixados na tela principal (acima). Esta ordenação pode ser alterada refazendo esta escolha.
                                                                  </div>
                                                              </ArrowContainer>
                                                          )}
                                                      >
                                                          <img onMouseOut={() => this.setState({ isClientOponentPopoverOpen: false })}  onMouseOver={() => this.setState({ isClientOponentPopoverOpen: !this.state.isClientOponentPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                                      </Popover>
                                                </div>
                                            </th>
                                            <th className="actions">
                                                <div>
                                                      Ações
                                                      <Popover
                                                          isOpen={this.state.isActionPopoverOpen}
                                                          position={['top', 'right', 'left', 'bottom']}
                                                          padding={10}
                                                          onClickOutside={() => this.setState({ isActionPopoverOpen: false })}
                                                          content={({ position, targetRect, popoverRect }) => (
                                                              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                                  position={position}
                                                                  targetRect={targetRect}
                                                                  popoverRect={popoverRect}
                                                                  arrowColor={'#94bcd8'}
                                                                  arrowSize={10}
                                                              >
                                                                  <div className="popover-box"
                                                                      onClick={() => this.setState({ isActionPopoverOpen: !this.state.isActionPopoverOpen })}
                                                                  >
                                                                        Possibilita fazer alterações no cadastro das partes. Quando os nomes aparecerem só as iniciais, complemente-os para facilitar a busca por nome. A exclusão de uma das partes, somente será excluida deste processo, permanecendo no cadastro de Partes.
                                                                  </div>
                                                              </ArrowContainer>
                                                          )}
                                                      >
                                                          <img onMouseOut={() => this.setState({ isActionPopoverOpen: false })}  onMouseOver={() => this.setState({ isActionPopoverOpen: !this.state.isActionPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                                      </Popover>
                                                </div>
                                            </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                        { ( typeof personas !== "undefined") ? personas.map( (record, index) => this.parteItem({ record, index }, 'parte-item') ) : null }
                                  </tbody>
                          </table>
                      </div>  
                          { ( typeof personas !== "undefined" ) ? personas.map( (record, index) =>
                            this.show_hide_button_container( record, index, 'parte-item' ) ) : ""
                          }
                    </div>
              </div>
          </div>

  }
}
