import React from 'react';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import PrintComponent from './printComponent';
import BaixarEntrada from '../finance/BaixarEntrada';
import BaixarSaida from '../finance/BaixarSaida';
import Loading from '../containers/loading';
import Confirm from '../containers/confirm';
//import MyPopover from '../containers/mypopover';

//import UsersService from '../../services/UsersService';
import FinanceService from '../../services/FinanceService';
import AuthService from '../../services/AuthService';

import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class ReportFinanceChartList extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
          financeToDelete: false
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
          showBaixarModal: false,
        });

        if( this.state.financeToDelete !== false ){
          this.deleteIncoming( this.state.financeToDelete, true );
        }
      }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });
  }

  constructor( { match } ) {
      super();

      let date_start = moment().startOf('day').subtract(45, 'days').format('DD/MM/YYYY');
      let date_end = moment().endOf('day').add(2, 'days').format('DD/MM/YYYY');

      this.back = this.back.bind(this);
      this.editFinance = this.editFinance.bind(this);
      this.modifiedFinances = this.modifiedFinances.bind(this);
      this.modifiedViewPrint = this.modifiedViewPrint.bind(this);
      this.openEditIncoming = this.openEditIncoming.bind(this);
      this.deleteIncoming = this.deleteIncoming.bind(this);

      this.authService = new AuthService();

      this.state = {
        financeList: [],
        start: date_start,
        end: date_end,
        qtd: 0,
        print: 0,
        futuras: [],
        atrasadas: [],
        token: "",
        hoje: [],
        showModal: false,
        showBaixarModal: false,
        loadingVisible: false,
        loadingMessage: [],
        showConfirm: false,
        message: "",
        financeToDelete: false,
        params: match.params
      }

  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.match.params !== this.props.match.params) {
      this.initParams( nextProps.match.params );
      this.setState({
        params: nextProps.match.params
      })
    }else{
      this.initParams( this.props.match.params );
    }

  }

  componentWillMount() {

    this.initParams( this.props.match.params );

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  initParams( params ){

    this.setState({
      loadingVisible: true,
      loadingMessage: ["O relatório está sendo processado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
    });

    this.FinanceService = new FinanceService();

    this.FinanceService.listIncoming( this.state.start, this.state.end, "3", null, null, null ).then( incomings =>{

      this.FinanceService.listOutgoing( this.state.start, this.state.end, "3", null, null, null ).then( outgoings =>{

        this.FinanceService.categoryIncomingList().then(incomingCategoryList =>{

          this.FinanceService.categoryOutgoingList().then(outgoingCategoryList =>{

            if(params.type === "1" ){
              this.updateFinanceIncoming( incomings, outgoings, incomingCategoryList );
            }else{
              this.updateFinanceOutgoing( outgoings, outgoingCategoryList );
            }

          });

        });

      });

    });


  }

  updateFinanceOutgoing( outgoings, outgoingCategoryList  ) {

    let atrasadas = []; let hoje = []; let futuras = [];
    let catList = [];

    outgoingCategoryList.map( ( record, index, array ) => {

      catList.push(record.key);

      return record;

    });

    for( let i=0; i < outgoings.length; i++){

          if( outgoings[i].data_vencimento.substr(0,10) === moment().format('YYYY-MM-DD') && catList.includes( outgoings[i].financial_category.token )  ){
            //hoje
            if( outgoings[i].pago !== 1 ){
              hoje.push(outgoings[i]);
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) < moment().format('YYYY-MM-DD') && catList.includes( outgoings[i].financial_category.token ) ){

            if( outgoings[i].pago !== 1 ){
              atrasadas.push(outgoings[i]);
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) && catList.includes( outgoings[i].financial_category.token ) ){
            //futuros
            if( outgoings[i].pago !== 1 ){
              futuras.push(outgoings[i]);
            }

          }

    }

    this.setState({
      atrasadas,
      hoje,
      futuras,
      qtd: hoje.length+atrasadas.length+futuras.length,
      loadingVisible: false,
    });

 }

  updateFinanceIncoming( incomings, outgoings, incomingCategoryList  ) {

    let atrasadas = []; let hoje = []; let futuras = [];
    let catList = [];

    incomingCategoryList.map( ( record, index, array ) => {

      catList.push(record.key);

      return record;

    });

    for( let i=0; i < incomings.length; i++){

          if( incomings[i].data_vencimento.substr(0,10) === moment().format('YYYY-MM-DD') && !(incomings[i].belongs_to_lawsuit === 1 && incomings[i].financial_category.name === "Recebimento de Acordo") ){
            //hoje
            if( incomings[i].pago !== 1 ){
              hoje.push(incomings[i]);
            }

          }else if( incomings[i].data_vencimento.substr(0,10) < moment().format('YYYY-MM-DD') && !(incomings[i].belongs_to_lawsuit === 1 && incomings[i].financial_category.name === "Recebimento de Acordo") ){

            if( incomings[i].pago !== 1 ){
              atrasadas.push(incomings[i]);
            }

          }else if( incomings[i].data_vencimento.substr(0,10) && !(incomings[i].belongs_to_lawsuit === 1 && incomings[i].financial_category.name === "Recebimento de Acordo") ){
            //futuros
            if( incomings[i].pago !== 1 ){
              futuras.push(incomings[i]);
            }

          }

    }

    for( let i=0; i < outgoings.length; i++){

          if( outgoings[i].data_vencimento.substr(0,10) === moment().format('YYYY-MM-DD') && (outgoings[i].belongs_to_lawsuit === 1 && catList.includes( outgoings[i].financial_category.token ) ) ){
            //hoje
            if( outgoings[i].pago !== 1 ){
              hoje.push(outgoings[i]);
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) < moment().format('YYYY-MM-DD') && (outgoings[i].belongs_to_lawsuit === 1 && catList.includes( outgoings[i].financial_category.token ) ) ){

            if( outgoings[i].pago !== 1 ){
              atrasadas.push(outgoings[i]);
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) && (outgoings[i].belongs_to_lawsuit === 1 && catList.includes( outgoings[i].financial_category.token ) ) ){
            //futuros
            if( outgoings[i].pago !== 1 ){
              futuras.push(outgoings[i]);
            }

          }

    }

    this.setState({
      atrasadas,
      hoje,
      futuras,
      qtd: hoje.length+atrasadas.length+futuras.length,
      loadingVisible: false,
    });

 }

  back(){
    this.props.history.goBack();
  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else{
      valor = valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  modifiedFinances(){
    //console.log("eventos modificados");
    this.initParams( this.state.params );
    this.setState({
      token: "",
      showModal: false,
    });
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  editFinance = ( event ) => {
     //console.log(event.target.dataset.token);
      this.setState({
        token: event.target.dataset.token,
        showModal: true,
      });
  }


   openEditIncoming = ( event ) => {
     this.setState({
         token: event.target.dataset.token,
         showBaixarModal: true
     });
   }

   deleteIncoming = ( event, r = false ) => {

         this.FinanceService = new FinanceService();

         //var r = window.confirm("Deseja realmente excluir a entrada?");

         if ( r ){

                   this.FinanceService.deleteIncoming( event.target.dataset.token )
                       .then(res =>{
                         if( res.success === true ){

                           localStorage.setItem('alert_message', "Entrada deletada com sucesso");
                           localStorage.setItem('alert_type', 'alert-success');
                           let time = new Date().getTime();
                           localStorage.setItem('alert_time', time );

                           this.initParams( this.state.params );

                         }else{
                           localStorage.setItem('alert_message', res.message);
                           localStorage.setItem('alert_type', 'alert-danger');
                           let time = new Date().getTime();
                           localStorage.setItem('alert_time', time );
                           setTimeout(() => {
                             this.setState({
                               updated: time,
                             });
                           }, 100);
                         }

                         this.setState({
                          financeToDelete: false
                         });

                       })
                       .catch(err =>{
                           document.body.style.cursor = "default";
                          //console.log(err);
                       })


        }else{
          let evt = {
            target: {
              dataset: {
                token: event.target.dataset.token
              }
            }
          }
          this.setState({
            showConfirm: true,
            financeToDelete: evt,
            message: "Deseja realmente excluir a entrada?"
          });
        }

    }

  financeItem = ( {record, index}, type ) => {

        let lawsuit = ""; let baixa = null;
        let source = (typeof record.source !== "undefined")? record.source : "";

        let data_vencimento = record.data_vencimento.substr(0, 10).split('-').reverse().join('/');

        if(record.lawsuit !== null && typeof record.lawsuit !== "undefined"){
          if( record.lawsuit.number === null ){
            lawsuit = record.lawsuit.name;
          }else{
            lawsuit = record.lawsuit.number;
          }
        }else{
          lawsuit = "";
        }

        if( record.pago === 1){
          baixa =  <div className="toggle">
                         <input className="toggle__input" readOnly type="checkbox" checked />
                         <span className="toggle__label">
                           <span className="toggle__text"></span>
                         </span>
                         <div className="toggle__title">Baixa</div>
                   </div>
        }else{
          baixa = <label className="toggle">
                      <button className="curi" data-source={source} data-token={record.token} onClick={ ( event ) => { this.openEditIncoming(event) } }>
                      </button>
                      <div className="toggle__title">Baixa</div>
                  </label>
        }

        let excluir = <i className="fa fa-trash" data-source={source} data-token={record.token} onClick={ ( event ) => { this.deleteIncoming(event) } }></i>;

       return (
         <tr key={record.token} className={type}>
               <td className="category" >
                       {/*category*/record.financial_category.name}
               </td>
               <td className="description" >
                       {record.observacao}
               </td>
               <td className="table_lawsuit_number">
                       {lawsuit}
               </td>
               <td className="valor"  >
                       R$ { this.formataMoeda(record.valor) }
               </td>
               <td className="date"  >
                       { data_vencimento }
               </td>
               <td className="actions hidden-xs hidden-sm">
                     {baixa}
                     {this.authService.can('financeiro_contas_receber_excluir', true, 'financeiro') ? excluir : null}
               </td>
         </tr>


       );

  }

  render() {
      //let url_base = localStorage.getItem('url_base');

      let table_head = <thead>
                                <tr>
                                      <th className="table_category">
                                          <div>Categoria</div>
                                      </th>
                                      <th className="table_description">
                                          <div>Observação</div>
                                      </th>
                                      <th className="table_lawsuit_number">
                                          <div>Processo</div>
                                      </th>
                                      <th className="table_value">
                                          <div>Valor</div>
                                      </th>
                                      <th className="table_date ">
                                          <div>Data Vcto</div>
                                      </th>
                                      <th className="table_actions hidden-xs hidden-sm">
                                          <div>Ações</div>
                                      </th>
                                </tr>
                       </thead>;

      let table_hoje =         <table>
                                          {table_head}
                                          <tbody>
                                              { this.state.hoje.map( (record, index) => this.financeItem( {record, index}, 'report-finance-item' ) ) }
                                          </tbody>
                                </table>;
      let table_atrasadas =     <table>
                                          {table_head}
                                          <tbody>
                                              { this.state.atrasadas.map( (record, index) => this.financeItem( {record, index}, 'report-finance-item' ) ) }
                                          </tbody>
                                </table>;
      let table_futuras =       <table>
                                          {table_head}
                                          <tbody>
                                              { this.state.futuras.map( (record, index) => this.financeItem( {record, index}, 'report-finance-item' ) ) }
                                          </tbody>
                                </table>;
      let content = <div>
                            <h2>Atrasadas</h2>
                            {table_atrasadas}
                            <h2>Hoje</h2>
                            {table_hoje}
                            <h2>Amanhã</h2>
                            {table_futuras}
                      </div>;

      let form = null;

      if( this.state.params.type === "1" ){
        form = <BaixarEntrada modifiedIncoming={this.modifiedFinances} token={this.state.token} showModal={this.state.showBaixarModal} />;
      }else{
        form = <BaixarSaida modifiedOutgoing={this.modifiedFinances} token={this.state.token} showModal={this.state.showBaixarModal} />;
      }

      if( this.state.print === 1 ){
          return <div>
                    <PrintComponent modifiedViewPrint={this.modifiedViewPrint} content={content} title={"Relatório da Agenda"} subtitle={(this.state.params.type === "1") ? "Contas a Receber" : "Contas a Pagar"} />
               </div>
      }else{
        return <div className="sheet reportFinanceList">
                  <HeaderBar />
                  <NavigationMenu />
                  <Confirm show={this.state.showConfirm} text={this.state.message} />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                  <div className="content-wrapper">
                    <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Relatórios - Financeiro Listagem</h1>
                          <span className="btn btn-info" onClick={this.back} >Voltar</span>
                          <span className="btn btn-default btn-report right" onClick={(event)=>{this.print(event)} }>Imprimir</span>
                        </section>

                        {form}

                        <div className="box-body">
                            <div className="alert alert-info" role="alert">
                                <center><h4>Financeiro - {(this.state.params.type === "1") ? "Contas a Receber" : "Contas a Pagar"}</h4></center>
                                Quantidade de registros encontrados: <strong>{this.state.qtd}</strong>
                            </div>

                            <div className="panel panel-default container-publicacoes">
                                    <div className="panel-heading text-center">
                                        <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                                            <strong className="red">Atrasadas ({this.state.atrasadas.length})</strong>
                                        </div>
                                    </div>
                                    <div className="panel-body collapse " id="filter_target">
                                          {table_atrasadas}
                                    </div>
                            </div>
                            <div className="panel panel-default container-publicacoes">
                                  <div className="panel-heading text-center">
                                      <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_atrasadas_target">
                                          <strong className="green">Hoje ({this.state.hoje.length})</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse " id="filter_atrasadas_target">
                                        {table_hoje}
                                  </div>
                            </div>
                            <div className="panel panel-default container-publicacoes">
                                  <div className="panel-heading text-center">
                                      <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_futuras_target">
                                          <strong className="yellow">Amanhã ({this.state.futuras.length})</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse " id="filter_futuras_target">
                                        {table_futuras}
                                  </div>
                            </div>

                        </div>
                    </div>
                  </div>
               </div>
      }

  }

}
