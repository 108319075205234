import React from 'react';
import { Modal, Button } from 'antd';
import IntlCurrencyInput from "react-intl-currency-input";
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import { DatePicker } from 'antd'; 
import AuthService from '../services/AuthService';
import AfiliadosService from '../services/AfiliadosService';

import 'antd/dist/antd.css';
import { Tabs } from 'antd';

import Select from 'react-select';

import Footer from "./containers/footer";
import FlashMessage from "./containers/FlashMessage";
import MyPopover from './containers/mypopover';

import Loading from './containers/loading';
import Alert from './containers/alert';
import Confirm from './containers/confirm';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

moment.locale('pt-BR');

const TabPane = Tabs.TabPane;

const { RangePicker, MonthPicker } = DatePicker;

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

const statusList = [
  "Saque em Revisão",
  "Saque Concluído"
];

export default class Afiliados extends React.Component { 
    _isMounted = false;

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount( ) { 
          this._isMounted = true;

          document.addEventListener("confirmDialogConfirm", (e) => {
            if( this._isMounted ){
              this.setState({
                showConfirm: false,
              });
      
              if( this.state.action === "deleteSaque" ){
                  this.deleteSaque(this.state.saqueToDelete, true);
              }
            }
          });

          this.getAfiliados();
          this.getSaques();
    }

    constructor( { match } ) {
        super();

        this.authService = new AuthService();

        this.solicitarSaque = this.solicitarSaque.bind(this);
        this.deleteSaque = this.deleteSaque.bind(this);
        this.moeda = this.moeda.bind(this);
        this.chargePayments = this.chargePayments.bind(this);
        this.clearWithDraw = this.clearWithDraw.bind(this);


        this.state = {

            date_start: moment().startOf('month').format('DD/MM/YYYY'),
            date_end: moment().endOf('month').format('DD/MM/YYYY'),
            withdraw_start: moment().startOf('month').format('DD/MM/YYYY'),
            withdraw_end: moment().endOf('month').format('DD/MM/YYYY'),
            errors: {},
            saqueModalVisible: false,
            saqueToDelete: false,
            payments: [],
            saques: [],
            valorSaque: 0,
            afiliadosList: []/*[
              {
                name: "João Augusto Gomes Barbosa",
                email: "joao.augusto@gmail.com",
                valor: 99.00,
                status: "teste",
                created: "2021-03-20",
                token: 1234
              },
              {
                name: "Rony Barbosa Dias",
                email: "rony.barbosa@gmail.com",
                valor: 99.00,
                status: "cancelado",
                created: "2021-02-20",
                token: 12345
              },
              {
                name: "Kelly Rodrigues Santos da Silva",
                email: "kelly.rodrigues@gmail.com",
                valor: 99.00,
                status: "ativo",
                created: "2021-10-15",
                token: 123456
              },
            ]*/,
            updated: "",
            loadingVisible: false,
            loadingMessage: [],
            /*cashflow: {
              saldo: 50.00,
              lancamentos: [
                {
                  mes_referencia: "04/2021",
                  n_indicados: 33,
                  indicados: [
                    {name: "Kelly Rodrigues Santos da Silva"},
                    {name: "Rony Barbosa Dias"},
                    {name: "João Augusto Gomes Barbosa"},
                    {name: "Gilmar Mendes"},
                    {name: "Bruno Mendes"},
                    {name: "Diego Mendes Reis"},
                    {name: "José Silva"},
                    {name: "Carla Santos"},
                    {name: "Beatriz Lima"},
                    {name: "Eduardo Saverin"},
                    {name: "João Wilde Carlos"},
                    {name: "Alexandre Fonseca"},
                    {name: "Sérgio Benedito"},
                    {name: "Bento Costa"},
                    {name: "Francisca Paula de Jesus"},
                    {name: "Shirley Costa"},
                    {name: "Agnaldo Dias"},
                    {name: "Anderson Ferreira"},
                    {name: "Paulo Martins"},
                    {name: "Rodrigo Santos"},
                    {name: "Sérgio Alves"},
                  ],
                  tipo: "credito",
                  valor: 330.00,
                  token: 334089847
                },
                {
                  mes_referencia: "03/2021",
                  n_indicados: 45,
                  indicados: [
                    {name: "Kelly Rodrigues Santos da Silva"},
                    {name: "Rony Barbosa Dias"},
                    {name: "João Augusto Gomes Barbosa"},
                  ],
                  tipo: "credito",
                  valor: 450.00,
                  token: 3340898478
                },
                {
                  mes_referencia: "02/2021",
                  n_indicados: 41,
                  indicados: [
                    {name: "Kelly Rodrigues Santos da Silva"},
                    {name: "Rony Barbosa Dias"},
                    {name: "João Augusto Gomes Barbosa"},
                  ],
                  tipo: "credito",
                  valor: 410.50,
                  token: 3340898479
                },
                {
                  mes_referencia: "01/2021",
                  n_indicados: null,
                  indicados: [
                  ],
                  tipo: "debito",
                  valor: 50.00,
                  token: 1234089847,
                  status: 1,
                },
              ]
            },*/
            statusIndicadosList: [
              {value: 'todos', label: 'Todos'},
              {value: 'teste', label: 'Teste'},
              {value: 'ativo', label: 'Ativo'},
              {value: 'bonus', label: 'Bônus'},
              {value: 'inativo', label: 'Inativo'},
            ],
            statusIndicados: {value: 'todos', label: 'Todos'},
            message: "",
            showAlert: false,
            showConfirm: false,
            total: 0,
            comissao: 0
        }

    }

  componentWillReceiveProps(nextProps) {
  }

  componentWillMount( ) {

  }

  clear = ( event ) => {
      this.setState({
        date_start: moment().startOf('month').format('DD/MM/YYYY'),
        date_end: moment().endOf('month').format('DD/MM/YYYY'),
        withdraw_start: moment().startOf('month').format('DD/MM/YYYY'),
        withdraw_end: moment().endOf('month').format('DD/MM/YYYY'),
      });
  }

 clearWithDraw = () => {
  this.setState({
    valorSaque: "",
    errors: {},
    saqueModalVisible: false
  });
 }

  onDatePickerChange = (date, dateString, name) => {

    if (dateString === "" ){
      dateString = null;
    }

    //console.log(dateString);

     this.setState(
         {
             [name]: dateString,
         }
     )

   }

   onMonthPickerChange = (date, dateString, name) => {

     this.setState(
         {
             [name]: date,
         }
     )

   }


   selectChange = (selected, actionMeta) => {
      let pops = [];
      pops[ actionMeta.name ] = selected;

      if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

        if ( typeof selected !== undefined && selected !== "" ) {
          this.setState( pops );
        }

      }
    }

   disabledStartDate = startValue => {
     const endValue = moment(this.state.date_end, "DD/MM/YYYY");
     if (!startValue || !endValue) {
       return false;
     }
     return  startValue.valueOf() > endValue.valueOf()  ;
   };

   disabledEndDate = endValue => {
     const startValue = moment(this.state.date_start, "DD/MM/YYYY");
     if (!endValue || !startValue) {
       return false;
     }
     return endValue.valueOf() < startValue.valueOf() ;
   }

  moeda( event, value, maskedValue ) {
      event.preventDefault();

      this.setState(
          {
              [event.target.name]: maskedValue
          }
      )
  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else{
      valor = (typeof valor === "undefined") ? (0).toFixed(2) : valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  getAfiliados(){
    this.AfiliadosService = new AfiliadosService();
    this.setState({
      loadingVisible: true,
      loadingMessage: ["Carregando os dados dos Afiliados", "Por favor aguarde"]
    })
    this.AfiliadosService.list( /*'01/01/2019'*/this.state.date_start, this.state.date_end, this.state.statusIndicados.value ).then(res =>{
        let total = 0;
        let comissao = 0;

        if( typeof res.data.Affiliates !== "undefined"){
          res.data.Affiliates.map( afiliate =>{
            if( typeof afiliate.payments != "undefined"){
                afiliate.payments.map( (payment)=>{
                    total += Number.parseFloat(payment.amount);
                    comissao += (Number.parseFloat(payment.comission_value));
                    return payment;
                });
            }
          });
        }
        
        this.setState({
          afiliadosList: res.data.Affiliates,
          loadingVisible: false,
          balance: res.data.balance,
          total,
          comissao
        })
    });
  }

  getSaques(){
    this.AfiliadosService = new AfiliadosService();
    this.AfiliadosService.listWithdraws( this.state.withdraw_start, this.state.withdraw_end ).then(res =>{
      //console.log(res);

      let withdraws = [];
      withdraws = res.data.Withdraws.sort((a,b)=>{
        if(a.created > b.created){
          return -1;
        }
        if(a.created < b.created){
          return 1;
        }
        return 0;
        //.substr(0, 10).split('-').reverse().join('/')
      });

        this.setState({
          saques: withdraws
        })
    });
  }

  getComissoes(){
    this.AfiliadosService = new AfiliadosService();
    this.setState({
      loadingVisible: true,
      loadingMessage: ["Carregando os dados dos Afiliados", "Por favor aguarde"]
    })
    this.AfiliadosService.listWithdraws( this.state.date_start, this.state.date_end ).then(res =>{
      //console.log(res);
        this.setState({
          loadingVisible: false,
          balance: res.data.balance
        })
    });
  }

  chargePayments( index ){
    let afiliado = this.state.afiliadosList[index];

    if(typeof afiliado.payments !=  "undefined"){
      this.setState({
        payments: afiliado.payments
      });
    }

  }

  deleteSaque( e, r = false ){
      if( r ){
        //console.log(e);
        //método para deletar saque
        let cashflow = this.state.cashflow;
        cashflow.lancamentos.pop();

        this.setState({
          cashflow
        });

      }else{
        let evt = {
          target: {
            dataset: {
              saque_token: e.target.dataset.saque_token
            }
          }
        }
        this.setState({
          showConfirm: true,
          action: 'deleteSaque',
          saqueToDelete: evt,
          message: "Tem certeza de que deseja excluir o saque?"
        });
      }
  }

  solicitarSaque(){
    let errors = {};
    if( this.state.valorSaque == 0){
      errors["valorSaque"] = "*O valor solicitado não pode ser zero";
      this.setState(
        {
          errors: errors
        }
      );
    }else{
      let valorSaque = this.state.valorSaque.replaceAll('R$ ', '').replaceAll('.', '').replaceAll(',', '.');
      //console.log(valorSaque);
  
      if( valorSaque > this.state.balance ){
        errors["valorSaque"] = "*O valor solicitado é maior que o saldo atual";
        this.setState(
          {
            errors: errors
          }
        );
        //console.log("maior");
      }else{
        this.setState({
          loadingVisible: true,
          loadingMessage: ["Solicitando o saque", "Por favor aguarde"]
        })
        //chama o service para inserir a nova retirada no endpoint e chama um novo service com a listagem da conta corrente
        this.AfiliadosService = new AfiliadosService();
  
        this.AfiliadosService.addWithdraws( parseFloat(valorSaque) ).then(res =>{
          //console.log(res);
            this.getSaques();
            this.setState({
              loadingVisible: false,
              valorSaque: "",
              saqueModalVisible: false,
              errors: {}
            })
            localStorage.setItem('alert_message', "Saque solicitado com sucesso");
            localStorage.setItem('alert_type', 'alert-success');
            let time = new Date().getTime();
            localStorage.setItem('alert_time', time );
        });
      }
  
    }

  }

  indicadosListText( indicados ){
    let indicadosText = "";

    indicados.map( (object)=>{
      indicadosText += object.name+"\n";
    });

    return indicadosText;
  }

  afiliadosItem = ( {record, index}, type ) => {

    //console.log(record);

    let total_payments = 0;

    if(typeof record.payments !== "undefined"){
      record.payments.map( (payment)=>{
        total_payments += Number.parseFloat(payment.amount);
        return payment;
      });
    }

      return (
        <tr key={record.token} className={type}>
              <td className="table_lawsuit_number">
                    {record.name}
              </td>
              <td className="table_lawsuit_oponente " >
                      { record.status }
              </td>
              <td className="">
                      { record.created.substr(0, 10).split('-').reverse().join('/') }
              </td>
              <td className="table_lawsuit_cliente " >
                  R$ { this.formataMoeda( (record.total) ? record.total : 0 ) }
              </td>
              <td className="table_lawsuit_cliente " >
                  <span className="btn btn-afiliado-more" data-toggle="modal" data-target="#paymentsModal" onClick={()=>{this.chargePayments(index)}} >ver</span>
                  R$ { this.formataMoeda( (total_payments) ? total_payments : 0 ) }
              </td>
        </tr>


      );
  };

  saqueItem = ( {record, index}, type ) => {

    //console.log(record);
    let valor  = this.formataMoeda(record.value);
    let debito_container = null;
    if( record.withdraw_situation_id === 1 ){
      debito_container = <i className={ "fa fa-ban align-text-center" } data-saque_token={record.token} onClick={ this.deleteSaque.bind(this)  }></i>;
    }
      return (
        <tr key={record.token} className={type}>
              <td className="">
                    {record.created.substr(0, 10).split('-').reverse().join('/')}
              </td>
              <td className="red">
                      {valor}
              </td>
              <td className="">
                      {record.obs}
              </td>
              <td className=""  >
                      {record.withdraw_situation.name}
              </td>
        </tr>


      );
  };

  paymentsItem = ( {record, index}, type ) => {

    //console.log("payments: ", record);
    let valor  = this.formataMoeda(parseFloat(record.amount));

      return (
        <tr key={record.token} className={type}>
              <td className="">
                    {record.date.substr(0, 10).split('-').reverse().join('/')}
              </td>
              <td className="">
                      {valor}
              </td>
              <td className="">
                      {record.comission_percentage}%
              </td>
              <td className=""  >
                      R$ {this.formataMoeda(parseFloat(record.comission_value))}
              </td>
        </tr>
      );
  };

  comissoesItem = ( {record, index}, type ) => {

    //console.log(record);
    let valor  = this.formataMoeda(record.valor);
    let indicados = <div>
          <span>{record.n_indicados}</span>
          <MyPopover icon="white" text={this.indicadosListText(record.indicados)} />
    </div>;
    let debito_container = null;
    if( record.status === 0 ){
      debito_container = <i className={ "fa fa-ban align-text-center" } data-saque_token={record.token} onClick={ this.deleteSaque.bind(this)  }></i>;
    }
      return (
        <tr key={record.token} className={type}>
              <td className="">
                    {record.mes_referencia}
              </td>
              <td>
                  {(record.tipo === "credito") ?  indicados : null}
              </td>
              <td className="debito">
                      {(record.tipo === "debito") ? valor: null}
              </td>
              <td className="credit "  >
                      {(record.tipo === "credito") ? valor : null}
              </td>
              <td className=""  >
                      {(record.tipo === "debito") ? statusList[record.status]: null}
              </td>
              <td className=""  >
                      {(record.tipo === "debito") ? debito_container : null}
              </td>
        </tr>


      );
  };

  showSaqueModal = () => {

    this.setState({
      saqueModalVisible: true,
    });
};

  render() {

      let url_base = localStorage.getItem('url_base');

      let saldo_periodo = 0;

      /*this.state.cashflow.lancamentos.map( (lancamento)=>{
          if( lancamento.tipo == "credito" ){
            saldo_periodo += lancamento.valor;
          }
          if( lancamento.tipo == "debito" ){
            saldo_periodo -= lancamento.valor;
          }
      });*/

      let balance = (this.state.balance) ? this.state.balance : 0;
      /*

                              <TabPane tab={<span>Conta Corrente</span>} key="2">
                              <span className="btn btn-primary" data-toggle="modal" data-target="#saqueModal">Nova Retirada</span>
                                <div className="row filter">
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_start" className="control-label">De</label>
                                          <MonthPicker 
                                            locale={locale} 
                                            className="" 
                                            picker="month" 
                                            format="MM/YYYY" 
                                            value={ (this.state.month_start !== null ) ? moment(this.state.month_start) : null } 
                                            onChange={(date, dateString) => this.onMonthPickerChange(date, dateString, "month_start")}
                                          />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_end" className="control-label">Até</label>
                                          <MonthPicker 
                                            locale={locale} 
                                            className="" 
                                            picker="month" 
                                            format="MM/YYYY" 
                                            value={ (this.state.month_end !== null ) ? moment(this.state.month_end) : null } 
                                            onChange={(date, dateString) => this.onMonthPickerChange(date, dateString, "month_end")}
                                          />
                                      </div>
                                      <div className="container-search">
                                          <span className="btn btn-primary" onClick={ ( event ) => { this.getComissoes() } }>Filtrar</span>
                                          <span className="btn btn-danger" data-type={this.state.type} onClick={ ( event ) => { this.clear(event) } } >Limpar</span>
                                      </div>
                                </div>
                                <div className="resultado-saldo">
                                      <div className="resultado-acumulado col-sm-6 col-md-6">
                                      Saldo Atual = <b className={(balance > 0) ? "credit" : "debito"}>R$ {this.formataMoeda(balance)}</b>
                                      </div>
                                      <div className="resultado-periodo col-sm-6 col-md-6">
                                      Saldo do Período = <b className={(saldo_periodo > 0) ? "credit" : "debito"}>R$ {this.formataMoeda(saldo_periodo)}</b>
                                      </div>
                                </div>
                                <br />
                                <div className="table-responsive">
                                  <table className="highlight-table table" >
                                          <thead>
                                              <tr>
                                                    <th className="table_lawsuit_number">
                                                        <div>Mês de Referência</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Nº de Indicados</div>
                                                    </th>
                                                    <th className="table_debito">
                                                        <div>Débito (R$)</div>
                                                    </th>
                                                    <th className="table_credit">
                                                        <div>Crédito (R$)<MyPopover icon="white" text="Total apurado do 1º ao último dia do mês" /></div>                                                        
                                                        
                                                    </th>
                                                    <th className="">
                                                        <div>Status</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Cancelar Retirada</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.cashflow.lancamentos !== "undefined") ? this.state.cashflow.lancamentos.map( (record, index) => this.comissoesItem( {record, index}, 'comissoes-item' ) ) : null }
                                          </tbody>
                                  </table>
                                </div>
                              </TabPane>
       */
      let codigo_indicacao_container = null;

      if( localStorage.getItem('afiliado' ) === "true" ){
      
      codigo_indicacao_container = <div className="codigo-indicacao_container">
            <div>
                  <label>Meu Código de Indicação: </label> 
                  <div className="input-icons"> 
                      <span className="hashtag-icon"> 
                      #
                    </span> 
                    <input className="input-lg input-field" readOnly={true} name="codigo_afiliado" value={localStorage.getItem('codigo_afiliado' )} />
                  </div> 
            </div>
            <div>
                <label>Link de Afiliado: </label> <br />
                <input className="link-afiliado-input input-lg" readOnly={true} value={"https://avvocatosistemas.com.br/teste-gratis.php?parceiro="+localStorage.getItem('codigo_afiliado' )} />
            </div>
      </div>
      }

      return <div className="afiliados sheet">
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Painel do Parceiro</h1>
                      </section>
                      <div className="box-body">
                        { codigo_indicacao_container }
                        <Tabs defaultActiveKey="1" size="large">
                              <TabPane tab={<span>Meus Indicados </span>} key="1">
                                <div className="row filter">
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_start" className="control-label">Data de Cadastro de</label>
                                          <DatePicker locale={locale} className="" disabledDate={this.disabledStartDate} placeholder="De" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_end" className="control-label">Data de Cadastro até</label>
                                          <DatePicker locale={locale} className="" disabledDate={this.disabledEndDate} placeholder="Até" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="statusIndicados" className="control-label">Status:</label>
                                          <Select
                                            className="statusIndicados"
                                            options={this.state.statusIndicadosList}
                                            placeholder="Status"
                                            value={this.state.statusIndicados}
                                            name="statusIndicados"
                                            isClearable={true}
                                            styles={customStyles}
                                            onChange={this.selectChange}
                                          />
                                      </div>
                                      <div className="container-search">
                                          <span className="btn btn-primary" onClick={ ( event ) => { this.getAfiliados() } }>Filtrar</span>
                                          <span className="btn btn-danger" data-type={this.state.type} onClick={ ( event ) => { this.clear(event) } } >Limpar</span>
                                      </div>
                                </div>
                                <div className="table-responsive">
                                  <div className="resultado-periodo valor-total"><span>Total: </span>R$ { this.formataMoeda( (this.state.total) ? this.state.total : 0 ) } / 
                                      Comissão: R$ { this.formataMoeda( this.state.comissao ) } / 
                                      Saldo: R$ { this.formataMoeda( this.state.balance ) }
                                  </div>
                                  <table className="table">
                                          <thead>
                                              <tr>
                                                    <th className="table_lawsuit_number">
                                                        <div>Nome do Indicado</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Status</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Cadastrado em</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Valor do Plano</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Pagamentos efetuados</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.afiliadosList !== "undefined") ? this.state.afiliadosList.map( (record, index) => this.afiliadosItem( {record, index}, 'indicados-item' ) ) : null }
                                          </tbody>
                                  </table>
                                  <div className="resultado-periodo valor-total"><span>Total: </span>R$ { this.formataMoeda( (this.state.total) ? this.state.total : 0 ) } /                             
                                  Comissão: R$ { this.formataMoeda( this.state.comissao ) } / 
                                  Saldo: { this.formataMoeda( this.state.balance ) }
                                  </div>
                                </div>
                              </TabPane>
                              <TabPane tab={<span>Saques</span>} key="2">
                              <span className="btn btn-primary" onClick={this.showSaqueModal}>Nova Retirada</span>
                                <div className="row filter">
                                      <div className="form-group date text-left">
                                          <label htmlFor="withdraw_start" className="control-label">Data Inicial</label>
                                          <DatePicker locale={locale} className="" placeholder="De" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.withdraw_start !== null ) ? moment(this.state.withdraw_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "withdraw_start")} />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_end" className="control-label">Data Final</label>
                                          <DatePicker locale={locale} className="" placeholder="Até" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.withdraw_end !== null ) ? moment(this.state.withdraw_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "withdraw_end")} />
                                      </div>
                                      <div className="container-search">
                                          <span className="btn btn-primary" onClick={ ( event ) => { this.getSaques() } }>Filtrar</span>
                                          <span className="btn btn-danger" data-type={this.state.type} onClick={ ( event ) => { this.clear(event) } } >Limpar</span>
                                      </div>
                                </div>
                                <br />
                                <div className="table-responsive">
                                  <div className="resultado-periodo valor-total">
                                      Saldo: { this.formataMoeda( this.state.balance ) }
                                  </div>
                                  <table className="highlight-table table" >
                                          <thead>
                                              <tr>
                                                    <th className="table_lawsuit_number">
                                                        <div>Data do Saque</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Valor (R$)</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Observação</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Status</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.saques !== "undefined") ? this.state.saques.map( (record, index) => this.saqueItem( {record, index}, 'saque-item' ) ) : null }
                                          </tbody>
                                  </table>
                                  <div className="resultado-periodo valor-total">
                                      Saldo: { this.formataMoeda( this.state.balance ) }
                                  </div>
                                </div>
                              </TabPane>
                        </Tabs>
                        <br />
                        <br />
                      </div>
                      <Modal
                        visible={this.state.saqueModalVisible}
                        title="Solicitar Retirada"
                        className=""
                        onOk={this.handleOk}
                        onCancel={(event) => {this.clearWithDraw(event)} }
                        footer={[
                          <Button key="submit" id="handleOk" type="primary" onClick={this.solicitarSaque}>
                            Solicitar
                          </Button>,
                          <Button key="back" id="close" className="btn-danger" onClick={(event) => {this.clearWithDraw(event)} }>
                            Fechar
                          </Button>,
                        ]}
                      >                           
                          <form>
                            <div className="form-group">
                              <label htmlFor="valorSaque" className="col-form-label col-md-12 col-xs-12">Valor do Saque: </label>
                              <IntlCurrencyInput className="form-control" currency="BRL" config={currencyConfig} value={this.state.valorSaque} onChange={this.moeda} id="valorSaque" name="valorSaque" />
                              <div className="errorMsg">{this.state.errors.valorSaque}</div>
                            </div>
                          </form>
                      </Modal>

                      <div className="modal fade" id="paymentsModal" tabIndex="-1" role="dialog" aria-labelledby="paymentsModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="saqueModalLabel">Pagamentos Efetuados</h5>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div className="form-group">
                                      <table className="table" >
                                              <thead>
                                                  <tr>
                                                        <th className="table_lawsuit_number">
                                                            <div>Data do Pagamento</div>
                                                        </th>
                                                        <th className="">
                                                            <div>Valor (R$)</div>
                                                        </th>
                                                        <th className="">
                                                            <div>% da Comissão</div>
                                                        </th>
                                                        <th className="">
                                                            <div>Comissão</div>
                                                        </th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                    { ( typeof this.state.payments !== "undefined") ? this.state.payments.map( (record, index) => this.paymentsItem( {record, index}, 'payments-item' ) ) : null }
                                              </tbody>
                                      </table>
                                </div>
                              </form>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>
                </div>
             </div>
  }

}
