import AuthService from './AuthService';
import {api} from '../config';


export default class MovementService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.addComment = this.addComment.bind(this)
    }

    addComment ( comment ){
      return this.fetch(`${this.domain}MovementComments/add`, {
          method: 'POST',
          body: JSON.stringify(comment)
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    editComment ( token, comment ){
      let comment_object = {
        'comment': comment
      }
      return this.fetch(`${this.domain}MovementComments/edit/${token}`, {
          method: 'POST',
          body: JSON.stringify(comment_object)
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteComment ( token ){
      return this.fetch(`${this.domain}MovementComments/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteMovements( lawsuit_token ){
      let object = {
        lawsuit_token: lawsuit_token
      }
        return this.fetch(`${this.domain}Movements/add/`, {
            method: 'POST',
            body: JSON.stringify(object)
        }).then(res => {
              return Promise.resolve(res);
        });
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
            return Promise.resolve({
              message: "Você não tem permissão para acessar o sistema neste momento."
            });
          }else{
    

              return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                          if(typeof response !== "undefined"){

                            return Promise.resolve( response.text().then(function(result){
                                //console.log(result);
                                let old_result = result;
                                result = result.split('{"success"');
                                if( result.length > 1 ){
                                    result = result[1].split('<pre');
                                    //console.log(result[0]);
                                    let json = '{"success"'+result[0];
                                    result = JSON.parse(json);
                                    //console.log(result);
                                    return result;
                                }else{
                                    return old_result;
                                }

                            }) );

                        }else{

                            let result = {
                                success: false,
                                message: "Erro desconhecido"
                            }

                            return Promise.resolve( result );

                        }

                  });

          }
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
         //console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
            window.location.href = '/config/mudar-plano?plano_vencido';
    
          }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
