import React from 'react';
import ListModels from './listModels';

import eDocsService from '../../../services/eDocsService'

export default class ListTipos extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
    super( props );

    this.emptyType = this.props.emptyType.bind(this);
    this.emptyModel = this.emptyModel.bind(this);

    this.state = {
        tipos: [],
    }
  }

 componentDidMount( ) { this._isMounted = true;
   this.eDocsService = new eDocsService();

   this.eDocsService.tipoList( this.props.area_token ).then(response =>{

       this.setState({
         tipos: response,
       });

       if( response.length < 1){
         this.emptyType( this.props.area_token );
       }

   });
 }

 emptyModel( tipo_token ){
   document.getElementById(tipo_token).className += ' hide';
 }

 tipoPanel = ( {record, index}, type ) => {
     return (
       <div key={record.value} id={record.value}  className="panel panel-success">
             <div className="panel-heading" role="tab" id={"accordion_parte"+record.value}>
                 <div className="pointer panel-title" data-toggle="collapse" data-target={"#collapse"+record.value}>
                     {record.label}
                 </div>
             </div>
             <div className="panel-body collapse" id={"collapse"+record.value}>
                  <ListModels tipo_token={record.value} emptyModel={this.emptyModel} />
             </div>
       </div>
     );
 };


  render() {

      return <div className="">
                  {this.state.tipos.map( (record, index) => this.tipoPanel({ record, index }, 'tipo-item') )}
             </div>
  }

}
