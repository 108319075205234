import React from 'react';
import 'antd/dist/antd.css';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import Confirm from '../../containers/confirm';

import MovementService from '../../../services/MovementService';
import LawsuitService from '../../../services/LawsuitService';
import AuthService from '../../../services/AuthService';

import HaveComment from './haveComment';

import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class MovementsLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,          
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        if( this.state.scrapToDelete !== false ){
            this.deleteScrap(this.state.scrapToDelete, true);
        }
      }
    });

  }


  constructor( props ) {
    super(props);
    this.addScrap = this.addScrap.bind(this);
    this.openAddScraps = this.openAddScraps.bind(this);
    this.openEditScraps = this.openEditScraps.bind(this);
    this.editScrap = this.editScrap.bind(this);
    this.deleteScrap = this.deleteScrap.bind(this);
    this.voltar_movements = this.voltar_movements.bind(this);
    this.commentItem = this.commentItem.bind(this);
    this.comment_button_container = this.comment_button_container.bind(this);
    this.modifiedMovements = this.props.modifiedMovements.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.movementItem = this.movementItem.bind(this);
    this.show_hide_button_container = this.show_hide_button_container.bind(this);
    this.roll = this.roll.bind(this);
    this.openAddInfo = this.openAddInfo.bind(this);
    this.addInfo = this.addInfo.bind(this);
    this.voltar = this.voltar.bind(this);
    this.myRef = React.createRef();

    this.authService = new AuthService();

    this.number_comments = 0;

    this.state = {
      lawsuit_token: this.props.lawsuit.token,
      lawsuit: this.props.lawsuit,
      scrapToDelete: false,
      showConfirm: false,
    }
  }

  componentWillReceiveProps(nextProps) {

      this.setState({
        lawsuit_token: nextProps.lawsuit.token,
        lawsuit: nextProps.lawsuit,
      });
      this.MovementService = new MovementService();
      var url_string = window.location.href;
      var url = new URL(url_string);
      var movements = url.searchParams.get("movements");
      //console.log(parte);//console.log(movements);
      if( movements !== null ){
        document.getElementById("movements-lawsuit-target").className += ' in';
        this.MovementService.deleteMovements( this.state.lawsuit_token ).then( response => {

        });
        setTimeout(() => {
          window.scrollTo(0, this.myRef.current.offsetTop-67)
        }, 800);
      }

  }

  roll = (event) => {

    this.MovementService = new MovementService();

    /*código de deletar movement*/

    if ( !event.target.classList.contains('collapsed') ) {
      this.MovementService.deleteMovements( this.state.lawsuit_token ).then( response => {

      });
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop-67)
      }, 800);
    }

  }

  openAddInfo ( e ){
    document.getElementById("info_moviment_add").classList.remove("hide");
    document.getElementById("movement-table").className += ' hide';
    document.getElementById("bnt-cadastrar-info_moviment").className += ' hide';
    if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
      document.getElementById("mvmt-show-hide-button-container").className += ' hide';
    }
  }

  addInfo ( e ){

    document.getElementById("bnt-cadastrar-nova-info").disabled = true;
    document.body.style.cursor = "wait";

    let data = {
      'lawsuit_token': document.getElementById("lawsuit_token").value,
      'movement': this.state.info,
    }

    this.LawsuitService = new LawsuitService();

    this.LawsuitService.addMovement( data ).then(res =>{

      document.getElementById("bnt-cadastrar-nova-info").disabled = false;
      document.body.style.cursor = "default";

      //alert(res.message);

      this.LawsuitService.get( document.getElementById("lawsuit_token").value ).then(res =>{

            this.setState(
              {
                'info': "",
                'lawsuit': res,
              }
            );

      });


      this.setState(
        {
          'info': "",
        }
      );

      document.getElementById("info_moviment_add").className += " hide";
      document.getElementById("movement-table").classList.remove("hide");
      document.getElementById("bnt-cadastrar-info_moviment").classList.remove("hide");
      document.getElementById("addPopover").classList.remove("hide");
      if ( document.getElementById("mvmt-show-hide-button-container") !== null ){        
        document.getElementById("mvmt-show-hide-button-container").classList.remove("hide");
      }
    });

  }

  openAddScraps ( e ) {

      document.getElementById("movements_add").classList.remove("hide");
      document.getElementById("movement-table").className += ' hide';

      if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
        document.getElementById("mvmt-show-hide-button-container").className += ' hide';
      }

      let movement = this.state.lawsuit.movements.filter( ( movement )=>{ return movement.token === e.target.dataset.token } )[0];

      document.getElementById("date-form").innerHTML = movement.date.substr(0, 10).split('-').reverse().join('/');

      document.getElementById("movement-name-form").innerHTML = movement.name;

      document.getElementById("movement_token").value = e.target.dataset.token;

      this.setState(
        { scrap: ""}
      )

  }

  openEditScraps ( e ) {

      document.getElementById("movements_edit").classList.remove("hide");
      document.getElementById("movement-table").className += ' hide';

      if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
        document.getElementById("mvmt-show-hide-button-container").className += ' hide';
      }

      document.getElementById("date-edit-form").innerHTML = e.target.dataset.date_comment.substr(0, 10).split('-').reverse().join('/');

      document.getElementById("movement_edit_token").value = e.target.dataset.comment_token;

      this.setState(
        { scrap: e.target.dataset.scrap}
      )

  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   voltar ( e ){
    document.getElementById("info_moviment_add").className += " hide";
    if ( document.getElementById("movement-table") !== null ){        
      document.getElementById("movement-table").classList.remove("hide");
    }
    document.getElementById("bnt-cadastrar-info_moviment").classList.remove("hide");
    if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
      document.getElementById("mvmt-show-hide-button-container").classList.remove("hide");
    }
  }

  voltar_movements ( e ) {
    document.getElementById("movements_add").className += ' hide';
    document.getElementById("movements_edit").className += ' hide';
    document.getElementById("movement-table").classList.remove("hide");
    if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
      document.getElementById("mvmt-show-hide-button-container").classList.remove("hide");
    }
  }

  addScrap( e ) {
      this.MovementService = new MovementService();

      let comment = {
          'movement_token': document.getElementById("movement_token").value,
          'comment': this.state.scrap
      };

      this.MovementService.addComment( comment ).then(res =>{

          document.getElementById("movements_add").className += ' hide';
          document.getElementById("movement-table").classList.remove("hide");

          if( res.success === true ){
              this.modifiedMovements();
              //alert("Comentário adicionado com sucesso");
          }else{
              //alert(res.message);
          }

      });
  }

  editScrap( e ) {
      this.MovementService = new MovementService();

      this.MovementService.editComment( document.getElementById("movement_edit_token").value, this.state.scrap ).then(res =>{

          document.getElementById("movements_edit").className += ' hide';
          document.getElementById("movement-table").classList.remove("hide");

          if( res.success === true ){
              this.modifiedMovements();
              //alert("Comentário alterado com sucesso");
          }else{
              //alert(res.message);
          }

      });
  }

  deleteScrap( e, r = false ) {

    //let result = window.confirm( "Deseja excluir definitivamente este comentário?");

    if ( r ) {

      this.MovementService = new MovementService();

      this.MovementService.deleteComment( e.target.dataset.comment_token ).then(res =>{

          if( res.success === true ){
              this.modifiedMovements();
              //alert("Comentário excluído com sucesso");
          }else{
              //alert(res.message);
          }

      });

    }else{
      let evt = {
        target: {
          dataset: {
            comment_token: e.target.dataset.comment_token
          }
        }
      }
      this.setState({
        showConfirm: true,
        scrapToDelete: evt,
        message: "Deseja excluir definitivamente este comentário?"
      });
    }

  }

  showComments = ( e ) => {
    let itens = document.getElementsByClassName("comment_item-"+e.target.dataset.index);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 3 ) {
           itens[n].className += ' show';
         }
     }

     document.getElementById("show-comment-button-"+e.target.dataset.index).className += ' hide';
     document.getElementById("hide-comment-button-"+e.target.dataset.index).className += ' show';

  }

  hideComments = ( e ) => {
    let itens = document.getElementsByClassName("comment_item-"+e.target.dataset.index);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 3 ) {
           itens[n].classList.remove('show');
         }
     }

     document.getElementById("hide-comment-button-"+e.target.dataset.index).classList.remove('show');
     document.getElementById("show-comment-button-"+e.target.dataset.index).classList.remove('hide');

  }

  commentItem = ( movement_comment, index, type ) => {

      let resto = index % 2;

      this.number_comments = index+1;

      return (
        <li key={movement_comment.token} className={ ( resto === 0 )? "comment_item comment_item-"+type: "reverse comment_item comment_item-"+type }>

            <span className="date_comment">{ ( movement_comment.created !== null ) ? moment( movement_comment.created ).format("DD/MM/YYYY") : "" } - ({movement_comment.user_name}) às { ( movement_comment.created !== null ) ? moment( movement_comment.created ).format('HH:mm') : ""}</span>
            <span className="scrap-box">{movement_comment.comment}</span>
            <i className={ ( movement_comment.owner === 1 ) ? "fa fa-pencil" : "" } title="Editar este Comentário" data-date_comment={movement_comment.created} data-scrap={movement_comment.comment} data-comment_token={movement_comment.token} onClick={this.openEditScraps}></i>
            <i className={ ( movement_comment.owner === 1 && this.authService.can('comentarios_andamentos_processo_excluir', true, 'processos') ) ? "fa fa-times text-danger" : "" } title="Excluir Comentário" data-comment_token={movement_comment.token} onClick={ (event)=>{ this.deleteScrap(event)} }></i>
        </li>
      );
  };

  comment_button_container = ( movement_comment, index, type ) => {

      if ( index > 2 && index < 4 ) {
        return (
          <div key={index} className="comment-button-container">
              <span id={"show-comment-button-"+type} className="btn-small btn-info" data-index={type} onClick={this.showComments}>Exibir todos os comentários</span>
              <span id={"hide-comment-button-"+type} className="btn-small btn-info hide-button" data-index={type} onClick={this.hideComments}>Ocultar comentários</span>
          </div>
        );
      }else {
        return "";
      }

  };

    movementItem = ( {record, index}, type ) => {

        let andamento = index; let comment_list = null; let have_comment = null; let comment_button = null;


        if( this.authService.can('comentarios_andamentos_processo', true, 'processos') ){
          comment_list = <ul id={"list-"+record.token} className="comments-list collapse">
              { (typeof record.movement_comments !== "undefined") ? record.movement_comments.map( (movement_comment, index) =>
                this.commentItem( movement_comment, index, andamento) ) : ""
              }
              { (typeof record.movement_comments !== "undefined") ? record.movement_comments.map( (movement_comment, index) =>
                this.comment_button_container( movement_comment, index, andamento) ) : ""
              }
          </ul>;

          have_comment = <HaveComment record={record} />;

          comment_button = <span className="pointer pull-right bt-edit movement-insert" id="bt-edit-dados-processo">
              <i className="fa fa-pencil-square-o" data-token={record.token} title="Adicionar Comentário" onClick={this.openAddScraps}></i>
              <span className="edit-label">Comentar</span>
          </span>;
        }

        return (
          <tr key={record.key} className={type}>
                <td className="number_movement hidden-xs hidden-sm">
                      {record.numero_andamento}
                </td>
                <td className="date" >
                      {(record.date !== null ) ? record.date.substr(0, 10).split('-').reverse().join('/') : ""}
                </td>
                <td className="movement" >
                      <span className="andamentos">
                        {record.name}
                      </span>
                      {comment_list}
                </td>
                <td className="movement_actions">
                      <span>
                        {have_comment}
                        {comment_button}
                      </span>
                </td>
          </tr>


        );
    };

    showMovements = ( e ) => {
      let itens = document.getElementsByClassName(e.target.dataset.type);

      for (let n = itens.length - 1; n >= 0; n--) {

           if ( n >= 10 ) {
             itens[n].className += ' show-table';
           }
       }

       document.getElementById("show-button-"+e.target.dataset.type).className += ' hide';
       document.getElementById("hide-button-"+e.target.dataset.type).className += ' show';

    }

    hideMovements = ( e ) => {
      let itens = document.getElementsByClassName(e.target.dataset.type);

      for (let n = itens.length - 1; n >= 0; n--) {

           if ( n >= 10 ) {
             itens[n].classList.remove('show-table');
           }
       }

       document.getElementById("hide-button-"+e.target.dataset.type).classList.remove('show');
       document.getElementById("show-button-"+e.target.dataset.type).classList.remove('hide');

    }

    show_hide_button_container = ( record, index, type ) => {

        if ( index > 2 && index < 4 ) {
          return (
            <div key={index} id="mvmt-show-hide-button-container" className="show-hide-button-container">
                <span id={"show-button-"+type} className="btn-small btn-info show-button" data-type={type} onClick={this.showMovements}>Ver mais</span>
                <span id={"hide-button-"+type} className="btn-small btn-info hide-button" data-type={type} onClick={this.hideMovements}>Ocultar registros</span>
            </div>
          );
        }else {
          return "";
        }

    };

  render() {

      let url_base = localStorage.getItem('url_base');

      if( !this.authService.can('andamentos_processo', true, 'processos') ){
        return null;
      }

      var movements  = []; var i = 0;

      this.state.lawsuit.movements.map((value, index, array )=>{
          movements[index] = value;
          movements[index].key = value.id;
          movements[index].numero_andamento = array.length-i;
          i++;
          return movements;
      });
      i = 0;

      let updated = null;

      if( this.state.lawsuit.status === "ERRO" ){
        updated = moment( this.state.lawsuit.created ).format("DD/MM/YYYY");

        updated = <div>
                    <i className="fa fa-remove text-danger" title="Processo com erro. Não está sendo atualizado."></i>
                    <span>Cadastrado em: <span>{ updated }</span></span>
                  </div>;
      }else if( this.state.lawsuit.ultima_atualizacao !== null && (this.state.lawsuit.name == null && this.state.lawsuit.tribunal.monitored == true) ){
        updated = moment( this.state.lawsuit.ultima_atualizacao ).format("DD/MM/YYYY HH:mm");

        updated = <div>
                    <i className="fa fa-refresh text-success" title="Atualização Automática do andamento do processo."></i>
                    <span>Atualizado em: <span>{ updated }</span></span>
                  </div>;

                  /*                    <div className="update-in">
                                            {updated}
                                      </div>*/
      }

      //console.log("o processo é manual?", this.state.lawsuit);

      return <div className="panel panel-default container-parte">
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#movements-lawsuit-target">
                        <strong>Andamentos do Processo</strong>
                    </div>
                    <div className="update-in">
                          {updated}
                    </div>
                </div>

                <div className="panel-body collapse" id="movements-lawsuit-target">
                    <span className={ (this.state.lawsuit.name != null || this.state.lawsuit.tribunal.monitored == false) ? "btn btn-primary" : "hide"} id="bnt-cadastrar-info_moviment" onClick={this.openAddInfo}>Incluir</span>
                    <div id="movements_edit" className="hide panel panel-warning">
                          <div className="panel-heading text-center">
                              <strong>Alterar Comentário</strong>
                          </div>
                          <div className="panel-body">
                                  <div className="form-group col-md-12 text-left">
                                        <span id="date-edit-form"></span> - <span id="movement-edit-name-form"></span>
                                        <input type="hidden" name="movement_edit_token" id="movement_edit_token" />
                                  </div>
                                  <div className="form-group col-md-12 text-left">
                                        <label htmlFor="scrap" className="control-label">Observações</label>
                                        <textarea name="scrap" rows="8" maxLength="2000" id="scrap" className="form-control" value={this.state.scrap} onChange={this.handleChange}></textarea>
                                  </div>
                                  <div className="form-group col-md-12 text-right">
                                        <input type="submit" className="btn btn-primary horizontal-space" value="Cadastrar" id="bnt-edit-scrap" onClick={this.editScrap} />
                                        <span className="horizontal-space">Máximo de 2.000 caracteres.</span>
                                        <span className="btn btn-danger horizontal-space" id="bnt-voltar-movements" onClick={this.voltar_movements}>Voltar</span>
                                  </div>
                          </div>
                    </div>
                    <div id="movements_add" className="hide panel panel-warning">
                          <div className="panel-heading text-center">
                              <strong>Inserir novo comentário ao andamento do processo</strong>
                          </div>
                          <div className="panel-body">
                                  <div className="form-group col-md-12 text-left">
                                        <span id="date-form"></span> - <span id="movement-name-form"></span>
                                        <input type="hidden" name="movement_token" id="movement_token" />
                                  </div>
                                  <div className="form-group col-md-12 text-left">
                                        <label htmlFor="scrap" className="control-label">Observações</label>
                                        <textarea name="scrap" id="scrap" rows="8" maxLength="2000" className="form-control" value={this.state.scrap} onChange={this.handleChange}></textarea>
                                  </div>
                                  <div className="form-group col-md-12 text-right">
                                        <input type="submit" className="btn btn-primary horizontal-space" value="Cadastrar" id="bnt-cadastrar-novo-scrap" onClick={this.addScrap} />
                                        <span className="horizontal-space">Máximo de 2.000 caracteres.</span>
                                        <span className="btn btn-danger horizontal-space" id="bnt-voltar-movements" onClick={this.voltar_movements}>Voltar</span>
                                  </div>
                          </div>
                    </div>
                    <div id="info_moviment_add" className="hide panel panel-warning">
                        <div className="panel-body">
                                    <div className="form-group col-md-12 text-left">
                                          <label htmlFor="info" className="control-label">Andamento</label>  
                                          
                                          <textarea name="info" id="info_moviment" rows="8" className="form-control" value={this.state.info} onChange={this.handleChange}></textarea>
                                    </div>
                                    <div className="form-group col-md-12 text-right">
                                          <input type="submit" className="btn btn-primary horizontal-space" value="Cadastrar" id="bnt-cadastrar-nova-info" onClick={this.addInfo} />
                                          <span className="btn btn-danger horizontal-space" id="bnt-voltar" onClick={this.voltar}>Voltar</span>
                                    </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                          <table id="movement-table" className="table">
                            <thead>
                                <tr>
                                      <th className="number_movement hidden-xs hidden-sm">
                                          <div>N°</div>
                                      </th>
                                      <th className="date">
                                          <div>Data</div>
                                      </th>
                                      <th className="movement">
                                          <div>Andamentos</div>
                                      </th>
                                      <th className="movement_actions">
                                          <div>
                                                 Ações
                                                 <Popover
                                                     isOpen={this.state.isPopoverOpen}
                                                     position={['top', 'right', 'left', 'bottom']}
                                                     padding={10}
                                                     onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                                     content={({ position, targetRect, popoverRect }) => (
                                                         <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                             position={position}
                                                             targetRect={targetRect}
                                                             popoverRect={popoverRect}
                                                             arrowColor={'#94bcd8'}
                                                             arrowSize={10}
                                                         >
                                                             <div className="popover-box"
                                                                 onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                                             >
                                                                   Possibilita registos de comentários em cada andamento do processo <i id="balaozinho" className="fa fa-comment"></i>.  A sua edição e exclusão será possível somente pelo próprio usuário que o fez,  e se liberado pelo administrador.
                                                             </div>
                                                         </ArrowContainer>
                                                     )}
                                                 >
                                                     <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                                 </Popover>
                                          </div>
                                      </th>
                                </tr>
                            </thead>
                            <tbody>
                                  { ( typeof movements !== "undefined") ? movements.map( (record, index) => this.movementItem({ record, index }, 'movement-item') ) : null }
                            </tbody>
                    </table>
                    </div>
                    { ( typeof movements !== "undefined" ) ? movements.map( (record, index) =>
                      this.show_hide_button_container( record, index, 'movement-item' ) ) : ""
                    }
              </div>
          </div>

  }
}
