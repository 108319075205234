function inflateTime () {
                    let hour = 0;
                    let minute = 0;
                    let label = "";
                    let timer = [];
                    let obj = {};

                    for( var i=0; i < 1440; i++ ){

                      if( i % 60 === 0 ){
                        minute = 0;
                        hour++;
                      }else{
                        minute = minute+1;
                      }

                      let hour_label = (hour == 24) ? 0 : hour;

                      label = hour_label.toString().padStart(2, '0')+":"+minute.toString().padStart(2, '0');

                      obj = {key: i, label: label, value: label};
                      timer.push( obj );

                    }

                    return timer;
                  }

const times = inflateTime();
export default times;
