const options = [
    {
        key: "new",
        label: "Novo Processo",
        leftIcon: "add",
        children: [
              {
                  key: "add-manual",
                  label: "Manual",
                  /*leftIcon: "library_books",*/
              },
              {
                key: "tribunal/add",
                label: "Judicial",
              },
              {
                key: "tribunal/inss",
                label: "INSS",
              }
        ]
    },
    {
        key: "tribunal",
        label: "Processos",
        leftIcon: "account_balance",
        children: [
              {
                  key: "processo-manual",
                  label: "Manual",
                  /*leftIcon: "library_books",*/
              },
              {
                key: "tribunal",
                label: "Tribunais",
            /*    grandchildren: [
            {
                key: 'federais',
                label: 'Federais',
                grandchildren: [
                    {
                        key: 'processo/listar_processo/stf',
                        label: 'STF - Supremo Tribunal Federal',
                        slug: "stf",
                    },
                    {
                        key: 'processo/listar_processo/stj',
                        label: 'STJ - Supremo Tribunal de Justiça',
                        slug: "stj",
                    },
                    {
                        key: 'processo/listar_processo/tse',
                        label: 'TSE - Tribunal Superior Eleitoral',
                        slug: "tse"
                    },
                    {
                        key: 'processo/listar_processo/tst',
                        label: 'TST - Tribunal Superior do Trabalho',
                        slug: "tst"
                    },
                ]
            },
            {
                key: 'rj',
                label: 'Rio de Janeiro',
                grandchildren: [
                    {
                        key: 'processo/listar_processo/jfrj1grau',
                        label: 'RJ - Justiça Federal 1º Grau',
                        slug: "jfrj1grau",
                    },
                    {
                        key: 'processo/listar_processo/trf2',
                        label: 'RJ - TRF2',
                        slug: "trf2",
                    },
                    {
                        key: 'processo/listar_processo/tjrj',
                        label: 'RJ - Tribunal de Justiça',
                        slug: "tjrj",
                    },
                    {
                        key: 'processo/listar_processo/trt1',
                        label: 'RJ - TRT 1° Região',
                        slug: "trt1",
                    },
                    {
                        key: 'processo/listar_processo/trt1pje',
                        label: 'RJ - TRT 1° Região - PJE',
                        slug: "trt1pje",
                    },
                ]
            },
              {
                  key: 'sp',
                  label: 'São Paulo',
                  grandchildren: [
                    {
                        key: 'processo/listar_processo/trt2',
                        label: 'SP - TRT 2ª Região - PJE',
                        slug: "trt2",
                    }*/
                /*    {
                        key: 'processo/listar_processo/1jf',
                        label: 'SP - 1° Grau - Justiça Federal',
                        slug: "1jf",
                    },
                    {
                        key: 'processo/listar_processo/jef',
                        label: 'SP - Juizado Especial Federal',
                        slug: "jef",
                    },
                    {
                        key: 'processo/listar_processo/trf3',
                        label: 'SP - TRF3',
                        slug: "trf3",
                    },
                    {
                        key: 'processo/listar_processo/trf3pje',
                        label: 'SP - TRF3 - PJE',
                        slug: "trf3pje",
                    },
                    {
                        key: 'processo/listar_processo/tjsp',
                        label: 'SP - Tribunal de Justiça',
                        slug: "tjsp",
                    },
                    {
                        key: 'processo/listar_processo/tjmilsp',
                        label: 'SP - TM - Tribunal Militar',
                        slug: "tjmilsp",
                    },
                    {
                        key: 'processo/listar_processo/trt15',
                        label: 'SP - TRT 15ª Região',
                        slug: "trt15",
                    },
                    {
                        key: 'processo/listar_processo/trt15pje',
                        label: 'SP - TRT 15ª Região - PJE',
                        slug: "trt15pje",
                    },*/
                  /*  {
                        key: 'processo/listar_processo/trt2pje',
                        label: 'SP - TRT 2ª Região - PJE',
                        slug: "trt2pje",
                    },
                  ]
              },
            ]*/
          },
            /*{
                key: 'mg',
                label: 'Minas Gerais',
                grandchildren: [
                    {
                        key: 'processo/listar_processo/trf1',
                        label: 'MG - TRF1',
                        slug: "trf1",
                    },
                    {
                        key: 'processo/listar_processo/tjmg',
                        label: 'MG - Tribunal de Justiça',
                        slug: "tjmg",
                    },
                    {
                        key: 'processo/listar_processo/tjmgpje',
                        label: 'MG - Tribunal de Justiça - PJE',
                        slug: "tjmgpje",
                    },
                    {
                        key: 'processo/listar_processo/tjmgprojudi',
                        label: 'MG - TJMG - PROJUDI',
                        slug: "tjmgprojudi",
                    },
                    {
                        key: 'processo/listar_processo/trt3',
                        label: 'MG - TRT 3ª Região',
                        slug: "trt3",
                    },
                    {
                        key: 'processo/listar_processo/trt3pje',
                        label: 'MG - TRT 3ª Região - PJE',
                        slug: "trt3pje",
                    },
                ]
            },*/
            {
                key: 'processo/listar_processo/inss',
                label: 'INSS',
                slug: "inss",
            },
        ],
    },
    {
        key: "agenda",
        label: "Agenda",
        leftIcon: "event",
    },
    {
        key: "financeiro",
        label: "Financeiro",
        leftIcon: "attach_money",
        children: [
                    {
                        key: 'financeiro/pagar',
                        label: 'Contas a Pagar',
                    },
                    {
                        key: 'financeiro/receber',
                        label: 'Contas a Receber',
                    },
                    {
                        key: 'financeiro/fluxo-caixa',
                        label: 'Fluxo de Caixa',
                    },
                ]
    },
    {
        key: "doc",
        label: "Doc-Fácil",
        leftIcon: "description",
        children: [
            {
                key: 'gerenciar_modelos',
                label: 'Gerenciar Modelos',
            },
            {
                key: 'listar_modelos',
                label: 'Listar Modelos',
            },
            {
                key: 'gerar_documento',
                label: 'Gerar Documento',
            },
        ]
    },
    {
        key: "relatorios",
        label: "Relatorios",
        leftIcon: "insert_chart",
        children: [
            {
                key: 'relatorio/agenda',
                label: 'Agenda',
            },
            {
                key: 'relatorio/processo',
                label: 'Processo',
            },
            {
                key: 'relatorio/financeiro',
                label: 'Financeiro',
            },
            {
                key: 'relatorio/cliente',
                label: 'Cliente',
            },
        ]
    },
    {
        key: "configs",
        label: "Configurações",
        leftIcon: "settings",
        children: [
            {
                key: 'config/usuario',
                label: 'Usuarios e Permissões',
            },
            {
                key: 'config/logo',
                label: 'Cabeçalho e Logo',
            },
            {
                key: 'config/escritorio',
                label: 'Meus dados e Planos',
            },
            {
                key: 'config/tribunal',
                label: 'Tribunais',
            },

        ]
    },
    /*{
        key: "videos",
        label: "Vídeos",
        leftIcon: "videocam",
        children: [
            {
                key: 'video/111',
                label: 'Primeiros Passos',
            },
            {
                key: 'video/222',
                label: 'Conhecendo o Sistema',
            },
            {
                key: 'video/33',
                label: 'Cadastro Processo Manual',
            },
            {
                key: 'video/4',
                label: 'Cadastro Processo Judicial',
            },
        ]
    },*/
    // {
    //     key: 'mailbox',
    //     label: 'sidebar.email',
    //     leftIcon: 'fa-university',
    // },
    // {
    //     key: 'chat',
    //     label: 'sidebar.chat',
    //     leftIcon: 'ion-chatbubbles',
    // },
    // {
    //     key: 'ecommerce',
    //     label: 'sidebar.ecommerce',
    //     leftIcon: 'ion-bag',
    //     children: [
    //         {
    //             key: 'shop',
    //             label: 'sidebar.shop',
    //             leftIcon: 'ion-bag',
    //             children: [
    //                 {
    //                     key: 'shop',
    //                     label: 'sidebar.shop',
    //                 },
    //                 {
    //                     key: 'cart',
    //                     label: 'sidebar.cart',
    //                 },
    //                 {
    //                     key: 'checkout',
    //                     label: 'sidebar.checkout',
    //                 },
    //                 {
    //                     key: 'card',
    //                     label: 'sidebar.cards',
    //                 },
    //             ],
    //         },
    //         {
    //             key: 'cart',
    //             label: 'sidebar.cart',
    //         },
    //         {
    //             key: 'checkout',
    //             label: 'sidebar.checkout',
    //         },
    //         {
    //             key: 'card',
    //             label: 'sidebar.cards',
    //         },
    //     ],
    // },
    // {
    //     key: 'maps',
    //     label: 'sidebar.maps',
    //     leftIcon: 'ion-map',
    //     children: [
    //         {
    //             key: 'googlemap',
    //             label: 'sidebar.googleMap',
    //         },
    //         {
    //             key: 'leafletmap',
    //             label: 'sidebar.leafletMap',
    //         },
    //     ],
    // },
    // {
    //     key: 'invoice',
    //     label: 'sidebar.invoice',
    //     leftIcon: 'ion-clipboard',
    // },
    // {
    //     key: 'youtubeSearch',
    //     label: 'sidebar.youtubeSearch',
    //     leftIcon: 'ion-social-youtube',
    // },
    // {
    //     key: 'calendar',
    //     label: 'sidebar.calendar',
    //     leftIcon: 'ion-calendar',
    // },
    // {
    //     key: 'notes',
    //     label: 'sidebar.notes',
    //     leftIcon: 'ion-ios-paper',
    // },
    // {
    //     key: 'todo',
    //     label: 'sidebar.todos',
    //     leftIcon: 'ion-android-checkbox-outline',
    // },
    // {
    //     key: 'firestorecrud',
    //     label: 'sidebar.firestorecrud',
    //     leftIcon: 'ion-fireball',
    //
    //     children: [
    //         {
    //             key: 'articles',
    //             label: 'sidebar.firestorecrudarticle',
    //         },
    //         {
    //             key: 'investors',
    //             label: 'sidebar.firestorecrudinvestor',
    //         },
    //     ],
    // },
    // {
    //     key: 'contacts',
    //     label: 'sidebar.contacts',
    //     leftIcon: 'ion-android-person-add',
    // },
    // {
    //     key: 'shuffle',
    //     label: 'sidebar.shuffle',
    //     leftIcon: 'ion-grid',
    // },
    // {
    //     key: 'charts',
    //     label: 'sidebar.charts',
    //     leftIcon: 'ion-arrow-graph-up-right',
    //     children: [
    //         {
    //             key: 'googleChart',
    //             label: 'sidebar.googleCharts',
    //         },
    //         {
    //             key: 'reecharts',
    //             label: 'sidebar.recharts',
    //         },
    //         {
    //             key: 'reactChart2',
    //             label: 'sidebar.reactChart2',
    //         },
    //         {
    //             key: 'frappeChart',
    //             label: 'sidebar.frappeChart',
    //         },
    //     ],
    // },
    // {
    //     key: 'Forms',
    //     label: 'sidebar.forms',
    //     leftIcon: 'ion-android-mail',
    //     children: [
    //         {
    //             key: 'InputField',
    //             label: 'sidebar.input',
    //         },
    //         {
    //             key: 'editor',
    //             label: 'sidebar.editor',
    //         },
    //         {
    //             key: 'FormsWithValidation',
    //             label: 'sidebar.formsWithValidation',
    //         },
    //         {
    //             key: 'progress',
    //             label: 'sidebar.progress',
    //         },
    //         {
    //             key: 'button',
    //             label: 'sidebar.button',
    //         },
    //         {
    //             key: 'tab',
    //             label: 'sidebar.tab',
    //         },
    //         {
    //             key: 'checkbox',
    //             label: 'sidebar.checkbox',
    //         },
    //         {
    //             key: 'radiobox',
    //             label: 'sidebar.radiobox',
    //         },
    //         {
    //             key: 'selectbox',
    //             label: 'sidebar.selectbox',
    //         },
    //         {
    //             key: 'transfer',
    //             label: 'sidebar.transfer',
    //         },
    //         {
    //             key: 'autocomplete',
    //             label: 'sidebar.autocomplete',
    //         },
    //     ],
    // },
    // // {
    // //   key: 'gridLayout',
    // //   label: 'sidebar.boxOptions',
    // //   leftIcon: 'ion-cube'
    // // },
    // {
    //     key: 'uielements',
    //     label: 'sidebar.uiElements',
    //     leftIcon: 'ion-leaf',
    //     children: [
    //         {
    //             key: 'op_badge',
    //             label: 'sidebar.badge',
    //         },
    //         {
    //             key: 'op_card',
    //             label: 'sidebar.card2',
    //         },
    //         {
    //             key: 'op_carousel',
    //             label: 'sidebar.corusel',
    //         },
    //         {
    //             key: 'op_collapse',
    //             label: 'sidebar.collapse',
    //         },
    //         {
    //             key: 'op_popover',
    //             label: 'sidebar.popover',
    //         },
    //         {
    //             key: 'op_tooltip',
    //             label: 'sidebar.tooltip',
    //         },
    //         {
    //             key: 'op_tag',
    //             label: 'sidebar.tag',
    //         },
    //         {
    //             key: 'op_timeline',
    //             label: 'sidebar.timeline',
    //         },
    //         {
    //             key: 'dropdown',
    //             label: 'sidebar.dropdown',
    //         },
    //         {
    //             key: 'pagination',
    //             label: 'sidebar.pagination',
    //         },
    //         {
    //             key: 'rating',
    //             label: 'sidebar.rating',
    //         },
    //         {
    //             key: 'tree',
    //             label: 'sidebar.tree',
    //         },
    //     ],
    // },
    // {
    //     key: 'advancedUielements',
    //     label: 'sidebar.advancedElements',
    //     leftIcon: 'ion-flash',
    //     children: [
    //         {
    //             key: 'reactDates',
    //             label: 'sidebar.reactDates',
    //         },
    //         {
    //             key: 'codeMirror',
    //             label: 'sidebar.codeMirror',
    //         },
    //         {
    //             key: 'uppy',
    //             label: 'sidebar.uppy',
    //         },
    //         {
    //             key: 'dropzone',
    //             label: 'sidebar.dropzone',
    //         },
    //     ],
    // },
    // {
    //     key: 'feedback',
    //     label: 'sidebar.feedback',
    //     leftIcon: 'ion-thumbsup',
    //     children: [
    //         {
    //             key: 'alert',
    //             label: 'sidebar.alert',
    //         },
    //         {
    //             key: 'modal',
    //             label: 'sidebar.modal',
    //         },
    //         {
    //             key: 'message',
    //             label: 'sidebar.message',
    //         },
    //         {
    //             key: 'notification',
    //             label: 'sidebar.notification',
    //         },
    //         {
    //             key: 'popConfirm',
    //             label: 'sidebar.popConfirm',
    //         },
    //         {
    //             key: 'spin',
    //             label: 'sidebar.spin',
    //         },
    //     ],
    // },
    // {
    //     key: 'table',
    //     label: 'sidebar.tables',
    //     leftIcon: 'ion-android-menu',
    //     children: [
    //         {
    //             key: 'table_ant',
    //             label: 'sidebar.antTables',
    //         },
    //     ],
    // },
    // {
    //     key: 'pages',
    //     label: 'sidebar.pages',
    //     leftIcon: 'ion-document-text',
    //     children: [
    //         {
    //             key: '404',
    //             label: 'sidebar.404',
    //             withoutDashboard: true,
    //         },
    //         {
    //             key: '500',
    //             label: 'sidebar.500',
    //             withoutDashboard: true,
    //         },
    //         {
    //             key: 'signin',
    //             label: 'sidebar.signIn',
    //             withoutDashboard: true,
    //         },
    //         {
    //             key: 'signup',
    //             label: 'sidebar.signUp',
    //             withoutDashboard: true,
    //         },
    //         {
    //             key: 'forgotpassword',
    //             label: 'sidebar.forgotPw',
    //             withoutDashboard: true,
    //         },
    //         {
    //             key: 'resetpassword',
    //             label: 'sidebar.resetPw',
    //             withoutDashboard: true,
    //         },
    //         // {
    //         //   key: 'comingSoon',
    //         //   label: 'sidebar.comingSoon',
    //         //    withoutDashboard: true
    //         // }
    //     ],
    // }
];
export default options;
