import React, { Component } from 'react';
import Parser from 'html-react-parser';

export default class FlashMessage extends Component {
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor( props ) {

      super( props );

      let message = localStorage.getItem('alert_message');

      this.state = {
        showingAlert: true,
        time: props.time,
        alert_mesage: ( message !== null && typeof message !== "undefined"  ) ? message : "" ,
        alert_type: localStorage.getItem('alert_type'),
      };

  }

  componentDidMount( ) { 
    this._isMounted = true;  
  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.time > parseInt( localStorage.getItem('alert_time') ) ) {

      if( this._isMounted ){

              //console.log(nextProps.time);
              //console.log(localStorage.getItem('alert_time'));
              let message = localStorage.getItem('alert_message');

            this.setState({
              time: nextProps.time,
              showingAlert: true,
              alert_mesage: ( message !== null && typeof message !== "undefined"  ) ? message : "" ,
              alert_type: localStorage.getItem('alert_type'),
            });

            setTimeout(() => {
              this.setState({
                showingAlert: false
              });
              localStorage.setItem('alert_message', "");
              localStorage.setItem('alert_type', "");
              localStorage.setItem('alert_time', "");
            }, 6000);

      }

    }
  }

  close = () => {
    if( this._isMounted ){
        this.setState({
          showingAlert: false
        });
    }
  }

  render() {

    var alert_message = this.state.alert_mesage;
    var alert_type = this.state.alert_type;

    if( alert_type === "" || alert_type === null ){
      alert_type = "hide";
    }

    let classes = "alert flash-message alert-dismissible "+alert_type+" "+(this.state.showingAlert ? 'alert-shown' : 'alert-hidden');

        return (
              <div className={classes}>
                <span className={"close "+( (this.state.showingAlert) ? 'alert-shown' : 'alert-hidden')} onClick={(event)=>{this.close();} } aria-label="close">&times;</span>
                <span className={(this.state.showingAlert) ? 'alert-shown' : 'alert-hidden'}>{Parser(alert_message)}</span>
              </div>
        );


  }
}
