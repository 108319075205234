import React from 'react';
import options from './json/menu_options';
import tribunal_options from './json/tribunal_options';
import { Link } from 'react-router-dom';

import TribunalService from '../services/TribunalService';
import LawsuitService from '../services/LawsuitService';
import AuthService from '../services/AuthService';
import OfficeService from '../services/OfficeService';

import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from "./containers/footer";
import TopCharts from './containers/topCharts';
import FlashMessage from './containers/FlashMessage';
import Alert from './containers/alert';
import { momentLocalizer } from 'react-big-calendar';
import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class Home extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    console.log("desmontando a home");
  }


  constructor( { match } ) {
      super();

      this.authService = new AuthService();

      let total_tribunals_count = JSON.parse(localStorage.getItem('total_tribunals_count'));
      let total_manuals_count = JSON.parse(localStorage.getItem('total_manuals_count'));
      let total_inss_count = JSON.parse(localStorage.getItem('total_inss_count'));

      if ( typeof total_manuals_count === undefined || total_manuals_count === null || total_manuals_count === "" ){
        total_manuals_count = 0;
      }else{
        total_manuals_count = total_manuals_count.total;
      }
      if ( typeof total_tribunals_count === undefined || total_tribunals_count === null || total_tribunals_count === "" ){
        total_tribunals_count = 0;
      }else{
        total_tribunals_count = total_tribunals_count.total;
      }
      if ( typeof total_inss_count === undefined || total_inss_count === null || total_inss_count === "" ){
        total_inss_count = 0;
      }else{
        total_inss_count = total_inss_count.total;
      }


      let total_tribunals_count_movements = JSON.parse(localStorage.getItem('total_tribunals_count_movements'));
      if ( typeof total_tribunals_count_movements === undefined || total_tribunals_count_movements === null || 
           total_tribunals_count_movements === "" || moment().diff(JSON.parse(localStorage.getItem('total_tribunals_count_movements')).date, 'minutes',true ) > 5 ){
        total_tribunals_count_movements = 0;
      }else{
        total_tribunals_count_movements = total_tribunals_count_movements.total;
      }
      let total_tribunals_count_favorites_movements = JSON.parse(localStorage.getItem('total_tribunals_count_favorites_movements'));
      if ( typeof total_tribunals_count_favorites_movements === undefined || total_tribunals_count_favorites_movements === null || total_tribunals_count_favorites_movements === "" ){
        total_tribunals_count_favorites_movements = 0;
      }else{
        total_tribunals_count_favorites_movements = total_tribunals_count_favorites_movements.total;
      }
      this.state = {
        'active': (typeof match.params.active !== undefined )? match.params.active : 0,
        'total_manuals_count': total_manuals_count,
        'total_tribunals_count': total_tribunals_count,
        'total_tribunals_count_movements': ( this.authService.can('avisos_de_andamentos', true) ) ? total_tribunals_count_movements : 0,
        'total_tribunals_count_favorites_movements': ( this.authService.can('avisos_de_andamentos', true) ) ? total_tribunals_count_favorites_movements : 0,
        total_inss_count,
        total_inss_movements: 0,
        total_inss_count_favorites_movements: 0,
        manualLawsuitsRaw: [],
        lawsuitListRaw: [],
        inssListRaw: []
      }

      /*const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
      let showPublicationNotice = localStorage.getItem('showPublicationNotice');

      if( typeof loggedUser !== "undefined" && loggedUser !== null && loggedUser.admin && !showPublicationNotice ){
        this.state.changePlanLink = localStorage.getItem('url_base')+'/config/mudar-plano';
        this.state.alertTextButton = "Clique aqui!";
  
        this.state.message= "Atenção: Agora temos disponível para contratação de Publicações a nível nacional. Clique no botão e contrate!";
        this.state.showAlert = true;

        localStorage.setItem('showPublicationNotice', true);
      }else{
        this.state.message = "";
        this.state.showAlert = false;
        this.state.changePlanLink = null;
      }*/

      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
      let showNotice = localStorage.getItem('showNewsNotice');

     //console.log(moment().diff( moment('2022-02-28'), 'days', true));

      if( /*typeof loggedUser !== "undefined" && loggedUser !== null && loggedUser.admin &&*/ !showNotice && moment().diff( moment('2022-08-28'), 'days', true) <=0  ){
  
        this.state.message= "ATENÇÃO à esta novidade, usuário do Avvocato \n\n"+

        "        A partir de agora. todas as melhorias, implementações e novas ferramentas serão informadas na aba “Novidades”, assim que forem implementadas no Avvocato."+
                
        " \n\n       Visualize esta aba de novidades ao lado da Pesquisa, na parte superior. Você será avisado quando for incluído uma ou mais novidades, assim que fizer login no sistema."+
                
        "\n\n        Avvocato, trabalhando para melhorar o seu trabalho! \n\n"+
                
        "\n\n    Qualquer dúvida, nos contate pelo nosso chat.\n\n"+
                
        "        Atenciosamente, \n"+
        "        Equipe Avvocato.";
        this.state.showAlert = true;

        localStorage.setItem('showNewsNotice', true);
      }else{
        this.state.message = "";
        this.state.showAlert = false;
        this.state.changePlanLink = null;
      }

      window.scrollTo(0,0);

  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps.match.params.active)

    this.setState({
        'active': (typeof nextProps.match.params.active !== undefined )? nextProps.match.params.active : 0,
    });

  }

  componentDidMount( ) {
        this._isMounted = true;

        this.authService.setConfigs();

        this.tribunalService = new TribunalService(); 
        console.log("tribunal counts localstorage", JSON.parse(localStorage.getItem('total_tribunals_count')));
        
        this.tribunalService.listAllowed().then( tribunals =>{

          console.log("meu momento", moment());
          console.log("this._isMounted", this._isMounted);

          if( this._isMounted ){ 
            console.log("home montada");
            if( 
                ( JSON.parse(localStorage.getItem('total_tribunals_count')) == null || 
                  JSON.parse(localStorage.getItem('total_tribunals_count')) == 0 || 
                  typeof JSON.parse(localStorage.getItem('total_tribunals_count')) == "undefined"
                ) 
              ){
              setTimeout(() => {              
                this.getCounts( tribunals, 1 );
              }, 100);
            }else{
              console.log("diferenca", moment().diff(JSON.parse(localStorage.getItem('total_tribunals_count')).date, 'minutes',true ));
              if( typeof JSON.parse(localStorage.getItem('total_tribunals_count')).date == "undefined"  || moment().diff(JSON.parse(localStorage.getItem('total_tribunals_count')).date, 'minutes',true ) > 5 ){

                  setTimeout(() => {              
                    this.getCounts( tribunals, 1 );
                  }, 100);

              }else{
                console.log("usando o do cache: " + JSON.parse(localStorage.getItem('total_tribunals_count')).date);
              }
            }
          }

        })
        .catch(err =>{
           //console.log("Conexão Instável."+err);
        });

        //this.getManualCounts();

  }

  getManualCounts = (page=1) => {
    this.lawsuitService = new LawsuitService();

    this.lawsuitService.list(null, null, null, page ).then(res =>{

      if( this._isMounted ){
            /* tratar se tem numero do processo nao é processo manual*/
            let lawsuitList = this.state.manualLawsuitsRaw;
            if( typeof res != "undefined"){ 
              res.data.Lawsuits.map( ( record, index, array ) => {
                  if ( record.number === null ) {
                    lawsuitList.push(record);
                   //console.log("oi");
                  }

                  return record;

              });
            }

            if( res.pagination.nextPage === true){
                this.setState({
                  manualLawsuitsRaw: lawsuitList
                });
      
                this.getManualCounts( page+1 );
            }else{
              this.setState({
                total_manuals_count: lawsuitList.length,
              });             
            }

            localStorage.setItem('total_manuals_count', JSON.stringify({ total: res.pagination.count/*lawsuitList.length*/, date: moment() } ) );
      }

    })
    .catch(err =>{
     //console.log(err);
    });    
  }

  getCounts = ( tribunals, page=1 ) => {
    console.log("entrou no getCounts");

    //console.log(page);

        this.lawsuitService = new LawsuitService();
        this.tribunalService = new TribunalService();
        this.OfficeService = new OfficeService();

        let total_tribunals_count = 0;
        let total_tribunals_count_movements = 0;
        let total_tribunals_count_favorites_movements = 0;

        let total_inss_count = 0;
        let total_inss_movements = 0;
        let total_inss_count_favorites_movements = 0;

        let inssListRaw = this.state.inssListRaw;
        let lawsuitListRaw = this.state.lawsuitListRaw;

        let can_view_movement = this.authService.can('avisos_de_andamentos', true) ;

        this.OfficeService.getPlan().then(response =>{

              if (this._isMounted) {
                  if( response.success === false ){

                  }else{
                    
                    this.setState({
                      total_tribunals_count: response.ativos,
                      total_manuals_count: response.manuais,
                    });
                    //localStorage.setItem('total_manuals_count', JSON.stringify({ total: response.manuais, date: moment() } ) );
                    localStorage.setItem('total_tribunals_count', JSON.stringify({total: response.ativos, date: moment()}) );
                  }
              }

        });

        /*this.tribunalService.counterTribunalsLawsuitsWithoutArchived().then( countTribunals =>{
          let count = 0;
          countTribunals.map( (ct, idx) => {
            if(ct.tribunal_id !=  null && ct.tribunal_id != 24){
              count =  count + parseInt( ct.counter );
            }
            if(ct.tribunal_id == 24){
              this.setState({
                total_inss_count: ct.counter
              });
              localStorage.setItem('total_inss_count', JSON.stringify({total: total_inss_count, date: moment()}) );
            }
          });
          this.setState({
            total_tribunals_count: count,
          });
        });*/

        //tribunals.map( ( record, index ) => { 

          this.lawsuitService.counterByTribunal( 24 ).then(res =>{

              //if( record.id === 24 ){
                total_inss_count += res;
                this.setState({
                  total_inss_count
                });
                localStorage.setItem('total_inss_count', JSON.stringify({total: total_inss_count, date: moment()}) );
              //}else if ( record.id === 23 ){
                //tribunal superior eleitoral temporariamente oculto
              //}else{
              /*  total_tribunals_count += res;
                this.setState({
                  total_tribunals_count
                });
                localStorage.setItem('total_tribunals_count', JSON.stringify({total: total_tribunals_count, date: moment()}) );*/
              //}


              //return null;
          });

        //});

        this.tribunalService.counterTribunalsMovements( ).then(response =>{
          console.log("iniciando contagem de movimentos na home");
          response.map( ( item, i ) => {

            if ( item.tribunal_id === 24 ){
              total_inss_movements += item.count_movements;
            }else if ( item.tribunal_id === 23 ){
              //tribunal superior eleitoral temporariamente oculto
            }else{
              tribunals.map( ( record, index ) => {
                if( item.tribunal_id == record.id && record.id){
                  //console.log(record);
                  total_tribunals_count_movements += item.count_movements;
                }
              });
            }
          });


          console.log("contagem de andamentos", total_tribunals_count_movements);
          console.log("pode ver andamentos", can_view_movement);
          console.log("objeto", JSON.stringify({total: ( can_view_movement ) ? total_tribunals_count_movements : 0, date: moment()}));
          localStorage.setItem('total_tribunals_count_movements', JSON.stringify({total: ( can_view_movement ) ? total_tribunals_count_movements : 0, date: moment()}) );
          localStorage.setItem('total_inss_movements', JSON.stringify({total: ( can_view_movement ) ? total_inss_movements : 0, date: moment()}) );


          this.setState({
            total_tribunals_count_movements,
            total_inss_movements
          });

        });

        this.tribunalService.counterTribunalsMovementsFavorites( ).then(response =>{
          response.map( ( item, i ) => {

            if ( item.tribunal_id === 24 ){
              total_inss_count_favorites_movements += item.count_movements;
            }else if ( item.tribunal_id === 23 ){
              //tribunal superior eleitoral temporariamente oculto
            }else{
              total_tribunals_count_favorites_movements += item.count_movements;
            }
            this.setState({
              total_tribunals_count_favorites_movements,
              total_inss_count_favorites_movements
            });
            localStorage.setItem('total_tribunals_count_favorites_movements', JSON.stringify({total: ( can_view_movement ) ? total_tribunals_count_favorites_movements : 0, date: moment()}) );
            localStorage.setItem('total_inss_count_favorites_movements', JSON.stringify({total: ( can_view_movement ) ? total_inss_count_favorites_movements : 0, date: moment()}) );
          });
        });
          
          /*this.lawsuitService.list( record.id ).then(res =>{

              if( this._isMounted ){

                if( res.data.Lawsuits.length > 0 && res.data.Lawsuits[0].tribunal_id === 24 ){
      
                  res.data.Lawsuits.map( (processo) =>{

                    inssListRaw.push(processo);
                    if( processo.favorito===1 ){
                      total_inss_count_favorites_movements += processo.movimentos_nao_visualizados
                    }else{
                      total_inss_movements += processo.movimentos_nao_visualizados
                    }
  
                  });
  
                }else if( res.data.Lawsuits.length > 0 && res.data.Lawsuits[0].tribunal_id === 23 ){
                  //tribunal superior eleitoral temporariamente oculto

                }else{ //console.log(res.length+ " "+record.id);
  
                  res.data.Lawsuits.map( (processo) =>{

                    lawsuitListRaw.push( processo );
                    if( processo.favorito===1 ){
                      total_tribunals_count_favorites_movements += processo.movimentos_nao_visualizados
                    }else{
                      total_tribunals_count_movements += processo.movimentos_nao_visualizados
                    }
  
                  });
                }


                  total_inss_count = inssListRaw.length;

                  total_tribunals_count = lawsuitListRaw.length;

      
                    //console.log(tribunals);
                    //console.log(ufs);
                    //console.log(total_tribunals_count);
                    this.setState({
                      "total_tribunals_count": total_tribunals_count,
                      "total_inss_count": total_inss_count,
                      "total_tribunals_count_movements": ( can_view_movement ) ? total_tribunals_count_movements : 0,
                      "total_inss_movements": ( can_view_movement ) ? total_inss_movements : 0,
                      "total_tribunals_count_favorites_movements": ( can_view_movement ) ? total_tribunals_count_favorites_movements : 0,
                      "total_inss_count_favorites_movements": ( can_view_movement ) ? total_inss_count_favorites_movements : 0
                    });
                    localStorage.setItem('total_tribunals_count', JSON.stringify({total: total_tribunals_count, date: moment()}) );
                    localStorage.setItem('total_inss_count', JSON.stringify({total: total_inss_count, date: moment()}) );
                    localStorage.setItem('total_tribunals_count_movements', JSON.stringify({total: ( can_view_movement ) ? total_tribunals_count_movements : 0, date: moment()}) );
                    localStorage.setItem('total_inss_movements', JSON.stringify({total: ( can_view_movement ) ? total_inss_movements : 0, date: moment()}) );
                    localStorage.setItem('total_tribunals_count_favorites_movements', JSON.stringify({total: ( can_view_movement ) ? total_tribunals_count_favorites_movements : 0, date: moment()}) );
                    localStorage.setItem('total_inss_count_favorites_movements', JSON.stringify({total: ( can_view_movement ) ? total_inss_count_favorites_movements : 0, date: moment()}) );

              }
              //console.log(res);

          });*/

  }

  getPanelItem = ({ singleOption }) => {
    const { key, label } = singleOption;
    //console.log(grandchildren);

    let numero_processos_cadastrados_container = null;
    let numero_andamentos_container = null;
    let numero_andamentos_favoritos_container = null;

    if( key === 'tribunal' ){
      if( this.state.total_tribunals_count > 0 ){
        numero_processos_cadastrados_container =  <span data-toggle="tooltip" title="N° de Processos Cadastrados" className="bg-green badge horizontal-space" data-original-title="N° de Processos Cadastrados">{this.state.total_tribunals_count}</span>
      }
      if( this.state.total_tribunals_count_movements > 0 ){
        numero_andamentos_container =  <span data-toggle="tooltip" title="Andamentos Não visualizados" className="bg-yellow badge horizontal-space" data-original-title="Andamentos Não visualizados">{this.state.total_tribunals_count_movements}</span>
      }
      if( this.state.total_tribunals_count_favorites_movements > 0 ){
        numero_andamentos_favoritos_container =  <span className="star_container badge bg-gray" data-toggle="tooltip" title="Andamentos Não visualizados de Processos em Destaque" data-original-title="Andamentos Não visualizados de Processos em Destaque" >
                <i className="material-icons starred">star_rate</i>
                <span className="number_starred">
                      {this.state.total_tribunals_count_favorites_movements}
                </span>
            </span>
      }
    }else if( key === 'processo-manual' ){ //console.log(this.state.total_manuals_count);
        if( this.state.total_manuals_count > 0 ){
          numero_processos_cadastrados_container =  <span data-toggle="tooltip" title="N° de Processos Cadastrados" className="bg-green badge horizontal-space" data-original-title="N° de Processos Cadastrados">{this.state.total_manuals_count}</span>
        }
    }else if( key === 'processo/listar_processo/inss' ){
        //console.log( this.state.total_inss_movements );
        if( this.state.total_inss_count > 0 ){
          numero_processos_cadastrados_container =  <span data-toggle="tooltip" title="N° de Processos Cadastrados" className="bg-green badge horizontal-space" data-original-title="N° de Processos Cadastrados">{this.state.total_inss_count}</span>
        }
        if( this.state.total_inss_movements > 0 ){
          numero_andamentos_container =  <span data-toggle="tooltip" title="Andamentos Não visualizados" className="bg-yellow badge horizontal-space" data-original-title="Andamentos Não visualizados">{this.state.total_inss_movements}</span>
        }
        if( this.state.total_inss_count_favorites_movements > 0 ){
          numero_andamentos_favoritos_container =  <span className="star_container badge bg-gray" data-toggle="tooltip" title="Andamentos Não visualizados de Processos em Destaque" data-original-title="Andamentos Não visualizados de Processos em Destaque" >
                  <i className="material-icons starred">star_rate</i>
                  <span className="number_starred">
                        {this.state.total_inss_count_favorites_movements}
                  </span>
              </span>
        }
    }

    return (
      <li key={key} className="treeview">
          <Link to={`/${key}`}>
                <label className="tribunal-name">{label}</label>
                {numero_processos_cadastrados_container}
                {numero_andamentos_container}
                {numero_andamentos_favoritos_container}
          </Link>
      </li>
    );
  };
  render() {/*link={this.state.changePlanLink}*/ /*textLink={this.state.alertTextButton}*/
    var escritorio_virtual = localStorage.getItem('escritorio_virtual' );
    if(  escritorio_virtual === false || escritorio_virtual === 0 || escritorio_virtual === "undefined" || escritorio_virtual === "false" ){
      this.props.history.push( '/afiliados' );
    }

      return <div className={(typeof this.state.active === "undefined")? "home sheet" : "sheet"}>
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar screen={ "Home" } active={ this.state.active } />
                <Alert show={this.state.showAlert} text={this.state.message}  />
                <NavigationMenu active={ this.state.active } />
                <div className="content-wrapper">
                  <TopCharts finance={true} active={ this.state.active } history={this.props.history} />
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <div className="panel-primary panel">
                            <div className="panel-heading text-center"><h4>Processos</h4></div>
                            <div className="panel-body">
                                <ul className="home-menu">
                                      {options[1].children.map(singleOption =>
                                        this.getPanelItem({ singleOption })
                                      )}
                                </ul>
                            </div>
                        </div>
                      </section>
                  </div>
                </div>
             </div>
  }

}
