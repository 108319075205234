import React from 'react';
import Footer from "./containers/footer";
import Alert from './containers/alert';
import { Link } from 'react-router-dom';
import FlashMessage from "./containers/FlashMessage";

import AuthService from '../services/AuthService';
import OfficeService from '../services/OfficeService';
import './Login.css';

export default class Login extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor() {
      super();
      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.Auth = new AuthService();
      this.OfficeService = new OfficeService();

      this.state = {
        message: "",
        showAlert: false
      }

      window.scrollTo(0,0);

  }

  componentWillMount(){
    if(this.Auth.loggedIn())
        this.props.history.replace('/');
  }

  componentDidMount(){
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");

    document.addEventListener("cancelAlert", (e) => {
        this.setState({
        showAlert: false
        });
    });

    var confirm_token = url.searchParams.get("confirm_token");

    if( token !== null){
        this.OfficeService.confirm(token).then(res =>{
              if( res.success === true ){
                  localStorage.setItem('alert_message', res.message);
                  localStorage.setItem('alert_type', 'alert-success');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );

                  setTimeout(() => {

                    this.setState ({
                        update: 1
                    });

                  }, 300);

              }else{
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-danger');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
                setTimeout(() => {

                  this.setState ({
                      update: 2
                  });

                }, 300);
              }
          });
    }

    if( confirm_token !== null){
      this.OfficeService.confirm(confirm_token).then(res =>{
            if( res.success === true ){
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );

                setTimeout(() => {

                  this.setState ({
                      update: 1
                  });

                }, 300);

            }else{
              localStorage.setItem('alert_message', res.message);
              localStorage.setItem('alert_type', 'alert-danger');
              let time = new Date().getTime();
              localStorage.setItem('alert_time', time );
              setTimeout(() => {

                this.setState ({
                    update: 2
                });

              }, 300);
            }
        });
  }

  }


  handleFormSubmit(e){
       e.preventDefault();
       document.body.style.cursor = "wait";
       document.getElementById("submit").disabled = true;

       let delay = 500+Math.random()*500;

       setTimeout(() => {
        this.Auth.login(this.state.email,this.state.password)
        .then(res =>{
           if(res === "success"){
             setTimeout(() => {                  
                 document.body.style.cursor = "default";
                 window.location.href = '/';
             }, 60);
             //this.props.history.replace('/');
           }else{
             document.body.style.cursor = "default";
           }
           document.getElementById("submit").disabled = false;
        })
        .catch(err =>{
            alert(err);
            /*this.setState({
             showAlert: true,
             message: err
            });*/

           if(this._isMounted){
             document.body.style.cursor = "default";
             document.getElementById("submit").disabled = false;
           }
        })        
       }, delay);


   }

   handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }


  render() {

      var url_base = localStorage.getItem('url_base');

      //<span className="alert alert-danger"><center>Estamos com uma instabilidade no servidor e trabalhando para resolvê-la. Desculpe-nos o transtorno.</center></span>

      return <div id="login">
                  <FlashMessage time={ new Date().getTime() } />
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <div className="center">
                      <div className="card">
                          <a className="center logo" href="/">
                            <img alt="nosso logo - avvocato" src={url_base+"/images/logo_avvocato.png"}></img>
                          </a>
                          <h1>Olá faça seu login!</h1>
                          <form>
                              <label htmlFor="email" className="">Seu e-mail</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="Informe seu login ou email"
                                  name="email"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <small id="emailHelp" className="form-text text-muted">Nós nunca compartilharemos seu email com ninguém.</small>
                              <label htmlFor="password">Sua senha</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="Informe sua senha"
                                  name="password"
                                  type="password"
                                  onChange={this.handleChange}
                              />
                              <div className="isoInputWrapper isoLeftRightComponent">
                                    <input
                                        id= "submit"
                                        className="btn btn-primary form-submit"
                                        value="Entrar"
                                        type="submit"
                                        onClick={this.handleFormSubmit.bind(this)}
                                    />
                              </div>
                              <Link className="link-forgot" to="/forgotPassword">
                                Esqueci minha Senha.
                              </Link>
                              <hr></hr>
                              <label htmlFor="email" className="link-forgot">Ainda não usa o Avvocato?</label>
                              <a className="link-forgot" href="https://avvocatosistemas.com.br/teste-gratis">
                                Crie uma conta.
                              </a>
                          </form>
                      </div>
                  </div>
             </div>
  }

}
