import React from 'react';
import { Link } from 'react-router-dom';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import FormEvent from '../events/FormEvent';
import PrintComponent from './printComponent';
import Loading from '../containers/loading';
import MyPopover from '../containers/mypopover';

import UsersService from '../../services/UsersService';
import LawsuitService from '../../services/LawsuitService';
import EventService from '../../services/EventService';
import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const eventList = [
  { value: '1', label: 'Compromisso' },
  { value: '2', label: 'Tarefa' },
  { value: '3', label: 'Notificação' },
];

const statusList = [
  {value: '1', label: 'Em andamento', color: '#00875A'},
  {value: '2', label: 'Realizado', color: '#0052CC'},
  {value: '3', label: 'Cancelado', color: '#FF5630'},

]

const prioridade = [
  { value: '1', label: 'Baixa' },
  { value: '2', label: 'Média' },
  { value: '3', label: 'Alta' },
];

export default class ReportEventChartList extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });
  }

  constructor( { match } ) {
      super();
      const select = { value: '0', label: 'Todos' };
      const fSelect = { value: '0', label: 'Todas' };
      let status = select; let event = select; let priority = fSelect; let user = select; let category = fSelect; let sponsorLawsuit = select;
      let client = select; let lawsuit = select;


      let date_start = (match.params.date_start === "0") ? "Tudo" : match.params.date_start.replace(/-/g,'/');
      let date_end = (match.params.date_end === "0") ? "Tudo" : match.params.date_end.replace(/-/g,'/');

      this.back = this.back.bind(this);
      this.editEvent = this.editEvent.bind(this);
      this.modifiedEvents = this.modifiedEvents.bind(this);
      this.modifiedViewPrint = this.modifiedViewPrint.bind(this);

      this.state = {
        event: event,
        eventList: [],
        status: status,
        priority: priority,
        user: user,
        category: category,
        sponsorLawsuit: sponsorLawsuit,
        client: client,
        lawsuit: lawsuit,
        date_start: date_start,
        date_end: date_end,
        qtd: 0,
        print: 0,
        futuras: [],
        atrasadas: [],
        token: "",
        start: "",
        end: "",
        hoje: [],
        params: match.params,
        users: [],
        loadingVisible: false,
        loadingMessage: [],
      }

  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.match.params !== this.props.match.params) {
      this.initParams( nextProps.match.params );
    }else{
      this.initParams( this.props.match.params );
    }

  }

  componentWillMount() {

    this.initParams( this.props.match.params );

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  initParams( params ){

    const select = { value: '0', label: 'Todos' };
    const fSelect = { value: '0', label: 'Todas' };
    let status = select; let event = select; let priority = fSelect; let user = select; let category = fSelect; let sponsorLawsuit = select;
    let client = select; let lawsuit = select;

    this.setState({
      loadingVisible: true,
      loadingMessage: ["O relatório está sendo processado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
    });

    this.usersService = new UsersService();
    this.lawsuitService = new LawsuitService();
    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.EventService = new EventService();

      this.usersService.list( ).then(userList =>{

          userList.unshift ( {value: -99, label: "Todos"} );

          this.lawsuitService.officeList().then(lawsuitList =>{

            this.EventService.categoryList( ).then(categoryList =>{

                this.LawsuitPersonagesService.list( ).then(clientList =>{

                      let date_start = (params.date_start === "0") ? "Tudo" : params.date_start.replace(/-/g,'/');
                      let date_end = (params.date_end === "0") ? "Tudo" : params.date_end.replace(/-/g,'/');

                      if( params.event !== "0" ){
                        event = eventList.filter( (event)=>{ return event.value === params.event } )[0];
                      }

                      if( params.status !== "0" ){
                        status = statusList.filter( (status)=>{ return status.value === params.status } )[0];
                      }

                      if( params.priority !== "0" ){
                        priority = prioridade.filter( (priority)=>{ return priority.value === params.priority } )[0];
                      }
                      if( params.user === "all" ){
                        params.user = "0";
                      }

                      if( params.category !== "0" ){
                        category = categoryList.filter( (category)=>{ return category.value === params.category } )[0];
                      }

                      if( params.sponsorLawsuit !== "0" ){
                        sponsorLawsuit = userList.filter( (sponsorLawsuit)=>{ return sponsorLawsuit.value === params.sponsorLawsuit } )[0];
                      }

                      if( params.client !== "0" ){
                        client = clientList.filter( (client)=>{ return client.value === params.client } )[0];
                      }

                      if( params.lawsuit !== "0" ){
                        lawsuit = lawsuitList.filter( (lawsuit)=>{ return lawsuit.value === params.lawsuit } )[0];
                      }

                      this.EventService.report( params.date_start, params.date_end, params.event, params.status, params.priority, params.user, params.category, params.sponsorLawsuit, params.client, params.lawsuit).then(eventList =>{

                            this.updateChart( eventList );

                            this.setState({
                              event: event,
                              status: status,
                              priority: priority,
                              user: user,
                              category: category,
                              sponsorLawsuit: sponsorLawsuit,
                              client: client,
                              lawsuit: lawsuit,
                              date_start: date_start,
                              date_end: date_end,
                              params: params,
                              users: userList,
                              loadingVisible: false,
                            });


                      });

                });

            });

          });


      });


  }

  updateChart( events ) {

    //console.log("agora vai calendario widget");
   //console.log( events );

    let hoje = []; let atrasadas = []; let futuras = [];

    for( let i=0; i < events.length; i++){

          if( events[i].start.substr(0,10) === moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //hoje
            hoje.push( events[i] );

          }else if( events[i].start.substr(0,10) < moment().format('YYYY-MM-DD') && ( events[i].events_situation === null || events[i].events_situation.id === 1 ) ){
            //Compromissos Atrasados
           //console.log(events[i]);
            atrasadas.push( events[i] );

          }else if( events[i].start.substr(0,10) > moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //futuros
            futuras.push( events[i] );

          }

    }

    this.setState({
      hoje: hoje,
      atrasadas: atrasadas,
      futuras: futuras,
      qtd: hoje.length+atrasadas.length+futuras.length
    });

 }

  back(){
    this.props.history.goBack();
  }

  modifiedEvents(){
    //console.log("eventos modificados");
    this.initParams( this.state.params );
    this.setState({
      token: "",
      showModal: false,
    });
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  editEvent = ( event ) => {
      //console.log();
      this.setState({
        token: event.target.dataset.token,
        showModal: true,
      });
  }

  eventItem = ( {record, index}, type ) => {

      let lawsuit = ""; let total_users = ""; let event_user = ""; let status = ""; let color = "";

      if (record.lawsuit !== null ) {
        if(record.lawsuit.name !== null){
          lawsuit = <Link to={'/processo/detalhe_processo/'+record.lawsuit.token} >
                          <p>{record.lawsuit.name}</p>
                    </Link> ;
        }else{
          lawsuit = <Link to={'/processo/detalhe_processo/'+record.lawsuit.token} >
                         <p>{record.lawsuit.number}</p>
                         <p>{record.lawsuit.tribunal.slug}</p>
                    </Link>
        }

      }

      if ( record.events_situation !== null && typeof record.events_situation !== "undefined" && record.events_situation.id === 1){
        status = 'Em andamento';
        color = 'event-green';
      }else if ( record.events_situation !== null && typeof record.events_situation !== "undefined" && record.events_situation.id === 2){
        status = 'Realizado';
        color = 'event-blue';
      }else if ( record.events_situation !== null && typeof record.events_situation !== "undefined" && record.events_situation.id === 3){
        status = 'Cancelado';
        color = 'event-red';
      }

      let logged_user_token = JSON.parse(localStorage.getItem('loggedUser')).token;
      let has_permission = false;

      if(record.events_users.length > 0){

        if( record.events_users.length >= 2 ) {
          event_user = record.events_users[0].user.name+" (e outros)";
        }else{
          event_user = record.events_users[0].user.name;
        }



        for(let i = 0; i < record.events_users.length; i++ ){

          if( (record.events_users[i].user.token === logged_user_token) || JSON.parse(localStorage.getItem('loggedUser')).admin ){
            has_permission = true;
          }

          if( i !== 0 ){
            total_users += ", ";
          }
          total_users += record.events_users[i].user.name;
        }
      }

    return (
      <tr key={record.token} className={type}>
              <td className="user" title={total_users}>
                  {event_user}
              </td>
              <td className="category">
                  {( record.event_category !== null ) ? record.event_category.name : ""}
              </td>
              <td className="" >
                  <p>{record.start.substr(0,10).split('-').reverse().join('/')}</p>
                  <p>{record.start.substr(11,5)}</p>
              </td>
              <td className="description " >
                  <p>{(record.event_type !==null && (record.event_type.id === 2 || record.event_type.id === 3 ) ) ? "" : record.title}</p>
                  <p>{record.description}</p>
              </td>
              <td className="table_lawsuit_number">                    
                  {lawsuit}
              </td>
              <td className={"status "+color}>
                  {status}
              </td>
              <td className="actions only-edit hidden-xs hidden-sm">
                    <span className={(has_permission) ? "pointer pull-right bt-edit movement-insert" : "hide"} id="bt-edit-dados-processo">
                        <i className="fa fa-pencil-square-o" data-token={record.token} onClick={( event )=>{this.editEvent( event )} }></i>
                        <span className="edit-label">Editar</span>
                    </span>
              </td>
      </tr>
    )
  }

  render() {
      //let url_base = localStorage.getItem('url_base');

      //console.log(this.state.params.user);
      let name = "";

      this.state.users.map( ( user ) => {
        //console.log(user);
        if( user.value === this.state.params.user ){
          name = "- "+user.label;
        }
        return user;
      });

      let table_head = <thead>
                            <tr>

                                  <th className="table_user user">
                                      <div>Usuário</div>
                                  </th>
                                  <th className="table_category">
                                      <div>Categoria</div>
                                  </th>
                                  <th className="table_date date ">
                                      <div>Data</div>
                                  </th>
                                  <th className="table_description description ">
                                      <div>
                                          <p>Título</p>
                                          <p>Descrição</p>
                                      </div>
                                  </th>
                                  <th className="table_lawsuit_number">
                                      <div>
                                            <p>Processo</p>
                                            <p>Tribunal</p>
                                      </div>
                                  </th>
                                  <th className="status">
                                        Status
                                  </th>
                                  <th className="table_actions hidden-xs hidden-sm">
                                      <div>Ações</div>
                                  </th>
                            </tr>
                        </thead>;

      let table_hoje =         <table>
                                          {table_head}
                                          <tbody>
                                              { this.state.hoje.map( (record, index) => this.eventItem( {record, index}, 'report-event-item' ) ) }
                                          </tbody>
                                </table>;
      let table_atrasadas =     <table>
                                          {table_head}
                                          <tbody>
                                              { this.state.atrasadas.map( (record, index) => this.eventItem( {record, index}, 'report-event-item' ) ) }
                                          </tbody>
                                </table>;
      let table_futuras =       <table>
                                          {table_head}
                                          <tbody>
                                              { this.state.futuras.map( (record, index) => this.eventItem( {record, index}, 'report-event-item' ) ) }
                                          </tbody>
                                </table>;
      let content = <div>
                            <h2>Atrasados</h2>
                            {table_atrasadas}
                            <h2>Hoje</h2>
                            {table_hoje}
                            <h2>Amanhã</h2>
                            {table_futuras}
                      </div>;

      if( this.state.print === 1 ){
          return <div>
                    <PrintComponent modifiedViewPrint={this.modifiedViewPrint} name={name} content={content} title={"Relatório da Agenda"} subtitle={(this.state.params.event === "1") ? "Compromissos" : "Tarefas"} />
               </div>
      }else{

        return <div className="sheet reportEventList">
                  <HeaderBar />
                  <NavigationMenu />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                  <div className="content-wrapper">
                    <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Relatórios - Agenda Listagem</h1>
                          <span className="btn btn-info" onClick={this.back} >Voltar</span>
                          <span className="btn btn-default btn-report right" onClick={(event)=>{this.print(event)} }>Imprimir</span>
                        </section>

                        <FormEvent modifiedEvents={this.modifiedEvents} token={this.state.token} showModal={this.state.showModal} start={this.state.start} end={this.state.end} events={[]} />
                        <div className="box-body">
                            <div className="alert alert-info" role="alert">
                                <center>Agenda - {(this.state.params.event === "1") ? "Compromissos" : "Tarefas"} {name}</center>
                                Quantidade de registros encontrados: <strong>{this.state.qtd}</strong>
                            </div>

                            <div className="panel panel-default container-publicacoes">
                                    <div className="panel-heading text-center">
                                        <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                                            <strong className="red">Atrasados ({this.state.atrasadas.length})</strong>
                                        </div>
                                    </div>
                                    <div className="panel-body collapse " id="filter_target">
                                          {table_atrasadas}
                                    </div>
                            </div>
                            <div className="panel panel-default container-publicacoes">
                                  <div className="panel-heading text-center">
                                      <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_atrasadas_target">
                                          <strong className="green">Hoje ({this.state.hoje.length})</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse " id="filter_atrasadas_target">
                                        {table_hoje}
                                  </div>
                            </div>
                            <div className="panel panel-default container-publicacoes">
                                  <div className="panel-heading text-center">
                                      <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_futuras_target">
                                          <strong className="yellow">Amanhã ({this.state.futuras.length})</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse " id="filter_futuras_target">
                                        {table_futuras}
                                  </div>
                            </div>

                        </div>
                    </div>
                  </div>
               </div>
      }

  }

}
