import AuthService from './AuthService';
import {api} from '../config';

import moment from "moment";
import 'moment/locale/pt-br';

export default class FinanceService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
    }

    addIncoming ( finance ){
      finance.financial_type_id = 1;
      return this.fetch(`${this.domain}Financial/add`, {
          method: 'POST',
          body: JSON.stringify(finance)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    editIncoming ( token, finance ){
      finance.financial_type_id = 1;
      return this.fetch(`${this.domain}Financial/edit/${token}`, {
          method: 'PUT',
          body: JSON.stringify(finance)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    listFinancialPaymentOptions () {
      return this.fetch(`${this.domain}FinancialPaymentOptions/index/`, {
          method: 'GET',
      }).then(res => {
          let response = [];

          if( res.success === true ){

            res.data.InputPaymentOptions.map((value, index, array )=>{
              response[index] = { "key": value.token, "value": value.token, "label": value.name };
              return response;
            });

          }

          //console.log(response);
          return Promise.resolve(response);
      });
    }

    financialTypes () {
      return this.fetch(`${this.domain}FinancialTypes/index/`, {
          method: 'GET',
      }).then(res => {
          let response = [];

          if( res.success === true ){

              response = res.data.InputTypes;

          }

          //console.log(response);
          return Promise.resolve(response);
      });
    }

    viewIncoming( token ){
      return this.fetch(`${this.domain}Financial/view/${token}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if( res.success === true ){
                return Promise.resolve(res.data.Financials);
            }

            return Promise.resolve(res);
        })

    }

    deleteIncoming ( token ){
      return this.fetch(`${this.domain}Financial/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    listLawsuitIncoming( lawsuit_token ){
        let endpoint = ""; let sort = '&sort=data_vencimento&direction=ASC';

          endpoint = `${this.domain}Financial/?financial_type_id=1&lawsuit_token=${lawsuit_token}${sort}`;

        return this.fetch( endpoint, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if( res.success === true ){
                return Promise.resolve(res.data.Financials);
            }

            return Promise.resolve(res);
        });
    }

    listIncoming( start, end, pago, centro_custo, lawsuit_token, forma_pagamento = null ){
        let endpoint = ""; let sort = '&sort=data_vencimento&direction=ASC';
        start = start.substr(0, 10).split('/').reverse().join('-');
        end = end.substr(0, 10).split('/').reverse().join('-');

        //console.log(pago);

        if(pago === "3"){
          pago = "";
          endpoint = `${this.domain}Financial/?financial_type_id=1${pago}&start=${start}&end=${end+sort}`;
        }else{
          pago = "&pago="+pago;
          endpoint = `${this.domain}Financial/?financial_type_id=1${pago}&start=${start}&end=${end}${sort}`;
        }

        if(centro_custo !== null){
          centro_custo = "&financial_center_cost_token="+centro_custo;
          endpoint = `${this.domain}Financial/?financial_type_id=1${pago}&start=${start}&end=${end}${centro_custo}&sort=data_vencimento&direction=ASC`;
        }else{
          centro_custo = "";
        }

        if(lawsuit_token !== null){
          lawsuit_token = "&lawsuit_token="+lawsuit_token;
          endpoint = `${this.domain}Financial/?financial_type_id=1${pago}&start=${start}&end=${end}${centro_custo}${lawsuit_token}&sort=data_vencimento&direction=ASC`;
        }else{
          lawsuit_token = "";
        }

        if(forma_pagamento !== null){
          forma_pagamento = "&financial_payment_option_token="+forma_pagamento;
          endpoint = `${this.domain}Financial/?financial_type_id=1${pago}&start=${start}&end=${end}${centro_custo}${lawsuit_token}${forma_pagamento}&sort=data_vencimento&direction=ASC`;
        }

        return this.fetch( endpoint, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if( res.success === true ){
                return Promise.resolve(res.data.Financials);
            }

            return Promise.resolve(res);
        });
    }

    addOutgoing ( finance ){
      finance.financial_type_id = 2;
      return this.fetch(`${this.domain}Financial/add`, {
          method: 'POST',
          body: JSON.stringify(finance)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    editOutgoing ( token, finance ){
      finance.financial_type_id = 2;
      return this.fetch(`${this.domain}Financial/edit/${token}`, {
          method: 'PUT',
          body: JSON.stringify(finance)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    viewOutgoing( token ){
      return this.fetch(`${this.domain}Financial/view/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          if( res.success === true ){
              return Promise.resolve(res.data.Financials);
          }

          return Promise.resolve(res);
      });

    }

    deleteOutgoing ( token ){
      return this.fetch(`${this.domain}Financial/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    listLawsuitOutgoing( lawsuit_token ){
        let endpoint = ""; let sort = '&sort=data_vencimento&direction=ASC';

          endpoint = `${this.domain}Financial/?financial_type_id=2&lawsuit_token=${lawsuit_token}${sort}`;

        return this.fetch( endpoint, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if( res.success === true ){
                return Promise.resolve(res.data.Financials);
            }

            return Promise.resolve(res);
        });
    }

    listOutgoing( start, end, pago, centro_custo, lawsuit_token, forma_pagamento = null ){
      let endpoint = ""; let sort = '&sort=data_vencimento&direction=ASC';
      start = start.substr(0, 10).split('/').reverse().join('-');
      end = end.substr(0, 10).split('/').reverse().join('-');

      /*console.log(pago);*/

      if(pago === "3"){
        pago = "";
        endpoint = `${this.domain}Financial/?financial_type_id=2${pago}&start=${start}&end=${end+sort}`;
      }else{
        pago = "&pago="+pago;
        endpoint = `${this.domain}Financial/?financial_type_id=2${pago}&start=${start}&end=${end}&pago=${pago+sort}`;
      }

      if(centro_custo !== null){
        centro_custo = "&financial_center_cost_token="+centro_custo;
        endpoint = `${this.domain}Financial/?financial_type_id=2${pago}&start=${start}&end=${end}${centro_custo}&sort=data_vencimento&direction=ASC`;
      }else{
        centro_custo = "";
      }

      if(lawsuit_token !== null){
        lawsuit_token = "&lawsuit_token="+lawsuit_token;
        endpoint = `${this.domain}Financial/?financial_type_id=2${pago}&start=${start}&end=${end}${centro_custo}${lawsuit_token}&sort=data_vencimento&direction=ASC`;
      }else{
        lawsuit_token = "";
      }

      if(forma_pagamento !== null){
        forma_pagamento = "&financial_payment_option_token="+forma_pagamento;
        endpoint = `${this.domain}Financial/?financial_type_id=2${pago}&start=${start}&end=${end}${centro_custo}${lawsuit_token}${forma_pagamento}&sort=data_vencimento&direction=ASC`;
      }

      return this.fetch( endpoint, {
          method: 'GET',
      }).then(res => {
            //console.log(res);
            if( res.success === true ){
                return Promise.resolve(res.data.Financials);
            }

            return Promise.resolve(res);
        })
    }

    centroCustoList(  ){

        return this.fetch(`${this.domain}FinancialCenterCosts/index/`, {
            method: 'GET',
        }).then(res => {
            let response = [];

            if( res.success === true ){

              res.data.InputCenterCosts.map((value, index, array )=>{
                response[index] = { "key": value.token, "value": value.token, "label": value.name };
                return response;
              });

            }

            //console.log(response);
            return Promise.resolve(response);
        });
    }

    deleteCentroCusto ( token ){

        return this.fetch(`${this.domain}FinancialCenterCosts/delete/${token}`, {
            method: 'DELETE'
        }).then(res => {
            return Promise.resolve(res);
        })
    }

    addCentroCusto ( finance ){

          return this.fetch(`${this.domain}FinancialCenterCosts/add`, {
              method: 'POST',
              body: JSON.stringify(finance)
          }).then(res => {
              //console.log(res);
              return Promise.resolve(res);
          })
    }

    categoryList(  ){

      let financialCategoriesList = JSON.parse(localStorage.getItem('financialCategoriesList'));
      if( 
        ( financialCategoriesList == null || 
          financialCategoriesList == 0 || 
          financialCategoriesList == "undefined" ||
          typeof financialCategoriesList.date == "undefined" 
        ) || 
          moment().diff(financialCategoriesList.date, 'minutes',true ) > 120 
      ){

            return this.fetch(`${this.domain}FinancialCategories/index/`, {
                method: 'GET',
            }).then(res => {
              let response = [];

              if( res.success === true ){

                var i = 0;
                res.data.InputCategories.map((value, index, array )=>{
                    response[i] = { "key": value.token, "value": value.token, "label": value.name };
                    i++;
                  return response;
                });



                localStorage.setItem('financialCategoriesList', JSON.stringify({ categories: res.data, date: moment() } ) );

              }
              //console.log(response);
              return Promise.resolve(response);
            });

        }else{
          let response = [];
          var i = 0;
          financialCategoriesList.categories.InputCategories.map((value, index, array )=>{
              response[i] = { "key": value.token, "value": value.token, "label": value.name };
              i++;
            return response;
          });
          return Promise.resolve(response);
        }

    }

    categoryIncomingList(  ){

      let financialCategoriesList = JSON.parse(localStorage.getItem('financialCategoriesList'));
      if( 
        ( financialCategoriesList == null || 
          financialCategoriesList == 0 || 
          financialCategoriesList == "undefined" ||
          typeof financialCategoriesList.date == "undefined" 
        ) || 
          moment().diff(financialCategoriesList.date, 'minutes',true ) > 120 
      ){

        return this.fetch(`${this.domain}FinancialCategories/index/`, {
            method: 'GET',
        }).then(res => {
            let response = [];

            if( res.success === true ){

              var i = 0;
              res.data.InputCategories.map((value, index, array )=>{
                if(value.financial_type.token === localStorage.getItem('credito_token') ){
                  response[i] = { "key": value.token, "value": value.token, "label": value.name };
                  i++;
                }
                return response;
              });

              localStorage.setItem('financialCategoriesList', JSON.stringify({ categories: res.data, date: moment() } ) );

            }

            //console.log(response);
            return Promise.resolve(response);
        });

      }else{
        let response = [];
        var i = 0;
        financialCategoriesList.categories.InputCategories.map((value, index, array )=>{
          if(value.financial_type.token === localStorage.getItem('credito_token') ){
            response[i] = { "key": value.token, "value": value.token, "label": value.name };
            i++;
          }
          return response;
        });
        return Promise.resolve(response);
      }

    }

    deleteCategoryIncoming ( token ){

      return this.fetch(`${this.domain}FinancialCategories/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          window.localStorage.removeItem('financialCategoriesList');
          return Promise.resolve(res);
      })

    }

    addCategoryIncoming ( finance ){

          finance.financial_type_id = 1;

          return this.fetch(`${this.domain}FinancialCategories/add`, {
              method: 'POST',
              body: JSON.stringify(finance)
          }).then(res => {
              //console.log(res);
              window.localStorage.removeItem('financialCategoriesList');
              return Promise.resolve(res);
          })
    }

    categoryOutgoingList(  ){

      let financialCategoriesList = JSON.parse(localStorage.getItem('financialCategoriesOutgoingList'));
      if( 
        ( financialCategoriesList == null || 
          financialCategoriesList == 0 || 
          financialCategoriesList == "undefined" ||
          typeof financialCategoriesList.date == "undefined" 
        ) || 
          moment().diff(financialCategoriesList.date, 'minutes',true ) > 120 
      ){

            return this.fetch(`${this.domain}FinancialCategories/index/`, {
                method: 'GET',
            }).then(res => {
                let response = [];

                if( res.success === true ){

                  var i = 0;
                  res.data.InputCategories.map((value, index, array )=>{

                    if(value.financial_type.token === localStorage.getItem('debito_token') ){
                      response[i] = { "key": value.token, "value": value.token, "label": value.name };
                      i++;
                    }
                    return response;
                  });

                  localStorage.setItem('financialCategoriesOutgoingList', JSON.stringify({ categories: res.data, date: moment() } ) );

                }

                //console.log(response);
                return Promise.resolve(response);
            });


        }else{
          let response = [];
          var i = 0;
          financialCategoriesList.categories.InputCategories.map((value, index, array )=>{

            if(value.financial_type.token === localStorage.getItem('debito_token') ){
              response[i] = { "key": value.token, "value": value.token, "label": value.name };
              i++;
            }
            return response;
          });
          return Promise.resolve(response);
        }
    }

    deleteCategoryOutgoing ( token ){

      return this.fetch(`${this.domain}FinancialCategories/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          window.localStorage.removeItem('financialCategoriesOutgoingList');
          return Promise.resolve(res);
      })
    }

    addCategoryOutgoing ( finance ){
          finance.financial_type_id = 2;

          return this.fetch(`${this.domain}FinancialCategories/add`, {
              method: 'POST',
              body: JSON.stringify(finance)
          }).then(res => {
              //console.log(res);
              window.localStorage.removeItem('financialCategoriesOutgoingList');
              return Promise.resolve(res);
          });
    }

    categoryEntryLawsuitList(  ){

      let financialCategoriesList = JSON.parse(localStorage.getItem('financialCategoriesEntryList'));
      if( 
        ( financialCategoriesList == null || 
          financialCategoriesList == 0 || 
          financialCategoriesList == "undefined" ||
          typeof financialCategoriesList.date == "undefined" 
        ) || 
          moment().diff(financialCategoriesList.date, 'minutes',true ) > 120 
      ){

          return this.fetch(`${this.domain}FinancialCategories/index/`, {
              method: 'GET',
          }).then(res => {
              let response = [];

              if( res.success === true ){

                localStorage.setItem('financialCategoriesList', JSON.stringify({ categories: res.data, date: moment() } ) );

                res.data.InputCategories.map((value, index, array )=>{
                  if( value.financial_type.token === localStorage.getItem('credito_token') ){
                    if(value.name === "Honorários" || value.name === "Valor de Consulta" || value.name === "Cálculos do Processo"){

                        response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 2 } );

                    }else if(value.name === "Valor Pago/Abatimento"){
                      response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 1 } );
                    }else if( moment(value.created.substr(0,10), "YYYY-MM-DD").valueOf() >= moment("2020-01-13", "YYYY-MM-DD").valueOf() ){
                      response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 1 } );
                    }

                  }else{

                    if(value.name === "Custas" || value.name === "Gastos com deslocamento"){
                        response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 2 } );
                    }else if( moment(value.created.substr(0,10), "YYYY-MM-DD").valueOf() >= moment("2020-01-13", "YYYY-MM-DD").valueOf() ){
                      response.push(  { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 2 } );
                    }

                  }
                  return response;
                });

              }

              //console.log(response);
              return Promise.resolve(response);
          });
        }else{
          let response = [];
          var i = 0;
          financialCategoriesList.categories.InputCategories.map((value, index, array )=>{
            if( value.financial_type.token === localStorage.getItem('credito_token') ){
              if(value.name === "Honorários" || value.name === "Valor de Consulta" || value.name === "Cálculos do Processo"){

                  response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 2 } );

              }else if(value.name === "Valor Pago/Abatimento"){
                response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 1 } );
              }else if( moment(value.created.substr(0,10), "YYYY-MM-DD").valueOf() >= moment("2020-01-13", "YYYY-MM-DD").valueOf() ){
                response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 1 } );
              }

            }else{

              if(value.name === "Custas" || value.name === "Gastos com deslocamento"){
                  response.push( { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 2 } );
              }else if( moment(value.created.substr(0,10), "YYYY-MM-DD").valueOf() >= moment("2020-01-13", "YYYY-MM-DD").valueOf() ){
                response.push(  { "key": value.token, "value": value.token, "label": value.name, "financial_type_id": 2 } );
              }

            }
            return response;
          });
          return Promise.resolve(response);
        }

    }

    deleteCategoryEntryLawsuit ( token ){
      return this.fetch(`${this.domain}FinancialCategories/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {

          window.localStorage.removeItem('financialCategoriesEntryList');
          return Promise.resolve(res);
      })
    }

    addCategoryEntryLawsuit ( finance ){
      return this.fetch(`${this.domain}FinancialCategories/add`, {
          method: 'POST',
          body: JSON.stringify(finance)
      }).then(res => {
          //console.log(res);
          window.localStorage.removeItem('financialCategoriesEntryList');
          return Promise.resolve(res);
      })
    }

    addEntry ( finance ){

      let incoming = localStorage.getItem('entries');
      if(incoming === ""){
        incoming = "[]";
      }

      incoming = JSON.parse(incoming);

      let token = this.generateUUID();
      finance.token = token;

      if(incoming !== null){
        incoming.push(finance);
      }else{
        incoming = [finance];
      }

      localStorage.setItem('entries', JSON.stringify(incoming) );

      let res = {
        success: true
      }
      return Promise.resolve(res);
    }

    deleteEntry ( token ){

      let incoming = JSON.parse(localStorage.getItem('entries') );
      for (var i = 0; i < incoming.length; i++) {
            var obj = incoming[i];

            if (obj.token === token) {
                incoming.splice(i, 1);
            }
        }
      localStorage.setItem('entries', JSON.stringify(incoming) );

      let res = {
        success: true,
        message: "Fatura excluída com sucesso"
      }

      return Promise.resolve(res);
    }

    listEntries(  ){
        let incoming = "[]";

        return Promise.resolve(JSON.parse(incoming));
    }

    viewEntry( token ){
      let outgoing = localStorage.getItem('entries'); var finance = null;
      if(outgoing === ""){
        outgoing = "[]";
      }

      outgoing = JSON.parse(outgoing);

      for (var i = 0; i < outgoing.length; i++) {
            var obj = outgoing[i];

            if (obj.token.toString() === token) {
                finance = obj;
            }
        }

        return Promise.resolve(finance);

    }

    editEntry ( finance ){

      let outgoing = JSON.parse( localStorage.getItem('entries') );
      for (var i = 0; i < outgoing.length; i++) {
            var obj = outgoing[i];

            if (obj.token === finance.token) {
                outgoing.splice(i, 1);
            }
        }

        if(outgoing !== null){
          outgoing.push(finance);
        }else{
          outgoing = [finance];
        }

      localStorage.setItem('entries', JSON.stringify(outgoing) );

      let res = {
        success: true,
        message: "Lançamento editado com sucesso"
      }

      return Promise.resolve(res);
    }

    report( cliente, lawsuit, categoria, type, status, centroCusto, excluirRepassesFuturos, excluirRecebimentoFuturos ){
      let tipo = '';

      if ( type === "1" ) {
        tipo = "";
      }else if( type === "2" ){
        tipo = ""//'&lawsuit_positive=1';
      }else if( type === "3" ){
        tipo = ""//'&lawsuit_negative=1';
      }else if( type === "4" ){
        //tipo = '&previsao_de_recebimento=1';
        tipo = "&start="+moment().format("YYYY-MM-DD")+"&sort=data_vencimento&direction=ASC";
      }else if( type === "5" ){
        //tipo = '&previsao_de_pagamento=1';
        tipo = "&start="+moment().format("YYYY-MM-DD")+"&sort=data_vencimento&direction=ASC";
      }

      if( cliente === "0" ){
        cliente = "";
      }else{
        cliente = "&personage_token="+cliente;
      }

      if( lawsuit === "0" ){
        lawsuit = "";
      }else{
        lawsuit = "&lawsuit_token="+lawsuit;
      }

      if( categoria === "0" ){
        categoria = "";
      }else{
        categoria = "&financial_category_token="+categoria;
      }

      if(centroCusto === "0"){
        centroCusto = "";
      }else{
        centroCusto = "&financial_center_cost_token="+centroCusto;
      }

      return this.fetch( `${this.domain}Financial/index/?only_client=1${tipo}${cliente}${lawsuit}${categoria}${centroCusto}`, {
          method: 'GET',
      }).then(res => {
            let response = [];
            //console.log(res);
            /*if( res.success === true && ( type === "2" || type === "3" ) ){

              for (var i in res.data.Financials) {
                let finance = {};
                  finance.saldo = res.data.Financials[i].saldo;
                  let entries = [];

                  for (var j in res.data.Financials[i]) {
                    if( j !== "saldo" ){
                      finance.number = ( res.data.Financials[i][j].lawsuit.number !== null ) ? res.data.Financials[i][j].lawsuit.number : res.data.Financials[i][j].lawsuit.name;
                      finance.cliente = res.data.Financials[i][j].lawsuit.lawsuits_personages[0].personage.name;
                      entries.push( res.data.Financials[i][j] );
                    }
                  }

                  finance.entries = entries;

                  response.push( finance );
                  response.push( finance );
              }
              //console.log(response);
                return Promise.resolve(response);
            }else */
            if( res.success === true  ){ //console.log(res.data);

              let tmp_number = []; let retorno = []; let entries_token = [];

              for (var i in res.data.Financials) {

                let finance = {}; let entries = [];

                  if( res.data.Financials[i].lawsuit !== null && res.data.Financials[i].belongs_to_lawsuit === 1 ){
                    //console.log(type);

                      if( (type === "2" || type === "1" || type === "3") && moment( res.data.Financials[i].data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() <= moment().valueOf() ){

                          if( res.data.Financials[i].lawsuit.number !== null){
                            finance.number = res.data.Financials[i].lawsuit.number
                          }else{
                            finance.number = res.data.Financials[i].lawsuit.name
                          }

                          /*Trata o lançamento para ser apresentado*/
                          finance.cliente = ( res.data.Financials[i].lawsuit !== null && typeof res.data.Financials[i].lawsuit.lawsuits_personages !== "undefined" ) ? res.data.Financials[i].lawsuit.lawsuits_personages[0].personage.name : "Sem cliente definido";
                          finance.saldo = res.data.Financials[i].valor;

                          if( !tmp_number.includes(finance.number) ){
                                tmp_number.push(finance.number);

                                if( !entries_token.includes(res.data.Financials[i].token) ){
                                  entries_token.push( res.data.Financials[i].token );
                                  entries.push(res.data.Financials[i]);
                                  finance.entries = entries;
                                  /*console.log(entries_token);
                                 //console.log(res.data.Financials[i].token);
                                 //console.log(entries);*/
                                }

                                response.push(finance);
                          }else{
                            for(let j in response){
                              if( response[j].number === finance.number ){
                                response[j].saldo += res.data.Financials[i].valor;

                                if( !entries_token.includes(res.data.Financials[i].token) ){
                                  entries_token.push( res.data.Financials[i].token );
                                  response[j].entries.push(res.data.Financials[i]);
                                }

                              }
                            }
                          }

                       }else if( (type === "4") && res.data.Financials[i].financial_type_id === 2 ) {

                        //console.log(res.data.Financials[i].financial_category);

                            if( excluirRecebimentoFuturos === "true" && ( res.data.Financials[i].financial_category !== null && res.data.Financials[i].financial_category.name === "Recebimento de Acordo") ){
                              //não mostramos recebimento de acordo
                            } else{
                                      res.data.Financials[i].financial_type_id = 1;

                                      if( res.data.Financials[i].lawsuit.number !== null){
                                        finance.number = res.data.Financials[i].lawsuit.number
                                      }else{
                                        finance.number = res.data.Financials[i].lawsuit.name
                                      }
            
                                      /*Trata o lançamento para ser apresentado*/
                                      finance.cliente = ( res.data.Financials[i].lawsuit !== null && typeof res.data.Financials[i].lawsuit.lawsuits_personages !== "undefined" ) ? res.data.Financials[i].lawsuit.lawsuits_personages[0].personage.name : "Sem cliente definido";
                                      finance.saldo = res.data.Financials[i].valor;
            
                                      if( !tmp_number.includes(finance.number) ){
                                        tmp_number.push(finance.number);
            
                                        if( !entries_token.includes(res.data.Financials[i].token) ){
                                          entries_token.push( res.data.Financials[i].token );
                                          entries.push(res.data.Financials[i]);
                                          finance.entries = entries;
                                          /*console.log(entries_token);
                                         //console.log(res.data.Financials[i].token);
                                         //console.log(entries);*/
                                        }
            
                                        response.push(finance);
                                      }else{
                                        for(let j in response){
                                          if( response[j].number === finance.number ){
                                            response[j].saldo += res.data.Financials[i].valor;
            
                                            if( !entries_token.includes(res.data.Financials[i].token) ){
                                              entries_token.push( res.data.Financials[i].token );
                                              response[j].entries.push(res.data.Financials[i]);
                                            }
            
                                          }
                                        }
                                      }
                            }

                       }else if( (type === "5") && res.data.Financials[i].financial_type_id === 1 ) {

                        //console.log(res.data.Financials[i].financial_category.name);

                              if( excluirRepassesFuturos === "true" && ( res.data.Financials[i].financial_category !== null && ( res.data.Financials[i].financial_category.name === "Repasse de Acordo" || res.data.Financials[i].financial_category.name === "Recebimento de Acordo" )  ) ){
                                //não mostramos repasses de acordo
                              } else{

                                      res.data.Financials[i].financial_type_id = 2;

                                      if( res.data.Financials[i].lawsuit.number !== null){
                                        finance.number = res.data.Financials[i].lawsuit.number
                                      }else{
                                        finance.number = res.data.Financials[i].lawsuit.name
                                      }
            
                                      /*Trata o lançamento para ser apresentado*/
                                      finance.cliente = ( res.data.Financials[i].lawsuit !== null && typeof res.data.Financials[i].lawsuit.lawsuits_personages !== "undefined" ) ? res.data.Financials[i].lawsuit.lawsuits_personages[0].personage.name : "Sem cliente definido";
                                      finance.saldo = res.data.Financials[i].valor;
            
                                      if( res.data.Financials[i].financial_category.name === "Recebimento de Acordo" ){
                                        res.data.Financials[i].financial_category.name =  "Repasse de Acordo";
                                      }
            
                                      if( !tmp_number.includes(finance.number) ){
                                        tmp_number.push(finance.number);
            
                                        if( !entries_token.includes(res.data.Financials[i].token) ){
                                          entries_token.push( res.data.Financials[i].token );
                                          entries.push(res.data.Financials[i]);
                                          finance.entries = entries;
                                          /*console.log(entries_token);
                                         //console.log(res.data.Financials[i].token);
                                         //console.log(entries);*/
                                        }
            
                                        response.push(finance);
                                      }else{
                                        for(let j in response){
                                          if( response[j].number === finance.number ){
                                            response[j].saldo += res.data.Financials[i].valor;
            
                                            if( !entries_token.includes(res.data.Financials[i].token) ){
                                              entries_token.push( res.data.Financials[i].token );
                                              response[j].entries.push(res.data.Financials[i]);
                                            }
            
                                          }
                                        }
                                      }

                              }

                       }



                  }else if( res.data.Financials[i].lawsuit !== null &&  (type === "4") && res.data.Financials[i].financial_type_id === 1 ){

                            //console.log(res.data.Financials[i].financial_category);

                            if( excluirRecebimentoFuturos === "true" && ( res.data.Financials[i].financial_category !== null && ( res.data.Financials[i].financial_category.name === "Repasse de Acordo" || res.data.Financials[i].financial_category.name === "Recebimento de Acordo" ) ) ){
                              //não mostramos recebimento de acordo
                            } else{

                                      if( res.data.Financials[i].lawsuit.number !== null){
                                        finance.number = res.data.Financials[i].lawsuit.number
                                      }else{
                                        finance.number = res.data.Financials[i].lawsuit.name
                                      }

                                      /*Trata o lançamento para ser apresentado*/
                                      finance.cliente = ( res.data.Financials[i].lawsuit !== null && typeof res.data.Financials[i].lawsuit.lawsuits_personages !== "undefined" ) ? res.data.Financials[i].lawsuit.lawsuits_personages[0].personage.name : "Sem cliente definido";
                                      finance.saldo = res.data.Financials[i].valor;

                                      if( !tmp_number.includes(finance.number) ){
                                        tmp_number.push(finance.number);

                                        if( !entries_token.includes(res.data.Financials[i].token) ){
                                          entries_token.push( res.data.Financials[i].token );
                                          entries.push(res.data.Financials[i]);
                                          finance.entries = entries;
                                          /*console.log(entries_token);
                                         //console.log(res.data.Financials[i].token);
                                         //console.log(entries);*/
                                        }

                                        response.push(finance);
                                      }else{
                                        for(let j in response){
                                          if( response[j].number === finance.number ){
                                            response[j].saldo += res.data.Financials[i].valor;

                                            if( !entries_token.includes(res.data.Financials[i].token) ){
                                              entries_token.push( res.data.Financials[i].token );
                                              response[j].entries.push(res.data.Financials[i]);
                                            }

                                          }
                                        }
                                      }

                            }

                  }else if( res.data.Financials[i].lawsuit !== null &&  (type === "5") && res.data.Financials[i].financial_type_id === 2 ){

                            if( excluirRepassesFuturos === "true" && (res.data.Financials[i].financial_category !== null && res.data.Financials[i].financial_category.name === "Repasse de Acordo") ){
                            } else {

                                    if( res.data.Financials[i].lawsuit.number !== null){
                                      finance.number = res.data.Financials[i].lawsuit.number
                                    }else{
                                      finance.number = res.data.Financials[i].lawsuit.name
                                    }
        
                                    /*Trata o lançamento para ser apresentado*/
                                    finance.cliente = ( res.data.Financials[i].lawsuit !== null && typeof res.data.Financials[i].lawsuit.lawsuits_personages !== "undefined" ) ? res.data.Financials[i].lawsuit.lawsuits_personages[0].personage.name : "Sem cliente definido";
                                    finance.saldo = res.data.Financials[i].valor;
        
                                    if( !tmp_number.includes(finance.number) ){
                                      tmp_number.push(finance.number);
        
                                      if( !entries_token.includes(res.data.Financials[i].token) ){
                                        entries_token.push( res.data.Financials[i].token );
                                        entries.push(res.data.Financials[i]);
                                        finance.entries = entries;
                                        /*console.log(entries_token);
                                       //console.log(res.data.Financials[i].token);
                                       //console.log(entries);*/
                                      }
        
                                      response.push(finance);
                                    }else{
                                      for(let j in response){
                                        if( response[j].number === finance.number ){
                                          response[j].saldo += res.data.Financials[i].valor;
        
                                          if( !entries_token.includes(res.data.Financials[i].token) ){
                                            entries_token.push( res.data.Financials[i].token );
                                            response[j].entries.push(res.data.Financials[i]);
                                          }
        
                                        }
                                      }
                                    }

                            }

                  }



              }

              for(let j in response){
                response[j].entries.sort(function(a, b){

                  if ( moment(a.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() < moment(b.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() ){
                    return -1;
                  }else{
                    return 1;
                  }

                });
              }

              for(let j in response){
                retorno.push(response[j]);
                retorno.push(response[j]);
              }

              //console.log(retorno);

              return Promise.resolve(retorno);
            }

            return Promise.resolve(response);
        })
    }

    generateUUID() { // Public Domain/MIT
          var d = new Date().getTime();//Timestamp
          var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              var r = Math.random() * 16;//random number between 0 and 16
              if(d > 0){//Use timestamp until depleted
                  r = (d + r)%16 | 0;
                  d = Math.floor(d/16);
              } else {//Use microseconds since page-load if supported
                  r = (d2 + r)%16 | 0;
                  d2 = Math.floor(d2/16);
              }
              return (c === 'x' ? r : (r & (0x3 | 0x8) )).toString(16);
          });
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
            return Promise.resolve({
              message: "Você não tem permissão para acessar o sistema neste momento."
            });
          }else{
    
              return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                            if(typeof response !== "undefined"){

                              return Promise.resolve( response.text().then(function(result){
                                  //console.log(result);
                                  let old_result = result;
                                  result = result.split('{"success"');
                                  if( result.length > 1 ){
                                      result = result[1].split('<pre');
                                      //console.log(result[0]);
                                      let json = '{"success"'+result[0];
                                      result = JSON.parse(json);
                                      //console.log(result);
                                      return result;
                                  }else{
                                      return old_result;
                                  }

                              }) );

                          }else{

                              let result = {
                                  success: false,
                                  message: "Erro desconhecido"
                              }

                              return Promise.resolve( result );

                          }

                  });

          }
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
         //console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
          window.location.href = '/config/mudar-plano?plano_vencido';
  
        }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
