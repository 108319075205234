import React from 'react';
import { Switch } from 'antd';
import Select from 'react-select';

import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from "./containers/footer";
import Alert from './containers/alert';
import Confirm from './containers/confirm';
import 'antd/dist/antd.css';
import FlashMessage from "./containers/FlashMessage";
import MyPopover from './containers/mypopover';

import Loading from './containers/loading';


import user_icon from './json/user_icon';

import UsersService from '../services/UsersService';
import AuthService from '../services/AuthService';
import TribunalService from '../services/TribunalService';
import { post } from 'axios';
import {api} from '../config';


export default class Perfil extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(...args) {
    super(...args)
    this.usersService = new UsersService();

    this.handleChange = this.handleChange.bind(this);
    this.addUser = this.addUser.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.cadastraSegredo = this.cadastraSegredo.bind(this);

    window.scrollTo(0,0);

    let tribunals = JSON.parse(localStorage.getItem('tribunals'));
      let tribunalOptions = [];

      /*tribunals.map( tribunal => {
          tribunalOptions.push( {label: tribunal.name+" - "+tribunal.slug, value: tribunal.id } );
      });*/

      tribunalOptions.push( {label: "Tribunal de Justiça de São Paulo - TJSP", value: 1 } );


    this.state = {
        loadingVisible: false,
        loadingMessage: [],
        token: "",
        errors: [],
        nome: "",
        admin: false,
        email: "",
        cargo: "",
        senha: "",
        senha2: "",
        cpf: "",
        senhaSegredo: "",
        image_path: user_icon,
        message: "",
        showAlert: false,
        segredoToken: "",
        segredos: [],
        tribunal: [],
        tribunalList: tribunalOptions
    }
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });
        this.deleteSegredo( this.state.segredoToken, true );
      }
    });

    document.addEventListener("cancelAlert", (e) => {
        this.setState({
        showAlert: false
        });
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

    this.usersService.view( user.token ).then(res =>{

          if (this._isMounted) {

               //console.log(res);

                if(res.imagem !== null){


                  this.setState({
                    image_path: res.imagem
                  });
                }

                this.setState({
                  nome: res.name,
                  email: res.email,
                  old_email: res.email,
                  token: res.token,
                  admin: res.admin
                });

          }

    });

    this.getSegredos();

  }

  componentDidUpdate(){

  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   validateForm() {
       let errors = {};
       let formIsValid = true;

       if ( this.state.nome === 0 || this.state.nome === "" || typeof this.state.nome === "undefined" ) {
         formIsValid = false;
         errors["nome"] = "*Campo obrigatório";
       }

       let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       if( !re.test(String( this.state.email ).toLowerCase()) ){
         formIsValid = false;
         errors["email"] = "*Digite um email válido";
       }

       if ( this.state.email === 0 || this.state.email === "" || typeof this.state.email === "undefined" ) {
         formIsValid = false;
         errors["email"] = "*Campo obrigatório";
       }

       /*if ( !this.state.senha.match(/[a-z]+/) || !this.state.senha.match(/[A-Z]+/) || !this.state.senha.match(/[0-9]+/) ) {

         formIsValid = false;
         errors["senha"] = "*A senha deve conter minúsculas, maiúsculas e números";
       }*/

       if ( this.state.senha2 !== this.state.senha ) {
         formIsValid = false;
         errors["senha2"] = "* Os campos não coincidem";
       }

       this.setState({
          errors: errors
        });

       return formIsValid;
  }

   handleOk = () => {
       if (this.validateForm() ) {

               this.addUser();

               this.setState({
                 loadingVisible: true,
                 loading: true,
                 loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
               });

       }

   };

   onChange(e) {
     //console.log(e.target.files);
     if (e.target.files.length > 1) {
         //alert("Você pode selecionar somente um arquivo");
         this.setState({
          showAlert: true,
          message: "Você pode selecionar somente um arquivo"
        });

     } else {
         this.setState({file:e.target.files});
     }

     var maxSize = e.target.dataset.max_size;

     for( let i = 0; i< e.target.files.length; i++){
         if(e.target.files.length){
               var fileSize = e.target.files[0].size; // in bytes
               if(fileSize>maxSize){
                   //alert('o arquivo '+e.target.files[0].name + ' é maior que 15 MegaBytes');
                   this.setState({
                    showAlert: true,
                    message: 'o arquivo '+e.target.files[0].name + ' é maior que 15 MegaBytes'
                  });
               }else{
               }
           }
     }

     var reader = new FileReader();

     reader.onload = (e) => {
       this.setState({
         logo: e.target.result.split(',')[1]
       });
     }

     reader.readAsDataURL(e.target.files[0]);

   }

   selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;

      if ( typeof selected !== undefined && selected !== "" ) {

        this.setState( pops );

      }
  }

   addUser = () => {
         this.UsersService = new UsersService();

         const formData = new FormData();

         let file = this.state.file;
         let url = api.url+'Users/edit/'+this.state.token;

         //console.log(file);
         let names = [];

         if(typeof file !== "undefined" ){
           for(let i=0; i < file.length; i++){
             //files.push(file[i]);
             formData.append(i,file[i], file[i].name);
             names.push(this.state.title);
           }

           formData.append('file',file);
           formData.append('names',JSON.stringify(names));

           let auth = new AuthService();
           const config = {
               headers: {
                   'content-type': 'multipart/form-data',
                   'Authorization': 'Bearer ' + auth.getToken(),
               }
           }
           post(url, formData, config);
         }

         let user = {
           password: this.state.senha,
           name: this.state.nome,
         }

         if( this.state.email !== this.state.old_email ){
           user.email = this.state.email;
         }

         this.UsersService.add( user, this.state.token ).then(res =>{

              if (this._isMounted) {

                    if ( res.success === true ) {

                      let user_token = res.data.Users.token;

                      localStorage.setItem('alert_message', "Dados de perfil alterados com sucesso");
                      localStorage.setItem('alert_type', 'alert-success');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                      this.props.history.push('/');
                    }else {
                      localStorage.setItem('alert_message', res.message);
                      localStorage.setItem('alert_type', 'alert-danger');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                    }

                    this.setState({
                      loadingVisible: false,
                      loadingMessage: [],
                      loading: false
                    });

              }

         })
         .catch(err =>{
            //console.log(err);
             this.setState({
               loadingVisible: false,
               loadingMessage: []
             });
         });
   }

   onSwitchSegredoJusticaChange = (checked) => {
      if ( checked ){

        document.getElementById('segredo-justica').classList.remove("hide");
        this.setState({
          switchSegredoJustica: true,
        });
      }else{
        document.getElementById("segredo-justica").className += ' hide';
        this.setState({
          switchSegredoJustica: false,
        });
      }
   }

   cadastraSegredo(){
    this.TribunalService = new TribunalService();

    if( this.state.tribunal === null ){
      alert("Selecione um tribunal para o segredo de justiça");
    }else if(this.state.cpf.length < 2){
      alert("O Campo CPF do segredo de justiça é obrigatório");
    }else if(this.state.senhaSegredo < 3){
      alert("preencha o campo senha do segredo de justiça. Campo Obrigatório");
    }else{

      let data = {
        tribunal_id: this.state.tribunal.value,
        login: this.state.cpf,
        password: this.state.senhaSegredo
      }
  
      this.TribunalService.addSegredo( data ).then( (response) => {

          let time = new Date().getTime();

          if ( response.success === true ) {
            localStorage.setItem('alert_message', "Acesso para segredo de justiça cadastrado com sucesso");
            localStorage.setItem('alert_type', 'alert-success');
          }else {
            localStorage.setItem('alert_message', response.message);
            localStorage.setItem('alert_type', 'alert-danger');
          }

          localStorage.setItem('alert_time', time );
          this.getSegredos();
          this.setState({
              cpf: "",
              senhaSegredo: "",
              tribunal: null
          });
      });

    }

  }

  deleteSegredo( token, r = false ){

    if( r ){
      this.TribunalService.deleteSegredo( token ).then( (response) => {
        this.getSegredos();
      });
    }else{
      this.setState({
        showConfirm: true,
        message: "Deseja realmente excluir o segredo de justiça?",
        segredoToken: token
      });
    }

  }

  getSegredos(){
      this.TribunalService = new TribunalService();

      this.TribunalService.getSegredos( ).then( (segredos) => {
        //console.log(segredos);
        this.onSwitchSegredoJusticaChange( (segredos.length > 0) ? true : false );
          this.setState({
            segredos,
            switchSegredoJustica: (segredos.length > 0) ? true : false
          });
      });
  }

   segredoItem = ( {record, index}, type ) => {

      return (
        <tr key={record.token} className={type}>
              <td className="table_lawsuit_number">
                {this.state.tribunalList.find( trib => trib.value === record.tribunal_id ).label}
              </td>
              <td>
                {record.login}
              </td>
              <td className=""  >
                      { '******' }
              </td>
              <td className="table_lawsuit_actions">
                    <i className="fa fa-trash" data-token={record.token} onClick={() => this.deleteSegredo(record.token)}></i>
              </td>
        </tr>


      );
  };

  render() {
    /*<i className="fa fa-plus"></i>*/

      return <div className="sheet perfil">
                <FlashMessage time={ new Date().getTime() } />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true} />
                <HeaderBar />
                <NavigationMenu />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Perfil</h1>
                        </section>
                        <div className="box-body col-md-9">

                              <div className="col-md-6">
                                  <div className="form-group col-md-12">
                                      <label htmlFor="nome">
                                          Nome<span className="text-red">*</span>
                                          <MyPopover icon="white" text="Se desejar, altere para o seu primeiro nome, para que apareça nos registros, comentários, cadastros e outros. Recomendamos usar somente o 1º nome, para simplificar nos registros." />
                                      </label>
                                      <input
                                          type="text"
                                          className="nome input-lg form-control"
                                          value={this.state.nome}
                                          name="nome"
                                          onChange={this.handleChange}
                                        />
                                      <div className="errorMsg">{this.state.errors.nome}</div>
                                  </div>
                                  <div className="form-group col-md-12">
                                      <label htmlFor="email">E-mail<span className="text-red">*</span></label>
                                      <input
                                          type="email"
                                          className="email input-lg form-control"
                                          value={this.state.email}
                                          disabled={ this.state.admin}
                                          name="email"
                                          autoComplete="new-password"
                                          onChange={this.handleChange}
                                        />
                                        <div className="errorMsg">{this.state.errors.email}</div>
                                  </div>
                                  <div className="form-group col-md-6">
                                      <label htmlFor="senha">Senha<span className="text-red">*</span></label>
                                      <input
                                          type="password"
                                          className="senha input-lg form-control"
                                          value={this.state.senha}
                                          name="senha"
                                          autoComplete="new-password"
                                          onChange={this.handleChange}
                                        />
                                        <div className="errorMsg">{this.state.errors.senha}</div>
                                  </div>
                                  <div className="form-group col-md-6">
                                      <label htmlFor="senha2">Confirme a Senha</label>
                                      <input
                                          type="password"
                                          className="senha2 input-lg form-control"
                                          value={this.state.senha2}
                                          name="senha2"
                                          autoComplete="new-password"
                                          onChange={this.handleChange}
                                        />
                                        <div className="errorMsg">{this.state.errors.senha2}</div>
                                  </div>
                            </div>
                            <div className="form-group perfil-form col-md-4">
                                <label htmlFor="lawsuit-number">Adicionar Imagem de Perfil<span className="text-red">*</span></label>
                                <input type="file" id="file_ged" accept="image/png, image/jpeg" name="file" data-max_size="15728640" onChange={this.onChange} />
                                    <div className="dimensoes-imagem">Dimensões recomendadas para a imagem: 90x90.</div>
                                <img id="image_perfil" width="90" height="90" className="imagem_salva" src={"data:image/png;charset=utf-8;base64, "+this.state.image_path} alt="imagem salva" />
                            </div>
                                  <button id="handleOk" type="button" onClick={this.handleOk} className="ant-btn ant-btn-primary right"><span>Atualizar Dados</span></button>
                        </div>
                        <div className="col-md-12">
                              <label className="switchSegredoJustica" htmlFor="segredoJustica">
                                    Segredo de Justiça
                                    <MyPopover icon="white" text="Se este usuário tiver seu nome em processos em Segredo de Justiça, basta cadastrar o CPF e a Senha de acesso no tribunal. Não se trata da senha do token do advogado, mas sim, da senha cadastrada no tribunal especifico para acessar estes processos sem o token." />
                                    <Switch defaultChecked={this.state.switchSegredoJustica} checked={this.state.switchSegredoJustica} onChange={this.onSwitchSegredoJusticaChange} />
                              </label>
                        </div>
                        <div id="segredo-justica" className="panel panel-default col-md-9 col-md-offset-1 hide">

                            <div className="panel-heading" id="exibe-esconde-cadastro-usuario-sj">
                                <div className="pointer panel-title" data-toggle="collapse" data-target="#box-exibe-esconde-cadastro-usuario-sj">
                                    <i className="fa fa-key"></i> Informações de <strong>acesso</strong> para processos em <strong>Segredo de Justiça</strong>
                                </div>
                            </div>

                            <div className="panel-body collapse in" id="box-exibe-esconde-cadastro-usuario-sj">

                            <div className="wrap-form">

                                <div className={( Array.isArray( this.state.segredos) && this.state.segredos.length > 0 ) ? "hide" : "panel panel-warning"} id="cad-usu-trib-sj">

                                    <div className="panel-heading">
                                        <i className="fa fa-keyboard-o"></i> Dados de acesso
                                    </div>

                                    <div className="panel-body">
                                        <div className="alert alert-info" role="alert">
                                            <i className="fa fa-info"></i> Informe o <strong>CPF</strong> e <strong>Senha</strong> utilizados para se autenticar no site do <strong>tribunal</strong>.
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label htmlFor="opt_tribunal_sj" className=" control-label">Tribunal <span className="text-red">*</span></label>
                                            <div className="">
                                                <Select
                                                    placeholder="Selecione ou digite..."
                                                    options={this.state.tribunalList}
                                                    value={this.state.tribunal}
                                                    name="tribunal"
                                                    onChange={this.selectChange}
                                                  />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inp_cpf_sj" className="control-label">CPF <span className="text-red">*</span>
                                            <MyPopover icon="white" text="dependendo do tribunal pode ser o cpf, login, id, email, nome, ou outros docs, digite neste campo " />
                                            </label>
                                            <input type="text" className="form-control" autoComplete="new-password" name="cpf" value={this.state.cpf} onChange={this.handleChange} />
                                      </div>
                                      <div className="form-group col-md-6">
                                            <label htmlFor="inp_senha_sj" className="control-label">Senha <span className="text-red">*</span></label>
                                              <input type="text" name="senhaSegredo" value={this.state.senhaSegredo} id="inp_senha_sj" autoComplete="off" className="form-control" onChange={this.handleChange} />

                                      </div>
                                      <div className="form-group col-md-12" id="box-bt-add-novo-usu-sj">
                                            <div className="col-md-offset-8">
                                                <button type="button" className="btn btn-info" id="bt-add-novo-usu-trib-sj" onClick={this.cadastraSegredo} > Cadastrar Acesso</button>
                                            </div>
                                      </div>
                                </div>
                                </div>

                            </div>
                            <div className="panel panel-warning" id="cad-usu-trib-sj-list">

                                <div className="panel-heading">
                                    <i className="fa fa-list"></i> Acessos
                                </div>

                                <div className="panel-body">
                                    <table className="table table-hover table-condensed table-bordered" id="tb-list-trib-sj-cad-usu">
                                        <thead>
                                            <tr>
                                                <th className="table_lawsuit_number">Tribunal</th>
                                                <th>CPF</th>
                                                <th>Senha</th>
                                                <th className="action">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                { ( Array.isArray( this.state.segredos) && this.state.segredos.length > 0 ) ? this.state.segredos.map( (record, index) => this.segredoItem( {record, index}, 'segredo-item' ) ) : null }
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>
                        </div>
                
                  </div>
                </div>
             </div>
  }

}
