import AuthService from './AuthService';
import {api} from '../config';


export default class OfficeService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
    }

    changePlan( data ){

          return this.fetch(`${this.domain}Offices/changePlan/`, {
              method: 'POST',
              body: JSON.stringify(data)
          }, true, false).then(res => {
              //console.log(res);
              return Promise.resolve(res);
          })
    }

    confirm( token ){
      return this.fetch(`${this.domain}Offices/confirm/?confirm_token=${token}`, {
          method: 'GET',
      }, false).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    getConfigs( token ){

          return this.fetch(`${this.domain}Offices/view/${token}`, {
              method: 'GET',
          }).then(res => {
              //console.log(res);
              return Promise.resolve(res.data.Offices);
          })
    }

    getPlan( ){

          return this.fetch(`${this.domain}PlanOffices/index`, {
              method: 'GET',
          }).then(res => {
              //console.log(res);
              if(res.success === true){
                return Promise.resolve(res.data.PlanOffices);
              }else{
                return Promise.resolve(res);
              }
          })
    }

    saveHeader( data, token ){

          return this.fetch(`${this.domain}Offices/edit/${token}`, {
              method: 'POST',
              body: JSON.stringify(data)
          }, true, true).then(res => {
             //console.log(res);
              return Promise.resolve(res);
          })
    }

    update( token, data ){

          return this.fetch(`${this.domain}Offices/edit/${token}`, {
              method: 'POST',
              body: JSON.stringify(data)
          }, true, true).then(res => {
             //console.log(res);
              return Promise.resolve(res);
          })
    }

    fetch(url, options, bearer = true, multipart = false) {
          let auth = new AuthService();

          options.headers = {
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

           //console.log(bearer);

           if (multipart) {
            options.headers['Content-type'] = 'multipart/form-data';
           }

           if (bearer) {
               options.headers['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');
           }

          if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
            return Promise.resolve({
              message: "Você não tem permissão para acessar o sistema neste momento."
            });
          }else{
    

            return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                          if(typeof response !== "undefined"){

                                    return response.text().then(function(result){
                                      //console.log(result);
                                      var message = result
                                      try {
                                        result = result.split('{"success"');
                                        result = result[1].split('<pre');
                                        //console.log(result[0]);
                                        let json = '{"success"'+result[0];
                                        result = JSON.parse(json);
                                        //console.log(result);
                                        return result;
                                      }catch(e){
                                        let result = {
                                          success: false,
                                          message: message
                                        }
                                        //console.log(result);
                                        return result;
                                      }
                                  });
                            
                          }else{

                                  let result = {
                                      success: false,
                                      message: "Erro desconhecido"
                                  }

                                  return Promise.resolve( result );

                          }

                  });

          }
    }

    _checkStatus(response) {
          // raises an error in case response status is not a success
          if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
              return response
          } else if( response.status === 401 || response.status === "401" ) {
           //console.log("Tentando trapacear? Você será desligado");


            localStorage.removeItem('id_token');
            localStorage.removeItem('loggedUser');
            window.location.href = '/login';

          }else {
              var error = new Error(response.statusText)
              error.response = response
              throw error
          }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
