import React from 'react';

import MyPopover from '../../containers/mypopover';
import Loading from '../../containers/loading';
import Confirm from '../../containers/confirm';

import UsersService from '../../../services/UsersService';
import TribunalService from '../../../services/TribunalService';

import { Modal, Button } from 'antd';
import { Switch } from 'antd';
import Select from 'react-select';
import FlashMessage from "../../containers/FlashMessage";

import times from '../../json/times_options';

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  },
}

export default class FormUser extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.modifiedFormUser = this.props.modifiedFormUser.bind(this);
      this.addUser = this.addUser.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
      this.cadastraSegredo = this.cadastraSegredo.bind(this);

      let tribunals = JSON.parse(localStorage.getItem('tribunals'));
      let tribunalOptions = [];

      /*tribunals.map( tribunal => {
          tribunalOptions.push( {label: tribunal.name+" - "+tribunal.slug, value: tribunal.id } );
      });*/

      tribunalOptions.push( {label: "Tribunal de Justiça de São Paulo - TJSP", value: 1 } );

      this.state = {
        loadingMessage: [],
        loadingVisible: false,
        titleModal: "Cadastrar Novo Usuário",
        visible: false,
        loading: false,
        errors: [],
        tribunalList: tribunalOptions,
        officesList: [],
        tribunal:  null,
        nome: "",
        cpf: "",
        senhaSegredo: "",
        email: "",
        cargo: "",
        senha: "",
        senha2: "",
        showConfirm: false,
        message: "",
        showModal: false,
        segredos: []
      };

  }

  componentWillReceiveProps(nextProps) {

    //console.log(nextProps.showModal);

    //console.log(this.state.showModal);

    if ( nextProps.token !== this.props.token || nextProps.showModal !== this.state.showModal ) {
        this.setState({
          token: nextProps.token,
          showModal: nextProps.showModal,
        });

      setTimeout(() => {        
        this.showModal();
        this.getSegredos();
      }, 200);
    }

  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });
        }
      });
  
      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
          });
  
          this.handleCancel(e, true);
        }
      });

      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

      this.usersService = new UsersService();
      this.getOffices(  );
      this.getSegredos();

  }

  getOffices(){
      this.UsersService = new UsersService();

      this.UsersService.officesList().then(response =>{

            if (this._isMounted) {

                this.setState({
                  officesList: response,
                  office: response[0]
                });

            }

      });
  }

  componentDidUpdate(){

      /*if( this.state.showModal === true ){
        this.showModal();
        this.setState({
          showModal: false
        });
      }*/

  }

  cadastraSegredo(){
    this.TribunalService = new TribunalService();

    if( this.state.tribunal === null ){
      alert("Selecione um tribunal para o segredo de justiça");
    }else if(this.state.cpf.length < 2){
      alert("O Campo CPF do segredo de justiça é obrigatório");
    }else if(this.state.senhaSegredo < 3){
      alert("preencha o campo senha do segredo de justiça. Campo Obrigatório");
    }else{

      let data = {
        tribunal_id: this.state.tribunal.value,
        login: this.state.cpf,
        password: this.state.senhaSegredo
      }
  
      this.TribunalService.addSegredo( data ).then( (response) => {
          this.getSegredos();
          this.setState({
              cpf: "",
              senha: "",
              tribunal: null
          });
      });

    }

  }

  deleteSegredo( token ){

      this.TribunalService.deleteSegredo( token ).then( (response) => {
        this.getSegredos();
      });

  }

  getSegredos(){
      this.TribunalService = new TribunalService();

      this.TribunalService.getSegredos( this.state.token ).then( (segredos) => {
        let secrets = [];
        if ( Array.isArray( segredos) ){
          secrets = segredos;
        }else{
          secrets.push( segredos );
        }
          this.setState({
            segredos: secrets
          });
      });
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   validateForm() {
       let errors = {};
       let formIsValid = true;

       if ( this.state.nome === 0 || this.state.nome === "" || typeof this.state.nome === "undefined" ) {
         formIsValid = false;
         errors["nome"] = "*Campo obrigatório";
       }

       let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       if( !re.test(String( this.state.email ).toLowerCase()) ){
         formIsValid = false;
         errors["email"] = "*Digite um email válido";
       }

       if ( this.state.email === 0 || this.state.email === "" || typeof this.state.email === "undefined" ) {
         formIsValid = false;
         errors["email"] = "*Campo obrigatório";
       }

       /*if ( !this.state.senha.match(/[a-z]+/) || !this.state.senha.match(/[A-Z]+/) || !this.state.senha.match(/[0-9]+/) ) {

         formIsValid = false;
         errors["senha"] = "*A senha deve conter minúsculas, maiúsculas e números";
       }*/

       if ( this.state.token === "" || typeof this.state.token === "undefined" ){

             if ( this.state.senha.length < 4 ) {
               formIsValid = false;
               errors["senha"] = "*A senha deve ter pelo menos 4 caracteres";
             }

             if ( this.state.senha === 0 || this.state.senha === "" || typeof this.state.senha === "undefined" ) {
               formIsValid = false;
               errors["senha"] = "*Campo obrigatório";
             }

             if ( this.state.senha2 === 0 || this.state.senha2 === "" || typeof this.state.senha2 === "undefined" ) {
               formIsValid = false;
               errors["senha2"] = "*Campo obrigatório";
             }

       }

       if ( this.state.senha2 !== this.state.senha ) {
         formIsValid = false;
         errors["senha2"] = "* Os campos não coincidem";
       }

       if( this.state.switchRestricaoHorario === true && ( typeof this.state.time_start === "undefined" || this.state.time_start === null || this.state.time_start === "" ) ){
         formIsValid = false;
         errors["time_start"] = "*Campo obrigatório";
       }

       if( this.state.switchRestricaoHorario === true && ( typeof this.state.time_end === "undefined" || this.state.time_end === null || this.state.time_end === "" ) ){
         formIsValid = false;
         errors["time_end"] = "*Campo obrigatório";
       }

       this.setState({
          errors: errors
        });

       return formIsValid;
  }


  clearFields(){
    this.setState(
      {
        loading: false,
        visible: false,
        showModal: false,
        errors: {},
        loadingVisible: false,
        loadingMessage: [],
        token: "",
        switchRestricaoHorario: false,
        dom: 0,
        seg: 0,
        ter: 0,
        qua: 0,
        qui: 0,
        sex: 0,
        sab: 0,
        time_start: null,
        time_end: null,
        tribunal: null,
        nome: "",
        cpf: "",
        senhaSegredo: "",
        cargo: "",
        senha: "",
        senha2: "",
        email: "",
        office: this.state.officesList[0]
      }
    );

  }

  newUser = () => {

    this.setState({
        token: "",
        showModal: true
    });

    setTimeout(() => {
        this.showModal();
    }, 300);

  }

  showModal = () => {

       //console.log(this.state.token);

        // Formulário de edição
        if( this.state.token !== "" && typeof this.state.token !== "undefined" ){

          this.UsersService = new UsersService();

          this.UsersService.view( this.state.token ).then(user =>{

                  if (this._isMounted) {
                            //console.log(user);

                            let escritorio = {};

                            this.state.officesList.map( (office, index) => {
                                if(office.value === user.office.token){
                                  escritorio = office;
                                }
                                return office;
                            });

                            this.setState({
                              nome: user.name,
                              cargo: ( typeof user.cargo !== "undefined" ) ? user.cargo : "",
                              email: user.email,
                              office: escritorio,
                            });

                            this.UsersService.viewRuleTimes( this.state.token ).then(rules =>{

                                if (this._isMounted) {

                                      if( typeof rules[0] !== "undefined" && rules[0] !== null ){

                                        if( (rules[0].start === ""  || rules[0].start === null ) && ( rules[0].end === "" || rules[0].end === null ) &&
                                            rules[0].wednesday === 1 && rules[0].thursday === 1 && rules[0].friday === 1 &&
                                            rules[0].saturday === 1 && rules[0].sunday === 1 && rules[0].monday === 1 && rules[0].tuesday === 1 ){
                                        }else{

                                          let start = new Date(rules[0].start);
                                          let end = new Date(rules[0].end);
                                          let switchRestricaoHorario = true;

                                          var userTimezoneOffset = start.getTimezoneOffset() * 60000;
                                          start = new Date(start.getTime() + userTimezoneOffset);
                                          userTimezoneOffset = end.getTimezoneOffset() * 60000;
                                          end = new Date(end.getTime() + userTimezoneOffset);

                                          let time_start = ('0' + start.getHours()).slice(-2)+':'+('0' + start.getMinutes()).slice(-2);
                                          let time_end = ('0' + end.getHours()).slice(-2)+':'+('0' + end.getMinutes()).slice(-2);

                                          if( time_start !== "00:00" ){
                                            time_start = times.filter( (time)=>{ return time.value === time_start } )[0];
                                            time_end = times.filter( (time)=>{ return time.value === time_end } )[0];
                                          }

                                          /*console.log(rules);

                                          if( rules[0].sunday === 0 && rules[0].monday === 0 && rules[0].tuesday === 0 && rules[0].wednesday === 0 &&
                                              rules[0].thursday === 0 && rules[0].friday === 0 && rules[0].saturday === 0 ){
                                            switchRestricaoHorario = false;
                                           //console.log("entrou");
                                          }*/

                                          this.setState({
                                            dom: rules[0].sunday,
                                            seg: rules[0].monday,
                                            ter: rules[0].tuesday,
                                            qua: rules[0].wednesday,
                                            qui: rules[0].thursday,
                                            sex: rules[0].friday,
                                            sab: rules[0].saturday,
                                            time_start: time_start,
                                            time_end: time_end,
                                            switchRestricaoHorario: switchRestricaoHorario
                                          });
                                        }

                                      }

                                }

                            });
                    }
          });

        }else {

        }

        this.setState({
          visible: true
        });

  };

  handleOk = () => {
      if (this.validateForm()) {

        this.setState({
          loadingVisible: true,
          loading: true,
          loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
        });

        this.addUser();

          setTimeout( () => {
            this.clearFields();
          }, 3000);

      }

  };

  handleCancel = ( event, r = false ) => {
      //window.confirm("As alterações serão perdidas, deseja realmente sair?") 
      if( r ){
          this.clearFields();

      }else{
        this.setState({
          showConfirm: true,
          message: "As alterações serão perdidas, deseja realmente sair?"
        });
      }
  };

  addUser = () => {
        this.UsersService = new UsersService();

        let user = {
          email: this.state.email,
          cargo: this.state.cargo,
          password: this.state.senha,
          name: this.state.nome,
          office_token: this.state.office.value,
        }

        let user_token = null; let edit = null;

        if( this.state.token !== "" && typeof this.state.token !== "undefined" ){
          user_token = this.state.token;
          edit = true;
        }

        this.UsersService.add( user, user_token ).then(res =>{

              if (this._isMounted) {

                    if ( res.success === true ) {

                      let user_token = res.data.Users.token;

                      //adiciono restrições de dia se houver
                      if( this.state.switchRestricaoHorario === true ){
                        let data = {
                          times: {
                            monday: ( this.state.seg !== 1) ? 0 : 1,
                            tuesday: (this.state.ter !== 1) ? 0 : 1,
                            wednesday: (this.state.qua !== 1) ? 0 : 1,
                            thursday: (this.state.qui !== 1) ? 0 : 1,
                            friday: (this.state.sex !== 1) ? 0 : 1,
                            saturday: (this.state.sab !== 1) ? 0 : 1,
                            sunday: (this.state.dom !== 1) ? 0 : 1,
                            start: (this.state.time_start !== "" ) ? this.state.time_start.value : "",
                            end: (this.state.time_end !== "" )? this.state.time_end.value: ""
                          }
                        }

                        this.UsersService.addRuleTimes( data, user_token ).then(res =>{

                          if( this.state.token === "" && typeof this.state.token === "undefined" ){
                            this.props.history.push( '/config/permissoes/'+user_token );
                          }

                        });


                      }else if ( edit === true ) {
                        let data = {
                          times: {
                            monday: 1,
                            tuesday: 1,
                            wednesday: 1,
                            thursday: 1,
                            friday: 1,
                            saturday: 1,
                            sunday: 1,
                            start: "",
                            end: ""
                          }
                        }

                        this.UsersService.addRuleTimes( data, user_token ).then(res =>{

                        });

                      }else{

                          if( this.state.token === "" && typeof this.state.token === "undefined" ){
                            this.props.history.push( '/config/permissoes/'+user_token );
                          }

                      }

                      this.modifiedFormUser();

                      localStorage.setItem('alert_message', "Usuário cadastrado com sucesso");
                      localStorage.setItem('alert_type', 'alert-success');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                      this.setState({
                        loadingVisible: false,
                        loadingMessage: [],
                        loading: false
                      });
                    }else {
                      localStorage.setItem('alert_message', res.message);
                      localStorage.setItem('alert_type', 'alert-danger');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                    }

              }

        })
        .catch(err =>{
           //console.log(err);
            if (this._isMounted) {
                this.setState({
                  loadingVisible: false,
                  loadingMessage: []
                });
            }
        });
  }

  onSwitchRestricaoHorarioChange = (checked) => {
    if ( checked ){
      document.getElementById("horario_container").classList.remove("hide");
      //document.getElementById("switchBaixaContainer").className += ' hide';
      this.setState({
        switchRestricaoHorario: true,
      });
    }else{
      document.getElementById("horario_container").className += ' hide';
      //document.getElementById("switchBaixaContainer").classList.remove("hide");
      this.setState({
        switchRestricaoHorario: false,
      });
    }
  }

  selected = (event) => {

    if (document.getElementById(event.target.id).classList.contains('rbc-active')) {
          // do some stuff
          document.getElementById(event.target.id).classList.remove("rbc-active");
          this.setState(
              {
                  [event.target.id]: 0
              }
          )
    }else{
          document.getElementById(event.target.id).className += ' rbc-active';
          this.setState(
              {
                  [event.target.id]: 1
              }
          )
    }

  }

  onSwitchSegredoJusticaChange = (checked) => {
    if ( checked ){

      document.getElementById('segredo-justica').classList.remove("hide");
      this.setState({
        switchSegredoJustica: true,
      });
    }else{
      document.getElementById("segredo-justica").className += ' hide';
      this.setState({
        switchSegredoJustica: false,
      });
    }
  }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;

      if ( typeof selected !== undefined && selected !== "" ) {

        this.setState( pops );

      }
  }

  segredoItem = ( {record, index}, type ) => {

      return (
        <tr key={record.token} className={type}>
              <td className="table_lawsuit_number">
                {this.state.tribunalList.find( trib => trib.value === record.tribunal_id ).label}
              </td>
              <td>
                {record.login}
              </td>
              <td className="action"  >
                      { '******' }
              </td>
              <td className="table_lawsuit_actions actions action">
                    <i className="fa fa-trash" data-token={record.token} onClick={() => this.deleteSegredo(record.token)}></i>
              </td>
        </tr>


      );
  };

  render() {

      let titleModal = <span>
                                {this.state.titleModal}

                                <MyPopover icon="white" text="Para não ficar extenso os registros, Cadastre somente o primeiro nome do usuário, pois as informações inseridas por ele irão aparecer o nome, data e hora do registro." />
                       </span>

      return <div>
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <span className="btn btn-primary form-submit add-user" onClick={this.newUser}>Cadastrar Novo</span>
                <Modal
                  visible={this.state.showModal}
                  title={titleModal}
                  width="655px"
                  onOk={this.handleOk}
                  onCancel={(event)=>{this.handleCancel(event)}}
                  footer={[
                    <Button key="submit" id="handleOk" type="primary" loading={this.state.loading} onClick={this.handleOk}>
                      Salvar Dados
                    </Button>,
                    <Button key="back" id="close" className="btn-danger" onClick={(event)=>{this.handleCancel(event)}}>
                      Fechar
                    </Button>,
                  ]}
                >
                    <div className="form-group col-md-8">
                        <label htmlFor="nome">Nome<span className="text-red">*</span></label>
                        <input
                            type="text"
                            className="nome input-lg form-control"
                            value={this.state.nome}
                            name="nome"
                            onChange={this.handleChange}
                          />
                        <div className="errorMsg">{this.state.errors.nome}</div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="cargo">Cargo</label>
                        <input
                            type="text"
                            className="cargo input-lg form-control"
                            value={( this.state.cargo !== null ) ? this.state.cargo : ""}
                            name="cargo"
                            onChange={this.handleChange}
                          />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="email">E-mail<span className="text-red">*</span></label>
                        <input
                            type="email"
                            className="email input-lg form-control"
                            value={this.state.email}
                            name="email"
                            autoComplete="new-password"
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.email}</div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="office">Escritório</label>
                        <Select
                            className="office"
                            defaultValue={this.state.officesList[0]}
                            options={this.state.officesList}
                            value={this.state.office}
                            name="office"
                            autoComplete="new-password"
                            onChange={this.selectChange}
                          />
                          <div className="errorMsg">{this.state.errors.office}</div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="senha">Senha<span className="text-red">*</span></label>
                        <input
                            type="password"
                            className="senha input-lg form-control"
                            value={this.state.senha}
                            name="senha"
                            autoComplete="new-password"
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.senha}</div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="senha2">Confirme a Senha</label>
                        <input
                            type="password"
                            className="senha2 input-lg form-control"
                            value={this.state.senha2}
                            name="senha2"
                            autoComplete="new-password"
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.senha2}</div>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="reembolso">
                              Restrição de Horário
                              <MyPopover icon="white" text="Permite definir em que dias da semana e horários este usuário poderá ter acesso ao sistema. Se não habilitado, a liberação será total, sem restrições." />
                        </label>
                        <Switch defaultChecked={this.state.switchRestricaoHorario} checked={this.state.switchRestricaoHorario} onChange={this.onSwitchRestricaoHorarioChange} />
                        <label className="switchSegredoJustica" htmlFor="segredoJustica">
                              Segredo de Justiça
                              <MyPopover icon="white" text="Se este usuário tiver seu nome em processos em Segredo de Justiça, basta cadastrar o CPF e a Senha de acesso no tribunal. Não se trata da senha do token do advogado, mas sim, da senha cadastrada no tribunal especifico para acessar estes processos sem o token." />
                              <Switch defaultChecked={this.state.switchSegredoJustica} checked={this.state.switchSegredoJustica} onChange={this.onSwitchSegredoJusticaChange} />
                        </label>

                        <div id="horario_container" className={(this.state.switchRestricaoHorario === true) ? "" : "hide" }>
                              <div id="user_week_days" className="rbc-toolbar">
                                  <label htmlFor="rbc" className="control-label">Dias da semana </label>
                                  <span className="rbc-btn-group">
                                      <button id="dom" className={ ( this.state.dom === 1 ) ? "rbc-active" : "" } type="button" onClick={this.selected}>Dom</button>
                                      <button id="seg" className={ ( this.state.seg === 1 ) ? "rbc-active" : "" } type="button" onClick={this.selected}>Seg</button>
                                      <button id="ter" className={ ( this.state.ter === 1 ) ? "rbc-active" : "" } type="button" onClick={this.selected}>Ter</button>
                                      <button id="qua" className={ ( this.state.qua === 1 ) ? "rbc-active" : "" } type="button" onClick={this.selected}>Qua</button>
                                      <button id="qui" className={ ( this.state.qui === 1 ) ? "rbc-active" : "" } type="button" onClick={this.selected}>Qui</button>
                                      <button id="sex" className={ ( this.state.sex === 1 ) ? "rbc-active" : "" } type="button" onClick={this.selected}>Sex</button>
                                      <button id="sab" className={ ( this.state.sab === 1 ) ? "rbc-active" : "" } type="button" onClick={this.selected}>Sab</button>
                                  </span>
                              </div>
                              <div className="form-group col-md-6 text-left">
                                  <label htmlFor="time_start" className="control-label">Horário de Início<span className="text-red">*</span></label>
                                  <Select
                                      placeholder="Selecione"
                                      styles={customStyles}
                                      options={times}
                                      value={this.state.time_start}
                                      name="time_start"
                                      isClearable={true}
                                      onChange={this.selectChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.time_start}</div>
                              </div>
                              <div className="form-group col-md-6 text-left">
                                    <label htmlFor="time_end" className="control-label">Horário de Término<span className="text-red">*</span></label>
                                    <Select
                                        placeholder="Selecione"
                                        styles={customStyles}
                                        options={times}
                                        value={this.state.time_end}
                                        name="time_end"
                                        isClearable={true}
                                        onChange={this.selectChange}
                                      />
                                    <div className="errorMsg">{this.state.errors.time_end}</div>
                              </div>

                        </div>
                    </div>

                    <div id="segredo-justica" className="panel panel-default col-md-12 hide">

                        <div className="panel-heading" id="exibe-esconde-cadastro-usuario-sj">
                            <div className="pointer panel-title" data-toggle="collapse" data-target="#box-exibe-esconde-cadastro-usuario-sj">
                                <i className="fa fa-key"></i> Informações de <strong>acesso</strong> para processos em <strong>Segredo de Justiça</strong>
                            </div>
                        </div>

                        <div className="panel-body collapse in" id="box-exibe-esconde-cadastro-usuario-sj">

                            <div className="panel panel-warning" id="cad-usu-trib-sj-list">

                                <div className="panel-heading">
                                    <i className="fa fa-list"></i> Acessos
                                </div>

                                <div className="panel-body">
                                    <table className="table table-hover table-condensed table-bordered" id="tb-list-trib-sj-cad-usu">
                                        <thead>
                                            <tr>
                                                <th className="table_lawsuit_number">Tribunal</th>
                                                <th>CPF</th>
                                                <th className="table_lawsuit_actions actions action">Senha</th>
                                                <th className="table_lawsuit_actions actions action">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                { ( Array.isArray( this.state.segredos) && this.state.segredos.length > 0 ) ? this.state.segredos.map( (record, index) => this.segredoItem( {record, index}, 'segredo-item' ) ) : null }
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
          </div>

  }

}
