import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from './containers/footer';
import ListTipos from './doc-facil/containers/listTipos';

import eDocsService from '../services/eDocsService';

import {isTablet} from './config/mobileDetect';

export default class listTemplate extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
      super( props );
      this.emptyType = this.emptyType.bind(this);
      this.state = {
        area_id: "",
        type_id: "",
        areas: [],
      }

      window.scrollTo(0,0);

      if(isTablet()){
        alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
        window.location.href = '/';
      }

  }

  componentDidMount( ) { this._isMounted = true;
    document.body.style.cursor = "wait";
    let areaList = [];

    this.eDocsService = new eDocsService();

      this.eDocsService.areaList( ).then(res =>{
            areaList = res;

            this.setState({
              areas: areaList,
            });
            document.body.style.cursor = "default";
      });
  }

  emptyType( area_token ){
    document.getElementById(area_token).className += ' hide';
  }

  areaPanel = ( {record, index}, type ) => {

      return (
        <div key={record.key} id={record.value} className="panel panel-default">
              <div className="panel-heading" role="tab" id={"accordion_parte"+record.value}>
                  <div className="pointer panel-title" data-toggle="collapse" data-target={"#collapse"+record.value}>
                      <strong>{record.label}</strong>
                  </div>
              </div>
              <div className="panel-body collapse" id={"collapse"+record.value}>
                    <ListTipos area_token={record.value} emptyType={this.emptyType} />
              </div>
        </div>
      );
  };

  render() {
      var url_base = localStorage.getItem('url_base');

      return <div className="sheet list-template">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="content-header">
                            <h1>Doc-Fácil</h1>
                      </div>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4 className="header-h4">
                                        Listar Modelos
                                        <Popover
                                            isOpen={this.state.isH4PopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isH4PopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })}
                                                    >
                                                        Permite a visualização de todos os modelos , separados por Área, Tipo e Títulos. (Somente visualização dos documentos)
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isH4PopoverOpen: false })} onMouseOver={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                        </Popover>
                                  </h4>
                            </div>
                      </div>
                      <div className="box-body">
                          {this.state.areas.map( (record, index) => this.areaPanel({ record, index }, 'area-item') )}
                      </div>
                  </div>
                </div>
             </div>
  }

}
