const print = [
    {
        key:`
                    * {
                      background:transparent !important;
                      color:#000 !important;
                      text-shadow:none !important;
                      filter:none !important;
                      -ms-filter:none !important;
                    }
                    body {
                      margin:0;
                      padding:0;
                      line-height: 1.4em;
                      color: #000;
                      font-size: 14pt;
                    }
                    @page {
                      margin: 0.5cm;
                      margin-right: 2.5cm;
                    }
                    nav, footer, video, audio, object, embed, jdiv {
                      display:none;
                    }
                    #root {
                      margin-top: 20px;
                    }
                    img {
                      max-width: 100%;
                    }
                    hr {
                      border-top: 2px solid #000;
                    }
                    .wrapper {
                      background-color: #fff !important;
                      width: 90%;
                      float: none !important;
                      margin: 2.5cm;
                      padding-left: 2.5cm;
                      padding-right: 2.5cm;
                      padding-top: 1cm;
                      padding-bottom: 0.5cm;
                    }
                    h1 {
                      font-size: 24pt;
                    }

                    h2 {
                      font-size: 18pt;
                      font-weight: bold;
                    }

                    h3 {
                      font-size: 14pt;
                    }
                    p {
                      widows: 5;
                    }
                    p {
                      orphans: 5;
                    }
                    p {
                      margin-left: 10px;
                      float: left;
                      font-size: 12pt;
                    }
                    section {
                      margin-top: 50px;
                      display: table;
                    }
                    label {
                      float: left;
                      clear: both;
                    }
                    .contato {
                      font-size: 12pt;
                      clear: both;
                      display: table;
                    }
                    .contato label {
                      font-weight: 500;
                      clear: both;
                    }
                    .cover {
                      text-transform: uppercase;
                    }
                    @media print {
                      #root {
                        margin-top: 0px;
                      }
                      .wrapper {
                        padding-left: 0cm;
                        padding-right: 0cm;
                        padding-top: 0cm;
                        padding-bottom: 0cm;
                      }
                    }
                            `}];
export default print;
