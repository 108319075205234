import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';
import Select from 'react-select';
import HeaderBar from './header-bar/header-bar';
import Footer from './containers/footer';
import NavigationMenu from './sidebar/navigation-menu';
import PrintComponent from './reports/printComponent';
//import Loading from './containers/loading';

import FinanceService from '../services/FinanceService';
import LawsuitService from '../services/LawsuitService';
import AuthService from '../services/AuthService';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

export default class Cashflow extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(){
    super();

    this.onDatePickerChange = this.onDatePickerChange.bind(this);
    this.clear = this.clear.bind(this);
    this.modifiedViewPrint = this.modifiedViewPrint.bind(this);

    this.authService = new AuthService();

    this.authService.can("financeiro_fluxo_caixa", false, 'financeiro');

    let date_since = localStorage.getItem("date_since");
    if( date_since === null ){
      date_since = moment().startOf('month').format('DD/MM/YYYY');
    }

    this.state = {
        date_start: moment().startOf('month').format('DD/MM/YYYY'),
        date_end: moment().endOf('month').format('DD/MM/YYYY'),
        date_since: date_since,
        centroCustoList: [],
        token: "",
        categoryList: [],
        centroCusto: null,
        forma_pagamento: null,
        loadingMessage: [],
        totalDebito: 0,
        totalCredito: 0,
        formaPagamentoList: [],
        categoryOutgoingList: [],
        categoryIncomingList: [],
        categoryEntryLawsuitList: [],
        lawsuitList: [],
        print: 0,
    }
    window.scrollTo(0,0);
  }

  componentDidMount( ) { this._isMounted = true;
    this.LawsuitService = new LawsuitService();
    this.FinanceService = new FinanceService();

    this.LawsuitService.officeList().then(response =>{

      this.setState({
        lawsuitList: response
      });
    });


      this.FinanceService.centroCustoList().then(response =>{

        this.setState({
          centroCustoList: response
        });
      });

      this.constroyCashFlow();

      this.FinanceService.categoryOutgoingList().then(response =>{

        this.setState({
          categoryOutgoingList: response
        });
      });

      this.FinanceService.categoryIncomingList().then(response =>{

        this.setState({
          categoryIncomingList: response
        });
      });

      this.FinanceService.categoryEntryLawsuitList().then(response =>{

        this.setState({
          categoryEntryLawsuitList: response
        });
      });

      this.FinanceService.listFinancialPaymentOptions().then(response =>{

        this.setState({
          formaPagamentoList: response
        });
      });

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  clear = ( event ) => {
    let date_since = localStorage.getItem("date_since");
    if( date_since === null ){
      date_since = moment().startOf('month').format('DD/MM/YYYY');
    }

    this.setState({
      date_start: moment().startOf('month').format('DD/MM/YYYY'),
      date_end: moment().endOf('month').format('DD/MM/YYYY'),
      date_since: date_since,
      centroCusto: null,
      forma_pagamento: null
    });
  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else{
      valor = valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  listDateSince(){
    let totalDebito = 0; let totalCredito = 0; let resultadoAcumulado = 0;
    let centro_custo = ( this.state.centroCusto===null ) ? null : this.state.centroCusto.value;
    let lawsuit_token = null;
    let forma_pagamento = ( this.state.forma_pagamento===null ) ? null : this.state.forma_pagamento.value;

    this.FinanceService = new FinanceService();
          this.FinanceService.listIncoming( this.state.date_since, this.state.date_end, "3", centro_custo, lawsuit_token, forma_pagamento ).then(res =>{
                if(res === null){
                  res = []
                }

                this.FinanceService.listOutgoing( this.state.date_since, this.state.date_end, "3", centro_custo, lawsuit_token, forma_pagamento ).then(response =>{
                  if(response === null){
                    response = []
                  }

                  res.map( ( record, index, array ) => {
                        if(typeof record.source === "undefined"){
                          record.source = 'incoming';
                        }

                        if(record.lawsuit !== null && typeof record.lawsuit !== "undefined"){

                          if(record.financial_category.name === "Valor Pago/Abatimento"){
                            record.type = "entrada";
                            totalCredito += record.valor;
                            return record;
                          }
                        }

                        if(record.pago === 1 && record.belongs_to_lawsuit === 0){
                          record.type = "entrada";
                          totalCredito += record.valor;
                          return record;
                        }

                        if(record.pago === 1 && record.belongs_to_lawsuit === 1 && record.financial_category.name === "Recebimento de Acordo"){
                          record.type = "saida";
                          totalDebito += record.valor;
                          return record;
                        }else if(record.pago === 1 && record.belongs_to_lawsuit === 1 ){
                          record.type = "entrada";
                          totalCredito += record.valor;
                          return record;
                        }

                        return record;

                  });

                  response.map( ( record, index, array ) => {
                        record.source = 'outgoing';

                        if(typeof record.reembolso !== "undefined" && record.reembolso.toString() === "1"){
                          record.type = "entrada";
                          totalCredito += record.valor;
                          return record;
                        }

                        if(record.pago === 1 ){
                          record.type = "saida";
                          totalDebito += record.valor;
                          return record;
                        }

                        return record;

                  });

                  resultadoAcumulado = totalCredito - totalDebito;;

                  let classAcum = (resultadoAcumulado > 0) ? "credit" : "debito";

                  resultadoAcumulado = this.formataMoeda(resultadoAcumulado);

                 //console.log(resultadoAcumulado);

                  this.setState({
                    resultadoAcumulado: resultadoAcumulado,
                    classAcum: classAcum
                  });

                });

          });
  }

  constroyCashFlow(){

    this.listDateSince();

    let totalDebito = 0; let totalCredito = 0; let resultadoPeriodo = 0;
    let centro_custo = ( this.state.centroCusto===null ) ? null : this.state.centroCusto.value;
    let lawsuit_token = null; let entrada = []; let saida = [];
    let forma_pagamento = ( this.state.forma_pagamento===null ) ? null : this.state.forma_pagamento.value;
    //console.log(forma_pagamento);

    this.FinanceService = new FinanceService();
    this.FinanceService.listEntries().then(entries =>{
          this.FinanceService.listIncoming( this.state.date_start, this.state.date_end, "3", centro_custo, lawsuit_token, forma_pagamento ).then(res =>{
                //console.log(res);
                if(res === null){
                  res = []
                }

                this.FinanceService.listOutgoing( this.state.date_start, this.state.date_end, "3", centro_custo, lawsuit_token, forma_pagamento ).then(response =>{
                  //console.log(response);
                  if(response === null){
                    response = []
                  }

                  if(entries === null){
                    entries = []
                  }

                  entries.map( ( record, index, array ) => {
                    record.source = 'entry';

                    if( record.tipoOperacao.toString() === "1" ){
                      res.push(record);
                    }

                    return record;

                  });

                  res.map( ( record, index, array ) => {
                        if(typeof record.source === "undefined"){
                          record.source = 'incoming';
                        }

                        if(record.lawsuit !== null && typeof record.lawsuit !== "undefined"){
                          /*if(record.financial_category.name === "Recebimento de Acordo" && (record.pago === 1 || record.pago === true) ){
                            record.type = "entrada";
                            totalCredito += record.valor;
                            entrada.push(record);
                            return record;
                          }*/
                          if(record.financial_category.name === "Valor Pago/Abatimento"){
                            record.type = "entrada";
                            totalCredito += record.valor;
                            entrada.push(record);
                            return record;
                          }
                        }

                        if(record.pago === 1 && record.belongs_to_lawsuit === 0){
                          record.type = "entrada";
                          totalCredito += record.valor;
                          entrada.push(record);
                          return record;
                        }

                        if(record.pago === 1 && record.belongs_to_lawsuit === 1 && record.financial_category.name === "Recebimento de Acordo"){
                          record.type = "saida";
                          record.financial_category.name = "Repasse de Acordo";
                          totalDebito += record.valor;
                          saida.push(record);
                          return record;
                        }else if(record.pago === 1 && record.belongs_to_lawsuit === 1 ){
                          record.type = "entrada";
                          totalCredito += record.valor;
                          entrada.push(record);
                          return record;
                        }

                        return record;

                  });

                  response.map( ( record, index, array ) => {
                        record.source = 'outgoing';

                        //if(record.custa === true){

                        if(typeof record.reembolso !== "undefined" && record.reembolso.toString() === "1"){
                          //console.log("entrou");
                          record.type = "entrada";
                          totalCredito += record.valor;
                          entrada.push(record);
                          return record;
                        }

                        if(record.pago === 1 ){
                          record.type = "saida";
                          totalDebito += record.valor;
                          saida.push(record);
                          return record;
                        }

                        return record;

                  });

                  let cashflow = entrada.concat(saida);
                  cashflow.sort(function(a, b){

                    if ( moment(a.data_pagamento.substr(0,10), "YYYY-MM-DD").valueOf() < moment(b.data_pagamento.substr(0,10), "YYYY-MM-DD").valueOf() ){
                      return -1;
                    }else{
                      return 1;
                    }

                  });

                 //console.log(cashflow);

                  resultadoPeriodo = totalCredito - totalDebito;

                  let classPeriod = (resultadoPeriodo > 0) ? "credit" : "debito";

                  resultadoPeriodo = this.formataMoeda(resultadoPeriodo);

                  totalCredito = this.formataMoeda(totalCredito);

                  totalDebito = this.formataMoeda(totalDebito);

                  this.setState({
                    cashflow: cashflow,
                    totalCredito: totalCredito,
                    totalDebito: totalDebito,
                    resultadoPeriodo: resultadoPeriodo,
                    classPeriod: classPeriod,
                  });

                });

          });
     });
  }

  onDatePickerChange = (date, dateString, name) => {

    if (dateString === "" ){
      dateString = null;
    }

    if(name === "date_since"){
      localStorage.setItem("date_since", dateString);
    }

     this.setState(
         {
             [name]: dateString,
         }
     )

   }

   disabledStartDate = startValue => {
     const endValue = moment(this.state.date_end, "DD/MM/YYYY");
     if (!startValue || !endValue) {
       return false;
     }
     return  startValue.valueOf() > endValue.valueOf()  ;
   };

   disabledEndDate = endValue => {
     const startValue = moment(this.state.date_start, "DD/MM/YYYY");
     if (!endValue || !startValue) {
       return false;
     }
     return endValue.valueOf() < startValue.valueOf() ;
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" ) {
         this.setState( pops );
       }

     }
   }

   cashflowItem = ( {record, index}, type ) => {
       let valor  = this.formataMoeda(record.valor);
       let lawsuit = "";

       if(record.lawsuit !== null && typeof record.lawsuit !== "undefined" ){
         /*lawsuit = this.state.lawsuitList.find( (law)=>{ return law.value === record.lawsuit.toString() } );
         lawsuit = ( typeof lawsuit === "undefined") ? "" : lawsuit.label;*/
         if( record.lawsuit.number === null ){
           lawsuit = record.lawsuit.name;
         }else{
           lawsuit = record.lawsuit.number;
         }

       }else{
         lawsuit = "";
       }

       let data_pagamento = (record.data_pagamento !== null) ? record.data_pagamento.substr(0, 10).split('-').reverse().join('/') : "";

       return (
         <tr key={index} className={type}>
               <td className="category " >
                       {record.financial_category.name}
               </td>
               <td className="description" >
                       {record.observacao}
               </td>
               <td className="">
                       {lawsuit}
               </td>
               <td className="date" >
                       {data_pagamento}
               </td>
               <td className="debito">
                       {(record.type === "saida") ? valor: null}
               </td>
               <td className="credit "  >
                       {(record.type === "entrada") ? valor : null}
               </td>
         </tr>
       );
   };

  render() {
    let url_base = localStorage.getItem('url_base');

    let table =           <div className="table-responsive"><table className="table">
                                  <thead>
                                      <tr>
                                            <th className="table_category">
                                                <div>Categoria</div>
                                            </th>
                                            <th className="table_description">
                                                <div>Observação</div>
                                            </th>
                                            <th className="table_lawsuit">
                                                <div>Processo</div>
                                            </th>
                                            <th className="table_date">
                                                <div>Data</div>
                                            </th>
                                            <th className="table_debito">
                                                <div>Débito (R$)</div>
                                            </th>
                                            <th className="table_credit">
                                                <div>Crédito (R$)</div>
                                            </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                        { ( typeof this.state.cashflow !== "undefined") ? this.state.cashflow.map( (record, index) => this.cashflowItem( {record, index}, 'cashflow-item' ) ) : null }
                                        <tr className='cashflow-item'>
                                              <td className="date">
                                              </td>
                                              <td className="date">
                                              </td>
                                              <td className="date">
                                              </td>
                                              <td className="total">
                                                      Parciais:
                                              </td>
                                              <td className="debito total">
                                                      R$ {this.state.totalDebito}
                                              </td>
                                              <td className="credit total"  >
                                                      R$ {this.state.totalCredito}
                                              </td>
                                        </tr>
                                  </tbody>
                          </table></div>;

    let content = <div>
                        <div className="resultado-saldo">
                              <div className="resultado-acumulado col-sm-6 col-md-6">
                              Saldo Acumulado = <b className={this.state.classAcum}>R$ {this.state.resultadoAcumulado}</b>
                              </div>
                              <div className="resultado-periodo col-sm-6 col-md-6">
                              Resultado do Período = <b className={this.state.classPeriod}>R$ {this.state.resultadoPeriodo}</b>
                              </div>
                        </div>
                        {table}
                        <div className="resultado-periodo valor-total">
                          Saldo do Período: <b className={this.state.classPeriod}>R$ {this.state.resultadoPeriodo}</b>
                        </div>
                  </div>;

    if( this.state.print === 1 ){
        return <div>
                  <PrintComponent modifiedViewPrint={this.modifiedViewPrint} content={content} title={"Fluxo de Caixa"} subtitle={"Fluxo de Caixa"} />
             </div>
    }else{
      return <div className="sheet finance cashflow">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1>Financeiro</h1>
                        <p></p>
                      </section>
                      <div className="panel panel-primary"><div className="panel-heading text-center">
                            <h4>
                                  Fluxo de Caixa
                                  <Popover
                                      isOpen={this.state.isH4PopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isH4PopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })}
                                              >
                                                  Possibilita o controle geral de entrada e saída de valores que foram efetivamente baixados (pagos), com saldo geral (saldo acumulado) e/ou por períodos.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isH4PopoverOpen: false })} onMouseOver={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                  </Popover>
                            </h4>
                      </div></div>
                      <div className="box-body">
                        <div className="row filter">
                            <div className="">
                                <span className="btn btn-default align-right btn-print" onClick={(event)=>{this.print(event)} }>
                                    <i className="fa fa-print"></i> Imprimir
                                </span>
                                <label htmlFor="date_start" className="control-label since-label">
                                        Trazer saldo desde:
                                        <Popover
                                            isOpen={this.state.isSincePopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isSincePopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isSincePopoverOpen: !this.state.isSincePopoverOpen })}
                                                    >
                                                          Ao colocar esta data, será computado o saldo acumulado até a data atual.  Caso queira o saldo real, indique uma data anterior ao do primeiro lançamento no sistema.
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isSincePopoverOpen: false })}  onMouseOver={() => this.setState({ isSincePopoverOpen: !this.state.isSincePopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                        </Popover>
                                </label>
                                <DatePicker locale={locale} className="since" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_since !== null ) ? moment(this.state.date_since, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_since")} />
                            </div>
                        </div>
                        <h2 className="box-title">
                              Filtro do Período
                              <Popover
                                  isOpen={this.state.isPeriodoPopoverOpen}
                                  position={['top', 'right', 'left', 'bottom']}
                                  padding={10}
                                  onClickOutside={() => this.setState({ isPeriodoPopoverOpen: false })}
                                  content={({ position, targetRect, popoverRect }) => (
                                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                          position={position}
                                          targetRect={targetRect}
                                          popoverRect={popoverRect}
                                          arrowColor={'#94bcd8'}
                                          arrowSize={10}
                                      >
                                          <div className="popover-box"
                                              onClick={() => this.setState({ isPeriodoPopoverOpen: !this.state.isPeriodoPopoverOpen })}
                                          >
                                                O sistema já traz lançamentos do 1° ao último dia do mês corrente, para visualização de como estão as contas dentro do mês, apresentando o saldo em Resultado do Período, Estas datas podem ser alteradas.
                                          </div>
                                      </ArrowContainer>
                                  )}
                              >
                                  <img onMouseOut={() => this.setState({ isPeriodoPopoverOpen: false })}  onMouseOver={() => this.setState({ isPeriodoPopoverOpen: !this.state.isPeriodoPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                              </Popover>
                        </h2>
                        <div className="row filter">
                            <div className="form-group date text-left">
                                <label htmlFor="date_start" className="control-label">De</label>
                                <DatePicker locale={locale} className="" disabledDate={this.disabledStartDate} placeholder="De" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                            </div>
                            <div className="form-group date text-left">
                                <label htmlFor="date_end" className="control-label">Até</label>
                                <DatePicker locale={locale} className="" disabledDate={this.disabledEndDate} placeholder="Até" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                            </div>
                            <div className="form-group date text-left">
                                <Select
                                    className="centro-custo-container"
                                    options={this.state.centroCustoList}
                                    placeholder="Centro de Custos"
                                    value={this.state.centroCusto}
                                    name="centroCusto"
                                    isClearable={true}
                                    styles={customStyles}
                                    onChange={this.selectChange}
                                  />
                            </div>
                            <div className="form-group date text-left">
                                <Select
                                    className="forma-pagamento-container"
                                    options={this.state.formaPagamentoList}
                                    placeholder="Forma de Pagamento"
                                    value={this.state.forma_pagamento}
                                    name="forma_pagamento"
                                    isClearable={true}
                                    styles={customStyles}
                                    onChange={this.selectChange}
                                  />
                            </div>
                            <div className="container-search">
                                <span className="btn btn-primary" onClick={ ( event ) => { this.constroyCashFlow() } }>Filtrar</span>
                                <span className="btn btn-danger" data-type={this.state.type} onClick={ ( event ) => { this.clear(event) } } >Limpar</span>
                            </div>
                        </div>
                        <div className="resultado-saldo">
                              <div className="resultado-acumulado col-sm-6 col-md-6">
                              Saldo Acumulado = <b className={this.state.classAcum}>R$ {this.state.resultadoAcumulado}</b>
                              </div>
                              <div className="resultado-periodo col-sm-6 col-md-6">
                              Resultado do Período = <b className={this.state.classPeriod}>R$ {this.state.resultadoPeriodo}</b>
                              </div>
                        </div>
                      </div>

                      {table}
                      <div className="resultado-periodo valor-total">
                        Saldo do Período: <b className={this.state.classPeriod}>R$ {this.state.resultadoPeriodo}</b>
                      </div>

                  </div>
                </div>
             </div>

      }
  }

}
