import AuthService from './AuthService';
import {api} from '../config';
import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class LawsuitPersonagesService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.edit = this.edit.bind(this)
        this.add = this.add.bind(this)
        this.addToLawsuit = this.addToLawsuit.bind(this)
        this.view = this.view.bind(this)
    }

    delete( token ){
      return this.fetch(`${this.domain}Personages/delete/${token}`, {
          method: 'DELETE',
      }).then(res => {
          localStorage.removeItem('lawsuitPersonagesList');
          return Promise.resolve(res);
      })
    }

    list() {

      let lawsuitPersonagesList = JSON.parse(localStorage.getItem('lawsuitPersonagesList'));
      if( 
        ( lawsuitPersonagesList == null || 
          lawsuitPersonagesList == 0 || 
          lawsuitPersonagesList == "undefined" ||
          typeof lawsuitPersonagesList.date == "undefined" 
        ) || 
          moment().diff(lawsuitPersonagesList.date, 'minutes',true ) > 120 
      ){
          return this.fetch(`${this.domain}Personages/?list=true&v2.0`, {
              method: 'GET',
          }).then(res => {
              //console.log(res);

              if( res.success === true){
                  var response = [];
                  res.data.Personages.map((value, index, array )=>{
                    response[index] = {"value": value.token, "label": value.name};
                    return response;
                  });

                  localStorage.setItem('lawsuitPersonagesList', JSON.stringify({ personages: response, date: moment() } ) );
                  return Promise.resolve(response);
              }else{
              //console.log(res.message);
                return Promise.resolve([]);
              }

          });
        }else{
          return Promise.resolve(lawsuitPersonagesList.personages);
        }
    }

    clientList() {
      return this.fetch(`${this.domain}Personages/?list=true&only_client=true&v2.0`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);

          if( res.success === true){
              var response = [];
              res.data.Personages.map((value, index, array )=>{
                response[index] = {"value": value.token, "label": value.name};
                return response;
              });

              return Promise.resolve(response);
          }else{
           //console.log(res.message);
            return Promise.resolve([]);
          }

      })
    }

    listLinked ( lawsuit_token ) {
        return this.fetch(`${this.domain}LawsuitsPersonages/index/${lawsuit_token}`, {
            method: 'GET',
        }).then(res => {
           //console.log(res);
            return Promise.resolve(res);
        })
    }

    addToLawsuit ( data ){
      return this.fetch(`${this.domain}LawsuitsPersonages/add`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    unlink ( lawsuit_token, personage_token ){
      return this.fetch(`${this.domain}LawsuitsPersonages/delete/${lawsuit_token}/${personage_token}`, {
          method: 'DELETE'
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    viewLink ( token ){
      return this.fetch(`${this.domain}LawsuitsPersonages/view/${token}`, {
          method: 'GET',
      }).then(res => {

       //console.log(res);

          if ( res.success === true ){
            return Promise.resolve(res.data.Personages);
          }

      })

    }

    add ( data ){
      return this.fetch(`${this.domain}Personages/add`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
         //console.log(res);
          localStorage.removeItem('lawsuitPersonagesList');
          return Promise.resolve(res);
      })

    }

    edit ( data, token ){
      return this.fetch(`${this.domain}Personages/edit/${token}`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })

    }

    view ( token ){
      return this.fetch(`${this.domain}Personages/view/${token}`, {
          method: 'GET',
      }).then(res => {

          if ( res.success === true ){
            return Promise.resolve(res.data.Personages);
          }

      })

    }

    getTypes () {
      /*let opt_types = [
        { value: '1', label: 'Acusado' },
        { value: '2', label: 'Adv Autor' },
        { value: '3', label: 'Recorrido' },
        { value: '4', label: 'Recorrente' },
      ];
      return Promise.resolve( opt_types );*/
      return this.fetch(`${this.domain}PersonageTypes`, {
          method: 'GET',
      }).then(res => {
          if( res.success === true ){
            var response = [];
            res.data.PersonagesTypes.map((value, index, array )=>{
              response[index] = {"value": value.id, "label": value.name};
              return response;
            });
            return Promise.resolve(response);
          }else {
            return Promise.resolve(res.message);
          }
      })
    }

    getCategories () {
      /*let opt_categories = [
        { value: '1', label: 'Acusado' },
        { value: '2', label: 'Adv Autor' },
        { value: '3', label: 'Recorrido' },
        { value: '4', label: 'Recorrente' },
      ];
      return Promise.resolve( opt_categories );*/
      return this.fetch(`${this.domain}PersonagesCategories`, {
          method: 'GET',
      }).then(res => {
         //console.log(res);
          return Promise.resolve(res);
      })
    }

    report( client, date, date_start, date_end, month, cidade, estado ) {

      if( client === "0" ){
        client = "";
      }else{
        client = "&token="+client;
      }

      if( date === "0" ){
        date = "";
      }else{
        date = "&birthday="+date;
      }

      if( date_start === "0" ){
        date_start = "";
      }else{
        date_start = "&birthday_start="+date_start;
      }

      if( date_end === "0" ){
        date_end = "";
      }else{
        date_end = "&birthday_end="+date_end;
      }

      if( month === "0" ){
        month = "";
      }else{
        month = "&birthday_month="+month;
      }

      if( cidade === "0" ){
        cidade = "";
      }else{
        cidade = "&city_id="+cidade;
      }

      if( estado === "0" ){
        estado = "";
      }else{
        estado = "&state_id="+estado;
      }

      return this.fetch(`${this.domain}Personages/?1${client}${date}${date_start}${date_end}${month}${cidade}${estado}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);

          if( res.success === true){
              return Promise.resolve(res.data.Personages);
          }else{
           //console.log(res.message);
            return Promise.resolve([]);
          }

      })
    }

    fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

         if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
          return Promise.resolve({
            message: "Você não tem permissão para acessar o sistema neste momento."
          });
        }else{
  

          return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())

        }
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
         //console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
          window.location.href = '/config/mudar-plano?plano_vencido';
  
        }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}
