import React from 'react';
import 'antd/dist/antd.css';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import Confirm from '../../containers/confirm';
import AuthService from '../../../services/AuthService';

import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class RecursosLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,          
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        if( this.state.scrapToDelete !== false ){
            this.deleteScrap(this.state.scrapToDelete, true);
        }
      }
    });

  }


  constructor( props ) {
    super(props);
    this.recursoItem = this.recursoItem.bind(this);
    this.myRef = React.createRef();

    this.authService = new AuthService();

    this.number_comments = 0;

    this.state = {
      lawsuit_token: this.props.lawsuit.token,
      lawsuit: this.props.lawsuit,
      showConfirm: false,
    }
  }

  componentWillReceiveProps(nextProps) {

      this.setState({
        lawsuit_token: nextProps.lawsuit.token,
        lawsuit: nextProps.lawsuit,
      });
      var url_string = window.location.href;
      var url = new URL(url_string);
  }

  recursoItem = ( {record, index}, type ) => {

      return (
        <tr key={record.token} className={type}>
              <td className="number">
                    {index+1}
              </td>
              <td className="date" >
                    <span className="date-info-lawsuit">{( record.date !== "") ? moment( record.date ).format("DD/MM/YYYY") : ""} às {( record.date !== "") ? moment( record.date ).format('HH:mm') : ""}</span>
              </td>
              <td className="informacoes" >
                <span>
                  {record.name}
                </span>
              </td>
        </tr>


      );
  };

  panelHeaderItem = ( {record, index}, type ) => {
    let label = record.name.replace(/_/g, " ");
    return (
      <div key={record.token}>
        <label>{label[0].toUpperCase() + label.substring(1)}: </label>
        <span>{record.value}</span>
      </div>
    );
  };

  recursoPanel = ( {record, index}, type ) => {

      record.procedure_movements.sort( (a,b)=>{
          if(a.date < b.date){
            return 1;
          }else{
            return -1;
          }
      });

      return (
        <div key={record.token} className={ type }>
              <div className='headers'>
              { ( typeof record.procedure_inputs !== "undefined") ? record.procedure_inputs.map( (record, index) => this.panelHeaderItem({ record, index }, 'panel--header-item') ) : null }
              </div>
              <div className="table-responsive">
                    <table id="movement-table" className={( typeof record.procedure_movements !== "undefined" && record.procedure_movements.length > 0) ? "table" : "hide"}>
                      <thead>
                          <tr>
                                <th className="number_movement hidden-xs hidden-sm">
                                    <div>N°</div>
                                </th>
                                <th className="date">
                                    <div>Data</div>
                                </th>
                                <th className="movement">
                                    <div>Recursos</div>
                                </th>
                          </tr>
                      </thead>
                      <tbody>
                            { ( typeof record.procedure_movements !== "undefined") ? record.procedure_movements.map( (record, index) => this.recursoItem({ record, index }, 'recurso-item') ) : null }
                      </tbody>
                    </table>
              </div>
        </div>
      );

  };

  render() {

      let url_base = localStorage.getItem('url_base');

      //console.log("o processo é manual?", this.state.lawsuit);

      return <div className="panel panel-default container-parte">
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} data-target="#recursos-lawsuit-target">
                        <strong>Recursos</strong>
                    </div>
                </div>

                <div className="panel-body collapse" id="recursos-lawsuit-target">
                    { ( typeof this.state.lawsuit.recursos !== "undefined") ? this.state.lawsuit.recursos.map( (record, index) => this.recursoPanel({ record, index }, 'recurso-panel') ) : null }
              </div>
          </div>

  }
}
