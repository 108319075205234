import React from 'react';
import { Switch } from 'antd';

import MyPopover from '../containers/mypopover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import PrintComponent from './printComponent';
import Loading from '../containers/loading';

import AddressService from '../../services/AddressService';
import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';

const monthList = [
  { value: '0', label: '' },
  { value: '1', label: 'Janeiro' },
  { value: '2', label: 'Fevereiro' },
  { value: '3', label: 'Março' },
  { value: '4', label: 'Abril' },
  { value: '5', label: 'Maio' },
  { value: '6', label: 'Junho' },
  { value: '7', label: 'Julho' },
  { value: '8', label: 'Agosto' },
  { value: '9', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' },
];

export default class ReportClientList extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });
  }

  constructor( { match } ) {
      super();
      const select = { value: '0', label: 'Todos' };
      let client = select; let estado = select;

      let date_start = (match.params.date_start === "0") ? "" : match.params.date_start.replace(/-/g,'/');
      let date_end = (match.params.date_end === "0") ? "" : match.params.date_end.replace(/-/g,'/');
      let date = (match.params.date === "0") ? "" : match.params.date.replace(/-/g,'/');

      this.back = this.back.bind(this);
      this.modifiedViewPrint = this.modifiedViewPrint.bind(this);

      //console.log( match );

      this.state = {
        client: client,
        cidade: estado,
        estado: estado,
        date_start: date_start,
        date_end: date_end,
        date: date,
        month: match.params.month,
        data_nasc_exata: match.params.data_nasc_exata,
        mes_nasc: match.params.mes_nasc,
        periodo_nasc: match.params.periodo_nasc,
        params: match.params,
        clientList: [],
        qtd: 4,
        print: 0,
        loadingVisible: false,
        loadingMessage: [],
        office: {
          name: "DFOLI COMERCIO E SERVIÇOS LTDA"
        }
      }

  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.history.location.pathname !== this.props.history.location.pathname  ) {
      this.initParams( nextProps.match.params );
    }

  }

  componentWillMount() {

    this.initParams( this.props.match.params );

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  initParams( params ){

        const select = { value: '0', label: 'Todos' };
        let client = select; let estado = select; let cidade = select;

        this.setState({
          loadingVisible: true,
          loadingMessage: ["O relatório está sendo processado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
        });

        let date_start = (params.date_start === "0") ? "" : params.date_start.replace(/-/g,'/');
        let date_end = (params.date_end === "0") ? "" : params.date_end.replace(/-/g,'/');
        let date = (params.date === "0") ? "" : params.date.replace(/-/g,'/');

        this.LawsuitPersonagesService = new LawsuitPersonagesService();
        this.AddressService = new AddressService();

        this.AddressService.getEstado( ).then(res =>{

              if( params.estado !== "0" ){
                  estado = res.filter( (uf)=>{ return uf.value.toString() === params.estado } )[0];

                  if( params.cidade !== "0" ){
                      this.AddressService.getCidades( estado.value ).then( cities=>{
                        cidade = cities.filter( (city)=>{ return city.value.toString() === params.cidade } )[0];
                        this.setState({
                          cidade: cidade,
                        });
                      });
                  }

              }

              this.setState({
                estado: estado,
              });

        });


        this.LawsuitPersonagesService.list( ).then(clientList =>{

              if( params.client !== "0" ){
                client = clientList.filter( (client)=>{ return client.value === params.client } )[0];
              }

              this.LawsuitPersonagesService.report( params.client, params.date, params.date_start, params.date_end, params.month, params.cidade, params.estado ).then(list =>{
                  this.setState({
                    client: client,
                    date_start: date_start,
                    date_end: date_end,
                    date: date,
                    month: params.month,
                    data_nasc_exata: params.data_nasc_exata,
                    mes_nasc: params.mes_nasc,
                    periodo_nasc: params.periodo_nasc,
                    params: params,
                    clientList: list,
                    qtd: list.length,
                    loadingVisible: false,
                  });
              });

        });

  }

  back(){
    this.props.history.goBack();
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  onSwitchChange = (checked) => {
        if ( checked ){
          this.setState({
            showOnReport: true,
          });

        }else{
          this.setState({
            showOnReport: false,
          });
        }
  }

  screen = (event) => {
    this.setState({
      print: 0,
    });
  }

  clientItem = ( {record, index}, type ) => {

      let data_nasc = ""; let lawsuit = ""; let endereco = ""; let tel = ""; let email = ""; let local_fisico = "";
      if( this.state.params.data_nasc_exata === "1" || this.state.params.mes_nasc === "1" || this.state.params.periodo_nasc === "1" ){
        data_nasc = "1";
      }

      if( typeof record.lawsuits_personages !== "undefined" && record.lawsuits_personages !== null && record.lawsuits_personages.length > 0 ){
        //console.log( record.lawsuits_personages[0] );
        if( record.lawsuits_personages[0].lawsuit.number !== null ){
          lawsuit = record.lawsuits_personages[0].lawsuit.number;
        }else{
          lawsuit = record.lawsuits_personages[0].lawsuit.name;
        }
        local_fisico = record.lawsuits_personages[0].lawsuit.localFisico;
      }

      if( typeof record.personage_addresses !== "undefined" && record.personage_addresses !== null && record.personage_addresses.length > 0 ){
        //console.log( record.personage_addresses[0] );
        let city = ( record.personage_addresses[0].city !== null ) ? record.personage_addresses[0].city.name : "";
        let state = ( record.personage_addresses[0].state !== null ) ? record.personage_addresses[0].state.uf : "";
        endereco = record.personage_addresses[0].street+", "+
        record.personage_addresses[0].number+" - "+record.personage_addresses[0].neighbor+
        ", "+city+" - "+state;
      }

      if( typeof record.personage_contacts !== "undefined" && record.personage_contacts !== null && record.personage_contacts.length > 0 ){

        for(var i = 0; i < record.personage_contacts.length; i++){
          if( record.personage_contacts[i].personage_contact_type_id === 1 && tel === "" ){
            tel = record.personage_contacts[i].value;

          }
          if( record.personage_contacts[i].personage_contact_type_id === 3 && email === "" ){
            email = record.personage_contacts[i].value;
          }

        }
      }

      return (
        <tr key={index} className={type}>

                <td className="client">
                    {record.name}
                    <p>{ (data_nasc === "1" && record.data_nascimento !== null) ? record.data_nascimento.substr(0,10).split("-").reverse().join("/") : "" }</p>
                </td>
                <td className={(this.state.params.email === "true" || this.state.params.tel === "true") ? "table_email" : "hide table_email"}>
                    {email}
                    <p>{tel}</p>
                </td>
                <td className={(this.state.params.rg === "true") ? "rg" : "hide rg"}>
                    {record.rg}
                </td>
                <td className={(this.state.params.cpf === "true") ? "cpf" : "hide cpf"}>
                    {record.cpf}
                </td>
                <td className={(this.state.params.profissao === "true") ? "profissao" : "hide profissao"}>
                    {record.profissao}
                </td>
                <td className={(this.state.params.estado_civil === "true") ? "estado_civil" : "hide estado_civil"}>
                    {record.estado_civil}
                </td>
                <td className={(this.state.params.n_processo === "true") ? "processo" : "hide processo"}>
                    { lawsuit }
                </td>
                <td className={(this.state.params.n_pasta === "true") ? "pasta" : "hide pasta"}>
                    { local_fisico }
                </td>
                <td className={(this.state.params.endereco_completo === "true") ? "endereco" : "hide endereco"} >
                    {endereco}
                </td>

        </tr>
      )

  }

  render() {
      //let url_base = localStorage.getItem('url_base');

      let mes = monthList.filter( (month)=>{ return month.value === this.state.month } )[0].label;

      var tamanho= 0;
      for (var i in this.state.params) {
          if ( this.state.params[i] === "true" ) {
              tamanho++;
          }
      }
      //console.log(tamanho);

      let data_nasc = "";
      if( this.state.params.data_nasc_exata === "1" || this.state.params.mes_nasc === "1" || this.state.params.periodo_nasc === "1" ){
        data_nasc = "1";
      }

      let panel_filtros =       <div className="panel panel-default container-publicacoes">
                                            <div className="panel-heading text-center">
                                                <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                                                    <strong>Filtros Utilizados</strong>
                                                </div>
                                            </div>
                                            <div className={ (this.state.showOnReport) ? "panel-body" : "panel-body collapse" } id="filter_target">
                                                  <ul>
                                                      <li className={(this.state.client.value === "0")?"hide": ""}><b>Cliente: </b> <span>{this.state.client.label}</span> </li>
                                                      <li className={(this.state.periodo_nasc === "0")?"hide": ""}><b>Data: </b> <span>{this.state.date_start}</span> <b>até: </b> <span>{this.state.date_end}</span> </li>
                                                      <li className={(this.state.data_nasc_exata === "0")?"hide": ""}><b>Data: </b> <span>{this.state.date}</span> </li>
                                                      <li className={(this.state.mes_nasc === "0")?"hide": ""}><b>Mês: </b> <span>{mes}</span> </li>
                                                      <li className={(this.state.params.cidade === "0")?"hide": ""}><b>Cidade: </b> <span>{this.state.cidade.label}</span> </li>
                                                      <li className={(this.state.params.estado === "0")?"hide": ""}><b>Estado: </b> <span>{this.state.estado.label}</span> </li>
                                                      <li>{ (this.state.client.value === "0" && this.state.periodo_nasc === "0" && this.state.data_nasc_exata === "0" && this.state.mes_nasc === "0" && this.state.params.cidade === "0" && this.state.params.estado === "0") ? "Nenhum Filtro utilizado - exibindo todos os clientes" : "" }</li>
                                                  </ul>
                                                  <label id="showOnReportContainer" className="right">

                                                          <MyPopover icon="white" text='Se desejar que os filtros do relatório apareçam no topo dos relatórios' />
                                                          Aparecer no relatório
                                                          <Switch title={(this.state.showOnReport)? "Desbloqueado": "Bloqueado"} checked={this.state.showOnReport} onChange={ this.onSwitchChange }  />
                                                  </label>
                                            </div>
                                  </div>

      let table =                     <table id="reportClientList" className={"reportClientList size-"+tamanho}>
                                          <thead>
                                              <tr>
                                                    <th className="">
                                                        <div>Nome { (data_nasc === "1") ? "/ Data de Nascimento" : "" }</div>
                                                    </th>
                                                    <th className={(this.state.params.email === "true" || this.state.params.tel === "true") ? "table_email" : "hide table_email"}>
                                                        <div>Contato</div>
                                                    </th>
                                                    <th className={(this.state.params.rg === "true") ? "rg" : "hide rg"}>
                                                        <div>RG</div>
                                                    </th>
                                                    <th className={(this.state.params.cpf === "true") ? "cpf" : "hide cpf"}>
                                                        <div>CPF</div>
                                                    </th>
                                                    <th className={(this.state.params.profissao === "true") ? "profissao" : "hide profissao"}>
                                                        <div>Profissao</div>
                                                    </th>
                                                    <th className={(this.state.params.estado_civil === "true") ? "estado_civil" : "hide estado_civil"}>
                                                        <div>Estado Civil</div>
                                                    </th>
                                                    <th className={(this.state.params.n_processo === "true") ? "processo" : "hide processo"}>
                                                        <div>Processo</div>
                                                    </th>
                                                    <th className={(this.state.params.n_pasta === "true") ? "pasta" : "hide pasta"}>
                                                        <div>Pasta</div>
                                                    </th>
                                                    <th className={(this.state.params.endereco_completo === "true") ? "endereco" : "hide endereco"}>
                                                        <div>Endereço</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              { (this.state.clientList.length > 0) ? this.state.clientList.map( (record, index) =>  this.clientItem( {record, index}, 'report-client-item' )  ) : <tr><td colSpan="3"><center>Nenhum cliente encontrado</center></td></tr>}
                                          </tbody>
                                  </table>;

      if( this.state.print === 1 ){
          if( !this.state.showOnReport ){
            panel_filtros = "";
          }
          return <div>
                    <PrintComponent modifiedViewPrint={this.modifiedViewPrint} panel_filtros={panel_filtros} content={table} title={"Relatório de Clientes"} subtitle={"Clientes"} />
               </div>
      }else{
        return <div className="sheet ">
                  <HeaderBar />
                  <NavigationMenu />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                  <div className="content-wrapper">
                    <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Relatórios - Cliente Listagem</h1>
                          <span className="btn btn-info" onClick={this.back} >Voltar</span>
                          <span className="btn btn-success btn-report btn-excel right hide">Exportar Excel</span>
                          <span className="btn btn-default btn-report right" onClick={(event)=>{this.print(event)} }>Imprimir</span>
                          <span className="btn btn-primary btn-report right hide">Enviar email</span>
                        </section>
                        <div className="box-body">
                            <div className="alert alert-info" role="alert">Quantidade de registros encontrados: <strong>{this.state.qtd}</strong> </div>

                            {panel_filtros}

                            {table}

                        </div>
                    </div>
                  </div>
               </div>
      }

  }

}
