import React from 'react';
import InputMask from 'react-input-mask';

export default class UniqueLawsuitInput extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <InputMask {...this.props} mask="999999999.9999.9999999" maskChar="_" />;
  }
}
