import React from 'react';
import './App.css';
import PublicRoutes from "./router";

import { Provider } from 'react-redux';

import store from './store';

import Footer from './components/containers/footer';

//localStorage.setItem('url_base', "http://173.249.28.254/avvocato_html/");
localStorage.setItem('url_base', "https://app.avvocatosistemas.com.br");
//localStorage.setItem('url_base', "http://localhost:3000");

const App = () => (
    <Provider store={store}>
            <PublicRoutes history={"hello"} />
            <Footer />
    </Provider>
);

export default App;
