const tribunalTypes = [
    {
        key: "inss",
        label: "INSS - Previdência Social",
        url_add: "/tribunal/inss/",
        path: "containers/addINSS"
    },
    {
      key: "stf",
      label: "STF - Supremo Tribunal Federal",
      url_add: "/tribunal/stf/",
      path: "containers/addSTF"
    },
    {
      key: "stj",
      label: "STJ - Supremo Tribunal de Justiça",
      url_add: "/tribunal/stj/",
      path: "containers/addSTJ"
    },
    {
      key: "tse",
      label: "TSE - Tribunal Superior Eleitoral",
      url_add: "/tribunal/tse/",
      path: "containers/addTSE"
    },
    {
      key: "tst",
      label: "TST - Tribunal Superior do Trabalho",
      url_add: "/tribunal/tst/",
      path: "containers/addTST",
      mask: "9999999-99.9999.5.99.9999"
    },
    {
      key: "tjsp",
      label: "SP - Tribunal de Justiça",
      url_add: "/tribunal/tjsp",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - Tribunal de Justiça",
      placeholder: "SP - Tribunal de Justiça",
      mask: "9999999-99.9999.8.26.9999"
    },
    {
      key: "tjmsp",
      label: "SP - TM - Tribunal Militar",
      url_add: "/tribunal/tjmsp/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - TM - Tribunal Militar",
      placeholder: "SP - TM - Tribunal Militar",
      mask: "9999999-99.9999.9.26.9999"
    },
    {
      key: "trt2",
      label: "SP - TRT 2ª Região",
      url_add: "/tribunal/trt2/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - TRT 2ª Região",
      placeholder: "SP - TRT 2ª Região",
      mask: "9999999-99.9999.5.02.9999"
    },
    {
      key: "trt2pje",
      label: "SP - Tribunal Regional do Trabalho 2ª Região - PJE",
      url_add: "/tribunal/trt2pje/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - TRT 2ª Região",
      placeholder: "SP - TRT 2ª Região",
      mask: "9999999-99.9999.5.02.9999"
    },
    {
      key: "trt15",
      label: "SP - TRT 15ª Região",
      url_add: "/tribunal/trt15/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - TRT 15ª Região",
      placeholder: "SP - TRT 15ª Região",
      mask: "9999999-99.9999.5.15.9999"
    },
    {
      key: "trt15pje",
      label: "SP - TRT 15ª Região - PJE",
      url_add: "/tribunal/trt15pje/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - TRT 15ª Região",
      placeholder: "SP - TRT 15ª Região",
      mask: "9999999-99.9999.5.15.9999"
    },
    {
      key: "trf3pje",
      label: "SP - TRF3 - PJE",
      url_add: "/tribunal/trf3pje/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - TRF3",
      placeholder: "SP - TRF3",
      mask: "9999999-99.9999.4.03.9999"
    },
    {
      key: "trf3",
      label: "SP - TRF3",
      url_add: "/tribunal/trf3/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - TRF3",
      placeholder: "SP - TRF3",
      mask: "9999999-99.9999.4.03.9999"
    },
    {
      key: "trt01-rj",
      label: "RJ - TRT 1° Região",
      url_add: "/tribunal/trt01-rj/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RJ - TRT 1° Região",
      placeholder: "RJ - TRT 1° Região",
      mask: "9999999-99.9999.5.01.9999"
    },
    {
      key: "trt1pje",
      label: "RJ - TRT 1° Região - PJE",
      url_add: "/tribunal/trt1pje/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RJ - TRT 1° Região",
      placeholder: "RJ - TRT 1° Região",
      mask: "9999999-99.9999.5.01.9999"
    },
    {
      key: "trt3pje",
      label: "MG - TRT 3ª Região - PJE",
      url_add: "/tribunal/trt3pje/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MG - TRT 3ª Região",
      placeholder: "MG - TRT 3ª Região",
      mask: "9999999-99.9999.5.03.9999"
    },
    {
      key: "trt3",
      label: "MG - TRT 3ª Região",
      url_add: "/tribunal/trt3/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MG - TRT 3ª Região",
      placeholder: "MG - TRT 3ª Região",
      mask: "9999999-99.9999.5.03.9999"
    },
    {
      key: "jfrj",
      label: "Justiça Federal do Rio de Janeiro",
      url_add: "/tribunal/jfrj/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RJ Justiça Federal",
      placeholder: "RJ - Justiça Federal",
      mask: "9999999-99.9999.4.02.9999"
    },
    {
      key: "trf2",
      label: "RJ - TRF2",
      url_add: "/tribunal/trf2/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RJ - TRF2",
      placeholder: "RJ - TRF2",
      mask: "9999999-99.9999.4.02.9999"
    },
    {
      key: "tjrj",
      label: "RJ - Tribunal de Justiça",
      url_add: "/tribunal/tjrj/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RJ - Tribunal de Justiça",
      placeholder: "RJ - Tribunal de Justiça",
      mask: "9999999-99.9999.8.19.9999"
    },
    {
      key: "jfsp",
      label: "SP - 1º Grau Justiça Federal",
      url_add: "/tribunal/jfsp/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - 1º Grau Justiça Federal",
      placeholder: "SP - 1º Grau Justiça Federal",
      mask: "9999999-99.9999.4.03.9999"
    },
    {
      key: "jef",
      label: "SP - Juizado Especial Federal",
      url_add: "/tribunal/jef/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SP - Juizado Especial Federal",
      placeholder: "SP - Juizado Especial Federal",
      mask: "9999999-99.9999.4.03.9999"
    },
    {
      key: "trf1",
      label: "MG - TRF1",
      url_add: "/tribunal/trf1/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MG - TRF1",
      placeholder: "MG - TRF1",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjmg",
      label: "MG - Tribunal de Justiça",
      url_add: "/tribunal/tjmg/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MG - Tribunal de Justiça",
      placeholder: "MG - Tribunal de Justiça",
      mask: "9999999-99.9999.8.13.9999"
    },
    {
      key: "tjmgpje",
      label: "MG - Tribunal de Justiça - PJE",
      url_add: "/tribunal/tjmgpje/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MG - Tribunal de Justiça",
      placeholder: "MG - Tribunal de Justiça",
      mask: "9999999-99.9999.8.13.9999"
    },
    {
      key: "tjmgprojudi",
      label: "MG - TJMG - PROJUDI",
      url_add: "/tribunal/tjmgprojudi/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - TJMG - PROJUDI",
      placeholder: "TJMG - PROJUDI",
      mask: "9999999-99.9999.8.13.9999"
    },
    {
      key: "jfpr",
      label: "PR - TRF4",
      url_add: "/tribunal/jfpr/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PR - TRF4",
      placeholder: "PR - TRF4",
      mask: "9999999-99.9999.4.04.9999"
    },
    {
      key: "tjpr",
      label: "PR - Tribunal de Justiça",
      url_add: "/tribunal/tjpr/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PR - Tribunal de Justiça",
      placeholder: "PR - Tribunal de Justiça",
      mask: "9999999-99.9999.8.16.9999"
    },
    /*{
      key: "tre-pr",
      label: "PR - TRE",
      url_add: "/tribunal/tre-pr/",
      path: "containers/addTREPR"
    },*/
    {
      key: "trt09-pr",
      label: "PR - TRT 9ª Região",
      url_add: "/tribunal/trt09-pr/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PR - TRT",
      placeholder: "PR - TRT",
      mask: "9999999-99.9999.5.0\\9.9999"
    },
    {
      key: "jfsc",
      label: "SC - TRF4",
      url_add: "/tribunal/jfsc/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SC - TRF4",
      placeholder: "SC - TRF4",
      mask: "9999999-99.9999.4.04.9999"
    },
    {
      key: "tjsc",
      label: "SC - Tribunal de Justiça",
      url_add: "/tribunal/tjsc/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SC - Tribunal de Justiça",
      placeholder: "SC - Tribunal de Justiça",
      mask: "9999999-99.9999.8.24.9999"
    },
    /*{
      key: "tre-sc",
      label: "SC - TRE",
      url_add: "/tribunal/tre-sc/",
      path: "containers/addTRESC"
    },*/
    {
      key: "trt12-sc",
      label: "SC - TRT 12ª Região",
      url_add: "/tribunal/trt12-sc/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SC - TRT 12ª Região",
      placeholder: "SC - TRT 12ª Região",
      mask: "9999999-99.9999.5.12.9999"
    },
    {
      key: "jfrs",
      label: "RS - TRF4",
      url_add: "/tribunal/jfrs/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RS - TRF4 - Tribunal Regional Federal 4ª Região",
      placeholder: "RS - TRF4",
      mask: "9999999-99.9999.4.04.9999"
    },
    {
      key: "tjrs",
      label: "RS - Tribunal de Justiça",
      url_add: "/tribunal/tjrs/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RS - Tribunal de Justiça",
      placeholder: "RS - Tribunal de Justiça",
      mask: "9999999-99.9999.8.21.9999"
    },
    /*{
      key: "tre-rs",
      label: "RS - TRE",
      url_add: "/tribunal/tre-rs/",
      path: "containers/addTRERS"
    },*/
    {
      key: "trt04-rs",
      label: "RS - TRT 4ª Região",
      url_add: "/tribunal/trt04-rs/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RS - Tribunal Regional do Trabalho 4ª Região",
      placeholder: "RS - Tribunal Regional do Trabalho 4ª Região",
      mask: "9999999-99.9999.5.04.9999"
    },
    {
      key: "jfes",
      label: "ES - TRF2",
      url_add: "/tribunal/jfes/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - ES - TRF2 - Tribunal Regional Federal 2ª Região",
      placeholder: "ES - TRF2",
      mask: "9999999-99.9999.4.02.9999"
    },
    {
      key: "tjes",
      label: "ES - Tribunal de Justiça",
      url_add: "/tribunal/tjes/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - ES - Tribunal de Justiça",
      placeholder: "ES - TJ",
      mask: "9999999-99.9999.8.08.9999"
    },
    /*{
      key: "tre-es",
      label: "ES - TRE",
      url_add: "/tribunal/tre-es/",
      path: "containers/addTRE_ES"
    },*/
    {
      key: "trt17-es",
      label: "ES - TRT 17ª Região",
      url_add: "/tribunal/trt17-es/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - ES - Tribunal Regional do Trabalho 17ª Região",
      placeholder: "ES - Tribunal Regional do Trabalho 17ª Região",
      mask: "9999999-99.9999.5.17.9999"
    },
    {
      key: "jfgo",
      label: "GO - TRF1",
      url_add: "/tribunal/jfgo/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - GO -  Justiça Federal",
      placeholder: "GO -  Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjgo",
      label: "GO - Tribunal de Justiça",
      url_add: "/tribunal/tjgo/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - GO - Tribunal de Justiça",
      placeholder: "GO - Tribunal de Justiça",
      mask: "9999999-99.9999.8.09.9999"
    },
    /*{
      key: "tre-go",
      label: "GO - TRE",
      url_add: "/tribunal/tre-go/",
      path: "containers/addTRE_GO"
    },*/
    {
      key: "trt18-go",
      label: "GO - TRT 18ª Região",
      url_add: "/tribunal/trt18-go/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - GO - Tribunal Regional do Trabalho 18ª Região",
      placeholder: "GO - Tribunal Regional do Trabalho 18ª Região",
      mask: "9999999-99.9999.5.18.9999"
    },
    {
      key: "jfms",
      label: "MS - TRF3",
      url_add: "/tribunal/jfms/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MS - Justiça Federal",
      placeholder: "MS - Justiça Federal",
      mask: "9999999-99.9999.4.03.9999"
    },
    {
      key: "tjms",
      label: "MS - Tribunal de Justiça",
      url_add: "/tribunal/tjms/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MS - Tribunal de Justiça",
      placeholder: "MS - Tribunal de Justiça",
      mask: "9999999-99.9999.8.12.9999"
    },
    /*{
      key: "tre-ms",
      label: "MS - TRE",
      url_add: "/tribunal/tre-ms/",
      path: "containers/addTRE_MS"
    },*/
    {
      key: "trt24-ms",
      label: "MS - Tribunal Regional do Trabalho 24ª Região",
      url_add: "/tribunal/trt24-ms/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MS - Tribunal Regional do Trabalho 24ª Região",
      placeholder: "MS - Tribunal de Justiça",
      mask: "9999999-99.9999.5.24.9999"
    },
    {
      key: "jfm",
      label: "MT - TRF1",
      url_add: "/tribunal/jfm/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MT -  Justiça Federal",
      placeholder: "MT -  Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjmt",
      label: "MT - Tribunal de Justiça",
      url_add: "/tribunal/tjmt/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MT - Tribunal de Justiça",
      placeholder: "MT - Tribunal de Justiça",
      mask: "9999999-99.9999.8.11.9999"
    },
    /*{
      key: "tre-mt",
      label: "MT - TRE",
      url_add: "/tribunal/tre-mt/",
      path: "containers/addTRE_MT"
    },*/
    {
      key: "trt23-mt",
      label: "MT - TRT 23ª Região",
      url_add: "/tribunal/trt23-mt/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MT - Tribunal Regional do Trabalho 23ª Região",
      placeholder: "MT - Tribunal Regional do Trabalho 23ª Região",
      mask: "9999999-99.9999.5.23.9999"
    },
    {
      key: "jfto",
      label: "TO - TRF1",
      url_add: "/tribunal/jfto/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - TO -Justiça Federal",
      placeholder: "TO - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjto",
      label: "TO - Tribunal de Justiça",
      url_add: "/tribunal/tjto/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - TO - Tribunal de Justiça",
      placeholder: "TO - Tribunal de Justiça",
      mask: "9999999-99.9999.8.27.9999"
    },
    /*{
      key: "tre-to",
      label: "TO - TRE",
      url_add: "/tribunal/tre-to/",
      path: "containers/addTRE_TO"
    },*/
    {
      key: "trt10-to",
      label: "TO - TRT 10ª Região",
      url_add: "/tribunal/trt10-to/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - TO - Tribunal Regional do Trabalho 10ª Região",
      placeholder: "TO - Tribunal Regional do Trabalho 10ª Região",
      mask: "9999999-99.9999.5.10.9999"
    },
    {
      key: "jfdf",
      label: "DF - TRF1",
      url_add: "/tribunal/jfdf/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - DF - Justiça Federal",
      placeholder: "DF - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjdf",
      label: "DF - Tribunal de Justiça",
      url_add: "/tribunal/tjdf/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - DF - Tribunal de Justiça",
      placeholder: "DF - Tribunal de Justiça",
      mask: "9999999-99.9999.8.07.9999"
    },
    /*{
      key: "tre-df",
      label: "DF - TRE",
      url_add: "/tribunal/tre-df/",
      path: "containers/addTRE_DF"
    },*/
    {
      key: "trt10-df",
      label: "DF - TRT 10ª Região",
      url_add: "/tribunal/trt10-df/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - DF - Tribunal Regional do Trabalho 10ª Região",
      placeholder: "DF - Tribunal Regional do Trabalho 10ª Região",
      mask: "9999999-99.9999.5.10.9999"
    },
    {
      key: "jfro",
      label: "RO - TRF1",
      url_add: "/tribunal/jfro/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RO - Justiça Federal",
      placeholder: "RO - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjro",
      label: "RO - Tribunal de Justiça",
      url_add: "/tribunal/tjro/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RO - Tribunal de Justiça",
      placeholder: "RO - Tribunal de Justiça",
      mask: "9999999-99.9999.8.22.9999"
    },
    /*{
      key: "tre-ro",
      label: "RO - TRE",
      url_add: "/tribunal/tre-ro/",
      path: "containers/addTRE_RO"
    },*/
    {
      key: "trt14-ro",
      label: "RO - TRT 14ª Região",
      url_add: "/tribunal/trt14-ro/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RO - Tribunal Regional do Trabalho 14ª Região",
      placeholder: "RO - Tribunal Regional do Trabalho 14ª Região",
      mask: "9999999-99.9999.5.14.9999"
    },
    {
      key: "jfam",
      label: "AM - TRF1",
      url_add: "/tribunal/jfam/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AM - Justiça Federal",
      placeholder: "AM - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjam",
      label: "AM - Tribunal de Justiça",
      url_add: "/tribunal/tjam/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AM - Tribunal de Justiça",
      placeholder: "AM - Tribunal de Justiça",
      mask: "9999999-99.9999.8.04.9999"
    },
    /*{
      key: "tre-am",
      label: "AM - TRE",
      url_add: "/tribunal/tre-am/",
      path: "containers/addTRE_AM"
    },*/
    {
      key: "trt11-am",
      label: "AM - TRT 11ª Região",
      url_add: "/tribunal/trt11-am/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AM - Tribunal Regional do Trabalho 11ª Região",
      placeholder: "AM - Tribunal Regional do Trabalho 11ª Região",
      mask: "9999999-99.9999.5.11.9999"
    },
    {
      key: "jfac",
      label: "AC - TRF1",
      url_add: "/tribunal/jfac/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AC -  Justiça Federal",
      placeholder: "AC - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjac",
      label: "AC - Tribunal de Justiça",
      url_add: "/tribunal/tjac/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AC -  Tribunal de Justiça",
      placeholder: "AC - Tribunal de Justiça",
      mask: "9999999-99.9999.8.01.9999"
    },
    /*{
      key: "tre-ac",
      label: "AC - TRE",
      url_add: "/tribunal/tre-ac/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - AC - TRE - Tribunal Regional Eleitoral",
      placeholder: "AC - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt14-ac",
      label: "AC - TRT 14ª Região",
      url_add: "/tribunal/trt14-ac/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AC -  Tribunal Regional do Trabalho 14ª Região",
      placeholder: "AC - Tribunal Regional do Trabalho 14ª Região",
      mask: "9999999-99.9999.5.14.9999"
    },
    {
      key: "jfrr",
      label: "RR - TRF1",
      url_add: "/tribunal/jfrr/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RR -  Justiça Federal",
      placeholder: "RR - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjrr",
      label: "RR - Tribunal de Justiça",
      url_add: "/tribunal/tjrr/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RR -  Tribunal de Justiça",
      placeholder: "RR - Tribunal de Justiça",
      mask: "9999999-99.9999.8.23.9999"
    },
    /*{
      key: "tre-rr",
      label: "RR - TRE",
      url_add: "/tribunal/tre-rr/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - RR - TRE - Tribunal Regional Eleitoral",
      placeholder: "RR - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt11-rr",
      label: "RR - TRT 11ª Região",
      url_add: "/tribunal/trt11-rr/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RR -  Tribunal Regional do Trabalho 11ª Região",
      placeholder: "RR - Tribunal Regional do Trabalho 11ª Região",
      mask: "9999999-99.9999.5.11.9999"
    },
    {
      key: "jfpa",
      label: "PA - TRF1",
      url_add: "/tribunal/jfpa/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PA -  Justiça Federal",
      placeholder: "PA - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjpa",
      label: "PA - Tribunal de Justiça",
      url_add: "/tribunal/tjpa/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PA -  Tribunal de Justiça",
      placeholder: "PA - Tribunal de Justiça",
      mask: "9999999-99.9999.8.14.9999"
    },
    /*{
      key: "tre-pa",
      label: "PA - TRE",
      url_add: "/tribunal/tre-pa/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - PA - TRE - Tribunal Regional Eleitoral",
      placeholder: "PA - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt08-pa",
      label: "PA - TRT 8ª Região",
      url_add: "/tribunal/trt08-pa/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PA -  Tribunal Regional do Trabalho 8ª Região",
      placeholder: "PA - Tribunal Regional do Trabalho 8ª Região",
      mask: "9999999-99.9999.5.08.9999"
    },
    {
      key: "jfap",
      label: "AP - TRF1",
      url_add: "/tribunal/jfap/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AP -  Justiça Federal",
      placeholder: "AP - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjap",
      label: "AP - Tribunal de Justiça",
      url_add: "/tribunal/tjap/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AP -  Tribunal de Justiça",
      placeholder: "AP - Tribunal de Justiça",
      mask: "9999999-99.9999.8.03.9999"
    },
    /*{
      key: "tre-ap",
      label: "AP - TRE",
      url_add: "/tribunal/tre-ap/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - AP - TRE - Tribunal Regional Eleitoral",
      placeholder: "AP - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt08-ap",
      label: "AP - TRT 8ª Região",
      url_add: "/tribunal/trt08-ap/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AP -  Tribunal Regional do Trabalho 8ª Região",
      placeholder: "AP - Tribunal Regional do Trabalho 8ª Região",
      mask: "9999999-99.9999.5.08.9999"
    },
    {
      key: "jfma",
      label: "MA - TRF1",
      url_add: "/tribunal/jfma/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MA -  Justiça Federal",
      placeholder: "MA - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjma",
      label: "MA - Tribunal de Justiça",
      url_add: "/tribunal/tjma/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MA -  Tribunal de Justiça",
      placeholder: "MA - Tribunal de Justiça",
      mask: "9999999-99.9999.8.10.9999"
    },
    /*{
      key: "tre-ma",
      label: "MA - TRE",
      url_add: "/tribunal/tre-ma/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - MA - TRE - Tribunal Regional Eleitoral",
      placeholder: "MA - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt16-ma",
      label: "MA - TRT 16ª Região",
      url_add: "/tribunal/trt16-ma/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - MA -  Tribunal Regional do Trabalho 16ª Região",
      placeholder: "MA - Tribunal Regional do Trabalho 16ª Região",
      mask: "9999999-99.9999.5.16.9999"
    },
    {
      key: "jfpi",
      label: "PI - TRF1",
      url_add: "/tribunal/jfpi/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PI -  Justiça Federal",
      placeholder: "PI - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjpi",
      label: "PI - Tribunal de Justiça",
      url_add: "/tribunal/tjpi/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PI -  Tribunal de Justiça",
      placeholder: "PI - Tribunal de Justiça",
      mask: "9999999-99.9999.8.18.9999"
    },
    /*{
      key: "tre-pi",
      label: "PI - TRE",
      url_add: "/tribunal/tre-pi/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - PI - TRE - Tribunal Regional Eleitoral",
      placeholder: "PI - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt22-pi",
      label: "MA - TRT 22ª Região",
      url_add: "/tribunal/trt22-pi/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PI -  Tribunal Regional do Trabalho 22ª Região",
      placeholder: "PI - Tribunal Regional do Trabalho 22ª Região",
      mask: "9999999-99.9999.5.22.9999"
    },
    {
      key: "jfce",
      label: "CE - TRF5",
      url_add: "/tribunal/jfce/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - CE -  Justiça Federal",
      placeholder: "CE - Justiça Federal",
      mask: "9999999-99.9999.4.05.9999"
    },
    {
      key: "tjce",
      label: "CE - Tribunal de Justiça",
      url_add: "/tribunal/tjce/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - CE -  Tribunal de Justiça",
      placeholder: "CE - Tribunal de Justiça",
      mask: "9999999-99.9999.8.06.9999"
    },
    /*{
      key: "tre-ce",
      label: "CE - TRE",
      url_add: "/tribunal/tre-ce/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - CE - TRE - Tribunal Regional Eleitoral",
      placeholder: "CE - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt07-ce",
      label: "CE - TRT 7ª Região",
      url_add: "/tribunal/trt07-ce/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - CE -  Tribunal Regional do Trabalho 7ª Região",
      placeholder: "CE - Tribunal Regional do Trabalho 7ª Região",
      mask: "9999999-99.9999.5.07.9999"
    },
    {
      key: "jfrn",
      label: "RN - TRF5",
      url_add: "/tribunal/jfrn/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RN -  Justiça Federal",
      placeholder: "RN - Justiça Federal",
      mask: "9999999-99.9999.4.05.9999"
    },
    {
      key: "tjrn",
      label: "RN - Tribunal de Justiça",
      url_add: "/tribunal/tjrn/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RN -  Tribunal de Justiça",
      placeholder: "RN - Tribunal de Justiça",
      mask: "9999999-99.9999.8.20.9999"
    },
    /*{
      key: "tre-rn",
      label: "RN - TRE",
      url_add: "/tribunal/tre-rn/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - RN - TRE - Tribunal Regional Eleitoral",
      placeholder: "RN - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt21-rn",
      label: "RN - TRT 21ª Região",
      url_add: "/tribunal/trt21-rn/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - RN -  Tribunal Regional do Trabalho 21ª Região",
      placeholder: "RN - Tribunal Regional do Trabalho 21ª Região",
      mask: "9999999-99.9999.5.21.9999"
    },    
    {
      key: "jfpb",
      label: "PB - TRF5",
      url_add: "/tribunal/jfpb/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PB -  Justiça Federal",
      placeholder: "PB - Justiça Federal",
      mask: "9999999-99.9999.4.05.9999"
    },
    {
      key: "tjpb",
      label: "PB - Tribunal de Justiça",
      url_add: "/tribunal/tjpb/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PB -  Tribunal de Justiça",
      placeholder: "PB - Tribunal de Justiça",
      mask: "9999999-99.9999.8.15.9999"
    },
    /*{
      key: "tre-pb",
      label: "PB - TRE",
      url_add: "/tribunal/tre-pb/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - PB - TRE - Tribunal Regional Eleitoral",
      placeholder: "PB - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt13-pb",
      label: "PB - TRT 13ª Região",
      url_add: "/tribunal/trt13-pb/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PB -  Tribunal Regional do Trabalho 13ª Região",
      placeholder: "PB - Tribunal Regional do Trabalho 13ª Região",
      mask: "9999999-99.9999.5.13.9999"
    },
    {
      key: "jfpb-pje",
      label: "PB - Justiça Federal PJE",
      url_add: "/tribunal/jfpb-pje/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PB -  Justiça Federal PJE",
      placeholder: "PB - Justiça Federal PJE",
      mask: "9999999-99.9999.4.05.9999"
    }, 
    {
      key: "jfpe",
      label: "PE - TRF5",
      url_add: "/tribunal/jfpe/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PE -  Justiça Federal",
      placeholder: "PE - Justiça Federal",
      mask: "9999999-99.9999.4.05.9999"
    },
    {
      key: "tjpe",
      label: "PE - Tribunal de Justiça",
      url_add: "/tribunal/tjpe/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PE -  Tribunal de Justiça",
      placeholder: "PE - Tribunal de Justiça",
      mask: "9999999-99.9999.8.17.9999"
    },
    /*{
      key: "tre-pe",
      label: "PE - TRE",
      url_add: "/tribunal/tre-pe/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - PE - TRE - Tribunal Regional Eleitoral",
      placeholder: "PE - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt06-pe",
      label: "PE - TRT 6ª Região",
      url_add: "/tribunal/trt06-pe/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - PE -  Tribunal Regional do Trabalho 6ª Região",
      placeholder: "PE - Tribunal Regional do Trabalho 6ª Região",
      mask: "9999999-99.9999.5.06.9999"
    },
    {
      key: "jfal",
      label: "AL - TRF5",
      url_add: "/tribunal/jfal/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AL -  Justiça Federal",
      placeholder: "AL - Justiça Federal",
      mask: "9999999-99.9999.4.05.9999"
    },
    {
      key: "tjal",
      label: "AL - Tribunal de Justiça",
      url_add: "/tribunal/tjal/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AL -  Tribunal de Justiça",
      placeholder: "AL - Tribunal de Justiça",
      mask: "9999999-99.9999.8.02.9999"
    },
    /*{
      key: "tre-al",
      label: "AL - TRE",
      url_add: "/tribunal/tre-al/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - AL - TRE - Tribunal Regional Eleitoral",
      placeholder: "AL - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt19-al",
      label: "AL - TRT 19ª Região",
      url_add: "/tribunal/trt19-al/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - AL -  Tribunal Regional do Trabalho 19ª Região",
      placeholder: "AL - Tribunal Regional do Trabalho 19ª Região",
      mask: "9999999-99.9999.5.1\\9.9999"
    },
    {
      key: "jfse",
      label: "SE - TRF5",
      url_add: "/tribunal/jfse/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SE -  Justiça Federal",
      placeholder: "SE - Justiça Federal",
      mask: "9999999-99.9999.4.05.9999"
    },
    {
      key: "tjse",
      label: "SE - Tribunal de Justiça",
      url_add: "/tribunal/tjse/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SE -  Tribunal de Justiça",
      placeholder: "SE - Tribunal de Justiça",
      mask: "9999999-99.9999.8.25.9999"
    },
    /*{
      key: "tre-se",
      label: "SE - TRE",
      url_add: "/tribunal/tre-se/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - SE - TRE - Tribunal Regional Eleitoral",
      placeholder: "SE - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt20-se",
      label: "SE - TRT 20ª Região",
      url_add: "/tribunal/trt20-se/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - SE -  Tribunal Regional do Trabalho 20ª Região",
      placeholder: "SE - Tribunal Regional do Trabalho 20ª Região",
      mask: "9999999-99.9999.5.20.9999"
    }, 
    {
      key: "jfba",
      label: "BA - TRF1",
      url_add: "/tribunal/jfba/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - BA -  Justiça Federal",
      placeholder: "BA - Justiça Federal",
      mask: "9999999-99.9999.4.01.9999"
    },
    {
      key: "tjba",
      label: "BA - Tribunal de Justiça",
      url_add: "/tribunal/tjba/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - BA -  Tribunal de Justiça",
      placeholder: "BA - Tribunal de Justiça",
      mask: "9999999-99.9999.8.05.9999"
    },
    /*{
      key: "tre-ba",
      label: "BA - TRE",
      url_add: "/tribunal/tre-ba/",
      path: "containers/addTGeneric",
      title: "Cadastrar processo - BA - TRE - Tribunal Regional Eleitoral",
      placeholder: "BA - TRE",
      mask: "9999999-99.9999.8.01.9999"
    },*/
    {
      key: "trt05-ba",
      label: "BA - TRT 5ª Região",
      url_add: "/tribunal/trt05-ba/",
      path: "containers/addGeneric",
      title: "Cadastrar processo - BA -  Tribunal Regional do Trabalho 5ª Região",
      placeholder: "BA - Tribunal Regional do Trabalho 5ª Região",
      mask: "9999999-99.9999.5.05.9999"
    },     
];
export default tribunalTypes;
