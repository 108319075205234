import React from 'react';
import Select from 'react-select';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import eDocsService from '../../../services/eDocsService';

export default class Area extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
    super( props );
    this.modifiedArea = this.props.modifiedArea.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.addArea = this.addArea.bind(this);
    this.deleteArea = this.deleteArea.bind(this);
    this.state = {
        area: {value: 0, label: ""},
        areaList: []
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps.area_id !== this.state.area.value ) {
      this.setState({
        area: {value: nextProps.area_id, label: ""}
      });
      this.populateSelect();

    }
  }

  addArea (e) {
    this.eDocsService = new eDocsService();
    let area = {
      name: document.getElementById("areaName").value
    }
    this.eDocsService.addArea( area ).then(res =>{

      this.eDocsService.areaList( ).then(res =>{

            document.getElementById("areaName").value = "";

            this.setState({
              areaList: res,
            });

      });

    });
  }

  deleteArea (e) {
    this.eDocsService = new eDocsService();

    this.eDocsService.tipoList( document.getElementById("areaSettings").value ).then(res =>{
          let tipoList = res;
          if ( tipoList.length > 0 ) {
            alert ("Não é possível apagar Áreas que não estão vazias!");
          }else{
            let id = document.getElementById("areaSettings").value;

            //console.log( id );

            this.eDocsService.deleteArea( id ).then(res =>{

              this.eDocsService.areaList( ).then(res =>{

                    this.setState({
                      areaList: res,
                    });

                    if( id === this.state.area.value ){
                      this.setState({
                        area: {value: 0, label: ""},
                      });
                      document.getElementById("area_id").value = "";
                    }
                    setTimeout(() => {
                      this.modifiedArea();
                    }, 200);

              });

            });
          }
    });


  }

  populateSelect(){
    document.body.style.cursor = "wait";
    let areaList = []; let area = this.state.area;

    this.eDocsService = new eDocsService();

      this.eDocsService.areaList( ).then(res =>{
            areaList = res;
            //console.log(this.state.area)
            //areaList.unshift({value: -9999, label: "Não Definida"});
            areaList.map((value, index, array )=>{

              if ( value.value === this.state.area.value ) {
                area = value;
              }

              return "";

            });

            this.setState({
              areaList: areaList,
              area: area,
            });
            document.body.style.cursor = "default";
      });


  }

 componentDidMount( ) { this._isMounted = true;
   this.populateSelect();
 }

  selectChange = (selected, actionMeta) => {

        let pops = [];
        pops[ actionMeta.name ] = selected;

        if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

          if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
            this.setState( pops );
          }

        }

        document.getElementById("area_id").value = selected.value;

        this.modifiedArea();

  }

  render() {
      var arrTen = [];
      for (var k = 0; k < this.state.areaList.length; k++) {
          arrTen.push(<option key={k} value={this.state.areaList[k].value}> {this.state.areaList[k].label} </option>);
      }

      var url_base = localStorage.getItem('url_base');

      return <div className="">
                    <div className="form-group col-md-4 text-left">
                            <label className="col-md-12">
                                    Área
                                    <Popover
                                        isOpen={this.state.isPopoverOpen}
                                        position={['top', 'right', 'left', 'bottom']}
                                        padding={10}
                                        onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                        content={({ position, targetRect, popoverRect }) => (
                                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                position={position}
                                                targetRect={targetRect}
                                                popoverRect={popoverRect}
                                                arrowColor={'#94bcd8'}
                                                arrowSize={10}
                                            >
                                                <div className="popover-box"
                                                    onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                                >
                                                      Clique em <i className="material-icons settings">settings</i> para incluir áreas como Trabalhista, Previdenciário, Cível, ADM e outras.
                                                </div>
                                            </ArrowContainer>
                                        )}
                                    >
                                        <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                    </Popover>
                            </label>
                            <input type="hidden" id="area_id" name="area_id" />
                            <Select
                                className="area col-md-10"
                                placeholder="Selecione"
                                options={this.state.areaList}
                                value={(this.state.area.value !== 0 && this.state.area.value !== "" )? this.state.area : null}
                                name="area"
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" data-toggle="modal" data-target="#areaModal"  title="Clique para adicionar ou excluir opções">settings</i>
                      </div>
                      <div className="modal fade" id="areaModal" tabIndex="-1" role="dialog" aria-labelledby="areaModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="areaModalLabel">Incluir ou Excluir Área</h5>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div className="form-group">
                                  <label htmlFor="areaName" className="col-form-label col-md-12">Nova Área: </label>
                                  <input type="text" className="form-control" id="areaName" />
                                  <span className="btn btn-primary" id="add-area" onClick={this.addArea} >Incluir</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="areaSettings" className="col-form-label col-md-12">Opções: </label>
                                    <select multiple id="areaSettings" className="area settings">
                                      {arrTen}
                                    </select>
                                    <i className="material-icons delete" id="delete-area" title="remover item selecionado" onClick={this.deleteArea} >delete</i>
                                </div>
                              </form>
                            </div>
                            <div className="modal-footer">
                              <p className="info">As alterações são salvas automaticamente</p>
                              <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                            </div>
                          </div>
                        </div>
                      </div>
             </div>
  }

}
