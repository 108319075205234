import React from 'react';
import Header from "./containers/header";
import Footer from "./containers/footer";
import Alert from './containers/alert';
import { Link } from 'react-router-dom';
import AuthService from '../services/AuthService';
import './Login.css';

export default class SignUp extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
        this._isMounted = true;

        document.addEventListener("cancelAlert", (e) => {
            this.setState({
            showAlert: false
            });
        });
  }

  constructor() {
      super();
      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.Auth = new AuthService();

      this.state = {
        message: "",
        showAlert: false
      }

      window.scrollTo(0,0);

  }

  componentWillMount(){
    if(this.Auth.loggedIn())
        this.props.history.replace('/');
  }


  handleFormSubmit(e){
       e.preventDefault();

       if (!this.state.name || !this.state.email || !this.state.password || !this.state.password_confirmation || !this.state.city || !this.state.uf ) {
         //alert( 'Favor Preencha os campos abaixo!' );
         this.setState({
            showAlert: true,
            message: 'Favor Preencha os campos abaixo!'
          });
       }else if ( this.state.password !== this.state.password_confirmation ) {
         //alert( 'Senha e confirmação não conferem!' );
         this.setState({
            showAlert: true,
            message: 'Senha e confirmação não conferem!'
          });
       }else{

           this.Auth.register(this.state.name, this.state.email, this.state.password, this.state.city, this.state.uf)
               .then(res =>{
                  this.props.history.replace('/');
               })
               .catch(err =>{
                   //alert(err);
                   this.setState({
                    showAlert: true,
                    message: err
                  });
               })

      }
   }

   handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }


  render() {
      return <div>
                  <Header />
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <div className="center">
                      <div className="card">
                          <h1>Criar Conta</h1>
                          <form>
                              <input
                                  className="form-control form-item"
                                  placeholder="Nome"
                                  name="name"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <input
                                  className="form-control form-item"
                                  placeholder="Email"
                                  name="email"
                                  type="email"
                                  onChange={this.handleChange}
                              />
                              <small id="emailHelp" className="form-text text-muted">Nós nunca compartilharemos seu email com ninguém.</small>
                              <input
                                  className="form-control form-item"
                                  placeholder="Informe sua senha"
                                  name="password"
                                  type="password"
                                  onChange={this.handleChange}
                              />
                              <input
                                  className="form-control form-item"
                                  placeholder="Confirme sua senha"
                                  name="password_confirmation"
                                  type="password"
                                  onChange={this.handleChange}
                              />
                              <input
                                  className="form-control form-item"
                                  placeholder="Cidade"
                                  name="city"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <input
                                  className="form-control form-item uf"
                                  placeholder="Estado"
                                  name="uf"
                                  maxLength="2"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <div className="isoInputWrapper isoLeftRightComponent">
                                    <input
                                        className="btn btn-primary form-submit"
                                        value="Entrar"
                                        type="submit"
                                        onClick={this.handleFormSubmit.bind(this)}
                                    />
                                    <Link to="/login">
                                      Já tem uma conta, faça Login!
                                    </Link>
                              </div>
                          </form>
                      </div>
                  </div>
             </div>
  }

}
