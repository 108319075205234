import React from 'react';
import InputMask from 'react-input-mask';

export default class TRE_ESLawsuitInput extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <InputMask {...this.props} mask="9999999-99.9999.6.08.9999" maskChar="_" />;
  }
}
