import React from 'react';
import Chart from "react-google-charts";

export default class donutChart extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {

      super( props );

      this.state = {
          count_atrasados: props.count_atrasados,
          count_hoje: props.count_hoje,
          count_futuros: props.count_futuros,
          label_title: props.label_title,
          label_value: props.label_value,
          color: props.color,
          title: props.title,
          class_title: props.class_title,
          height: props.height,
          show_future: props.show_future,
          pasteTitle: props.pasteTitle
      }

  }

  componentWillReceiveProps(nextProps) {
    

    if ( ( nextProps.count_atrasados !== this.props.count_atrasados || nextProps.count_hoje !== this.props.count_hoje || nextProps.count_futuros !== this.props.count_futuros) && this._isMounted ) {
      //console.log("passou");
      this.setState({
        count_atrasados: nextProps.count_atrasados,
        count_hoje: nextProps.count_hoje,
        count_futuros: nextProps.count_futuros,
        label_title: nextProps.label_title,
        label_value: nextProps.label_value,
        color: nextProps.color,
        title: nextProps.title,
        class_title: nextProps.class_title,
        height: nextProps.height,
        show_future: nextProps.show_future,
        pasteTitle: nextProps.pasteTitle,
        chart_data: [
          [this.state.label_title, nextProps.label_value],
          ["Hoje", nextProps.count_hoje],
          [nextProps.pasteTitle, nextProps.count_atrasados],
          ["Futuros", nextProps.count_futuros],
        ]
      });
    }

  }

  componentDidMount(){
    this._isMounted = true;

    this.setState({
        chart_data: [
          [this.state.label_title, this.state.label_value],
          ["Hoje", this.state.count_hoje],
          [this.state.pasteTitle, this.state.count_atrasados],
          ["Futuros", this.state.count_futuros],
        ],
        chart_options: {
          title: "",
          pieHole: 0.8,
          backgroundColor: {fill: this.state.color, stroke: this.state.color },
          pieSliceBorderColor : this.state.color,
          legend: 'none',
          is3D: false,
          pieSliceText: 'none',
          slices: {
            0: { color: 'green' },
            1: { color: 'red' },
            2: { color: 'orange' }
          }
        }
    });

  }

  doReport = () => {
    if( this.props.url !== ''){
      this.props.history.push( this.props.url );
      //window.location.href =  localStorage.getItem('url_base')+this.props.url ;
    }
  }

  render() {
        let future = null;
        if( this.state.show_future === true ){
          future = <div className="info"><span className="futuros">{this.state.count_futuros}</span>Amanhã</div>
        }

        return (
                <div style={{background: this.state.color}} className={this.state.class_title+" donut-chart"} onClick={()=>{this.doReport()} }>
                      <h2>{this.state.title}</h2>
                      <div className="chart-box">
                            <Chart
                                chartType="PieChart"
                                width="150px"
                                height={this.state.height+"px"}
                                data={this.state.chart_data}
                                options={this.state.chart_options}
                            />
                            <div className="chart" style={{top: this.props.top }}>
                                  <span className="number">{this.state.count_hoje+this.state.count_atrasados+this.state.count_futuros}</span>
                                  <span className="total">Total</span>
                            </div>

                      </div>
                      <div className="info"><span className="atrasados">{this.state.count_atrasados}</span>{this.state.pasteTitle}</div>
                      <div className="info"><span className="hoje">{this.state.count_hoje}</span>Hoje</div>
                      {future}
                </div>
        );
  }
}
