import React from 'react';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import 'antd/dist/antd.css';
import { Divider } from 'antd';
import FlashMessage from "./containers/FlashMessage";

import Loading from './containers/loading';


export default class Partes extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(...args) {
    super(...args)

    window.scrollTo(0,0);

    this.state = {
        partes: [],
        loadingVisible: false,
        loadingMessage: []
    }
  }

  componentDidMount( ) { 
      this._isMounted = true;
      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

  }

  componentDidUpdate(){

  }


  render() {

      var url_base = localStorage.getItem('url_base');

      return <div className="sheet">
                <FlashMessage time={ new Date().getTime() } />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Novidades</h1>
                        </section>
                        <div className="box-body">
                            <div className="news-item">
                              <span className="date">13/09/22</span> - <span>
                              Possibilitamos o cadastro automático dos processos Administrativos no INSS. São os mesmos dados exigidos para acesso no site do Meu INSS.    
                              </span>
                              <img alt="cadastro automático INSS" src={url_base+"/images/image008.jpg"}></img>
                              <span>
                              B - Foram incluídas novas abas (Pedidos e Recursos)  com busca automática de “novas”  solicitações, andamentos, exigências, atualizadas automaticamente.    
                              </span>
                              <img alt="pedidos recursos" src={url_base+"/images/image009.png"}></img>
                              <span>
                              C - Em casos de ausência de um dos dados exigidos, poderá cadastrá-los de forma Manual (sem monitoramento).    
                              </span>
                              <img alt="inss manual" src={url_base+"/images/image010.png"}></img>
                            </div>
                            <hr />
                            <div className="news-item">
                              <span className="date">01/08/22</span> - <span>
                                Agora é possível cadastrar os processos por dentro das Publicações e acessar a pasta do processo clicando no número dos já cadastrados. 
                              </span>
                              <img alt="botão cadastrar processo na tela de publicações" src={url_base+"/images/publicacoes.jpeg"}></img>
                              <img alt="link para a tela de detalhes de processos" src={url_base+"/images/link_publicacoes.jpeg"}></img>
                            </div>
                            <hr />
                            <div className="news-item">
                              <span className="date">01/08/22</span> - <span>
                                Possibilidade de "Incluir" os andamentos dos processos não monitorados, (cópia e cola).
                              </span>
                              <img alt="botão de adicionar andamentos manualmente para processos não monitorados" src={url_base+"/images/incluir-andamento-manual.jpg"}></img>
                            </div>
                            <hr />
                        </div>
                  </div>
                </div>
             </div>
  }

}
