import React, { Component } from 'react';
import { Switch } from 'antd';

import LevelService from '../../../services/LevelsService';

export default class extends Component {

  constructor( props ){
      super();

      this.setActive = this.setActive.bind(this);

      this.state = {
        switch: (props.active === 1) ? true : false,
        area: props.area,
        user_token: props.user_token
      }
  }

  componentWillReceiveProps(nextProps) {

      if ( nextProps.active !== this.props.active ) {

        this.setState({
          switch: ( nextProps.active === 1) ? true : false,
          area: nextProps.area,
          user_token: nextProps.user_token
        });

      }
  }

  setActive( allow ){
    this.levelService = new LevelService();

    let area_elements = document.getElementsByClassName("switch-area-item");

   //console.log(area_elements);

    let permissions = {lawsuit_areas : [] };

    for (let n = area_elements.length - 1; n >= 0; n--) {
         if ( area_elements[n].dataset.area !== this.state.area.toString() ) {
           const allowed = (area_elements[n].value === "true") ? 1: 0;
           permissions.lawsuit_areas.push( {lawsuit_area_id: area_elements[n].dataset.area, allowed: allowed } );
         }
     }

     permissions.lawsuit_areas.push( { lawsuit_area_id: this.state.area, allowed: (allow) ? 1 : 0 } );

     //console.log(permissions);

    this.levelService.setAreas( permissions, this.state.user_token ).then(res =>{
      //console.log("permissão setada");
      //console.log(res);

    });
  }

  onSwitchChange = (checked) => {
    if ( checked ){
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: true,
      });

      this.setActive(true);

    }else{
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: false,
      });

      this.setActive(false);

    }
  }

  render() {

    return (
      <div>
        <input type="hidden" className="switch-area-item" data-area={this.state.area} value={this.state.switch} />
        <Switch title={(this.state.switch)? "Desbloqueado": "Bloqueado"} checked={this.state.switch} onChange={ this.onSwitchChange }  />
      </div>
    );
  }
}
