import React from 'react';
import { Modal, Button } from 'antd';
import MyPopover from '../containers/mypopover';
import Alert from '../containers/alert';
import Confirm from '../containers/confirm';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Loading from '../containers/loading';
import FlashMessage from '../containers/FlashMessage';

import PublicationsService from '../../services/PublicationsService';
import AddressService from '../../services/AddressService';
import AuthService from '../../services/AuthService';

export default class ConfigPublication extends React.Component { 
  _isMounted = false;
  _numPub = 0;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);

    this.authService = new AuthService();

    this.handleChange = this.handleChange.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.openAddPublication = this.openAddPublication.bind(this);
    this.openEditPublications = this.openEditPublications.bind(this);
    this.handlePublicationModalCancel = this.handlePublicationModalCancel.bind(this);

    this.state = {
      loadingVisible: false,
      loadingMessage: [],
      message: "",
      showAlert: false,
      showConfirm: false,
      free: "",
      publications: [],
      publicacoes_contratadas: props.publicacoes_contratadas,
      publicationToDelete: "",
      publicationModal: false,
      loading: false,
      name: "",
      oab: "",
      token: "",
      abrangencias: [],
      abrangencia: null
    }

  }

  componentWillReceiveProps( nextProps ){
    this.setState({
        publicacoes_contratadas: nextProps.publicacoes_contratadas
    });
  }

  componentDidMount( ) { 
    this._isMounted = true;

    this.AddressService = new AddressService();

    document.addEventListener("cancelAlert", (e) => {
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
    });


      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,          
          });
        }
      });

      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
          });

          if( this.state.publicationToDelete !== "" ){
              this.deletePublication(this.state.publicationToDelete, true);
          }
        }
      });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });
    this.PublicationsService = new PublicationsService();

    this.listPublications();

    this.AddressService.getEstado( ).then(res =>{

            if (this._isMounted) {
                  let opt_estados = res;

                  this.setState({
                    opt_estados: opt_estados,
                  });
            }

      });

    this.PublicationsService.abrangenciasList().then( abrangencias => {
        abrangencias.unshift( {'label': 'Todos', 'value': -1} );
        this.setState({
            abrangencias
        });
    });

  }

  listPublications () {
    this.PublicationsService.list().then( publications => {
        this.setState({
            publications
        });
    });
  }

  openAddPublication(){
    this.setState({
      publicationModal: true
    });
  }

  openEditPublications( token, name, oab, uf ){

      let estado_selecionado = this.state.opt_estados.filter( (item) =>{ return item.uf === uf })[0];

      this.setState({
        publicationModal: true,
        oab: oab,
        name: name,
        token: token,
        opt_estado: estado_selecionado
      });


  }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }
  }

  handleOk(){

    let abran = [];

    if( this.state.abrangencia.length === 1 && this.state.abrangencia[0].value === -1){
      for(let i=1; i<this.state.abrangencias.length; i++){
        abran.push( this.state.abrangencias[i].value );
      }
    }else{
      for(let i=0; i<this.state.abrangencia.length; i++){
        abran.push( this.state.abrangencia[i].value );
      }
    }

    let data = {
      name: this.state.name,
      oab: this.state.oab,
      uf: this.state.opt_estado.uf,
      abrangencias: abran
    }

    this.PublicationsService = new PublicationsService();

    this.setState({
      loadingVisible: true,
      loadingMessage: ["As alterações estão sendo salvas.", "Por gentileza, aguarde...", "Tempo estimado de 45 a 90 segundos"]
    });

    if(this.state.token !== ""){

      this.PublicationsService.edit( this.state.token, data ).then(res =>{

          if( res.success === true ){
              //alert("Comentário excluído com sucesso");
              this.listPublications();
              this.setState({
                publicationModal: false
              });
              localStorage.setItem('alert_message', res.message );
                                  localStorage.setItem('alert_type', 'alert-success');
                                  let time = new Date().getTime();
                                  localStorage.setItem('alert_time', time );
                                  setTimeout(() => {
                                    this.setState({
                                      updated: time,
                                      loadingVisible: false
                                    });
                                  }, 300);

          }else{
              //alert(res.message);
              localStorage.setItem('alert_message', res.message );
                                  localStorage.setItem('alert_type', 'alert-danger');
                                  let time = new Date().getTime();
                                  localStorage.setItem('alert_time', time );
                                  setTimeout(() => {
                                    this.setState({
                                      updated: time,
                                      loadingVisible: false
                                    });
                                  }, 300);
          }

      });
      
    }else{

      this.PublicationsService.add( data ).then(res =>{

          if( res.success === true ){
              //alert("Comentário excluído com sucesso");
              this.listPublications();
              this.setState({
                publicationModal: false
              });

              localStorage.setItem('alert_message', res.message );
                                  localStorage.setItem('alert_type', 'alert-success');
                                  let time = new Date().getTime();
                                  localStorage.setItem('alert_time', time );
                                  setTimeout(() => {
                                    this.setState({
                                      updated: time,
                                      loadingVisible: false
                                    });
                                  }, 300);

          }else{
              //alert(res.message);
              localStorage.setItem('alert_message', res.message );
                                  localStorage.setItem('alert_type', 'alert-danger');
                                  let time = new Date().getTime();
                                  localStorage.setItem('alert_time', time );
                                  setTimeout(() => {
                                    this.setState({
                                      updated: time,
                                      loadingVisible: false
                                    });
                                  }, 300);
          }

      });

    }

  }

  handlePublicationModalCancel(){
    this.setState({
      publicationModal: false,
      name: "",
      oab: "",
      token: "",
      opt_estado: null,
      abrangencia: null
    });
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   deletePublication( token, r = false ) {

        //let result = window.confirm( "Deseja excluir definitivamente este comentário?");

        if ( r ) {

          this.PublicationsService = new PublicationsService();

          this.PublicationsService.delete( token ).then(res =>{

              if( res.success === true ){
                  //alert("Comentário excluído com sucesso");
                  this.listPublications();
              }else{
                  //alert(res.message);
              }

              this.setState({
                publicationToDelete: "",
              });

          });

        }else{
          this.setState({
            showConfirm: true,
            publicationToDelete: token,
            message: "Atenção, o nome excluido não terá mais suas publicações pesquisadas! Deseja excluir definitivamente este nome das publicações?"
          });
        }

  }

   publicationItem = ( {record, index}, type ) => {

      this._numPub++;

      //let oab = record.oab.split('|')[0];
      //let uf = record.oab.split('|')[1];


      /* <span className="pointer pull-left bt-edit">
              <i className={ "fa fa-pencil-square-o" } onClick={ () => this.openEditPublications(record.token, record.name, oab, uf) }></i>
              <span className="edit-label">Editar</span>
      </span> */


      return (
        <tr key={record.token} className={type}>
              <td className="table_publication_actions actions action">
                {index+1}
              </td>
              <td className="description">
                {record.name}
              </td>
              <td className=""  >
                { record.oab }
              </td>
              <td className="table_publication_actions actions action">
                    <i className="fa fa-trash" data-token={record.token} onClick={() => this.deletePublication(record.token)}></i>
              </td>
        </tr>


      );
  };

  emptyName( {n, index}, type ) {
      this._numPub++;
      return (
        <tr key={Math.random()} className={type}>
              <td className="table_publication_actions actions action">
                {this._numPub}
              </td>
              <td className="description">
                
              </td>
              <td className=""  >
                
              </td>
              <td className="table_publication_actions actions action">
              </td>
        </tr>
      );
  }

  render() {
      this._numPub = 0; 

      let publications_button = null; let qtd = 0; let emptyPublications = [];

      if(this.state.publications.length < this.state.publicacoes_contratadas ){
        publications_button = <span className="btn btn-info add-publication" onClick={this.openAddPublication}>
                                      Incluir Nomes
                              </span>
      }

      if( Array.isArray(this.state.publications) && typeof this.state.publications !== "undefined" && this.state.publications.length < this.state.publicacoes_contratadas ) {

          qtd = this.state.publicacoes_contratadas - this.state.publications.length;

      }

      for( qtd; qtd > 0; qtd--){
        emptyPublications.unshift(qtd);
      }

      /*const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

        if( loggedUser.token !== "a332805e-ec30-403b-a92b-e19c8007d70d" ){
          return null;
        }*/

      return <div className="">
                  <FlashMessage time={ new Date().getTime() } />
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <Confirm show={this.state.showConfirm} text={this.state.message} />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                  <h5>
                          Publicações Contratadas
                          <MyPopover icon="white" text='Somente os nomes cadastrados abaixo, serão pesquizados nos Diários Oficiais.' />
                          {(this.state.publicacoes_contratadas > 0) ? "("+this.state.publicacoes_contratadas+")" : ""}
                          {publications_button}
                    </h5>
                    <table>
                              <thead>
                                      <tr>
                                            <th className="table_publication_actions actions action">Nº</th>
                                            <th className="table_publication_name">
                                                <div>Nome</div>
                                            </th>
                                            <th className="table_oab">
                                                <div>OAB</div>
                                            </th>
                                            <th className="table_publication_actions actions action">
                                                <div>Ações</div>
                                            </th>
                                      </tr>
                              </thead>
                              <tbody>
                                      { ( Array.isArray(this.state.publications) && typeof this.state.publications !== "undefined") ? this.state.publications.map( (record, index) => this.publicationItem({ record, index }, 'publication-item') ) : null }
                                      { ( Array.isArray(emptyPublications) ) ? emptyPublications.map( (n, index) => this.emptyName({ n, index }, 'publication-item') ) : null }                                    
                              </tbody>
                    </table>
                    <Modal
                        visible={this.state.publicationModal}
                        className="modalSettings"
                        title="Incluir Nomes"
                        width="450px"
                        onOk={this.handleOk}
                        onCancel={this.handlePublicationModalCancel}
                        footer={[
                          <Button key="submit" id="handleOk" type="primary" loading={this.state.loading} onClick={this.handleOk}>
                            Salvar
                          </Button>,
                          <Button key="back" className="btn-danger" onClick={this.handlePublicationModalCancel}>
                            Fechar
                          </Button>
                        ]}
                      >
                              <div className="form-group">
                                <label htmlFor="name" className="col-form-label col-md-12">Termo (Nome do Advogado, PF ou PJ): </label>
                                <input type="text" name="name" className="form-control" value={this.state.name} onChange={this.handleChange} id="publicationName" />
                              </div>
                              <div className="form-group">
                                      <label htmlFor="oab" className="col-form-label col-md-12">OAB (somente números): </label>
                                      <input type="text" name="oab" className="form-control" value={this.state.oab} onChange={this.handleChange} id="oab" />
                                      <label htmlFor="opt_estado" className="col-form-label col-md-12">Estado de Inscrição da OAB:</label>
                                      <Select
                                          className="opt_estado col-md-9"
                                          name="opt_estado"
                                          value={this.state.opt_estado}
                                          options={this.state.opt_estados}
                                          placeholder="Selecione"
                                          onChange={this.selectChange}
                                        />
                                        <label htmlFor="abrangencia" className="col-form-label col-md-12">Abrangências:</label>
                                        <Select
                                          className="abrangencia col-md-9"
                                          name="abrangencia"
                                          isMulti
                                          value={this.state.abrangencia}
                                          options={this.state.abrangencias}
                                          placeholder="Selecione"
                                          onChange={this.selectChange}
                                        />
                               </div>
                    </Modal>
             </div>
  }

}
