import React from 'react';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from "./containers/footer";
import 'antd/dist/antd.css';
import { Divider } from 'antd';
import FlashMessage from "./containers/FlashMessage";

import Loading from './containers/loading';
import Confirm from './containers/confirm';

import EventService from '../services/EventService';

export default class Eventos extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(...args) {
    super(...args)
    this.eventService = new EventService();


    this.delete = this.delete.bind(this);

    window.scrollTo(0,0);

    this.state = {
        eventos: [],
        loadingVisible: false,
        loadingMessage: [],
        showConfirm: false,
        message: "",
        eventToDelete: false
    }
  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });
        }
      });

      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });
          this.delete(this.state.eventToDelete, true);
        }
      });

      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

      this.getEventos();

  }

  componentDidUpdate(){

  }

  getEventos(){
    this.eventService = new EventService();

    this.eventService.list( '1900-01-01', '2022-05-11' ).then(res =>{
      if (this._isMounted) {
          this.setState({
            eventos: res,
          });
      }

    });
  }

  delete( e, result=false ) {
    document.body.style.cursor = "wait";

   //console.log(e.target.dataset.event_token);

    //let result = window.confirm( "Deseja excluir definitivamente este evento?");

    if ( result ) {

      this.eventService = new EventService();

      this.eventService.delete( e.target.dataset.event_token ).then(res =>{

          if (this._isMounted) {

              if( res.success === true ){
                  localStorage.setItem('alert_message', "Evento excluído com sucesso");
                  localStorage.setItem('alert_type', 'alert-success');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );
                  this.getEventos();

              }else{
                  //alert(res.message);
              }
              document.body.style.cursor = "default";

          }

      });

    }else{
      let evt = {
        target: {
          dataset: {
            event_token: e.target.dataset.event_token
          }
        }
      }
      this.setState({
        showConfirm: true,
        eventToDelete: evt,
        message: "Deseja excluir definitivamente este evento?"
      });
    }

  }

  eventItem = ( {record, index}, type ) => {

      return (
        <tr key={record.token} className={type+" only"}>
              <td className="personage_name hidden-xs hidden-sm" title={record.title} >
                      {record.title}
              </td>
              <td className="actions">
                    <Divider type="vertical" />
                    <i className="fa fa-trash" data-event_token={record.token} onClick={(event)=>{this.delete(event)} }></i>
              </td>
        </tr>


      );
  };


  render() {

      return <div className="sheet list-lawsuit">
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Eventos</h1>
                        </section>
                        <div className="box-body">
                            <table className="partes-only-table">
                                    <thead>
                                        <tr>
                                              <th className="table_lawsuit_number">
                                                  <div>Nome</div>
                                              </th>
                                              <th className="table_lawsuit_actions">
                                                  <div>Ações</div>
                                              </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                          { ( typeof this.state.eventos !== "undefined") ? this.state.eventos.map( (record, index) => this.eventItem({ record, index }, 'evento2-item') ) : null }
                                    </tbody>
                            </table>
                        </div>
                  </div>
                </div>
             </div>
  }

}
