import React from 'react';
import Select from 'react-select';
import Loading from '../../containers/loading';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import eDocsService from '../../../services/eDocsService'

export default class Template extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
    super( props );
    this.selectChange = this.selectChange.bind(this);
    this.addTemplate = this.addTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.openTemplate = this.openTemplate.bind(this);

    this.state = {
        template: "",
        templateList: [],
        type_id: props.type_id,
        loadingVisible: false,
        loadingMessage: [],
        template_disabled: (props.type_id === "")? true: false,
    }

  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps.type_id !== this.props.type_id || nextProps.area_id !== this.props.area_id ) {

      let template = {value: -9999, label: "Selecione"};

      if ( !(typeof nextProps.template === "undefined") ) {
          if( nextProps.template !== null ) {
            template = {value: "", label: nextProps.template} ;
          }
      }

      if ( nextProps.area_id !== this.props.area_id ) {
        //console.log("entrou aqui");
        this.setState({
          template: template,
          type_id: "",
          updateList: ( nextProps.type_id !== this.props.type_id )? true: false,
          template_disabled: false,
          templateList: [],
        });

      }else{
        //console.log("e agora?");
        this.setState({
          template: template,
          type_id: nextProps.type_id,
          templateList: [],
          updateList: ( nextProps.type_id !== this.props.type_id )? true: false,
          template_disabled: (nextProps.type_id === "")? true: false,
        });

      }

      setTimeout(() => {
        document.getElementById("openTemplate").disabled = true;
        this.populateSelect();

      }, 300);

    }
  }

  addTemplate (e) {
    this.eDocsService = new eDocsService();
    this.setState({
      loadingVisible: true,
      loadingMessage: ["O modelo está sendo salvo.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });

    let template = {
      name: document.getElementById("templateName").value,
      edocs_type_token: this.state.type_id
    }
    this.eDocsService.addTemplate( template ).then(res =>{

      this.eDocsService.templateList( this.state.type_id ).then(res =>{

            if (this._isMounted) {

                document.getElementById("templateName").value = "";

                this.setState({
                  templateList: res,
                  loadingVisible: false
                });

            }

      });

    });
  }

  deleteTemplate (e) {
    this.eDocsService = new eDocsService();
    this.setState({
      loadingVisible: true,
      loadingMessage: ["O modelo está sendo deletado.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });

    let id = document.getElementById("templateSettings").value;

    //console.log( id );

    this.eDocsService.deleteTemplate( id ).then(res =>{

      this.eDocsService.templateList( this.state.type_id ).then(res =>{

            if (this._isMounted) {

                this.setState({
                  templateList: res,
                  loadingVisible: false,
                });

            }

      });

    });
  }

  populateSelect(){
    document.body.style.cursor = "wait";
    let templateList = []; let template = this.state.template;

    this.setState({
      loadingVisible: true,
      loadingMessage: ["Os modelos estão sendo carregados.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });

    this.eDocsService = new eDocsService();

      this.eDocsService.templateList( this.state.type_id ).then(res =>{

            if (this._isMounted) {

                  templateList = res;
                  //console.log(this.state.tipo)
                  //tipoList.unshift({value: -9999, label: "Não Definida"});
                  templateList.map((value, index, array )=>{

                    if ( value.label === this.state.template.label ) {
                      template = value;
                    }

                    return "";

                  });

                  this.setState({
                    templateList: templateList,
                    template: template,
                    loadingVisible: false,
                    template_disabled: (this.state.type_id === "")? true: false,
                  });
                  document.body.style.cursor = "default";

            }
      });
  }

 componentDidMount( ) { 
    this._isMounted = true;
    document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
    });

    if ( this.state.type_id !== "" ){
      this.populateSelect();
    }

    document.getElementById("openTemplate").disabled = true;
 }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }
    document.getElementById("openTemplate").disabled = false;

  }

  openTemplate = () => {
    this.props.history.push( '/modelo/editar/'+this.state.template.value );
  }

  render() {
      var url_base = localStorage.getItem('url_base');
      var arrTemplate = [];
      for (var k = 0; k < this.state.templateList.length; k++) {
          arrTemplate.push(<option key={k} value={this.state.templateList[k].value}> {this.state.templateList[k].label} </option>);
      }

      return <div className="">

                    <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                    <div className="form-group col-md-4 text-left">
                            <label className="col-md-12">
                                  Título do Modelo
                                  <Popover
                                      isOpen={this.state.isPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                              >
                                                    Clique em <i className="material-icons settings">settings</i> para incluir os nomes para cada modelo criado, por ex.: Procuração ad-judícia, Contrato de Honorários, Atestado de Hipossuficiência,  Inicial de Horas Extras, e outros.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </label>
                            <Select
                                className="template col-md-7"
                                placeholder="Selecione"
                                options={this.state.templateList}
                                value={this.state.template}
                                isDisabled={this.state.template_disabled}
                                name="template"
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" data-toggle="modal" data-target={(!this.state.template_disabled) ? "#templateModal" : ""}  title="Clique para adicionar ou excluir opções">settings</i>
                      </div>
                      <button id="openTemplate" className="btn btn-success btn-modelo" onClick={this.openTemplate}>Abrir Modelo</button>
                      <div className="modal fade" id="templateModal" tabIndex="-1" role="dialog" aria-labelledby="templateModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="templateModalLabel">Incluir ou Excluir Template</h5>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div className="form-group">
                                  <label htmlFor="templateName" className="col-form-label col-md-12">Novo Template: </label>
                                  <input type="text" className="form-control" id="templateName" />
                                  <span className="btn btn-primary" id="add-template" onClick={this.addTemplate} >Incluir</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="templateSettings" className="col-form-label col-md-12">Opções: </label>
                                    <select multiple id="templateSettings" className="template settings">
                                      {arrTemplate}
                                    </select>
                                    <i className="material-icons delete" id="delete-template" title="remover item selecionado" onClick={this.deleteTemplate} >delete</i>
                                </div>
                              </form>
                            </div>
                            <div className="modal-footer">
                              <p className="info">As alterações são salvas automaticamente</p>
                              <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                            </div>
                          </div>
                        </div>
                      </div>
             </div>
  }

}
