import React from 'react';

export default function Clear() {
    window.localStorage.removeItem('entries');
    window.localStorage.removeItem('incomings');
    window.localStorage.removeItem('outgoings');
    window.localStorage.removeItem('categoriesEntryLawsuit');
    window.localStorage.removeItem('categoriesIncoming');
    window.localStorage.removeItem('categoriesOutgoing');
    localStorage.removeItem('id_token');
    localStorage.removeItem('loggedUser');
    window.localStorage.removeItem('usersList');
    window.localStorage.removeItem('comboLawsuitList');
    window.localStorage.removeItem('financialCategoriesList');
    window.localStorage.removeItem('total_tribunals_count');
    window.localStorage.removeItem('total_manuals_count');
    window.localStorage.removeItem('total_inss_count');
    localStorage.removeItem('lawsuitAreasList');
    localStorage.removeItem('lawsuitTypesList');
    localStorage.removeItem('lawsuitPhasesList');
    localStorage.removeItem('lawsuitComarcasList');
    localStorage.removeItem('lawsuitLossesList');
    localStorage.removeItem('lawsuitOfficesList');
    localStorage.removeItem('lawsuitPersonagesList');
    localStorage.removeItem('tribunalList');
    localStorage.removeItem('tribunals');
    window.localStorage.removeItem('financialCategoriesOutgoingList');
    window.localStorage.removeItem('financialCategoriesList');
    window.localStorage.removeItem('financialCategoriesEntryList');
    window.localStorage.removeItem('total_tribunals_count_movements');
    window.localStorage.removeItem('comboLawsuitList');

    return (
        <h1>Os Dados temporários foram limpados com sucesso</h1>
    );
}
