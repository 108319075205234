import React from 'react';
import AuthService from '../../services/AuthService';

export default class Badge extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {

      this.authService = new AuthService();

      if ( this.props.info > 0 &&  this.authService.can('avisos_de_andamentos', true)  ) {
        let classes = this.props.color + " badge horizontal-space";

        return <span>
                  <span data-toggle="tooltip" title={this.props.title} className={classes} data-original-title={this.props.title}>{this.props.info}</span>
               </span>
      }else {
        return "";
      }


  }

}
