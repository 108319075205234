import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from './containers/footer';
import Area from './doc-facil/containers/area';
import Tipo from './doc-facil/containers/tipo';
import Template from './doc-facil/containers/template';
import FlashMessage from './containers/FlashMessage';

import {isTablet} from './config/mobileDetect';

import AuthService from '../services/AuthService';

export default class managerTemplates extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
      super( props );
      this.modifiedArea = this.modifiedArea.bind(this);
      this.modifiedType = this.modifiedType.bind(this);
      this.state = {
        area_id: 0,
        type_id: ""
      }

      this.authService = new AuthService();

      this.authService.can('gerenciar_doc_facil');

      window.scrollTo(0,0);

      if(isTablet()){
        alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
        window.location.href = '/';
      }

  }

  modifiedArea(){

    //console.log("area modificada");
    //console.log(document.getElementById("area_id").value);
    setTimeout(() => {
      this.setState({
        area_id: document.getElementById("area_id").value,
      });

      //console.log(document.getElementById("area_id").value);
    }, 200);

  }

  modifiedType(){

    //console.log("tipo modificado");
    //console.log(document.getElementById("type_id").value);

    this.setState({
      type_id: document.getElementById("type_id").value,
    });

  }

  render() {
      var url_base = localStorage.getItem('url_base');

      return <div className="sheet manager-template">
                <HeaderBar />
                <NavigationMenu />
                <FlashMessage time={ new Date().getTime() } />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="content-header">
                            <h1>
                                  Doc-Fácil
                                  <Popover
                                      isOpen={this.state.isPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                              >
                                                    Configure e imprima documentos padrões como Procurações, Recibos, Atestados, Iniciais, Contratos de Honorários e outros, uma única vez. Super fácil!
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </h1>
                      </div>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4 className="header-h4">
                                        Gerenciar Modelos
                                        <Popover
                                            isOpen={this.state.isH4PopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isH4PopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })}
                                                    >
                                                        Permite incluir,  excluir, alterar e configurar modelos de documentos. Permissões liberadas pelo Administrador.
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isH4PopoverOpen: false })} onMouseOver={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                        </Popover>
                                  </h4>
                            </div>
                      </div>
                        <p></p>
                        <Area modifiedArea={this.modifiedArea} area_id={this.state.area_id} />
                        <Tipo area_id={this.state.area_id} modifiedType={this.modifiedType} type_id={this.state.type_id} />
                        <Template type_id={this.state.type_id} area_id={this.state.area_id} history={this.props.history} />
                  </div>
                </div>
             </div>
  }

}
