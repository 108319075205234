import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import Alert from '../containers/alert';

export default class LawsuitSearchSection extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    document.addEventListener("cancelAlert", (e) => {
        this.setState({
        showAlert: false
        });
    });

  }


  constructor( props ) {
      super(props);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

      this.state = {
        'isEditPopoverOpen': false
      }
  }

  handleFormSubmit(e){
       e.preventDefault();
        //console.log(e.target.id);
        let test = true;
        let shouldTest = false;
        let message = "Não foram selecionados Cliente e Oponente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções! \n Selecione o Cliente e o Oponente!";
        
        if( document.getElementById("formIsHalfFilledOut") ){
          test = ( document.getElementById("formIsHalfFilledOut").value === "true" ) ? true : false ;
        }              
        
        if( document.getElementById("lawsuit_manual") && document.getElementById("lawsuit_manual").value === "manual" ) {
          if( document.getElementById("cliente") ){
            test = (document.getElementById("cliente").value === "true") ? false : true;
          }
          shouldTest = true;
          message = "Não foi incluído um Cliente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções! \n Selecione ou inclua um Cliente para este processo.";
        }else if( document.getElementById("lawsuit_status") && document.getElementById("lawsuit_status").value === "CADASTRADO" ){
          shouldTest = true;
        }

        if(  test && shouldTest ){
          this.setState({
            showAlert: true,
            message: "Não foram selecionados Cliente e Oponente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções!"
          });
        }else {
          this.props.history.push( '/processo/pesquisa/?q='+document.getElementById("q").value );
        }
  }

  render() {

      let url_base = localStorage.getItem('url_base');

      return <div className="lawsuit_search">

              <Alert show={this.state.showAlert} text={this.state.message} />
              <Popover
                  isOpen={this.state.isEditPopoverOpen}
                  position={['top', 'right', 'left', 'bottom']}
                  padding={10}
                  onClickOutside={() => this.setState({ isEditPopoverOpen: false })}
                  content={({ position, targetRect, popoverRect }) => (
                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                          position={position}
                          targetRect={targetRect}
                          popoverRect={popoverRect}
                          arrowColor={'#94bcd8'}
                          arrowSize={10}
                      >
                          <div className="popover-box"
                              onClick={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })}
                          >
                              Pesquisa pelo N° de processo, nome do Cliente e Oponente em toda base do sistema.
                          </div>
                      </ArrowContainer>
                  )}
              >
                  <img onMouseOut={() => this.setState({ isEditPopoverOpen: false })} onMouseOver={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
              </Popover>
             <label>Pesquisa</label>
             <form action="#" method="get" className="form-search">
                    <div className="input-group input-container-search" >
                      <input type="text" name="q" id="q" className="form-control sidebar-form" placeholder="N° Pasta, N° de Processo, Cliente, Oponente" />
                    </div>
                    <span className="input-group-btn btn-container-search">
                            <button type="submit" name="search" id="search-btn" onClick={this.handleFormSubmit.bind(this)} className="btn btn-flat">
                              <i className="fa fa-search"></i>
                            </button>
                    </span>
            </form></div>

  }

}
