import React from 'react';
import InputMask from 'react-input-mask';

export default class LawsuitInput extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <InputMask {...this.props} mask="99999.999999/9999-99" maskChar="_" />;
  }
}
