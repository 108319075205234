import React from 'react';
/*import Header from "./containers/header";
import Footer from "./containers/footer";
import { Link } from 'react-router-dom';*/
import AuthService from '../services/AuthService';
const Auth = new AuthService();

export default class Logout extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor() {
      super();
      this.Auth = new AuthService();
  }

  render() {
      Auth.logout();
      window.location.href = "/login";

      return <div>
                  <h1>Você será deslogado em 05 segundos</h1>
             </div>
  }

}
