import React from 'react';
import { Modal, Button } from 'antd';

export default class Confirm extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(props) {
    super(props);

    this.cancel = this.cancel.bind(this);

    this.state = {
      showConfirm: props.show
    }
  }

  componentWillReceiveProps(nextProps) {

      //if( nextProps.show !== this.props.show ){
        this.setState({
          showConfirm: nextProps.show
        });
      //}

  }

  cancel = () => {

    var event = new CustomEvent("cancelDialogConfirm", { "detail": "Example of an event" });

    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(event);

    this.setState(
      {
        showConfirm: false
      }
    )
  }

  confirm = () => {

    var event = new CustomEvent("confirmDialogConfirm", { "detail": "Example of an event" });

    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(event);

    this.setState(
      {
        showConfirm: false
      }
    )
  }

  render() {
              let url_base = localStorage.getItem('url_base');
              let avvocato_logo = "/images/logo_avvocato.png";

              //console.log( this.props.text );
              //console.log( this.state.showConfirm);

              return (
                        <Modal
                          visible={this.state.showConfirm}
                          className={"confirm_dialog"}
                          title=""
                          width="550px"
                          onOk={this.cancel}
                          onCancel={this.cancel}
                          footer={[
                            <Button key="cancel" id="cancel" onClick={this.cancel}>
                            Cancelar
                            </Button>,
                            <Button key="submit" id="confirm" type="primary" onClick={this.confirm}>
                              OK
                            </Button>
                          ]}
                        >                          
                          <img className=" " alt="Logotipo Avvocato" src={url_base+avvocato_logo}></img>
                          <div className="my-alert-box">
                                { (typeof this.props.text !== "undefined") ? this.props.text.split('\n').map(i => {return <h3 key={i}>{i}</h3>}) : "" }
                          </div>
                        </Modal>
                    );
  }
}
