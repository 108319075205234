import React from 'react';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from '../containers/footer';
import FlashMessage from '../containers/FlashMessage';
import ReactQuill from 'react-quill';

import eDocsService from '../../services/eDocsService'

export default class viewTemplate extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
      super( props );
      this.view = this.view.bind(this);
      this.back = this.back.bind(this);

      this.state = {
        title: "",
        content: "",
        template_id: props.match.params.id,
        edocs_type_token: "",
      }

  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props  ) {

      this.setState ({
          template_id: nextProps.match.params.id
      });

      setTimeout(() => {

        this.view();

      }, 300);

    }

  }

  componentDidMount( ) { this._isMounted = true;

      this.view();

  }


  view(){

    this.eDocsService = new eDocsService();

      this.eDocsService.viewTemplate( this.state.template_id ).then(res =>{
            //console.log(res);

            this.setState({
                title: res.name,
                content: res.content,
                edocs_type_token: res.edocs_type.token,
            });
      });

  }

  back(){
    this.props.history.goBack();
  }

  modules = {
      toolbar: [

      ],
    };

formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
      'list', 'bullet', 'indent',
      'image', 'align',
      'font', 'color', 'size'
    ];

  render() {

      return <div className="sheet view-template">
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="content-header">
                            <h1>{this.state.title}</h1>
                            <span onClick={this.back} className="btn btn-info">Voltar</span>
                            <ReactQuill
                                theme="snow"
                                readOnly={true}
                                value={this.state.content}
                                modules={this.modules}
                                formats={this.formats}
                            />
                      </div>
                  </div>
                </div>
             </div>
  }

}
