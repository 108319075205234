import React from 'react';
import 'antd/dist/antd.css';
import LawsuitService from '../../../services/LawsuitService';
import AuthService from '../../../services/AuthService';

import Alert from '../../containers/alert';
import Confirm from '../../containers/confirm';
import MyPopover from '../../containers/mypopover';

import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class infoLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);
    this.openEditInfo = this.openEditInfo.bind(this);
    this.openAddInfo = this.openAddInfo.bind(this);
    this.addInfo = this.addInfo.bind(this);
    this.editInfo = this.editInfo.bind(this);
    this.deleteInfo = this.deleteInfo.bind(this);
    this.voltar = this.voltar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.roll = this.roll.bind(this);
    this.myRef = React.createRef();

    this.LawsuitService = new LawsuitService();
    this.authService = new AuthService();

    this.state = {
      infos: [],
      info: "",
      message: "",
      showAlert: false,
      showConfirm: false,
      infoToDelete: false,
    }

  }

  roll = (event) => {

    if ( !event.target.classList.contains('collapsed') ) {
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop-67)
      }, 800);
    }

  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,          
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        if( this.state.infoToDelete !== false ){
            this.deleteInfo(this.state.infoToDelete, true);
        }
      }
    });

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });

    this.lawsuitService = new LawsuitService();

    let url = window.location.pathname.split('/');

    this.LawsuitService.listInformation( url[ url.length-1] ).then(res =>{

        this.setState(
          {
            'info': "",
            'infos': res,
            'isPopoverOpen': false,
            'isEditPopoverOpen': false
          }
        );

    });

    setTimeout(function(  ){



     }, 3000);

  }

  openEditInfo ( e ){
    document.getElementById("info_edit").classList.remove("hide");
    document.getElementById("info-table").className += ' hide';
    document.getElementById("bnt-cadastrar-info").className += ' hide';
    document.getElementById("addPopover").className += ' hide';
    if ( document.getElementById("info-show-hide-button-container") !== null ){
      document.getElementById("info-show-hide-button-container").className += ' hide';
    }

    document.getElementById("info_edit_token").value = e.target.dataset.info_token;

    this.setState(
      { info: e.target.dataset.scrap}
    )
  }

  openAddInfo ( e ){
    document.getElementById("info_add").classList.remove("hide");
    document.getElementById("info-table").className += ' hide';
    document.getElementById("bnt-cadastrar-info").className += ' hide';
    document.getElementById("addPopover").className += ' hide';
    if ( document.getElementById("info-show-hide-button-container") !== null ){
      document.getElementById("info-show-hide-button-container").className += ' hide';
    }
  }

  addInfo ( e ){

    document.getElementById("bnt-cadastrar-nova-info").disabled = true;
    document.body.style.cursor = "wait";

    let data = {
      'lawsuit_token': document.getElementById("lawsuit_token").value,
      'information': this.state.info,
    }

    this.LawsuitService.addInformation( data ).then(res =>{

      document.getElementById("bnt-cadastrar-nova-info").disabled = false;
      document.body.style.cursor = "default";

      //alert(res.message);

      this.LawsuitService.listInformation( document.getElementById("lawsuit_token").value ).then(res =>{

            this.setState(
              {
                'info': "",
                'infos': res,
              }
            );

      });


      this.setState(
        {
          'info': "",
        }
      );

      document.getElementById("info_add").className += " hide";
      document.getElementById("info-table").classList.remove("hide");
      document.getElementById("bnt-cadastrar-info").classList.remove("hide");
      document.getElementById("addPopover").classList.remove("hide");
      if ( document.getElementById("info-show-hide-button-container") !== null ){
        document.getElementById("info-show-hide-button-container").classList.remove("hide");
      }
    });

  }

  editInfo ( e ){

    document.getElementById("bnt-edit-info").disabled = true;
    document.body.style.cursor = "wait";

    let data = {
      'lawsuit_token': document.getElementById("lawsuit_token").value,
      'information': this.state.info,
    }

    this.LawsuitService.editInformation( data, document.getElementById("info_edit_token").value ).then(res =>{

      document.getElementById("bnt-edit-info").disabled = false;
      document.body.style.cursor = "default";

      //alert(res.message);

      this.LawsuitService.listInformation( document.getElementById("lawsuit_token").value ).then(res =>{

            this.setState(
              {
                'info': "",
                'infos': res,
              }
            );

      });

      this.setState(
        {
          'info': "",
        }
      );

      document.getElementById("info_edit").className += " hide";
      document.getElementById("info-table").classList.remove("hide");
      document.getElementById("bnt-cadastrar-info").classList.remove("hide");
      document.getElementById("addPopover").classList.remove("hide");

    });

  }

  deleteInfo ( e, r = false ){

    //let result = window.confirm( "Deseja excluir definitivamente esta informação dos registros?");

    if ( r ) {

      this.LawsuitService = new LawsuitService();

      this.LawsuitService.deleteInformation( e.target.dataset.info_token ).then(res =>{

          if( res.success === true && this._isMounted ){
            //alert("atividade excluída com sucesso");
            this.LawsuitService.listInformation( document.getElementById("lawsuit_token").value ).then(res =>{

                if( this._isMounted ){

                  this.setState(
                    {
                      'infos': res,
                    }
                  );

                }

            });
          }else{
              //alert(res.message);
          }

      });

    }else{
        let evt = {
          target: {
            dataset: {
              info_token: e.target.dataset.info_token
            }
          }
        }
        this.setState({
          showConfirm: true,
          infoToDelete: evt,
          message: "Deseja excluir definitivamente esta informação dos registros?"
        });
    }

  }

  voltar ( e ){
    document.getElementById("info_add").className += " hide";
    document.getElementById("info_edit").className += " hide";
    document.getElementById("info-table").classList.remove("hide");
    document.getElementById("bnt-cadastrar-info").classList.remove("hide");
    document.getElementById("addPopover").classList.remove("hide");
    if ( document.getElementById("info-show-hide-button-container") !== null ){
      document.getElementById("info-show-hide-button-container").classList.remove("hide");
    }
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   infoItem = ( {record, index}, type ) => {

       return (
         <tr key={record.key} className={type}>
               <td className="number">
                     {record.key}
               </td>
               <td className="date" >
                     <span className="date-info-lawsuit">{( record.created !== "") ? moment( record.created ).format("DD/MM/YYYY") : ""} às {( record.created !== "") ? moment( record.created ).format('HH:mm') : ""}</span>
               </td>
               <td className="informacoes" >
                 <span>
                    ({record.user_name}) - {record.information}
                 </span>
               </td>
               <td className="actions">
                     <span className="pointer pull-right bt-edit">
                         <i className={ ( record.owner === 1 ) ? "fa fa-pencil-square-o" : "" } data-scrap={record.information} data-info_token={record.value} onClick={this.openEditInfo}></i>
                         <span className={ ( record.owner === 1 ) ? "edit-label" : "hide" }>Editar</span>
                     </span>
                     <i className={ ( record.owner === 1 && this.authService.can('rai_processo_excluir', true, 'processos') ) ? "fa fa-trash " : "" } data-info_token={record.value} onClick={ (event)=>{this.deleteInfo(event)} }></i>
               </td>
         </tr>


       );
   };

   showInfos = ( e ) => {
     let itens = document.getElementsByClassName(e.target.dataset.type);

     for (let n = itens.length - 1; n >= 0; n--) {

          if ( n >= 3 ) {
            itens[n].className += ' show-table';
          }
      }

      document.getElementById("show-button-"+e.target.dataset.type).className += ' hide';
      document.getElementById("hide-button-"+e.target.dataset.type).className += ' show';

   }

   hideInfos = ( e ) => {
     let itens = document.getElementsByClassName(e.target.dataset.type);

     for (let n = itens.length - 1; n >= 0; n--) {

          if ( n >= 3 ) {
            itens[n].classList.remove('show-table');
          }
      }

      document.getElementById("hide-button-"+e.target.dataset.type).classList.remove('show');
      document.getElementById("show-button-"+e.target.dataset.type).classList.remove('hide');

   }

   show_hide_button_container = ( record, index, type ) => {

       if ( index > 2 && index < 4 ) {
         return (
           <div key={index} id="info-show-hide-button-container" className="show-hide-button-container">
               <span id={"show-button-"+type} className="btn-small btn-info show-button" data-type={type} onClick={this.showInfos}>Ver mais</span>
               <span id={"hide-button-"+type} className="btn-small btn-info hide-button" data-type={type} onClick={this.hideInfos}>Ocultar registros</span>
           </div>
         );
       }else {
         return "";
       }

   };

  render() {

    if( !this.authService.can('rai_processo', true, 'processos') ){
      return null;
    }

    var url_base = localStorage.getItem('url_base');

      return (
        <div className="panel panel-default container-parte">
              <Confirm show={this.state.showConfirm} text={this.state.message} />
              <Alert show={this.state.showAlert} text={this.state.message} />
              <div className="panel-heading text-center">
                  <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#info-lawsuit-target">
                      <strong>Registro de Ações Internas - RAI</strong>
                  </div>
              </div>
              <div className="panel-body collapse" id="info-lawsuit-target">
              <span className="btn btn-primary" id="bnt-cadastrar-info" onClick={this.openAddInfo}>Incluir</span>
              <MyPopover icon="white" id="addPopover"  text="Registre aqui - Ligações, pendencias, conversas e informações passadas para o cliente, acontecimentos, artigos ou matérias referentes ao cliente /processo." />
              <div id="info_edit" className="hide panel panel-warning">
                    <div className="panel-heading text-center">
                        <strong>Alterar Informações</strong>
                    </div>
                    <div className="panel-body">
                    <div className="form-group col-md-12 text-left">
                                  <input type="hidden" name="info_edit_token" id="info_edit_token" />
                            </div>
                            <div className="form-group col-md-12 text-left">
                                  <label htmlFor="info" className="control-label">Informações</label>
                                  <MyPopover icon="white" text="Exemplos: telefonemas, reuniões, pendências, solicitações, matérias, artigos, etc... relacionados ao cliente/processo" />

                                  <textarea name="info" id="info" rows="8" maxLength="2000" className="form-control" value={this.state.info} onChange={this.handleChange}></textarea>
                            </div>
                            <div className="form-group col-md-12 text-right">
                                  <span className="horizontal-space">Máximo de 2.000 caracteres.</span>
                                  <input type="submit" className="btn btn-primary horizontal-space" value="Alterar" id="bnt-edit-info" onClick={this.editInfo} />                                  
                                  <span className="btn btn-danger horizontal-space" id="bnt-voltar" onClick={this.voltar}>Voltar</span>
                            </div>
                    </div>
              </div>
              <div id="info_add" className="hide panel panel-warning">
                  <div className="panel-body">
                              <div className="form-group col-md-12 text-left">
                                    <label htmlFor="info" className="control-label">Informações</label>  
                                    <MyPopover icon="white" text="Exemplos: telefonemas, reuniões, pendências, solicitações, matérias, artigos, etc... relacionados ao cliente/processo" />
                                    
                                    <textarea name="info" id="info" rows="8" maxLength="2000" className="form-control" value={this.state.info} onChange={this.handleChange}></textarea>
                              </div>
                              <div className="form-group col-md-12 text-right">
                                <span className="horizontal-space">Máximo de 2.000 caracteres.</span>
                                    <input type="submit" className="btn btn-primary horizontal-space" value="Cadastrar" id="bnt-cadastrar-nova-info" onClick={this.addInfo} />                                    
                                    <span className="btn btn-danger horizontal-space" id="bnt-voltar" onClick={this.voltar}>Voltar</span>
                              </div>
                      </div>
                </div>
                <div className="table-responsive">
                      <table id="info-table" className="table">
                              <thead>
                                  <tr>
                                        <th className="number">
                                            <div>N°</div>
                                        </th>
                                        <th className="date">
                                            <div>Data</div>
                                        </th>
                                        <th className="informacoes">
                                            <div>Informações</div>
                                        </th>
                                        <th className="actions">
                                            <div>Ações</div>
                                        </th>
                                  </tr>
                              </thead>
                              <tbody>
                                    { ( typeof this.state.infos !== "undefined") ? this.state.infos.map( (record, index) => this.infoItem({ record, index }, 'info-item') ) : null }
                              </tbody>
                      </table>
                </div>
                { ( typeof this.state.infos !== "undefined" ) ? this.state.infos.map( (record, index) =>
                  this.show_hide_button_container( record, index, 'info-item' ) ) : ""
                }
              </div>
        </div>
      )

  }
}
