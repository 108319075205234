const estados = [
    {
      "uf": 'federais',
      "count": 0,
    },
    {
      "uf": 'sp',
      "count": 0,
    },
    {
      "uf": 'mg',
      "count": 0,
    },
    {
      "uf": 'rj',
      "count": 0,
    },
    {
      "uf": 'es',
      "count": 0,
    },
    {
      "uf": 'pr',
      "count": 0
    },
    {
      "uf": 'sc',
      "count": 0
    },
    {
      "uf": 'rs',
      "count": 0
    },
    {
      "uf": 'go',
      "count": 0
    },
    {
      "uf": 'ms',
      "count": 0
    },
    {
      "uf": 'mt',
      "count": 0
    },
    {
      "uf": 'to',
      "count": 0
    },
    {
      "uf": 'df',
      "count": 0
    },
    {
      "uf": 'ro',
      "count": 0
    },
    {
      "uf": 'am',
      "count": 0
    },
    {
      "uf": 'ac',
      "count": 0
    },
    {
      "uf": 'rr',
      "count": 0
    },
    {
      "uf": 'pa',
      "count": 0
    },
    {
      "uf": 'ap',
      "count": 0
    },
    {
      "uf": 'ma',
      "count": 0
    },
    {
      "uf": 'pi',
      "count": 0
    },
    {
        "uf": 'ce',
        "count": 0
    },
    {
        "uf": 'rn',
        "count": 0
    },
    {
        "uf": 'pb',
        "count": 0
    },
    {
        "uf": 'pe',
        "count": 0
    },
    {
        "uf": 'al',
        "count": 0
    },
    {
        "uf": 'se',
        "count": 0
    },
    {
        "uf": 'ba',
        "count": 0
    },

  ];
export default estados;
