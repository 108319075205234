import React from 'react';
import DonutChart from "./donutChart";
import PubChart from "./pubChart";
import Popover, { ArrowContainer } from 'react-tiny-popover';

import UsersService from '../../services/UsersService';
import PublicationsService from '../../services/PublicationsService';
import EventService from '../../services/EventService';
import FinanceService from '../../services/FinanceService';
import AuthService from '../../services/AuthService';


import MyPopover from './mypopover';

import moment from "moment";
import 'moment/locale/pt-br';

export default class TopCharts extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.myInterval);
  }


  constructor( props ) {

      super( props );

      this.authService = new AuthService();

      this.state = {
          count_atrasados: 0,
          count_hoje: 0,
          count_futuros: 0,
          count_task_atrasados: 0,
          count_task_hoje: 0,
          count_task_futuros: 0,
          count_publicacoes_hoje: 0,
          count_publicacoes_nao_cadastrados: 0,
          count_contas_pagar_atrasados: 0,
          count_contas_pagar_hoje: 0,
          count_contas_pagar_futuros: 0,
          count_contas_receber_atrasados: 0,
          count_contas_receber_hoje: 0,
          count_contas_receber_futuros: 0,
          usersList: [],
          isAdmin: false,
          selectedUser: 0,
          event_loading: "hide",
          finance_loading: "hide",
          publications: 0
      }

  }

  componentWillReceiveProps(nextProps) {

    this.setState({

    });

  }

  componentDidMount(){

        this._isMounted = true;

        this.usersService = new UsersService();

        this.getPublications();

        this.usersService.list( ).then(res =>{

            if( this._isMounted ) {

                      let options = res; let sponsor = []; let isAdmin = false;
                      let user_can_access = this.authService.can('agenda_escritorio', true, 'agenda');
          
                      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

                      //console.log(loggedUser);
          
                      res.map( ( record, index, array ) => {
                        //console.log(record);
                        if( record.value === loggedUser.token && ( record.admin === true || record.admin === 1) ){
                          isAdmin = true;
                        }
          
                        if( record.value === loggedUser.token && !user_can_access ){
                          options = [];
                          options.push(record);
                        }
          
                        return record;
          
                      });
          
                      if( user_can_access ){
                        if(options.length > 0){
                          options.unshift ( {value: -99, label: "Todos"} );
                        }
                      }
          
                      this.setState({
                        usersList: options,
                        isAdmin: isAdmin
                      });
          
                      let month = new moment().format("MMMM");
                      let day =  new moment().format("DD");
                      let year = new moment().format("YYYY");
                      let week = new moment().format("dddd");
          
                      this.setState({
                        month: month,
                        day: day,
                        year: year,
                        week: week,
                        selectedUser: loggedUser.token,
                        'isPopoverOpen': false,
                        'isEditPopoverOpen': false,
                        'isEditFinancePopoverOpen': false
                      });
          
                      setTimeout(() => {
                        /*if( user_can_access ){
                          this.getEvents("all");
                        }else{*/
                          this.getEvents( loggedUser.token );
                        //}
                      }, 300);

                      //let myInterval = setInterval(() => {
                          this.getEvents( this.state.selectedUser );
                      //}, 600000);

                      /*this.setState({
                        myInterval
                      });*/
          
                      this.getFinances();

            }

        });

  }

  getPublications () {

    this.PublicationsService = new PublicationsService();

    this.PublicationsService.today(  ).then( response =>{

      //console.log(response);

        if( this._isMounted ) {
          this.setState({
            count_publicacoes_hoje: response.pagination.count
          });
        }

    });

    this.PublicationsService.lawsuitsNotRegistered( '&counter=1' ).then( response =>{

      //console.log(response);

        if( this._isMounted ) {
          this.setState({
            count_publicacoes_nao_cadastrados: (typeof response.data !== "undefined") ? response.data.Publications.all : 0
          });
        }

    });


    /*this.PublicationsService.counter(  ).then( publications =>{
      this.setState({
        publications: publications.all
      });
    });*/

  }

  getEvents ( token ) {

      this.setState({
        event_loading: 'show'
      });

      let start = moment().startOf('day').subtract(31, 'days').format('DD-MM-YYYY');;
      let end = moment().endOf('day').add(2, 'days').format('DD-MM-YYYY');

      if ( token === "all" || token === "-99" ){

              this.EventService = new EventService();

              this.EventService.report( start, end, "0", "0", "0", "0", "0", "0", "0", "0", "0" ).then( events =>{

                if( this._isMounted ) {
                  this.updateChart( events );
                }

              });

      }else{

              this.EventService = new EventService();

              this.EventService.report( start, end, "0", "0", "0", token, "0", "0", "0", "0", "0" ).then( events =>{

                  if( this._isMounted ) {
                        this.updateChart( events );
                  }

              });

      }

      this.setState({
        user_token: token,
        start: start,
        end: end,
      });

  }

  getFinances ( ) {

      this.setState({
        finance_loading: 'show'
      });

      let start = moment().startOf('day').subtract(15, 'days').format('DD/MM/YYYY');
      let end = moment().endOf('day').add(2, 'days').format('DD/MM/YYYY');

      this.FinanceService = new FinanceService();

      this.FinanceService.listIncoming( start, end, "3", null, null, null ).then( incomings =>{

        if( this._isMounted ) {

          this.FinanceService.listOutgoing( start, end, "3", null, null, null ).then( outgoings =>{

                if( this._isMounted ) {

                  this.FinanceService.categoryIncomingList().then(incomingCategoryList =>{

                    if( this._isMounted ) {

                      this.FinanceService.categoryOutgoingList().then(outgoingCategoryList =>{

                        if( this._isMounted ) {
                          this.updateChartFinanceIncoming( incomings, outgoings, incomingCategoryList );
                          this.updateChartFinanceOutgoing( outgoings, outgoingCategoryList );
                        }
          
                      });

                    }
        
                  });

                }
  
          });

        }

      });

  }

  updateChartFinanceOutgoing( outgoings, outgoingCategoryList  ) {

    let count_contas_pagar_atrasados = 0; let count_contas_pagar_hoje = 0; let count_contas_pagar_futuros = 0;
    let catList = [];

    outgoingCategoryList.map( ( record, index, array ) => {

      catList.push(record.key);

      return record;

    });

    for( let i=0; i < outgoings.length; i++){

          if( outgoings[i].data_vencimento.substr(0,10) === moment().format('YYYY-MM-DD') && catList.includes( outgoings[i].financial_category.token )  ){
            //hoje
            if( outgoings[i].pago !== 1 ){
              count_contas_pagar_hoje += 1;
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) < moment().format('YYYY-MM-DD') && catList.includes( outgoings[i].financial_category.token ) ){
            //Compromissos Atrasados
            if( outgoings[i].pago !== 1 ){
              count_contas_pagar_atrasados += 1;
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) && catList.includes( outgoings[i].financial_category.token ) ){
            //futuros
            if( outgoings[i].pago !== 1 ){
              count_contas_pagar_futuros += 1;
            }

          }

    }

    this.setState({
      count_contas_pagar_atrasados,
      count_contas_pagar_hoje,
      count_contas_pagar_futuros,
      finance_loading: 'hide'
    });

 }

  updateChartFinanceIncoming( incomings, outgoings, incomingCategoryList  ) {

    let count_contas_receber_atrasados = 0; let count_contas_receber_hoje = 0; let count_contas_receber_futuros = 0;
    let catList = [];

    incomingCategoryList.map( ( record, index, array ) => {

      catList.push(record.key);

      return record;

    });

    for( let i=0; i < incomings.length; i++){

          if( incomings[i].data_vencimento.substr(0,10) === moment().format('YYYY-MM-DD') && !(incomings[i].belongs_to_lawsuit === 1 && incomings[i].financial_category.name === "Recebimento de Acordo") ){
            //hoje
            if( incomings[i].pago !== 1 ){
              count_contas_receber_hoje += 1;
            }

          }else if( incomings[i].data_vencimento.substr(0,10) < moment().format('YYYY-MM-DD') && !(incomings[i].belongs_to_lawsuit === 1 && incomings[i].financial_category.name === "Recebimento de Acordo") ){
            //Compromissos Atrasados
            if( incomings[i].pago !== 1 ){
              count_contas_receber_atrasados += 1;
            }

          }else if( incomings[i].data_vencimento.substr(0,10) && !(incomings[i].belongs_to_lawsuit === 1 && incomings[i].financial_category.name === "Recebimento de Acordo") ){
            //futuros
            if( incomings[i].pago !== 1 ){
              count_contas_receber_futuros += 1;
            }

          }

    }

    for( let i=0; i < outgoings.length; i++){

          if( outgoings[i].data_vencimento.substr(0,10) === moment().format('YYYY-MM-DD') && (outgoings[i].belongs_to_lawsuit === 1 && catList.includes( outgoings[i].financial_category.token ) ) ){
            //hoje
            if( outgoings[i].pago !== 1 ){
              count_contas_receber_hoje += 1;
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) < moment().format('YYYY-MM-DD') && (outgoings[i].belongs_to_lawsuit === 1 && catList.includes( outgoings[i].financial_category.token ) ) ){
            //Compromissos Atrasados
            if( outgoings[i].pago !== 1 ){
              count_contas_receber_atrasados += 1;
            }

          }else if( outgoings[i].data_vencimento.substr(0,10) && (outgoings[i].belongs_to_lawsuit === 1 && catList.includes( outgoings[i].financial_category.token ) ) ){
            //futuros
            if( outgoings[i].pago !== 1 ){
              count_contas_receber_futuros += 1;
            }

          }

    }

    this.setState({
      count_contas_receber_atrasados,
      count_contas_receber_hoje,
      count_contas_receber_futuros,
    });

 }

  updateChart( events ) {

    //console.log("agora vai calendario widget");
    //console.log( events );

    let count_hoje = 0; let count_task_hoje = 0; let count_atrasados = 0; let count_task_atrasados = 0; let count_futuros = 0;
    let count_task_futuros = 0;

    for( let i=0; i < events.length; i++){

          if( events[i].start.substr(0,10) === moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //hoje
            if( events[i].event_type !== null && events[i].event_type.id === 1 ){
              count_hoje += 1;
            }else if ( events[i].event_type !== null && events[i].event_type.id === 2 ){
              count_task_hoje += 1;

              //console.log("tasks hoje: "+count_task_hoje);
            }

          }else if( events[i].start.substr(0,10) < moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //Compromissos Atrasados
            if( events[i].event_type !== null && events[i].event_type.id === 1 ){
              count_atrasados += 1;
            }else if ( events[i].event_type !== null && events[i].event_type.id === 2 ){
              count_task_atrasados += 1;
              //console.log("tasks atrasadas: "+count_task_atrasados);
            }

          }else if( events[i].start.substr(0,10) > moment().format('YYYY-MM-DD') && ( events[i].events_situation !== null && events[i].events_situation.id === 1 ) ){
            //futuros
            if( events[i].event_type !== null && events[i].event_type.id === 1 ){
              count_futuros += 1;
            }else if ( events[i].event_type !== null && events[i].event_type.id === 2 ){
              count_task_futuros += 1;
              //console.log("tasks futuros: "+count_task_futuros);
            }

          }

    }

    this.setState({
      count_hoje: count_hoje,
      count_task_hoje: count_task_hoje,
      count_atrasados: count_atrasados,
      count_task_atrasados: count_task_atrasados,
      count_futuros: count_futuros,
      count_task_futuros: count_task_futuros,
      event_loading: 'hide'
    });

 }

  changeUser = ( event ) => {

   //console.log( event.target.value );

    if( event.target.value === "-99"){
      this.getEvents("all");
    }else{
      this.getEvents(event.target.value);
    }

    this.setState({
      selectedUser: event.target.value
    });

  }

  render() {

        let financeiro_container = null; let publicacoes_container = null;

        if ( this.props.active === "1" ){
          return null;
        }else{

          var arrUser = [];
          for (var k = 0; k < this.state.usersList.length; k++) {
              arrUser.push(<option key={k} value={this.state.usersList[k].value}> {this.state.usersList[k].label} </option>);            
        }

        const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

        if( /*loggedUser.token === "a332805e-ec30-403b-a92b-e19c8007d70d" && */this.authService.can('publicacoes_visualizar_grafico', true, 'publicacoes') ){
            publicacoes_container = <div className="publicacoes-container" >
                    <h1>PUBLICAÇÕES<MyPopover icon="white" text="Dê um clique no círculo para visualizar as publicações. Para contratação, acesse Configurações/Meus dados e planos / Alterar Planos." /></h1>
                    <PubChart
                        title=""
                        class_title=""
                        show_future={false}
                        label_title="Publicações"
                        label_value="Quantidade"
                        color="#D7F0FB"
                        height="150"
                        top="20px"
                        naoCadastradosTitle="Publicações de Processos não cadastrados"
                        count_nao_cadastrados={this.state.count_publicacoes_nao_cadastrados}
                        count_hoje={this.state.count_publicacoes_hoje}
                        total={this.state.publications}
                        url={'publicacoes'}
                        history={this.props.history}
                    />
            </div>;
        }

          let url_base = localStorage.getItem('url_base');
          //console.log(this.state.isAdmin);
          if ( this.authService.can('financeiro_fluxo_caixa', true, 'financeiro') ){
            financeiro_container =      <div className="financeiro-container">
                                                  <h1>
                                                       FINANCEIRO
                                                       <Popover
                                                           isOpen={this.state.isEditFinancePopoverOpen}
                                                           position={['top', 'right', 'left', 'bottom']}
                                                           padding={10}
                                                           onClickOutside={() => this.setState({ isEditFinancePopoverOpen: false })}
                                                           content={({ position, targetRect, popoverRect }) => (
                                                               <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                                   position={position}
                                                                   targetRect={targetRect}
                                                                   popoverRect={popoverRect}
                                                                   arrowColor={'#94bcd8'}
                                                                   arrowSize={10}
                                                               >
                                                                   <div className="popover-box"
                                                                       onClick={() => this.setState({ isEditFinancePopoverOpen: !this.state.isEditFinancePopoverOpen })}
                                                                   >
                                                                       Dê um clique no círculo para visualizar os detalhes dos lançamentos.
                                                                   </div>
                                                               </ArrowContainer>
                                                           )}
                                                       >
                                                           <img onMouseOut={() => this.setState({ isEditFinancePopoverOpen: false })} onMouseOver={() => this.setState({ isEditFinancePopoverOpen: !this.state.isEditFinancePopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                                       </Popover>
                                                  </h1>
                                                  <div>
                                                          <img alt="loading" className={this.state.finance_loading+" chart-loader"} src={url_base+"/images/transparent_loader.gif"}></img>
                                                          <div className={this.state.finance_loading+" div-loading"}></div>
                                                          <DonutChart
                                                              title="Contas a Pagar"
                                                              class_title="contas_pagar"
                                                              show_future={true}
                                                              label_title="Contas a Pagar"
                                                              label_value="Quantidade"
                                                              color="#D7F0FB"
                                                              height="120"
                                                              top="5px"
                                                              pasteTitle="Atrasadas"
                                                              count_atrasados={this.state.count_contas_pagar_atrasados}
                                                              count_hoje={this.state.count_contas_pagar_hoje}
                                                              count_futuros={this.state.count_contas_pagar_futuros}
                                                              url={'relatorio/financeiro/chart/2'}
                                                              history={this.props.history}
                                                          />
                                                          <DonutChart
                                                              title="Contas a Receber"
                                                              class_title="contas_receber"
                                                              show_future={true}
                                                              label_title="Contas a Receber"
                                                              label_value="Quantidade"
                                                              color="#E6F5FC"
                                                              height="120"
                                                              top="5px"
                                                              pasteTitle="Atrasadas"
                                                              count_atrasados={this.state.count_contas_receber_atrasados}
                                                              count_hoje={this.state.count_contas_receber_hoje}
                                                              count_futuros={this.state.count_contas_receber_futuros}
                                                              url={'relatorio/financeiro/chart/1'}
                                                              history={this.props.history}
                                                          />
                                                  </div>
                                          </div>;
          }

          return (
                  <div id="top-chart-container">
                          <div className="chart-inner">
                              <div className="calendar-widget">
                                      <div className="month">{this.state.month}</div>
                                      <div className="day">{this.state.day}</div>
                                      <div className="year">{this.state.year}</div>
                                      <div className="week">{this.state.week}</div>
                                      {/*<i className="fa fa-angle-left previous"></i>
                                      <i className="fa fa-angle-right next"></i>*/}
                                      <div className="users">
                                            <span>Agenda do Usuário</span>
                                            <select className="user" value={this.state.selectedUser} onChange={this.changeUser} >
                                            {arrUser}
                                            </select>
                                      </div>
                              </div>
                              <div className="agenda-container">
                                      <h1>
                                      AGENDA
                                      <Popover
                                          isOpen={this.state.isEditPopoverOpen}
                                          position={['top', 'right', 'left', 'bottom']}
                                          padding={10}
                                          onClickOutside={() => this.setState({ isEditPopoverOpen: false })}
                                          content={({ position, targetRect, popoverRect }) => (
                                              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                  position={position}
                                                  targetRect={targetRect}
                                                  popoverRect={popoverRect}
                                                  arrowColor={'#94bcd8'}
                                                  arrowSize={10}
                                              >
                                                  <div className="popover-box"
                                                      onClick={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })}
                                                  >
                                                      Dê um clique no círculo para visualizar os detalhes da agenda de todos ou de um usuário.
                                                  </div>
                                              </ArrowContainer>
                                          )}
                                      >
                                          <img onMouseOut={() => this.setState({ isEditPopoverOpen: false })} onMouseOver={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                      </Popover>
                                      </h1>
                                      <div>


                                        <img alt="loading" className={this.state.event_loading+" chart-loader"} src={url_base+"/images/transparent_loader.gif"}></img>
                                        <div className={this.state.event_loading+" div-loading"}></div>
                                              <DonutChart
                                                  title="Compromissos"
                                                  class_title="compromissos"
                                                  show_future={true}
                                                  label_title="Compromissos"
                                                  label_value="Quantidade"
                                                  color="#D7F0FB"
                                                  height="120"
                                                  top="5px"
                                                  pasteTitle="Atrasados"
                                                  count_atrasados={this.state.count_atrasados}
                                                  count_hoje={this.state.count_hoje}
                                                  count_futuros={this.state.count_futuros}
                                                  url={'relatorio/agenda/chart/1/0/0/'+this.state.user_token+'/0/0/0/0/'+this.state.start+'/'+this.state.end}
                                                  history={this.props.history}
                                              />
                                              <DonutChart
                                                  title="Tarefas"
                                                  class_title="tarefas"
                                                  show_future={true}
                                                  label_title="Tarefas"
                                                  label_value="Quantidade"
                                                  color="#E6F5FC"
                                                  height="120"
                                                  top="5px"
                                                  pasteTitle="Atrasadas"
                                                  count_atrasados={this.state.count_task_atrasados}
                                                  count_hoje={this.state.count_task_hoje}
                                                  count_futuros={this.state.count_task_futuros}
                                                  url={'relatorio/agenda/chart/2/0/0/'+this.state.user_token+'/0/0/0/0/'+this.state.start+'/'+this.state.end}
                                                  history={this.props.history}
                                              />
                                      </div>
                              </div>
                              {publicacoes_container}
                              {financeiro_container}
                          </div>
                  </div>
          );

        }
  }
}
