import React from 'react';
import ReactQuill from 'react-quill';
import moment from "moment";
import 'moment/locale/pt-br';

import print from '../reports/print-style.js';
import print_lawsuit from './print.lawsuit.css.js';

import company_logo from '../json/company_logo';

import OfficeService from '../../services/OfficeService';
import LawsuitService from '../../services/LawsuitService';
import UsersService from '../../services/UsersService';

export default class PrintLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  componentWillReceiveProps(nextProps) {
    if ( nextProps.match.params.id !== this.props.match.params.id) {
      this.lawsuitService = new LawsuitService();
      this.lawsuitService.get( nextProps.match.params.id ).then(res =>{
        this.setState({
          lawsuit: res,
          id: nextProps.match.params.id
        });
      });

    }
  }

  constructor( { match } ) {
      super();
      this.lawsuitService = new LawsuitService();

      this.state = {
        id: match.params.id,
        lawsuit: {
          tribunal: {},
          valorCausa: "",
          lawsuits_personages: [],
          movements: [{id: 1, name: "", date: ""}],
          token: 0,
        },
        user: {
          name: ""
        },
        office: {
          name: "AVVOCATO SISTEMAS LTDA"
        },
        logo: company_logo,
      };
  }

  componentWillMount() {

    this.getHeader();

  }

  getHeader = () => {
      this.OfficeService = new OfficeService();
      let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;//'4eb7f0a8-4541-4e96-890e-9318b232f945';

      //console.log("carregou");

      this.OfficeService.getConfigs( token ).then(res =>{

            let logo = (res.logo !== null ) ? res.logo : this.state.logo;

            this.setState({
              office: {
                name: res.cabecalho
              },
              logo: logo
            });
      });
  }

  componentDidMount( ) { this._isMounted = true;

    this.lawsuitService = new LawsuitService();
    this.usersService = new UsersService();

    this.lawsuitService.get( this.props.match.params.id ).then(res =>{
      this.setState({
        lawsuit: res,
      });
    });

    this.usersService.list( ).then( users =>{

      users.map( (user, index, array ) =>{
          if(user.value === this.state.lawsuit.user_registration_token){
            this.setState({
              user: {
                name: user.label
              }
            });
          }
      });

    });

  }

  setStyle( print ) {
    return { __html: print[0].key };
  }

  getClientOrOponent ( record, side ) {

    let name = []; let response = "";

    //console.log( record );

    record.lawsuits_personages.sort(function(a, b){
      //console.log(a);
      if ( moment(a.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() < moment(b.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() ){
        return -1;
      }else{
        return 1;
      }

    });

    record.lawsuits_personages.map((value, index, array )=>{
      //console.log(value);

      if ( value.personage_side_id === side ) {
        name.push( value.personage.name );
      }

      return "";

    });

    if( name.length >= 2 ) {
      response = name[0]
    }else{
      response = name[0];
    }
    return response;

  }

  componentDidUpdate = () => {

  }

  parteItem = ( {record, index}, type ) => {

      return (
        <tr key={record.token}>
              <td className="type_personage">
                    <label>
                        {record.personage_type_name}
                    </label>
              </td>
              <td className="personage_name" title={record.personage.name} >
                      {record.personage.name}
              </td>
        </tr>


      );
  };

  movementItem = ( {record, index}, type ) => {

        return (
          <tr key={record.key} className={type}>
                <td className="number_movement">
                      {record.numero_andamento}
                </td>
                <td className="date " >
                      {record.date.substr(0, 10).split('-').reverse().join('/')}
                </td>
                <td className="movement" >
                      <span className="andamentos">
                        {record.name}
                      </span>
                </td>
          </tr>


        );
    };

  modules = {
    toolbar: [

    ],
  };

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'image', 'align',
    'font', 'color', 'size'
  ];

  render() {
      var url_base = localStorage.getItem('url_base');

     //console.log( this.state.lawsuit );
      //let cliente = this.getClientOrOponent( this.state.lawsuit, 1 );
      //let oponente = this.getClientOrOponent( this.state.lawsuit, 2 );

      var personas  = [];

      this.state.lawsuit.lawsuits_personages.map((value, index, array )=>{
        personas[index] = value.personage;
        personas[index].key = value.personage.token;

        return personas;
      });

      let movements  = []; let i = 0;

      this.state.lawsuit.movements.map((value, index, array )=>{
          movements[index] = value;
          movements[index].key = value.id;
          movements[index].numero_andamento = array.length-i;
          i++;
          return movements;
      });

      return <div>
                  <style dangerouslySetInnerHTML={ this.setStyle(print) } />
                  <style dangerouslySetInnerHTML={ this.setStyle(print_lawsuit) } />
                  <table id="table-layout">

                        <thead>
                          <tr>
                            <th>
                                  <div className="page-header-space">
                                          <div className="topo">
                                                <div className="headline">
                                                      <div className="image-top">
                                                            <img alt="dfoli" src={"data:image/png;charset=utf-8;base64, "+this.state.logo} width="150px"  />
                                                      </div>
                                                      <div id="office" className="office">
                                                              <ReactQuill
                                                                  theme="snow"
                                                                  readOnly={true}
                                                                  value={this.state.office.name}
                                                                  modules={this.modules}
                                                                  formats={this.formats}
                                                              />
                                                      </div>
                                                </div>
                                          </div>
                                  </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                                <tr>
                                    <td>
                                        <div className="controls">
                                            <button onClick={ () => window.print() }>Imprimir</button>
                                        </div>

                                        <section className="sectionLawsuit">
                                            <h1>Dados do processo</h1>
                                            <table>
                                                <tbody>
                                                      <tr>
                                                          <td style={{fontWeight: 'bold'}}>Pasta</td>
                                                          <td>{ ( typeof this.state.lawsuit.pasta !== "undefined") ? this.state.lawsuit.pasta : "Não Definida" }</td>
                                                          <td style={{fontWeight: 'bold'}}>Área</td>
                                                          <td>{ ( typeof this.state.lawsuit.area !== "undefined") ? this.state.lawsuit.area : "Não Definida" }</td>
                                                      </tr>
                                                      <tr>
                                                          <td style={{fontWeight: 'bold'}}>Responsável</td>
                                                          <td>Dércio Ferreira</td>
                                                          <td style={{fontWeight: 'bold'}}>Cadastrado por</td>
                                                          <td>{this.state.user.name}</td>
                                                      </tr>
                                                      <tr>
                                                          <td style={{fontWeight: 'bold'}}>Data de Distribuição</td>
                                                          <td>{ ( typeof this.state.lawsuit.dataDistribuicao !== "undefined" && this.state.lawsuit.dataDistribuicao !== null) ? this.state.lawsuit.dataDistribuicao.substr(0, 10).split('-').reverse().join('/')+" "+this.state.lawsuit.dataDistribuicao.substr(12, 7) : "Não Definida"}</td>
                                                          <td style={{fontWeight: 'bold'}}>Data de Cadastro</td>
                                                          <td>{ ( typeof this.state.lawsuit.created !== "undefined") ? this.state.lawsuit.created.substr(0, 10).split('-').reverse().join('/')+" "+this.state.lawsuit.created.substr(12, 7) : "Não Definida"}</td>
                                                      </tr>
                                                      <tr>
                                                          <td style={{fontWeight: 'bold'}}>Órgão Julgador</td>
                                                          <td>{ ( typeof this.state.lawsuit.orgaoJulgador !== "undefined") ? this.state.lawsuit.orgaoJulgador : "Não Definida" }</td>
                                                          <td style={{fontWeight: 'bold'}}>Classe</td>
                                                          <td>{ ( typeof this.state.lawsuit.classe !== "undefined") ? this.state.lawsuit.classe : "Não Definida" }</td>
                                                      </tr>
                                                      <tr>
                                                          <td style={{fontWeight: 'bold'}}>Assunto</td>
                                                          <td colSpan="3" >{ ( typeof this.state.lawsuit.assunto !== "undefined") ? this.state.lawsuit.assunto : "Não Definida" }</td>
                                                      </tr>
                                                </tbody>
                                            </table>
                                        </section>

                                        <section className="sectionLawsuit">
                                            <h1>Partes do Processo</h1>
                                            <table className="ant-table-content">
                                                    <thead>
                                                        <tr>
                                                              <th className="type_personage tipo ">
                                                                  <div>Tipo</div>
                                                              </th>
                                                              <th className="table_parte">
                                                                  <div>Parte</div>
                                                              </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { ( Array.isArray(this.state.lawsuit.lawsuits_personages) ) ? this.state.lawsuit.lawsuits_personages.map( (record, index) => this.parteItem( {record, index}, 'parte-item' ) ) : null }
                                                    </tbody>
                                            </table>
                                        </section>

                                        <section className="sectionLawsuit">
                                            <h1>Andamentos do Processo</h1>
                                            <table className="ant-table-content">
                                                    <thead>
                                                        <tr>
                                                              <th className="number_movement">
                                                                  <div>N°</div>
                                                              </th>
                                                              <th className="date ">
                                                                  <div>Data</div>
                                                              </th>
                                                              <th className="movement">
                                                                  <div>Andamentos</div>
                                                              </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { ( Array.isArray(movements) ) ? movements.map( (record, index) => this.movementItem( {record, index}, 'mvt-item' ) ) : null }
                                                    </tbody>
                                            </table>
                                        </section>


                                      </td>
                                  </tr>
                          </tbody>
                          <tfoot>
                                  <tr>
                                        <td>
                                          <div className="page-footer-space"></div>
                                        </td>
                                  </tr>
                          </tfoot>
                  </table>
             </div>
  }

}
