import React from 'react';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from "./containers/footer";
import 'antd/dist/antd.css';
import { Divider } from 'antd';
import FlashMessage from "./containers/FlashMessage";

import Loading from './containers/loading';

import LawsuitPersonagesService from '../services/LawsuitPersonagesService';


export default class Partes extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(...args) {
    super(...args)
    this.lawsuitPersonagesService = new LawsuitPersonagesService();


    this.deletePersonageLawsuit = this.deletePersonageLawsuit.bind(this);
    this.openEditPersonage = this.openEditPersonage.bind(this);

    window.scrollTo(0,0);

    this.state = {
        partes: [],
        loadingVisible: false,
        loadingMessage: []
    }
  }

  componentDidMount( ) { 
      this._isMounted = true;
      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

      this.getPartes();

  }

  componentDidUpdate(){

  }

  getPartes(){
    this.lawsuitPersonagesService = new LawsuitPersonagesService();

    this.lawsuitPersonagesService.list( ).then(res =>{
          if (this._isMounted) {
              this.setState({
                partes: res,
              });
          }
    });
  }

  openEditPersonage( e ){
    this.props.history.push( '/partes/edit/'+e.target.dataset.personage_token);
  }

  deletePersonageLawsuit( e ) {
    document.body.style.cursor = "wait";

    let result = window.confirm( "Deseja excluir definitivamente esta Parte do processo?");

    if ( result ) {

      this.LawsuitPersonages = new LawsuitPersonagesService();

      this.LawsuitPersonages.delete( e.target.dataset.personage_token ).then(res =>{

          if (this._isMounted) {

                if( res.success === true ){
                    localStorage.setItem('alert_message', "Parte excluída com sucesso");
                    localStorage.setItem('alert_type', 'alert-success');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                    this.getPartes();

                }else{
                    //alert(res.message);
                }
                document.body.style.cursor = "default";

          }

      });

    }

  }

  parteItem = ( {record, index}, type ) => {

      return (
        <tr key={record.value} className={type+" only"}>
              <td className="personage_name hidden-xs hidden-sm" title={record.label} >
                      {record.label}
              </td>
              <td className="actions">
                    <span className="pointer pull-right bt-edit movement-insert" id="bt-edit-dados-processo">
                        <i className="fa fa-pencil-square-o" data-personage_token={record.value} onClick={this.openEditPersonage}></i>
                        <span className="edit-label">Editar</span>
                    </span>
                    <Divider type="vertical" />
                    <i className="fa fa-trash" data-personage_token={record.value} onClick={this.deletePersonageLawsuit}></i>
              </td>
        </tr>


      );
  };


  render() {

      return <div className="sheet list-lawsuit">
                <FlashMessage time={ new Date().getTime() } />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Partes</h1>
                        </section>
                        <div className="box-body">
                            <table className="partes-only-table">
                                    <thead>
                                        <tr>
                                              <th className="table_lawsuit_number">
                                                  <div>Nome</div>
                                              </th>
                                              <th className="table_lawsuit_actions">
                                                  <div>Ações</div>
                                              </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                          { ( typeof this.state.partes !== "undefined") ? this.state.partes.map( (record, index) => this.parteItem({ record, index }, 'partes-item') ) : null }
                                    </tbody>
                            </table>
                        </div>
                  </div>
                </div>
             </div>
  }

}
