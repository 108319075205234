import React from 'react';
import LawsuitPersonagesService from '../../../services/LawsuitPersonagesService';

export default class CoverClientInfos extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);
    this.LawsuitPersonagesService = new LawsuitPersonagesService();

    this.state = {
      client: { personage: [] }
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props ) {

      this.LawsuitPersonagesService = new LawsuitPersonagesService();

      if ( typeof nextProps.client !== "undefined" ) {

            this.LawsuitPersonagesService.view( nextProps.client.personage.token ).then( personage =>{

              this.setState({
                client: personage,
              });

            });

      }

    }
  }

  componentDidMount( ) { 
    this._isMounted = true;

    this.LawsuitPersonagesService = new LawsuitPersonagesService();

    if ( typeof this.props.client !== "undefined" ) {
      this.LawsuitPersonagesService.view( this.props.client.personage.token ).then( personage =>{

        //console.log("oi");

        this.setState({
          client: personage,
        });

      });
    }
  }

  contactContainer = ({ contact, index }) => {

   //console.log(contact);
   //console.log(index);

      if ( index === 0 ) {
        return (
          <span key={index}>
              <label>TELEFONE: </label><p><i className="fa fa-phone"></i> {contact.value}</p>
          </span>
        );
      }else if ( index === 1 ){
        return (
          <span key={index}>
              <label>CELULAR: </label><p><i className="fa fa-phone"></i> {contact.value}</p>
          </span>
        );
      }else {
        return "";
      }

  };

  render() {

          let firstClient;

          firstClient = this.state.client;

          return (

            <div className="contato">
              {
                ( typeof firstClient.personage_contacts !== "undefined" ) ? firstClient.personage_contacts.map( (contact, index) =>
                  this.contactContainer({ contact, index }) ) : ""
              }
            </div>
          )


  }
}
