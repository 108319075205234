import React from 'react';
import { Switch } from 'antd';

import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import Loading from '../containers/loading';
import PrintComponent from './printComponent';
import MyPopover from '../containers/mypopover';

import LawsuitService from '../../services/LawsuitService';
import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';
import FinanceService from '../../services/FinanceService';

import print from './print-style.js';

const statusList = [
  {label: "Todos", value: 0},
  {label: "Ativo", value: 1},
  {label: "Arquivado", value: 2},
];

const typeList = [
  {label: "Processos com Saldo Positivo e Negativo", value: 1 },
  {label: "Processos com Saldo Positivo", value: 2 },
  {label: "Processos com Saldo Negativo", value: 3 },
  {label: "Provisão de Recebimento", value: 4 },
  {label: "Provisão de Pagamento", value: 5 },
];

export default class ReportFinanceList extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });
  }

  constructor( { match } ) {
      super();
      const select = { value: '0', label: 'Todos' };
      let tipo = select; let lawsuit = select; let client = select; let status = select; let categoria = select;
      let centroCusto = select;

      this.back = this.back.bind(this);
      this.modifiedViewPrint = this.modifiedViewPrint.bind(this);

     //console.log( match );

      this.state = {
        tipo: tipo,
        status: status,
        client: client,
        lawsuit: lawsuit,
        categoria: categoria,
        centroCusto: centroCusto,
        params: match.params,
        financeList: [],
        qtd: 4,
        print: 0,
        loadingVisible: false,
        loadingMessage: [],
        office: {
          name: "DFOLI COMERCIO E SERVIÇOS LTDA"
        }
      }

  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.history.location.pathname !== this.props.history.location.pathname  ) {
      this.initParams( nextProps.match.params );
    }

  }

  componentWillMount() {

    this.initParams( this.props.match.params );

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  initParams( params ){

    const select = { value: '0', label: 'Todos' };
    let tipo = select; let status = select; let client = select; let lawsuit = select; let categoria = select;
    let centroCusto = select; let new_list = []; let total = 0;

    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.lawsuitService = new LawsuitService();
    this.FinanceService = new FinanceService();

    this.setState({
      loadingVisible: true,
      loadingMessage: ["O relatório está sendo carregado.", "Por gentileza, aguarde...", "Tempo estimado de 20 a 60 segundos"]
    });

    this.FinanceService.report( params.client, params.lawsuit, params.categoria, params.type, params.status, params.centroCusto, params.excluirRepassesFuturos, params.excluirRecebimentoFuturos ).then(list =>{

        //console.log(list);

        list.map( ( lancamento ) => {
          let saldo = 0;

          lancamento.entries.map( ( entry ) => {
            if(entry.financial_type_id === 1){
              saldo += entry.valor;
            }
            if(entry.financial_type_id === 2){
              saldo -= entry.valor;
            }
            return entry;
          });

          lancamento.saldo = saldo;

          if(params.type === "1"){
            new_list.push(lancamento);
            total += saldo;
          }else if (params.type === "2" && saldo >= 0){
            //console.log(lancamento);
            new_list.push(lancamento);
            total += saldo;
          }else if (params.type === "3" && saldo < 0){
            new_list.push(lancamento);
            total += saldo;
          }else if ( params.type === "4" || params.type === "5") {
            new_list.push(lancamento);
            total += saldo;
          }

          return lancamento;

        });

        this.setState({
          qtd: new_list.length/2,
          financeList: new_list,
          loadingVisible: false,
          total: this.formataMoeda(total/2)
        });
    });

    this.lawsuitService.officeList().then(lawsuitList =>{

        this.LawsuitPersonagesService.list( ).then(clientList =>{

          this.FinanceService.categoryList().then(categoryList =>{

            this.FinanceService.centroCustoList().then(centroCustoList =>{

                  if( params.type !== "0" ){
                    //console.log(params.type);
                    //console.log(typeList);
                    tipo = typeList.filter( (type)=>{ return type.value.toString() === params.type } )[0];
                    //console.log(tipo);
                  }
                  if( params.status !== "0" ){
                    status = statusList.filter( (stat)=>{ return stat.value.toString() === params.status } )[0];
                  }
                  if( params.client !== "0" ){
                    client = clientList.filter( (client)=>{ return client.value === params.client } )[0];
                  }
                  if( params.lawsuit !== "0" ){
                    lawsuit = lawsuitList.filter( (lawsuit)=>{ return lawsuit.value === params.lawsuit } )[0];
                  }
                  if( params.categoria !== "0" ){
                    categoria = categoryList.filter( (cat)=>{ return cat.value === params.categoria } )[0];
                  }
                  if( params.centroCusto !== "0" ){
                    centroCusto = centroCustoList.filter( (cat)=>{ return cat.value === params.centroCusto } )[0];
                  }
                  this.setState({
                    tipo: tipo,
                    status: status,
                    client: client,
                    categoria: categoria,
                    centroCusto: centroCusto,
                    params: params,
                    lawsuit: lawsuit,
                    loadingVisible: false
                  });
            });
          });

        });

    });



  }

  back(){
    this.props.history.goBack();
  }

  setStyle() {
    return { __html: print[0].key };
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  onSwitchChange = (checked) => {
        if ( checked ){
          this.setState({
            showOnReport: true,
          });

        }else{
          this.setState({
            showOnReport: false,
          });
        }
  }

  screen = (event) => {
    this.setState({
      print: 0,
    });
  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else{
      valor = valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  entryItem = (entry, index) => {
    let date = entry.data_vencimento.substr(0, 10).split('-').reverse().join('/') ;
    let valor = "R$ "+this.formataMoeda(entry.valor);
    let tipo_operacao = (entry.financial_type_id === 1) ? "Crédito" : "Débito";


    return (
      <tr key={index} className={(entry.financial_type_id === 1) ? "tcredito" : "tdebito"}>
              <td className="">
                  {date}
              </td>
              <td className="">
                  {tipo_operacao}
              </td>
              <td className="" >
                  {valor}
              </td>
              <td className="" >
                  {entry.financial_category.name}
              </td>
      </tr>
    );
  }

  financeItem = ( {record, index}, type ) => {

    let saldo = "R$ "+this.formataMoeda(record.saldo);

    if(index % 2 === 0){
      return (
        <tr key={index} className={type+( ( this.state.params.exibir_detalhes === "true" ) ? " bg-info" : "" )}>
                <td className="lawsuit">
                    {record.number}
                </td>
                <td className="">
                    {record.cliente}
                </td>
                <td className="date" >
                    {saldo}
                </td>
        </tr>
      )
    }else if( this.state.params.exibir_detalhes === "true" ){
      return (
        <tr key={index} className={type}>
                <td colSpan="3" >
                    <strong>Extrato Financeiro</strong>
                    <div className="table-responsible">
                          <table className="table table-condensed">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Tipo</th>
                                        <th>Valor</th>
                                        <th>Categoria</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { record.entries.map( (entry, index) =>  this.entryItem( entry, index )  ) }
                                </tbody>
                          </table>
                    </div>
                </td>
        </tr>
      )
    }

  }

  render() {
      //let url_base = localStorage.getItem('url_base');

      //console.log(this.state.financeList)

      let panel_filtros = <div className="panel panel-default container-publicacoes">
                                    <div className="panel-heading text-center">
                                        <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                                            <strong>Filtros Utilizados</strong>
                                        </div>
                                    </div>
                                    <div className={ (this.state.showOnReport) ? "panel-body" : "panel-body collapse" } id="filter_target">
                                          <ul>
                                              <li className={(this.state.params.type === "0")?"hide": ""}><b>Tipo de Saldo: </b> <span>{this.state.tipo.label}</span> </li>
                                              <li className={""}><b>Status do Processo: </b> <span>{this.state.status.label}</span> </li>
                                              <li className={(this.state.params.client === "0")?"hide": ""}><b>Nome do Cliente: </b> <span>{this.state.client.label}</span> </li>
                                              <li className={(this.state.params.lawsuit === "0")?"hide": ""}><b>Número do Processo: </b> <span>{this.state.lawsuit.label}</span> </li>
                                              <li className={(this.state.params.categoria === "0")?"hide": ""}><b>Categoria: </b> <span>{this.state.categoria.label}</span> </li>
                                              <li className={(this.state.params.centroCusto === "0")?"hide": ""}><b>Centro de Custos: </b> <span>{this.state.centroCusto.label}</span> </li>
                                          </ul>
                                          <label id="showOnReportContainer" className="right">
                                                  <MyPopover icon="white" text='Se desejar que os filtros do relatório apareçam no topo dos relatórios' />
                                                  Aparecer no relatório
                                                  <Switch title={(this.state.showOnReport)? "Desbloqueado": "Bloqueado"} checked={this.state.showOnReport} onChange={ this.onSwitchChange }  />
                                          </label>
                                    </div>
                          </div>;

      let table = <table id="reportFinanceList" className={"reportFinanceList "}>
                        <thead>
                            <tr>
                                  <th className="">
                                      <div>Processo</div>
                                  </th>
                                  <th className="">
                                      <div>Cliente</div>
                                  </th>
                                  <th className="date">
                                      <div>Saldo</div>
                                  </th>
                            </tr>
                        </thead>
                        <tbody>
                            { (this.state.financeList.length > 0) ? this.state.financeList.map( (record, index) =>  this.financeItem( {record, index}, 'report-finance-item' )  ) : <tr><td colSpan="3"><center>Nenhum lançamento encontrado</center></td></tr> }
                        </tbody>
                  </table>;

        let content =  <div className="">
                                      <div className="resultado-acumulado col-md-6">
                                      Total dos Saldos = <b >R$ {this.state.total}</b>
                                      </div>
                                {table}
                                <div className="resultado-periodo valor-total">
                                  Total dos Saldos: <b >R$ {this.state.total}</b>
                                </div>
                        </div>;

      if( this.state.print === 1 ){
          if( !this.state.showOnReport ){
            panel_filtros = "";
          }
          return <div>
                    <PrintComponent modifiedViewPrint={this.modifiedViewPrint} panel_filtros={panel_filtros} content={content} title={"Relatório Financeiro"} subtitle={"Clientes"} />
               </div>
      }else{
        return <div className="sheet financeList">
                  <HeaderBar />
                  <NavigationMenu />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                  <div className="content-wrapper">
                    <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Relatórios - Financeiro Listagem</h1>
                          <span className="btn btn-info" onClick={this.back} >Voltar</span>
                          <span className="btn btn-default btn-report right" onClick={(event)=>{this.print(event)} }>Imprimir</span>
                        </section>
                        <div className="box-body">
                            <div className="alert alert-info" role="alert">Quantidade de registros encontrados: <strong>{this.state.qtd}</strong> </div>

                            {panel_filtros}
                            <div className="">
                                  <div className="resultado-acumulado col-md-6">
                                  Total dos Saldos = <b >R$ {this.state.total}</b>
                                  </div>
                            </div>

                            {table}
                            <div className="resultado-periodo valor-total">
                              Total dos Saldos: <b >R$ {this.state.total}</b>
                            </div>

                        </div>
                    </div>
                  </div>
               </div>
      }

  }

}
