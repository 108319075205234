import React from 'react';
import Select from 'react-select';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';

import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import DataLawsuit from './containers/dataLawsuit';
import AdditionalDataLawsuit from './containers/additionalDataLawsuit';
import RelatedLawsuits from './containers/relatedLawsuits';
import PersonagesLawsuit from './containers/personagesLawsuit';
import MovementsLawsuit from './containers/movementsLawsuit';

import PedidosLawsuit from './containers/pedidosLawsuit';
import RecursosLawsuit from './containers/recursosLawsuit';

import PublicationsLawsuit from './containers/publicationsLawsuit';
import EventLawsuit from './containers/scheduleLawsuit';
import FinanceLawsuit from './containers/financeLawsuit';
import InfoLawsuit from './containers/infoLawsuit';
import GED from './containers/ged';
import ClientSection from './containers/clientSection';
import MyPopover from '../containers/mypopover';

import LawsuitService from '../../services/LawsuitService';
import MovementService from '../../services/MovementService';
import AuthService from '../../services/AuthService';

import Loading from '../containers/loading';
import Alert from '../containers/alert';
import Confirm from '../containers/confirm';

import Footer from "../containers/footer";
import FlashMessage from "../containers/FlashMessage";

export default class DetailsLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( { match } ) {
      super();
      this.lawsuitService = new LawsuitService();
      this.authService = new AuthService();

      this.handleChange = this.handleChange.bind(this);
      this.selectChange = this.selectChange.bind(this);
      this.toRelate = this.toRelate.bind(this);
      this.modifiedPersonages = this.modifiedPersonages.bind(this);
      this.modifiedLawsuit = this.modifiedLawsuit.bind(this);
      this.modifiedMovements = this.modifiedMovements.bind(this);
      this.chargeModal = this.chargeModal.bind(this);
      this.back = this.back.bind(this);

      window.scrollTo(0,0);

      this.state = {
        id: match.params.id,
        message: "",
        showConfirm: false,
        showAlert: false,
        ajaxComplete: false,
        lawsuitList: [],
        lawsuitListCharge: 0,
        lawsuitToRelate: "",
        fireMessage: null,
        updated: "",
        loadingVisible: false,
        loadingMessage: ['Carregando dados do processo'],
        isArchivePopoverOpen: false,
        isRelatedPopoverOpen: false,
        isPrintPopoverOpen: false,
        isHighlightPopoverOpen: false,
        lawsuit: {
          tribunal: {},
          tribunal_id: 0,
          valorCausa: "",
          lawsuits_personages: [{token:1, personage_type_name:"", personage: "", modified: "1970-01-01 10:00"},{token: 2, personage_type_name:"", personage:"",modified: "1970-01-01 09:00"}],
          movements: [{id: 1, name: "", date: ""}],
          token: match.params.id,
          formIsHalfFilledOut: 1,
        }
      };
  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.match.params.id !== this.props.match.params.id) {

          this.lawsuitService = new LawsuitService();

          this.lawsuitService.get( nextProps.match.params.id ).then(res =>{

            if( this._isMounted ){


              if (res.arquivo === 0) {
                document.getElementById("highlight-button").classList.remove("hide");
              }/*else {
                document.getElementById("highlight-button").className += ' hide';
              }*/

              let lawsuit = res;

              this.lawsuitService.officeList().then(response =>{

                    if( this._isMounted ){

                      let lawsuitList = [];

                      for( let i=0; i < response.length; i++ ){
                        if( response[i].value !== lawsuit.token ){
                          lawsuitList.push( response[i] );
                        }
                      }

                      this.setState({
                        lawsuit: lawsuit,
                        highlight: lawsuit.favorito,
                        archived: lawsuit.arquivo,
                        lawsuitList: lawsuitList,
                        lawsuitListCharge: 1,
                        lawsuitToRelate: "",
                        id: nextProps.match.params.id,
                      });

                    }

              });
              
            }

          });

          var url_string = window.location.href;
          var url = new URL(url_string);
          var parte = url.searchParams.get("parte");
          var movements = url.searchParams.get("movements");
         //console.log(parte);//console.log(movements);
          if ( parte !== null ) {
            document.getElementById("personages-lawsuit-target").className += ' in';
            setTimeout(() => {
              window.scrollTo(0, 621)
            }, 2200);
          }else if( movements !== null ){
            document.getElementById("movements-lawsuit-target").className += ' in';
            setTimeout(() => {
              window.scrollTo(0, 751);
              this.MovementService = new MovementService();
      
              /*código de deletar movement*/
              this.MovementService.deleteMovements( nextProps.match.params.id ).then( response => {
      
              });
            }, 2200);
          }else{
            window.scrollTo(0,0);
          }

    }

  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,          
          });
        }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          if( this.state.action === "deleteLawsuit" ){
              this.deleteLawsuit( this.state.lawsuit.token, true);
          }else if( this.state.action === "toArchive" ){
              this.toArchive(this.state.lawsuit.token, this.state.archived, true);
          }
        }
    });

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.lawsuitService = new LawsuitService();

    this.lawsuitService.get( this.props.match.params.id ).then(res =>{

              if( this._isMounted ){

                    if(typeof res.arquivo !== "undefined"){

                          if (res.arquivo === 0 && document.getElementById("highlight-button") !== null ) {
                            document.getElementById("highlight-button").classList.remove("hide");
                          }/*else {
                            document.getElementById("highlight-button").className += ' hide';
                          }*/

                          let lawsuit = res;

                          this.lawsuitService.officeList().then(response =>{

                                  if( this._isMounted ){

                                            let lawsuitList = [];

                                            for( let i=0; i < response.length; i++ ){
                                              if( response[i].value !== lawsuit.token ){
                                                lawsuitList.push( response[i] );
                                              }
                                            }

                                            this.setState({
                                              lawsuit: lawsuit,
                                              ajaxComplete: true,
                                              highlight: lawsuit.favorito,
                                              archived: lawsuit.arquivo,
                                              lawsuitList: lawsuitList,
                                              lawsuitListCharge: 1
                                            });
                                  }

                          });

                    }else{
                      localStorage.setItem('alert_message', res.message);
                      localStorage.setItem('alert_type', 'alert-danger');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                      //console.log(res);
                      setTimeout(() => {
                        this.setState({
                          fireMessage: time,
                          updated: time,
                          lawsuit: res,
                          ajaxComplete: true
                        });
                      }, 100);
                    }

                    setTimeout(() => {
                      //console.log(parte);//console.log(movements);
                        window.scrollTo(0,0);
                    }, 1000);
              }

          });

  }

  componentDidUpdate = () => {

    if (this.state.archived === 0) {
      if( document.getElementById("highlight-button") !== null ){
          document.getElementById("highlight-button").classList.remove("hide");
      }
    }else{
      if( document.getElementById("highlight-button") !== null ){          
          document.getElementById("highlight-button").className += ' hide';
      }
    }

  }

  handleChange(e){
       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   addToHighlight = () => {

         document.body.style.cursor = "wait";

         let token = ""; let highlight = "";
         token = this.state.lawsuit.token;
         highlight = this.state.highlight;

         this.lawsuitService.addToHighlight( token )
             .then(res =>{
                  if( this._isMounted ){
                        if( res.success === true ){

                            if ( highlight === 0 ) {
                              localStorage.setItem('alert_message', "Processo destacado com sucesso.");
                              localStorage.setItem('alert_type', 'alert-success');
                              let time = new Date().getTime();
                              localStorage.setItem('alert_time', time );
                              setTimeout(() => {
                                this.setState({
                                  highlight: 1,
                                  updated: time
                                });
                              }, 100);

                            }else{
                              localStorage.setItem('alert_message', "Processo removido dos destacados.");
                              localStorage.setItem('alert_type', 'alert-success');
                              let time = new Date().getTime();
                              localStorage.setItem('alert_time', time );
                              setTimeout(() => {
                                this.setState({
                                  highlight: 0,
                                  updated: time
                                });
                              }, 100);
                            }


                        }else{
                          //alert(res.message);
                          this.setState({
                            message: res.message
                          });
                        }
                        document.body.style.cursor = "default";

                  }

             })
             .catch(err =>{
                 document.body.style.cursor = "default";
                //console.log(err);
             })
   }

   addToArchive = (token, archived) => {

         document.body.style.cursor = "wait";         

         this.lawsuitService.addToArchive( token )
         .then(res =>{
              if( this._isMounted ){
                       //console.log(archived);
                       //console.log(this.state.highlight);
                        if( archived != "1" && this.state.highlight === 1 ){
                          this.lawsuitService.addToHighlight( token ).then(res =>{
                          });
                         //console.log("processo arquivado e removido dos destaques");
                        }

                        document.body.style.cursor = "default";
                        if( res.success === true ){

                          if ( archived === 1 ) {
                            localStorage.setItem('alert_message', "Processo desarquivado com sucesso.");
                            localStorage.setItem('alert_type', 'alert-success');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );

                            setTimeout(() => {
                                this.setState({
                                  archived: 0,
                                  updated: time,
                                });
                              }, 200);

                          }else{
                            localStorage.setItem('alert_message', "Processo arquivado com sucesso.");
                            localStorage.setItem('alert_type', 'alert-success');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );

                            this.setState({
                              formIsHalfFilledOut: false,
                              archived: 1,
                              updated: time,
                            });
                            this.props.history.goBack();

                          }

                        }else{
                          localStorage.setItem('alert_message', res.message);
                          localStorage.setItem('alert_type', 'alert-danger');
                          let time = new Date().getTime();
                          localStorage.setItem('alert_time', time );
                          setTimeout(() => {
                            this.setState({
                              updated: time,                            
                            });
                          }, 100);
                        }

              }

         })
         .catch(err =>{
             document.body.style.cursor = "default";
            //console.log(err);
         })
   }

   deleteLawsuit( token, r = false ){

         //var r = window.confirm("Este processo e todas as informações, arquivos e documentos serão excluídos definitivamente. Esta ação é irreversível. Deseja realmente excluí-lo?");

         if ( r ){
           this.lawsuitService.delete( token )
               .then(res =>{

                    if( this._isMounted ){

                          if( res.success === true ){
                            localStorage.setItem('alert_message', res.message);
                            localStorage.setItem('alert_type', 'alert-success');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );

                              setTimeout(() => {
                                if(  this.state.lawsuit.tribunal !== null ){
                                  this.props.history.push( '/processo/listar_processo/'+this.state.lawsuit.tribunal.slug.toLowerCase() );
                                }else{
                                  this.props.history.push( '/processo-manual' );
                                }
                              }, 1200);

                          }else{
                            localStorage.setItem('alert_message', res.message);
                            localStorage.setItem('alert_type', 'alert-danger');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );
                            setTimeout(() => {
                              this.setState({
                                updated: time,
                              });
                            }, 100);
                          }
                    }
               })
               .catch(err =>{
                  //console.log(err);
                   this.setState({
                      showConfirm: false,
                    });
               })
         }else{
                this.setState({
                  showConfirm: true,
                  action: "deleteLawsuit",
                  message: "Este processo e todas as informações, arquivos e documentos serão excluídos definitivamente. Esta ação é irreversível. Deseja realmente excluí-lo?"
                });
         }

    }

   modifiedPersonages(){
    //console.log("executado");
     this.lawsuitService.get( this.state.id ).then(res =>{

              if( this._isMounted ){

                      this.setState({
                        lawsuit: res,
                      });
              }
     });
   }

   modifiedMovements = this.modifiedPersonages;

   modifiedPublications = this.modifiedPersonages;

   modifiedLawsuit = this.modifiedPersonages;

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
         this.setState( pops );
       }

     }
   }

   chargeModal(){

        if( this._isMounted ){
              document.body.style.cursor = "wait";
              document.getElementById("btn-vincular").disabled = true;
        
              if ( this.state.lawsuitListCharge === 1 ){
                document.body.style.cursor = "default";
                document.getElementById("btn-vincular").disabled = false;
              }else{
                var self = this;
                var interval = setInterval(function( ){
                  if ( self.state.lawsuitListCharge === 1 ){
        
                    document.body.style.cursor = "default";
                    document.getElementById("btn-vincular").disabled = false;
                    clearInterval(interval);
                  }
                }, 500);
              }
        }

   }

   toArchive ( token, archived, r = false ){

      if ( this.state.archived === 0 && this.state.highlight === 1 ) {
        //window.confirm("Este processo possui Destaque e ao arquiva-lo será excluído dos destacados.")
        if ( r ){
            this.addToHighlight( );
            this.addToArchive( token, archived );
        }else{
          this.setState({
            showConfirm: true,
            action: "toArchive",
            message: "Este processo possui Destaque e ao arquiva-lo será excluído dos destacados."
          });
        }
      }else {
         //console.log("archived: "+this.state.archived+" highlight: "+this.state.highlight);
        this.addToArchive( token, archived );
      }
   }

   toRelate ( event ){
     document.body.style.cursor = "wait";
     event.preventDefault();

     this.setState({
       loadingVisible: true,
       loadingMessage: ["O vínculo está sendo efetuado.", "Por gentileza, aguarde..."]
     });

     let token = this.state.lawsuit.token;

     /*if ( document.getElementById("relatedList") !== null ){
       let relatedList = JSON.parse(document.getElementById("relatedList").value);
      //console.log(relatedList);
       let second_lawsuit = this.state.lawsuitToRelate.value;
       for(var i=0; i < relatedList.length; i++){

         this.lawsuitService.toRelate( {'lawsuit_token': relatedList[i].token, 'lawsuit_related_token': second_lawsuit } )
             .then(res =>{

             });
       }

     }*/

     let data = {
       'lawsuit_token': token,
       'lawsuit_related_token': this.state.lawsuitToRelate.value
     }

     this.lawsuitService.toRelate( data )
         .then(res =>{

                  if( this._isMounted ){
                              if( res.success === true ){

                                localStorage.setItem('alert_message', res.message);
                                localStorage.setItem('alert_type', 'alert-success');
                                let time = new Date().getTime();
                                localStorage.setItem('alert_time', time );

                                this.setState({
                                  loadingVisible: false,
                                  loadingMessage: []
                                });

                                setTimeout(() => {
                                  this.setState({
                                    lawsuitToRelate: "",
                                    updated: time
                                  });
                                }, 900);

                              }else{
                                localStorage.setItem('alert_message', "Ocorreu um erro ao vincular processo.");
                                localStorage.setItem('alert_type', 'alert-danger');
                                let time = new Date().getTime();
                                localStorage.setItem('alert_time', time );
                                setTimeout(() => {
                                  this.setState({
                                    updated: time,
                                  });
                                }, 100);
                              }
                              document.body.style.cursor = "default";
                              this.setState({
                                loadingVisible: false,
                                loadingMessage: []
                              });
                  }

         })
         .catch(err =>{
                if( this._isMounted ){
                        document.body.style.cursor = "default";
                       //console.log(err);
                        this.setState({
                          loadingVisible: false,
                          loadingMessage: []
                        });
                }
         })
   }

   toUpdate(){
    document.body.style.cursor = "wait";

    this.lawsuitService.atualizacaoAutomatica( this.state.lawsuit.token ).then(res =>{
      if( this._isMounted ){
          if( res.success === true ){

              if ( this.state.lawsuit.atualizacao_automatica === 0 ) {
                localStorage.setItem('alert_message', "Processo entrou em atualização automática com sucesso.");
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
                let lawsuit = this.state.lawsuit;
                lawsuit.atualizacao_automatica = 1;
                setTimeout(() => {
                  this.setState({
                    lawsuit,
                    updated: time
                  });
                }, 100);

              }else{
                localStorage.setItem('alert_message', "Processo removido da atualização automática.");
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
                let lawsuit = this.state.lawsuit;
                lawsuit.atualizacao_automatica = 0;
                setTimeout(() => {
                  this.setState({
                    lawsuit,
                    updated: time
                  });
                }, 100);
              }


          }else{
            //alert(res.message);
            this.setState({
              message: res.message
            });
          }
          document.body.style.cursor = "default";

      }
    });

   }

   back( event ){
     event.preventDefault();
     //this.props.history.goBack();
   }

  render() {

    //console.log(this.state.lawsuit);

      var clientSection; var tribunal_link = null;
      if ( typeof this.state.lawsuit.lawsuits_personages !== "undefined" ) {
        clientSection = <ClientSection lawsuit={this.state.lawsuit} />;
      } else {
        clientSection = "";
      }

      if ( this.state.lawsuit.tribunal_id !== null ){
        tribunal_link = <b className="tribunal_link"><a href={(typeof this.state.lawsuit.tribunal.api_url !== "undefined")? this.state.lawsuit.tribunal.api_url : "#" } target="_blank"  rel="noopener noreferrer" className="text-white"> Acesso ao site <i className="fa fa-external-link"></i></a></b>;
      }


      let button_delete_lawsuit = <span className="excluir-processo btn btn-danger" role="button"  onClick={() => this.deleteLawsuit( this.state.lawsuit.token )}><i className="fa fa-times fa-fw"></i> Excluir</span>;


      let url_base = localStorage.getItem('url_base');

      if( this.state.ajaxComplete === false ){

        return <div id="details-lawsuit" className="details-lawsuit sheet">
                          <FlashMessage time={ new Date().getTime() } />
                          <Loading visible={!this.state.ajaxComplete} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                          <NavigationMenu />
                </div>

      }else{

            let status_box = "";
            if( this.state.lawsuit.number !== null && typeof this.state.lawsuit.number !== "undefined" ){

              let uf = (typeof this.state.lawsuit.tribunal != "undefined") ? this.state.lawsuit.tribunal.uf : "";
              if(uf == "SP" || uf == "RJ" || uf == "MG" || (this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS") ){
                  if( this.state.lawsuit.status === "ERRO" ) {

                          status_box = <span>({this.state.lawsuit.status})
                                      <MyPopover icon="white" text={"Verifique se o número do processo e/ou Tribunal estão corretos. Acesse o site do Tribunal e tente visualizá-lo. Caso consiga, informe ao suporte o Tribunal e Nº para que possamos verificar. \n Obs.: Processos com Status ERRO não serão atualizados."} />
                          </span>;
                    }else if ( this.state.lawsuit.status === "VALIDANDO" ) {
                          status_box = <span>({this.state.lawsuit.status})
                                      <MyPopover icon="white" text='Os dados deste processo estão sendo capturados pelos robôs. Aguarde até que este mude para o status Cadastrado, aí sim comece a inclusão dos dados!' />
                          </span>;
                    }else {
                          status_box = <span>({this.state.lawsuit.status})
                          </span>;                    
                    }
                  }
            }

            let movement_container = <MovementsLawsuit lawsuit={this.state.lawsuit} modifiedMovements={this.modifiedMovements} />;

            if( this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS" ){
              movement_container = null;
            }

            let pedidos_container = null;

            if( this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS" /*&& JSON.parse(localStorage.getItem('loggedUser')).email == "dercio@dfoli.com.br"*/ ){
              pedidos_container = <PedidosLawsuit lawsuit={this.state.lawsuit} />;
            }

            let recursos_container = null;

            if( this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS" /*&& JSON.parse(localStorage.getItem('loggedUser')).email == "dercio@dfoli.com.br"*/ ){
              recursos_container = <RecursosLawsuit lawsuit={this.state.lawsuit} />;
            }

            let alertINSS = null; 

            /*if(this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS"){
              alertINSS = <div class="alert alert-danger" role="alert">
                            Devido a mudanças internas do site do INSS , estamos em manutenção nos painéis de pedidos e recursos
                          </div>;
            }*/

            /*
            <li>
                  <a href="https://sistemaavvocato.com.br/avvocato/relatorio/enviar_email/detalhe_processo/687295dad3f229d70dbec1a5700fbd88a5332b030d01871297358d8f90608a52/27"><i className="fa fa fa-envelope-o fa-fw"></i> Enviar e-mail</a>
            </li>*/

            return <div id="details-lawsuit" className="details-lawsuit sheet">
                  <FlashMessage time={ new Date().getTime() } />
                  <Confirm show={this.state.showConfirm} text={this.state.message} />
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                  <HeaderBar />
                  <NavigationMenu />
                  <div className="content-wrapper">
                    <div className="content-wrapper-inner">
                        <section className="content-header">
                          {alertINSS}
                          <h1 className="page-header">Detalhes do Processo {status_box}</h1>
                        </section>
                        <div className="box-body panel-group" id="box-body">

                          <div className="panel panel-primary ">

                                <div className="panel-heading text-center">
                                    <input type="hidden" id="lawsuit_token" value={this.state.lawsuit.token} />
                                    <span id="numero_processo" data-id={this.state.lawsuit.number}>Processo {( this.state.lawsuit.number === null )? " Manual: "+this.state.lawsuit.name : ": "+this.state.lawsuit.number} <span className="red">{ (this.state.archived === 1) ? "(ARQUIVADO)" : null }</span> <span className="yellow">{ (this.state.highlight === 1) ? " (DESTACADO)" : null }</span> </span>
                                    <input type="hidden" id="lawsuit_manual" value={( this.state.lawsuit.number === null ) ? "manual" : "tribunal"} />
                                    <input type="hidden" id="lawsuit_status" value={( this.state.lawsuit.status !== null ) ? this.state.lawsuit.status : ""} />
                                    <input type="hidden" id="lawsuit_tribunal" value={ (typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal !== null ) ? this.state.lawsuit.tribunal.name : ""} />
                                    <small>
                                    { (typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal !== null ) ? this.state.lawsuit.tribunal.name+" - " : ""} {tribunal_link}
                                    </small>
                                    {clientSection}
                                </div>
                          </div>

                          <div className="btn-group menu-topo-processo">
                              <a href="/" onClick={ (event)=> { this.back(event) } } id="btn-back" className="btn btn-info btn-back">Voltar</a>
                              <span className="btn btn-default hide" id="highlight-button" role="button" data-token={this.state.lawsuit.token} data-highlight={this.state.highlight}  onClick={( event ) => this.addToHighlight( )}>
                                  <i className={ (this.state.highlight === 0)? "fa fa-star-o fa-fw" : "fa fa-star fa-fw" }></i>{ (this.state.highlight === 0)? "Destacar" : "Remover Destaque" }
                                  <Popover
                                      isOpen={this.state.isHighlightPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isHighlightPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isHighlightPopoverOpen: !this.state.isHighlightPopoverOpen })}
                                              >
                                                    { (this.state.highlight === 0)? "Destaque os processos de alto risco, acompanhamento diferenciado, ou de um cliente em especial." : "Ao remover o Destaque, este processo passará para a classificação de Ativos comuns." }
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isHighlightPopoverOpen: false })} onMouseOver={() => this.setState({ isHighlightPopoverOpen: !this.state.isHighlightPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                              </span>
                              <div className="btn-group">
                                <button type="button" className="btn btn-default dropdown-toggle btn-print details-lawsuit-bt-print" data-toggle="dropdown">
                                  <i className="fa fa-print fa-fw"></i> Imprimir<span className="caret"></span>
                                  <Popover
                                      isOpen={this.state.isPrintPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isPrintPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isPrintPopoverOpen: !this.state.isPrintPopoverOpen })}
                                              >
                                                  Possibilita a impressão completa dos processos selecionado e Capa para o processo Físico.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isPrintPopoverOpen: false })}  onMouseOver={() => this.setState({ isPrintPopoverOpen: !this.state.isPrintPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                                </button>
                                <ul className="dropdown-menu" role="menu">
                                  <li>
                                    <Link to={`/print-lawsuit/${this.state.id}`} target="_blank" rel="noopener noreferrer" >
                                            <i className="fa fa-file-powerpoint-o fa-fw"></i>
                                            <span> Processo</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`/cover/${this.state.id}`} target="_blank" rel="noopener noreferrer" >
                                            <i className="fa fa-file-o fa-fw"></i>
                                            <span> Capa</span>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <span className={ (this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS") ? "btn btn-default tribunal_token" : "hide" } role="button" onClick={ (event) => { this.toUpdate() } } ><i className="fa fa-refresh fa-fw"></i> { ( this.state.lawsuit.atualizacao_automatica === 0 ) ? "Atualizar Agora" : "Remover Atualização Automática"}</span>
                              <span className="btn btn-default" id="vincular-processo" role="button" data-toggle="modal" data-target="#relatedModal" onClick={this.chargeModal} >
                                        <i className="fa fa-magnet fa-fw"></i> Vincular Processo
                                        <Popover
                                            isOpen={this.state.isRelatedPopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isRelatedPopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isRelatedPopoverOpen: !this.state.isRelatedPopoverOpen })}
                                                    >
                                                        Ao vincular um processo a outro, poderá consultar estes de maneira rápida, sem a nececidade de pesquisas. Ideal para processos em Recursos.
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isRelatedPopoverOpen: false })} onMouseOver={() => this.setState({ isRelatedPopoverOpen: !this.state.isRelatedPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                        </Popover>
                              </span>
                              {/*<span className="btn btn-default" id="unificar-processo" role="button"><i className="fa fa-compress fa-fw"></i> Unificar Processo</span>*/}
                              <span className="btn btn-default" role="button"  data-highlight={this.state.highlight}   data-token={this.state.lawsuit.token} data-archived={this.state.archived}
                                  onClick={ (event) => { this.toArchive(this.state.lawsuit.token, this.state.archived) } } >
                                  <i className="fa fa-archive fa-fw"></i>{ (this.state.archived === 0)? "Arquivar" : "Desarquivar" }
                                  <Popover
                                      isOpen={this.state.isArchivePopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isArchivePopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isArchivePopoverOpen: !this.state.isArchivePopoverOpen })}
                                              >
                                                  Ao Arquivar um processo, esté  não será mais atualizado e não contará mais no plano contratato de processos  Ativos. Pode ser desarquivado a qualquer tempo. Seus dados podeão ser acessados normalmente.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isArchivePopoverOpen: false })}  onMouseOver={() => this.setState({ isArchivePopoverOpen: !this.state.isArchivePopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                              </span>
                              { this.authService.can("cadastrar_processo_excluir", true, 'processos') ? button_delete_lawsuit : null }
                          </div>
                          <br />
                          <div className={(this.state.lawsuit.message !== null ) ? "alert alert-danger" : "hide"}>{this.state.lawsuit.message}</div>

                          <DataLawsuit  lawsuit={this.state.lawsuit} modifiedLawsuit={this.modifiedLawsuit}  />

                          <AdditionalDataLawsuit  lawsuit={this.state.lawsuit} modifiedLawsuit={this.modifiedLawsuit} />

                          <RelatedLawsuits  lawsuit={this.state.lawsuit.token} time={new Date().getTime()} />

                          <PersonagesLawsuit lawsuit={this.state.lawsuit} history={this.props.history} modifiedPersonages={this.modifiedPersonages} formIsHalfFilledOut={this.state.formIsHalfFilledOut} />

                          { movement_container }

                          { pedidos_container }

                          { recursos_container }

                          <PublicationsLawsuit lawsuit={this.state.lawsuit} modifiedPublications={this.modifiedPublications} />

                          <InfoLawsuit  token={this.state.lawsuit.token} />

                          <EventLawsuit  token={this.state.lawsuit.token} />

                          <GED token={this.state.lawsuit.token} />

                          <FinanceLawsuit token={this.state.lawsuit.token} />

                        </div>

                    </div>
                  </div>

                  <div className="modal fade" id="relatedModal" tabIndex="-1" role="dialog" aria-labelledby="relatedModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="statusModalLabel">Vincular processo</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                                <label htmlFor="relate" className="col-form-label col-md-12">Processos: </label>
                                <Select
                                    className="lawsuitToRelate  col-md-9"
                                    options={this.state.lawsuitList}
                                    placeholder="(escolha um)"
                                    value={this.state.lawsuitToRelate}
                                    name="lawsuitToRelate"
                                    onChange={this.selectChange}
                                  />
                                <button className="btn btn-primary" id="btn-vincular" onClick={this.toRelate} >Vincular</button>
                                <p className="col-md-12">{(this.state.lawsuitListCharge===0)?"Carregando...":null}</p>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>


      }

      
}

}
