import React from 'react';
import ClientInfos from './clientInfos';

import moment from "moment";
import 'moment/locale/pt-br';

export default class ClientSection extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);

    this.state = {
      lawsuit: props.lawsuit
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props ) {

        this.setState({
          lawsuit: nextProps.lawsuit,
        });


    }
  }

  getClientOrOponent ( record, side ) {

    let name = []; let response = "";

    //console.log(record);

    record.lawsuits_personages.sort(function(a, b){
      //console.log(a);
      if ( moment(a.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() < moment(b.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() ){
        return -1;
      }else{
        return 1;
      }

    });

    record.lawsuits_personages.map((value, index, array )=>{

      if ( value.personage_side_id === side ) {
        name.push( value.personage.name );
      }

      return "";

    });

    if( name.length >= 2 ) {
      response = name[0]+" (e outros)";
    }else{
      response = name[0];
    }

    if ( typeof response === "undefined" ){
      response = "Não Definido";
    }
    return response;

  }

  getFirstClientIndex ( record, side ) {

    let indice = []; let response = "";

    record.lawsuits_personages.sort(function(a, b){

      //console.log(a);

      if ( moment(a.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() < moment(b.modified.substr(0,10), "YYYY-MM-DD HH:mm").valueOf() ){
        return -1;
      }else{
        return 1;
      }

    });

    record.lawsuits_personages.map((value, index, array )=>{

      if ( value.personage_side_id === side ) {
        indice.push( index );
      }

      return "";

    });

    response = indice[0];

    return response;

  }

  render() {

      let cliente; let oponente; let firstClientIndex; let firstClient;

      cliente = this.getClientOrOponent( this.state.lawsuit, 1 );
      firstClientIndex = this.getFirstClientIndex( this.state.lawsuit, 1);
      firstClient = this.state.lawsuit.lawsuits_personages[firstClientIndex];
      oponente = this.getClientOrOponent( this.state.lawsuit, 2 );

      if ( cliente || oponente ) {

          return (

            <div>
              <div id="divclientexoponente">
                <small><strong>Cliente:</strong> {cliente} </small> x <small><strong>Oponente:</strong> {oponente} </small>
              </div>
              <ClientInfos client={firstClient} />
            </div>
          )

      }else{
        return (
            <div>
            </div>
        )
      }

  }
}
