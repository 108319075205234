import React from 'react';

function Document( {attr} ) {

  let url_base = localStorage.getItem('url_base');

  return (
    <div className={ (attr.document_token === 0)? "hide" : "alert alert-success document-info alert-dismissible alert-shown" } role="alert">
      <span className="close" data-dismiss="alert" aria-label="close">&times;</span>
      <div className="container-info">
          <p>Documento gerado com sucesso!</p>
          <p>
          {(attr.area !== null )? attr.area.label : null} -&gt; {(attr.tipo !== null )? attr.tipo.label : null} -&gt; {( attr.template !== null )? attr.template.label : null}
          </p>
          <p>Nome:
          <b className="title">{attr.title}</b>
          </p>
      </div>
       <a className="link" href={url_base+"/docfacil/"+attr.document_token} rel="noopener noreferrer" target="_blank"><b>Clique aqui</b></a>
    </div>
  );
}

export default Document;
