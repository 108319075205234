import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import Select from 'react-select';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';

import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';
import AddressService from '../../services/AddressService';

import {isTablet} from '../config/mobileDetect';

import moment from "moment";

import 'moment/locale/pt-br';

moment.locale('pt-BR');

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  },
}

const example = <div className="toggle">
               <input className="toggle__input" readOnly type="checkbox" checked />
               <span className="toggle__label">
                 <span className="toggle__text"></span>
               </span>
         </div>;

export default class ReportClient extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(props) {
    super(props);

    this.doReport = this.doReport.bind(this);
    this.selected = this.selected.bind(this);
    this.checkChange = this.checkChange.bind(this);

    this.AddressService = new AddressService();

    const monthList = [
      { value: '1', label: 'Janeiro' },
      { value: '2', label: 'Fevereiro' },
      { value: '3', label: 'Março' },
      { value: '4', label: 'Abril' },
      { value: '5', label: 'Maio' },
      { value: '6', label: 'Junho' },
      { value: '7', label: 'Julho' },
      { value: '8', label: 'Agosto' },
      { value: '9', label: 'Setembro' },
      { value: '10', label: 'Outubro' },
      { value: '11', label: 'Novembro' },
      { value: '12', label: 'Dezembro' },
    ];

    this.state = {
      monthList: monthList,
      month: monthList[0],
      date: null,
      date_start: null,
      date_end: null,
      client: null,
      data_nasc_exata: 0,
      mes_nasc: 0,
      periodo_nasc: 0,
      n_processo: false,
      email: false,
      endereco_completo: false,
      n_pasta: false,
      rg: false,
      cpf: false,
      tel: false,
      cidade: "",
      profissao: false,
      estado_civil: false,
      estadoList: [],
      estado: null,
      cities: []
    }

    if(isTablet()){
      alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
      window.location.href = '/';
    }

  }

  componentDidMount( ) { this._isMounted = true;

    this.LawsuitPersonagesService = new LawsuitPersonagesService();

      this.LawsuitPersonagesService.list( ).then(res =>{
        this.setState({
          clientList: res,
        });

      });

      this.AddressService.getEstado( ).then(res =>{
            this.setState({
              estadoList: res,
            });
      });

  }

  handleChange = (e) => {

          this.setState(
              {
                  [e.target.name]: e.target.value
              }
          )

   }

   stateChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
    //console.log(selected);

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( selected !== null ){
         this.AddressService.getCidades( selected.value ).then( cities=>{

           this.setState({
             cities: cities
           });
           document.getElementById("cidadesContainer").classList.remove("hide");

         });
       }else{
           document.getElementById("cidadesContainer").className += ' hide';
       }

     }

     if ( typeof selected !== undefined && selected !== ""  ) {
       this.setState( pops );
     }

   }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;

      if ( typeof selected !== undefined && selected !== "" ) {

        this.setState( pops );

      }
  }

  onDatePickerChange = (date, dateString, name) => {

    if (dateString === "" ){
      dateString = null;
    }

    if( name === 'date_start'){
      this.setState(
          {
              'date_end': dateString,
          }
      )
    }

     this.setState(
         {
             [name]: dateString,
         }
     )

   }

   disabledEndDate = endValue => {
     const startValue = moment(this.state.date_start, "DD/MM/YYYY");
     if (!endValue || !startValue) {
       return false;
     }
     return endValue.valueOf() < startValue.valueOf();
   }

   selected = (event) => {

     let rbcs = document.getElementsByClassName("rbc");


      let i;
      for (i = 0; i < rbcs.length; i++) {
        rbcs[i].classList.remove("rbc-active");
      }

      let container = document.getElementsByClassName("container");

       for (i = 0; i < container.length; i++) {
         container[i].className += ' hide';;
       }

       this.setState(
           {
               data_nasc_exata: 0,
               mes_nasc: 0,
               periodo_nasc: 0
           }
       );

     if (document.getElementById(event.target.id).classList.contains('rbc-active')) {
           // do some stuff
           document.getElementById(event.target.id).classList.remove("rbc-active");
           //document.getElementById(event.target.id+"-container").className += ' hide';
           this.setState(
               {
                   [event.target.id]: 0
               }
           )
     }else{
           document.getElementById(event.target.id).className += ' rbc-active';
           document.getElementById(event.target.id+"-container").classList.remove("hide");
           this.setState(
               {
                   [event.target.id]: 1
               }
           )
     }

   }

   doReport = () => {

     let date_start = (this.state.date_start === null) ? 0 : this.state.date_start.replace(/\//g,'-');
     let date_end = (this.state.date_end === null) ? 0 : this.state.date_end.replace(/\//g,'-');
     let date = (this.state.date === null) ? 0 : this.state.date.replace(/\//g,'-');
     let estado = (this.state.estado === null) ? 0 : this.state.estado.value;
     let cidade = (this.state.cidade === null || this.state.cidade === "") ? 0 : this.state.cidade.value;

    if( this.state.mes_nasc === 0 ){
      this.state.month.value = 0;
    }

    if(this.state.client === null){
      this.state.client = {value: 0};
    }

     this.props.history.push( '/relatorio/cliente/listar/'+this.state.data_nasc_exata+'/'+this.state.mes_nasc+'/'+
     this.state.periodo_nasc+'/'+this.state.client.value+'/'+date+'/'+date_start+'/'+date_end+'/'+this.state.month.value+'/'+
     this.state.n_processo+'/'+this.state.rg+'/'+this.state.cpf+'/'+this.state.email+'/'+this.state.tel+'/'+this.state.profissao+'/'+
     this.state.estado_civil+'/'+this.state.endereco_completo+'/'+this.state.n_pasta+'/'+cidade+'/'+estado+'/');

   }

   checkChange = (e) => {
     const item = e.target.name;
     const isChecked = e.target.checked;

     let object = {
        [item]: isChecked
      }

     this.setState( object );
   }

  render() {

      let date_end_disabled = true;
      if( this.state.date_start !== null ){
        date_end_disabled = false;
      }

      let url_base = localStorage.getItem('url_base');

      return <div className="report report-event  sheet">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>Relatório de Clientes</h4>
                            </div>
                      </div>
                      <div className="box-body">
                          <div className="col-md-12">
                              <h5>Filtro de Cliente</h5>
                              <div className="form-group col-md-6 ">
                                  <label htmlFor="user" className="col-md-12">Cliente</label>
                                  <Select
                                      className="client col-md-12"
                                      options={this.state.clientList}
                                      styles={customStyles}
                                      placeholder="Todos"
                                      value={this.state.client}
                                      isClearable={true}
                                      name="client"
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div className="form-group col-md-3 text-left">
                                    <label htmlFor="estado" className="control-label">Estado</label>
                                    <Select
                                        className="estado"
                                        name="estado"
                                        styles={customStyles}
                                        value={this.state.estado}
                                        options={this.state.estadoList}
                                        isClearable={true}
                                        placeholder="Todos"
                                        onChange={this.stateChange}
                                      />
                              </div>
                              <div id="cidadesContainer" className="form-group col-md-3 text-left hide">
                                    <label htmlFor="cidade" className="control-label">Cidade</label>
                                    <Select
                                        className="cidades"
                                        name="cidade"
                                        styles={customStyles}
                                        value={this.state.cidade}
                                        options={this.state.cities}
                                        isClearable={true}
                                        placeholder="Todas"
                                        onChange={this.selectChange}
                                      />
                              </div>
                              <div id="typeBirthClientContainer" className="rbc-toolbar form-group col-md-12">
                                  <label htmlFor="rbc" className="col-md-12">Data de nascimento </label>
                                  <span className="rbc-btn-group">
                                      <button id="data_nasc_exata" type="button" className="rbc" onClick={ (event)=>{this.selected(event)} }>Data Nascimento Exata</button>
                                      <button id="mes_nasc" type="button" className="rbc" onClick={ (event)=>{this.selected(event)} }>Mês de Nascimento</button>
                                      <button id="periodo_nasc" type="button" className="rbc" onClick={ (event)=>{this.selected(event)} }>Período de Nascimento</button>
                                  </span>
                              </div>
                              <div id="data_nasc_exata-container" className="container hide col-md-12">
                                  <div className="form-group col-md-4 text-left">
                                      <label htmlFor="date" className="control-label">Data de Nascimento</label>
                                      <DatePicker locale={locale} className=" " placeholder="Data de Nascimento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date !== null ) ? moment(this.state.date, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date")} />
                                  </div>
                              </div>
                              <div id="mes_nasc-container" className="container hide col-md-12">
                                      <div className="form-group col-md-4">
                                          <label htmlFor="event">Mês</label>
                                          <Select
                                              className="month"
                                              options={this.state.monthList}
                                              value={this.state.month}
                                              name="month"
                                              onChange={this.selectChange}
                                            />
                                      </div>
                              </div>
                              <div id="periodo_nasc-container" className="container hide col-md-12">
                                      <div className="form-group col-md-2 text-left">
                                          <label htmlFor="date_start" className="control-label">Data de Início</label>
                                          <DatePicker locale={locale} className=" " placeholder="Data Inicial" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                                      </div>
                                      <div className="form-group col-md-2 text-left">
                                          <label htmlFor="date_end" className="control-label">Até</label>
                                          <DatePicker locale={locale} className=" " disabled={date_end_disabled} placeholder="Data Limite" showToday={false} disabledDate={this.disabledEndDate} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                                      </div>
                              </div>
                          </div>

                          <div className="col-md-12 report-client-config-exibition">
                                  <h5>
                                        Configuração de Exibição
                                        <Popover
                                            isOpen={this.state.isFilterPopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isFilterPopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isFilterPopoverOpen: !this.state.isFilterPopoverOpen })}
                                                    >
                                                          Selecione quais dados devem  aparecer impressos individualmente no relatório . Estas opções não são filtros.
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isFilterPopoverOpen: false })}  onMouseOver={() => this.setState({ isFilterPopoverOpen: !this.state.isFilterPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                        </Popover>
                                  </h5>
                                  <label className="col-md-12">( Mostrar no relatório {example} )</label>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="n_processo" onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">Nº do Processo</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="n_pasta" onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">Nº da Pasta</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="rg" checked={(this.state.rg) ? "checked" : ""} onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">RG</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="cpf" checked={(this.state.cpf) ? "checked" : ""} onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">CPF</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="tel" checked={(this.state.tel) ? "checked" : ""} onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">Telefone</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="email" checked={(this.state.email) ? "checked" : ""} onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">E-mail</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="profissao" checked={(this.state.profissao) ? "checked" : ""} onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">Profissão</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="estado_civil" checked={(this.state.estado_civil) ? "checked" : ""} onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">Estado Civil</span>
                                                </span>
                                          </label>
                                  </div>
                                  <div className="form-group col-md-3 ">
                                          <label className="toggle">
                                                <input className="toggle__input" type="checkbox" name="endereco_completo" checked={(this.state.endereco_completo) ? "checked" : ""} onChange={this.checkChange} />
                                                <span className="toggle__label">
                                                  <span className="toggle__text">Endereço completo</span>
                                                </span>
                                          </label>
                                  </div>

                                  <span className="btn btn-primary form-submit view-report right" onClick={this.doReport}>Gerar Relatório</span>
                          </div>
                      </div>
                  </div>
                </div>
             </div>
  }

}
