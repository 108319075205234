import React from 'react';
import ReactQuill from 'react-quill';
import moment from "moment";
import 'moment/locale/pt-br';

import LawsuitService from '../../services/LawsuitService';
import print from '../reports/print-style.js';
import cover from '../../assets/static/css/cover.lawsuit.js';
import CoverClientInfos from './containers/coverClientInfos';

import OfficeService from '../../services/OfficeService';

export default class CoverLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  componentWillReceiveProps(nextProps) {
    if ( nextProps.match.params.id !== this.props.match.params.id) {
      this.lawsuitService = new LawsuitService();
      this.lawsuitService.get( nextProps.match.params.id ).then(res =>{
        this.setState({
          lawsuit: res,
          id: nextProps.match.params.id
        });
      });

    }
  }

  constructor( { match } ) {
      super();
      this.lawsuitService = new LawsuitService();

      this.state = {
        id: match.params.id,
        lawsuit: {
          tribunal: {},
          valorCausa: "",
          lawsuits_personages: [],
          movements: [{id: 1, name: "", date: ""}],
          token: 0,
        },
        office: {
          name: "DFOLI ADVOGADOS ASSOCIADOS SAG"
        }
      };
  }

  componentWillMount() {

    this.getHeader();

  }

  getHeader = () => {
      this.OfficeService = new OfficeService();
      let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;//'4eb7f0a8-4541-4e96-890e-9318b232f945';

      //console.log("carregou");

      this.OfficeService.getConfigs( token ).then(res =>{

        //console.log(res.cabecalho);

            this.setState({
              office: {
                name: res.cabecalho
              }
            });
      });
  }

  componentDidMount( ) { 
    this._isMounted = true;

    this.lawsuitService = new LawsuitService();

    this.lawsuitService.get( this.props.match.params.id ).then(res =>{
      this.setState({
        lawsuit: res,
      });
    });

  }

  setStyle( print ) {
    return { __html: print[0].key };
  }

  getClientOrOponent ( record, side ) {

    let name = []; let response = "";

    //console.log( record );

    record.lawsuits_personages.sort(function(a, b){
      //console.log(a);
      if ( moment(a.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() < moment(b.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() ){
        return -1;
      }else{
        return 1;
      }

    });

    record.lawsuits_personages.map((value, index, array )=>{
      //console.log(value);

      if ( value.personage_side_id === side ) {
        name.push( value.personage.name );
      }

      return "";

    });

    if( name.length >= 2 ) {
      response = name[0]
    }else{
      response = name[0];
    }
    return response;

  }

  getFirstClientIndex ( record, side ) {

    let indice = []; let response = "";

    record.lawsuits_personages.sort(function(a, b){

      //console.log(a);

      if ( moment(a.modified.substr(0,16), "YYYY-MM-DD HH:mm").valueOf() < moment(b.modified.substr(0,10), "YYYY-MM-DD HH:mm").valueOf() ){
        return -1;
      }else{
        return 1;
      }

    });

    record.lawsuits_personages.map((value, index, array )=>{

      if ( value.personage_side_id === side ) {
        indice.push( index );
      }

      return "";

    });

    response = indice[0];

    return response;

  }

  componentDidUpdate = () => {

  }

  modules = {
    toolbar: [

    ],
  };

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'image', 'align',
    'font', 'color', 'size'
  ];

  render() {

      //console.log( this.state.lawsuit );
      //console.log( this.state.office.name );
      let firstClientIndex; let firstClient;


      let cliente = this.getClientOrOponent( this.state.lawsuit, 1 );
      let oponente = this.getClientOrOponent( this.state.lawsuit, 2 );
      firstClientIndex = this.getFirstClientIndex( this.state.lawsuit, 1);
      firstClient = this.state.lawsuit.lawsuits_personages[firstClientIndex];

      return <div className="cover">
                  <style dangerouslySetInnerHTML={ this.setStyle(print) } />
                  <style dangerouslySetInnerHTML={ this.setStyle(cover) } />
                  
                  <div className="controls">
                      <button onClick={ () => window.print() }>Imprimir</button>
                  </div>
                  <hr></hr>
                  <div id="office" className="office">
                          <ReactQuill
                              theme="snow"
                              readOnly={true}
                              value={this.state.office.name}
                              modules={this.modules}
                              formats={this.formats}
                          />
                  </div>
                  <section>
                      <label>Responsável pelo Processo: </label><p>Dércio Ferreira</p>
                  </section>

                  <section>
                      <label>CLIENTE: </label><p>{cliente}</p>
                      <CoverClientInfos client={firstClient} />       
                  </section>

                  <section>
                      <label>OPONENTE: </label><p>{oponente}</p>
                  </section>

                  <section>
                      <label>N° do Processo: </label><p>{this.state.lawsuit.number}</p>
                  </section>

                  <section>
                      <label>TRIBUNAL: </label><p>{this.state.lawsuit.tribunal.name}</p>
                      <label>VARA: </label><p>{ (this.state.lawsuit.vara === "" || typeof this.state.lawsuit.vara === 'undefined') ? 'Não Definida': this.state.lawsuit.vara}</p>
                  </section>

                  <section>
                      <label>Data de Distribuição: </label><p>{ ( typeof this.state.lawsuit.dataDistribuicao !== "undefined" && this.state.lawsuit.dataDistribuicao !== null ) ? this.state.lawsuit.dataDistribuicao.substr(0, 10).split('-').reverse().join('/')+" "+this.state.lawsuit.dataDistribuicao.substr(12, 7) : "Não Definida"}</p>
                  </section>

                  <section>
                      <label>Pasta: </label><p>{ ( typeof this.state.lawsuit.pasta !== "undefined") ? this.state.lawsuit.pasta : "Não Definida" }</p>
                  </section>

             </div>
  }

}
