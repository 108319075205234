import React from 'react';
import { Switch } from 'antd';

import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import Loading from '../containers/loading';
import MyPopover from '../containers/mypopover';

import UsersService from '../../services/UsersService';
import LawsuitService from '../../services/LawsuitService';
import EventService from '../../services/EventService';
import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';
import PrintComponent from './printComponent';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const eventList = [
  { value: '1', label: 'Compromisso' },
  { value: '2', label: 'Tarefa' },
  { value: '3', label: 'Notificação' },
];

const statusList = [
  {value: '1', label: 'Em andamento', color: '#00875A'},
  {value: '2', label: 'Realizado', color: '#0052CC'},
  {value: '3', label: 'Cancelado', color: '#FF5630'},

]

const prioridade = [
  { value: '1', label: 'Baixa' },
  { value: '2', label: 'Média' },
  { value: '3', label: 'Alta' },
];

export default class ReportEventList extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });
  }

  constructor( { match } ) {
      super();
      const select = { value: '0', label: 'Todos' };
      const fSelect = { value: '0', label: 'Todas' };
      let status = select; let event = select; let priority = fSelect; let user = select; let category = fSelect; let sponsorLawsuit = select;
      let client = select; let lawsuit = select;


      let date_start = (match.params.date_start === "0") ? "Tudo" : match.params.date_start.replace(/-/g,'/');
      let date_end = (match.params.date_end === "0") ? "Tudo" : match.params.date_end.replace(/-/g,'/');

      this.back = this.back.bind(this);
      this.modifiedViewPrint = this.modifiedViewPrint.bind(this);

      this.state = {
        event: event,
        eventList: [],
        status: status,
        priority: priority,
        user: user,
        category: category,
        sponsorLawsuit: sponsorLawsuit,
        client: client,
        lawsuit: lawsuit,
        date_start: date_start,
        date_end: date_end,
        qtd: 0,
        print: 0,
        showOnReport: false,
        loadingVisible: false,
        loadingMessage: [],
        params: match.params
      }

  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.history.location.pathname !== this.props.history.location.pathname  ) {
      this.initParams( nextProps.match.params );
    }/*else{
      this.initParams( this.props.match.params );
    }*/

  }

  componentWillMount() {

    this.initParams( this.props.match.params );

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  initParams( params ){

    const select = { value: '0', label: 'Todos' };
    const fSelect = { value: '0', label: 'Todas' };
    let status = select; let event = select; let priority = fSelect; let user = select; let category = fSelect; let sponsorLawsuit = select;
    let client = select; let lawsuit = select;

    this.setState({
      loadingVisible: true,
      loadingMessage: ["O relatório está sendo processado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
    });

    this.usersService = new UsersService();
    this.lawsuitService = new LawsuitService();
    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.EventService = new EventService();

      this.usersService.list( ).then(userList =>{

          userList.unshift ( {value: -99, label: "Todos"} );

          this.lawsuitService.officeList().then(lawsuitList =>{

            this.EventService.categoryList( ).then(categoryList =>{

                this.LawsuitPersonagesService.list( ).then(clientList =>{

                      let date_start = (params.date_start === "0") ? "Tudo" : params.date_start.replace(/-/g,'/');
                      let date_end = (params.date_end === "0") ? "Tudo" : params.date_end.replace(/-/g,'/');
                      params.date_end = moment(params.date_end, "DD-MM-YYYY").endOf('day').add(1, 'days').format('DD-MM-YYYY');

                      if( params.event !== "0" ){
                        event = eventList.filter( (event)=>{ return event.value === params.event } )[0];
                      }

                      if( params.status !== "0" ){
                        status = statusList.filter( (status)=>{ return status.value === params.status } )[0];
                      }

                      if( params.priority !== "0" ){
                        priority = prioridade.filter( (priority)=>{ return priority.value === params.priority } )[0];
                      }

                      if( params.user !== "0" ){
                        user = userList.filter( (user)=>{ return user.value === params.user } )[0];
                      }

                      if( params.category !== "0" ){
                        category = categoryList.filter( (category)=>{ return category.value === params.category } )[0];
                      }

                      if( params.sponsorLawsuit !== "0" ){
                        sponsorLawsuit = userList.filter( (sponsorLawsuit)=>{ return sponsorLawsuit.value === params.sponsorLawsuit } )[0];
                      }

                      if( params.client !== "0" ){
                        client = clientList.filter( (client)=>{ return client.value === params.client } )[0];
                      }

                      if( params.lawsuit !== "0" ){
                        lawsuit = lawsuitList.filter( (lawsuit)=>{ return lawsuit.value === params.lawsuit } )[0];
                      }

                      this.EventService.report( params.date_start, params.date_end, params.event, params.status, params.priority, params.user, params.category, params.sponsorLawsuit, params.client, params.lawsuit).then(eventList =>{

                        this.setState({
                          event: event,
                          status: status,
                          priority: priority,
                          user: user,
                          category: category,
                          sponsorLawsuit: sponsorLawsuit,
                          client: client,
                          lawsuit: lawsuit,
                          date_start: date_start,
                          date_end: date_end,
                          eventList: eventList,
                          qtd: eventList.length,
                          loadingVisible: false,
                          params: params
                        });
                      });

                });

            });

          });


      });


  }

  back(){
    this.props.history.goBack();
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  onSwitchChange = (checked) => {
        if ( checked ){
          this.setState({
            showOnReport: true,
          });

        }else{
          this.setState({
            showOnReport: false,
          });
        }
  }

  eventItem = ( {record, index}, type ) => {

      let lawsuit = ""; let total_users = ""; let event_user = ""; let status = ""; let color = "";

      if (record.lawsuit !== null ) {
        if(record.lawsuit.name !== null){
          lawsuit = record.lawsuit.name ;
        }else{
          lawsuit = <div>
                          <p>{record.lawsuit.number}</p>
                          <p>{record.lawsuit.tribunal.slug}</p>
                    </div>
        }

      }

      if ( record.events_situation !== null && typeof record.events_situation !== "undefined" && record.events_situation.id === 1){
        status = 'Em Andamento';
        color = 'event-green';
      }else if ( record.events_situation !== null && typeof record.events_situation !== "undefined" && record.events_situation.id === 2){
        status = 'Realizado';
        color = 'event-blue';
      }else if ( record.events_situation !== null && typeof record.events_situation !== "undefined" && record.events_situation.id === 3){
        status = 'Cancelado';
        color = 'event-red';
      }

      if(record.events_users.length > 0){

        if( record.events_users.length >= 2 ) {
          event_user = record.events_users[0].user.name+" (e outros)";
        }else{
          event_user = record.events_users[0].user.name;
        }



        for(let i = 0; i < record.events_users.length; i++ ){
          if( i !== 0 ){
            total_users += ", ";
          }
          total_users += record.events_users[i].user.name;
        }
      }

    return (
      <tr key={record.token} className={type}>
              <td id="user-author" className={(this.state.params.event === "3") ? "user actions" : "hide"}>
                  {record.user.name}
              </td>
              <td className="user actions" title={total_users}>
                  {event_user}
              </td>
              <td className="">
                  <p>{record.start.substr(0,10).split('-').reverse().join('/')}</p>
                  <p>{record.start.substr(11,5)}</p>
              </td>
              <td className="category">
                  {( record.event_category !== null ) ? record.event_category.name : ""}
              </td>
              <td className="description ">
                  <p>{(record.event_type !==null && (record.event_type.id === 2 || record.event_type.id === 3 ) ) ? "" : record.title}</p>
                  <p>{record.description}</p>
              </td>
              <td className="table_lawsuit_number">
                  {lawsuit}
              </td>
              <td className={(this.state.params.event !== "3") ? "status "+color : "hide"}>
                  {status}
              </td>
      </tr>
    )
  }

  render() {
      //let url_base = localStorage.getItem('url_base');

      let panel_filtros =  <div className="panel panel-default container-publicacoes">
                                    <div className="panel-heading text-center">
                                        <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                                            <strong>Filtros Utilizados</strong>
                                        </div>
                                    </div>
                                    <div className={ (this.state.showOnReport) ? "panel-body" : "panel-body collapse" } id="filter_target">
                                        <ul>
                                            <li><b>Tipo de Evento: </b> <span>{this.state.event.label}</span> </li>
                                            <li><b>Status: </b> <span>{this.state.status.label}</span> </li>
                                            <li><b>Prioridade: </b> <span>{this.state.priority.label}</span> </li>

                                            <li><b>Usuários: </b> <span>{this.state.user.label}</span> </li>
                                            <li><b>Categoria: </b> <span>{this.state.category.label}</span> </li>
                                            <li><b>Responsável pelo Processo: </b> <span>{this.state.sponsorLawsuit.label}</span> </li>


                                            <li><b>Data de Início: </b> <span>{this.state.date_start}</span> </li>
                                            <li><b>Processo: </b> <span>{this.state.lawsuit.label}</span> </li>
                                            <li><b>Cliente: </b> <span>{this.state.client.label}</span> </li>
                                            <li><b>Data Limite: </b> <span>{this.state.date_end}</span> </li>
                                        </ul>
                                        <label id="showOnReportContainer" className="right">
                                                <MyPopover icon="white" text='Se desejar que os filtros do relatório apareçam no topo dos relatórios' />
                                                Aparecer no relatório
                                                <Switch title={(this.state.showOnReport)? "Desbloqueado": "Bloqueado"} checked={this.state.showOnReport} onChange={ this.onSwitchChange }  />
                                        </label>
                                    </div>
                            </div>

      let table =                     <table>
                                          <thead>
                                              <tr>
                                                    <th id="user-author" className={(this.state.params.event === "3") ? "user actions " : "hide"}>
                                                        Usuário Autor
                                                    </th>
                                                    <th className="table_user user actions">
                                                        <div>{(this.state.params.event !== "3") ? "Usuário" : "Usuário Notificado"}</div>
                                                    </th>
                                                    <th className="table_date date ">
                                                        <div>Data</div>
                                                    </th>
                                                    <th className="table_category">
                                                        <div>Categoria</div>
                                                    </th>
                                                    <th className="table_description description ">
                                                        <div>Título / </div>
                                                        <div>Descrição</div>
                                                    </th>
                                                    <th className="table_lawsuit_number">
                                                        <div>Processo/ </div>
                                                        <div>Tribunal</div>
                                                    </th>
                                                    <th className={(this.state.params.event !== "3") ? "status " : "hide"}>
                                                        Status
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              { this.state.eventList.map( (record, index) => this.eventItem( {record, index}, 'report-event-item' ) ) }
                                          </tbody>
                                  </table>;

      if( this.state.print === 1 ){
          if( !this.state.showOnReport ){
            panel_filtros = "";
          }
          let param_evento = "";
          if (this.state.params.event === "1") {
            param_evento = "Compromissos";
          }else if (this.state.params.event === "2"){
            param_evento =  "Tarefas";
          }else if (this.state.params.event === "3"){
            param_evento =  "Notificações";
          }
          return <div>
                    <PrintComponent modifiedViewPrint={this.modifiedViewPrint} panel_filtros={panel_filtros} content={table} title={"Relatório da Agenda"} subtitle={param_evento} />
               </div>
      }else{
        return <div className="sheet reportEventList">
                  <HeaderBar />
                  <NavigationMenu />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]}  cancel={true} />
                  <div className="content-wrapper">
                    <div className="content-wrapper-inner">
                        <section className="content-header">
                          <h1 className="page-header">Relatórios - Agenda Listagem</h1>
                          <span className="btn btn-info" onClick={this.back} >Voltar</span>
                          <span className="btn btn-success btn-report btn-excel right hide">Exportar Excel</span>
                          <span className="btn btn-default btn-report right" onClick={(event)=>{this.print(event)} }>Imprimir</span>
                          <span className="btn btn-primary btn-report right hide">Enviar email</span>
                        </section>
                        <div className="box-body">
                            <div className="alert alert-info" role="alert">Quantidade de registros encontrados: <strong>{this.state.qtd}</strong> </div>

                            {panel_filtros}

                            {table}

                        </div>
                    </div>
                  </div>
               </div>
      }

  }

}
