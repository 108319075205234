import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import Select from 'react-select';
import { DatePicker } from 'antd'; 
import locale from 'antd/es/date-picker/locale/pt_BR';
import { Switch } from 'antd';
import { Link } from 'react-router-dom';
import PrintComponent from './printComponent';
import Loading from '../containers/loading';
import MyPopover from '../containers/mypopover';

import UsersService from '../../services/UsersService';
import LawsuitService from '../../services/LawsuitService';
import FinanceService from '../../services/FinanceService';
import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';
import AdditionalDataLawsuitService from '../../services/AdditionalDataLawsuitService';
import EventService from '../../services/EventService';
import TribunalService from '../../services/TribunalService';
import PublicationsService from '../../services/PublicationsService';

import {isTablet} from '../config/mobileDetect';

import moment from "moment";

import 'moment/locale/pt-br';
import Creatable from 'react-select/lib/Creatable';

moment.locale('pt-BR');

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  },
}

const example = <div className="toggle">
               <input className="toggle__input" readOnly type="checkbox" checked />
               <span className="toggle__label">
                 <span className="toggle__text"></span>
               </span>
         </div>;

var url_base = localStorage.getItem('url_base');

let oldShowOnReport = null;

export default class ReportLawsuit extends React.Component { 
  _isMounted = false;
  _cancel = false;
  _countAjax = 0;
  _milisecondsAjax = 0;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(props) {
    super(props);

    this.doReport = this.doReport.bind(this);
    this.checkChange = this.checkChange.bind(this);
    this.modifiedViewPrint = this.modifiedViewPrint.bind(this);

    const select = { value: '0', label: 'Todos' };
    const fSelect = { value: '0', label: 'Todas' };
    const destaqueList = [
      {value: '1', label: 'Sim'},
      {value: '2', label: 'Não'},
    ];
    const statusList = [
      {label: "Ativo", value: 0},
      {label: "Arquivado", value: 1},
    ];
    const qtdList = [
      {label: "último", value: 1},
      {label: "2 últimos", value: 2},
      {label: "3 últimos", value: 3},
      {label: "4 últimos", value: 4},
      {label: "5 últimos", value: 5},
      {label: "Todos", value: 999}
    ];
    const typeList = [
      {label: "Todos", value: 1 },
      {label: "Saldo Positivo", value: 2 },
      {label: "Saldo Negativo", value: 3 },
    ];

    this.state = {
      monthList: [],
      doReport: 0,
      tribunalList: [],
      print: 0,
      showOnReport: false,
      resultLawsuitList: [],
      federal: true,
      mg: true,
      sp: true,
      rj: true,
      manual: true,
      inss: true,
      stf: true,
      stj: true,
      tse: true,
      tst: true,
      trf1: true,
      tjmg: true,
      tjmgpje: true,
      tjmgprojudi: true,
      trt3: true,
      trt3pje: true,
      jfrj: true,
      trf2: true,
      tjrj: true,
      trt1: true,
      trt1pje: true,
      sp1jf: true,
      jef: true,
      trf3: true,
      trf3pje: true,
      tjsp: true,
      tjmilsp: true,
      trt15: true,
      trt15pje: true,
      trt2: true,
      trt2pje: true,
      jef: true,
      jfpr: true,
      tjpr: true,
      tre_pr: true,
      trt09_pr: true,
      jfsc: true,
      tjsc: true,
      tre_sc: true,
      trt12_sc: true,
      jfrs: true,
      tjrs: true,
      tre_rs: true,
      trt04_rs: true,
      jfes: true,
      tjes: true,
      tre_es: true,
      trt17_es: true,
      jfgo: true,
      tjgo: true,
      tre_go: true,
      trt18_go: true,
      jfms: true,
      tjms: true,
      tre_ms: true,
      trt24_ms: true,
      jfm: true,
      tjmt: true,
      tre_mt: true,
      trt23_mt: true,
      jfto: true,
      tjto: true,
      tre_to: true,
      trt10_to: true,
      jfdf: true,
      tjdf: true,
      tre_df: true,
      trt10_df: true,
      jfro: true,
      tjro: true,
      tre_ro: true,
      trt14_ro: true,
      jfam: true,
      tjam: true,
      tre_am: true,
      trt11_am: true,
      jfac: true,
      tjac: true,
      tre_ac: true,
      trt14_ac: true,
      jfrr: true,
      tjrr: true,
      tre_rr: true,
      trt11_rr: true,
      jfpa: true,
      tjpa: true,
      tre_pa: true,
      trt08_pa: true,
      jfap: true,
      tjap: true,
      tre_ap: true,
      trt08_ap: true,
      jfma: true,
      tjma: true,
      tre_ma: true,
      trt16_ma: true,
      jfpi: true,
      tjpi: true,
      tre_pi: true,
      trt22_pi: true,
      jfce: true,
      tjce: true,
      tre_ce: true,
      trt07_ce: true,
      jfrn: true,
      tjrn: true,
      tre_rn: true,
      trt21_rn: true,
      jfpb: true,
      jfpb_pje: true,
      tjpb: true,
      tre_pb: true,
      trt13_pb: true,
      jfpe: true,
      tjpe: true,
      tre_pe: true,
      trt06_pe: true,
      jfal: true,
      tjal: true,
      tre_al: true,
      trt19_al: true,
      trt20_se: true,
      jfse: true,
      tjse: true,
      tre_se: true,
      trt19_se: true,
      jfba: true,
      tjba: true,
      tre_ba: true,
      trt05_ba: true,
      sponsor: null,
      sponsorsList: [],
      lawsuit: null,
      lawsuitList: [],
      client: null,
      clientList: [],
      destaque: null,
      destaqueList: destaqueList,
      tipo_parte: null,
      tipoParteList: [],
      status: null,
      statusList: statusList,
      showTipo: false,
      showResponsavel: false,
      showStatus: false,
      date_cadastro_start: null,
      date_cadastro_end: null,
      date_movimento_start: null,
      date_movimento_end: null,
      date_distribuicao_start: null,
      date_distribuicao_end: null,
      date_andamento_start: null,
      area: null,
      areaList: [],
      tipo: null,
      tipoList: [],
      comarca: null,
      comarcaList: [],
      fase: null,
      faseList: [],
      filial: null,
      filialList: [],
      statusLawsuitTribunal: null,
      statusLawsuitTribunalList: [],
      perda: null,
      perdaList: [],
      switchMovements: false,
      qtdMovements: qtdList[0],
      qtdMovementsList: qtdList,
      switchRAI: false,
      qtdRAI: qtdList[0],
      qtdRAIList: qtdList,
      switchCompromissos: false,
      qtdCompromissos: qtdList[0],
      qtdCompromissosList: qtdList,
      qtdSaldoList: typeList,
      qtdSaldo: typeList[0],
      switchPublicacoes: false,
      qtdPublicacoes: qtdList[0],
      qtdPublicacoesList: qtdList,
      switchSaldo: false,
      showEmail: false,
      showTelefones: false,
      showEndereco: false,
      pasta: "",
      showValorCausa: false,
      loadingVisible: false,
      loadingMessage: [],
    }

    if(isTablet()){
      alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
      window.location.href = '/';
    }

  }

  componentWillReceiveProps(nextProps) {

    this.clearFields();

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    oldShowOnReport = null;
    this.setState({
      print: 0
    });
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false,        
      });
      this._cancel = true;
    });

    this.usersService = new UsersService();
    this.lawsuitService = new LawsuitService();
    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    this.tribunalService = new TribunalService();

    this.tribunalService.list( ).then( tribunalList  =>{

      this.setState({
        tribunalList: tribunalList,
      });

    });

    this.usersService.list( ).then(sponsorsList =>{

      this.setState({
        sponsorsList: sponsorsList,
      });

    });

    this.lawsuitService.officeList().then(response =>{

      this.setState({
        lawsuitList: response
      });

    });

    this.LawsuitPersonagesService.clientList( ).then(res =>{
      this.setState({
        clientList: res,
      });

    });

    this.LawsuitPersonagesService.getTypes( ).then(res =>{
      this.setState({
        tipoParteList: res,
      });

    });

    this.additionalDataLawsuitService.areaList( ).then(res =>{
          this.setState({
            areaList: res,
          });
    });

    this.additionalDataLawsuitService.tipoList( ).then(res =>{
          this.setState({
            tipoList: res,
          });
    });

    this.additionalDataLawsuitService.comarcaList( ).then(res =>{
          this.setState({
            comarcaList: res,
          });
    });

    this.additionalDataLawsuitService.faseList( ).then(res =>{
          this.setState({
            faseList: res,
          });
    });

    this.additionalDataLawsuitService.filialList( ).then(res =>{
          this.setState({
            filialList: res,
          });
    });

    /*this.additionalDataLawsuitService.statusList( ).then(res =>{
          this.setState({
            statusLawsuitTribunalList: res,
          });
    });*/

    this.setState({
      statusLawsuitTribunalList: [
        {label: "CADASTRADO", value: "CADASTRADO"},
        {label: "ERRO", value: "ERRO"},
        {label: "VALIDANDO", value: "VALIDANDO"},
        {label: "ARQUIVADO", value: "ARQUIVADO"},
        {label: "SEGREDO", value: "SEGREDO"},
      ],
    });    

    this.additionalDataLawsuitService.perdaList( ).then(res =>{
          this.setState({
            perdaList: res,
          });
    });

  }

  markAll = (e) => {
    let checkboxes = document.querySelectorAll("#filter-tribunals-target input[type=checkbox]");
    let state = {};

    for(let cb in checkboxes){
      //console.log(checkboxes[cb].name);
      state[ checkboxes[cb].name ] = true;
    }
    this.setState( state );
    //console.log(checkboxes);

  }

  unmarkAll = (e) => {
    let checkboxes = document.querySelectorAll("#filter-tribunals-target input[type=checkbox]");
    let state = {};

    for(let cb in checkboxes){
      //console.log(checkboxes[cb].name);
      state[ checkboxes[cb].name ] = false;
    }
    this.setState( state );
    //console.log(checkboxes);

  }

  ufChange = (e) => {
    let checkboxes = document.querySelectorAll( "."+e.target.getAttribute("id") );
    let state = {};

    for(let cb in checkboxes){
      //console.log(checkboxes[cb].name);
      state[ checkboxes[cb].name ] = e.target.checked;
    }
    state[e.target.name]= e.target.checked;
    this.setState( state );
    //console.log(checkboxes);

  }

  handleChange = (e) => {

          this.setState(
              {
                  [e.target.name]: e.target.value
              }
          )

   }

  selectChange = (selected, actionMeta) => {

    let pops = [];
    pops[ actionMeta.name ] = selected;

      if( actionMeta.name === "lawsuit" && selected !== null){
        document.getElementById("clientContainer").className += ' hide';
        document.getElementById("destaqueContainer").className += ' hide';
        document.getElementById("tipoParteContainer").className += ' hide';
        document.getElementById("sponsorContainer").className += ' hide';
        document.getElementById("statusContainer").className += ' hide';
        document.getElementById("filtro-data").className += ' hide';
        document.getElementById("pastaContainer").className += ' hide';
        document.getElementById("areaContainer").className += ' hide';
        document.getElementById("tipoContainer").className += ' hide';
        document.getElementById("comarcaContainer").className += ' hide';
        document.getElementById("faseContainer").className += ' hide';
        document.getElementById("filialContainer").className += ' hide';
        document.getElementById("statusLawsuitTribunalContainer").className += ' hide';
        document.getElementById("perdaContainer").className += ' hide';

        pops['client'] = null;
        pops['destaque'] = null;
        pops['tipo_parte'] = null;
        pops['sponsor'] = null;
        pops['status'] = null;
        pops['area'] = null;
        pops['tipo'] = null;
        pops['comarca'] = null;
        pops['fase'] = null;
        pops['filial'] = null;
        pops['statusLawsuitTribunal'] = null;
        pops['perda'] = null;

      }else{
        document.getElementById("clientContainer").classList.remove("hide");
        document.getElementById("destaqueContainer").classList.remove("hide");
        document.getElementById("tipoParteContainer").classList.remove("hide");
        document.getElementById("sponsorContainer").classList.remove("hide");
        document.getElementById("statusContainer").classList.remove("hide");
        document.getElementById("filtro-data").classList.remove("hide");
        document.getElementById("pastaContainer").classList.remove("hide");
        document.getElementById("areaContainer").classList.remove("hide");
        document.getElementById("tipoContainer").classList.remove("hide");
        document.getElementById("comarcaContainer").classList.remove("hide");
        document.getElementById("faseContainer").classList.remove("hide");
        document.getElementById("filialContainer").classList.remove("hide");
        document.getElementById("statusLawsuitTribunalContainer").classList.remove("hide");
        document.getElementById("perdaContainer").classList.remove("hide");
      }

      if ( typeof selected !== undefined && selected !== "" ) {

            this.setState( pops );

      }
  }

  clientChange = (selected, actionMeta) => {

      document.getElementById("clientContainer").classList.remove("hide");
      document.getElementById("destaqueContainer").classList.remove("hide");
      document.getElementById("tipoParteContainer").classList.remove("hide");
      document.getElementById("sponsorContainer").classList.remove("hide");
      document.getElementById("statusContainer").classList.remove("hide");
      document.getElementById("filtro-data").classList.remove("hide");
      document.getElementById("pastaContainer").classList.remove("hide");
      document.getElementById("areaContainer").classList.remove("hide");
      document.getElementById("tipoContainer").classList.remove("hide");
      document.getElementById("comarcaContainer").classList.remove("hide");
      document.getElementById("faseContainer").classList.remove("hide");
      document.getElementById("filialContainer").classList.remove("hide");
      document.getElementById("statusLawsuitTribunalContainer").classList.remove("hide");
      document.getElementById("perdaContainer").classList.remove("hide");

      console.group('Value Changed');
     //console.log(selected);
     //console.log(`action: ${actionMeta.action}`);
     //console.log(actionMeta);
      console.groupEnd();

      let pops = [];
      pops[ 'client' ] = selected;

      this.setState( pops );
  }

  checkChange = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;

        let object = {
           [item]: isChecked
         }

        this.setState( object );
  }

  niceCheckChange = (item, check) => {

    let object = {
       [item]: !check
     }

    this.setState( object );
  }

  back = () => {
    this.clearFields();
  }

  print = (event, pdf=false) => {
    this.setState({
      print: 1,
      printPDF: pdf
    });
  }

  clearFields = () => {
    oldShowOnReport = null;
    this.setState ({
      doReport: 0,
      print: 0,
      federal: true,
      mg: true,
      sp: true,
      rj: true,
      manual: true,
      inss: true,
      stf: true,
      stj: true,
      tse: true,
      tst: true,
      trf1: true,
      tjmg: true,
      tjmgpje: true,
      tjmgprojudi: true,
      trt3: true,
      trt3pje: true,
      jfrj: true,
      trf2: true,
      tjrj: true,
      trt1: true,
      trt1pje: true,
      sp1jf: true,
      jef: true,
      trf3: true,
      trf3pje: true,
      tjsp: true,
      tjmilsp: true,
      trt15: true,
      trt15pje: true,
      trt2: true,
      trt2pje: true,
      jef: true,
      sponsor: null,
      lawsuit: null,
      client: null,
      destaque: null,
      tipo_parte: null,
      showTipoParte: false,
      status: null,
      showTipo: false,
      showResponsavel: false,
      showStatus: false,
      date_cadastro_start: null,
      date_cadastro_end: null,
      date_movimento_start: null,
      date_movimento_end: null,
      date_distribuicao_start: null,
      date_distribuicao_end: null,
      date_andamento_start: null,
      area: null,
      showArea: false,
      showComarca: false,
      tipo: null,
      comarca: null,
      fase: null,
      filial: null,
      statusLawsuitTribunal: null,
      perda: null,
      switchMovements: false,
      qtdMovements: this.state.qtdMovementsList[0],
      switchRAI: false,
      qtdRAI: this.state.qtdMovementsList[0],
      switchCompromissos: false,
      qtdCompromissos: this.state.qtdMovementsList[0],
      switchPublicacoes: false,
      qtdPublicacoes: this.state.qtdMovementsList[0],
      qtdSaldo: this.state.qtdSaldoList[0],
      switchSaldo: false,
      showEmails: false,
      showTelefones: false,
      showEndereco: false,
      pasta: "",
      showPasta: false,
      showFase: false,
      showFilial: false,
      showStatusLawsuitTribunal: false,
      showPerda: false,
      showValorCausa: false,
      loadingVisible: false,
      resultLawsuitList: []
    });
    this._milisecondsAjax = 0;
    this._countAjax = 0;
  }

  doReport = () => {

    let tribunals = ""; let page=1;
    let tribunalList = this.state.tribunalList;

    this.setState({
      loadingVisible: true,
      resultLawsuitList: [],
      loadingMessage: ["O relatório está sendo carregado.", "Por gentileza, aguarde...", 0+" Processos encontrados. Tempo estimado de 30 a 60 segundos"]
    });

    if( this.state.manual === true ){
      tribunals = "?list_manual=1&tribunals[]=-1";
    }else {
      tribunals = "?list_manual=0";
    }

    for(let i=0; i < tribunalList.length; i++){

      if( tribunalList[i].slug.toLowerCase() === "inss" && this.state.inss === true ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "stf" && this.state.stf === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "stj" && this.state.stj === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tse" && this.state.tse === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tst" && this.state.tst === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trf1" && this.state.trf1 === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjmg" && this.state.tjmg === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjmgpje" && this.state.tjmgpje === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjmgprojudi" && this.state.tjmgprojudi === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt3" && this.state.trt3 === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt3pje" && this.state.trt3pje === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfrj" && this.state.jfrj === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
       //console.log(tribunals);
      }
      if( tribunalList[i].slug.toLowerCase() === "trf2" && this.state.trf2 === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjrj" && this.state.tjrj === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt01-rj" && this.state.trt1 === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt1pje" && this.state.trt1pje === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfsp" && this.state.sp1jf === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jef" && this.state.jef === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trf3" && this.state.trf3 === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trf3pje" && this.state.trf3pje === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjsp" && this.state.tjsp === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjmilsp" && this.state.tjmilsp === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt15" && this.state.trt15 === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt15pje" && this.state.trt15pje === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt2" && this.state.trt2 === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt2pje" && this.state.trt2pje === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jef" && this.state.jef === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjpr" && this.state.tjpr === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt09-pr" && this.state.trt09_pr === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfpr" && this.state.jfpr === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjsc" && this.state.tjsc === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt12-sc" && this.state.trt12_sc === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfsc" && this.state.jfsc === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjrs" && this.state.tjrs === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt04-rs" && this.state.trt04_rs === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfrs" && this.state.jfrs === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjgo" && this.state.tjgo === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt18-go" && this.state.trt18_go === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfgo" && this.state.jfgo === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjms" && this.state.tjms === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt24-ms" && this.state.trt24_ms === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfms" && this.state.jfms === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjmt" && this.state.tjmt === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt23-mt" && this.state.trt23_mt === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfm" && this.state.jfm === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjdf" && this.state.tjdf === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt10-df" && this.state.trt10_df === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfdf" && this.state.jfdf === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjto" && this.state.tjto === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt10-to" && this.state.trt10_to === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfto" && this.state.jfto === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjro" && this.state.tjro === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt14-ro" && this.state.trt14_ro === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfro" && this.state.jfro === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjam" && this.state.tjam === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt11-am" && this.state.trt11_am === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfam" && this.state.jfam === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjac" && this.state.tjac === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt14-ac" && this.state.trt14_ac === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfac" && this.state.jfac === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjrr" && this.state.tjrr === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt11-rr" && this.state.trt11_rr === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfrr" && this.state.jfrr === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjpa" && this.state.tjpa === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tr08-pa" && this.state.trt08_pa === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfpa" && this.state.jfpa === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjap" && this.state.tjap === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt08-ap" && this.state.trt08_ap === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfap" && this.state.jfap === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjma" && this.state.tjma === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt16-ma" && this.state.trt16_ma === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfma" && this.state.jfma === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjpi" && this.state.tjpi === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt22-pi" && this.state.trt22_pi === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfpi" && this.state.jfpi === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjce" && this.state.tjce === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt07-ce" && this.state.trt07_ce === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfce" && this.state.jfce === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjrn" && this.state.tjrn === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt21-rn" && this.state.trt21_rn === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfrn" && this.state.jfrn === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjpb" && this.state.tjpb === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt13-pb" && this.state.trt13_pb === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfpb" && this.state.jfpb === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfpb-pje" && this.state.jfpb_pje === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjpe" && this.state.tjpe === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt06-pe" && this.state.trt06_pe === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfpe" && this.state.jfpe === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjal" && this.state.tjal === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt19-al" && this.state.trt19_al === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfal" && this.state.jfal === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjse" && this.state.tjse === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt20-se" && this.state.trt20_se === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfse" && this.state.jfse === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "tjba" && this.state.tjba === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "trt05-ba" && this.state.trt05_ba === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }
      if( tribunalList[i].slug.toLowerCase() === "jfba" && this.state.jfba === true  ){
        tribunals += "&tribunals[]="+tribunalList[i].id;
      }

    }

    if(tribunals === "?list_manual=0"){
      alert("Selecione pelo menos um tribunal");
      this.setState({
        loadingVisible: false,
        resultLawsuitList: [],
      });
    }else{
      this.reportPaginate(tribunals, 1);
    }

  }

  reportPaginate(tribunals, page){

    let resultLawsuitList = this.state.resultLawsuitList;

    this.lawsuitService.report(
      this.state.lawsuit, this.state.client, this.state.destaque, this.state.sponsor, this.state.status,
      this.state.tipo_parte, this.state.pasta, this.state.area, this.state.tipo, this.state.comarca,
      this.state.fase, this.state.filial, this.state.statusLawsuitTribunal, this.state.perda,
      this.state.date_movimento_start, this.state.date_movimento_end, this.state.date_andamento_start, tribunals,
      this.state.date_cadastro_start, this.state.date_cadastro_end, this.state.date_distribuicao_start,
      this.state.date_distribuicao_end, page
    ).then(response =>{

      if( !this._cancel ){

        if( response.success == true){

          if( typeof response.data !== "undefined" ){

            for(let i=0; i<response.data.Lawsuits.length; i++){
              resultLawsuitList.push( response.data.Lawsuits[i] );
            }
      
            if( response.pagination.nextPage === true ){
              let tempo_restante = (Math.ceil((response.pagination.count/180)+1-(resultLawsuitList.length/180)));
              this.setState({
                resultLawsuitList,
                loadingMessage: ["O relatório está sendo carregado.", "Por gentileza, aguarde... "+(resultLawsuitList.length-1)+" processos carregados de ", response.pagination.count+" Processos encontrados. Tempo estimado "+tempo_restante+" minutos."]
              });

              setTimeout(() => {

                this.reportPaginate(tribunals, page+1);
        
              }, Math.random() * (200 - 10) + 10);

            }else{
              this.setState({
                resultLawsuitList,
                doReport: 1,
                loadingVisible: false
              });
            }
    
          }

        }else{
          document.body.style.cursor = "default";
          this.setState({
            doReport: 1,
            loadingVisible: false,
            resultLawsuitList
          });
         //console.log(response.message);
          alert("Desculpe ocorreu um erro. "+response.message);
        }

      }else{
        this._cancel = false;
      }

    })
    .catch(err =>{
          if( this._isMounted ){
              document.body.style.cursor = "default";
              this.setState({
                doReport: 1,
                loadingVisible: false,
                resultLawsuitList
              });
             //console.log(err);
              alert("Desculpe ocorreu um erro. "+err);
          }
    });
  }

  getStateFederal = () => {
    if( this.state.inss && this.state.stf && this.state.stj && this.state.tse && this.state.tst ){
      return true;
    }else{
      return false;
    }
  }

  getStateMG = () => {
    if( this.state.trf1 && this.state.tjmg && this.state.tjmgpje && this.state.tjmgprojudi && this.state.trt3 && this.state.trt3pje ){
      return true;
    }else{
      return false;
    }
  }

  getStateRJ = () => {
    if( this.state.jfrj && this.state.trf2 && this.state.tjrj && this.state.trt1 && this.state.trt1pje ){
      return true;
    }else{
      return false;
    }
  }

  getStateSP = () => {
    if(
        this.state.sp1jf && this.state.jef && this.state.trf3 && this.state.trf3pje &&
        this.state.tjsp && this.state.tjmilsp && this.state.trt15 && this.state.trt15pje &&
        this.state.trt2 && this.state.trt2pje && this.state.jef
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePR = () => {
    if(
        this.state.jfpr && this.state.tjpr /*&& this.state.tre_pr*/ &&
        this.state.trt09_pr 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateSC = () => {
    if(
        this.state.jfsc && this.state.tjsc /*&& this.state.tre_sc*/ &&
        this.state.trt12_sc 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRS = () => {
    if(
        this.state.jfrs && this.state.tjrs /*&& this.state.tre_rs*/ &&
        this.state.trt04_rs 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateES = () => {
    if(
        this.state.jfes && this.state.tjes /*&& this.state.tre_es*/ &&
        this.state.trt17_es 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateGO = () => {
    if(
        this.state.jfgo && this.state.tjgo /*&& this.state.tre_go*/ &&
        this.state.trt18_go 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateMS = () => {
    if(
        this.state.jfms && this.state.tjms /*&& this.state.tre_ms*/ &&
        this.state.trt24_ms 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateMT = () => {
    if(
        this.state.jfm && this.state.tjmt /*&& this.state.tre_mt*/ &&
        this.state.trt23_mt 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateTO = () => {
    if(
        this.state.jfto && this.state.tjto /*&& this.state.tre_to*/ &&
        this.state.trt10_to
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateDF = () => {
    if(
        this.state.jfdf && this.state.tjdf /*&& this.state.tre_df*/ &&
        this.state.trt10_df
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRO = () => {
    if(
        this.state.jfro && this.state.tjro /*&& this.state.tre_ro*/ &&
        this.state.trt14_ro
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAM = () => {
    if(
        this.state.jfam && this.state.tjam /*&& this.state.tre_am*/ &&
        this.state.trt11_am
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAC = () => {
    if(
        this.state.jfac && this.state.tjac /*&& this.state.tre_ac*/ &&
        this.state.trt14_ac
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRR = () => {
    if(
        this.state.jfrr && this.state.tjrr /*&& this.state.tre_rr*/ &&
        this.state.trt11_rr
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePA = () => {
    if(
        this.state.jfpa && this.state.tjpa /*&& this.state.tre_pa*/ &&
        this.state.trt08_pa
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAP = () => {
    if(
        this.state.jfap && this.state.tjap /*&& this.state.tre_ap*/ &&
        this.state.trt08_ap
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateMA = () => {
    if(
        this.state.jfma && this.state.tjma /*&& this.state.tre_ma*/ &&
        this.state.trt16_ma
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePI = () => {
    if(
        this.state.jfpi && this.state.tjpi /*&& this.state.tre_pi*/ &&
        this.state.trt22_pi
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateCE = () => {
    if(
        this.state.jfce && this.state.tjce /*&& this.state.tre_ce*/ &&
        this.state.trt07_ce
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRN = () => {
    if(
        this.state.jfrn && this.state.tjrn /*&& this.state.tre_rn*/ &&
        this.state.trt21_rn
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePB = () => {
    if(
        this.state.jfpb && this.state.jfpb_pje && this.state.tjpb /*&& this.state.tre_pb*/ &&
        this.state.trt13_pb
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePE = () => {
    if(
        this.state.jfpe && this.state.tjpe /*&& this.state.tre_pe*/ &&
        this.state.trt06_pe
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAL = () => {
    if(
        this.state.jfal && this.state.tjal /*&& this.state.tre_al*/ &&
        this.state.trt19_al
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateSE = () => {
    if(
        this.state.jfse && this.state.tjse /*&& this.state.tre_se*/ &&
        this.state.trt20_se
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateBA = () => {
    if(
        this.state.jfba && this.state.tjba /*&& this.state.tre_ba*/ &&
        this.state.trt05_ba
      )
    {
      return true;
    }else{
      return false;
    }
  }

  filterTribunalsComponent = () => {

    let federal = this.state.federal;
    federal = this.getStateFederal();
    let mg = this.state.mg;
    mg = this.getStateMG();
    let rj = this.state.rj;
    rj = this.getStateRJ();
    let sp = this.state.sp;
    sp = this.getStateSP();
    let pr = this.state.pr;
    pr = this.getStatePR();
    let sc = this.state.sc;
    sc = this.getStateSC();
    let rs = this.state.rs;
    rs = this.getStateRS();
    let es = this.state.es;
    es = this.getStateES();
    let go = this.state.go;
    go = this.getStateGO();
    let ms = this.state.ms;
    ms = this.getStateMS();
    let mt = this.state.mt;
    mt = this.getStateMT();
    let to = this.state.to;
    to = this.getStateTO();
    let df = this.state.df;
    df = this.getStateDF();
    let ro = this.state.ro;
    ro = this.getStateRO();
    let am = this.state.am;
    am = this.getStateAM();
    let ac = this.state.ac;
    ac = this.getStateAC();
    let rr = this.state.rr;
    rr = this.getStateRR();
    let pa = this.state.pa;
    pa = this.getStatePA();
    let ap = this.state.ap;
    ap = this.getStateAP();
    let ma = this.state.ma;
    ma = this.getStateMA();
    let pi = this.state.pi;
    pi = this.getStatePI();
    let ce = this.state.ce;
    ce = this.getStateCE();
    let rn = this.state.rn;
    rn = this.getStateRN();
    let pb = this.state.pb;
    pb = this.getStatePB();
    let pe = this.state.pe;
    pe = this.getStatePE();
    let al = this.state.al;
    al = this.getStateAL();
    let se = this.state.se;
    se = this.getStateSE();
    let ba = this.state.ba;
    ba = this.getStateBA();

    return <div className="panel panel-default" id="filtros">
                  <div className="panel-heading text-center">
                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#filter-tribunals-target">
                          <strong>Filtro de Tribunais</strong>
                      </div>
                  </div>
                  <div className="panel-body collapse" id="filter-tribunals-target">
                      <button type="button" className="btn btn-xs btn-primary bt-rel-proc-trib-check " onClick={this.markAll}>Marcar todos</button>
                      <MyPopover icon="white" text=" Possibilita filtrar por Estado e/ou Tribunal específico, permite selecionar mais que um para o filtro." />

                      <button type="button" className="btn btn-xs btn-danger bt-rel-proc-trib-uncheck " onClick={this.unmarkAll}>Desmarcar todos</button>
                      <div className="form-group">

                          <div className="col-md-12">

                              <div className="panel panel-info">
                                  <div className="panel-heading text-center">
                                      <input type="checkbox" className="chk_estado_trib" name="federal" checked={(federal === true) ? "checked" : ""} onChange={this.ufChange} id="cb_fe" />
                                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#federals-target">
                                          <strong>Federais</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse" id="federals-target">
                                    <label htmlFor="chk_27" className=" col-md-4"><input type="checkbox" name="inss" checked={(this.state.inss === true) ? "checked" : ""} onChange={this.checkChange} id="chk_27" className="cb_fe" /> INSS - Previdência Social</label>
                                    <label htmlFor="chk_2" className=" col-md-4"><input type="checkbox" name="stf" checked={(this.state.stf === true) ? "checked" : ""} onChange={this.checkChange} id="chk_2" className="cb_fe" /> STF - Supremo Tribunal Federal</label>
                                    <label htmlFor="chk_7" className=" col-md-4"><input type="checkbox" name="stj" checked={(this.state.stj === true) ? "checked" : ""} onChange={this.checkChange} id="chk_7" className="cb_fe" /> STJ - Superior Tribunal de Justiça</label>
                                    <label htmlFor="chk_12" className=" col-md-4"><input type="checkbox" name="tse" checked={(this.state.tse === true) ? "checked" : ""} onChange={this.checkChange} id="chk_12" className="cb_fe" /> TSE - Tribunal Superior Eleitoral</label>
                                    <label htmlFor="chk_10" className=" col-md-4"><input type="checkbox" name="tst" checked={(this.state.tst === true) ? "checked" : ""} onChange={this.checkChange} id="chk_10" className="cb_fe" /> TST - Tribunal Superior do Trabalho</label>
                                  </div>
                              </div>
                              <div className="panel panel-info">
                                  <div className="panel-heading text-center">
                                      <input type="checkbox" className="chk_estado_trib pull-left" name="mg" checked={(mg === true) ? "checked" : ""} onChange={this.ufChange} id="cb_mg" />
                                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#mg-target">
                                          <strong>Minas Gerais</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse" id="mg-target">
                                    <label htmlFor="chk_15" className=" col-md-4"><input type="checkbox" name="trf1" checked={(this.state.trf1 === true) ? "checked" : ""} onChange={this.checkChange} id="chk_15" className="cb_mg" /> MG - TRF1 - Tribunal Regional Federal 1ª Região</label>
                                    <label htmlFor="chk_16" className=" col-md-4"><input type="checkbox" name="tjmg" checked={(this.state.tjmg === true) ? "checked" : ""} onChange={this.checkChange} id="chk_16" className="cb_mg" /> MG - Tribunal de Justiça</label>
                                    <label htmlFor="chk_17" className=" col-md-4"><input type="checkbox" name="tjmgpje" checked={(this.state.tjmgpje === true) ? "checked" : ""} onChange={this.checkChange} id="chk_17" className="cb_mg" /> MG - Tribunal de Justiça - PJE</label>
                                    <label htmlFor="chk_18" className=" col-md-4"><input type="checkbox" name="tjmgprojudi" checked={(this.state.tjmgprojudi === true) ? "checked" : ""} onChange={this.checkChange} id="chk_18" className="cb_mg" /> MG - Tribunal de Justiça - PROJUDI</label>
                                    <label htmlFor="chk_14" className=" col-md-4"><input type="checkbox" name="trt3" checked={(this.state.trt3 === true) ? "checked" : ""} onChange={this.checkChange} id="chk_14" className="cb_mg" /> MG - Tribunal Regional do Trabalho 3ª Região</label>
                                    <label htmlFor="chk_13" className=" col-md-4"><input type="checkbox" name="trt3pje" checked={(this.state.trt3pje === true) ? "checked" : ""} onChange={this.checkChange} id="chk_13" className="cb_mg" /> MG - Tribunal Regional do Trabalho 3ª Região - PJE</label>
                                  </div>
                              </div>
                              <div className="panel panel-info">
                                  <div className="panel-heading text-center">
                                      <input type="checkbox" name="manual" checked={(this.state.manual === true) ? "checked" : ""} onChange={this.checkChange} id="chk_1000" className="cb_manual" />
                                      <div className="panel-title">
                                          <strong>Processo Manual</strong>
                                      </div>
                                  </div>
                              </div>
                              <div className="panel panel-info">
                                  <div className="panel-heading text-center">
                                      <input type="checkbox" className="chk_estado_trib pull-left" name="rj" checked={(rj === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rj" />
                                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rj-target">
                                          <strong>Rio de Janeiro</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse" id="rj-target">
                                      <label htmlFor="chk_20" className=" col-md-4"><input type="checkbox" name="jfrj" checked={(this.state.jfrj === true) ? "checked" : ""} onChange={this.checkChange} id="chk_20" className="cb_rj" />RJ - Justiça Federal</label>
                                      <label htmlFor="chk_21" className=" col-md-4"><input type="checkbox" name="trf2" checked={(this.state.trf2 === true) ? "checked" : ""} onChange={this.checkChange} id="chk_21" className="cb_rj" /> RJ - TRF2 - Tribunal Regional Federal 2ª Região</label>
                                      <label htmlFor="chk_19" className=" col-md-4"><input type="checkbox" name="tjrj" checked={(this.state.tjrj === true) ? "checked" : ""} onChange={this.checkChange} id="chk_19" className="cb_rj" /> RJ - Tribunal de Justiça</label>
                                      <label htmlFor="chk_23" className=" col-md-4"><input type="checkbox" name="trt1" checked={(this.state.trt1 === true) ? "checked" : ""} onChange={this.checkChange} id="chk_23" className="cb_rj" /> RJ - Tribunal Regional do Trabalho 1° Região</label>
                                      <label htmlFor="chk_22" className=" col-md-4"><input type="checkbox" name="trt1pje" checked={(this.state.trt1pje === true) ? "checked" : ""} onChange={this.checkChange} id="chk_22" className="cb_rj" /> RJ - Tribunal Regional do Trabalho 1° Região - PJE</label>
                                  </div>
                              </div>
                              <div className="panel panel-info">
                                  <div className="panel-heading text-center">
                                      <input type="checkbox" className="chk_estado_trib pull-left" name="sp" checked={(sp === true) ? "checked" : ""} onChange={this.ufChange} id="cb_sp" />
                                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#sp-target">
                                          <strong>São Paulo</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse" id="sp-target">
                                  <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="sp1jf" checked={(this.state.sp1jf === true) ? "checked" : ""} onChange={this.checkChange} id="chk_11" className="cb_sp" />SP - 1º Grau Justiça Federal</label>
                                  <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="trf3" checked={(this.state.trf3 === true) ? "checked" : ""} onChange={this.checkChange} id="chk_6" className="cb_sp" /> SP - TRF3 - Tribunal Regional Federal 3ª Região</label>
                                  <label htmlFor="chk_1" className=" col-md-4"><input type="checkbox" name="tjsp" checked={(this.state.tjsp === true) ? "checked" : ""} onChange={this.checkChange} id="chk_1" className="cb_sp" /> SP - Tribunal de Justiça</label>
                                  <label htmlFor="chk_24" className=" col-md-4"><input type="checkbox" name="tjmilsp" checked={(this.state.tjmilsp === true) ? "checked" : ""} onChange={this.checkChange} id="chk_24" className="cb_sp" /> SP - TM - Tribunal Militar</label>
                                  <label htmlFor="chk_3" className=" col-md-4"><input type="checkbox" name="trt15" checked={(this.state.trt15 === true) ? "checked" : ""} onChange={this.checkChange} id="chk_3" className="cb_sp" /> SP - Tribunal Regional do Trabalho 15ª Região</label>
                                  <label htmlFor="chk_4" className=" col-md-4"><input type="checkbox" name="trt2" checked={(this.state.trt2 === true) ? "checked" : ""} onChange={this.checkChange} id="chk_4" className="cb_sp" /> SP - Tribunal Regional do Trabalho 2ª Região</label>
                                  <label htmlFor="chk_26" className=" col-md-4"><input type="checkbox" name="jef" checked={(this.state.jef === true) ? "checked" : ""} onChange={this.checkChange} id="chk_26" className="cb_sp" /> SP - Juizado Especial Federal</label>
                              </div>

                            </div>

                              <div className="panel estado sudeste panel-info">
                                  <div className="panel-heading text-center">
                                      <input type="checkbox" className="chk_estado_trib pull-left" name="es" checked={(es === true) ? "checked" : ""} onChange={this.ufChange} id="cb_es" />
                                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#es-target">
                                          <strong>Espírito Santo</strong>
                                      </div>
                                  </div>
                                  <div className="panel-body collapse" id="es-target">
                                      <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfes" checked={(this.state.jfes === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_es" />ES - TRF2</label>
                                      <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjes" checked={(this.state.tjes === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_es" />ES - Tribunal de Justiça</label>
                                      <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_es" checked={(this.state.tre_es === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_es" />ES - TRE</label>
                                      <label htmlFor="chk_24" className=" col-md-4">
                                      <input type="checkbox" name="trt17_es" checked={(this.state.trt17_es === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_es" />ES - TRT 17ª Região</label>
                                  </div>
                              </div>


                              <div className="panel estado sul panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="pr" checked={(pr === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pr" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pr-target">
                                        <strong>Paraná</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="pr-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpr" checked={(this.state.jfpr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pr" />PR - TRF4</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpr" checked={(this.state.tjpr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pr" />PR - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pr" checked={(this.state.tre_pr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_pr" />PR - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt09_pr" checked={(this.state.trt09_pr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_pr" />PR - TRT 9ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado sul panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="sc" checked={(sc === true) ? "checked" : ""} onChange={this.ufChange} id="cb_sc" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#sc-target">
                                        <strong>Santa Catarina</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="sc-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfsc" checked={(this.state.jfsc === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_sc" />SC - TRF4</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjsc" checked={(this.state.tjpr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_sc" />SC - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_sc" checked={(this.state.tre_pr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_sc" />SC - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt12_sc" checked={(this.state.trt12_sc === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_sc" />SC - TRT 12ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado sul panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="rs" checked={(rs === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rs" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rs-target">
                                        <strong>Rio Grande do Sul</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="rs-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfrs" checked={(this.state.jfrs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rs" />RS - TRF4</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjrs" checked={(this.state.tjrs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_rs" />RS - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_rs" checked={(this.state.tre_rs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_rs" />RS - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt04_rs" checked={(this.state.trt04_rs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_rs" />RS - TRT 4ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado centro-oeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="go" checked={(go === true) ? "checked" : ""} onChange={this.ufChange} id="cb_go" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#go-target">
                                        <strong>Goiás</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="go-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfgo" checked={(this.state.jfgo === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_go" />GO - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjgo" checked={(this.state.tjgo === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_go" />GO - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_go" checked={(this.state.tre_go === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_go" />GO - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt18_go" checked={(this.state.trt18_go === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_go" />ES - TRT 17ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado centro-oeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="ms" checked={(ms === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ms" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ms-target">
                                        <strong>Mato Grosso do Sul</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="ms-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfms" checked={(this.state.jfms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ms" />MS - TRF3</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjms" checked={(this.state.tjms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ms" />MS - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ms" checked={(this.state.tre_ms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_ms" />MS - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt24_ms" checked={(this.state.trt24_ms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_ms" />MS - TRT 24ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado centro-oeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="mt" checked={(mt === true) ? "checked" : ""} onChange={this.ufChange} id="cb_mt" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#mt-target">
                                        <strong>Mato Grosso</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="mt-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfm" checked={(this.state.jfm === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_mt" />MT - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjmt" checked={(this.state.tjmt === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_mt" />MT - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_mt" checked={(this.state.tre_mt === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_mt" />MT - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt23_mt" checked={(this.state.trt23_mt === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_mt" />MT - TRT 23ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado centro-oeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="df" checked={(df === true) ? "checked" : ""} onChange={this.ufChange} id="cb_df" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#df-target">
                                        <strong>Distrito Federal</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="df-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfdf" checked={(this.state.jfdf === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_df" />DF - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjdf" checked={(this.state.tjdf === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_df" />DF - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_df" checked={(this.state.tre_df === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_df" />DF - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt10_df" checked={(this.state.trt10_df === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_df" />DF - TRT 10ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado norte panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="to" checked={(to === true) ? "checked" : ""} onChange={this.ufChange} id="cb_to" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#to-target">
                                        <strong>Tocantins</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="to-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfto" checked={(this.state.jfto === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_to" />TO - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjto" checked={(this.state.tjto === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_to" />TO - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_to" checked={(this.state.tre_to === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_to" />TO - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt10_to" checked={(this.state.trt10_to === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_to" />TO - TRT 10ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado norte panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="ro" checked={(ro === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ro" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ro-target">
                                        <strong>Rondônia</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="ro-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfro" checked={(this.state.jfro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ro" />RO - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjro" checked={(this.state.tjro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ro" />RO - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ro" checked={(this.state.tre_ro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_ro" />RO - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt14_ro" checked={(this.state.trt14_ro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_ro" />RO - TRT 14ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado norte panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="am" checked={(am === true) ? "checked" : ""} onChange={this.ufChange} id="cb_am" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#am-target">
                                        <strong>Amazonas</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="am-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfam" checked={(this.state.jfam === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_am" />AM - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjam" checked={(this.state.tjam === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_am" />AM - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_am" checked={(this.state.tre_am === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_am" />AM - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt11_am" checked={(this.state.trt11_am === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_am" />AM - TRT 11ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado norte panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="ac" checked={(ac === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ac" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ac-target">
                                        <strong>Acre</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="ac-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfac" checked={(this.state.jfac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ac" />AC - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjac" checked={(this.state.tjac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ac" />AC - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ac" checked={(this.state.tre_ac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ac" />AC - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt14_ac" checked={(this.state.trt14_ac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ac" />AC - TRT 14ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado norte panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="rr" checked={(rr === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rr" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rr-target">
                                        <strong>Roraima</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="rr-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfrr" checked={(this.state.jfrr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rr" />RR - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjrr" checked={(this.state.tjrr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_rr" />RR - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_rr" checked={(this.state.tre_rr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rr" />RR - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt11_rr" checked={(this.state.trt11_rr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_rr" />RR - TRT 11ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado norte panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="pa" checked={(pa === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pa" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pa-target">
                                        <strong>Pará</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="pa-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpa" checked={(this.state.jfpa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pa" />PA - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpa" checked={(this.state.tjpa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pa" />PA - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pa" checked={(this.state.tre_pa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pa" />PA - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt08_pa" checked={(this.state.trt08_pa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pa" />PA - TRT 8ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado norte panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="ap" checked={(ap === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ap" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ap-target">
                                        <strong>Amapá</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="ap-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfap" checked={(this.state.jfap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ap" />AP - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjap" checked={(this.state.tjap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ap" />AP - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ap" checked={(this.state.tre_ap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ap" />AP - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt08_ap" checked={(this.state.trt08_ap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ap" />AP - TRT 8ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="ma" checked={(ma === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ma" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ma-target">
                                        <strong>Maranhão</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="ma-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfma" checked={(this.state.jfma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ma" />MA - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjma" checked={(this.state.tjma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ma" />MA - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ma" checked={(this.state.tre_ma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ma" />MA - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt16_ma" checked={(this.state.trt16_ma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ma" />MA - TRT 16ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="pi" checked={(pi === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pi" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pi-target">
                                        <strong>Piauí</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="pi-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpi" checked={(this.state.jfpi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pi" />PI - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpi" checked={(this.state.tjpi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pi" />PI - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pi" checked={(this.state.tre_pi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pi" />PI - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt22_pi" checked={(this.state.trt22_pi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pi" />PI - TRT 22ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="ce" checked={(ce === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ce" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ce-target">
                                        <strong>Ceará</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="ce-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfce" checked={(this.state.jfce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ce" />CE - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjce" checked={(this.state.tjce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ce" />CE - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ce" checked={(this.state.tre_ce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ce" />CE - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt07_ce" checked={(this.state.trt07_ce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ce" />CE - TRT 07ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="rn" checked={(rn === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rn" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rn-target">
                                        <strong>Rio Grande do Norte</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="rn-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfrn" checked={(this.state.jfrn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rn" />RN - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjrn" checked={(this.state.tjrn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_rn" />RN - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_rn" checked={(this.state.tre_rn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rn" />RN - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt21_rn" checked={(this.state.trt21_rn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_rn" />RN - TRT 21ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="pb" checked={(pb === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pb" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pb-target">
                                        <strong>Paraíba</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="pb-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpb" checked={(this.state.jfpb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pb" />PB - TRF5</label>
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpb_pje" checked={(this.state.jfpb_pje === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_12" className="cb_pb" />PB - TRF5 PJE</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpb" checked={(this.state.tjpb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pb" />PB - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pb" checked={(this.state.tre_pb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pb" />PB - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt13_pb" checked={(this.state.trt13_pb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pb" />PB - TRT 13ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="pe" checked={(pe === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pe" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pe-target">
                                        <strong>Pernambuco</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="pe-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpe" checked={(this.state.jfpe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pe" />PE - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpe" checked={(this.state.tjpe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pe" />PE - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pe" checked={(this.state.tre_pe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pe" />PE - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt06_pe" checked={(this.state.trt06_pe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pe" />PE - TRT 06ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="al" checked={(al === true) ? "checked" : ""} onChange={this.ufChange} id="cb_al" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#al-target">
                                        <strong>Alagoas</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="al-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfal" checked={(this.state.jfal === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_al" />AL - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjal" checked={(this.state.tjal === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_al" />AL - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_al" checked={(this.state.tre_al === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_al" />AL - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt19_al" checked={(this.state.trt19_al === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_al" />AL - TRT 19ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="se" checked={(se === true) ? "checked" : ""} onChange={this.ufChange} id="cb_se" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#se-target">
                                        <strong>Sergipe</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="se-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfse" checked={(this.state.jfse === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_se" />SE - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjse" checked={(this.state.tjse === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_se" />SE - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_se" checked={(this.state.tre_se === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_se" />SE - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt20_se" checked={(this.state.trt20_se === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_se" />SE - TRT 20ª Região</label>
                                </div>

                            </div>

                            <div className="panel estado nordeste panel-info">
                                <div className="panel-heading text-center">
                                    <input type="checkbox" className="chk_estado_trib pull-left" name="ba" checked={(ba === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ba" />
                                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ba-target">
                                        <strong>Bahia</strong>
                                    </div>
                                </div>
                                <div className="panel-body collapse" id="ba-target">
                                    <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfba" checked={(this.state.jfba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ba" />BA - TRF1</label>
                                    <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjba" checked={(this.state.tjba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ba" />BA - Tribunal de Justiça</label>
                                    <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ba" checked={(this.state.tre_ba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ba" />BA - TRE</label>
                                    <label htmlFor="chk_24" className=" col-md-4">
                                    <input type="checkbox" name="trt05_ba" checked={(this.state.trt05_ba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ba" />BA - TRT 5ª Região</label>
                                </div>

                            </div>

                          </div>

                      </div>

                  </div>
              </div>;
  }

  filterGenericComponent = () => {

    return <div className="panel panel-default" id="filtro-generico">
              <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#filter-generic-target">
                        <strong>Filtros Genéricos</strong>
                    </div>
              </div>
              <div className="panel-body" id="filter-generic-target">

                      <div className="row">
                            <div className="form-group col-md-12">
                                  <label>
                                          ( Mostrar no relatório {example} )
                                          <MyPopover icon="white" text="Selecione quais dados devem  aparecer impressos individualmente no relatório . Estas opções não são filtros." />

                                  </label>
                            </div>
                      </div>
                      <div className="row">
                            <div className="form-group col-md-4">
                                <label htmlFor="lawsuit">
                                        Nº do Processo
                                        <MyPopover icon="white" text="Quando não escolhido um processo, serão apresentados todos os processos. Quando selecionado um processo, será possível somente a escolha de itens que deverão aparecer no Relatório." />

                                </label>
                                <Select
                                    className="lawsuit"
                                    options={this.state.lawsuitList}
                                    placeholder="Todos"
                                    styles={customStyles}
                                    isClearable={true}
                                    name="lawsuit"
                                    value={this.state.lawsuit}
                                    onChange={this.selectChange}
                                  />
                            </div>
                            <div id="clientContainer" className="form-group col-md-4">
                                <label htmlFor="client">
                                  Nome do Cliente
                                  <MyPopover icon="white" text='Para uma pesquisa genérica ou por expressão, digite a palavra desejada, que seja comum para todos, clique em pesquisar por este termo, para fixar a palavra e clique em Gerar Relatório' />
                                </label>
                                <Creatable
                                    className="client"
                                    classNamePrefix='clientFilter'
                                    options={this.state.clientList}
                                    placeholder="Todos"
                                    isValidNewOption={(inputValue, selectValue, selectOptions, accessors) => true}
                                    styles={customStyles}
                                    isClearable={true}
                                    value={this.state.client}
                                    name="client"
                                    onChange={this.clientChange}
                                    createOptionPosition="first"
                                    formatCreateLabel={(inputText) => `Pesquisar por este termo: "${inputText}"`}
                                  />
                            </div>
                            <div id="destaqueContainer" className="form-group col-md-4">
                                <label htmlFor="destaque">Destaque</label>
                                <Select
                                    className="destaque"
                                    options={this.state.destaqueList}
                                    placeholder="Todos"
                                    styles={customStyles}
                                    isClearable={true}
                                    value={this.state.destaque}
                                    name="destaque"
                                    onChange={this.selectChange}
                                  />
                            </div>
                      </div>
                      <div className="row">
                            <div className="form-group col-md-4">
                                  <div className="toggle">
                                         <input className="toggle__input" type="checkbox" name="showTipoParte" readOnly checked={(this.state.showTipoParte === true) ? "checked" : ""} />
                                         <span className="toggle__label" onClick={ () => {this.niceCheckChange("showTipoParte", this.state.showTipoParte)} }>
                                           <span className="toggle__text"></span>
                                         </span>
                                   </div>
                                  <label htmlFor="tipo_parte" className="">Tipo da Parte</label>
                                  <Select
                                      id="tipoParteContainer"
                                      styles={customStyles}
                                      className="tipo_parte"
                                      name="tipo_parte"
                                      value={this.state.tipo_parte}
                                      options={this.state.tipoParteList}
                                      placeholder="Todos"
                                      isClearable={true}
                                      onChange={this.selectChange}
                                  />
                            </div>
                            <div className="form-group col-md-4">
                                <div className="toggle">
                                       <input className="toggle__input" type="checkbox" name="showResponsavel" readOnly checked={(this.state.showResponsavel === true) ? "checked" : ""} />
                                       <span className="toggle__label" onClick={ () => {this.niceCheckChange("showResponsavel", this.state.showResponsavel)} }>
                                         <span className="toggle__text"></span>
                                       </span>
                                 </div>
                                <label htmlFor="user">Responsável</label>
                                <Select
                                    id="sponsorContainer"
                                    className="sponsor"
                                    options={this.state.sponsorsList}
                                    placeholder="Todos"
                                    styles={customStyles}
                                    isClearable={true}
                                    value={this.state.sponsor}
                                    name="sponsor"
                                    onChange={this.selectChange}
                                  />
                            </div>
                            <div className="form-group col-md-4">
                                <div className="toggle">
                                       <input className="toggle__input" type="checkbox" name="showStatus" readOnly checked={(this.state.showStatus === true) ? "checked" : ""} />
                                       <span className="toggle__label" onClick={ () => {this.niceCheckChange("showStatus", this.state.showStatus)} }>
                                         <span className="toggle__text"></span>
                                       </span>
                                 </div>
                                <label htmlFor="status" >Ativos / Arquivados</label>
                                <Select
                                    id="statusContainer"
                                    className=""
                                    options={this.state.statusList}
                                    name="status"
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                    value={this.state.status}
                                    onChange={this.selectChange}
                                  />
                            </div>
                            <div className="form-group col-md-4">
                                    <div className="toggle">
                                           <input className="toggle__input" type="checkbox" name="showValorCausa" readOnly checked={(this.state.showValorCausa === true) ? "checked" : ""} />
                                           <span className="toggle__label" onClick={ () => {this.niceCheckChange("showValorCausa", this.state.showValorCausa)} }>
                                             <span className="toggle__text"></span>
                                           </span>
                                     </div>
                                    <label htmlFor="valorCausa" >Valor da Causa</label>
                            </div>
                      </div>
              </div>
    </div>

  }

    onDatePickerChange = (date, dateString, name) => {
      let disabled = false;

      if (dateString === "" ){
        dateString = null;
      }

      if(dateString !== "" && dateString !== null){
          disabled = true;
      }else{
          disabled = false;
      }

      if( name === 'date_cadastro_start'){

        this.setState(
            {
                'date_cadastro_end': dateString,
                'date_movimento_start_disabled': disabled,
                'date_andamento_start_disabled': disabled,
                'date_distribuicao_start_disabled': disabled,
            }
        )
      }

      if( name === 'date_movimento_start'){
        this.setState(
            {
                'date_movimento_end': dateString,
                'date_cadastro_start_disabled': disabled,
                'date_andamento_start_disabled': disabled,
                'date_distribuicao_start_disabled': disabled,
            }
        )
      }

      if( name === 'date_distribuicao_start'){
        this.setState(
            {
                'date_distribuicao_end': dateString,
                'date_cadastro_start_disabled': disabled,
                'date_andamento_start_disabled': disabled,
                'date_movimento_start_disabled': disabled,
            }
        )
      }

      if( name === 'date_andamento_start'){
        this.setState(
            {
                'date_cadastro_start_disabled': disabled,
                'date_distribuicao_start_disabled': disabled,
                'date_movimento_start_disabled': disabled,
            }
        )
      }

       this.setState(
           {
               [name]: dateString,
           }
       )

     }

     disabledEndDate = endValue => {
       const startValue = moment(this.state.date_cadastro_start, "DD/MM/YYYY");
       if (!endValue || !startValue) {
         return false;
       }
       return endValue.valueOf() < startValue.valueOf();
     }

     disabledMovimentoEndDate = endValue => {
       const startValue = moment(this.state.date_movimento_start, "DD/MM/YYYY");
       if (!endValue || !startValue) {
         return false;
       }
       return endValue.valueOf() < startValue.valueOf();
     }

     disabledDistribuicaoEndDate = endValue => {
       const startValue = moment(this.state.date_distribuicao_start, "DD/MM/YYYY");
       if (!endValue || !startValue) {
         return false;
       }
       return endValue.valueOf() < startValue.valueOf();
     }

  filterDateComponent = () => {

    let date_end_disabled = true; let date_movimento_end_disabled = true; let date_distribuicao_end_disabled = true;
    if( this.state.date_cadastro_start !== null ){
      date_end_disabled = false;
    }

    if( this.state.date_movimento_start !== null ){
      date_movimento_end_disabled = false;
    }

    if( this.state.date_distribuicao_start !== null ){
      date_distribuicao_end_disabled = false;
    }

    return <div className="panel panel-default" id="filtro-data">
                  <div className="panel-heading text-center">
                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#filter-datas-target">
                          <strong>Filtro por Datas</strong>
                          <MyPopover icon="white" text="Evite demora na extração dos relatórios, adicionando o filtro acima (somente processos 'Ativos')." />
                      </div>
                  </div>
                  <div className="panel-body collapse" id="filter-datas-target">
                        <div className="date-container">
                              <div className="text-left">
                                  <label htmlFor="date_cadastro_start" className="control-label">Data de Cadastro</label>
                                  <DatePicker locale={locale} disabled={this.state.date_cadastro_start_disabled} placeholder="Data Inicial" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_cadastro_start !== null ) ? moment(this.state.date_cadastro_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_cadastro_start")} />
                              </div>
                              <div className="text-left">
                                  <label htmlFor="date_cadastro_end" className="control-label">Até</label>
                                  <DatePicker locale={locale} className=" " disabled={date_end_disabled} placeholder="Data Limite" showToday={false} disabledDate={this.disabledEndDate} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_cadastro_end !== null ) ? moment(this.state.date_cadastro_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_cadastro_end")} />
                              </div>
                        </div>

                        <div className="date-container">
                                <div className="text-left">
                                    <label htmlFor="date_movimento_start" className="control-label">
                                            Processos com andamentos entre
                                            
                                          <MyPopover icon="white" text="Possibilita filtrar processos com andamentos entre duas datas ou de um dia específico colocando as duas datas iguais." />
                                          
                                    </label>
                                    <DatePicker locale={locale} id="date_movimento_start" disabled={this.state.date_movimento_start_disabled} placeholder="Data Inicial" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_movimento_start !== null ) ? moment(this.state.date_movimento_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_movimento_start")} />
                                </div>
                                <div className="text-left">
                                    <label htmlFor="date_movimento_end" className="control-label">Até</label>
                                    <DatePicker locale={locale} className=" " disabled={date_movimento_end_disabled} placeholder="Data Limite" showToday={false} disabledDate={this.disabledMovimentoEndDate} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_movimento_end !== null ) ? moment(this.state.date_movimento_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_movimento_end")} />
                                </div>
                        </div>

                        <div className="date-container">
                              <div className="text-left">
                                  <label htmlFor="date_distribuicao_start" className="control-label">Data de Distribuição</label>
                                  <DatePicker locale={locale} disabled={this.state.date_distribuicao_start_disabled} placeholder="Data Inicial" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_distribuicao_start !== null ) ? moment(this.state.date_distribuicao_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_distribuicao_start")} />
                              </div>
                              <div className="text-left">
                                  <label htmlFor="date_distribuicao_end" className="control-label">Até</label>
                                  <DatePicker locale={locale} className=" " disabled={date_distribuicao_end_disabled} placeholder="Data Limite" showToday={false} disabledDate={this.disabledDistribuicaoEndDate} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_distribuicao_end !== null ) ? moment(this.state.date_distribuicao_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_distribuicao_end")} />
                              </div>
                        </div>

                        <div className="date-container">
                              <div className="text-left date_andamento">
                                  <label htmlFor="date_andamento_start" className="control-label">Processos sem andamentos posteriores a </label>
                                  <DatePicker locale={locale} disabled={this.state.date_andamento_start_disabled} placeholder="" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_andamento_start !== null ) ? moment(this.state.date_andamento_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_andamento_start")} />
                                  <label>
                                          Até hoje

                                  <MyPopover icon="white" text="É possível filtrar processos que não tiveram andamentos nos últimos dias, semanas, meses ou anos, para que se possa tomar providências." />
                                          
                                  </label>
                              </div>
                        </div>

                  </div>
           </div>
  }

  filterAdditionalComponent = () => {

    return <div className="panel panel-default" id="filtro-adicional">
                  <div className="panel-heading text-center">
                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#filter-additional-target">
                          <strong>Filtro de Dados Complementares</strong>
                      </div>
                  </div>
                  <div className="panel-body collapse" id="filter-additional-target">
                          <div className="row">
                                <div className="form-group col-md-12">
                                      <label>
                                              ( Mostrar no relatório {example} )

                                          <MyPopover icon="white" text="Selecione quais dados devem  aparecer impressos individualmente no relatório . Estas opções não são filtros." />
                                          
                                      </label>
                                </div>
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                     <input className="toggle__input" type="checkbox" name="showPasta" readOnly checked={(this.state.showPasta === true) ? "checked" : ""} />
                                     <span className="toggle__label" onClick={ () => {this.niceCheckChange("showPasta", this.state.showPasta)} }>
                                       <span className="toggle__text"></span>
                                     </span>
                                </div>
                                <label htmlFor="pasta" className="control-label">Nº da Pasta</label>
                                <input type="text" name="pasta" onChange={this.handleChange} value={this.state.pasta} id="pastaContainer" className="form-control input-lg uppercase" />
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                       <input className="toggle__input" type="checkbox" name="showArea" readOnly checked={(this.state.showArea === true) ? "checked" : ""} />
                                       <span className="toggle__label" onClick={ () => {this.niceCheckChange("showArea", this.state.showArea)} }>
                                         <span className="toggle__text"></span>
                                       </span>
                                 </div>
                                <label htmlFor="area" className="">Área</label>
                                <Select
                                    id="areaContainer"
                                    className=""
                                    options={this.state.areaList}
                                    value={this.state.area}
                                    name="area"
                                    onChange={this.selectChange}
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                  />
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                     <input className="toggle__input" type="checkbox" name="showTipo" readOnly checked={(this.state.showTipo === true) ? "checked" : ""} />
                                     <span className="toggle__label" onClick={ () => {this.niceCheckChange("showTipo", this.state.showTipo)} }>
                                       <span className="toggle__text"></span>
                                     </span>
                                </div>
                                <label htmlFor="tipo" className="">Tipo</label>
                                <Select
                                    id="tipoContainer"
                                    className=""
                                    options={this.state.tipoList}
                                    value={this.state.tipo}
                                    name="tipo"
                                    onChange={this.selectChange}
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                  />
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                     <input className="toggle__input" type="checkbox" name="showComarca" readOnly checked={(this.state.showComarca === true) ? "checked" : ""} />
                                     <span className="toggle__label" onClick={ () => {this.niceCheckChange("showComarca", this.state.showComarca)} }>
                                       <span className="toggle__text"></span>
                                     </span>
                                </div>
                                <label htmlFor="comarca" className="">Comarca/Fórum</label>
                                <Select
                                    id="comarcaContainer"
                                    className=""
                                    options={this.state.comarcaList}
                                    value={this.state.comarca}
                                    name="comarca"
                                    onChange={this.selectChange}
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                  />
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                     <input className="toggle__input" type="checkbox" name="showFase" readOnly checked={(this.state.showFase === true) ? "checked" : ""} />
                                     <span className="toggle__label" onClick={ () => {this.niceCheckChange("showFase", this.state.showFase)} }>
                                       <span className="toggle__text"></span>
                                     </span>
                                </div>
                                <label htmlFor="fase" className="">Fase Processual</label>
                                <Select
                                    id="faseContainer"
                                    className=""
                                    options={this.state.faseList}
                                    value={this.state.fase}
                                    name="fase"
                                    onChange={this.selectChange}
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                  />
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                     <input className="toggle__input" type="checkbox" name="showFilial" readOnly checked={(this.state.showFilial === true) ? "checked" : ""} />
                                     <span className="toggle__label" onClick={ () => {this.niceCheckChange("showFilial", this.state.showFilial)} }>
                                       <span className="toggle__text"></span>
                                     </span>
                                </div>
                                <label htmlFor="filial" className="">Filial</label>
                                <Select
                                    id="filialContainer"
                                    className=""
                                    options={this.state.filialList}
                                    value={this.state.filial}
                                    name="filial"
                                    onChange={this.selectChange}
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                  />
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                     <input className="toggle__input" type="checkbox" name="showStatusLawsuitTribunal" readOnly checked={(this.state.showStatusLawsuitTribunal === true) ? "checked" : ""} />
                                     <span className="toggle__label" onClick={ () => {this.niceCheckChange("showStatusLawsuitTribunal", this.state.showStatusLawsuitTribunal)} }>
                                       <span className="toggle__text"></span>
                                     </span>
                                </div>
                                <label htmlFor="statusLawsuitTribunal" className="">Status</label>
                                <Select
                                    id="statusLawsuitTribunalContainer"
                                    className=""
                                    options={this.state.statusLawsuitTribunalList}
                                    value={this.state.statusLawsuitTribunal}
                                    name="statusLawsuitTribunal"
                                    onChange={this.selectChange}
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                  />
                          </div>
                          <div className="form-group col-md-3 text-left">
                                <div className="toggle">
                                     <input className="toggle__input" type="checkbox" name="showPerda" readOnly checked={(this.state.showPerda === true) ? "checked" : ""} />
                                     <span className="toggle__label" onClick={ () => {this.niceCheckChange("showPerda", this.state.showPerda)} }>
                                       <span className="toggle__text"></span>
                                     </span>
                                </div>
                                <label htmlFor="perda" className="">Perda</label>
                                <Select
                                    id="perdaContainer"
                                    className=""
                                    options={this.state.perdaList}
                                    value={this.state.perda}
                                    name="perda"
                                    onChange={this.selectChange}
                                    placeholder="Todos"
                                    isClearable={true}
                                    styles={customStyles}
                                  />
                          </div>
                  </div>
           </div>
  }

  onSwitchChange = ( field, state ) => {

      let elements = document.getElementsByClassName(field);
      //console.log(field);
      //console.log(elements);

      if(state){
        for (let n = elements.length - 1; n >= 0; n--) {
              elements[n].className += ' hide';
        }
      }else{
        for (let n = elements.length - 1; n >= 0; n--) {
             elements[n].classList.remove("hide");
        }
      }

      this.setState({
        [field]: !state,
      });

  }

  onSwitchReportChange = (checked) => {
        oldShowOnReport = this.state.showOnReport;
        if ( checked ){
            this.setState({
              showOnReport: true
            });

        }else{
          this.setState({
            showOnReport: false
          });
        }
  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else if( valor === null || typeof valor === "undefined") {
        valor = "0,00";
    }else{
      valor = valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  configShowComponent = () => {

    return <div className="panel panel-default" id="config-show">
                  <div className="panel-heading text-center">
                      <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#config-show-target">
                          <strong>Configuração de Exibição</strong>
                      </div>
                  </div>
                  <div className="panel-body collapse" id="config-show-target">
                          <div className="form-group col-md-7 text-left">
                              <div className="line col-md-12">
                                <div className="row">
                                      <div className="form-group col-md-12">
                                            <label>
                                                    Do Processo:

                                          <MyPopover icon="white" text="Escolha qual item e a quantidade para que apaceça no relatório. (Por processo)." />

                                            </label>
                                      </div>
                                </div>
                                <div className="sub space-top">
                                      <Switch checked={this.state.switchMovements} onChange={ ()=>{this.onSwitchChange('switchMovements', this.state.switchMovements)} }  />
                                      <label className="">Exibir Andamentos</label>
                                </div>
                                <label className="lb switchMovements hide">Quantidade</label>
                                <Select
                                    className="col-md-3 switchMovements hide"
                                    options={this.state.qtdMovementsList}
                                    value={this.state.qtdMovements}
                                    name="qtdMovements"
                                    onChange={this.selectChange}
                                  />
                              </div>
                              <div className="line col-md-12">
                                <div className="sub space-top">
                                      <Switch checked={this.state.switchRAI} onChange={ ()=>{this.onSwitchChange('switchRAI', this.state.switchRAI)} }  />
                                      <label className="">Registros de ações internas</label>
                                </div>

                                <label className="lb switchRAI hide">Quantidade</label>
                                <Select
                                    className="col-md-3 switchRAI hide"
                                    options={this.state.qtdRAIList}
                                    value={this.state.qtdRAI}
                                    name="qtdRAI"
                                    onChange={this.selectChange}
                                  />
                              </div>
                              <div className="line col-md-12">
                                <div className="sub space-top">
                                      <Switch checked={this.state.switchCompromissos} onChange={ ()=>{this.onSwitchChange('switchCompromissos', this.state.switchCompromissos)} }  />
                                      <label className="">Exibir Compromissos</label>
                                </div>
                                <label className="lb switchCompromissos hide">Quantidade</label>
                                <Select
                                    className="col-md-3 switchCompromissos hide"
                                    options={this.state.qtdCompromissosList}
                                    value={this.state.qtdCompromissos}
                                    name="qtdCompromissos"
                                    onChange={this.selectChange}
                                  />
                              </div>
                              <div className="line col-md-12">
                                <div className="sub space-top">
                                      <Switch checked={this.state.switchPublicacoes} onChange={ ()=>{this.onSwitchChange('switchPublicacoes', this.state.switchPublicacoes)} }  />
                                      <label className="">Exibir Publicações</label>
                                </div>
                                <label className="lb switchPublicacoes hide">Quantidade</label>
                                <Select
                                    className="col-md-3 switchPublicacoes hide"
                                    options={this.state.qtdPublicacoesList}
                                    value={this.state.qtdPublicacoes}
                                    name="qtdPublicacoes"
                                    onChange={this.selectChange}
                                  />
                              </div>
                              <div className="line col-md-12">
                                <div className="sub space-top">
                                      <Switch checked={this.state.switchSaldo} onChange={ ()=>{this.onSwitchChange('switchSaldo', this.state.switchSaldo)} }  />
                                      <label className="">Exibir Saldo Financeiro</label>
                                      <div className=""> </div>
                                </div>
                                <label className="lb switchSaldo hide">Quando</label>
                                <Select
                                    className="col-md-4 switchSaldo hide"
                                    options={this.state.qtdSaldoList}
                                    value={this.state.qtdSaldo}
                                    name="qtdSaldo"
                                    onChange={this.selectChange}
                                  />
                              </div>

                          </div>
                          <div className="form-group col-md-5 text-left cliente">
                                <div className="col-md-12">
                                    <div className="doCliente">
                                          Do cliente: ( Mostrar no relatório {example} )
                                          <MyPopover icon="white" text="Selecione quais dados devem  aparecer impressos individualmente no relatório . Estas opções não são filtros." />

                                    </div>
                                </div>
                                <div className="col-md-12">
                                        <label htmlFor="showEndereco" className="">Endereço</label>
                                        <div className="toggle">
                                               <input className="toggle__input" type="checkbox" name="showEndereco" readOnly checked={(this.state.showEndereco === true) ? "checked" : ""} />
                                               <span className="toggle__label" onClick={ () => {this.niceCheckChange("showEndereco", this.state.showEndereco)} }>
                                                 <span className="toggle__text"></span>
                                               </span>
                                         </div>
                                </div>
                                <div className="col-md-12">
                                         <label htmlFor="showTelefones" className="">Telefones</label>
                                         <div className="toggle">
                                                <input className="toggle__input" type="checkbox" name="showTelefones" readOnly checked={(this.state.showTelefones === true) ? "checked" : ""} />
                                                <span className="toggle__label" onClick={ () => {this.niceCheckChange("showTelefones", this.state.showTelefones)} }>
                                                  <span className="toggle__text"></span>
                                                </span>
                                          </div>
                                </div>
                                <div className="col-md-12">
                                          <label htmlFor="showEmails" className="">E-mails</label>
                                          <div className="toggle">
                                                 <input className="toggle__input" type="checkbox" name="showEmails" readOnly checked={(this.state.showEmails === true) ? "checked" : ""} />
                                                 <span className="toggle__label" onClick={ () => {this.niceCheckChange("showEmails", this.state.showEmails)} }>
                                                   <span className="toggle__text"></span>
                                                 </span>
                                         </div>
                                </div>
                          </div>
                  </div>
           </div>
  }

  resultReportComponent = () => {
    return <div>
                { (this.state.resultLawsuitList.length > 0) ? this.state.resultLawsuitList.map( (record, index) =>  this.resultComponent( {record, index}, 'report-lawsuit-item' ) ) : "Nenhum resultado encontrado"}
           </div>;
  }

  formComponent = () => {
    return <div>
                {this.filterTribunalsComponent()}
                {this.filterGenericComponent()}
                {this.filterDateComponent()}
                {this.filterAdditionalComponent()}
                {this.configShowComponent()}

                <span className="btn btn-primary form-submit view-report right" onClick={this.doReport}>Gerar Relatório</span>
           </div>;
  }

  headerButtonsComponent = () => {
    return <div>
                  <span className="btn btn-info" onClick={this.back} >Voltar</span>
                  <span className="btn btn-success btn-report btn-excel right hide">Exportar Excel</span>
                  <span className="btn btn-default btn-report right" onClick={(event)=>{this.print(event, true)} }>Gera PDF</span>
                  <span className="btn btn-default btn-report right" onClick={(event)=>{this.print(event)} }>Imprimir</span>
                  <span className="btn btn-primary btn-report right hide">Enviar email</span>
           </div>
  }

  resultShowClientComponent = ( personage_token ) => {

      this.LawsuitPersonagesService = new LawsuitPersonagesService();

      if( oldShowOnReport == null ){

        this._milisecondsAjax += 100;

        setTimeout(() => {


          this.LawsuitPersonagesService.view( personage_token ).then(personage =>{

            let response = document.createElement('div');
            let endereco = ""; let telefones = ""; let emails = "";
    
           //console.log(personage);
            if( this.state.showEndereco === true ){
              let inp_rua = ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].street : "";
              let inp_numero = ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].number : "";
              let complemento = ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].complement : "";
              let inp_bairro = ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].neighbor : "";
              let opt_estado = ( typeof personage.personage_addresses[0] !== "undefined" && personage.personage_addresses[0].state !== null ) ? personage.personage_addresses[0].state.uf : "";
              let cidade =  ( typeof personage.personage_addresses[0] !== "undefined" && personage.personage_addresses[0].city !== null ) ? personage.personage_addresses[0].city.name : "";
    
              endereco = inp_rua+", n: "+inp_numero+" "+complemento+" - "+inp_bairro+" "+cidade+"/"+opt_estado;
    
              endereco = `
                <div class="col-md-2"><strong>Endereco </strong></div>
                <div class="col-md-4 endereco">${endereco}</div>
              `;
            }
    
            if ( this.state.showTelefones === true ){
                if( personage.personage_contacts.length > 0 ){
                    for( let i = 0; i < personage.personage_contacts.length; i++ ){
                       if( personage.personage_contacts[i].personage_contact_type_id === 1 ){
                         if( i > 0){
                           telefones += " / ";
                         }
                         telefones += personage.personage_contacts[i].value;
                       }
                    }
                    telefones = `
                      <div class="col-md-2"><strong>Telefones </strong></div>
                      <div class="col-md-4 telefones">${telefones}</div>
                    `;
                }
            }
    
            if ( this.state.showEmails === true ){
                if( personage.personage_contacts.length > 0 ){
                    for( let i = 0; i < personage.personage_contacts.length; i++ ){
                       if( personage.personage_contacts[i].personage_contact_type_id === 3 ){
                         if( i > 0 && emails !== ""){
                           emails += " / ";
                         }
                         emails += personage.personage_contacts[i].value;
                       }
                    }
                    emails = `
                      <div class="col-md-2"><strong>Emails </strong></div>
                      <div class="col-md-10 emails">${emails}</div>
                    `;
                }
            }
    
            response.className = '';
            response.innerHTML = `
              <div class="col-md-12"><strong>Dados do Cliente:</strong></div>
              ${endereco}${telefones}${emails}
            `;
    
            document.getElementById("data-client-"+personage_token).innerHTML = "";
            document.getElementById("data-client-"+personage_token).appendChild(response);
    
          });
  
        }, this._milisecondsAjax );

  
      }

  }

  resultShowFinanceComponent = ( lawsuit_token ) => {

      let new_list = []; let total = 0;

      this.FinanceService = new FinanceService();

      if( oldShowOnReport == null ){

        this._milisecondsAjax += 100;

        setTimeout(() => {
  
          this.FinanceService.report( "0", lawsuit_token, "0", this.state.qtdSaldo.value.toString(), "0", "0" ).then(list =>{

            let response = document.createElement('div');
            let movements = ""; let updated = "";
    
            list.map( ( lancamento ) => {
              let saldo = 0;
    
              lancamento.entries.map( ( entry ) => {
                if(entry.financial_type_id === 1){
                  saldo += entry.valor;
                }
                if(entry.financial_type_id === 2){
                  saldo -= entry.valor;
                }
                //console.log(saldo);
                return entry;
              });
    
              lancamento.saldo = saldo;
    
              if(this.state.qtdSaldo.value.toString() === "1"){
                new_list.push(lancamento);
                total += saldo;
              }else if (this.state.qtdSaldo.value.toString() === "2" && saldo >= 0){
                //console.log(lancamento);
                new_list.push(lancamento);
                total += saldo;
              }else if (this.state.qtdSaldo.value.toString() === "3" && saldo < 0){
                new_list.push(lancamento);
                total += saldo;
              }
    
              return lancamento;
    
            });
    
            response.className = '';
            response.innerHTML = `
              <div class="col-md-6"><strong>Saldo Total: </strong>R$ ${this.formataMoeda(total/2)}</div>
            `;
    
              document.getElementById("data-finance-"+lawsuit_token).innerHTML = "";
              document.getElementById("data-finance-"+lawsuit_token).appendChild( response ) ;
    
          });
  
        }, this._milisecondsAjax );

  
      }

  }

  resultShowMovementsComponent = ( lawsuit_token ) => {

      let responsavel = "";

      this._countAjax ++;
      this._milisecondsAjax += 100;

      this.LawsuitService = new LawsuitService();

      if( oldShowOnReport == null || this.state.print === 1 ){

        setTimeout(() => {

          this.LawsuitService.get( lawsuit_token ).then(lawsuit =>{

            let response = document.createElement('div'); let response_responsavel = document.createElement('div');
            let movements = ""; let updated = "";
    
            //console.log(lawsuit);
            if( typeof lawsuit.tribunal !== "undefined" && lawsuit.tribunal !== null && lawsuit.tribunal !== "null" && document.getElementById("tribunal-"+lawsuit_token) !== null  ){
              document.getElementById("tribunal-"+lawsuit_token).innerHTML =  lawsuit.tribunal.name ;
            }
    
            if( typeof lawsuit.movements !== "undefined" && lawsuit.movements.length > 0 && document.getElementById("updated-"+lawsuit_token) !== null ){
              updated = lawsuit.movements[0].date.substr(0, 10).split('-').reverse().join('/') ;
              document.getElementById("updated-"+lawsuit_token).innerHTML = updated;
              //console.log( updated );
    
              for( let i = 0; i < ( lawsuit.movements.length  ); i++ ){
                 if( i < this.state.qtdMovements.value ){
                   let date = "<div class='row linha-top' ><div class='col-md-2'>"+lawsuit.movements[i].date.substr(0, 10).split('-').reverse().join('/')+"</div>";
                   let andamento = "<div class='col-md-10'>"+lawsuit.movements[i].name+"</div></div>";
                   movements += date+andamento;
                 }
              }
    
            }
    
            if( !(typeof lawsuit.user_token === "undefined") ) {
                responsavel = this.state.sponsorsList.filter( ( sponsor )=>{
                    //console.log( sponsor.value.toString() === lawsuit.user_token )
                    return sponsor.value.toString() === lawsuit.user_token  ;
                } );
    
                responsavel = (responsavel.length > 0 && Array.isArray(responsavel) ) ? responsavel[0].label : "";
            }
    
            response.className = '';
            response.innerHTML = `
              <div class="col-md-12"><strong>Andamentos:</strong></div>
              ${movements}
            `;
            response_responsavel.innerHTML = `
              ${responsavel}
            `;
    
            if( this.state.showResponsavel ){
              document.getElementById("data-responsavel-"+lawsuit_token).innerHTML = "";
              document.getElementById("data-responsavel-"+lawsuit_token).appendChild( response_responsavel ) ;
            }
    
            if( this.state.switchMovements ){
              document.getElementById("data-movement-"+lawsuit_token).innerHTML = "";
              document.getElementById("data-movement-"+lawsuit_token).appendChild( response ) ;
            }
           //console.log(this._countAjax);
            this._countAjax = this._countAjax - 1;

            if( this._countAjax < 20 && document.getElementById("loader-background") !== null && document.getElementById("loader-background").style !== null ){
              document.getElementById("loader-background").style.display = "none";
            }
    
          });         

        }, this._milisecondsAjax );

  
      }

  }

  resultShowRAIComponent = ( lawsuit_token ) => {

      this.LawsuitService = new LawsuitService();

      if( oldShowOnReport == null ){

        this._milisecondsAjax += 100;

        setTimeout(() => {

          this.LawsuitService.listInformation( lawsuit_token ).then(rai =>{

            let response = document.createElement('div');
            let informations = ""; let updated = "";
    
            //console.log(rai);
    
            if( rai.length > 0 ){
    
              for( let i = 0; i < ( rai.length  ); i++ ){
                 if( i < this.state.qtdRAI.value ){
                   let date = ( rai[i].created !== "") ? moment( rai[i].created ).format("DD/MM/YYYY HH:mm") : "";
                       date = "<div class='row linha-top' ><div class='col-md-2'>"+ date + "</div>";
                   let info = "<div class='col-md-10'>"+rai[i].information+"</div></div>";
                   informations += date+info;
                 }
              }
    
            }
    
            response.className = '';
            response.innerHTML = `
              <div class="col-md-12"><strong>Registro de Ações Internas (RAI):</strong></div>
              ${informations}
            `;
    
    
            document.getElementById("data-rai-"+lawsuit_token).innerHTML = "";
            document.getElementById("data-rai-"+lawsuit_token).appendChild( response );
    
          });        

        }, this._milisecondsAjax );


  
      }
  }

  resultShowPublicacoesComponent = ( lawsuit_token ) => {

    //testar com 1012824-92.2019.8.26.0006

    this.PublicationsService = new PublicationsService();

    if( oldShowOnReport == null ){

      this._milisecondsAjax += 100;

      setTimeout(() => {


        this.PublicationsService.listPublicationsByLawsuit( lawsuit_token ).then( publications =>{

          let response = document.createElement('div');
          let events = ""; let updated = "";
    
          //console.log(publications);
    
          if( publications.length > 0 ){
    
            for( let i = 0; i < ( publications.length  ); i++ ){
               if( i < this.state.qtdPublicacoes.value ){
                 let start = new Date(publications[i].dataPublicacao);
    
                 start = ('0' + start.getDate()).slice(-2)+"/"+('0' + (start.getMonth()+1)).slice(-2)+"/"+start.getFullYear();
                 let date = "<div class='row linha-top' ><div class='col-md-2'>"+start+"</div>";
                 let info = "<div class='col-md-10'>"+publications[i].nomePesquisado+" - "+publications[i].oab+" - "+publications[i].conteudoPublicacao+"</div></div>";
                 events += date+info;
               }
            }
    
          }
    
          response.className = '';
          response.innerHTML = `
            <div class="col-md-12"><strong>Publicações:</strong></div>
            ${events}
          `;
    
    
          document.getElementById("data-publicacoes-"+lawsuit_token).innerHTML = "";
          document.getElementById("data-publicacoes-"+lawsuit_token).appendChild( response );
    
        });     

      }, this._milisecondsAjax );


    }
  }

  resultShowCompromissosComponent = ( lawsuit_token ) => {

      this.EventService = new EventService();

      let start = moment().startOf('day').subtract(45, 'days').format('YYYY-MM-DD');
      let end = moment().endOf('day').add(365, 'days').format('YYYY-MM-DD');

      if( oldShowOnReport == null ){

      this._milisecondsAjax += 100;

      setTimeout(() => {
  
        this.EventService.listByLawsuit( lawsuit_token, start, end ).then( compromissos =>{

          let response = document.createElement('div');
          let events = ""; let updated = "";
  
          //console.log(compromissos);
  
          if( compromissos.length > 0 ){
  
            for( let i = 0; i < ( compromissos.length  ); i++ ){
               if( i < this.state.qtdCompromissos.value ){
                 let start = new Date(compromissos[i].start);
  
                 start = ('0' + start.getDate()).slice(-2)+"/"+('0' + (start.getMonth()+1)).slice(-2)+"/"+start.getFullYear();
                 let date = "<div class='row linha-top' ><div class='col-md-2'>"+start+"</div>";
                 let info = "<div class='col-md-10'>"+compromissos[i].title+" - "+compromissos[i].description+"</div></div>";
                 events += date+info;
               }
            }
  
          }
  
          response.className = '';
          response.innerHTML = `
            <div class="col-md-12"><strong>Compromissos:</strong></div>
            ${events}
          `;
  
  
          document.getElementById("data-compromissos-"+lawsuit_token).innerHTML = "";
          document.getElementById("data-compromissos-"+lawsuit_token).appendChild( response );
  
        });

      }, this._milisecondsAjax );

    
      }

    }

  resultComponent = ( {record, index}, type ) => {

    let cliente = ""; let oponente = ""; let status = ""; let responsavel = "";
    let exibir_cliente = false; let personage_token = ""; let showAdditional = "";

    if( this.state.showPasta !== true && this.state.showArea !== true && this.state.showTipo !== true && this.state.showComarca !== true && this.state.showFase !== true && this.state.showFilial !== true && this.state.showStatusLawsuitTribunal !== true && this.state.showPerda !== true ){
      showAdditional = "hide";
    }

    let valor  = this.formataMoeda(record.valorCausa);

    if( this.state.doReport === 1 ) {
        window.scrollTo(0,0);
    }

    if( typeof record.lawsuits_personages !== "undefined" ){

      for(let i=0; i < record.lawsuits_personages.length; i++){
        if(record.lawsuits_personages[i].personage_side_id === 1){

          if(cliente !== ""){
            cliente += ",";
          }
          cliente += " "+record.lawsuits_personages[i].personage.name;
          if( this.state.showTipoParte === true && record.lawsuits_personages[i].personage_type_name != null ){
            cliente += "("+record.lawsuits_personages[i].personage_type_name+")";
          }
          if( exibir_cliente === false){
              if( this.state.showEndereco === true || this.state.showTelefones || this.state.showEmails ){
                  exibir_cliente = true;
                  personage_token = record.lawsuits_personages[i].personage.token;
              }
          }

        }else if(record.lawsuits_personages[i].personage_side_id === 2){

          oponente += " "+record.lawsuits_personages[i].personage.name;
          if( this.state.showTipoParte === true && record.lawsuits_personages[i].personage_type_name != null ){
            oponente += "("+record.lawsuits_personages[i].personage_type_name+")";
          }

        }
      }

    }

    if(typeof record.token !== "undefined"){
      if( document.getElementById("loader-background") !== null && document.getElementById("loader-background").style !== null ){
        document.getElementById("loader-background").style.display = "block";
      }
      this.resultShowMovementsComponent( record.token );
      if (this.state.switchSaldo) {
         this.resultShowFinanceComponent( record.token );
       }

    }

   //console.log(record);

    let dataDistribuicao = (typeof record.dataDistribuicao !== "undefined" && record.dataDistribuicao !== null )? record.dataDistribuicao.substr(0, 10).split('-').reverse().join('/') : "";
    let dataCadastro = (typeof record.created !== "undefined" && record.created !== null )? record.created.substr(0, 10).split('-').reverse().join('/') : "";

    if (this.state.showStatus===true ) {
      if(record.arquivo === 1){
        status = "(ARQUIVADO)";
      }
    }

    //console.log("updated-"+record.token);
    //<div className="col-md-2 both"><strong>Data Cadastro</strong></div>
    //<div className="col-md-4">{dataCadastro}</div>

    return <div className="panel panel-default" key={index}>
              <div className="panel-body">

                  <div className="row">
                      <div className="col-md-2 both"><strong>Cliente</strong></div>
                      <div className="col-md-4 uppercase">{cliente}</div>
                      <div className="col-md-2"><strong>Oponente</strong></div>
                      <div className="col-md-4 uppercase">{oponente}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-2"><strong>Processo</strong></div>
                      <div className="col-md-4">
                          <Link to={'/processo/detalhe_processo/'+record.token} >
                              {( record.number !== null ) ? record.number : record.name}
                          </Link>
                          { status }
                      </div>
                      <div className="col-md-2"><strong>Tribunal</strong></div>
                      <div className="col-md-4" id={"tribunal-"+record.token}></div>
                  </div>
                  <div className={(this.state.showResponsavel===true)? "row" : "hide"}>
                      <div className="col-md-2 both"><strong>Responsável</strong></div>
                      <div id={"data-responsavel-"+record.token} className="col-md-10"></div>
                  </div>
                  <div className={(this.state.showValorCausa===true)? "row" : "hide"}>
                      <div className="col-md-2"><strong>Valor da Causa</strong></div>
                      <div className="col-md-10">R$ {valor}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-2"><strong>Data Distribuição</strong></div>
                      <div className="col-md-4">{dataDistribuicao}</div>
                      <div className="col-md-2"><strong>Vara</strong></div>
                      <div className="col-md-3">{record.vara}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-2"><strong>Último Andamento</strong></div>
                      <div className="col-md-9" id={"updated-"+record.token}></div>
                      <div className="col-md-2 both"><strong>Assunto</strong></div>
                      <div className="col-md-10">{record.assunto}</div>
                  </div>
                  <div id={"data-client-"+personage_token} className="row data-client">
                      { (exibir_cliente) ? this.resultShowClientComponent( personage_token ) : null }
                  </div>
                  <div className={"row "+showAdditional}>
                      <div className="col-md-12"><strong>Dados complementares do processo:</strong></div>
                  </div>
                  <div className="row">
                      <div className={(this.state.showPasta === true) ? "col-md-2 both" : "hide"}><strong>Pasta</strong></div>
                      <div className={(this.state.showPasta === true) ? "col-md-4 uppercase" : "hide"}>{record.pasta}</div>
                      <div className={(this.state.showArea === true) ? "col-md-2" : "hide"}><strong>Área</strong></div>
                      <div className={(this.state.showArea === true) ? "col-md-4 uppercase" : "hide"}>{record.area}</div>
                  </div>

                  <div className="row">
                      <div className={(this.state.showTipo === true) ? "col-md-2 both" : "hide"}><strong>Tipo</strong></div>
                      <div className={(this.state.showTipo === true) ? "col-md-4 uppercase" : "hide"}>{record.tipo}</div>
                      <div className={(this.state.showComarca === true) ? "col-md-2" : "hide"}><strong>Comarca/Fórum</strong></div>
                      <div className={(this.state.showComarca === true) ? "col-md-4 uppercase" : "hide"}>{record.comarca}</div>
                  </div>
                  <div className="row">
                      <div className={(this.state.showFase === true) ? "col-md-2 both" : "hide"}><strong>Fase Processual</strong></div>
                      <div className={(this.state.showFase === true) ? "col-md-4 uppercase" : "hide"}>{record.faseProcessual}</div>
                      <div className={(this.state.showFilial === true) ? "col-md-2" : "hide"}><strong>Filial</strong></div>
                      <div className={(this.state.showFilial === true) ? "col-md-4 uppercase" : "hide"}>{record.filial}</div>
                  </div>
                  <div className="row">
                      <div className={(this.state.showStatusLawsuitTribunal === true) ? "col-md-2 both" : "hide"}><strong>Status</strong></div>
                      <div className={(this.state.showStatusLawsuitTribunal === true) ? "col-md-4 uppercase" : "hide"}>{record.status}</div>
                      <div className={(this.state.showPerda === true) ? "col-md-2" : "hide"}><strong>Perda</strong></div>
                      <div className={(this.state.showPerda === true) ? "col-md-4 uppercase" : "hide"}>{record.perda}</div>
                  </div>
                  <div id={"data-finance-"+record.token} className="row data-finance">
                  </div>
                  <div id={"data-movement-"+record.token} className="row data-movement">
                  </div>
                  <div id={"data-rai-"+record.token} className="row data-rai">
                      { (this.state.switchRAI) ? this.resultShowRAIComponent( record.token ) : null }
                  </div>
                  <div id={"data-compromissos-"+record.token} className="row data-compromissos">
                      { (this.state.switchCompromissos) ? this.resultShowCompromissosComponent( record.token ) : null }
                  </div>
                  <div id={"data-publicacoes-"+record.token} className="row data-publicacoes">
                      { (this.state.switchPublicacoes) ? this.resultShowPublicacoesComponent( record.token ) : null }
                  </div>
              </div>
      </div>;

  }

  panelFiltrosComponent = () => {
    let lawsuit = ( this.state.lawsuit !== null ) ? <li><b>Processo: </b> <span>{this.state.lawsuit.label}</span> </li> : "";
    let client = ( this.state.client !== null ) ? <li><b>Cliente: </b> <span>{this.state.client.label}</span> </li> : "";
    let destaque = ( this.state.destaque !== null ) ? <li><b>Destaque: </b> <span>{this.state.destaque.label}</span> </li> : "";
    let sponsor = ( this.state.sponsor !== null ) ? <li><b>Responsável: </b> <span>{this.state.sponsor.label}</span> </li> : "";
    let status = ( this.state.status !== null ) ? <li><b>Ativos / Arquivados: </b> <span>{this.state.status.label}</span> </li> : "";
    let tipo_parte = ( this.state.tipo_parte !== null ) ? <li><b>Tipo da Parte: </b> <span>{this.state.tipo_parte.label}</span> </li> : "";
    let pasta = ( this.state.pasta !== "" ) ? <li><b>Pasta: </b> <span>{this.state.pasta}</span> </li> : "";
    let area = ( this.state.area !== null ) ? <li><b>Área: </b> <span>{this.state.area.label}</span> </li> : "";
    let tipo = ( this.state.tipo !== null ) ? <li><b>Tipo: </b> <span>{this.state.tipo.label}</span> </li> : "";
    let comarca = ( this.state.comarca !== null ) ? <li><b>Comarca/Fórum: </b> <span>{this.state.comarca.label}</span> </li> : "";
    let fase = ( this.state.fase !== null ) ? <li><b>Fase Processual: </b> <span>{this.state.fase.label}</span> </li> : "";
    let filial = ( this.state.filial !== null ) ? <li><b>Filial: </b> <span>{this.state.filial.label}</span> </li> : "";
    let statusLawsuitTribunal = ( this.state.statusLawsuitTribunal !== null) ? <li><b>Status do Processo: </b> <span>{this.state.statusLawsuitTribunal.label}</span> </li> : "";
    let perda = ( this.state.perda !== null ) ? <li><b>Perda: </b> <span>{this.state.perda.label}</span> </li> : "";
    let date_movimento_start = ( this.state.date_movimento_start !== null ) ? <li><b>Processos com andamentos entre: </b> <span>{this.state.date_movimento_start}</span> </li> : "";
    let date_movimento_end = ( this.state.date_movimento_end !== null ) ? <li><b>Até: </b> <span>{this.state.date_movimento_end}</span> </li> : "";
    let date_andamento_start = ( this.state.date_andamento_start !== null ) ? <li><b>Processos sem andamentos posteriores a : </b> <span>{this.state.date_andamento_start} até hoje</span> </li> : "";
    let date_cadastro_start = ( this.state.date_cadastro_start !== null ) ? <li><b>Data de Cadastro: </b> <span>{this.state.date_cadastro_start}</span> </li> : "";
    let date_cadastro_end = ( this.state.date_cadastro_end !== null ) ? <li><b>Até: </b> <span>{this.state.date_cadastro_end}</span> </li> : "";
    let date_distribuicao_start = ( this.state.date_distribuicao_start !== null ) ? <li><b>Data de Distribuição: </b> <span>{this.state.date_distribuicao_start}</span> </li> : "";
    let date_distribuicao_end = ( this.state.date_distribuicao_end !== null ) ? <li><b>Até: </b> <span>{this.state.date_distribuicao_end}</span> </li> : "";

    let tribunals = "";

    if( this.state.inss === true ){
      tribunals += "inss, ";
    }
    if( this.state.stf === true  ){
      tribunals += "stf, ";
    }
    if( this.state.stj === true  ){
      tribunals += "stj, ";
    }
    if( this.state.tse === true  ){
      tribunals += "tse, ";
    }
    if( this.state.tst === true  ){
      tribunals += "tst, ";
    }
    if( this.state.trf1 === true  ){
      tribunals += "trf1, ";
    }
    if( this.state.tjmg === true  ){
      tribunals += "tjmg, ";
    }
    if( this.state.tjmgpje === true  ){
      tribunals += "tjmgpje, ";
    }
    if( this.state.tjmgprojudi === true  ){
      tribunals += "tjmgprojudi, ";
    }
    if( this.state.trt3 === true  ){
      tribunals += "trt3, ";
    }
    if( this.state.trt3pje === true  ){
      tribunals += "trt3pje, ";
    }
    if( this.state.jfrj === true  ){
      tribunals += "jfrj, ";
    }
    if( this.state.trf2 === true  ){
      tribunals += "trf2, ";
    }
    if( this.state.tjrj === true  ){
      tribunals += "tjrj, ";
    }
    if( this.state.trt1 === true  ){
      tribunals += "trt01-rj, ";
    }
    if( this.state.trt1pje === true  ){
      tribunals += "trt1pje, ";
    }
    if( this.state.sp1jf === true  ){
      tribunals += "jfsp, ";
    }
    if( this.state.jef === true  ){
      tribunals += "jef, ";
    }
    if( this.state.trf3 === true  ){
      tribunals += "trf3, ";
    }
    if( this.state.trf3pje === true  ){
      tribunals += "trf3pje, ";
    }
    if( this.state.tjsp === true  ){
      tribunals += "tjps, ";
    }
    if( this.state.tjmilsp === true  ){
      tribunals += "tjmilsp, ";
    }
    if( this.state.trt15 === true  ){
      tribunals += "trt15, ";
    }
    if( this.state.trt15pje === true  ){
      tribunals += "trt15pje, ";
    }
    if( this.state.trt2 === true  ){
      tribunals += "trt2, ";
    }
    if( this.state.trt2pje === true  ){
      tribunals += "trt2pje, ";
    }
    if( this.state.jef === true  ){
      tribunals += "jef";
    }

    tribunals = <div className="filter-tribunals"><b>Tribunais: </b> <span>{tribunals}</span> </div>;

    let filters = <ul>
                      {lawsuit}{client}{destaque}{sponsor}{status}{tipo_parte}{pasta}{area}{tipo}{comarca}{fase}{filial}
                      {statusLawsuitTribunal}{perda}{date_movimento_start}{date_movimento_end}{date_andamento_start}
                      {date_cadastro_start}{date_cadastro_end}{date_distribuicao_start}{date_distribuicao_end}
                  </ul>;

    return <div className="panel panel-default container-publicacoes">
                      <div className="panel-heading text-center">
                          <div className="pointer panel-title" data-toggle="collapse" data-target="#filter_target">
                              <strong>Filtros Utilizados</strong>
                          </div>
                      </div>
                      <div className={ (this.state.showOnReport) ? "panel-body" : "panel-body collapse" } id="filter_target">
                            {filters}
                            {tribunals}
                            <label id="showOnReportContainer" className="right">
                                    <MyPopover icon="white" text='Se desejar que os filtros do relatório apareçam no topo dos relatórios' />
                                    Aparecer no relatório
                                    <Switch title={(this.state.showOnReport)? "Desbloqueado": "Bloqueado"} checked={this.state.showOnReport} onChange={ this.onSwitchReportChange }  />
                            </label>
                      </div>
            </div>
  }

  render() {
      let url_base = localStorage.getItem('url_base');
      let header_buttons_component = null;
      let panel_filtros = this.panelFiltrosComponent();
      let content = "Nenhum resultado encontrado";

      if(this.state.doReport === 1){
        header_buttons_component = this.headerButtonsComponent();
        content = (this.state.resultLawsuitList.length > 0) ? this.state.resultLawsuitList.map( (record, index) =>  this.resultComponent( {record, index}, 'report-lawsuit-item' ) ) : "Nenhum resultado encontrado";
      }

      if(this.state.loadingVisible === true && document.getElementById("loader-background") != null){

        document.getElementById("loader-background").style.display = "block";
      }

      if( this.state.print === 1 ){
          if( !this.state.showOnReport ){
            panel_filtros = "";
          }
          return <div>
                    <PrintComponent modifiedViewPrint={this.modifiedViewPrint} panel_filtros={panel_filtros} content={content} title={"Relatório de Processos"} subtitle={"Processos"} printPDF={this.state.printPDF} />
               </div>
      }else{
          return <div className="sheet report-lawsuit">
                    <HeaderBar />
                    <NavigationMenu />
                    <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]}  cancel={true}  />
                    <div className="content-wrapper">
                      <div className="content-wrapper-inner">
                          <section className="content-header">
                            <h1 className="page-header">Relatórios - Processo</h1>
                            {header_buttons_component}
                          </section>
                          <div className="box-body">
                                {(this.state.doReport === 1) ? panel_filtros : null}
                                { (this.state.doReport === 1) ? this.resultReportComponent() : this.formComponent() }
                          </div>
                      </div>
                    </div>
                 </div>
      }
  }

}
