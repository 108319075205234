import React from 'react';

export default class HaveComment extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }



  constructor( props ) {
    super(props);
    this.changeText = this.changeText.bind(this);

    this.state = {
      commentTitle: "Exibir Comentários"
    }
  }

  changeText(){
    if( this.state.commentTitle === "Exibir Comentários"){
      this.setState(
        { commentTitle: "Ocultar Comentários"}
      )
    }else{
      this.setState(
        { commentTitle: "Exibir Comentários"}
      )
    }
  }


  render() {

      if ( typeof this.props.record.movement_comments !== "undefined" && this.props.record.movement_comments.length > 0 ) {
        return <i className="fa fa-comment" data-toggle="collapse" onClick={this.changeText} title={this.state.commentTitle} data-target={"#list-"+this.props.record.token}></i>
      }else{
        return "";
      }

  }
}
