import React from 'react';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Badge from '../containers/Badge';

import LawsuitService from '../../services/LawsuitService';
import Select from 'react-select';

import tribunalTypes from '../json/tribunalTypes';

import TribunalService from '../../services/TribunalService';
import AuthService from '../../services/AuthService';

import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import { Divider } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import Footer from "../containers/footer";
import FlashMessage from "../containers/FlashMessage";
import MyPopover from '../containers/mypopover';

import Loading from '../containers/loading';
import Alert from '../containers/alert';
import Confirm from '../containers/confirm';

const opt_pesquisa = [
  { value: 'cliente', label: 'CLIENTE' },
  { value: 'oponente', label: 'OPONENTE' },
  { value: 'cliente_e_oponente', label: 'CLIENTE E OPONENTE' },
  { value: 'processo', label: 'PROCESSO' },
];
const TabPane = Tabs.TabPane;

let totalSteps = ( list ) => {

  var sum = 0;

  if( list instanceof Array ){
    for  ( var item of list ) {
      sum += Number(item.movimentos_nao_visualizados);
    }
  }

  return sum;

}

export default class ListLawsuit extends React.Component { 
    _isMounted = false;

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount( ) { 
          this._isMounted = true;  

          document.addEventListener("cancelDialogConfirm", (e) => {
            if( this._isMounted ){
              this.setState({
                showConfirm: false
              });
            }
          });

          document.addEventListener("confirmDialogConfirm", (e) => {
            if( this._isMounted ){
              this.setState({
                showConfirm: false
              });

              if( this.state.movementsToDelete ){
                this.deleteMovements(e, true);
              }else if( this.state.lawsuitToDelete ){
                this.deleteLawsuit(this.state.lawsuitToDelete, true);
              }else{
                this.archiveLawsuitsFromTribunal(this.state.lawsuitsToArchive, true);
              }
            }
          });

          document.addEventListener("cancelAlert", (e) => {
            if( this._isMounted ){
              this.setState({
                showConfirm: false
              });
            }
          });   
      
          document.addEventListener("cancelLoading", (e) => {
            this.setState({
              loadingVisible: false
            });
          });
  
    }

    constructor( { match } ) {
        super();
        this.lawsuitService = new LawsuitService();
        this.authService = new AuthService();

        this.back = this.back.bind(this);

        this.getClientOrOponent = this.getClientOrOponent.bind(this);
        this.lawsuitItem = this.lawsuitItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.find = this.find.bind(this);
        this.clear = this.clear.bind(this);
        this.deleteMovements = this.deleteMovements.bind(this);
        this.addToHighlight = this.addToHighlight.bind(this);
        this.getLawsuits = this.getLawsuits.bind(this);
        this.archiveLawsuitsFromTribunal = this.archiveLawsuitsFromTribunal.bind(this);

        this.authService = new AuthService();

        let filtered = tribunalTypes.filter( (tribunalTypes)=>{ return tribunalTypes.key === match.params.type } );
        let tribunal_url_add = "";

        if( typeof filtered !== undefined && filtered.length > 0 ){
          tribunal_url_add = filtered[0].url_add;
        }

        this.state = {
            type: match.params.type,
            opt_pesquisa: opt_pesquisa[0],
            inp_pesquisa: "",
            tribunal_url_add: tribunal_url_add,
            updated: "",
            loadingVisible: false,
            loadingMessage: [],
            'isEditPopoverOpen': false,
            isMovementPopoverOpen: false,
            message: "",
            showAlert: false,
            showConfirm: false,
            lawsuitToDelete: false,
            movementsToDelete: false,
            lawsuitsToArchive: false,
            lawsuitsRaw: [],
            pagination: {prevPage: false, nextPage: true},
            highlightPagination: {prevPage: false, nextPage: true},
            archivedPagination: {prevPage: false, nextPage: true},
        }

    }

  componentWillReceiveProps(nextProps) {
    if ( nextProps.match.params.type !== this.props.match.params.type ) {

      this.lawsuitService = new LawsuitService();

      this.tribunals = JSON.parse(localStorage.getItem('tribunals'));
      this.tribunal_id = 0;
      this.tribunal = "";

      if (this.tribunals === null || this.tribunals === "" ){
          this.tribunalService = new TribunalService();
          this.tribunalService.list().then(res =>{

            if( this._isMounted ){

              //this.tribunals = JSON.parse(localStorage.getItem('tribunals'));
              this.tribunals = res;

              this.tribunal_id = this.tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === this.state.type } )[0].id;
              this.tribunal = this.tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === this.state.type } )[0].name;
            }

          })
          .catch(err =>{
             //console.log("Conexão Instável."+err);
              this.tribunal_id = 999;
          })
      }else{

            this.tribunal_id = this.tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === nextProps.match.params.type } )[0].id;
            this.tribunal = this.tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === nextProps.match.params.type } )[0].name;

      }
      document.body.style.cursor = "wait";

      this.getLawsuits( null, 1 );
      this.getHighlights();
      this.getArchived();

      let filtered = tribunalTypes.filter( (tribunalTypes)=>{ return tribunalTypes.key === nextProps.match.params.type } );
      let tribunal_url_add = "";

      if( typeof filtered !== undefined && filtered.length > 0 ){
        tribunal_url_add = filtered[0].url_add;
      }

      this.setState({
          tribunal_id: this.tribunal_id,
          tribunal: this.tribunal,
          tribunals: this.tribunals,
          type: nextProps.match.params.type,
          tribunal_url_add: tribunal_url_add,
          lawsuitsRaw: [],
          lawsuitList: [],
          qtd: 0,
          totalStepsActive: 0,
          lawsuitListHighlight: [],
          qtdHighlight: 0,
          totalStepsHighlight: 0,
          lawsuitListArchived: [],
          qtdArchived: [],
       });

    }
  }

  getLawsuits( message, page=1 ) {

    //console.log("message: "+message);

    if ( message !== null ){
      localStorage.setItem('alert_message', message);
      localStorage.setItem('alert_type', 'alert-success');
      let time = new Date().getTime();
      localStorage.setItem('alert_time', time );
      setTimeout(() => {
        this.setState({
          updated: time,
        });
      }, 100);
    }

    this.lawsuitService = new LawsuitService();

   //console.log('tribunal_id', this.tribunal_id);

    if( this.tribunal_id !== 0 ){

      this.setState({
        loadingVisible: true,
        loadingMessage: ["Os processos estão sendo carregados.", "Por gentileza, aguarde...", " Tempo estimado de 10 a 40 segundos"]
      });

          this.lawsuitService.list( this.tribunal_id, null, null, page ).then(res =>{

            //console.log(res); 

            if( this._isMounted ){

                  let lawsuitsRaw = []; let response = ""; let highlight = []; let lawsuits = [];

                  for(let i=0; i<res.data.Lawsuits.length; i++){
                    lawsuitsRaw.push( res.data.Lawsuits[i] );
                  }

                        lawsuits = lawsuitsRaw;
            
                        if ( lawsuits.length === 0 ){
                          localStorage.setItem('alert_message', 'Nenhum Processo cadastrado');
                          localStorage.setItem('alert_type', 'alert-success');
                          let time = new Date().getTime();
                          localStorage.setItem('alert_time', time );
                          setTimeout(() => {
                            this.setState({
                              updated: time,
                            });
                          }, 100);
                        }
            
                        //response = this.getHighlights ( lawsuits );
            
                        //highlight = response.highlight;
            
                        //response = this.getArchived ( response.lawsuit );
            
                        document.body.style.cursor = "default";
                        this.setState({
                          loadingVisible: false,
                          loadingMessage: []
                        });
            
                        this.setState({
            
                          lawsuitList: lawsuits,
                          qtd: res.pagination.count,
                          totalStepsActive: totalSteps ( lawsuits ),
                          //lawsuitListHighlight: highlight,
                          //qtdHighlight: highlight.length,
                          //totalStepsHighlight: totalSteps ( highlight ),
                          //lawsuitListArchived: response.archived,
                          //qtdArchived: response.archived.length,
                          pagination: res.pagination,
            
                        });

            }

          })
          .catch(err =>{
                  if( this._isMounted ){

                       //console.log(err);
                        document.body.style.cursor = "default";
                        this.setState({
                          loadingVisible: false,
                          loadingMessage: []
                        });
                        this.setState({

                          lawsuitList: [],
                          qtd: 0,
                          totalStepsActive: 0,

                        });
                        localStorage.setItem('alert_message', "Tribunal não cadastrado.");
                        localStorage.setItem('alert_type', 'alert-danger');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                        setTimeout(() => {
                          this.setState({
                            updated: time,
                          });
                        }, 100);

                  }
          });

    }else{
      document.body.style.cursor = "default";
      localStorage.setItem('alert_message', "Tribunal não cadastrado.");
      localStorage.setItem('alert_type', 'alert-danger');
      let time = new Date().getTime();
      localStorage.setItem('alert_time', time );
      setTimeout(() => {
        this.setState({
          updated: time,
        });
      }, 100);

      this.setState({

        lawsuitList: [],
        qtd: 0,
        totalStepsActive: 0,
        lawsuitListHighlight: [],
        qtdHighlight: 0,
        totalStepsHighlight: 0,
        lawsuitListArchived: [],
        qtdArchived: [],

      });
    }

  }

  getHighlights ( page=1 ) {

    if( this.tribunal_id !== 0 ){

      this.lawsuitService.list( this.tribunal_id, 1, null, page ).then(res =>{

        //console.log(res); 

        if( this._isMounted ){

              let highlight = [];

                    highlight = res.data.Lawsuits;
        
                    if ( res.data.Lawsuits.length === 0 ){
                    }
        
                    this.setState({
                      totalStepsHighlight: totalSteps ( highlight ),
                      lawsuitListHighlight: highlight,
                      qtdHighlight: res.pagination.count,
                      highlightPagination: res.pagination,        
                    });

        }

      })
      .catch(err =>{
              if( this._isMounted ){
                   //console.log(err);
              }
      });

    }

  }

  getArchived ( page=1 ) {

    this.lawsuitService.list( this.tribunal_id, null, 1, page ).then(res =>{

      //console.log(res); 

      if( this._isMounted ){

            let archived = [];

                  archived = res.data.Lawsuits;
      
                  if ( res.data.Lawsuits.length === 0 ){
                  }
      
                  this.setState({
                    lawsuitListArchived: archived,
                    qtdArchived: res.pagination.count,
                    archivedPagination: res.pagination,        
                  });

      }

    })
    .catch(err =>{
            if( this._isMounted ){
                 //console.log(err);
            }
    });

  }

  componentWillMount( ) {
    this.lawsuitService = new LawsuitService();

    this.tribunals = JSON.parse(localStorage.getItem('tribunals'));
    this.tribunal_id = 0;
    this.tribunal = "";

    if (this.tribunals === null || this.tribunals === "" ){
        this.tribunalService = new TribunalService();
        this.tribunalService.list().then(res =>{
          if( this._isMounted ){
              //this.tribunals = JSON.parse(localStorage.getItem('tribunals'));
              this.tribunals = res;

              this.tribunal_id = this.tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === this.state.type } )[0].id;
              this.tribunal = this.tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === this.state.type } )[0].name;
             //console.log(this.tribunal_id);

              document.body.style.cursor = "wait";

              this.getLawsuits( null, 1 );
              this.getHighlights();
              this.getArchived();
          
              this.setState({
                  tribunal_id: this.tribunal_id,
                  tribunal: this.tribunal,
                  tribunals: this.tribunals
               });
          }

        })
        .catch(err =>{
           //console.log("Conexão Instável."+err);
        })
    }else{

            let filtered = this.tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === this.state.type } );

            if( typeof filtered !== undefined && filtered.length > 0 ){
              this.tribunal_id = filtered[0].id;
              this.tribunal = filtered[0].name;
            }

            document.body.style.cursor = "wait";

            this.getLawsuits( null, 1 );
            this.getHighlights();
            this.getArchived();
        
            this.setState({
                tribunal_id: this.tribunal_id,
                tribunal: this.tribunal,
                tribunals: this.tribunals
             });

    }

  }


  state = {
    value: ''
  }

  deleteLawsuit(token, r=false){

        //r = window.confirm("Realmente deseja deletar este processo com todos os arquivos, documentos, comentários e todas as informações? ESTA AÇÃO É IRREVERSÍVEL!");

        if ( r ){
          this.lawsuitService.delete( token )
              .then(res =>{

                  if( this._isMounted ){

                        if( res.success === true ){
                          var message = res.message;
                          document.body.style.cursor = "wait";

                          this.setState({
                            lawsuitsRaw: [],
                            lawsuitList: [],
                            qtd: 0,
                            totalStepsActive: 0,
                            lawsuitListHighlight: [],
                            qtdHighlight: 0,
                            totalStepsHighlight: 0,
                            lawsuitListArchived: [],
                            qtdArchived: [],
                          });
        
                          this.getLawsuits( message, 1 );
        
                        }else{
                          localStorage.setItem('alert_message', res.message);
                          localStorage.setItem('alert_type', 'alert-danger');
                          let time = new Date().getTime();
                          localStorage.setItem('alert_time', time );
                          setTimeout(() => {
                            this.setState({
                              updated: time,
                            });
                          }, 100);
                        }

                  }

              })
              .catch(err =>{
                if( this._isMounted ){
                  document.body.style.cursor = "default";
                 //console.log(err);
                }
              })
        }else{
          this.setState({
            showConfirm: true,
            lawsuitToDelete: token,
            message: "Realmente deseja deletar este processo com todos os arquivos, documentos, comentários e todas as informações? \nESTA AÇÃO É IRREVERSÍVEL!"
          });
        }

   }

   selectAllAtivos(){
     let checked = document.getElementById('selectAllAtivos').checked;
      let checkboxes = document.getElementsByClassName('migrar-ativos');
      for (var checkbox of checkboxes) {
          checkbox.checked = checked;
      }
   }

   selectAllDestacados(){
      let checked = document.getElementById('selectAllDestacados').checked;
      let checkboxes = document.getElementsByClassName('migrar-destacados');
      for (var checkbox of checkboxes) {
          checkbox.checked = checked;
      }
    }

    selectAllArquivados(){
      let checked = document.getElementById('selectAllArquivados').checked;
      let checkboxes = document.getElementsByClassName('migrar-arquivados');
      for (var checkbox of checkboxes) {
          checkbox.checked = checked;
      }
    }

   jefToTrf3(){


      let checkboxes = document.getElementsByClassName('migrar');
      let tamanho = checkboxes.length; let n_check = 0;

      for (var checkbox of checkboxes) {
          if( checkbox.checked ){
           //console.log("token da checkbox: ", checkbox.dataset.token);

            this.setState({
              loadingVisible: true,
              loadingMessage: [
                "A migração está sendo efetuada.", 
                "Por gentileza, aguarde.",
                ""
              ] 
            });
    
            this.lawsuitService.jefToTrf3( checkbox.dataset.token )
            .then(res =>{
                n_check = n_check+1;
    
                if( this._isMounted ){
    
                      if( res.success === true ){
    
                        this.getLawsuits( null, 1 );
                        this.getHighlights();
                        this.getArchived();
    
                      }else{
                        localStorage.setItem('alert_message', res.message);
                        localStorage.setItem('alert_type', 'alert-danger');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                        setTimeout(() => {
                          this.setState({
                            updated: time,
                          });
                        }, 100);
                      }

                      if(n_check >= tamanho){
                          setTimeout(() => {
                            localStorage.setItem('alert_message', res.message);
                            localStorage.setItem('alert_type', 'alert-success');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );
          
                            this.setState({
                              loadingVisible: false,
                              loadingMessage: [],
                              updated: time,
                            });
              
                          }, 200);
                      }
    
                }
    
            })
            .catch(err =>{
              if( this._isMounted ){
                document.body.style.cursor = "default";
               //console.log(err);
              }
            }); 
          }
      }  
   }

   getClientOrOponent ( record, side ) {

     let name = []; let response = "";

     if ( typeof record.lawsuits_personages !== "undefined" ) {

         record.lawsuits_personages.map((value, index, array )=>{

           if ( value.personage_side_id === side ) {
             name.push( value.personage.name );
           }

           return "";

         });

         if( name.length >= 2 ) {
           response = name[0]+" (e outros)";
         }else{
           response = name[0];
         }

     }

     return response;

   }

  onChange = (event) => {
    this.setState({
      value: event.target.value
    });
  }

  addToHighlight = (event) => {

        document.body.style.cursor = "wait";

        let token = ""; let highlight = "";
        token = event.target.dataset.token;
        highlight = event.target.dataset.highlight;

        this.lawsuitService.addToHighlight( token )
            .then(res =>{

              if( this._isMounted ){
                        if( res.success === true ){

                          if ( highlight === "1" ) {
                            this.getLawsuits( "Processo removido do destaque.", 1 );
                          }else{

                            this.getLawsuits( "Processo destacado com sucesso.", 1 );
                          }

                      }else{
                        localStorage.setItem('alert_message', res.message);
                        localStorage.setItem('alert_type', 'alert-danger');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                        setTimeout(() => {
                          this.setState({
                            updated: time,
                          });
                        }, 100);
                      }
              }

            })
            .catch(err =>{
                document.body.style.cursor = "default";
               //console.log(err);
            })
  }

  lawsuitItem = ( {record, index}, type ) => {

    let title = ""; let classes = ""; let checkbox_class = "";

      if ( record.favorito === 1 ){
        title = "Remover dos destaques";
        checkbox_class = 'migrar migrar-destacados';
      }else{
        title = "Destacar";
        if (record.arquivo === 1){
          classes = "hide";
          checkbox_class = 'migrar migrar-arquivados';
        }else{
          checkbox_class = 'migrar migrar-ativos'
        }
      }

      let button_delete_lawsuit = <i className="fa fa-trash" data-token={record.token} onClick={() => this.deleteLawsuit(record.token)}></i>;

      return (
        <tr key={record.token} className={type}>
              <td className={(this.tribunal_id === 25) ? "table_lawsuit_actions trf" : "hide"}>
                  <input type="checkbox" className={checkbox_class} data-token={record.token}  />
              </td>
              <td className="table_lawsuit_number">
                    <Link to={'/processo/detalhe_processo/'+record.token} >
                        {(record.input1 === "manual") ? record.name : record.number}
                    </Link>
                    <Link to={'/processo/detalhe_processo/'+record.token+'?movements=true'} >
                        { (record.arquivo === 1 ) ? null : <Badge color="bg-yellow" info={record.movimentos_nao_visualizados} title="Andamentos Não visualizados" /> }
                    </Link>
              </td>
              <td>
                {(record.input1 === "manual") ? "Processo INSS Manual" : record.status}
              </td>
              <td className="table_lawsuit_cliente " title={this.getClientOrOponent(record, 1)} >
                      { ( typeof this.getClientOrOponent(record, 1) !== "undefined" ) ? this.getClientOrOponent(record, 1).substr(0,30)+"..." : "" }
              </td>
              <td className="table_lawsuit_oponente " title={this.getClientOrOponent(record, 2)} >
                      { ( typeof this.getClientOrOponent(record, 2) !== "undefined" ) ? this.getClientOrOponent(record, 2).substr(0,30)+"..." : "" }
              </td>
              <td className="table_lawsuit_actions">
                      { (record.arquivo === 1 )? null : <i className={ classes+" fa fa-star"} data-token={record.token} data-highlight={record.favorito} title={title} onClick={(event) => this.addToHighlight(event)}></i> }
                      { (record.arquivo !== 1 && this.authService.can("cadastrar_processo_excluir", true, 'processos') )? <Divider type="vertical" /> : null }
                      { this.authService.can("cadastrar_processo_excluir", true, 'processos') ? button_delete_lawsuit : null }
              </td>
        </tr>


      );
  };

  paginationItem = (page, index) => {
    return(
      <li key={"pagination-item-"+page} className={ (page == this.state.pagination.page) ? "current" : ""}><a onClick={()=>{this.goPage('lawsuit', page)}}>{page}</a></li>
    );
  }

  paginationHighlightItem = (page, index) => {
    return(
      <li key={"pagination-item-"+page} className={ (page == this.state.highlightPagination.page) ? "current" : ""}><a onClick={()=>{this.goPage('highlight', page)}}>{page}</a></li>
    );
  }

  paginationArchivedItem = (page, index) => {
    return(
      <li key={"pagination-item-"+page} className={ (page == this.state.archivedPagination.page) ? "current" : ""}><a onClick={()=>{this.goPage('archived', page)}}>{page}</a></li>
    );
  }

  goFirstPage = (type) => {
    if(type == "lawsuit"){
      this.getLawsuits( null, 1 );
    }
    if(type == "highlight"){
      this.getHighlights( 1 );
    }
    if(type == "archived"){
      this.getArchived( 1 );
    }
  }

  //this.state.pagination.prevPage) ? "" : "disable"}><a onClick={()=>{this.goPrevPage

  goPrevPage = (type) => {
    if(type == "lawsuit"){
      if(this.state.pagination.prevPage){
        this.getLawsuits( null, this.state.pagination.page-1 );
      }
    }
    if(type == "highlight"){
      if(this.state.highlightPagination.prevPage){
        this.getHighlights( this.state.highlightPagination.page-1 );
      }
    }
    if(type == "archived"){
      if(this.state.archivedPagination.prevPage){
        this.getArchived( this.state.archivedPagination.page-1 );
      }
    }
  }

  goNextPage = (type) => {
    if(type == "lawsuit"){
      if(this.state.pagination.nextPage){
        this.getLawsuits( null, this.state.pagination.page+1 );
      }
    }
    if(type == "highlight"){
      if(this.state.highlightPagination.nextPage){
        this.getHighlights( this.state.highlightPagination.page+1 );
      }
    }
    if(type == "archived"){
      if(this.state.archivedPagination.nextPage){
        this.getArchived( this.state.archivedPagination.page+1 );
      }
    }
  }

  goPage = (type, page) => {
      if(type == "lawsuit"){
        this.getLawsuits( null, page );
      }
      if(type == "highlight"){
          this.getHighlights( page );
      }
      if(type == "archived"){
          this.getArchived( page );
      }
  }

  goLastPage = (type) => {
    if(type == "lawsuit"){
      this.getLawsuits( null, this.state.pagination.pageCount );
    }
    if(type == "highlight"){
      if(this.state.highlightPagination.nextPage){
        this.getHighlights( this.state.highlightPagination.pageCount );
      }
    }
    if(type == "archived"){
      if(this.state.archivedPagination.nextPage){
        this.getArchived( this.state.archivedPagination.pageCount );
      }
    }
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }
  }

  find ( event ){
      document.body.style.cursor = "wait";
      this.setState({
        loadingVisible: true,
        loadingMessage: ["Pesquisando.", "Por favor, aguarde...", ]
      });
      this.lawsuitService.find( this.state.inp_pesquisa, this.state.opt_pesquisa.value, "&tribunals[]="+this.tribunal_id ).then(res =>{

              if( this._isMounted ){

                    let response = ""; let highlight = [];

                    response = this.getHighlights ( res );
            
                    highlight = response.highlight;
            
                    response = this.getArchived ( response.lawsuit );
            
                    document.body.style.cursor = "default";
                    this.setState({
                      loadingVisible: false,
                      loadingMessage: []
                    });
            
                    this.setState({
            
                      lawsuitList: response.lawsuit,
                      qtd: response.lawsuit.length,
                      totalStepsActive: totalSteps ( response.lawsuit ),
                      lawsuitListHighlight: highlight,
                      qtdHighlight: highlight.length,
                      totalStepsHighlight: totalSteps ( highlight ),
                      lawsuitListArchived: response.archived,
                      qtdArchived: response.archived.length,
            
                    });

              }

      })
      .catch(err =>{

          if( this._isMounted ){

            document.body.style.cursor = "default";
            this.setState({
              loadingVisible: false,
              loadingMessage: []
            });
            this.setState({
  
              lawsuitList: [],
              qtd: 0,
              totalStepsActive: 0,
              lawsuitListHighlight: [],
              qtdHighlight: 0,
              totalStepsHighlight: 0,
              lawsuitListArchived: [],
              qtdArchived: [],
  
            });
            //alert("Não foi possível estabelecer conexão com o servidor. Contate o administrador");
            this.setState({
              showAlert: true,
              message: "Não foi possível estabelecer conexão com o servidor. Contate o administrador"
            });

          }

      });
  }

  deleteMovements ( event, result=false ) {
    //result = window.confirm( "Deseja realmente deletar todos os avisos de Andamentos (Não visualizados) deste Tribunal? \n*Serão apagados somente os avisos do seu login. \nESTA AÇÃO É IRREVERSÍVEL!");

    if ( result ) {
        /*código de deletar movement*/
        this.tribunalService = new TribunalService();
        this.tribunalService.deleteMovements( this.state.tribunal_id ).then( response=>{
          if( this._isMounted ){
            this.getLawsuits( null, 1 );
            this.getHighlights();
            this.getArchived();
          }
        });

    }else{
      this.setState({
        showConfirm: true,
        movementsToDelete: this.state.tribunal_id,
        message: "Deseja realmente deletar todos os avisos de Andamentos (Não visualizados) deste Tribunal? \n*Serão apagados somente os avisos do seu login. \nESTA AÇÃO É IRREVERSÍVEL!"
      });
    }
  }

  archiveLawsuitsFromTribunal (event, result=false) {
    if ( result ) {
      /*código de arquivar lawsuit*/
      this.tribunalService = new TribunalService();
      this.tribunalService.archiveAll( this.state.tribunal_id ).then( response=>{
        if( this._isMounted ){
          this.getLawsuits( null, 1 );
          this.getHighlights();
          this.getArchived();
        }
      });

    }else{
      this.setState({
        showConfirm: true,
        lawsuitsToArchive: this.state.tribunal_id,
        message: "Deseja realmente arquivar TODOS os processos deste tribunal?"
      });
    }    
  }

  clear ( event ){
    document.body.style.cursor = "wait";
    this.setState({
      loadingVisible: true,
      loadingMessage: [ "Limpando pesquisa...", ]
    });

    this.setState ({
        inp_pesquisa: "",
        opt_pesquisa: opt_pesquisa[0]
    });

    this.getLawsuits( null, 1 );
    this.getHighlights();
    this.getArchived();

  }

  back(){
    this.props.history.goBack();
  }

  render() {

      let url_base = localStorage.getItem('url_base');
      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

      //console.log(this.state.lawsuitList);
      let button_add_lawsuit =        <Link to={this.state.tribunal_url_add} className="btn btn-primary add-lawsuit">
                                        <label>Novo Processo</label>
                                      </Link>

      let pagination_numbers = []; let pagination_highlight_numbers = []; let pagination_archived_numbers = [];

      for(let i=1; i<= this.state.pagination.pageCount; i++){
        pagination_numbers.push(i);
      }

      for(let i=1; i<= this.state.highlightPagination.pageCount; i++){
        pagination_highlight_numbers.push(i);
      }

      for(let i=1; i<= this.state.archivedPagination.pageCount; i++){
        pagination_archived_numbers.push(i);
      }

      /*
          <form acceptCharset="utf-8"  className="form-pesquisa-tribunal" >
              <div className="row">
                    <div className=" text-right container-label-pesquisa">
                        <label htmlFor="inp_pesquisa" className="control-label"> Pesquisar</label>

                        <MyPopover icon="white" text={"Pesquisa N° de processo, Cliente e/ou Oponente somente dentro deste Tribunal."} />
                        
                    </div>
                    <div className="container-input-pesquisa">
                        <input type="text" className="form-control" name="inp_pesquisa" value={this.state.inp_pesquisa} onChange={this.handleChange} placeholder="Digite o termo" id="inp_pesquisa" />
                    </div>
                    <Select
                        id="opt_pesquisa"
                        className="opt_pesquisa"
                        classNamePrefix="mySelect"
                        options={opt_pesquisa}
                        value={this.state.opt_pesquisa}
                        name="opt_pesquisa"
                        onChange={this.selectChange}
                      />
                    <div className="container-search">
                        <span className="btn btn-primary" onClick={this.find}>Pesquisar</span>
                        <span className="btn btn-danger" data-type={this.state.type} onClick={this.clear} >Limpar</span>
                    </div>
                </div>
          </form>
      */

      return <div className="list-lawsuit sheet">
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">{(this.state.tribunal === "Justiça Federal de São Paulo") ? "1º Grau Justiça Federal de São Paulo" : this.state.tribunal}</h1>

                        <span className={(this.tribunal_id === 25) ? "trf-button trf" : "hide"} >
                            <label>Enviar processo para TRF3-PJE: </label>
                            <span className="btn btn-secondary" onClick={(event) => this.jefToTrf3()}>Migrar</span>
                            <MyPopover icon="white" text={"Processos que tramitavam no JEF e migraram para o TRF 3 Pje, terão que ser transferidos para passarem a ser atualizados pelo sistema. \n Clique na checkbox ao lado do processo para selecionar os processos a serem transferidos, depois clique no botão migrar. \n Obs: uma vez transferidos, não há como retornar."} />
                        </span>
                        <span className={ (loggedUser.admin === true) ? "archive-all-button " : ""} >
                            <span className="btn btn-black" onClick={this.archiveLawsuitsFromTribunal}>Arquivar Todos</span>
                        </span>
                      </section>
                      <div className="box-body">
                        <h2 className="box-title">Lista de Processos</h2>
                        <div className="actions-container">
                            <span className="btn btn-info" onClick={this.back} >Voltar</span>
                            <div className="form-group lista-processos-action-buttons">
                                { this.authService.can('cadastrar_processo', true, 'processos') ? button_add_lawsuit : null }
                                <span className={(this.tribunal === "INSS" || ( !this.authService.can('avisos_de_andamentos', true) ) )? "hide" : "btn btn-warning"} role="button" onClick={this.deleteMovements} ><label>Deletar Avisos de Andamentos</label></span>
                                <MyPopover icon="white" text={"Possibilita limpar todas as sinalizações de andamentos não visualizados (Laranjinhas) deste usuário e somente desteTribunal. Esta ação é IRREVERSÍVEL."} />
                            </div>
                        </div>

                        <div className="alert alert-info" role="alert">Processos: <strong>{this.state.qtd}</strong> / Processos destacados: <strong>{this.state.qtdHighlight}</strong> / Processos arquivados: <strong>{this.state.qtdArchived}</strong> </div>
                        <Tabs defaultActiveKey="1" size="large">
                              <TabPane tab={<span>Ativos <Badge color="bg-blue" info={this.state.qtd} title="N° de processos ativos" /> <Badge color="bg-yellow" info={this.state.totalStepsActive} title="Andamentos Não visualizados" /></span>} key="1">
                                <div className="table-responsive">
                                  <table className="table">
                                          <thead>
                                              <tr>
                                                    <th className={(this.tribunal_id === 25) ? "table_lawsuit_actions " : "hide"}>
                                                          <div>
                                                            <input type="checkbox" id="selectAllAtivos" onClick={()=>{this.selectAllAtivos()}} />
                                                          </div>
                                                    </th>
                                                    <th className="table_lawsuit_number">
                                                        <div>Processo</div>
                                                    </th>
                                                    <th className="table_lawsuit_status">
                                                        <div>Status</div>
                                                    </th>
                                                    <th className="table_lawsuit_cliente">
                                                        <div>Cliente</div>
                                                    </th>
                                                    <th className="table_lawsuit_oponente">
                                                        <div>Oponente</div>
                                                    </th>
                                                    <th className="table_lawsuit_actions">
                                                        <div>Ações</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.lawsuitList !== "undefined") ? this.state.lawsuitList.map( (record, index) => this.lawsuitItem( {record, index}, 'lawsuit-item' ) ) : null }
                                          </tbody>
                                  </table>
                                  <ul className="pagination" role="menubar" aria-label="Pagination">
                                    <li><a onClick={()=>{this.goFirstPage('lawsuit')}}><span>Primeira</span></a></li>
                                    <li className={ (this.state.pagination.prevPage) ? "" : "disable"}><a onClick={()=>{this.goPrevPage('lawsuit')}}><span>Anterior</span></a></li>
                                    {pagination_numbers.map( (num, index) =>  this.paginationItem( num, index )  )}
                                    <li className={ (this.state.pagination.nextPage) ? "" : "disable"}><a onClick={()=>{this.goNextPage('lawsuit')}}><span>Próxima</span></a></li>
                                    <li><a onClick={()=>{this.goLastPage('lawsuit')}}><span>Última</span></a></li>
                                  </ul> 
                                </div>
                              </TabPane>
                              <TabPane tab={<span><i className="fa fa-star"></i><span> Destacados <Badge color="bg-blue" info={this.state.qtdHighlight} title="N° de processos destacados" /> <Badge color="bg-yellow" info={this.state.totalStepsHighlight} title="Andamentos Não visualizados" /></span></span>} key="2">
                                <div className="table-responsive">
                                  <table className="highlight-table table" >
                                          <thead>
                                              <tr>
                                                    <th className={(this.tribunal_id === 25) ? "table_lawsuit_actions " : "hide"}>
                                                        <div>
                                                          <input type="checkbox" id="selectAllDestacados" onClick={()=>{this.selectAllDestacados()}} />
                                                        </div>
                                                    </th>
                                                    <th className="table_lawsuit_number">
                                                        <div>Processo</div>
                                                    </th>
                                                    <th className="table_lawsuit_status">
                                                        <div>Status</div>
                                                    </th>
                                                    <th className="table_lawsuit_cliente">
                                                        <div>Cliente</div>
                                                    </th>
                                                    <th className="table_lawsuit_oponente">
                                                        <div>Oponente</div>
                                                    </th>
                                                    <th className="table_lawsuit_actions">
                                                        <div>Ações</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.lawsuitListHighlight !== "undefined") ? this.state.lawsuitListHighlight.map( (record, index) => this.lawsuitItem( {record, index}, 'lawsuit-highlight-item' ) ) : null }
                                          </tbody>
                                  </table>
                                  <ul className="pagination" role="menubar" aria-label="Pagination">
                                    <li><a onClick={()=>{this.goFirstPage('highlight')}}><span>Primeira</span></a></li>
                                    <li className={ (this.state.highlightPagination.prevPage) ? "" : "disable"}><a onClick={()=>{this.goPrevPage('highlight')}}><span>Anterior</span></a></li>
                                    {pagination_highlight_numbers.map( (num, index) =>  this.paginationHighlightItem( num, index )  )}
                                    <li className={ (this.state.highlightPagination.nextPage) ? "" : "disable"}><a onClick={()=>{this.goNextPage('highlight')}}><span>Próxima</span></a></li>
                                    <li><a onClick={()=>{this.goLastPage('highlight')}}><span>Última</span></a></li>
                                  </ul> 
                                </div>
                              </TabPane>
                              <TabPane tab={<span>Arquivados  <Badge color="bg-black" info={this.state.qtdArchived} title="N° de processos arquivados" /></span>}  key="3">
                                  <div className="table-responsive">
                                    <table className="table">
                                            <thead>
                                                <tr>

                                                      <th className={(this.tribunal_id === 25) ? "table_lawsuit_actions " : "hide"}>
                                                          <div>
                                                            <input type="checkbox" id="selectAllArquivados" onClick={()=>{this.selectAllArquivados()}} />
                                                          </div>
                                                      </th>
                                                      <th className="table_lawsuit_number">
                                                          <div>Processo</div>
                                                      </th>
                                                      <th className="table_lawsuit_status">
                                                          <div>Status</div>
                                                      </th>
                                                      <th className="table_lawsuit_cliente">
                                                          <div>Cliente</div>
                                                      </th>
                                                      <th className="table_lawsuit_oponente">
                                                          <div>Oponente</div>
                                                      </th>
                                                      <th className="table_lawsuit_actions">
                                                          <div>Ações</div>
                                                      </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  { ( typeof this.state.lawsuitListArchived !== "undefined") ? this.state.lawsuitListArchived.map( (record, index) => this.lawsuitItem({ record, index }, 'lawsuit-archived-item') ) : null }
                                            </tbody>
                                    </table>
                                    <ul className="pagination" role="menubar" aria-label="Pagination">
                                      <li><a onClick={()=>{this.goFirstPage('archived')}}><span>Primeira</span></a></li>
                                      <li className={ (this.state.archivedPagination.prevPage) ? "" : "disable"}><a onClick={()=>{this.goPrevPage('archived')}}><span>Anterior</span></a></li>
                                      {pagination_archived_numbers.map( (num, index) =>  this.paginationArchivedItem( num, index )  )}
                                      <li className={ (this.state.archivedPagination.nextPage) ? "" : "disable"}><a onClick={()=>{this.goNextPage('archived')}}><span>Próxima</span></a></li>
                                      <li><a onClick={()=>{this.goLastPage('archived')}}><span>Última</span></a></li>
                                    </ul> 
                                  </div>
                              </TabPane>
                        </Tabs>
                      </div>

                  </div>
                </div>
             </div>
  }

}