import React from 'react';
import MyPopover from '../../containers/mypopover';

import Alert from '../../containers/alert';

import UsersService from '../../../services/UsersService';
import LawsuitService from '../../../services/LawsuitService';
import AdditionalDataLawsuitService from '../../../services/AdditionalDataLawsuitService';
import AuthService from '../../../services/AuthService';

import Select from 'react-select';
import Popover, { ArrowContainer } from 'react-tiny-popover';

export default class AdditionalDataLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor( props ) {
      super( props );
      this.roll = this.roll.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.getOption = this.getOption.bind(this);
      this.myRef = React.createRef();
      this.save = this.save.bind(this);
      this.addArea= this.addArea.bind(this);
      this.deleteArea= this.deleteArea.bind(this);
      this.addTipo= this.addTipo.bind(this);
      this.deleteTipo= this.deleteTipo.bind(this);
      this.addFase= this.addFase.bind(this);
      this.deleteFase= this.deleteFase.bind(this);
      this.addComarca= this.addComarca.bind(this);
      this.deleteComarca= this.deleteComarca.bind(this);
      this.addPerda= this.addPerda.bind(this);
      this.editPerda= this.editPerda.bind(this);
      this.deletePerda= this.deletePerda.bind(this);
      this.addFilial= this.addFilial.bind(this);
      this.deleteFilial= this.deleteFilial.bind(this);
      this.modifiedLawsuit = this.props.modifiedLawsuit.bind(this);

      this.lawsuitService = new LawsuitService();
      this.authService = new AuthService();

      let pasta = "Não Definida"; let comarca = {value: -9999, label: "Não Definida"};
      let perda = {value: -9999, label: "Não Definido"}; let status = "";
      let area = {value: -9999, label: "Não Definida"}; let filial = {value: -9999, label: "Não Definida"};
      let tipo = {value: -9999, label: "Não Definido"}; let fase = {value: -9999, label: "Não Definida"};

      if ( !(typeof props.lawsuit.pasta === "undefined") ) {
          if( props.lawsuit.pasta !== null ) {
            pasta = props.lawsuit.pasta;
          }
      }

      if ( !(typeof props.lawsuit.comarca === "undefined") ) {
          if( props.lawsuit.comarca !== null ) {
            if ( props.lawsuit.comarca !== '-') {
                comarca = {value: "", label: props.lawsuit.comarca} ;
            }
          }
      }

      if ( !(typeof props.lawsuit.perda === "undefined") ) {
          if( props.lawsuit.perda !== null ) {
            perda = {value: "", label: props.lawsuit.perda} ;
          }
      }

      if ( !(typeof props.lawsuit.area === "undefined") ) {
          if( props.lawsuit.area !== null ) {
            area = {value: "", label: props.lawsuit.area} ;
          }
      }

      if ( !(typeof props.lawsuit.filial === "undefined") ) {
          if( props.lawsuit.filial !== null ) {
            filial = {value: "", label: props.lawsuit.filial} ;
          }
      }

      if ( !(typeof props.lawsuit.status === "undefined") ) {
          if( props.lawsuit.status !== null ) {
            status = props.lawsuit.status ;
          }
      }

      if ( !(typeof props.lawsuit.tipo === "undefined") ) {
          if( props.lawsuit.tipo !== null ) {
            tipo = {value: "", label: props.lawsuit.tipo} ;
          }
      }

      if ( !(typeof props.lawsuit.faseProcessual === "undefined") ) {
          if( props.lawsuit.faseProcessual !== null ) {
            fase = {value: "", label: props.lawsuit.faseProcessual} ;
          }
      }

    // //console.log(props.lawsuit.user_registration_token);

      this.state = {
        lawsuit: props.lawsuit,
        token: props.lawsuit.token,
        cadastrado_por: "Não Definido",
        areaList: [],
        tipoList: [],
        faseList: [],
        comarcaList: [],
        perdaList: [],
        filialList: [],
        responsavel: ( !(typeof props.lawsuit.user_token === "undefined") ) ? {value: props.lawsuit.user_token, label: ""} : {value: -9999, label: "Não Definido"},
        pasta: pasta,
        tipo: tipo ,
        fase_processual: fase ,
        status: status,
        user_registration_token: ( !(typeof props.lawsuit.user_registration_token === "undefined") ) ? props.lawsuit.user_registration_token : "Não Definido",
        user: "Não Definido",
        area: area,
        comarca: comarca,
        filial: filial,
        perda: perda,
        isPopoverOpen: false,
        isSponsorPopoverOpen: false,
        showAlert: false,
        message: ""
      }
  }

  roll = (event) => {

    if ( !event.target.classList.contains('collapsed') ) {
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop+51)
      }, 200);
    }

  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props ) {

      let pasta = "Não Definida"; let comarca = {value: -9999, label: "Não Definida"};
      let perda = {value: -9999, label: "Não Definido"}; let status = "";
      let area = {value: -9999, label: "Não Definida"}; let filial = {value: -9999, label: "Não Definida"};
      let tipo = {value: -9999, label: "Não Definido"}; let fase = {value: -9999, label: "Não Definida"};

      if ( !(typeof nextProps.lawsuit.pasta === "undefined") ) {
          if( nextProps.lawsuit.pasta !== null ) {
            pasta = nextProps.lawsuit.pasta;
          }
      }

      if ( !(typeof nextProps.lawsuit.comarca === "undefined") ) {
          if( nextProps.lawsuit.comarca !== null ) {
            comarca =  {value: "", label: nextProps.lawsuit.comarca};
          }
      }

      if ( !(typeof nextProps.lawsuit.perda === "undefined") ) {
          if( nextProps.lawsuit.perda !== null ) {
            perda =  {value: "", label: nextProps.lawsuit.perda};
          }
      }

      if ( !(typeof nextProps.lawsuit.area === "undefined") ) {
          if( nextProps.lawsuit.area !== null ) {
            area = {value: "", label: nextProps.lawsuit.area} ;
          }
      }

      if ( !(typeof nextProps.lawsuit.filial === "undefined") ) {
          if( nextProps.lawsuit.filial !== null ) {
            filial = {value: "", label: nextProps.lawsuit.filial} ;
          }
      }

      if ( !(typeof nextProps.lawsuit.status === "undefined") ) {
          if( nextProps.lawsuit.status !== null ) {
            status = nextProps.lawsuit.status ;
          }
      }

      if ( !(typeof nextProps.lawsuit.tipo === "undefined") ) {
          if( nextProps.lawsuit.tipo !== null ) {
            tipo = {value: "", label: nextProps.lawsuit.tipo} ;
          }
      }

      if ( !(typeof nextProps.lawsuit.faseProcessual === "undefined") ) {
          if( nextProps.lawsuit.faseProcessual !== null ) {
            fase = {value: "", label: nextProps.lawsuit.faseProcessual} ;
          }
      }

      //console.log(nextProps.lawsuit.user_registration_token);

      this.setState({
        lawsuit: nextProps.lawsuit,
        token: nextProps.lawsuit.token,
        responsavel: ( !(typeof nextProps.lawsuit.user_token === "undefined") ) ? {value: nextProps.lawsuit.user_token, label: ""} : {value: -9999, label: "Não Definido"},
        pasta: pasta,
        tipo: tipo,
        fase_processual: fase,
        status: status,
        user_registration_token: ( !(typeof nextProps.lawsuit.user_registration_token === "undefined") ) ? nextProps.lawsuit.user_registration_token : "Não Definido",
        area: area,
        comarca: comarca,
        filial: filial,
        perda: perda
      });

      this.populateSelect( nextProps.lawsuit.user_registration_token );

    }
  }

  addArea (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    let area = {
      value: document.getElementById("areaName").value
    }
    this.additionalDataLawsuitService.addArea( area ).then(res =>{

      this.additionalDataLawsuitService.areaList( ).then(res =>{

            document.getElementById("areaName").value = "";

            this.setState({
              areaList: res,
            });

      });

    });
  }

  deleteArea (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();

    let id = document.getElementById("areaSettings").value;

    //console.log( id );

    this.additionalDataLawsuitService.deleteArea( id ).then(res =>{

      if( res.success === true ){

        this.additionalDataLawsuitService.areaList( ).then(res =>{

              this.setState({
                areaList: res,
              });

        });

      }else{
        document.body.style.cursor = "default";
        this.setState({
          showAlert: true,
          message: res.message
        });
      }      

    });
  }

  addTipo (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";
    let tipo = {
      value: document.getElementById("tipoName").value
    }
    this.additionalDataLawsuitService.addTipo( tipo ).then(res =>{

      this.additionalDataLawsuitService.tipoList( ).then(res =>{

            document.getElementById("tipoName").value = "";
            document.body.style.cursor = "default";
            this.setState({
              tipoList: res,
            });

      });

    });
  }

  deleteTipo (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";

    let id = document.getElementById("tipoSettings").value;

    //console.log( id );

    this.additionalDataLawsuitService.deleteTipo( id ).then(res =>{

      if( res.success === true ){

        this.additionalDataLawsuitService.tipoList( ).then(res =>{
              document.body.style.cursor = "default";

              this.setState({
                tipoList: res,
              });

        });

      }else{
        document.body.style.cursor = "default";
        this.setState({
          showAlert: true,
          message: res.message
        });
      }

    });
  }

  addFase (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";
    let fase = {
      value: document.getElementById("faseName").value
    }
    this.additionalDataLawsuitService.addFase( fase ).then(res =>{

      this.additionalDataLawsuitService.faseList( ).then(res =>{

            document.getElementById("faseName").value = "";
            document.body.style.cursor = "default";
            this.setState({
              faseList: res,
            });

      });

    });
  }

  deleteFase (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";

    let id = document.getElementById("faseSettings").value;

    //console.log( id );

    this.additionalDataLawsuitService.deleteFase( id ).then(res =>{

      if( res.success === true ){

        this.additionalDataLawsuitService.faseList( ).then(res =>{
              document.body.style.cursor = "default";

              this.setState({
                faseList: res,
              });

        });

      }else{
        document.body.style.cursor = "default";
        this.setState({
          showAlert: true,
          message: res.message
        });
      }

    });
  }

  addComarca (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";
    let comarca = {
      value: document.getElementById("comarcaName").value
    }
    this.additionalDataLawsuitService.addComarca( comarca ).then(res =>{

      this.additionalDataLawsuitService.comarcaList( ).then(res =>{

            document.getElementById("comarcaName").value = "";
            document.body.style.cursor = "default";
            this.setState({
              comarcaList: res,
            });

      });

    });
  }

  deleteComarca (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";

    let id = document.getElementById("comarcaSettings").value;

    //console.log( id );

    this.additionalDataLawsuitService.deleteComarca( id ).then(res =>{

      if( res.success === true ){

        this.additionalDataLawsuitService.comarcaList( ).then(res =>{
              document.body.style.cursor = "default";

              this.setState({
                comarcaList: res,
              });

        });

      }else{
        
        document.body.style.cursor = "default";
        this.setState({
          showAlert: true,
          message: res.message
        });
      }

    });
  }

  addPerda (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";
    let perda = {
      value: document.getElementById("perdaName").value
    }
    this.additionalDataLawsuitService.addPerda( perda ).then(res =>{

      this.additionalDataLawsuitService.perdaList( ).then(res =>{

            document.getElementById("perdaName").value = "";
            document.body.style.cursor = "default";
            this.setState({
              perdaList: res,
            });

      });

    });
  }

  editPerda (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";
    let perda = {
      value: document.getElementById("perdaName").value,
      id: document.getElementById("perdaSettings").value
    }

    //console.log( id );

    this.additionalDataLawsuitService.editPerda( perda ).then(res =>{

      if( res.success === true ){

        this.additionalDataLawsuitService.perdaList( ).then(res =>{
              document.body.style.cursor = "default";

              this.setState({
                perdaList: res,
              });

        });

      }else{
        document.body.style.cursor = "default";
        this.setState({
          showAlert: true,
          message: res.message
        });
      }

    });
  }

  deletePerda (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";

    let id = document.getElementById("perdaSettings").value;

    //console.log( id );

    this.additionalDataLawsuitService.deletePerda( id ).then(res =>{

      if( res.success === true ){

        this.additionalDataLawsuitService.perdaList( ).then(res =>{
              document.body.style.cursor = "default";

              this.setState({
                perdaList: res,
              });

        });

      }else{
        document.body.style.cursor = "default";
        this.setState({
          showAlert: true,
          message: res.message
        });
      }

    });
  }

  addFilial (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";
    let filial = {
      name: document.getElementById("filialName").value,
      email: document.getElementById("filialName").value+'@gmail.com',
    }
    this.additionalDataLawsuitService.addFilial( filial ).then(res =>{

      this.additionalDataLawsuitService.filialList( ).then(res =>{

            document.getElementById("filialName").value = "";
            document.body.style.cursor = "default";
            this.setState({
              filialList: res,
            });

      });

    });
  }

  deleteFilial (e) {
    this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
    document.body.style.cursor = "wait";

    let id = document.getElementById("filialSettings").value;

    //console.log( id );

    this.additionalDataLawsuitService.deleteFilial( id ).then(res =>{

      if( res.success === true ){

        this.additionalDataLawsuitService.filialList( ).then(res =>{
              document.body.style.cursor = "default";

                this.setState({
                  filialList: res,
                });

        });

      }else{
        this.setState({
          showAlert: true,
          message: res.message
        });
      }

    });
  }

  getOption ( attr ) {
    let opt = "";
    if ( typeof attr !== "undefined" ){
      if ( attr instanceof Array && attr.length > 0 ){
        opt = attr[0].label;
      }else{
        opt = attr.label;
      }
    }

    return opt;
  }

  save (e) {
    e.preventDefault();
    document.getElementById("bt-update-edit-dados-processo").disabled = true;
    document.body.style.cursor = "wait";

    let opt_area = ""; let opt_tipo = ""; let opt_fase = ""; let opt_comarca = ""; let opt_perda = ""; let opt_filial = "";
    let sponsor = "";

    if ( typeof this.state.tipo !== "undefined" ){
      if ( this.state.responsavel instanceof Array && this.state.responsavel.length > 0 ){
        sponsor = this.state.responsavel[0].value;
      }else{
        sponsor = this.state.responsavel.value;
      }
    }

    opt_area = this.getOption( this.state.area );
    opt_tipo = this.getOption( this.state.tipo );
    opt_fase = this.getOption( this.state.fase_processual );
    opt_comarca = this.getOption( this.state.comarca );
    opt_perda = this.getOption( this.state.perda );
    opt_filial = this.getOption( this.state.filial );

    let data = {
      'user_id': sponsor,
      'pasta': this.state.pasta,
      'area': opt_area,
      "tipo": opt_tipo,
      "faseProcessual": opt_fase,
      "comarca": opt_comarca,
      "perda": opt_perda,
      "filial": opt_filial,
    }

    this.lawsuitService.edit( data, this.state.token ).then(res =>{

      document.getElementById("bt-update-edit-dados-complementares-processo").disabled = false;
      document.body.style.cursor = "default";

      if ( res.success === true ) {
        setTimeout(() => {
          this.modifiedLawsuit();
        }, 100);
        document.getElementById("bt-edit-dados-complementares-processo").classList.remove("hide");
        document.getElementById("view-dados-complementares").classList.remove("hide");
        document.getElementById("panel-edit-dados-complementares-processo").classList.remove("in");

        document.getElementById("erro-dados-processo").innerHTML = "";
      }else{
        document.getElementById("erro-dados-complementares-processo").innerHTML = res.message;

       //console.log(res.message);
      }

    });
  }

  openEdit = () => {
    document.getElementById("bt-edit-dados-complementares-processo").className += ' hide';
    document.getElementById("view-dados-complementares").className += ' hide';
  }

  closeEdit = () => {
    document.getElementById("bt-edit-dados-complementares-processo").classList.remove("hide");
    document.getElementById("view-dados-complementares").classList.remove("hide");
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   sponsorChange = (selectedOption) => {
     this.setState({ "responsavel": selectedOption });
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
         this.setState( pops );
       }

     }
   }

   populateSelect( user_registration_token ){
     document.body.style.cursor = "wait";
     let options = []; let responsavel = this.state.responsavel; let areaList = []; let area = this.state.area;
     let tipo = this.state.tipo; let comarca = this.state.comarca;
     let tipoList = []; let faseList = []; let fase = this.state.fase_processual; let comarcaList = [];
     let perdaList= []; let perda = this.state.perda; let filial = this.state.filial; let filialList= [];
     let user = "Não Definido";

     this.usersService = new UsersService();
     this.additionalDataLawsuitService = new AdditionalDataLawsuitService();

     this.usersService.list( ).then(res =>{
       options = res;

       //console.log(this.state.user_registration_token);

       options.map((value, index, array )=>{

         if ( value.value === this.state.responsavel.value ) {
           responsavel = value;
         }

         if ( value.value === user_registration_token ) {
           user = value.label;
         }

         return "";

       });

       this.additionalDataLawsuitService.areaList( ).then(res =>{
             areaList = res;
             //console.log(this.state.area)
             areaList.unshift({value: -9999, label: "Não Definida"});
             areaList.map((value, index, array )=>{

               if ( value.label === this.state.area.label ) {
                 area = value;
               }

               return "";

             });

             this.setState({
               sponsorsList: options,
               responsavel: responsavel,
               user: user,
               areaList: areaList,
               area: area,
             });
       });

       this.additionalDataLawsuitService.tipoList( ).then(res =>{
             tipoList = res;
             //console.log(this.state.tipo)
             tipoList.unshift({value: -9999, label: "Não Definido"});
             tipoList.map((value, index, array )=>{

               if ( value.label === this.state.tipo.label ) {
                 tipo = value;
               }

               return "";

             });

             this.setState({
               tipoList: tipoList,
               tipo: tipo,
             });

       });


       this.additionalDataLawsuitService.faseList( ).then(res =>{
             faseList = res;
             //console.log(faseList)

             faseList.unshift({value: -9999, label: "Não Definida"});
             faseList.map((value, index, array )=>{

               if ( value.label === this.state.fase_processual.label ) {
                 fase = value;
               }

               return "";

             });

             this.setState({
               faseList: faseList,
               fase_processual: fase
             });

       });

       this.additionalDataLawsuitService.comarcaList( ).then(res =>{
             comarcaList = res;

             comarcaList.unshift({value: -9999, label: "Não Definida"});
             comarcaList.map((value, index, array )=>{

               if ( value.label === this.state.comarca.label ) {
                 comarca = value;
               }

               return "";

             });

             this.setState({
               comarcaList: comarcaList,
               comarca: comarca
             });

       });

       this.additionalDataLawsuitService.perdaList( ).then(res =>{
             perdaList = res;

             perdaList.unshift({value: -9999, label: "Não Definido"});
             perdaList.map((value, index, array )=>{

               if ( value.label === this.state.perda.label ) {
                 perda = value;
               }

               return "";

             });

             this.setState({
               perdaList: perdaList,
               perda: perda
             });

       })

       this.additionalDataLawsuitService.filialList( ).then(res =>{
             filialList = res;

             filialList.unshift({value: -9999, label: "Não Definida"});
             filialList.map((value, index, array )=>{

               if ( value.label === this.state.filial.label ) {
                 filial = value;
               }

               return "";

             });
             document.body.style.cursor = "default";

             this.setState({
               filialList: filialList,
               filial: filial
             });
       })

     });
   }

  componentDidMount( ) { this._isMounted = true;
    this.populateSelect( this.state.user_registration_token );

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });

  }

  render() {

        if( !this.authService.can('dados_complementares_processo', true, 'processos') ){
          return null;
        }

        let url_base = localStorage.getItem('url_base');

        var arrTen = []; var arrTipo = []; var arrFase = []; var arrComarca = []; var arrPerda = []; var arrFilial = [];
        
        for (var k = 0; k < this.state.areaList.length; k++) {
            arrTen.push(<option key={k} value={this.state.areaList[k].value}> {this.state.areaList[k].label} </option>);
        }

        for (k = 0; k < this.state.tipoList.length; k++) {
            arrTipo.push(<option key={k} value={this.state.tipoList[k].value}> {this.state.tipoList[k].label} </option>);
        }

        for (k = 0; k < this.state.faseList.length; k++) {
            arrFase.push(<option key={k} value={this.state.faseList[k].value}> {this.state.faseList[k].label} </option>);
        }

        for (k = 0; k < this.state.comarcaList.length; k++) {
            arrComarca.push(<option key={k} value={this.state.comarcaList[k].value}> {this.state.comarcaList[k].label} </option>);
        }

        for (k = 0; k < this.state.perdaList.length; k++) {
            arrPerda.push(<option key={k} value={this.state.perdaList[k].value}> {this.state.perdaList[k].label} </option>);
        }

        for (k = 0; k < this.state.filialList.length; k++) {
            arrFilial.push(<option key={k} value={this.state.filialList[k].value}> {this.state.filialList[k].label} </option>);
        }

      return <div className="panel panel-default container-complementar-processo">

                <Alert show={this.state.showAlert} text={this.state.message} />
                <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#additional-data-lawsuit-target">
                        <strong>Dados Complementares do Processo</strong>
                        <Popover
                            isOpen={this.state.isPopoverOpen}
                            position={['top', 'right', 'left', 'bottom']}
                            padding={10}
                            onClickOutside={() => this.setState({ isPopoverOpen: false })}
                            content={({ position, targetRect, popoverRect }) => (
                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                    position={position}
                                    targetRect={targetRect}
                                    popoverRect={popoverRect}
                                    arrowColor={'#94bcd8'}
                                    arrowSize={10}
                                >
                                    <div className="popover-box"
                                        onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                    >
                                        Preencha todos os dados para que seja possivel extrair informações precisas do processo e gerar relatórios mais completos.
                                    </div>
                                </ArrowContainer>
                            )}
                        >
                            <img onMouseOut={() => this.setState({ isPopoverOpen: false })} onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                        </Popover>
                    </div>
                </div>
                <div className="panel-body collapse" id="additional-data-lawsuit-target">

                    <div className="panel panel-warning collapse" id="panel-edit-dados-complementares-processo">
                            <div className="panel-heading text-center">
                                <strong>Editar Dados Complementares do Processo</strong>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="responsavel" className="col-md-12 col-xs-12">Responsável</label>
                                  <Select
                                      className="sponsor col-md-10 col-xs-11"
                                      options={this.state.sponsorsList}
                                      value={this.state.responsavel}
                                      name="responsavel"
                                      onChange={this.sponsorChange}
                                    />
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="pasta" className="pasta-label col-md-12 col-xs-12">Nº da Pasta</label>
                                  <input name="pasta" id="pasta" className="col-md-9 input-lg" placeholder={(this.state.pasta !== "Não Definida")? "" : this.state.pasta} value={(this.state.pasta !== "Não Definida")? this.state.pasta : ""} onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="area" className="col-md-12 col-xs-12">
                                          Área
                                          <MyPopover icon="white" text="Ex.: Trabalhista, Cível, Previdenciária" />
                                  </label>
                                  <Select
                                      className="area  col-md-10 col-xs-11"
                                      options={this.state.areaList}
                                      value={this.state.area}
                                      name="area"
                                      onChange={this.selectChange}
                                    />
                                    <i className="material-icons settings" data-toggle="modal" data-target="#areaModal" title="Clique para adicionar ou excluir opções">settings</i>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="tipo" className="col-md-12 col-xs-12">
                                          Tipo
                                          <MyPopover icon="white" text="Ex.: Cobrança, Despejo, Inventário, Usucapião, Revisão" />
                                  </label>
                                  <Select
                                      className="tipo  col-md-10 col-xs-11"
                                      options={this.state.tipoList}
                                      value={this.state.tipo}
                                      name="tipo"
                                      onChange={this.selectChange}
                                    />
                                    <i className="material-icons settings" data-toggle="modal" data-target="#tipoModal"  title="Clique para adicionar ou excluir opções">settings</i>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="fase" className="col-md-12 col-xs-12 fase-label">
                                          Fase
                                          <MyPopover icon="white" text="Ex.: Em Análise, Em execução, Em contestação, Aguardando Cálculo, Aguardando Perícia" />
                                  </label>
                                  <Select
                                      className="fase  col-md-10 col-xs-11"
                                      options={this.state.faseList}
                                      value={this.state.fase_processual}
                                      name="fase_processual"
                                      onChange={this.selectChange}
                                    />
                                    <i className="material-icons settings" data-toggle="modal" data-target="#faseModal"  title="Clique para adicionar ou excluir opções">settings</i>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="comarca" className="col-md-12 col-xs-12">
                                          Comarca/Fórum
                                          <MyPopover icon="white" text="Ex.: Campinas, Arujá, João Mendes" />
                                  </label>
                                  <Select
                                      className="comarca  col-md-10 col-xs-11"
                                      options={this.state.comarcaList}
                                      value={this.state.comarca}
                                      name="comarca"
                                      onChange={this.selectChange}
                                    />
                                    <i className="material-icons settings" data-toggle="modal" data-target="#comarcaModal"  title="Clique para adicionar ou excluir opções">settings</i>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="filial" className="col-md-12 col-xs-12 fase-label">
                                          Filial
                                          <MyPopover icon="white" text="Ex.: Filial 1, Filial Campinas, Parceiro Paulo, Escritório Correspondente X" />
                                  </label>
                                  <Select
                                      className="filial  col-md-10 col-xs-11"
                                      options={this.state.filialList}
                                      value={this.state.filial}
                                      name="filial"
                                      onChange={this.selectChange}
                                    />
                                    <i className="material-icons settings" data-toggle="modal" data-target="#filialModal"  title="Clique para adicionar ou excluir opções">settings</i>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="perda" className="col-md-12 col-xs-12">
                                          Risco da Ação
                                          <MyPopover icon="white" text="Ex.: Baixa, Média, Alta, ou 10%, 30%, 60%, 100%" />
                                  </label>
                                  <Select
                                      className="perda  col-md-10 col-xs-11"
                                      options={this.state.perdaList}
                                      value={this.state.perda}
                                      name="perda"
                                      onChange={this.selectChange}
                                    />
                                    <i className="material-icons settings" data-toggle="modal" data-target="#perdaModal"  title="Clique para adicionar ou excluir opções">settings</i>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="status" className="col-md-12 col-xs-12">
                                          Status
                                          <MyPopover icon="white" text={"CADASTRADO: Processo com todos os dados migrados; \n VALIDANDO: Aguarde, os dados estão sendo pesquisados por robôs; \n ERRO: Verifique se o Nº ou Tribunal estão corretos, confirme  no site do tribunal"} />
                                  </label>
                                  <input
                                      disabled={true}
                                      className="status  col-md-10 input-lg"
                                      value={this.state.status}
                                      name="status"
                                      
                                    />
                            </div>
                            <div className="row">
                              <p id="erro-dados-coplementares-processo" className="col-md-12 error"></p>
                            </div>
                            <div className="button-group">
                              <button type="button" className="btn btn-primary" id="bt-update-edit-dados-complementares-processo" onClick={this.save} >Alterar</button>
                              <button type="button" className="btn btn-danger horizontal-space" onClick={this.closeEdit} id="bt-cancel-edit-dados-complemetares-processo" data-toggle="collapse"  data-target="#panel-edit-dados-complementares-processo">Cancelar</button>
                            </div>
                    </div>

                    <span className="pointer pull-right" id="bt-edit-dados-complementares-processo" onClick={this.openEdit} data-toggle="collapse"  data-target="#panel-edit-dados-complementares-processo">
                        <i className="fa fa-pencil-square-o"></i>
                        <span className="edit-dados-processo-label">Editar</span>
                    </span>

                    <div id="view-dados-complementares">

                          <div className="row">
                              <div className="col-md-2">
                                      <strong>Responsável</strong>
                                      <Popover
                                          isOpen={this.state.isSponsorPopoverOpen}
                                          position={['top', 'right', 'left', 'bottom']}
                                          padding={10}
                                          onClickOutside={() => this.setState({ isSponsorPopoverOpen: false })}
                                          content={({ position, targetRect, popoverRect }) => (
                                              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                  position={position}
                                                  targetRect={targetRect}
                                                  popoverRect={popoverRect}
                                                  arrowColor={'#94bcd8'}
                                                  arrowSize={10}
                                              >
                                                  <div className="popover-box"
                                                      onClick={() => this.setState({ isSponsorPopoverOpen: !this.state.isSponsorPopoverOpen })}
                                                  >
                                                      Preferencialmente Advogados.   Para processos em Segredo de Justiça, cadastre sempre o nome do advogado que conste no processo.
                                                  </div>
                                              </ArrowContainer>
                                          )}
                                      >
                                          <img onMouseOut={() => this.setState({ isSponsorPopoverOpen: false })} onMouseOver={() => this.setState({ isSponsorPopoverOpen: !this.state.isSponsorPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                      </Popover>
                              </div>
                              <div className="col-md-4"><span id="span-processo-responsavel" className={ (this.state.responsavel.value === -9999)? "not-defined": null }>{ this.state.responsavel.label }</span></div>
                              <div className="col-md-2"><strong>Cadastrado por</strong></div>
                              <div className="col-md-3">
                                  <span className={ (this.state.user_registration_token === "Não Definido")? "not-defined": null }>{ ( !(typeof this.state.user_registration_token === "undefined") ) ? this.state.user : "Não Definido"  }</span>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-2"><strong>Pasta</strong></div>
                              <div className="col-md-4"><span id="span-processo-pasta" className={ (this.state.pasta === "Não Definida")? "not-defined": null }>{ ( !(typeof this.state.pasta === "undefined") ) ? this.state.pasta : "Não Definida"  }</span></div>
                              <div className="col-md-2"><strong>Área</strong></div>
                              <div className="col-md-4"><span id="span-processo-area" className={ (this.state.area.value === -9999)? "not-defined": null } >{ this.state.area.label  }</span></div>
                          </div>
                          <div className="row">
                              <div className="col-md-2"><strong>Tipo</strong></div>
                              <div className="col-md-4"><span id="span-processo-tipo" className={ (this.state.tipo.value === -9999)? "not-defined": null }>{ this.state.tipo.label  }</span></div>
                              <div className="col-md-2"><strong>Comarca/Fórum</strong></div>
                              <div className="col-md-4"><span id="span-processo-comarca" className={ (this.state.comarca.value === -9999)? "not-defined": null }>{ this.state.comarca.label }</span></div>
                          </div>
                          <div className="row">
                              <div className="col-md-2"><strong>Fase Processual</strong></div>
                              <div className="col-md-4"><span id="span-processo-fase" className={ (this.state.fase_processual.value === -9999)? "not-defined": null }>{ this.state.fase_processual.label  }</span></div>
                              <div className="col-md-2"><strong>Filial</strong></div>
                              <div className="col-md-4"><span id="span-processo-filial" className={ (this.state.filial.value === -9999)? "not-defined": null }>{ this.state.filial.label  }</span></div>
                          </div>
                          <div className="row">
                              <div className="col-md-2">
                                    <strong>Status</strong>
                                    <MyPopover icon="white" text={"CADASTRADO: Processo com todos os dados migrados; \n VALIDANDO: Aguarde, os dados estão sendo pesquisados por robôs; \n ERRO: Verifique se o Nº ou Tribunal estão corretos, confirme  no site do tribunal"} />
                              </div>
                              <div className="col-md-4"><span id="span-processo-status" className={ (this.state.status === -9999)? "not-defined": null }>{ this.state.status  }</span></div>
                              <div className="col-md-2"><strong>Risco da Ação</strong></div>
                              <div className="col-md-4"><span id="span-processo-perda" className={ (this.state.perda.value === -9999)? "not-defined": null }>{ this.state.perda.label  }</span></div>
                          </div>
                    </div>
              </div>

                  <div className="modal fade" id="areaModal" tabIndex="-1" role="dialog" aria-labelledby="areaModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="areaModalLabel">Incluir ou Excluir Área</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label htmlFor="areaName" className="col-form-label col-md-12 col-xs-12">Nova Área: </label>
                              <input type="text" className="form-control" id="areaName" />
                              <span className="btn btn-primary" id="add-area" onClick={this.addArea} >Incluir</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="areaSettings" className="col-form-label col-md-12 col-xs-12">Opções: </label>
                                <select multiple id="areaSettings" className="area settings">
                                  {arrTen}
                                </select>
                                <i className={ this.authService.can('dados_complementares_processo_excluir', true, 'processos') ? "material-icons delete" : "material-icons delete hide" } id="delete-area" title="remover item selecionado" onClick={this.deleteArea} >delete</i>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <p className="info">As alterações são salvas automaticamente</p>
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="tipoModal" tabIndex="-1" role="dialog" aria-labelledby="tipoModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="tipoModalLabel">Incluir ou Excluir Tipo</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label htmlFor="tipoName" className="col-form-label col-md-12">Novo Tipo: </label>
                              <input type="text" className="form-control" id="tipoName" />
                              <span className="btn btn-primary" id="add-tipo" onClick={this.addTipo} >Incluir</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="tipoSettings" className="col-form-label col-md-12">Opções: </label>
                                <select multiple id="tipoSettings" className="tipo settings">
                                  {arrTipo}
                                </select>
                                <i className={ this.authService.can('dados_complementares_processo_excluir', true, 'processos') ? "material-icons delete" : "material-icons delete hide" } id="delete-tipo" title="remover item selecionado" onClick={this.deleteTipo} >delete</i>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <p className="info">As alterações são salvas automaticamente</p>
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="faseModal" tabIndex="-1" role="dialog" aria-labelledby="faseModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="faseModalLabel">Incluir ou Excluir Fase Processual</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label htmlFor="tipoName" className="col-form-label col-md-12">Nova Fase: </label>
                              <input type="text" className="form-control" id="faseName" />
                              <span className="btn btn-primary" id="add-fase" onClick={this.addFase} >Incluir</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="faseSettings" className="col-form-label col-md-12">Opções: </label>
                                <select multiple id="faseSettings" className="fase settings">
                                  {arrFase}
                                </select>
                                <i className={ this.authService.can('dados_complementares_processo_excluir', true, 'processos') ? "material-icons delete" : "material-icons delete hide" } id="delete-fase" title="remover item selecionado" onClick={this.deleteFase} >delete</i>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <p className="info">As alterações são salvas automaticamente</p>
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="comarcaModal" tabIndex="-1" role="dialog" aria-labelledby="comarcaModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="comarcaModalLabel">Incluir ou Excluir Comarca / Fórum</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label htmlFor="comarcaName" className="col-form-label col-md-12">Nova Comarca/Fórum: </label>
                              <input type="text" className="form-control" id="comarcaName" />
                              <span className="btn btn-primary" id="add-comarca" onClick={this.addComarca} >Incluir</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="comarcaSettings" className="col-form-label col-md-12">Opções: </label>
                                <select multiple id="comarcaSettings" className="comarca settings">
                                  {arrComarca}
                                </select>
                                <i className={ this.authService.can('dados_complementares_processo_excluir', true, 'processos') ? "material-icons delete" : "material-icons delete hide" } id="delete-comarca" title="remover item selecionado" onClick={this.deleteComarca} >delete</i>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <p className="info">As alterações são salvas automaticamente</p>
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="perdaModal" tabIndex="-1" role="dialog" aria-labelledby="perdaModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="perdaModalLabel">Incluir ou Excluir Espectativa de Perda</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label htmlFor="perdaName" className="col-form-label col-md-12">Novo valor: </label>
                              <input type="text" className="form-control" id="perdaName" />
                              <span className="btn btn-primary" id="add-perda" onClick={this.addPerda} >Incluir</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="perdaSettings" className="col-form-label col-md-12">Opções: </label>
                                <select multiple id="perdaSettings" className="perda settings">
                                  {arrPerda}
                                </select>
                                <i className={ this.authService.can('dados_complementares_processo_excluir', true, 'processos') ? "material-icons delete" : "material-icons delete hide" } id="delete-perda" title="remover item selecionado" onClick={this.deletePerda} >delete</i>
                                <span className="pointer bt-edit delete-select-additional-lawsuit">
                                    <i className="fa fa-pencil-square-o" onClick={this.editPerda}></i>
                                    <span className="edit-label">Editar</span>
                                </span>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <p className="info">As alterações são salvas automaticamente</p>
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" id="filialModal" tabIndex="-1" role="dialog" aria-labelledby="filialModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="filialModalLabel">Incluir ou Excluir Filiais</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label htmlFor="filialName" className="col-form-label col-md-12">Nova Filial: </label>
                              <input type="text" className="form-control" id="filialName" />
                              <span className="btn btn-primary" id="add-filial" onClick={this.addFilial} >Incluir</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="filialSettings" className="col-form-label col-md-12">Opções: </label>
                                <select multiple id="filialSettings" className="filial settings">
                                  {arrFilial}
                                </select>
                                <i className={ this.authService.can('dados_complementares_processo_excluir', true, 'processos') ? "material-icons delete" : "material-icons delete hide" } id="delete-filial" title="remover item selecionado" onClick={this.deleteFilial} >delete</i>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <p className="info">As alterações são salvas automaticamente</p>
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>

          </div>

  }
}
