import React from 'react';
import MyPopover from '../../containers/mypopover';
import { Modal, Button } from 'antd';
import FlashMessage from "../../containers/FlashMessage";
import Loading from '../../containers/loading';
import Alert from '../../containers/alert';
import Confirm from '../../containers/confirm';
import AuthService from '../../../services/AuthService';
import GedService from '../../../services/GedService';
import { post } from 'axios';
import {api} from '../../../config';

export default class GED extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.roll = this.roll.bind(this);
    this.addDocument = this.addDocument.bind(this);
    //this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);

    this.myRef = React.createRef();

    this.authService = new AuthService();

    this.state = {
      documents: [],
      lawsuit_token: props.token,
      visible: false,
      errors: [],
      title: "",
      loadingMessage: [],
      message: "",
      showAlert: false,
      showConfirm: false,
      deleteDocument: false
    }

  }

  componentWillReceiveProps( nextProps ) {

    if ( nextProps.token !== this.props.token ) {
      this.setState(
        {
          'lawsuit_token': nextProps.token
        }
      );
      this.listDocuments( nextProps.token );
    }

  }

  roll = (event) => {

    if ( !event.target.classList.contains('collapsed') ) {
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop-67)
      }, 800);
    }

  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,          
          });
        }
      });
  
      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
          });
  
          if( this.state.deleteDocument !== false ){
              this.deleteDocument(this.state.deleteDocument, true);
          }
        }
      });

      document.addEventListener("cancelAlert", (e) => {
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
      });

      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

      this.listDocuments( this.state.lawsuit_token );

  }

  listDocuments ( token ) {

    this.GedService = new GedService();
    this.GedService.listDocuments( token ).then(res =>{

      if(this._isMounted ){

            //console.log(res);

            this.setState({
                documents: res,
            });

      }

    });

  }


   handleChange(e){

        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    onChange(e) {
      //console.log(e.target.files);
      if (e.target.files.length > 10) {
          //alert("Você pode selecionar no máximo 10 arquivos");
          this.setState({
            showAlert: true,
            message: "Você pode selecionar no máximo 10 arquivos"
          });
          document.getElementById("handleOk").disabled = true;
      } else {
          this.setState({file:e.target.files});
          document.getElementById("handleOk").disabled = false;
      }

      var maxSize = e.target.dataset.max_size;

      for( let i = 0; i< e.target.files.length; i++){
          if(e.target.files.length){
                var fileSize = e.target.files[0].size; // in bytes
                if(fileSize>maxSize){
                    //alert('o arquivo '+e.target.files[0].name + ' é maior que 15 MegaBytes');
                    this.setState({
                      showAlert: true,
                      message: 'o arquivo '+e.target.files[0].name + ' é maior que 15 MegaBytes'
                    });
                    document.getElementById("handleOk").disabled = true;
                }else{
                    document.getElementById("handleOk").disabled = false;
                }
            }
      }

    }

    /*onFormSubmit(e){
        e.preventDefault() // Stop form submit

    }*/

    fileUpload(file){
      const url = 'https://api.avvocatosistemas.com.br/Arquivos/add?lawsuit_token='+this.state.lawsuit_token;
      const formData = new FormData();

      //console.log(file);
      let names = [];

      for(let i=0; i < file.length; i++){
        //files.push(file[i]);
        formData.append(i,file[i], file[i].name);
        names.push(this.state.title);
      }

      //formData.append('file',file);
      formData.append('names',JSON.stringify(names));
      let auth = new AuthService();
      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              'Authorization': 'Bearer ' + auth.getToken(),
          }
      }
      return  post(url, formData, config)
    }

    addDocument = (event) => {

      if( this.validateForm() ){

        
                this.setState({
                  loadingVisible: true,
                  loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", ""]
                });
          
                this.fileUpload(this.state.file).then( (response) =>{

                  if(this._isMounted ){

                          //console.log(response);
                  
                          if( typeof response !== "undefined" && response.data.length > 0 && response.data[0].success ){
                            localStorage.setItem('alert_message', "Arquivo adicionado com sucesso");
                            localStorage.setItem('alert_type', 'alert-success');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );
                  
                            document.getElementById("file_ged").value="";
                  
                            this.setState({visible: false, title: "", file: "", loadingVisible: false});
                          }else{
                            let message = "";
                            if( typeof response !== "undefined" && response.data.length > 0 && response.data[0].message !== "" ){
                              message = ": "+response.data[0].message;
                            }
                            //alert("Ocorreu um erro ao fazer upload \n"+message);
                            this.setState({
                              showAlert: true,
                              message: "Ocorreu um erro ao fazer upload \n"+message
                            });
                            this.setState({visible: false, title: "", file: "", loadingVisible: false});
                          }
                  
                          this.listDocuments( this.state.lawsuit_token );

                  }

                });

      }

    }

    deleteDocument = (event, result = false) => {

      //let result = window.confirm( "Deseja excluir definitivamente este documento?");

      if ( result ) {

        this.GedService = new GedService();
        this.GedService.delete( event.target.dataset.token ).then(res =>{

            if(this._isMounted ){
                    //console.log(res);

                    this.listDocuments( this.state.lawsuit_token );
            }

        });

      }else{
        let evt = {
          target: {
            dataset: {
              token: event.target.dataset.token
            }
          }
        }
        this.setState({
          showConfirm: true,
          deleteDocument: evt,
          message: "Deseja excluir definitivamente este documento?"
        });
      }

    }

   documentItem = ( {record, index}, type ) => {

     return (
       <tr key={record.token} className={type}>
             <td className="category " >
                     {( record.created !== "") ? record.created.substr(0, 10).split('-').reverse().join('/') : ""}
             </td>
             <td className=" description" >
                     {record.name+" ("+record.name_old+")"}
             </td>
             <td className="">
                     {record.user.name}
             </td>
             <td className="actions">
                <a target="_blank" className="ged-download-button" rel="noopener noreferrer" href={api.url+"Arquivos/download/"+record.user.token+"/"+record.token} ><i className="fa fa-download"></i></a>
                <i className={(this.authService.can('ged_documentos_do_processo_excluir', true, 'ged')) ? "fa fa-trash" : "hide"} data-token={record.token} onClick={ (event)=>{ this.deleteDocument(event) } }></i>
             </td>
       </tr>


     );

   };

   showDocuments = ( e ) => {
     let itens = document.getElementsByClassName(e.target.dataset.type);

     for (let n = itens.length - 1; n >= 0; n--) {

          if ( n >= 10 ) {
            itens[n].className += ' show-table';
          }
      }

      document.getElementById("show-button-"+e.target.dataset.type).className += ' hide';
      document.getElementById("hide-button-"+e.target.dataset.type).className += ' show';

   }

   hideDocuments = ( e ) => {
     let itens = document.getElementsByClassName(e.target.dataset.type);

     for (let n = itens.length - 1; n >= 0; n--) {

          if ( n >= 10 ) {
            itens[n].classList.remove('show-table');
          }
      }

      document.getElementById("hide-button-"+e.target.dataset.type).classList.remove('show');
      document.getElementById("show-button-"+e.target.dataset.type).classList.remove('hide');

   }

   show_hide_button_container = ( record, index, type ) => {

       if ( index > 9 && index < 11 ) {
         return (
           <div key={index} id="document-show-hide-button-container" className="show-hide-button-container">
               <span id={"show-button-"+type} className="btn-small btn-info show-button" data-type={type} onClick={this.showDocuments}>Ver mais</span>
               <span id={"hide-button-"+type} className="btn-small btn-info hide-button" data-type={type} onClick={this.hideDocuments}>Ocultar registros</span>
           </div>
         );
       }else {
         return "";
       }

   };

   openAddDocument = () => {
     this.setState({
       visible: true
     });
   }

   validateForm() {
      let errors = {};
      let formIsValid = true;

        if ( !this.state.title ) {
          formIsValid = false;
          errors["title"] = "*Preencha o campo título do documento";
        }

        if( typeof this.state.file !== "undefined"){
        }else{
          formIsValid = false;
          //alert("Nenhum arquivo foi selecionado");
          this.setState({
            showAlert: true,
            message: "Nenhum arquivo foi selecionado"
          });
        }

      this.setState({
          errors: errors
        });

        return formIsValid;
  }

   clearFields(){
     this.setState({visible: false, title: "", file: "", loadingVisible: false});
     document.getElementById("file_ged").value="";
   }

     handleCancel = () => {
         //if( window.confirm("As alterações serão perdidas, deseja realmente sair?") ){

             this.clearFields();

         //}
     }

  render() {

    if( !this.authService.can("ged_documentos_do_processo", true, 'ged') ){
      return null;
    }

    var url_base = localStorage.getItem('url_base');

      return (
        <div className="panel panel-default container-parte">
              <FlashMessage time={ new Date().getTime() } />
              <Confirm show={this.state.showConfirm} text={this.state.message} />
              <Alert show={this.state.showAlert} text={this.state.message} />
              <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
              <div className="panel-heading text-center">
                  <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#document-lawsuit-target">
                      <strong>Gerenciador Eletrônico de Documentos - GED</strong>
                  </div>
              </div>
              <div className="panel-body collapse" id="document-lawsuit-target">
                <span className="btn btn-primary" id="bnt-cadastrar-ged" onClick={this.openAddDocument}>Incluir</span>
                <MyPopover icon="white" id="addPopover"  text="Arquive em nuvem, todos os documentos digitalizados do processo e baixe-os a qualquer momento ou local." />
                <div className="table-responsive">
                      <table id="document-table" className="table">
                              <thead>
                                  <tr>
                                        <th className="date">
                                            <div>Data</div>
                                        </th>
                                        <th className="title">
                                            <div>Título</div>
                                        </th>
                                        <th className="user">
                                            <div>Usuário</div>
                                        </th>
                                        <th className="actions">
                                            <div>Ações</div>
                                        </th>
                                  </tr>
                              </thead>
                              <tbody>
                                    { ( typeof this.state.documents !== "undefined") ? this.state.documents.map( (record, index) => this.documentItem({ record, index }, 'document-item') ) : null }
                              </tbody>
                      </table>
                </div>
                { ( typeof this.state.documents !== "undefined" ) ? this.state.documents.map( (record, index) =>
                  this.show_hide_button_container( record, index, 'document-item' ) ) : ""
                }
              </div>
              <Modal
                visible={this.state.visible}
                title="Cadastrar Novo Documento"
                width="675px"
                onOk={this.addDocument}
                onCancel={this.handleCancel}
                footer={[
                  <Button key="submit" id="handleOk" type="primary" onClick={this.addDocument}>
                    Cadastrar
                  </Button>,
                  <Button key="back" className="btn-danger" onClick={this.handleCancel}>
                    Fechar
                  </Button>,
                ]}
              >

                      <div className="form-group">
                          <label htmlFor="lawsuit-number">Título<span className="text-red">*</span></label>
                          <MyPopover icon="white" id="addPopover"  text="Evite nomes extensos e utilização de caracteres especiais no preenchimento do Título do arquivo. Se certifique que a extensão do arquivo é uma extensão válida/liberada." />
                          <input className="form-control input-lg" name="title" value={this.state.title} onChange={this.handleChange} required />
                          <div className="errorMsg">{this.state.errors.title}</div>
                          <input type="file" id="file_ged" multiple name="file" data-max_size="15728640" onChange={this.onChange} />
                              <div className="">Limite de 15MB por arquivo.</div>
                              <div className="">Máximo de 10 arquivos por upload.</div>
                              <div className="">Arquivos liberados: .pdf, .txt, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .gif</div>
                      </div>

              </Modal>
        </div>
      )

  }
}
