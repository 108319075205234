import React from 'react';
import Select from 'react-select';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import FlashMessage from './containers/FlashMessage';
import Footer from './containers/footer';
import Loading from './containers/loading';
import ParteSelect from './doc-facil/containers/parteSelect';
import Document from './doc-facil/containers/document';
import eDocsService from '../services/eDocsService';

import {isTablet} from './config/mobileDetect';

export default class generateDocuments extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
      super( props );
      this.addParteComponent = this.addParteComponent.bind(this);
      this.generate = this.generate.bind(this);

      this.modifiedParte = this.modifiedParte.bind(this);
      this.removeComponent = this.removeComponent.bind(this);
      this.enableButton = this.enableButton.bind(this);
      this.handleChange = this.handleChange.bind(this);

      this.state = {
        areaList: [],
        area: "",
        document: "",
        tipo: {value: 0, label: ""},
        tipoList: [],
        loadingVisible: false,
        loadingMessage: [],
        tipo_disabled: true,
        template: "",
        templateList: [],
        template_disabled: true,
        parteList: [],
        parte_key: 1,
        parte_ids: [], //{key: 0, value: ""}
        partesComponents: [],
        document_token: 0,
        documents: [],
        success: 1,
        msg: [],
        personages: []
      }

      window.scrollTo(0,0);

      if(isTablet()){
        alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
        window.location.href = '/';
      }

  }

  componentDidMount(){
    this._isMounted = true;
    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });
    
    this.populateArea();
    document.getElementById("generate-btn").disabled = true;
  }

  populateArea(){
    document.body.style.cursor = "wait";
    let areaList = []; let area = this.state.area;

    this.eDocsService = new eDocsService();

      this.eDocsService.areaList( ).then(res =>{
          if (this._isMounted) {
                areaList = res;
                //console.log(this.state.area)
                //areaList.unshift({value: -9999, label: "Não Definida"});
                areaList.map((value, index, array )=>{

                  if ( value.value === this.state.area.value ) {
                    area = value;
                  }

                  return "";

                });

                this.setState({
                  areaList: areaList,
                  area: area,
                });
                document.body.style.cursor = "default";
          }
      });


  }

  populateType( area ){
    document.body.style.cursor = "wait";
    let tipoList = []; //let tipo = this.state.tipo;

    this.setState({
      loadingVisible: true,
      loadingMessage: ["Os tipos de modelos estão sendo carregados.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });

    this.eDocsService = new eDocsService();

      this.eDocsService.tipoList( area ).then(res =>{
          if (this._isMounted) {
            tipoList = res;
                //console.log(this.state.tipo)
                //tipoList.unshift({value: -9999, label: "Não Definida"});

                this.setState({
                  tipoList: tipoList,
                  loadingVisible: false,
                  tipo_disabled:  false,
                });
                document.body.style.cursor = "default";
          }
      });


  }

  populateTemplate( tipo ){
    document.body.style.cursor = "wait";
    let templateList = []; //let template = this.state.template;

    this.setState({
      loadingVisible: true,
      loadingMessage: ["Os modelos estão sendo carregados.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });

    this.eDocsService = new eDocsService();

      this.eDocsService.templateList( tipo ).then(res =>{
          if (this._isMounted) {
              templateList = res;

              this.setState({
                templateList: templateList,
                loadingVisible: false,
                template_disabled: false,
              });
              document.body.style.cursor = "default";
          }
      });
  }

  generate(e){
    //acessar endpoint enviando os ids, título do documento e token do modelo para o doc ser gerado no backend;
    document.body.style.cursor = "wait";
    let personages = [];

    let parte_ids = this.state.parte_ids;

   //console.log("generate doc");
   //console.log(parte_ids);

    for(let i = 0; i < parte_ids.length; i++){
          personages.push(parte_ids[i].value);
    }

    this.setState({
      loadingVisible: true,
      loadingMessage: ["Os modelos estão sendo carregados.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });



    this.eDocsService = new eDocsService();

    let template = {
      name: this.state.document,
      edocs_model_token: this.state.template.value,
      personages: personages
    }

    this.eDocsService.addDocument( template ).then(response =>{

      if (this._isMounted) {

          if(response.success === true){

            let documents = this.state.documents;
            let attr = {
              document_token: response.data.EdocsDocuments.token,
              area: this.state.area,
              tipo: this.state.tipo,
              template: this.state.template,
              title: this.state.document,
            }
            documents.push(attr);


              this.setState({
                loadingVisible: false,
                document_token: response.data.EdocsDocuments.token,
                documents: documents
              });

          }else{

           //console.log(response.errors);

            this.setState({
              loadingVisible: false,
              document: "",
              tipo: null,
              template: null,
              success: 0,
              msg: response.errors,
              personages: personages
            });

          }

          document.getElementById("generate-btn").disabled = true;

          document.body.style.cursor = "default";

      }


    });

  }

  modifiedParte(){

    if( document.getElementById("addParteExtra") !== null ){
      document.getElementById("addParteExtra").classList.remove("hide");
      document.getElementById("help_icon").classList.remove("hide");
    }

    //console.log("area modificada");
    //console.log(document.getElementById("area_id").value);

    //busco elementos pela classe do DOM. E pego o data attribute key e parte_id e populo o array parte_ids
    let parte_ids = [];
    let partes = document.getElementsByClassName("parte_id");
    var i;
    for (i = 0; i < partes.length; i++) {
      let parte = { key: partes[i].dataset.key , value: partes[i].value }
      parte_ids.push(parte);
    }
   //console.log( "Id das partes a ser montado o documento");
   //console.log(parte_ids);

    this.setState({
      parte_ids: parte_ids,
    });

    setTimeout(() => {

      this.enableButton();

    }, 300);

  }

  removeComponent( event ){
    let id = event.target.dataset.id;
    //let itens = 0;

    let newe_ids = [];

    let parte_ids = this.state.parte_ids;

    //console.log(parte_ids);
    //console.log("agora são os novos");

    for(let i = 1; i < parte_ids.length; i++){
        if(parte_ids[i].key === id){

        }else{
          newe_ids.push(parte_ids[i]);
        }
    }

    let complete_newe_ids = parte_ids[0];

    if( typeof newe_ids !== "undefined"){
     //console.log(complete_newe_ids);
      complete_newe_ids = newe_ids.concat(complete_newe_ids);
    }

    this.setState({
      parte_ids: complete_newe_ids,
    });

    this.recriateComponent( newe_ids );

  }

  recriateComponent( parte_ids ){

    let partesComponents = [];

    let parte_key = 1;

    for(let i = 0; i < parte_ids.length; i++){
      parte_key++;
      let component = <div key={parte_key+"-div"} className="partes-container">
                        <ParteSelect key={parte_key} parte_key={parte_key} modifiedParte={this.modifiedParte} option_value={parte_ids[i].value}  />
                        <span key={parte_key+"-span"} className="remove-component" ><i className="material-icons red" data-id={parte_key} onClick={this.removeComponent}>close</i></span>
                      </div>

      partesComponents.push( component );
    }

    this.setState({
      partesComponents: partesComponents,
      parte_key: parte_key
    });
  }

  enableButton(){
    /*console.log("Enable Button?");
   //console.log(this.state.parte_ids.length);
   //console.log(this.state.area);
   //console.log(this.state.tipo);
   //console.log(this.state.template);
   //console.log(this.state.document);*/
      if( this.state.parte_ids.length > 0 && this.state.area !== "" && this.state.tipo !== null && this.state.template !== null && this.state.tipo.value !== 0 && this.state.template !== "" && this.state.document !== "" ){
        document.getElementById("generate-btn").disabled = false;
      }
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )

       setTimeout(() => {

         this.enableButton();

       }, 300);
   }

  selectChange = (selected, actionMeta) => {

        let pops = [];
        pops[ actionMeta.name ] = selected;

        if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

          if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
            this.setState( pops );
          }

        }

        //console.log( pops.area );

        if ( actionMeta.name === 'area' ){
            this.populateType( pops.area.value );
            this.setState( {tipo: null, template: null, templateList: [], document_token: 0} );
        }

        if ( actionMeta.name === 'tipo' ){
            this.populateTemplate( pops.tipo.value );
            this.setState( {template: null, document_token: 0} );
        }

        if ( actionMeta.name === 'template' ){
            this.setState( { document_token: 0} );
        }

        setTimeout(() => {

          this.enableButton();

        }, 300);

  }

  addParteComponent = () => {

        let partesComponents = this.state.partesComponents;

        let parte_key = this.state.parte_key+1;

        let component = <div key={parte_key+"-div"} className="partes-container">
                          <ParteSelect key={parte_key} parte_key={parte_key} modifiedParte={this.modifiedParte} />
                          <span key={parte_key+"-span"} className="remove-component" ><i className="material-icons red" data-id={parte_key} onClick={this.removeComponent}>close</i></span>
                        </div>

        partesComponents.push( component );

        this.setState({
          partesComponents: partesComponents,
          parte_key: parte_key
        });
  }

  msgErrorItem = ( {record, index}, type ) => {

    return (
      <p className={type} key={index} >{record}</p>
    );
  }

  parteErrorItem = ( {record, index}, type ) => {

    let url_base = localStorage.getItem('url_base');

    return (
      <a className={type} key={index} href={url_base+"/partes/edit/"+record} rel="noopener noreferrer" target="_blank"><b>Ir para o cadastro da parte {index+1}.</b></a>
    );
  }

  render() {
      var url_base = localStorage.getItem('url_base');
      let addParteExtra = null;
      if (this.state.parte_key !== 2) {
        addParteExtra = <div>
                              <span id="addParteExtra" className={"btn btn-info hide"} onClick={this.addParteComponent}>Adicionar parte extra</span>
                              <Popover
                                  isOpen={this.state.isEventPopoverOpen}
                                  position={['top', 'right', 'left', 'bottom']}
                                  padding={10}
                                  onClickOutside={() => this.setState({ isEventPopoverOpen: false })}
                                  content={({ position, targetRect, popoverRect }) => (
                                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                          position={position}
                                          targetRect={targetRect}
                                          popoverRect={popoverRect}
                                          arrowColor={'#94bcd8'}
                                          arrowSize={10}
                                      >
                                          <div className="popover-box"
                                              onClick={() => this.setState({ isEventPopoverOpen: !this.state.isEventPopoverOpen })}
                                          >
                                                Poderá ser adicionado mais que uma parte em um único documento, desde que estejam com todos os dados cadastrados ne ficha cadastral destas partes. Ex.: Marido e Mulher,  Sócios de empresas, litisconsorte e outros.
                                          </div>
                                      </ArrowContainer>
                                  )}
                              >
                                  <img onMouseOut={() => this.setState({ isEventPopoverOpen: false })}  onMouseOver={() => this.setState({ isEventPopoverOpen: !this.state.isEventPopoverOpen })} id="help_icon" className="help_icon hide" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                              </Popover>
                        </div>;
      }

      return <div className="sheet generate-document">
                <HeaderBar />
                <NavigationMenu />
                <FlashMessage time={ new Date().getTime() } />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="content-header">
                            <h1>Doc - Fácil</h1>
                      </div>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4 className="header-h4">
                                      Gerar Documentos
                                      <Popover
                                          isOpen={this.state.isH4PopoverOpen}
                                          position={['top', 'right', 'left', 'bottom']}
                                          padding={10}
                                          onClickOutside={() => this.setState({ isH4PopoverOpen: false })}
                                          content={({ position, targetRect, popoverRect }) => (
                                              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                  position={position}
                                                  targetRect={targetRect}
                                                  popoverRect={popoverRect}
                                                  arrowColor={'#94bcd8'}
                                                  arrowSize={10}
                                              >
                                                  <div className="popover-box"
                                                      onClick={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })}
                                                  >
                                                      Possibilita gerar e imprimir vários documentos de uma só vez, do mesmo cliente ou clientes diferentes. Preenchimento automático de todos os dados cadastrais. Os documentos gerados não serão arquivados.(Para arquivar pós assinados, utilize o GED).
                                                  </div>
                                              </ArrowContainer>
                                          )}
                                      >
                                          <img onMouseOut={() => this.setState({ isH4PopoverOpen: false })} onMouseOver={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                      </Popover>
                                  </h4>
                            </div>
                      </div>
                      <div className="col-md-6">
                          <div className="form-group col-md-12 text-left">
                                  <label className="col-md-12">Área</label>
                                  <Select
                                      className="area col-md-12"
                                      placeholder="Selecione"
                                      options={this.state.areaList}
                                      value={(this.state.area.value !== 0 && this.state.area.value !== "" )? this.state.area : null}
                                      name="area"
                                      onChange={this.selectChange}
                                    />
                          </div>
                          <div className="form-group col-md-12 text-left">
                                  <label className="col-md-12">Tipo</label>
                                  <input type="hidden" id="type_id" name="type_id" />
                                  <Select
                                      className="tipo col-md-12"
                                      placeholder="Selecione"
                                      options={this.state.tipoList}
                                      value={(this.state.tipo !== null && this.state.tipo.value !== 0 && this.state.tipo.value !== "" )? this.state.tipo : null}
                                      name="tipo"
                                      isDisabled={this.state.tipo_disabled}
                                      onChange={this.selectChange}
                                    />
                            </div>
                            <div className="form-group col-md-12 text-left">
                                    <label className="col-md-12">Título do Modelo</label>
                                    <Select
                                        className="template col-md-12"
                                        placeholder="Selecione"
                                        options={this.state.templateList}
                                        value={this.state.template}
                                        isDisabled={this.state.template_disabled}
                                        name="template"
                                        onChange={this.selectChange}
                                      />
                              </div>
                              <div className="form-group col-md-12 text-left">
                                    <label className="col-md-12">Título para este Documento</label>
                                    <input
                                        className="document form-control input-lg"
                                        value={this.state.document}
                                        name="document"
                                        maxLength="40"
                                        onChange={this.handleChange}
                                      />
                              </div>
                              <ParteSelect parte_key={1} modifiedParte={this.modifiedParte} />
                              {this.state.partesComponents.map( ( component => (
                                  component
                                )) )
                              }
                              <div className="form-group col-md-12 text-left">
                                  {addParteExtra}
                              </div>
                              <div className="form-group col-md-12 text-right">
                                  <button id="generate-btn" className="btn btn-success" onClick={this.generate}>Gerar Documento</button>
                              </div>
                      </div>
                      <div className="col-md-6">
                            <div className="form-group col-md-12 text-left">
                                  { this.state.documents.map( ( record, index ) => {
                                    return ( <Document key={index} attr={record} /> )
                                  } ) }
                            </div>
                            <div className={ (this.state.success === 1)? "hide" : "alert alert-danger document-info alert-dismissible alert-shown" } role="alert">
                                    <span className="close" data-dismiss="alert" aria-label="close">&times;</span>
                                    <div className="container-info">
                                        <b>Erro ao gerar documento</b>
                                        <p>Existem informações das partes que não foram preenchidas e necessitam para a geração do documento!
                                        </p>
                                        {this.state.msg.map( (record, index) => this.msgErrorItem( {record, index}, 'msg-error-item' ) )}
                                        {this.state.personages.map( (record, index) => this.parteErrorItem( {record, index}, 'parte-error-item' ) )}
                                    </div>
                            </div>
                      </div>
                  </div>
                </div>
             </div>
  }

}
