import React from 'react';
import MyPopover from '../containers/mypopover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import FlashMessage from '../containers/FlashMessage';
import TribunalCheckbox from "./containers/tribunalCheckbox";
import Loading from '../containers/loading';
import Alert from '../containers/alert';
import options from '../json/tribunal_options';
import AuthService from '../../services/AuthService';
import TribunalService from '../../services/TribunalService';
import LawsuitService from '../../services/LawsuitService';

import {isTablet} from './mobileDetect';

const tribunal_options = options;

export default class ConfigTribunals extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelAlert", (e) => {
       //console.log("ei");
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
      }); 

      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

  }

  constructor(...args) {
      super(...args);

          
      if(isTablet()){
        alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
        window.location.href = '/';
      }

      this.authService = new AuthService();

      this.saveTribunals = this.saveTribunals.bind(this);
      this.validateTribunal = this.validateTribunal.bind(this);

      this.state = {
        federal: true,
        mg: true,
        sp: true,
        rj: true,
        manual: true,
        inss: true,
        stf: true,
        stj: true,
        tse: true,
        tst: true,
        trf1: true,
        tjmg: true,
        tjmgprojudi: true,
        trt3: true,
        jfrj: true,
        trf2: true,
        tjrj: true,
        trt01_rj: true,
        jfsp: true,
        jef: true,
        trf3: true,
        tjsp: true,
        tjmsp: true,
        trt15: true,
        trt2: true,
        jef: true,
        jfpr: true,
        tjpr: true,
        tre_pr: true,
        trt09_pr: true,
        jfsc: true,
        tjsc: true,
        tre_sc: true,
        trt12_sc: true,
        jfrs: true,
        tjrs: true,
        tre_rs: true,
        trt04_rs: true,
        jfes: true,
        tjes: true,
        tre_es: true,
        trt17_es: true,
        jfgo: true,
        tjgo: true,
        tre_go: true,
        trt18_go: true,
        jfms: true,
        tjms: true,
        tre_ms: true,
        trt24_ms: true,
        jfm: true,
        tjmt: true,
        tre_mt: true,
        trt23_mt: true,
        jfto: true,
        tjto: true,
        tre_to: true,
        trt10_to: true,
        jfdf: true,
        tjdf: true,
        tre_df: true,
        trt10_df: true,
        jfro: true,
        tjro: true,
        tre_ro: true,
        trt14_ro: true,
        jfam: true,
        tjam: true,
        tre_am: true,
        trt11_am: true,
        jfac: true,
        tjac: true,
        tre_ac: true,
        trt14_ac: true,
        jfrr: true,
        tjrr: true,
        tre_rr: true,
        trt11_rr: true,
        jfpa: true,
        tjpa: true,
        tre_pa: true,
        trt08_pa: true,
        jfap: true,
        tjap: true,
        tre_ap: true,
        trt08_ap: true,
        jfma: true,
        tjma: true,
        tre_ma: true,
        trt16_ma: true,
        jfpi: true,
        tjpi: true,
        tre_pi: true,
        trt22_pi: true,
        jfce: true,
        tjce: true,
        tre_ce: true,
        trt07_ce: true,
        jfrn: true,
        tjrn: true,
        tre_rn: true,
        trt21_rn: true,
        jfpb: true,
        jfpb_pje: true,
        tjpb: true,
        tre_pb: true,
        trt13_pb: true,
        jfpe: true,
        tjpe: true,
        tre_pe: true,
        trt06_pe: true,
        jfal: true,
        tjal: true,
        tre_al: true,
        trt19_al: true,
        jfse: true,
        tjse: true,
        tre_se: true,
        trt19_se: true,
        jfba: true,
        tjba: true,
        tre_ba: true,
        trt05_ba: true,
        message: "",
        showAlert: false,
        tribunalListPermissions: [],
        countTribunals: [],
        tribunalList: [],
        time: new Date().getTime(),
        loadingVisible: false,
        loadingMessage: [],
      }

      window.scrollTo(0,0);

      if( !this.authService.can('acesso_configuracoes', true) ){
        this.props.history.replace('/');
      }

  }

  componentWillReceiveProps(nextProps) {

    this.getTribunalListPermissions();

  }

  getTribunalId( tribunal_slug ) {

    //console.log(tribunal_slug);
    let id = 0;

    for(let i=0; i < this.state.tribunalList.length; i++){

        //console.log( this.state.tribunalList[i].slug.toLowerCase() );
        //console.log(tribunal_slug);

        if( this.state.tribunalList[i].slug.toLowerCase() === tribunal_slug ){
          //console.log( this.state.tribunalList[i] );
          id = this.state.tribunalList[i].id;
        }

    }

    return id;

  }

  getTribunalListPermissions( ) {
        this.tribunalService = new TribunalService();

        this.tribunalService.getPermissions( ).then( tribunalListPermissions  =>{

          this.tribunalService.list( ).then( tribunalList  =>{

                if (this._isMounted) {

                        //console.log(tribunalList);

                        //console.log(tribunalListPermissions);

                        let object = {};

                            for(let i=0; i < tribunalList.length; i++){

                                for (let j=0; j < tribunalListPermissions.length; j++){

                                      if( tribunalList[i].id === tribunalListPermissions[j].tribunal_id ){
                                            let title = tribunalList[i].slug.toLowerCase();
                                            if(title.includes("-") ){
                                              title = title.split("-");
                                              title = title.join("_");
                                            }
                                            let value = (tribunalListPermissions[j].allowed === 1) ? true : false;

                                            object[title] = value;
                                      }

                                }

                            }

                            object['tribunalList'] = tribunalList;

                            //console.log(object);

                        this.setState( object );

                }

          });

        });
  }

  componentWillMount( ) {

    this.tribunalService = new TribunalService();

    this.tribunalService.counterTribunalsLawsuitsWithoutArchived().then( countTribunals =>{

      this.setState({
        countTribunals
      });

    });

    let tribunals = JSON.parse(localStorage.getItem('tribunals'));

    if (tribunals === null || tribunals === "" ){

        this.tribunalService.list().then(res =>{

        })
        .catch(err =>{
           //console.log("Conexão Instável."+err);
        });

    }else{
    }

    this.getTribunalListPermissions();

  }

  ufChange = (e) => {
    let checkboxes = [...document.querySelectorAll( "."+e.target.getAttribute("id") )];

    let state = {};

    //console.log(checkboxes);

    this.tribunalService = new TribunalService();

    for(let cb in checkboxes){
      //console.log(checkboxes[cb].name);
      state[ checkboxes[cb].name ] = e.target.checked;
    }
    state[e.target.name]= e.target.checked;
    this.setState( state );
    //console.log(checkboxes);

  }

  checkChange = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;

        //console.log(e);

        let object = {
           [item]: isChecked
         }

        this.setState( object );
  }

  getStateFederal = () => {
    if( this.state.inss && this.state.stf && this.state.stj && this.state.tse && this.state.tst ){
      return true;
    }else{
      return false;
    }
  }

  getStateMG = () => {
    if( this.state.trf1 && this.state.tjmg && this.state.tjmgprojudi && this.state.trt3 ){
      return true;
    }else{
      return false;
    }
  }

  getStateRJ = () => {
    if( this.state.jfrj && this.state.trf2 && this.state.tjrj && this.state.trt01_rj  ){
      return true;
    }else{
      return false;
    }
  }

  getStateSP = () => {
    if(
        this.state.jfsp && this.state.jef && this.state.trf3 &&
        this.state.tjsp && this.state.tjmsp && this.state.trt15  &&
        this.state.trt2 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePR = () => {
    if(
        this.state.jfpr && this.state.tjpr /*&& this.state.tre_pr*/ &&
        this.state.trt09_pr 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateSC = () => {
    if(
        this.state.jfsc && this.state.tjsc /*&& this.state.tre_sc*/ &&
        this.state.trt12_sc 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRS = () => {
    if(
        this.state.jfrs && this.state.tjrs /*&& this.state.tre_rs*/ &&
        this.state.trt04_rs 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateES = () => {
    if(
        this.state.jfes && this.state.tjes /*&& this.state.tre_es*/ &&
        this.state.trt17_es 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateGO = () => {
    if(
        this.state.jfgo && this.state.tjgo /*&& this.state.tre_go*/ &&
        this.state.trt18_go 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateMS = () => {
    if(
        this.state.jfms && this.state.tjms /*&& this.state.tre_ms*/ &&
        this.state.trt24_ms 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateMT = () => {
    if(
        this.state.jfm && this.state.tjmt /*&& this.state.tre_mt*/ &&
        this.state.trt23_mt 
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateTO = () => {
    if(
        this.state.jfto && this.state.tjto /*&& this.state.tre_to*/ &&
        this.state.trt10_to
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateDF = () => {
    if(
        this.state.jfdf && this.state.tjdf /*&& this.state.tre_df*/ &&
        this.state.trt10_df
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRO = () => {
    if(
        this.state.jfro && this.state.tjro /*&& this.state.tre_ro*/ &&
        this.state.trt14_ro
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAM = () => {
    if(
        this.state.jfam && this.state.tjam /*&& this.state.tre_am*/ &&
        this.state.trt11_am
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAC = () => {
    if(
        this.state.jfac && this.state.tjac /*&& this.state.tre_ac*/ &&
        this.state.trt14_ac
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRR = () => {
    if(
        this.state.jfrr && this.state.tjrr /*&& this.state.tre_rr*/ &&
        this.state.trt11_rr
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePA = () => {
    if(
        this.state.jfpa && this.state.tjpa /*&& this.state.tre_pa*/ &&
        this.state.trt08_pa
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAP = () => {
    if(
        this.state.jfap && this.state.tjap /*&& this.state.tre_ap*/ &&
        this.state.trt08_ap
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateMA = () => {
    if(
        this.state.jfma && this.state.tjma /*&& this.state.tre_ma*/ &&
        this.state.trt16_ma
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePI = () => {
    if(
        this.state.jfpi && this.state.tjpi /*&& this.state.tre_pi*/ &&
        this.state.trt22_pi
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateCE = () => {
    if(
        this.state.jfce && this.state.tjce /*&& this.state.tre_ce*/ &&
        this.state.trt07_ce
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateRN = () => {
    if(
        this.state.jfrn && this.state.tjrn /*&& this.state.tre_rn*/ &&
        this.state.trt21_rn
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePB = () => {
    if(
        this.state.jfpb && this.state.jfpb_pje && this.state.tjpb /*&& this.state.tre_pb*/ &&
        this.state.trt13_pb
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStatePE = () => {
    if(
        this.state.jfpe && this.state.tjpe /*&& this.state.tre_pe*/ &&
        this.state.trt06_pe
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateAL = () => {
    if(
        this.state.jfal && this.state.tjal /*&& this.state.tre_al*/ &&
        this.state.trt19_al
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateSE = () => {
    if(
        this.state.jfse && this.state.tjse /*&& this.state.tre_se*/ &&
        this.state.trt20_se
      )
    {
      return true;
    }else{
      return false;
    }
  }

  getStateBA = () => {
    if(
        this.state.jfba && this.state.tjba /*&& this.state.tre_ba*/ &&
        this.state.trt05_ba
      )
    {
      return true;
    }else{
      return false;
    }
  }

  validateTribunal = ()=> {
    let tribunal_errors = "";

    for( let item in this.state ){
          if( item !== "tribunalListPermissions" && item !== "tribunalList" && item !== "time" ){
            //console.log(item);
            let slug = item;
            if(item.includes("_") ){
              slug = item.split("_");
              slug = slug.join("-");
            }
            let tribunal_id = this.getTribunalId( slug );

            this.state.countTribunals.map(( trib, x ) => {
              if( trib.tribunal_id === tribunal_id ){
                if( trib.counter > 0 ){
                  if(tribunal_id !== 0 && item !== "" && !this.state[item]){

                        tribunal_errors += slug+", ";
                        //valid = false;
                  }
                }
              }
            });

          }
    }

    return tribunal_errors;
  }

  saveTribunals = () => {

    let validate = this.validateTribunal();

    if( validate === "" ) {

        this.setState({
          loadingVisible: true,
          loadingMessage: ["As configurações estão sendo salvas.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 20 segundos"]
        });

        this.tribunalService = new TribunalService();
        let tribunals = [];

        for( let item in this.state ){
              if( item !== "tribunalListPermissions" && item !== "tribunalList" && item !== "time" ){
                //console.log(item);
                let slug = item;
                if(item.includes("_") ){
                  slug = item.split("_");
                  slug = slug.join("-");
                }
                let tribunal_id = this.getTribunalId( slug );

                this.state.countTribunals.map(( item, x ) => {
                  if( item.tribunal_id === tribunal_id ){
                    if( item.counter > 0 ){

                    }
                  }
                });

                //console.log(tribunal_id);

                    if(tribunal_id !== 0 && item !== ""){

                          let permissions = {
                              tribunal_id: tribunal_id,
                              allowed: this.state[item] ? 1 : 0
                          }
                          tribunals.push(permissions);
                    }

              }
        }

        let params = {
          tribunals: tribunals
        }

        this.tribunalService.setPermissions( params ).then( response  =>{

            if (this._isMounted) {

                  let time = new Date().getTime();
                  if( response.success === true ){
                        localStorage.setItem('alert_message', "Configurações salvas com sucesso");
                        localStorage.setItem('alert_type', 'alert-success');
                        time = new Date().getTime();
                        localStorage.setItem('alert_time', time );

                        //let compromisso_token = res.data.Events.token;
                        //console.log( compromisso_token );
                  }else {
                    localStorage.setItem('alert_message', response.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                  }
                  setTimeout(() => {
                    this.setState({
                      time: time,
                      loadingVisible: false,
                    });
                   //console.log("executou");
                  }, 200);

            }
        });

    }else{
      this.setState({
        showAlert: true,
        message: "Alguns tribunais não podem ser ocultados pois existem processos ativos nele. Arquive os processos e tente novamente. \n Os tribunais com processos ativos são: "+validate
      });
    }

  }

  changeUF = () => {
      let regiao = document.getElementById("regioes").value;

      if(regiao == "todos"){
        let elements = document.getElementsByClassName("estado");
        for (var i = 0; i < elements.length; i++){
          elements[i].style.display = 'block';
        }
      }else{
        let elements = document.getElementsByClassName("estado");
        for (var i = 0; i < elements.length; i++){
          elements[i].style.display = 'none';
        }

        let uf = document.getElementsByClassName(regiao);
        for (var i = 0; i < uf.length; i++){
          uf[i].style.display = 'block';
        }
      }
  }

  render() {
      let federal = this.state.federal;
      federal = this.getStateFederal();
      let mg = this.state.mg;
      mg = this.getStateMG();
      let rj = this.state.rj;
      rj = this.getStateRJ();
      let sp = this.state.sp;
      sp = this.getStateSP();
      let pr = this.state.pr;
      pr = this.getStatePR();
      let sc = this.state.sc;
      sc = this.getStateSC();
      let rs = this.state.rs;
      rs = this.getStateRS();
      let es = this.state.es;
      es = this.getStateES();
      let go = this.state.go;
      go = this.getStateGO();
      let ms = this.state.ms;
      ms = this.getStateMS();
      let mt = this.state.mt;
      mt = this.getStateMT();
      let to = this.state.to;
      to = this.getStateTO();
      let df = this.state.df;
      df = this.getStateDF();
      let ro = this.state.ro;
      ro = this.getStateRO();
      let am = this.state.am;
      am = this.getStateAM();
      let ac = this.state.ac;
      ac = this.getStateAC();
      let rr = this.state.rr;
      rr = this.getStateRR();
      let pa = this.state.pa;
      pa = this.getStatePA();
      let ap = this.state.ap;
      ap = this.getStateAP();
      let ma = this.state.ma;
      ma = this.getStateMA();
      let pi = this.state.pi;
      pi = this.getStatePI();
      let ce = this.state.ce;
      ce = this.getStateCE();
      let rn = this.state.rn;
      rn = this.getStateRN();
      let pb = this.state.pb;
      pb = this.getStatePB();
      let pe = this.state.pe;
      pe = this.getStatePE();
      let al = this.state.al;
      al = this.getStateAL();
      let se = this.state.se;
      se = this.getStateSE();
      let ba = this.state.ba;
      ba = this.getStateBA();

      return <div className="config-user config-tribunal tribunal sheet">
                <HeaderBar />
                <NavigationMenu />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <FlashMessage time={ new Date().getTime()+1000 } />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Configurações</h1>
                        <h5>
                             Selecione o estado e tribunal que deverá aparecer na tela de cadastro.
                             <MyPopover icon="white" text='Para que a tela inicial possa ficar limpa e com facilidade de visualização, selecione somente os Estados e Tribunais que realmente contenham processos. Esta ação pode ser revertida a qualquer tempo pelo administrador ou usuário com acesso à Configurações.' />
                        </h5>
                      </section>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>Tribunais</h4>
                            </div>
                      </div>
                      <div className="box-body">
                                                <div className="filtro">
                                                      <label>Filtrar por regiões: </label>
                                                      <select id="regioes" className="" onChange={()=>{ this.changeUF() } }>
                                                              <option value="todos">Todas</option>
                                                              <option value="sudeste">Sudeste</option>
                                                              <option value="sul">Sul</option>
                                                              <option value="centro-oeste">Centro-Oeste</option>
                                                              <option value="norte">Norte</option>
                                                              <option value="nordeste">Nordeste</option>
                                                      </select>
                                                </div>

                                                <div className="panel estado panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib" name="federal" checked={(federal === true) ? "checked" : ""} onChange={this.ufChange} id="cb_fe" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#federals-target">
                                                            <strong>Federais</strong>
                                                            <span className="green lawsuit-categoria">(Automático)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="federals-target">
                                                      <label htmlFor="chk_27" className=" col-md-4"><input type="checkbox" name="inss" checked={(this.state.inss === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_27" className="cb_fe" /> INSS - Previdência Social</label>
                                                      <label htmlFor="chk_2" className=" col-md-4"><input type="checkbox" name="stf" checked={(this.state.stf === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_2" className="cb_fe" /> STF - Supremo Tribunal Federal</label>
                                                      <label htmlFor="chk_7" className=" col-md-4"><input type="checkbox" name="stj" checked={(this.state.stj === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_7" className="cb_fe" /> STJ - Superior Tribunal de Justiça</label>
                                                      <label htmlFor="chk_12" className=" col-md-4"><input type="checkbox" name="tse" checked={(this.state.tse === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_12" className="cb_fe" /> TSE - Tribunal Superior Eleitoral</label>
                                                      <label htmlFor="chk_10" className=" col-md-4"><input type="checkbox" name="tst" checked={(this.state.tst === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_10" className="cb_fe" /> TST - Tribunal Superior do Trabalho</label>
                                                    </div>
                                                </div>

                                                <div className="panel estado sudeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="mg" checked={(mg === true) ? "checked" : ""} onChange={this.ufChange} id="cb_mg" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#mg-target">
                                                            <strong>Minas Gerais</strong>
                                                            <span className="green lawsuit-categoria">(Automático)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="mg-target">
                                                      <label htmlFor="chk_15" className=" col-md-4"><input type="checkbox" name="trf1" checked={(this.state.trf1 === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_15" className="cb_mg" /> MG - TRF1 - Tribunal Regional Federal 1ª Região</label>
                                                      <label htmlFor="chk_16" className=" col-md-4"><input type="checkbox" name="tjmg" checked={(this.state.tjmg === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_16" className="cb_mg" /> MG - Tribunal de Justiça</label>
                                                      <label htmlFor="chk_18" className=" col-md-4"><input type="checkbox" name="tjmgprojudi" checked={(this.state.tjmgprojudi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_18" className="cb_mg" /> MG - Tribunal de Justiça - PROJUDI</label>
                                                      <label htmlFor="chk_14" className=" col-md-4"><input type="checkbox" name="trt3" checked={(this.state.trt3 === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_mg" /> MG - Tribunal Regional do Trabalho 3ª Região</label>
                                                    </div>
                                                </div>

                                                <div className="panel estado sudeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="rj" checked={(rj === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rj" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rj-target">
                                                            <strong>Rio de Janeiro</strong>
                                                            <span className="green lawsuit-categoria">(Automático)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="rj-target">
                                                        <label htmlFor="chk_20" className=" col-md-4"><input type="checkbox" name="jfrj" checked={(this.state.jfrj === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_20" className="cb_rj" />RJ - Justiça Federal</label>
                                                        <label htmlFor="chk_21" className=" col-md-4"><input type="checkbox" name="trf2" checked={(this.state.trf2 === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_21" className="cb_rj" /> RJ - TRF2 - Tribunal Regional Federal 2ª Região</label>
                                                        <label htmlFor="chk_19" className=" col-md-4"><input type="checkbox" name="tjrj" checked={(this.state.tjrj === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_19" className="cb_rj" /> RJ - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_23" className=" col-md-4"><input type="checkbox" name="trt01_rj" checked={(this.state.trt01_rj === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_23" className="cb_rj" /> RJ - Tribunal Regional do Trabalho 1° Região</label>
                                                    </div>
                                                </div>

                                                <div className="panel estado sudeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="sp" checked={(sp === true) ? "checked" : ""} onChange={this.ufChange} id="cb_sp" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#sp-target">
                                                            <strong>São Paulo</strong>
                                                            <span className="green lawsuit-categoria">(Automático)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="sp-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfsp" checked={(this.state.jfsp === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_sp" />SP - 1º Grau Justiça Federal</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="trf3" checked={(this.state.trf3 === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_sp" /> SP - TRF3 - Tribunal Regional Federal 3ª Região</label>
                                                        <label htmlFor="chk_1" className=" col-md-4"><input type="checkbox" name="tjsp" checked={(this.state.tjsp === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_sp" /> SP - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_24" className=" col-md-4"><input type="checkbox" name="tjmsp" checked={(this.state.tjmsp === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_sp" /> SP - TM - Tribunal Militar</label>
                                                        <label htmlFor="chk_3" className=" col-md-4"><input type="checkbox" name="trt15" checked={(this.state.trt15 === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_3" className="cb_sp" /> SP - Tribunal Regional do Trabalho 15ª Região</label>
                                                        <label htmlFor="chk_4" className=" col-md-4"><input type="checkbox" name="trt2" checked={(this.state.trt2 === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_4" className="cb_sp" /> SP - Tribunal Regional do Trabalho 2ª Região</label>
                                                        <label htmlFor="chk_26" className=" col-md-4"><input type="checkbox" name="jef" checked={(this.state.jef === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_9" className="cb_sp" /> SP - Juizado Especial Federal</label>
                                                    </div>
                                                </div>

                                                <div className="panel estado sudeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="es" checked={(es === true) ? "checked" : ""} onChange={this.ufChange} id="cb_es" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#es-target">
                                                            <strong>Espírito Santo</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="es-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfes" checked={(this.state.jfes === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_es" />ES - TRF2</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjes" checked={(this.state.tjes === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_es" />ES - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_es" checked={(this.state.tre_es === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_es" />ES - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt17_es" checked={(this.state.trt17_es === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_es" />ES - TRT 17ª Região</label>
                                                    </div>
                                                </div>

                                                <div className="panel estado sul panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="pr" checked={(pr === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pr" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pr-target">
                                                            <strong>Paraná</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="pr-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpr" checked={(this.state.jfpr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pr" />PR - TRF4</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpr" checked={(this.state.tjpr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pr" />PR - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pr" checked={(this.state.tre_pr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_pr" />PR - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt09_pr" checked={(this.state.trt09_pr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_pr" />PR - TRT 9ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado sul panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="sc" checked={(sc === true) ? "checked" : ""} onChange={this.ufChange} id="cb_sc" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#sc-target">
                                                            <strong>Santa Catarina</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="sc-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfsc" checked={(this.state.jfsc === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_sc" />SC - TRF4</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjsc" checked={(this.state.tjpr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_sc" />SC - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_sc" checked={(this.state.tre_pr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_sc" />SC - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt12_sc" checked={(this.state.trt12_sc === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_sc" />SC - TRT 12ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado sul panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="rs" checked={(rs === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rs" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rs-target">
                                                            <strong>Rio Grande do Sul</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="rs-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfrs" checked={(this.state.jfrs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rs" />RS - TRF4</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjrs" checked={(this.state.tjrs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_rs" />RS - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_rs" checked={(this.state.tre_rs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_rs" />RS - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt04_rs" checked={(this.state.trt04_rs === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_rs" />RS - TRT 4ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado centro-oeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="go" checked={(go === true) ? "checked" : ""} onChange={this.ufChange} id="cb_go" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#go-target">
                                                            <strong>Goiás</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="go-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfgo" checked={(this.state.jfgo === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_go" />GO - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjgo" checked={(this.state.tjgo === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_go" />GO - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_go" checked={(this.state.tre_go === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_go" />GO - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt18_go" checked={(this.state.trt18_go === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_go" />ES - TRT 17ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado centro-oeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="ms" checked={(ms === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ms" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ms-target">
                                                            <strong>Mato Grosso do Sul</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="ms-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfms" checked={(this.state.jfms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ms" />MS - TRF3</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjms" checked={(this.state.tjms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ms" />MS - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ms" checked={(this.state.tre_ms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_ms" />MS - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt24_ms" checked={(this.state.trt24_ms === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_ms" />MS - TRT 24ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado centro-oeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="mt" checked={(mt === true) ? "checked" : ""} onChange={this.ufChange} id="cb_mt" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#mt-target">
                                                            <strong>Mato Grosso</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="mt-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfm" checked={(this.state.jfm === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_mt" />MT - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjmt" checked={(this.state.tjmt === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_mt" />MT - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_mt" checked={(this.state.tre_mt === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_mt" />MT - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt23_mt" checked={(this.state.trt23_mt === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_mt" />MT - TRT 23ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado centro-oeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="df" checked={(df === true) ? "checked" : ""} onChange={this.ufChange} id="cb_df" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#df-target">
                                                            <strong>Distrito Federal</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="df-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfdf" checked={(this.state.jfdf === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_df" />DF - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjdf" checked={(this.state.tjdf === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_df" />DF - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_df" checked={(this.state.tre_df === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_df" />DF - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt10_df" checked={(this.state.trt10_df === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_df" />DF - TRT 10ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado norte panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="to" checked={(to === true) ? "checked" : ""} onChange={this.ufChange} id="cb_to" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#to-target">
                                                            <strong>Tocantins</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="to-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfto" checked={(this.state.jfto === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_to" />TO - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjto" checked={(this.state.tjto === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_to" />TO - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_to" checked={(this.state.tre_to === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_to" />TO - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt10_to" checked={(this.state.trt10_to === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_to" />TO - TRT 10ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado norte panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="ro" checked={(ro === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ro" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ro-target">
                                                            <strong>Rondônia</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="ro-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfro" checked={(this.state.jfro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ro" />RO - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjro" checked={(this.state.tjro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ro" />RO - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ro" checked={(this.state.tre_ro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_1" className="cb_ro" />RO - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt14_ro" checked={(this.state.trt14_ro === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_24" className="cb_ro" />RO - TRT 14ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado norte panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="am" checked={(am === true) ? "checked" : ""} onChange={this.ufChange} id="cb_am" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#am-target">
                                                            <strong>Amazonas</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="am-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfam" checked={(this.state.jfam === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_am" />AM - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjam" checked={(this.state.tjam === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_am" />AM - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_am" checked={(this.state.tre_am === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_am" />AM - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt11_am" checked={(this.state.trt11_am === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_am" />AM - TRT 11ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado norte panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="ac" checked={(ac === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ac" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ac-target">
                                                            <strong>Acre</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="ac-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfac" checked={(this.state.jfac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ac" />AC - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjac" checked={(this.state.tjac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ac" />AC - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ac" checked={(this.state.tre_ac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ac" />AC - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt14_ac" checked={(this.state.trt14_ac === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ac" />AC - TRT 14ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado norte panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="rr" checked={(rr === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rr" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rr-target">
                                                            <strong>Roraima</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="rr-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfrr" checked={(this.state.jfrr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rr" />RR - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjrr" checked={(this.state.tjrr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_rr" />RR - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_rr" checked={(this.state.tre_rr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rr" />RR - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt11_rr" checked={(this.state.trt11_rr === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_rr" />RR - TRT 11ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado norte panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="pa" checked={(pa === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pa" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pa-target">
                                                            <strong>Pará</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="pa-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpa" checked={(this.state.jfpa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pa" />PA - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpa" checked={(this.state.tjpa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pa" />PA - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pa" checked={(this.state.tre_pa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pa" />PA - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt08_pa" checked={(this.state.trt08_pa === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pa" />PA - TRT 8ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado norte panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="ap" checked={(ap === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ap" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ap-target">
                                                            <strong>Amapá</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="ap-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfap" checked={(this.state.jfap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ap" />AP - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjap" checked={(this.state.tjap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ap" />AP - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ap" checked={(this.state.tre_ap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ap" />AP - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt08_ap" checked={(this.state.trt08_ap === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ap" />AP - TRT 8ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="ma" checked={(ma === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ma" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ma-target">
                                                            <strong>Maranhão</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="ma-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfma" checked={(this.state.jfma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ma" />MA - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjma" checked={(this.state.tjma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ma" />MA - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ma" checked={(this.state.tre_ma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ma" />MA - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt16_ma" checked={(this.state.trt16_ma === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ma" />MA - TRT 16ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="pi" checked={(pi === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pi" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pi-target">
                                                            <strong>Piauí</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="pi-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpi" checked={(this.state.jfpi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pi" />PI - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpi" checked={(this.state.tjpi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pi" />PI - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pi" checked={(this.state.tre_pi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pi" />PI - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt22_pi" checked={(this.state.trt22_pi === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pi" />PI - TRT 22ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="ce" checked={(ce === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ce" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ce-target">
                                                            <strong>Ceará</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="ce-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfce" checked={(this.state.jfce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ce" />CE - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjce" checked={(this.state.tjce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ce" />CE - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ce" checked={(this.state.tre_ce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ce" />CE - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt07_ce" checked={(this.state.trt07_ce === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ce" />CE - TRT 07ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="rn" checked={(rn === true) ? "checked" : ""} onChange={this.ufChange} id="cb_rn" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#rn-target">
                                                            <strong>Rio Grande do Norte</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="rn-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfrn" checked={(this.state.jfrn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rn" />RN - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjrn" checked={(this.state.tjrn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_rn" />RN - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_rn" checked={(this.state.tre_rn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_rn" />RN - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt21_rn" checked={(this.state.trt21_rn === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_rn" />RN - TRT 21ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="pb" checked={(pb === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pb" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pb-target">
                                                            <strong>Paraíba</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="pb-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpb" checked={(this.state.jfpb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pb" />PB - TRF5</label>
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpb_pje" checked={(this.state.jfpb_pje === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_12" className="cb_pb" />PB - TRF5 PJE</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpb" checked={(this.state.tjpb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pb" />PB - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pb" checked={(this.state.tre_pb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pb" />PB - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt13_pb" checked={(this.state.trt13_pb === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pb" />PB - TRT 13ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="pe" checked={(pe === true) ? "checked" : ""} onChange={this.ufChange} id="cb_pe" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#pe-target">
                                                            <strong>Pernambuco</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="pe-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfpe" checked={(this.state.jfpe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pe" />PE - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjpe" checked={(this.state.tjpe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_pe" />PE - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_pe" checked={(this.state.tre_pe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_pe" />PE - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt06_pe" checked={(this.state.trt06_pe === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_pe" />PE - TRT 06ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="al" checked={(al === true) ? "checked" : ""} onChange={this.ufChange} id="cb_al" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#al-target">
                                                            <strong>Alagoas</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="al-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfal" checked={(this.state.jfal === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_al" />AL - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjal" checked={(this.state.tjal === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_al" />AL - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_al" checked={(this.state.tre_al === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_al" />AL - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt19_al" checked={(this.state.trt19_al === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_al" />AL - TRT 19ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="se" checked={(se === true) ? "checked" : ""} onChange={this.ufChange} id="cb_se" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#se-target">
                                                            <strong>Sergipe</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="se-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfse" checked={(this.state.jfse === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_se" />SE - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjse" checked={(this.state.tjse === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_se" />SE - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_se" checked={(this.state.tre_se === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_se" />SE - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt20_se" checked={(this.state.trt20_se === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_se" />SE - TRT 20ª Região</label>
                                                    </div>

                                                </div>

                                                <div className="panel estado nordeste panel-default">
                                                    <div className="panel-heading text-center">
                                                        <input type="checkbox" className="chk_estado_trib pull-left" name="ba" checked={(ba === true) ? "checked" : ""} onChange={this.ufChange} id="cb_ba" />
                                                        <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#ba-target">
                                                            <strong>Bahia</strong>
                                                            <span className="red lawsuit-categoria">(Manual)</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body collapse" id="ba-target">
                                                        <label htmlFor="chk_11" className=" col-md-4"><input type="checkbox" name="jfba" checked={(this.state.jfba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ba" />BA - TRF1</label>
                                                        <label htmlFor="chk_6" className=" col-md-4"><input type="checkbox" name="tjba" checked={(this.state.tjba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_6" className="cb_ba" />BA - Tribunal de Justiça</label>
                                                        <label htmlFor="chk_1" className=" col-md-4 hide"><input type="checkbox" name="tre_ba" checked={(this.state.tre_ba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_11" className="cb_ba" />BA - TRE</label>
                                                        <label htmlFor="chk_24" className=" col-md-4">
                                                        <input type="checkbox" name="trt05_ba" checked={(this.state.trt05_ba === true) ? "checked" : ""} onChange={ (event) => { this.checkChange(event) } } id="chk_14" className="cb_ba" />BA - TRT 5ª Região</label>
                                                    </div>

                                                </div>

                                              <span className="btn btn-success" onClick={()=>{this.saveTribunals(); }} >Salvar</span>

                      </div>
                  </div>
                </div>
             </div>
  }

}
